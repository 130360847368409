import { MatDialogRef } from '@angular/material/dialog';
import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-dialog',
  template: `
    <h2 mat-dialog-title><span>Hlídač hypotéky</span></h2>
    <mat-dialog-content>
      <form #dealForm="ngForm">
        <div class="form-row">
          <mat-slide-toggle [(ngModel)]="updatedDeal.isWatchdog" name="isEnabled">Aktivní</mat-slide-toggle>
        </div>
      </form>
    </mat-dialog-content>
    <mat-dialog-actions>
      <button type="button" mat-button color="warn" (click)="dialogRef.close(null)">Zavřít</button>
      <button type="button" mat-raised-button color="accent" (click)="dialogRef.close(updatedDeal)" [disabled]="dealForm.invalid || dealForm.pristine">Uložit</button>
    </mat-dialog-actions>
  `,
})
export class WatchdogDialogComponent implements OnInit {

  public deal: any;
  public updatedDeal: any;

  constructor(
    public dialogRef: MatDialogRef<WatchdogDialogComponent>,
  ) {
  }

  ngOnInit() {
    this.updatedDeal = {
      isWatchdog: !!this.deal.watchdog?.isEnabled
    };
  }
}
