import { Pipe, PipeTransform } from '@angular/core';
import {AbstractControl, FormArray} from '@angular/forms';

@Pipe({ name: 'formArray' })
export class FormArrayPipe implements PipeTransform {
  constructor() {}
  transform(form: AbstractControl) {
    return (form as FormArray);
  }
}
