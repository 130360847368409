import { Pipe, PipeTransform } from '@angular/core';
import { TimelineItem } from '@app/components/timeline/timeline-item.component';

@Pipe({
  name: 'timelineIsFuture',
  pure: true,
})
export class TimelineIsFuturePipe implements PipeTransform {

  transform(value: TimelineItem[], isFuture: boolean): TimelineItem[] {
    return value.filter((item: TimelineItem) => (isFuture ? item.isFutureActivity : !item.isFutureActivity));
  }

}
