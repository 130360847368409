import {Component, OnInit, OnDestroy} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {UipathService} from '@app/services/uipath.service';
import {UipathInterface} from '@app/interfaces/uipath.interface';
import {DealsService} from '@app/services/deals.service';
import {OffersService} from '@app/services/offers.service';
import {SettingsService} from '@app/services/settings.service';
import {MainService} from '@app/services/main.service';
import {DealProductsService} from '@app/services/dealproducts.service';
import {DialogsService} from '@app/services/dialogs.service';
import {FilesService} from '@app/services/files.service';
import {SnackbarService} from '@app/services/snackbar.service';
import {ProvidersService} from '@app/services/providers.service';
import {OptionInterface} from '@app/interfaces/option.interface';
import {Provider} from '@app/models/provider.model';
import {Deal} from '@app/models/deal.model';
import {Offer} from '@app/models/offer.model';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-offer-modelations',
  templateUrl: './offer-modelations.component.html',
  styleUrls: ['./offer-modelations.component.scss']
})

export class OfferModelationsComponent implements OnInit, OnDestroy {

  public deal: Deal = null;
  public offer: Offer = null;
  private subs$: Subscription[] = [];
  public selectingSourceProduct: any = null;
  public sourceProductsForm: FormGroup;

  constructor(
    public dealsService: DealsService,
    public offersService: OffersService,
    public settingsService: SettingsService,
    public mainService: MainService,
    public productsService: DealProductsService,
    private dialogsService: DialogsService,
    private filesService: FilesService,
    private snackbarService: SnackbarService,
    private fb: FormBuilder,
    public uipathService: UipathService,
    private providersService: ProvidersService
  ) {
    this.offersService.view = 'offer';
  }

  public canDeactivate() {
    return new Promise( (resolve) => {
      if (this.offersService.offerForm.pristine) {
        resolve(true);
      } else {
        this.dialogsService.deactivate().subscribe((confirm) => {
          if (!confirm) {
            this.mainService.hideLoading();
          }
          resolve(confirm);
        });
      }
    });
  }

  ngOnInit(): void {
    this.subs$.push(this.dealsService.deal$.subscribe((deal: Deal) => {
      this.deal = deal;
    }));
    this.subs$.push(this.offersService.offer$.subscribe((offer: Offer) => {
      this.offer = offer;
      // this.uipathService.loadItems(this.offer._id);
    }));
    this.createSourceProductsForm();
  }

  ngOnDestroy(): void {
    this.subs$.forEach((sub) => sub.unsubscribe());
    this.subs$ = [];
  }

  public closeSourceProducts() {
    this.selectingSourceProduct = null;
  }

  public showSourceProducts(variant, productIndex = null, productTemplate = null, reload = true) {
    if (variant) {
      this.selectingSourceProduct = {
        variant,
        productIndex,
        productTemplate
      };
      if (reload) {
        const offer = this.offersService.offerForm.getRawValue();
        const modelation = offer.modelations[this.offersService.modelationIndex];
        this.productsService.clearItems();
        this.productsService.resetFilters();
        this.productsService.filters.oldestApplicantAge = offer.oldestApplicantAge;
        this.productsService.filters.propertyType = this.offersService.propertyType(offer);
        this.productsService.filters.productFilter = productTemplate.filter;
        this.productsService.filters.value = this.mainService.unmaskCurrency(offer.value);
        this.productsService.filters.offerId = this.offer._id;
        let product;
        if (variant.get('products').value.length) {
          if (productIndex !== null) { // Není to první produkt ve variantě a jedná se o změnu - načíst ze stávajícího produktu
            product = (this.offersService.variantProducts(variant).at(productIndex) as FormGroup).getRawValue();
            this.productsService.filters.amount = this.mainService.unmaskCurrency(product.amount);
            this.productsService.filters.averageAccountBalance = this.mainService.unmaskCurrency(product.averageAccountBalance || 0);
            this.productsService.filters.anotherFinancingSource = this.mainService.unmaskCurrency(offer.amount) > this.productsService.filters.amount ? 1 - (this.productsService.filters.amount / this.mainService.unmaskCurrency(offer.amount)) : 0;
            this.productsService.filters.anotherFinancingSourceApply = product.anotherFinancingSourceApply === undefined || product.anotherFinancingSourceApply;
          } else { // Není to první produkt ve variantě a nejedná se o změnu - dopočítat dle předcházejících produktů
            product = (this.offersService.variantProducts(variant).at(0) as FormGroup).getRawValue();
            this.productsService.filters.amount = this.mainService.unmaskCurrency(product.amount) < this.mainService.unmaskCurrency(offer.amount) ? this.mainService.unmaskCurrency(modelation.amount) - this.mainService.unmaskCurrency(product.amount) : 0;
            this.productsService.filters.otherSourceOf = product.source.provider.stringCode.toLowerCase();
          }
          this.productsService.filters.insurance = product.insurance;
          this.productsService.filters.maturity = product.maturity;
          this.productsService.filters.fixation = product.fixation;
          this.productsService.filters.purpose = Array.from(product.purpose);
          this.productsService.filters.esexpress = product.esexpress;
          this.productsService.filters.esonline = product.esonline;
          this.productsService.filters.ddexpress = product.ddexpress;
          this.productsService.filters.nolimits = product.nolimits;
          this.productsService.filters.offsetType = product.offsetType;
        } else { // Je to první produkt ve variantě
          if (this.offersService.variants.controls.length === 0) { // Zatím v modelaci není žádný produkt
            this.productsService.filters.amount = this.mainService.unmaskCurrency(modelation.amount);
            this.productsService.filters.insurance = modelation.insurance;
            this.productsService.filters.maturity = modelation.maturity;
            this.productsService.filters.fixation = modelation.fixation;
            this.productsService.filters.offsetType = modelation.offsetType;
          } else { // V modelaci už existuje nějáký produkt
            product = (this.offersService.variantProducts(this.offersService.variants.at(0)).at(0) as FormGroup).getRawValue();
            this.productsService.filters.amount = this.mainService.unmaskCurrency(product.amount);
            this.productsService.filters.insurance = product.insurance;
            this.productsService.filters.maturity = product.maturity;
            this.productsService.filters.fixation = product.fixation;
            this.productsService.filters.offsetType = product.offsetType;
          }
          this.productsService.filters.averageAccountBalance = this.mainService.unmaskCurrency(offer.totalAverageAccountBalance);
          this.productsService.filters.purpose = Array.from(offer.purpose);
        }
        this.productsService.load();
        this.fillSourceProductsForm();
      }
    }
  }

  private createSourceProductsForm() {
    this.sourceProductsForm = this.fb.group({
      others: [[]],
      nolimits: [false],
      anotherFinancingSourceApply: [false],
      averageAccountBalance: [0, Validators.required],
      amount: [0, Validators.required],
      maturity: [0, Validators.required],
      maturityMonths: [0],
      maturityYears: [0],
      fixation: [0, Validators.required],
      purpose: [[]],
      insurance: [false],
      offsetType: ['none']
    });
    this.sourceProductsForm.get('maturityYears').valueChanges.subscribe((value: any) => {
      const mYears = parseInt(value, null) || 0;
      const mMonths = parseInt(this.sourceProductsForm.get('maturityMonths').value, null) || 0;
      this.sourceProductsForm.get('maturity').patchValue((mYears * 12) + mMonths, {emitEvent: false});
    });
    this.sourceProductsForm.get('maturityMonths').valueChanges.subscribe((value: any) => {
      const mMonths = parseInt(value, null) || 0;
      const mYears = parseInt(this.sourceProductsForm.get('maturityYears').value, null) || 0;
      this.sourceProductsForm.get('maturity').patchValue((mYears * 12) + mMonths, {emitEvent: false});
    });
    this.sourceProductsForm.valueChanges.subscribe((value) => {
      this.filterSourceProducts();
    });
  }

  private fillSourceProductsForm() {
    const mMonths = (this.productsService.filters.maturity || 0) % 12;
    const mYears = Math.floor((this.productsService.filters.maturity || 0) / 12);
    this.sourceProductsForm.get('amount').setValue(this.productsService.filters.amount, {emitEvent: false});
    this.sourceProductsForm.get('purpose').setValue(this.productsService.filters.purpose, {emitEvent: false});
    this.sourceProductsForm.get('insurance').setValue(this.productsService.filters.insurance, {emitEvent: false});
    this.sourceProductsForm.get('maturity').setValue(this.productsService.filters.maturity, {emitEvent: false});
    this.sourceProductsForm.get('maturityMonths').setValue(mMonths, {emitEvent: false});
    this.sourceProductsForm.get('maturityYears').setValue(mYears, {emitEvent: false});
    this.sourceProductsForm.get('fixation').setValue(this.productsService.filters.fixation, {emitEvent: false});
    this.sourceProductsForm.get('offsetType').setValue(this.productsService.filters.offsetType, {emitEvent: false});
    const others = [];
    if (this.productsService.filters.esexpress) {
      others.push('esexpress');
    }
    if (this.productsService.filters.esonline) {
      others.push('esonline');
    }
    if (this.productsService.filters.ddexpress) {
      others.push('ddexpress');
    }
    this.sourceProductsForm.get('others').setValue(others, {emitEvent: false});
    this.sourceProductsForm.get('nolimits').setValue(this.productsService.filters.nolimits, {emitEvent: false});
    this.sourceProductsForm.get('anotherFinancingSourceApply').setValue(this.productsService.filters.anotherFinancingSourceApply, {emitEvent: false});
    this.sourceProductsForm.get('averageAccountBalance').setValue(this.productsService.filters.averageAccountBalance, {emitEvent: false});
  }

  public filterSourceProducts() {
    const offer = this.offersService.offerForm.getRawValue();
    const filter = this.sourceProductsForm.getRawValue();
    this.productsService.filters.amount = this.mainService.unmaskCurrency(filter.amount);
    this.productsService.filters.averageAccountBalance = this.mainService.unmaskCurrency(filter.averageAccountBalance);
    this.productsService.filters.purpose = Array.from(filter.purpose);
    this.productsService.filters.insurance = filter.insurance;
    this.productsService.filters.maturity = (parseInt(filter.maturityYears || 0, null) * 12) + parseInt(filter.maturityMonths || 0, null);
    this.productsService.filters.fixation = filter.fixation;
    this.productsService.filters.offsetType = filter.offsetType;
    this.productsService.filters.esexpress = filter.others.includes('esexpress');
    this.productsService.filters.esonline = filter.others.includes('esonline');
    this.productsService.filters.ddexpress = filter.others.includes('ddexpress');
    this.productsService.filters.nolimits = filter.nolimits;
    this.productsService.filters.anotherFinancingSourceApply = filter.anotherFinancingSourceApply;
    this.productsService.filters.anotherFinancingSource = this.mainService.unmaskCurrency(offer.amount) > this.productsService.filters.amount ? 1 - (this.productsService.filters.amount / this.mainService.unmaskCurrency(offer.amount)) : 0;
    this.productsService.load();
  }

  public chooseSourceProduct(selectedProduct) {
    const isNewVariant = this.selectingSourceProduct.variant.get('products').value.length === 0;
    selectedProduct = this.offersService.processSelectedSourceProduct(selectedProduct);
    const product = {
      offsetType: this.productsService.filters.offsetType,
      productTemplate: this.selectingSourceProduct.productTemplate,
      source: selectedProduct,
      esonline: this.productsService.filters.esonline,
      esexpress: this.productsService.filters.esexpress,
      ddexpress: this.productsService.filters.ddexpress,
      nolimits: this.productsService.filters.nolimits,
      anotherFinancingSourceApply: this.productsService.filters.anotherFinancingSourceApply,
      averageAccountBalance: this.productsService.filters.averageAccountBalance,
      purpose: Array.from(this.productsService.filters.purpose),
      effectiveInterestRate: selectedProduct.fixation.effectiveInterestRate,
      depositInterestRate: selectedProduct.fixation.depositInterestRate,
      insurance: this.productsService.filters.insurance,
      fixation: this.productsService.filters.fixation,
      maturity: this.productsService.filters.maturity,
      arp: selectedProduct.fixation.arp,
      repayment: selectedProduct.fixation.repayment,
      amount: this.productsService.filters.amount,
      fees: selectedProduct.fixation.fees,
      maximumLoanAmount: 0,
      interestRate: selectedProduct.fixation.interestRate
    };
    const productForm = this.offersService.createProductForm(product);
    if (this.selectingSourceProduct.productIndex === null) {
      this.offersService.variantProducts(this.selectingSourceProduct.variant).push(productForm);
    } else {
      this.offersService.variantProducts(this.selectingSourceProduct.variant).setControl(this.selectingSourceProduct.productIndex, productForm);
    }
    this.selectingSourceProduct.variant.markAsDirty();
    if (isNewVariant) {
      this.offersService.variants.push(this.selectingSourceProduct.variant);
    }
    this.offersService.offerForm.markAsDirty();
    this.addVariant(this.selectingSourceProduct.productTemplate, null, false); // Pridavame dalsi a dalsi varianty dokud neukoncime krizkem
  }

  public addVariant(productTemplate, variant = null, reload = true) {
    if (!variant) {
      variant = this.offersService.createVariantForm(null);
    }
    this.showSourceProducts(variant, null, productTemplate, reload);
  }

  public copyVariant(index) {
    const newVariant = this.offersService.unmaskVariant((this.offersService.variants.at(index) as FormGroup).getRawValue());
    newVariant.isConfirmed = false;
    newVariant.isFinal = false;
    newVariant.isCandidate = false;
    this.offersService.variants.push(this.offersService.createVariantForm(newVariant));
    this.offersService.offerForm.markAsDirty();
  }

  public removeVariant(index) {
    this.dialogsService.confirm('Smazat variantu', 'Chcete opravdu smazat tuto variantu?').subscribe((confirm) => {
      if (confirm) {
        if (this.selectingSourceProduct && this.offersService.variants.controls[index].value.id === this.selectingSourceProduct.variant.id) {
          this.selectingSourceProduct = null;
        }
        for (let i = index; i < this.offersService.variants.controls.length - 1; i++) {
          const current = this.offersService.variants.at(i + 1).value;
          this.offersService.variants.at(i).patchValue(current, {emitEvent: false});
        }
        this.offersService.variants.removeAt(this.offersService.variants.controls.length - 1);
        this.offersService.offerForm.markAsDirty();
      }
    });
  }

  public selectVariant(index) {
    const variant = this.offersService.variants.at(index);
    if (!variant.get('isConfirmed').value) {
      variant.get('isConfirmed').patchValue(true);
    } else if (variant.get('isConfirmed').value && !variant.get('isFinal').value) {
      if (!this.offersService.offerForm.get('modelations').value.some(m => m.variants.some(v => v.isFinal)) || this.deal._offers.some(o => o._id !== this.offer._id && !o.isCanceled && o.modelations.some(m => m.variants.some(v => v.isFinal)))) {
        this.offersService.variants.at(index).get('isFinal').patchValue(true);
      } else {
        variant.get('isConfirmed').patchValue(false);
      }
    } else if (variant.get('isFinal').value) {
      variant.get('isConfirmed').patchValue(false);
      variant.get('isFinal').patchValue(false);
    }
    this.offersService.offerForm.markAsDirty();
  }

  public hideVariant(index) {
    const variant = this.offersService.variants.at(index);
    if (!this.offersService.offerForm.get('modelations').value.some(m => m.variants.some(v => v.isConfirmed)) || this.deal._offers.some(o => o._id !== this.offer._id && !o.isCanceled && o.modelations.some(m => m.variants.some(v => v.isConfirmed)))) {
      variant.get('isHidden').patchValue(!variant.get('isHidden').value);
      this.offersService.offerForm.markAsDirty();
    }
  }

  public selectCandidate(index) {
    const variant = this.offersService.variants.at(index);
    if (!this.offersService.offerForm.get('modelations').value.some(m => m.variants.some(v => v.isConfirmed)) || this.deal._offers.some(o => o._id !== this.offer._id && !o.isCanceled && o.modelations.some(m => m.variants.some(v => v.isConfirmed)))) {
      variant.get('isCandidate').patchValue(!variant.get('isCandidate').value);
      this.offersService.offerForm.markAsDirty();
    }
  }

  public trackByFn(index: any, item: any) {
    return index;
  }

  public async downloadAttachment(filename) {
    await this.filesService.get(filename);
  }

  public removeAttachment(product) {
    this.dialogsService.confirm('Odstranit přílohu', 'Chcete opravdu odstranit tuto přílohu?').subscribe((confirm) => {
      if (confirm) {
        product.get('attachment').setValue(null);
        product.get('attachment').markAsDirty();
      }
    });
  }

  public onFileSelect(event, product) {
    event.preventDefault();
    event.stopPropagation();
    const files = event.target.files || event.dataTransfer.files;
    if (!files || !files.length) {
      return;
    }
    this.filesService.create(files[0]).subscribe(
      (response) => {
        product.get('attachment').setValue(response.id);
        product.get('attachment').markAsDirty();
        this.snackbarService.showSuccess('Příloha byla přidána.');
      },
      (err) => {
        this.snackbarService.showError('Příloha nemohla být přidána.');
      });
  }

  public options(type: string): OptionInterface[] {
    return this.settingsService.options[type];
  }

  variantIdentifier(mIndex: number, vIndex: number) {
    return `${mIndex + 1}${String.fromCharCode(65 + vIndex)}`;
  }

  public createUipath(mIndex: number) {
    this.dialogsService.uipath(this.providersService.$items.getValue().filter((p: Provider) => p.uipath?.includes('bonita'))).subscribe(async (providersId) => {
      if (providersId?.length) {
        for (const providerId of providersId) {
          await this.uipathService.create({
            dealId: this.deal._id,
            offerId: this.offer._id,
            modelationId: this.offer.modelations[mIndex]._id,
            providerId,
            queue: 'bonita'
          });
        }
        this.snackbarService.showSuccess('Modelace byla odeslána ke kalkulaci.');
      }
    });
  }

  isUipath(mIndex: number) {
    if (this.offer.modelations[mIndex] && this.offer.modelations[mIndex]._id) {
      const modelationId = this.offer.modelations[mIndex]._id;
      return this.uipathService.$items.getValue().findIndex((ui: UipathInterface) => ui.modelationId === modelationId) > -1;
    }
    return false;
  }

  isUipathProgress(mIndex: number) {
    if (this.offer.modelations[mIndex] && this.offer.modelations[mIndex]._id) {
      const modelationId = this.offer.modelations[mIndex]._id;
      const uipaths = this.uipathService.$items.getValue().filter((ui: UipathInterface) => ui.modelationId === modelationId);
      return !uipaths.length || uipaths.some((ui: UipathInterface) => !ui.finishedAt);
    }
    return false;
  }
}
