import { Injectable } from '@angular/core';
import { Router, Resolve, RouterStateSnapshot,
  ActivatedRouteSnapshot } from '@angular/router';
import {UsersService} from '@app/services/users.service';
import {CarInsurancesService} from '@app/services/car-insurances.service';
import {CarInsurance} from '@app/models/car-insurance.model';
import {PipelinesService} from '@app/services/pipelines.service';

@Injectable()
export class PipelinesResolver implements Resolve<void> {
  constructor(
    private pipelinesService: PipelinesService
  ) {

  }

  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<void> {
    return new Promise(async (resolve) => {
      if (!this.pipelinesService.isLoaded) {
        this.pipelinesService.isLoaded = true;
        this.pipelinesService.pipelines = await this.pipelinesService.find({});
        this.pipelinesService.stages = this.pipelinesService.pipelines[0].stages;
      }
      resolve();
    });
  }
}
