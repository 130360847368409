import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    OnDestroy,
    Output,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import { PinchZoomComponent } from '@app/components/pinch-zoom/pinch-zoom.component';
import { FilesService } from '@app/services/files.service';
import { DocumentSingleFile } from '@app/interfaces/document-file.interface';

@Component({
    selector: 'app-file-preview',
    templateUrl: './file-preview.component.html',
    styleUrls: ['./file-preview.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilePreviewComponent implements OnChanges, OnDestroy {
    public zoomScale = 0;
    public canPrev = false;
    public canNext = false;
    public canZoom = false;

    public file: DocumentSingleFile;
    public fileUrl: SafeResourceUrl;
    public fileDownload: SafeResourceUrl;

    @Input() public display = false;
    @Input() public index: number = 0;
    @Input() public files: DocumentSingleFile[] = [];
    @Output() public displayChange: EventEmitter<any> = new EventEmitter();
    @Output() public dataChange: EventEmitter<boolean> = new EventEmitter();

    @ViewChild('pinchzoom', { static: false })
    public pinchZoom!: PinchZoomComponent;

    @HostListener('window:keyup', ['$event'])
    public keyEvent(event: KeyboardEvent): void {
        if (this.display) {
            if (event.key === 'ArrowLeft') {
                this.prevImage();
            }
            if (event.key === 'ArrowRight') {
                this.nextImage();
            }
            if (event.key === 'Escape') {
                this.hideDialog();
            }
        }
    }

    constructor(
        private sanitizer: DomSanitizer,
        private filesService: FilesService,
    ) {}

    public ngOnChanges(): void {
        if (this.display) {
            this.fileUrl = null;
            this.zoomScale = 1;
            this.changeImage();
        }
    }

    public ngOnDestroy(): void {
        this.displayChange.unsubscribe();
        this.dataChange.unsubscribe();
    }

    public hideDialog(): void {
        this.displayChange.emit(false);
    }

    public toggleZoom(): void {
        this.pinchZoom.toggleZoom();
    }

    public changeImage(): void {
        this.file = this.files[this.index];
        this.fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.filesService.getFileGetUrl(this.file.path));
        this.fileDownload = this.sanitizer.bypassSecurityTrustResourceUrl(this.filesService.getFileDownloadUrl(this.file.path, this.file.name));
        this.canZoom = true;
        this.canPrev = this.index > 0;
        this.canNext = this.index < this.files.length - 1;
    }

    public prevImage(): void {
        if (this.index > 0) {
            this.index--;
            this.changeImage();
        }
    }

    public nextImage(): void {
        if (this.index < this.files.length - 1) {
            this.index++;
            this.changeImage();
        }
    }

    public reload(): void {
        this.dataChange.emit(true);
        this.display = false;
    }
}
