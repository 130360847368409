import { Injectable } from '@angular/core';
import {DataService} from '@app/services/data.service';
import {Message} from '@app/models/message.model';

@Injectable({providedIn: 'root'})
export class MessagesService extends DataService<Message> {

  constructor(
  ) {
    super('messages');
    this.pagination = 20;
    this.sort = {createdAt: -1};
  }

}
