import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { Provider } from '@app/models/provider.model';

@Injectable({providedIn: 'root'})
export class ProvidersService extends DataService<Provider> {
  constructor()
  {
    super('providers');
    this.query = {
      active: true,
      $select: [
        '_id', 'active', 'city', 'createdAt', 'crn', 'name', 'phone', 'pipelines', 'shortName',
        'street', 'stringCode', 'type', 'updatedAt', 'vatNumber', 'www', 'zip', 'lowestInterestRate',
        'lastPublishAt', 'publishedVersion', 'lowestInterestRateProducts'
      ]
    };
    this.sort = {
      type: 1,
      lowestInterestRate: 1,
      name: 1
    };
    this.load(0);
  }

  public getById(id: string) {
    return this.items.find(i => i._id === id);
  }
}

