import { Injectable } from '@angular/core';
import {DataService} from './data.service';
import {Task} from '../models/task.model';

@Injectable({providedIn: 'root'})
export class TimelineMessagesService extends DataService<Task> {

  constructor(
  ) {
    super('messages');
    this.pagination = 1000;
    this.query = {
      nojoins: ['deal'], // SPEEDUP
    };
  }

}
