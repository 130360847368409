import {
  ApplicationRef,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import { HttpClient, HttpEvent, HttpEventType, HttpHeaders } from '@angular/common/http';
import { environment } from '@env/environment';
import { FileUpload } from 'primeng/fileupload';

import { FilesService } from '@app/services/files.service';
import { FileListUploadEvent } from '@app/components/file-list/file-list.component';
import { SnackbarService } from '@app/services/snackbar.service';
import { BackendService } from '@app/services/backend.service';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileUploadComponent {

  private uploadedFiles: File[];
  public progress = 0;
  public uploading = false;
  public totalFilesSize = 0;
  public totalFilesCount = 0;
  public currentFilesSize = 0;
  public currentFilesCount = 0;

  @Input() public visible = false;
  @Input() public title = '';
  @Input() public isDeliveredByUs = false;

  @Output() readonly visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() readonly uploadFile: EventEmitter<FileListUploadEvent> = new EventEmitter<FileListUploadEvent>();

  @ViewChild('fileUpload', {static: false}) public fileUpload!: FileUpload;

  constructor(
    private appRef: ApplicationRef,
    private cd: ChangeDetectorRef,
    private filesService: FilesService,
    private snackbarService: SnackbarService,
    private backendService: BackendService,
    private http: HttpClient) {
  }

  public closePanel(): void {
    this.fileUpload.clear();
    this.visibleChange.emit(false);
  }

  public async upload(event: any): Promise<boolean> {
    this.cd.detectChanges();
    this.uploading = true;
    this.progress = 0;
    this.totalFilesSize = 0;
    this.totalFilesCount = 0;
    this.currentFilesSize = 0;
    this.currentFilesCount = 0;
    if (event.files?.length > 0) {
      for (const file of event.files) {
        this.totalFilesSize = this.totalFilesSize + file.size;
        this.totalFilesCount++;
      }
    }

    this.uploadedFiles = [];
    if (event.files?.length > 0) {
      for (const file of event.files) {
        try {
          const res = await this.uploadOneFile(file);

          this.uploadedFiles.push(file);
          this.uploadFile.emit({response: res, isDeliveredByUs: this.isDeliveredByUs});
        } catch (e) {
          console.log('error uploading file', file, e);
          throw (e);
        }
        this.currentFilesSize = this.currentFilesSize + file.size;
        this.currentFilesCount++;
        this.progress = Math.round((this.currentFilesSize / this.totalFilesSize) * 100);
        this.cd.detectChanges();
      }
    }

    let notUploaded = [];
    if (this.uploadedFiles?.length > 0) {
      for (const file of this.uploadedFiles) {
        const index: number = this.fileUpload.files.findIndex((f: File) => f.name === file.name && f.size === file.size);
        if (index >= 0) {
          this.fileUpload.remove(event, index);
        }
        notUploaded = [...this.fileUpload.files];
      }
    }

    this.uploading = false;

    this.fileUpload.clear();
    this.fileUpload.files = notUploaded.slice();

    if (notUploaded.length === 0) {
      this.snackbarService.showSuccess('Soubory byly přidány.');
      this.visibleChange.emit(false);
    }
    return true;
  }

  public uploadOneFile(file: File) {
    return new Promise<any>((resolve, reject) => {
      const url = environment.backendUrl + '/files/upload';
      const body: FormData = new FormData();
      body.append('uri', file, encodeURIComponent(file.name));
      const headers = new HttpHeaders({
        Authorization: this.backendService.accessToken,
      });
      this.cd.detectChanges();

      this.http.request('POST', url, {
        body,
        headers,
        reportProgress: true,
        observe: 'events',
        withCredentials: true
      }).subscribe((event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.Sent:
            break;
          case HttpEventType.Response:
            resolve(event.body);
            break;
          case HttpEventType.UploadProgress:
            this.progress = Math.round(((this.currentFilesSize + event.loaded) / this.totalFilesSize) * 100);
            this.cd.detectChanges();
            break;
        }
      }, (error) => {
        console.log('upload failed', file.name, error);
        reject(error);
      });
    });
  }
}
