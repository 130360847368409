<ng-container *ngIf="display">
  <div class="file-preview-backdrop">
    <div class="file-preview">

      <button mat-mini-fab (click)="prevImage()" *ngIf="canPrev" class="btn btn-prev">
        <mat-icon>chevron_left</mat-icon>
      </button>

      <button mat-mini-fab (click)="nextImage()" *ngIf="canNext" class="btn btn-next">
        <mat-icon>chevron_right</mat-icon>
      </button>

      <button mat-mini-fab (click)="hideDialog()" class="btn-close">
        <mat-icon>close</mat-icon>
      </button>

      <button mat-mini-fab *ngIf="file.type === 'image'" (click)="toggleZoom()" class="btn btn-zoom">
        <mat-icon>{{zoomScale > 1 ? 'zoom_out' : 'zoom_in'}}</mat-icon>
      </button>

      <a mat-mini-fab [href]="fileDownload" class="btn btn-download">
        <mat-icon>cloud_download</mat-icon>
      </a>

      <div class="doc-header" *ngIf="file.type !== 'image'">
        {{ file.name }}
      </div>

      <ng-container *ngIf="file.type === 'image'">
        <app-pinch-zoom #pinchzoom [height]="'100%'" [zoom-button]="false" [(scale)]="zoomScale">
          <img [src]="fileUrl" [alt]="file.name"/>
        </app-pinch-zoom>
      </ng-container>

      <ng-container *ngIf="file.type === 'pdf'">
        <object [data]="fileUrl" type="application/pdf" width="100%" height="100%">
          <p>Váš prohlížeč nepodporuje zobrazení PDF dokumentů.</p>
        </object>
      </ng-container>

    </div>
  </div>
</ng-container>
