import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fileListIsPlatform',
  pure: true,
})
export class FileListIsPlatformPipe implements PipeTransform {

  transform(filesInfo: any, value: boolean): boolean {
    return !!filesInfo.isPlatformChange === value;
  }
}
