import { Pipe, PipeTransform } from '@angular/core';
import {Property} from '@app/models/property.model';
import {AbstractControl, FormArray} from '@angular/forms';

@Pipe({ name: '_interventionForm' })
export class InterventionGetFormPipe implements PipeTransform {
  constructor() {}
  transform(interventionId: string, interventions: FormArray) {
    return interventions.controls.find((p: AbstractControl) => p.get('_id')?.value === interventionId) || null;
  }
}
