import { Injectable } from '@angular/core';
import {DataService} from './data.service';
import {AbstractControl, FormArray, FormBuilder, FormGroup, ValidatorFn, Validators} from '@angular/forms';
import {DialogsService} from '@app/services/dialogs.service';
import {MainService} from '@app/services/main.service';
import {Household} from '@app/models/household.model';

@Injectable({providedIn: 'root'})
export class HouseholdsService extends DataService<Household> {

  constructor(
    private fb: FormBuilder,
    private dialogsService: DialogsService,
    private mainService: MainService
  ) {
    super('households');
  }

  public createForm(household?, offerStage?, _applicantsForm?): FormGroup {
    const form = this.fb.group({
      _id: [],
      type: [null, [Validators.required, this.mainService.requireSelectionValidator('householdType')]],
      other: [null],
      address: this.fb.group({
        street: [null],
        orientationNumber: [null],
        houseNumber: [null],
        zipCode: [null, Validators.required],
        city: [null],
        district: [null],
        region: [null], // this.mainService.requireSelectionValidator('region')],
        country: ['CZE', [this.mainService.requireSelectionValidator('country')]],
      }),
      members: this.fb.array([], [this.householdMembersValidator(_applicantsForm)])
    });
    if (household) {
      form.patchValue(household);
      for (const member of household.members || []) {
        this.householdMembers(form).push(this.createHouseholdMemberForm(member));
      }
    }
    return form;
  }

  public createHouseholdMemberForm(member) {
    const form = this.fb.group({
      type: [null, [Validators.required, this.mainService.requireSelectionValidator('memberType')]],
      age: [null, Validators.required]
    });
    if (member) {
      form.patchValue(member);
    }
    return form;
  }

  public addHouseholdMember(control) {
    this.householdMembers(control).push(this.createHouseholdMemberForm(null));
    control.markAllAsTouched();
    control.markAsDirty();
  }

  public removeHouseholdMember(control, mmIndex) {
    this.dialogsService.confirm('Smazat člena', 'Chcete opravdu smazat tohoto člena?').subscribe((confirm) => {
      if (confirm) {
        this.householdMembers(control).removeAt(mmIndex);
        control.markAsDirty();
      }
    });
  }

  public householdMembers(control) {
    return control.get('members') as FormArray;
  }

  public householdMembersCount(control, _applicants) {
    return this.householdMembers(control).controls?.length + _applicants.reduce((acc, _applicant) => {
      acc += _applicant.get('households').value.includes(control.get('_id').value) ? 1 : 0;
      return acc;
    }, 0);
  }

  public householdMembersValidator(_applicants): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      const householdControl = control.parent;
      const householdApplicants = _applicants.value.filter(a => a.households.includes(householdControl?.get('_id')?.value));
      if (householdApplicants.length) {
        if (householdApplicants.some(a => a.incomes.some(i => i.type === 'alimony')) && !control.value.length) {
          return {alimony: true};
        }
      }
      return null;
    };
  }

  public householdApplicantsValidator(): ValidatorFn { // TODO V-P Pokud je jeden v domacnosti a je zenatej.
    return (control: AbstractControl): any | null => {
      // if (control.value?.length === 1) {
      //   const applicant = this.applicants.getRawValue().find(a => a.contactId === control.value[0]);
      //   if (['married', 'registered'].includes(applicant?.contact?.familyStatus)) {
      //     return {familyStatus: true};
      //   }
      // }
      return(null);
    };
  }
}
