import { NgModule } from '@angular/core';
import { CaseVariantsChangesPipe } from '@app/pipes/case-variants-changes.pipe';
import { DateWordPipe } from '@app/pipes/date-word.pipe';
import { PipelineProductPipe } from '@app/pipes/pipeline-product.pipe';
import { TemplateIsInternalPipe } from '@app/pipes/template-is-internal.pipe';
import { DocumentIsInternalPipe } from '@app/pipes/document-is-internal.pipe';
import { SumObjPropsPipe } from '@app/pipes/sum-obj-props.pipe';

@NgModule({
  declarations: [
    CaseVariantsChangesPipe,
    DateWordPipe,
    PipelineProductPipe,
    TemplateIsInternalPipe,
    DocumentIsInternalPipe,
    SumObjPropsPipe,
  ],
  imports: [
  ],
  providers: [
  ],
  exports: [
    CaseVariantsChangesPipe,
    DateWordPipe,
    PipelineProductPipe,
    TemplateIsInternalPipe,
    DocumentIsInternalPipe,
    SumObjPropsPipe,
  ]
})
export class PipesModule {}
