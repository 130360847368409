import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { DialogsService } from '@app/services/dialogs.service';
import Quill from 'quill';

/*********************************************************************************************************/
/**  Pozor rich editor vyuziva knihovnu Quill.js, pokud se provede upgrade na v2, prestane fungovat :-( **/
/*********************************************************************************************************/

@Component({
  selector: 'app-rich-editor',
  templateUrl: './rich-editor.component.html',
  styleUrls: ['./rich-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RichEditorComponent implements OnChanges {
  public tmp: string;
  public quill: Quill;

  @Input() visible: boolean;
  @Input() text: string;
  @Output() readonly visibleChange: EventEmitter<boolean> = new EventEmitter();
  @Output() readonly saveText: EventEmitter<string> = new EventEmitter();

  constructor(private dialogsService: DialogsService) {
  }

  public ngOnChanges(changes: SimpleChanges): void {
    this.tmp = this.text;
  }

  public closeDialog(save: boolean): void {
    if (save) {
      this.saveText.emit(this.tmp);
      this.visible = false;
      this.visibleChange.emit(false);
    } else if (this.text !== this.tmp) {
      this.dialogsService.confirm('Neuložené změny', 'Chcete uložit změny?').subscribe((confirm) => {
        if (confirm) {
          this.saveText.emit(this.tmp);
        }
        this.visible = false;
        this.visibleChange.emit(false);
      });
    } else {
      this.visible = false;
      this.visibleChange.emit(false);
    }
  }

  public initEditor($event: any): void {
    this.quill = $event.editor;
    setTimeout(() => {
      this.quill.focus();
      this.quill.setSelection(this.quill.getLength(), 0);
    }, 100);
  }
}
