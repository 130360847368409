import { Routes } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { LoginGuard } from './guards/login.guard';
import { MainComponent } from './components/main.component';
import { DealsComponent } from './components/deals/deals.component';
import { DealComponent } from './components/deals/deal.component';
import { DealResolver } from './resolvers/deal.resolver';
import { OfferComponent } from './components/deals/offer.component';
import { OfferModelationsComponent } from './components/deals/offer-modelations.component';
import { OfferResolver } from './resolvers/offer.resolver';
import { CanDeactivateGuard } from './guards/deactivate.guard';
import { OfferFormComponent } from './components/deals/offer-form.component';
import { OfferDocumentsComponent } from './components/deals/offer-documents.component';
import { SalesComponent } from './components/sales/sales.component';
import { SettingsComponent } from './components/settings/settings.component';
import { CalculationsComponent } from './components/calculations/calculations.component';
import { DealTimelineComponent } from './components/deals/deal-timeline.component';
import { DealTasksComponent } from './components/deals/deal-tasks.component';
import { OfferCommissionsComponent } from '@app/components/deals/offer-commissions.component';
import { PipelinesResolver } from '@app/resolvers/pipelines.resolver';
import { CanDeactivateFormGuard } from '@app/guards/deactivate-form.guard';

export const ROUTES: Routes = [
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [LoginGuard]
  },
  {
    path: '',
    component: MainComponent,
    resolve: {
      pipelines: PipelinesResolver,
    },
    children: [
      // {
      //   path: 'dashboard',
      //   component: DashboardComponent,
      //   canActivate: [LoginGuard],
      // },
      {
        path: 'dashboard',
        redirectTo: 'deals',
        pathMatch: 'full'
      },
      {
        path: 'deals/list',
        component: DealsComponent,
        canActivate: [LoginGuard],
      },
      {
        path: 'deals/board',
        canActivate: [LoginGuard],
        loadChildren: () => import('./modules/board/board.module').then(m => m.BoardModule)
      },
      {
        path: 'deals/:dealId',
        component: DealComponent,
        runGuardsAndResolvers: 'always', // TODO nevim proc to tu je ale urcite je to potreba
        canActivate: [LoginGuard],
        resolve: {
          deal: DealResolver
        },
        children: [
          {
            path: 'offers',
            component: OfferComponent,
            children: [
              {
                path: ':offerId/timeline',
                component: DealTimelineComponent,
                resolve: {
                  offer: OfferResolver
                },
                canDeactivate: [CanDeactivateGuard]
              },
              {
                path: ':offerId/tasks',
                component: DealTasksComponent,
                resolve: {
                  offer: OfferResolver
                },
                canDeactivate: [CanDeactivateFormGuard]
              },
              {
                path: ':offerId/modelations',
                component: OfferModelationsComponent,
                resolve: {
                  offer: OfferResolver
                },
                canDeactivate: [CanDeactivateGuard]
              },
              {
                path: ':offerId/edit',
                component: OfferFormComponent,
                resolve: {
                  offer: OfferResolver
                },
                canDeactivate: [CanDeactivateGuard]
              },
              {
                path: ':offerId/documents',
                component: OfferDocumentsComponent,
                resolve: {
                  offer: OfferResolver
                },
                canDeactivate: [CanDeactivateGuard]
              },
              {
                path: ':offerId/commissions',
                component: OfferCommissionsComponent,
                resolve: {
                  offer: OfferResolver
                },
                canDeactivate: [CanDeactivateGuard]
              },
              {
                path: '',
                redirectTo: '0/timeline',
                pathMatch: 'full'
              },
              {
                path: '**',
                redirectTo: '0/timeline',
                pathMatch: 'full'
              }
            ]
          },
          {
            path: '**',
            redirectTo: 'offers/0/timeline',
            pathMatch: 'full'
          }
        ]
      },
      {
        path: 'products',
        canActivate: [LoginGuard],
        loadChildren: () => import('./modules/products/products.module').then(m => m.ProductsModule)
      },
      {
        path: 'providers',
        canActivate: [LoginGuard],
        loadChildren: () => import('./modules/providers/providers.module').then(m => m.ProvidersModule)
      },
      {
        path: 'users',
        canActivate: [LoginGuard],
        loadChildren: () => import('./modules/users/users.module').then(m => m.UsersModule)
      },
      {
        path: 'sales',
        component: SalesComponent,
        canActivate: [LoginGuard],
      },
      {
        path: 'settings',
        component: SettingsComponent,
        canActivate: [LoginGuard],
      },
      {
        path: 'calculations',
        component: CalculationsComponent,
        canActivate: [LoginGuard],
      },
      {
        path: 'documents',
        canActivate: [LoginGuard],
        loadChildren: () => import('./modules/documents/documents.module').then(m => m.DocumentsModule)
      },
      {
        path: 'tasks',
        canActivate: [LoginGuard],
        loadChildren: () => import('./modules/tasks/tasks.module').then(m => m.TasksModule)
      },
      {
        path: 'campaigns',
        canActivate: [LoginGuard],
        loadChildren: () => import('./modules/campaigns/campaigns.module').then(m => m.CampaignsModule)
      },
      {
        path: 'contacts',
        canActivate: [LoginGuard],
        loadChildren: () => import('./modules/contacts/contacts.module').then(m => m.ContactsModule)
      },
      {
        path: 'cases',
        canActivate: [LoginGuard],
        loadChildren: () => import('./modules/cases/cases.module').then(m => m.CasesModule)
      },
      {
        path: 'clients/:clientId',
        canActivate: [LoginGuard],
        loadChildren: () => import('./modules/clients/client.module').then(m => m.ClientModule)
      },
      {
        path: 'cases/mortgage/:caseId',
        canActivate: [LoginGuard],
        loadChildren: () => import('./modules/cases/mortgage/mortgage.module').then(m => m.MortgageModule)
      },
      {
        path: 'cases/real-estate-insurance/:caseId',
        canActivate: [LoginGuard],
        loadChildren: () => import('./modules/cases/real-estate-insurance/real-estate-insurance.module').then(m => m.RealEstateInsuranceModule)
      },
      {
        path: 'cases/car-insurance/:caseId',
        canActivate: [LoginGuard],
        loadChildren: () => import('./modules/cases/car-insurance/car-insurance.module').then(m => m.CarInsuranceModule)
      },
      {
        path: 'cases/life-insurance/:caseId',
        canActivate: [LoginGuard],
        loadChildren: () => import('./modules/cases/life-insurance/life-insurance.module').then(m => m.LifeInsuranceModule)
      },
      {
        path: '',
        redirectTo: 'deals/list',
        pathMatch: 'full'
      }
    ]
  },
  {
    path: '',
    redirectTo: 'deals/list',
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: 'deals/list',
    pathMatch: 'full'
  }
];
