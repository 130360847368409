import { Pipe, PipeTransform } from '@angular/core';
import { Call } from '@app/models/calls.model';

@Pipe({ name: 'callsContactName'})
export class CallsContactNamePipe implements PipeTransform {
  constructor() {}
  transform(call: Call) {
    let r = '';
    if (call._contact?.firstName || call._contact?.lastName) {
      r = ((call._contact?.firstName || '') + ' ' + (call._contact?.lastName || '')).trim();
    } else if (call._deals && call._deals.length) {
      call._deals.forEach((deal) => {
        if (deal.name && deal.name !== 'undefined undefined') {
          r = deal.name;
        }
      });
    }
    if (r === '') {
      r = call.phoneNumber;
    }
    return r;
  }
}
