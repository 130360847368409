import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'dateWord'
})
export class DateWordPipe implements PipeTransform {
  constructor(
    private datePipe: DatePipe,
  ) {
  }
  transform(value: string | Date): string {
    const date = new Date(value);
    const t = new Date(value);
    date.setHours(0,0);
    const today = new Date();
    const tomorrow = new Date();
    tomorrow.setDate(today.getDate() + 1);
    const dayAfterTomorrow = new Date();
    dayAfterTomorrow.setDate(today.getDate() + 2);
    const yesterday = new Date();
    yesterday.setDate(today.getDate() - 1);
    const dayBeforeYesterday = new Date();
    dayBeforeYesterday.setDate(today.getDate() - 2);

    const time = ` ${this.datePipe.transform(t, 'HH:mm')}`;

    if (this.isSameDay(date, today)) {
      return `Dnes${time}`;
    } else if (this.isSameDay(date, tomorrow)) {
      return `Zítra${time}`;
    } else if (this.isSameDay(date, dayAfterTomorrow)) {
      return `Pozítří${time}`;
    } else if (this.isSameDay(date, yesterday)) {
      return `Včera${time}`;
    } else if (this.isSameDay(date, dayBeforeYesterday)) {
      return `Předevčírem${time}`;
    } else {
      return `${this.datePipe.transform(date, 'dd.MM.yyyy')}${time}`;
    }
  }

  private isSameDay(date1: Date, date2: Date): boolean {
    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    );
  }
}
