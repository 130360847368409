<div role="group" class="app-input-number-container"
     [attr.aria-labelledby]="_formField?.getLabelId()"
     (focusin)="onFocusIn($event)"
     (focusout)="onFocusOut($event)">
    <input class="app-input-number-input"
           #input
           role="spinbutton"
           [ngStyle]="inputStyle"
           [class]="inputStyleClass"
           [value]="formattedValue()"
           [disabled]="disabled"
           [readonly]="readonly"
           [attr.size]="size"
           [attr.name]="name"
           [attr.autocomplete]="autocomplete"
           [attr.maxlength]="maxlength"
           [attr.tabindex]="tabindex"
           [attr.required]="required"
           [attr.min]="min"
           [attr.max]="max"
           inputmode="decimal"
           (input)="onUserInput($event)"
           (keydown)="onInputKeyDown($event)"
           (keypress)="onInputKeyPress($event)"
           (paste)="onPaste($event)"
           (click)="onInputClick()"
           (focus)="onInputFocus($event)"
           (blur)="onInputBlur($event)"
    >
</div>
