<div class="widget-wrapper" [ngClass]="{'is-opened': opened, disconnected: !usersService.user.daktelaConnected, active: callsService.activeCall$ | async, incoming: ((callsService.incomingCall$ | async) && !(callsService.activeCall$ | async))}" [class]="status">
  <div class="widget-header" [matBadgeHidden]="((missedCallsService.total$ | async) + (missedConversationsService.total$ | async)) < 1" [matBadge]="(missedCallsService.total$ | async) + (missedConversationsService.total$ | async)" matBadgeColor="warn" matBadgeSize="large" matBadgePosition="above before">
    <div class="widget-header-basic" matRipple (click)="toggle()">
      <div class="widget-header-text">
        <ng-container *ngIf="!(callsService.activeCall$ | async) && !(callsService.incomingCall$ | async) && usersService.user.daktelaConnected">
          <div class="widget-header-text-line widget-header-text-center"><b>Telefonní linka</b>&nbsp;{{usersService.user.phone}}</div>
        </ng-container>
        <ng-container *ngIf="!(callsService.activeCall$ | async) && !(callsService.incomingCall$ | async) && !usersService.user.daktelaConnected">
          <div class="widget-header-text-line widget-header-text-center"><b>Pozor, telefonní linka není připojena.</b></div>
        </ng-container>
        <ng-container *ngIf="((callsService.incomingCall$ | async) && !(callsService.activeCall$ | async)) || (callsService.activeCall$ | async)">
          <div class="widget-header-text-line call-info" *ngIf="((callsService.activeCall$ | async) || (callsService.incomingCall$ | async)) as call">
            <a *ngIf="call._contact" (click)="$event.stopPropagation()" [routerLink]="['/contacts', call._contact._id]"><b>{{(call | callsContactName)}}</b></a>
            <span *ngIf="!call._contact"><b>{{call.phoneNumber}}</b></span>
            <span *ngIf="((callsService.incomingCall$ | async) && !(callsService.activeCall$ | async))">{{(callsService.incomingCall$ | async).direction === 'in' ? 'příchozí' : 'odchozí'}} hovor</span>
            <span *ngIf="callsService.activeCall$ | async">{{(callsService.activeCall$ | async)?.answeredAt | elapsedTime}}</span>
          </div>
          <ng-container *ngIf="((callsService.activeCall$ | async) || (callsService.incomingCall$ | async))?._deals as _deals">
            <ng-container *ngFor="let _deal of _deals;let dIndex = index">
              <div class="widget-header-text-line" *ngIf="opened || dIndex === 0"><a (click)="$event.stopPropagation()" [routerLink]="_deal._pipelineUrl === 'mortgage' ? ['/deals', _deal._id,'offers'] : ['/cases', _deal._pipelineUrl, _deal._id]">{{ _deal | caseNumber }}</a>&nbsp;-&nbsp;<b>{{_deal.stage | caseStage:pipelinesService.getPipeline(_deal._pipelineUrl)?.stages}}</b><span *ngIf="_deal.amount">&nbsp;-&nbsp;{{mainService.maskCurrency(_deal.amount)}} Kč</span></div>
            </ng-container>
          </ng-container>
        </ng-container>
      </div>
      <button mat-icon-button class="widget-header-button">
        <mat-icon *ngIf="opened">keyboard_arrow_down</mat-icon>
        <mat-icon *ngIf="!opened">keyboard_arrow_up</mat-icon>
      </button>
    </div>
    <ng-container *ngIf="(callsService.incomingCall$ | async) && !(callsService.activeCall$ | async) && opened">
      <div class="widget-header-actions">
        <button mat-flat-button class="widget-header-button" (click)="callsService.ignoreCall(callsService.incomingCall); $event.stopPropagation()">
          <span>Ignorovat</span><mat-icon>close</mat-icon>
        </button>
        <button mat-flat-button class="widget-header-button" color="warn" (click)="callsService.hangUpCall(callsService.incomingCall); $event.stopPropagation()">
          <span>Ukončit</span><mat-icon>call_end</mat-icon>
        </button>
      </div>
    </ng-container>
    <ng-container *ngIf="(callsService.activeCall$ | async) && opened">
      <div class="widget-header-actions">
        <button mat-flat-button class="widget-header-button" color="warn" (click)="callsService.hangUpCall(callsService.activeCall); $event.stopPropagation()">
          <span>Ukončit</span><mat-icon>call_end</mat-icon>
        </button>
        <button mat-flat-button class="widget-header-button" (click)="$event.stopPropagation()" [matMenuTriggerFor]="dealsMenu">
          <span>Nová hypotéka</span><mat-icon>add</mat-icon>
        </button>
        <button mat-flat-button class="widget-header-button" (click)="$event.stopPropagation()" [matMenuTriggerFor]="casesMenu">
          <span>Nové pojištění</span><mat-icon>add</mat-icon>
        </button>
      </div>
    </ng-container>

  </div>
  <div class="widget-content">
    <mat-tab-group mat-stretch-tabs animationDuration="0ms" color="primary" #tabs>
      <mat-tab>
        <ng-template mat-tab-label>
          <span>Historie</span>
          <mat-icon>history</mat-icon>
          <span *ngIf="(missedCallsService.total$ | async) > 0" class="missed">{{(missedCallsService.total$ | async)}}</span>
        </ng-template>
        <app-calling-widget-history></app-calling-widget-history>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <span>Textové zprávy</span>
          <mat-icon>comment</mat-icon>
          <span *ngIf="(missedConversationsService.total$ | async) > 0" class="missed">{{(missedConversationsService.total$ | async)}}</span>
        </ng-template>
        <app-calling-widget-sms></app-calling-widget-sms>
      </mat-tab>
      <mat-tab #tab2>
        <ng-template mat-tab-label>
          <span>Kontakty</span>
          <mat-icon>contacts</mat-icon>
        </ng-template>
        <app-calling-widget-contacts [tabActivated]="tab2.isActive"></app-calling-widget-contacts>
      </mat-tab>
    </mat-tab-group>
    <div class="widget-loader" *ngIf="callingWidgetService.loading$ | async"><mat-spinner></mat-spinner></div>
  </div>
</div>

<mat-menu #dealsMenu="matMenu">
  <ng-template matMenuContent>
    <ng-container *ngIf="((callsService.activeCall$ | async) || (callsService.incomingCall$ | async))?._deals as _deals">
      <ng-container *ngFor="let _deal of _deals">
        <button *ngIf="_deal._pipelineUrl === 'mortgage'" mat-menu-item (click)="newDeal(_deal)"><mat-icon>home</mat-icon>Vytvořit z {{ _deal | caseNumber }}</button>
        <button *ngIf="_deal._pipelineUrl !== 'mortgage'" mat-menu-item (click)="newDeal(_deal)"><mat-icon>personal_injury</mat-icon>Vytvořit z {{ _deal | caseNumber }}</button>
      </ng-container>
    </ng-container>
  </ng-template>
</mat-menu>
<mat-menu #casesMenu="matMenu">
  <ng-template matMenuContent>
    <ng-container *ngIf="((callsService.activeCall$ | async) || (callsService.incomingCall$ | async))?._deals as _deals">
      <ng-container *ngFor="let _deal of _deals">
        <button *ngIf="_deal._pipelineUrl === 'mortgage'" mat-menu-item (click)="newCase(_deal)"><mat-icon>home</mat-icon>Vytvořit z {{ _deal | caseNumber }}</button>
        <button *ngIf="_deal._pipelineUrl !== 'mortgage'" mat-menu-item (click)="newCase(_deal)"><mat-icon>personal_injury</mat-icon>Vytvořit z {{ _deal | caseNumber }}</button>
      </ng-container>
    </ng-container>
  </ng-template>
</mat-menu>
