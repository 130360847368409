import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'caseNumber'})
export class CaseNumberPipe implements PipeTransform {
  constructor(
  ) {}
  transform(deal: any) {
    return (deal._pipelineUrl === 'life-insurance' ? 'Z' : deal._pipelineUrl === 'car-insurance' ? 'A' : deal._pipelineUrl === 'real-estate-insurance' ? 'M' : 'H') + deal.numberId;
  }
}
