import { Pipe, PipeTransform } from '@angular/core';
import {Provider} from '@app/models/provider.model';

@Pipe({ name: 'getProvider' })
export class ProviderGetPipe implements PipeTransform {
  constructor() {}
  transform(providerId: string, providers: Provider[]) {
    return providers.find((c: any) => c._id === providerId) || null;
  }
}
