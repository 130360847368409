import { Pipe, PipeTransform } from '@angular/core';
import { Pipeline } from '@app/models/pipeline.model';

@Pipe({ name: 'pipelineStage'})
export class PipelineStagePipe implements PipeTransform {
  constructor(
  ) {}
  transform(stage: string, pipelineId: string, pipelineService?: any) {
    return pipelineService.pipelines?.find(p => p._id === pipelineId).stages.find(s => s.id === stage);
  }
}
