import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'variantStatus', pure: false })
export class ModelationVariantsStatusPipe implements PipeTransform {
  constructor() {}
  transform(modelationControl: any, variantStatus: string) {
    const modelation = modelationControl.getRawValue();
    return modelation.variants.some(v => (variantStatus === 'candidate' && v.isCandidate) || (variantStatus === 'confirmed' && v.isConfirmed) || (variantStatus === 'final' && v.isFinal));
  }
}
