import { Pipe, PipeTransform } from '@angular/core';
import {OptionInterface} from '../interfaces/option.interface';
import {UsersService} from '@app/services/users.service';

@Pipe({ name: 'getUser' })
export class GetUserPipe implements PipeTransform {
  constructor(
    private usersService: UsersService
  ) {}
  async transform(userId: string) {
    return await this.usersService.getUser(userId);
  }
}
