import { MatDialogRef } from '@angular/material/dialog';
import { Component } from '@angular/core';

@Component({
    selector: 'app-dialog',
    template: `
        <h2 mat-dialog-title>{{ title }}</h2>
        <mat-dialog-content>{{ message }}</mat-dialog-content>
    `,
})
export class ModalInfoDialogComponent {

    public title: string;
    public message: string;

    constructor(
      public dialogRef: MatDialogRef<ModalInfoDialogComponent>
    ) {

    }
}
