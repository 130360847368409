import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'casePipeline'})
export class CasePipelinePipe implements PipeTransform {
  constructor(
  ) {}
  transform(deal: any, long?: boolean) {
    if (long) {
      if (deal._pipelineUrl === 'car-insurance') {
        return 'Pojištění vozidel';
      }
      if (deal._pipelineUrl === 'life-insurance') {
        return 'Pojištění života';
      }
      if (deal._pipelineUrl === 'real-estate-insurance') {
        return 'Pojištění nemovitosti';
      }
      if (deal._pipelineUrl === 'mortgage') {
        return 'Hypotéky';
      }
      return 'Hypotéky';
    } else {
      if (deal._pipelineUrl === 'car-insurance') {
        return 'PV';
      }
      if (deal._pipelineUrl === 'life-insurance') {
        return 'PŽ';
      }
      if (deal._pipelineUrl === 'real-estate-insurance') {
        return 'PN';
      }
      if (deal._pipelineUrl === 'mortgage') {
        return 'H';
      }
      return 'H';
    }
  }
}
