import { MatDialogRef } from '@angular/material/dialog';
import {AfterViewInit, Component} from '@angular/core';

@Component({
  selector: 'app-dialog',
  template: `
    <h2 mat-dialog-title>Zamítnout případ</h2>
    <mat-dialog-content>
      <div class="form-row" *ngFor="let opts of this.reasonOptions; let rIndex = index">
        <mat-form-field>
          <mat-select placeholder="Vyber možnost" [(ngModel)]="reasons[rIndex]" (selectionChange)="updateOptions(rIndex)">
            <ng-container *ngFor="let option of opts">
              <mat-option [value]="option.id">{{ option.label }}</mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions>
      <button type="button" mat-button color="warn" (click)="dialogRef.close(null)">Zrušit</button>
      <button type="button" mat-raised-button color="primary" [disabled]="reasons.length !== reasonOptions.length" (click)="dialogRef.close(reasons)">Potvrdit</button>
    </mat-dialog-actions>
  `,
})

export class CancelCaseDialogComponent implements AfterViewInit {

  public options: any[];
  public reasons: string[];
  public reasonOptions: any[];

  constructor(
    public dialogRef: MatDialogRef<CancelCaseDialogComponent>,
  ) {
    this.options = [];
    this.reasons = [];
    this.reasonOptions = [];
  }

  ngAfterViewInit() {
    this.reasonOptions = [this.options];
  }

  public updateOptions(index) {
    this.reasons.splice(index + 1);
    this.reasonOptions.splice(index + 1);
    if (this.reasonOptions[index].find(o => o.id === this.reasons[index])?.next) {
       this.reasonOptions.push(this.reasonOptions[index].find(o => o.id === this.reasons[index]).next);
    }
  }
}
