import { MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MainService } from '@app/services/main.service';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { UsersService } from '@app/services/users.service';

@Component({
  selector: 'app-dialog',
  templateUrl: './choose-owner-dialog.component.html',
})
export class ChooseOwnerDialogComponent implements OnInit {

  public owners: any[];
  public groups: any[];
  public ownerId: string;
  public oldOwnerId: string;
  public assigned: any;
  public filteredOwners: any[];
  public options: any;

  @ViewChild(MatAutocompleteTrigger) autocompleteTrigger: MatAutocompleteTrigger;

  constructor(
    public dialogRef: MatDialogRef<ChooseOwnerDialogComponent>,
    protected mainService: MainService,
    public usersService: UsersService,
  ) {
  }

  ngOnInit() {
    this.filterOwners('');
    if (this.ownerId) {
      this.assigned = this.filteredOwners.find((item) => item._id === this.ownerId);
      this.oldOwnerId = this.ownerId;
    } else {
      this.assigned = null;
      this.oldOwnerId = null;
    }
  }

  public userDisplayFn(user: any): string {
    return user?.name ?? '';
  }

  public filterOwners(event: any): void {
    if (event._id) {
      this.ownerId = event._id;
    } else {
      const filterValue = this.mainService.removeDiacritics(event.toLowerCase().trim());
      const users = this.owners
        .map((user) => { return { _id: user._id, name: (user.firstName ?? '') + ' ' + (user.lastName ?? '') }; } )
        .filter(option => this.mainService.removeDiacritics(option.name.toLowerCase()).includes(filterValue))
        .sort((a, b) => a.name.localeCompare(b.name, 'cs', { sensitivity: 'base' } ));
      const groups = this.groups
        .filter(option => this.mainService.removeDiacritics(option.name.toLowerCase()).includes(filterValue))
        .sort((a, b) => a.name.localeCompare(b.name, 'cs', { sensitivity: 'base' } ));
      this.filteredOwners = [...users, ...groups];
    }
  }

  public openPanel(): void {
    this.filterOwners('');
    this.autocompleteTrigger.openPanel();
  }

  public closeDialog(): void {
    this.dialogRef.close(this.ownerId);
  }

  public assignToMe() {
    this.dialogRef.close(this.usersService.user._id);
  }
}
