import { Injectable } from '@angular/core';
import { BackendService } from '@app/services/backend.service';

@Injectable()
export class MagicLinksService {
  private rest: any;

  constructor(
    private backendService: BackendService
  ) {
    this.rest = this.backendService.getService('magic-links');
  }

  public create(item: any, params: any = {}) {
    return this.rest.create(item, params);
  }

}
