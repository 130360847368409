import { MatDialogRef } from '@angular/material/dialog';
import {Component} from '@angular/core';

@Component({
  selector: 'app-dialog',
  template: `
    <h2 mat-dialog-title>Obnovit případ</h2>
    <mat-dialog-content>
      <div class="form-row">
        <mat-form-field>
          <mat-select placeholder="Vyber stav obnovení" [(ngModel)]="result">
            <ng-container *ngFor="let stage of options">
              <mat-option [value]="stage.id">{{ stage.label }}</mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions>
      <button type="button" mat-button color="warn" (click)="dialogRef.close(null)">Zrušit</button>
      <button type="button" mat-raised-button color="primary" [disabled]="!result" (click)="dialogRef.close(result)">Potvrdit</button>
    </mat-dialog-actions>
  `,
})

export class RestoreCaseDialogComponent {

  public stages: any;
  public options: any[] = [];
  public result: string;

  constructor(
    public dialogRef: MatDialogRef<RestoreCaseDialogComponent>,
  ) {
  }

  public setValues(stages, stage) {
    let option = null;
    let oIndex = 0;
    do {
      option = stages[oIndex];
      this.options.push(option);
      oIndex++;
    } while (option?.id !== stage);
    this.result = stage;
  }
}
