import { Component, ViewEncapsulation } from '@angular/core';
import { MainService } from '@app/services/main.service';
import { UsersService } from '@app/services/users.service';
import { IncompleteTasksService } from '@app/services/incomplete-tasks.service';
import { appInfo } from '@app/modules/core.module';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class SidenavComponent {

  public appInfo = appInfo;

  constructor(
    public mainService: MainService,
    public usersService: UsersService,
    public tasksService: IncompleteTasksService,
  ) {
    this.usersService.authenticated$.subscribe(authenticated => {
      if (authenticated) {
        this.tasksService.loadCounts();
      }
    });
  }

  public toggleSidenav() {
    this.mainService.sidenavFull = !this.mainService.sidenavFull;
  }
}
