import {
  Component,
  ElementRef,
  EventEmitter, Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
} from '@angular/core';

const ActiveZone = 300; // pixels

@Component({
  selector: 'app-infinite-scroll',
  template: '',
})
export class InfiniteScrollComponent implements OnInit, OnDestroy {
  @Output() endReached = new EventEmitter<void>();
  @Input() scrollDirectionTop = false;
  private _unlisten!: () => void;
  private _endLock: boolean = false;

  constructor(private _elRef: ElementRef, private _renderer: Renderer2) {}

  ngOnInit() {
    const parent = this._elRef.nativeElement.parentElement.parentElement;
    this._unlisten = this._renderer.listen(parent, 'scroll', (e: Event) => {
      const target = e.target as HTMLElement;
      const scrollTop = target.scrollTop;

      // Check if scrolled to the top with an active zone
      const topReached = scrollTop <= ActiveZone;

      // Check if scrolled to the bottom with an active zone
      const bottomReached =
        scrollTop + target.clientHeight + ActiveZone >= target.scrollHeight;
      if (
        (this.scrollDirectionTop && topReached) ||
        (!this.scrollDirectionTop && bottomReached)
      ) {
        this.endReached.emit();
        this._endLock = true;
      } else if (!topReached && !bottomReached) {
        this._endLock = false;
      }
    });
  }

  ngOnDestroy() {
    this._unlisten();
  }
}
