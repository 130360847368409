import { MatDialogRef } from '@angular/material/dialog';
import {AfterViewInit, Component, OnInit} from '@angular/core';
import {UsersService} from '../../services/users.service';
import {UserInterface} from '../../interfaces/user.interface';
import {AffiliatesService} from '@app/services/affiliates.service';
import {BehaviorSubject, Observable} from 'rxjs';
import {ContactsService} from '@app/services/contacts.service';
import {map} from 'rxjs/operators';
import { Contact } from '@app/models/contact.model';
import {DatePipe} from '@angular/common';

@Component({
  selector: 'app-dialog',
  template: `
    <h2 mat-dialog-title><span>Nový případ</span></h2>
    <form #newDealForm="ngForm">
      <mat-dialog-content>
          <ng-container *ngIf="!existingContact">
            <div class="form-row">
              <mat-form-field>
                <mat-select placeholder="Předvolba" [(ngModel)]="newDeal.contacts.phoneCountryCode" name="contactPhoneCountryCode" (ngModelChange)="filterContacts(newDealForm.control)">
                  <mat-option *ngFor="let option of options['phoneCountryCode']" [value]="option.value">{{ option.label }}</mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field>
                <input matInput type="number" placeholder="Telefon" [(ngModel)]="newDeal.contacts.phoneNumber" name="contactPhoneNumber" required (ngModelChange)="filterContacts(newDealForm.control)"/>
              </mat-form-field>
            </div>
            <div class="form-row">
              <mat-form-field>
                <input matInput placeholder="Email" [(ngModel)]="newDeal.contacts.email" name="contactEmail" email (ngModelChange)="filterContacts(newDealForm.control)"/>
              </mat-form-field>
            </div>
            <div class="form-row" *ngIf="(filteredContacts | async).length && !contactSelectionDone">
              <mat-form-field>
                <mat-select placeholder="Existující kontakty" [(ngModel)]="selectedContact" name="existingContacts">
                  <ng-container *ngFor="let contact of filteredContacts | async">
                    <mat-option [value]="contact._id">
                      <div class="flex flex-col">
                        <span style="line-height: 1.5em;">{{contact.label}}&nbsp;
                        </span> 
                        <small style="line-height: 1.5em;">{{contact.sublabel}}
                        </small>
                      </div>
                    </mat-option>
                  </ng-container>
                </mat-select>
              </mat-form-field>
            </div>
          </ng-container>
          <ng-container *ngIf="existingContact || contactSelectionDone">
            <div class="form-row" *ngIf="!existingContact">
              <mat-form-field>
                <input matInput placeholder="Jméno" [(ngModel)]="newDeal.contacts.name" name="contactName" required/>
              </mat-form-field>
              <mat-form-field>
                <app-input-mask placeholder="PSČ" [(ngModel)]="newDeal.contacts.zipCode" name="contactZipCode" mask="999 99" inputmode="numeric"></app-input-mask>
              </mat-form-field>
            </div>
            <div class="form-row">
              <mat-form-field>
                <mat-select placeholder="Vlastník případu" [(ngModel)]="newDeal.ownerId" name="ownerId">
                  <mat-option [value]="''">Přiřadit automaticky</mat-option>
                  <mat-option [value]="owner._id" *ngFor="let owner of owners">{{ owner.firstName }} {{ owner.lastName }}</mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field>
                <mat-select placeholder="Typ" [(ngModel)]="newDeal.type" name="type" (ngModelChange)="onTypeChange($event)">
                  <mat-option [value]="'new'">Nová hypotéka</mat-option>
                  <mat-option [value]="'refinancing'">Refinancování</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <ng-container *ngIf="newDeal.type === 'new'">
              <div class="form-row">
                <mat-form-field>
                  <input matInput type="number" placeholder="Výše úvěru" [(ngModel)]="newDeal.amount" name="amount" required/>
                </mat-form-field>
                <mat-form-field>
                  <input matInput type="number" placeholder="Hodnota nemovitosti" [(ngModel)]="newDeal.value" name="value" required/>
                </mat-form-field>
              </div>
              <div class="form-row">
                <mat-form-field class="maturity">
                  <input matInput type="number" placeholder="Doba splácení" [(ngModel)]="newDeal.maturityYears" (ngModelChange)="updateMaturity()" name="maturityYears"/><span>r</span>
                  <span class="space">, </span>
                  <input matInput type="number" [(ngModel)]="newDeal.maturityMonths" (click)="$event.stopPropagation()" #maturityMonths (ngModelChange)="updateMaturity()" name="maturityMonths" /><span (click)="maturityMonths.focus();$event.stopPropagation()">m</span>
                </mat-form-field>
                <mat-form-field>
                  <input matInput type="number" placeholder="Fixace (roky)" [(ngModel)]="newDeal.fixation" name="fixation" required/>
                </mat-form-field>
              </div>
            </ng-container>
            <ng-container *ngIf="newDeal.type === 'refinancing'">
              <div class="form-row">
                <mat-form-field>
                  <mat-select placeholder="Banka" [(ngModel)]="newDeal.refinancedProduct.bankCode" name="refinancedProductBankCode" required>
                    <mat-option [value]="option.value" *ngFor="let option of options['bank']">{{ option.label }}</mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field>
                  <input matInput type="number" placeholder="Úroková sazba (%)" [(ngModel)]="newDeal.refinancedProduct.interestRate" name="refinancedProductInterestRate" required type="number" step=".01"/>
                </mat-form-field>
              </div>
              <div class="form-row">
                <mat-form-field>
                  <input matInput type="number" placeholder="Výše úvěru" [(ngModel)]="newDeal.refinancedProduct.amount" name="refinancedProductAmount" required/>
                </mat-form-field>
                <mat-form-field>
                  <input matInput type="number" placeholder="Splátka" [(ngModel)]="newDeal.refinancedProduct.repayment" name="refinancedProductRepayment" required/>
                </mat-form-field>
                <mat-form-field>
                  <input matInput placeholder="Datum podpisu" [matDatepicker]="signatureDate" [(ngModel)]="newDeal.refinancedProduct.signatureDate" name="refinancedProductSignatureDate" required />
                  <mat-datepicker-toggle matSuffix [for]="signatureDate"></mat-datepicker-toggle>
                  <mat-datepicker #signatureDate></mat-datepicker>
                </mat-form-field>
              </div>
            </ng-container>
            <ng-container *ngIf="deal?._offers?.length">
              <div class="form-row">
                <mat-form-field>
                  <mat-select placeholder="Z nabídky" [(ngModel)]="newDeal.copyOfferId" name="copyOfferId">
                    <mat-option [value]="offer._id" *ngFor="let offer of deal._offers">N{{ offer.numberId }}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </ng-container>
            <div class="form-row">
              <mat-form-field *ngIf="newDeal.type === 'refinancing'">
                <input matInput type="number" placeholder="Fixace (roky)" [(ngModel)]="newDeal.refinancedProduct.fixation" name="refinancedProductFixation" required/>
              </mat-form-field>
              <mat-form-field *ngIf="newDeal.type === 'new'">
                <mat-select placeholder="Účel" [(ngModel)]="newDeal.purpose" name="purpose" required>
                  <ng-container *ngFor="let option of options['purpose']">
                    <mat-option [value]="option.value" *ngIf="option.value !== 'refinancing'">{{ option.label }}</mat-option>
                  </ng-container>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="form-row">
              <mat-form-field>
                <mat-select placeholder="Kdy potřebuje" [(ngModel)]="newDeal.timeHorizon" name="timeHorizon" required>
                  <mat-option [value]="option.value" *ngFor="let option of options['timeHorizon']">{{ option.label }}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <ng-container *ngIf="!deal">
              <div class="form-row">
                <mat-form-field>
                  <mat-select placeholder="Zdroj obchodu" [(ngModel)]="newDeal.sourceType" name="sourceType" (selectionChange)="sourceTypeChanged($event)">
                    <mat-option [value]="''">Firemní obchod</mat-option>
                    <mat-option [value]="'affiliate'">Affiliate partner</mat-option>
                    <mat-option [value]="'personal'">Soukromá osoba</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="form-row" *ngIf="newDeal.sourceType === 'affiliate'">
                <mat-form-field>
                  <mat-select placeholder="Partner" [(ngModel)]="newDeal.affiliateId" name="affiliateId">
                    <mat-option [value]="''">Není vybrán</mat-option>
                    <mat-option [value]="affiliate._id" *ngFor="let affiliate of affiliatesService.items$ | async">{{ affiliate.name }}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="form-row" *ngIf="newDeal.sourceType === 'personal'">
                <mat-form-field>
                  <input matInput placeholder="Soukromá osoba (email)" [(ngModel)]="newDeal.authorEmail" name="authorEmail" [matAutocomplete]="autoUser" (ngModelChange)="filterUsers($event)" />
                  <mat-autocomplete #autoUser="matAutocomplete" panelWidth="auto">
                    <mat-option *ngFor="let user of filteredUsers | async" [value]="user.email" (onSelectionChange)="newDeal.authorId = user._id">
                      {{ user.email }}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
                <mat-checkbox [(ngModel)]="newDeal.isOwnDeal" name="isOwnDeal" class="flex-0-0-auto">Vlastní hypotéka</mat-checkbox>
              </div>
            </ng-container>
          </ng-container>
      </mat-dialog-content>
      <mat-dialog-actions>
        <button type="button" mat-button color="warn" (click)="dialogRef.close(null)">Zrušit</button>
        <ng-container *ngIf="!existingContact && !contactSelectionDone">
          <button type="button" mat-raised-button color="accent" (click)="selectContact(true)" [disabled]="!selectedContact">Použít kontakt</button>
          <button type="button" mat-raised-button color="accent" (click)="selectContact(false)" [disabled]="newDealForm.control.get('contactPhoneNumber')?.invalid">Nový kontakt</button>
        </ng-container>
        <ng-container *ngIf="existingContact || contactSelectionDone">
          <button type="button" mat-raised-button color="primary" (click)="backToContactSelection()">Zpět</button>
          <button type="button" mat-raised-button color="accent" (click)="confirmDialog()" [disabled]="newDealForm.invalid">Vytvořit</button>
        </ng-container>
      </mat-dialog-actions>
    </form>
  `,
})
export class DealDialogComponent implements OnInit {

  public user: UserInterface;
  public existingContact = null;
  public deal: any;
  public offerId: string;
  public owners: any[];
  public affiliates: any[];
  public options: any;
  public newDeal: any;
  public filteredUsers: BehaviorSubject<any> = new BehaviorSubject([]);
  public filteredContacts: Observable<any> = new Observable<any>();
  public contactSelectionDone = false;
  public selectedContact = null;

  constructor(
    public dialogRef: MatDialogRef<DealDialogComponent>,
    public affiliatesService: AffiliatesService,
    private usersService: UsersService,
    public contactsService: ContactsService,
    private datePipe: DatePipe
  ) {
    this.filteredContacts = this.contactsService.items$.pipe(
      map((contacts: any[]) => contacts.map((contact: any) => {
        contact.phoneVerified = contact.phone.some((p: any) => p.isVerified);
        return contact;
      }).sort((a: any, b: any) => {
        if (a.emailVerified && a.phoneVerified && !b.emailVerified && !b.phoneVerified) {
          return -1;
        }
        if (!a.emailVerified && !a.phoneVerified && b.emailVerified && b.phoneVerified) {
          return 1;
        }

        // Secondary sorting: At least one of them is true
        if ((a.emailVerified || a.phoneVerified) && !(b.emailVerified || b.phoneVerified)) {
          return -1;
        }
        if (!(a.emailVerified || a.phoneVerified) && (b.emailVerified || b.phoneVerified)) {
          return 1;
        }
      }).map((contact: any) => {
        let fields = [];
        let subfields = [];
        fields.push(((contact.firstName || '') + ' ' + (contact.lastName || '')).trim());
        fields.push(contact.birthDate ? (new Date (contact.birthDate)).getFullYear() : '');
        fields.push(contact.email || '');
        subfields.push(contact.address?.permanent?.city || '');
        subfields.push(`(${this.datePipe.transform(contact.createdAt, 'shortDate')})`);
        fields = fields.filter((field: string) => field);
        subfields = subfields.filter((field: string) => field);
        return {
          _id: contact._id,
          label: fields.join(', ') || `K${contact.numberId}`,
          sublabel: subfields.join(', '),
          emailVerified: contact.emailVerified,
          phoneVerified: contact.phoneVerified
        };
      }).filter((contact: any) => contact.label || contact.sublabel))
    );
    this.filteredContacts.subscribe((contacts: any[]) => {
      if (contacts.length) {
        this.selectedContact = contacts[0]?._id;
      } else {
        this.selectedContact = null;
      }
    });
  }

  ngOnInit() {
    this.selectedContact = null;
    this.contactSelectionDone = false;
    this.contactsService.$items.next([]);
    this.existingContact = this.deal?._offers?.[0]._applicants?.[0] || this.deal?._contacts?.find(c => c._id === this.deal?.participants?.[0]?.contactId) || null;
    let newOwnerId = this.user.role === 'specialist' ? this.user._id : this.deal?.ownerId ? this.deal.ownerId : '';
    if (newOwnerId && !this.owners.find(o => o._id === newOwnerId)) {
      newOwnerId = '';
    }
    this.newDeal = {
      type: 'new',
      source: 'portal',
      affiliateId: '',
      authorId: null,
      ownerId: newOwnerId,
      amount: null,
      value: null,
      purpose: null,
      propertyType: null,
      fixation: 5,
      maturity: 360,
      maturityMonths: 0,
      maturityYears : 30,
      timeHorizon: null,
      solveOnline: true,
      pipeline: 'mortgage',
      contacts: {
        name: this.existingContact ? this.existingContact.firstName + ' ' + this.existingContact.lastName : null,
        zipCode: this.existingContact ? this.existingContact.address?.permanent?.zipCode : null,
        phoneCountryCode: this.existingContact ? this.existingContact.phone[0]?.countryCode : '+420',
        phoneNumber: this.existingContact ? this.existingContact.phone[0]?.number : null,
        email: this.existingContact ? this.existingContact.email : null,
      },
      whereDidHear: null,
      refinancedProduct: {
        bankCode: null,
        interestRate: null,
        amount: null,
        signatureDate: null,
        fixation: null,
        repayment: null
      },
      sourceType: '',
      isPlatform: true
    };
    if (this.deal) {
      this.newDeal.sourceDeal = {
        pipelineId: this.deal?._pipelineId,
        dealId: this.deal?._id,
        contactsId: this.existingContact ? [this.existingContact._id] : [],
        offerId: this.offerId,
        stage: this.deal?.stage
      };
    }
  }

  onTypeChange(type: string) {
    this.newDeal.purpose = type === 'refinancing' ? 'refinancing' : null;
  }

  updateMaturity() {
    const mYears = parseInt(this.newDeal.maturityYears, null) || 0;
    const mMonths = parseInt(this.newDeal.maturityMonths, null) || 0;
    this.newDeal.maturity = (mYears * 12) + mMonths;
  }

  async filterUsers(search: string) {
    if (search.length > 3) {
      const users = await this.usersService.find({
        query: {
          $limit: 20,
          email: {$regex: search, $options: 'i'}
        }
      });
      this.filteredUsers.next(users);
    } else {
      this.filteredUsers.next([]);
    }
  }

  sourceTypeChanged(event: any) {
    if (event.value !== 'affiliate') {
      this.newDeal.affiliateId = '';
    }
    if (event.value !== 'personal') {
      this.newDeal.isOwnDeal = false;
      this.newDeal.authorEmail = '';
      this.newDeal.authorId = '';
    }
  }

  public filterContacts(control: any) {
    if (!this.contactSelectionDone) {
      const countryCode = control.get('contactPhoneCountryCode')?.value;
      const phoneNumber = control.get('contactPhoneNumber')?.value;
      const email = control.get('contactEmail')?.value;
      if (phoneNumber) {
        this.contactsService.filter = {
          phone: {
            $elemMatch: {
              countryCode,
              number: phoneNumber
            }
          },
        };
        if (email) {
          this.contactsService.filter.email = email;
        }
        this.contactsService.load(0);
      }
    }
  }

  public selectContact(fill = false) {
    if (fill) {
      this.existingContact = {_id: this.selectedContact};
      this.newDeal.sourceDeal = {
        contactsId: this.existingContact ? [this.existingContact._id] : [],
      };
    }
    this.contactSelectionDone = true;
  }

  backToContactSelection() {
    this.existingContact = null;
    this.newDeal.sourceDeal = {
      contactsId: [],
    };
    this.contactSelectionDone = false;
  }

  confirmDialog() {
    if (!this.newDeal.authorId) {
      this.newDeal.authorId = this.user._id;
    }
    if (!this.newDeal.affiliateId) {
      this.newDeal.affiliateId = null;
    }
    if (!this.newDeal.ownerId) {
      this.newDeal.ownerId = null;
    }
    this.dialogRef.close(this.newDeal);
  }
}
