import { Pipe, PipeTransform } from '@angular/core';
import {Property} from '@app/models/property.model';
import {AbstractControl, FormArray} from '@angular/forms';

@Pipe({ name: 'variantIdentifier' })
export class VariantIdentifierPipe implements PipeTransform {
  constructor() {}
  transform(mIndex: number, vIndex: number) {
    return `${mIndex + 1}${String.fromCharCode(65 + vIndex)}`;
  }
}
