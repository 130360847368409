import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {DealsService} from '@app/services/deals.service';
import {OffersService} from '@app/services/offers.service';
import {SettingsService} from '@app/services/settings.service';
import {UsersService} from '@app/services/users.service';
import {MainService} from '@app/services/main.service';
import {DialogsService} from '@app/services/dialogs.service';
import {SnackbarService} from '@app/services/snackbar.service';
import {DocumentInterface} from '@app/interfaces/document.interface';
import {OptionInterface} from '@app/interfaces/option.interface';
import {TasksService} from '@app/services/tasks.service';
import {DocumentsService} from '@app/services/documents.service';
import {FormArray, NgForm} from '@angular/forms';
import {Offer} from '@app/models/offer.model';
import {Deal} from '@app/models/deal.model';
import {ContactsService} from '@app/services/contacts.service';
import {HouseholdsService} from '@app/services/households.service';
import {PipelinesService} from '@app/services/pipelines.service';
import {CasesService} from '@app/services/cases.service';
import {DialNumberParam} from '@app/models/calls.model';
import {CallsService} from '@app/services/calls.service';
import {DemandsService} from '@app/services/demands.service';
import {BankingService} from '@app/services/banking.service';

@Component({
  selector: 'app-deal-offer-panel',
  templateUrl: './deal-offer-panel.component.html',
  styleUrls: ['./deal-offer-panel.component.scss']
})

export class DealOfferPanelComponent implements OnInit {

  public deal: Deal = null;
  public offer: Offer = null;

  public showComments = false;
  public leadInfo: string[] = [];
  private leadInfoLoaded = false;

  constructor(
    public dealsService: DealsService,
    public offersService: OffersService,
    public contactsService: ContactsService,
    public settingsService: SettingsService,
    public usersService: UsersService,
    public mainService: MainService,
    private dialogsService: DialogsService,
    private router: Router,
    private snackbarService: SnackbarService,
    public tasksService: TasksService,
    private documentsService: DocumentsService,
    public householdsService: HouseholdsService,
    public pipelinesService: PipelinesService,
    public casesService: CasesService,
    public callsService: CallsService,
    private demandsService: DemandsService,
    public bankingService: BankingService,
  ) {}

  ngOnInit(): void {
    this.dealsService.deal$.subscribe((deal: Deal) => {
      this.deal = deal;
      this.loadLead();
    });
    this.offersService.offer$.subscribe((offer: Offer) => {
      this.offer = offer;
    });
  }

  public toggleComments() {
    this.showComments = !this.showComments;
  }

  private async loadLead() {
    if (!this.leadInfoLoaded) {
      this.leadInfoLoaded = true;
      const demand = (await this.demandsService.find({query: {dealId: this.deal._id}}))?.data[0];
      if (demand) {
        if (demand.anal?.device) {
          this.leadInfo.push(demand.anal?.device);
        }
        if (demand.anal?.geolocation?.city) {
          this.leadInfo.push(demand.anal?.geolocation?.city);
        }
        if (demand.anal?.platform) {
          this.leadInfo.push(demand.anal?.platform);
        }
        if (demand.utmzz?.utmcsr) {
          this.leadInfo.push(demand.utmzz.utmcsr === 'direct' ? 'přímý přístup' : demand.utmzz.utmcsr);
        }
        if (demand.utmzz?.utmcmd && demand.utmzz?.utmcmd !== '(none)') {
          this.leadInfo.push(demand.utmzz.utmcmd);
        }
      }
    }
  }

  public async confirmChangeOfferStage(stage, changePriority = false) {
    let addon = '';
    if (stage === 'signed') {
      addon = ' Všechny ostatní nabídky budou zamítnuty.';
    }
    if (!changePriority) {
      await this.dialogsService.confirm('Změnit stav nabídky', 'Chcete opravdu změnit stav této nabídky?' + addon).subscribe(async (confirm) => {
        if (confirm) {
          await this.offersService.changeOfferStage(stage);
        }
      });
    } else {
      await this.dialogsService.priority('Změnit stav nabídky', 'Chcete opravdu změnit stav této nabídky?', this.options('priority')).subscribe(async (priority) => {
        if (Number.isInteger(priority)) {
          await this.dealsService.patch(this.deal._id, {priority});
          await this.offersService.changeOfferStage(stage);
        }
      });
    }
  }

  public async confirmNewOffer() {
    await this.dialogsService.confirm('Přidat novou nabídku', 'Chcete opravdu přidat novou nabídku?').subscribe(async (confirm) => {
      if (confirm) {
        this.copyOffer();
        this.snackbarService.showSuccess('Byla vytvořena nová nabídka.');
      }
    });
  }

  public async copyOffer(type = null, redirect = true) {
    const offer = this.offersService.unmaskOffer(this.offersService.offerForm.getRawValue());
    let documents = this.offersService.documentsForm.value.documents;
    offer['stage'] = null;
    offer['isCanceled'] = false;
    offer['comments'] = [];
    offer['tempComment'] = null;
    offer['documents'] = [];
    if (offer.modelations?.length) { // Musíme zrušit kandidáty
      for (const modelation of offer.modelations) {
        if (modelation.variants?.length) {
          for (const variant of modelation.variants) {
            variant.isCandidate = false;
            variant.isConfirmed = false;
            variant.isFinal = false;
          }
        }
      }
    }
    delete offer._id;
    if (type === 'recreate') {
      offer['note']['operator'] = 'Přenabídkování N' + this.offer.numberId + ' z důvodu: ' + this.offer.tempComment + '\n-\n' + this.offer.note?.operator;
      documents = documents.filter((d: DocumentInterface) => d._template?.isRelatedTo !== 'contact');
    } else {
      documents = [];
    }
    this.mainService.showLoading();
    let createdOffer = await this.offersService.create(offer);
    for (const document of documents) {
      delete document._id;
      document.offerId = createdOffer._id;
      await this.documentsService.create(document);
    }
    await this.dealsService.patch(this.deal._id, {$push: {offers: createdOffer._id}});
    createdOffer = await this.offersService.patch(createdOffer._id, {}); // Kvuli přepočtu score
    if (redirect) {
      await this.router.navigate(['/deals', this.deal._id, 'offers', createdOffer._id]);
    } else {
      return createdOffer;
    }
    this.mainService.hideLoading();
  }

  public async recreateOffer() {
    this.dialogsService.confirm('Přenabídkovat', 'Chystáte se zamítnout tuto nabídku za účelem přenabídkování. Pokud potvrdíte tuto akci, bude znovu nutné provést vypočet bonity a vytvoření nové nabídky.').subscribe(async (confirm) => {
      if (confirm) {
        const newOffer = await this.copyOffer('recreate', false);
        await this.offersService.changeOfferStage('canceled', newOffer._id);
        await this.router.navigate(['/deals', this.deal._id, 'offers', newOffer._id, 'edit']);
      }
    });
  }

  public async cancelDeal() {
    this.dialogsService.cancel(this.settingsService.options).subscribe(async (reasons) => {
      if (reasons) {
        this.mainService.showLoading();
        this.deal = await this.dealsService.patch(this.deal._id, {stage: 'canceled', cancelReason: reasons, lastContactedAt: Date()});
        this.mainService.hideLoading();
        this.snackbarService.showSuccess('Případ byl stornován.');
      }
    });
  }

  public async restoreDeal() {
    this.dialogsService.confirm('Obnovení případu', 'Chcete skutečně obnovit případ?').subscribe( async (result) => {
      if (result) {
        this.mainService.showLoading();
        await this.offersService.restoreOffer(this.deal, this.deal._offers[this.deal.offers.length - 1], result);
        this.mainService.hideLoading();
        this.snackbarService.showSuccess('Případ byl obnoven.');
      }
    });
  }

  public async postponeDeal() {
    this.dialogsService.postpone(this.settingsService.options, this.deal.postponedUntil, this.deal.postponeReason || []).subscribe(async (result) => {
      if (result) {
        this.mainService.showLoading();
        this.deal = await this.dealsService.patch(this.deal._id, {
          stage: 'postponed',
          repostpone: !!this.deal.postponedUntil,
          postponeAlert: 0,
          postponeReason: result.reasons,
          postponedUntil: result.postponeDate,
          postponeTask: result.task,
          postponeTaskDate: result.taskDate,
          postponeTaskTitle: result.taskTitle,
          postponeTaskText: result.taskText,
          postponeTaskHours: result.taskHours,
          postponeTaskMinutes: result.taskMinutes,
          postpone: {
            autoCommunicate: result.autoCommunicate,
            communicationType: result.communicationType
          },
          lastContactedAt: Date(),
        });
        this.mainService.hideLoading();
        this.snackbarService.showSuccess('Případ byl odložen.');
      }
    });
  }

  public async missedCall() {
    await this.dialogsService.confirm('Nedovoláno', 'Skutečně se nepovedlo klientovi dovolat?').subscribe(async (confirm) => {
      if (confirm) {
        let missedCalls = this.offer.missedCalls ? this.offer.missedCalls : 0;
        this.mainService.showLoading();
        this.offer = await this.offersService.patch(this.offer._id, {missedCalls: ++missedCalls, isPlatformChange: false });
        this.snackbarService.showSuccess('Úspěšně zaznamenáno.');
        this.mainService.hideLoading();
      }
    });
  }

  public async changeDrawer() {
    const users = this.usersService.items.filter(u => u.role === 'drawer');
    this.dialogsService.drawer(this.deal.drawerId, users).subscribe((drawerId) => {
      if (drawerId) {
        this.dealsService.patch(this.deal._id, {drawerId}).then(() => {
          this.snackbarService.showSuccess('Změna čerpatele proběhla úspěšně.');
        });
      }
    });
  }

  public async changePriority() {
    const options = this.options('priority');
    this.dialogsService.priority('Změna priority', '', options, this.deal.priority).subscribe((priority) => {
      if (Number.isInteger(priority)) {
        this.dealsService.patch(this.deal._id, {priority}).then(() => {
          this.snackbarService.showSuccess('Změna priority proběhla úspěšně.');
        });
      }
    });
  }

  public options(type: string): OptionInterface[] {
    return this.settingsService.options[type];
  }

  public async save() {
    if (this.offersService.view === 'offer') {
      const a = await this.offersService.canSaveOffer();
      if (a) {
        await this.offersService.saveOffer();
      }
    } else if (this.offersService.view === 'documents') {
      await this.offersService.saveDocuments();
    } else if (this.offersService.view === 'commissions') {
      await this.offersService.$save.next('commissions');
    }
  }

  public canGoNext(isCancel = false) {
    if (this.offersService.view === 'offer') {
      return this.offersService.canGoNext(isCancel);
    } else if (this.offersService.view === 'documents') {
      return this.offersService.canGoNext(isCancel) && this.isPristine();
    } else if (this.offersService.view === 'commissions') {
      return this.offersService.canGoNext(isCancel) && this.isPristine();
    }
  }

  public isPristine() {
    if (this.offersService.view === 'offer') {
      return this.offersService.offerForm.pristine && this.offersService._applicantsForm.controls.every(c => c.pristine) && this.offersService._householdsForm.controls.every(c => c.pristine) && this.offersService._propertiesForm.controls.every(c => c.pristine);
    } else if (this.offersService.view === 'documents') {
      return this.offersService.documentsForm.pristine;
    } else if (this.offersService.view === 'commissions') {
      return !!this.offersService.commissionsForm?.pristine;
    }
  }

  variantIdentifier(mIndex: number, vIndex: number) {
    return `${mIndex + 1}${String.fromCharCode(65 + vIndex)}`;
  }

  applyBenefits(benefitsForm: NgForm) {
    const form = benefitsForm.value;
    const benefits = {};
    const checks = Object.keys(form).filter(k => k.substring(0, 6) === 'check:' && form[k]);
    Object.keys(form).filter(k => k.substring(0, 6) !== 'check:' && form[k]).forEach(k => benefits[k] = this.mainService.unmaskCurrency(form[k]));
    if (checks.length && Object.keys(benefits).length) {
      for (let mIndex = 0; mIndex < this.offersService.modelations.controls.length; mIndex++) {
        const modelation = this.offersService.modelations.at(mIndex);
        for (let vIndex = 0; vIndex < modelation.get('variants').value.length; vIndex++) {
          if (checks.includes(`check:${mIndex}`) || checks.includes(`check:${mIndex}:${vIndex}`)) {
            const variant = (modelation.get('variants') as FormArray).at(vIndex);
            const vairantBenefits = (variant.get('benefits') as FormArray);
            vairantBenefits.controls = [];
            for (const type of Object.keys(benefits)) {
              vairantBenefits.push(this.offersService.createBenefitForm({type, amount: benefits[type]}));
            }
            variant.markAsDirty();
          }
        }
      }
      this.snackbarService.showSuccess('Benefity aplikovány');
    }
  }

  resetForm(benefitsForm: NgForm) {
    benefitsForm.reset();
  }

  public trackByFn(index: any, item: any) {
    return index;
  }

  public async dialPhone(event: any, obj: any, isDeal: boolean) {
    const origin = isDeal ? ':DealMenu' : ':OfferMenu';
    const param: DialNumberParam = {
      countryCode: event.countryCode,
      number: event.phoneNumber,
      contactName: event.contactName,
      contactId: isDeal ? obj._offers[obj.offers.length - 1].applicants[0] : obj.applicants[0],
      origin: `DealOfferPanelComponent${origin}`,
      dealId: this.deal._id,
      pipelineId: this.deal._pipelineId,
      dealOwnerId: this.deal.ownerId,
      stage: this.deal.stage
    };
    await this.callsService.initiateCall(param);
  }
}
