import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'casesFilterStage'})
export class CasesFilterStagePipe implements PipeTransform {
  constructor(
  ) {}
  transform(cases: any[], excludeStages: string[], includeStages?: string[]) {
    cases = cases.filter(c => !excludeStages?.length || !excludeStages?.includes(c.stage));
    cases = cases.filter(c => !includeStages?.length || includeStages?.includes(c.stage));
    if (excludeStages.includes('canceled')) {
      cases = cases.filter(c => !c.isCanceled);
    }
    return cases;
  }
}
