import { Injectable } from '@angular/core';
import {DataService} from './data.service';
import {Campaign} from '../models/campaign.model';
import {CampaignTemplatesService} from './campaign-templates.service';

@Injectable({providedIn: 'root'})
export class CampaignsService extends DataService<Campaign> {

  constructor(
    private campaignTemplatesService: CampaignTemplatesService
  ) {
    super('campaigns');
    this.pagination = 50;
    this.sort = {
      createdAt: -1,
    };
  }
  public loadTemplates() {
    return this.campaignTemplatesService.find();
  }

  public loadTemplate(id: string) {
    return this.campaignTemplatesService.get(id);
  }

}
