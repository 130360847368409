import { Injectable } from '@angular/core';
import { Router, Resolve, RouterStateSnapshot,
  ActivatedRouteSnapshot } from '@angular/router';
import {OffersService} from '../services/offers.service';
import {Offer} from '@app/models/offer.model';
import {Deal} from '@app/models/deal.model';

@Injectable()
export class OfferResolver implements Resolve<Offer> {
  constructor(
    private router: Router,
    private offersService: OffersService
  ) {

  }

  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<Offer> {
    const deal: Deal = route.parent.parent.data.deal;
    const offerId = route.paramMap.get('offerId');
    return new Promise(async (resolve) => {
      if (offerId === '0') {
        if (deal._offers.length) {
          const notCanceledOffersId = deal._offers.filter(o => !o.isCanceled).map(o => o._id);
          const offersId = notCanceledOffersId?.length ? notCanceledOffersId : deal._offers.map(o => o._id);
          await this.router.navigate(['/', 'deals', deal._id, 'offers', offersId[offersId.length - 1], 'modelations']);
        } else {
          await this.router.navigate(['/', 'deals']);
        }
      } else {
        const offer: Offer = deal._offers.find(o => o._id === offerId);
        if (offer) {
          this.offersService.setOffer(offer, true);
          resolve(offer);
        } else {
          await this.router.navigate(['/', 'deals']);
        }
      }
    });
  }
}
