<ng-container *ngFor="let task of tasks | async; trackBy: tasksService.trackByFn; let first = first">
  <app-tasks-list-item
          [first]="first"
          [task]="task"
          [id]="'task-' + task._id"
          [options]="options"
          (moveTask)="taskMoved($event)"
          (noteChange)="noteChanged($event)"
          (taskComplete)="taskCompleted($event)">
  </app-tasks-list-item>
</ng-container>
