import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'charactersWords'
})
export class CharactersWordsPipe implements PipeTransform {
  transform(value: string, charactersCount: number = 40): string {
    if (!value) { return ''; }

    // Split the input text into words
    const words = value.split(' ');

    // Initialize an empty array to hold the words of the truncated text
    const truncatedWords: string[] = [];

    // Initialize a counter to keep track of the number of characters
    let charCount = 0;

    // Iterate over the words
    for (const word of words) {
      // Add the word to the truncated text
      truncatedWords.push(word);

      // Increment the character count by the length of the word plus one (for the space)
      charCount += word.length + 1;

      // If the character count exceeds 40, break the loop
      if (charCount > charactersCount) {
        break;
      }
    }

    // Join the truncated words and return the result
    return truncatedWords.join(' ');
  }
}
