import { Injectable } from '@angular/core';
import {DataService} from './data.service';
import {Task} from '../models/task.model';

@Injectable({providedIn: 'root'})
export class TimelineCallsService extends DataService<Task> {

  constructor(
  ) {
    super('calls');
    this.pagination = 100;
  }

}
