import { Injectable } from '@angular/core';
import { Router, Resolve, RouterStateSnapshot,
  ActivatedRouteSnapshot } from '@angular/router';
import {Contact} from '@app/models/contact.model';
import {ContactsService} from '@app/services/contacts.service';

@Injectable()
export class ContactResolver implements Resolve<Contact> {
  constructor(
    private router: Router,
    private contactService: ContactsService,
  ) {

  }

  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<Contact> {
    const contactId = route.paramMap.get('id');
    return new Promise(async (resolve) => {
      try {
        const contact: Contact = await this.contactService.get(contactId);
        if (contact) {
          resolve(contact);
        } else {
          await this.router.navigate(['/', 'contacts']);
        }
      } catch (e) {
        await this.router.navigate(['/', 'contacts']);
      }
    });
  }
}
