import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'fullAddress', pure: true })
export class FullAddressPipe implements PipeTransform {
  transform(address: any) {
    if (!address || address?.noAddressYet) {
      return '';
    }
    let a = '';
    if (address.houseNumber && address?.orientationNumber) {
      a = `${address.houseNumber}/${address.orientationNumber}`;
    } else if (address.houseNumber) {
      a = address.houseNumber;
    } else {
      a = address.orientationNumber;
    }
    return `${address.street ?? ''} ${a}, ${address.zipCode ?? ''} ${address.city ?? ''}`;
  }
}
