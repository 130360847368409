import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import {Injectable, Injector} from '@angular/core';

@Injectable()
export class CompositeRouteGuard implements CanActivate {

  constructor(  protected router: Router,
                protected injector: Injector ) {
  }

  canActivate( route: ActivatedRouteSnapshot, state: RouterStateSnapshot ): Promise<boolean> {
    const routeGuards = route.data.routeGuards;
    return new Promise(async (resolve) => {
      if (routeGuards) {
        let canActivate = false;
        for (let i = 0; i < routeGuards.length; i++) {
          if (!canActivate) {
            const routeGuard = this.injector.get(routeGuards[i]);
            canActivate = await routeGuard.canActivate(route, state);
          }
        }
        resolve(canActivate);
      } else {
        resolve(true);
      }
    });
  }
}
