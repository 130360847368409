import { environment } from '@env/environment';
import { Injectable } from '@angular/core';
import feathers, {Service} from '@feathersjs/feathers';
import rest from '@feathersjs/rest-client'


import authentication from '@feathersjs/authentication-client';

@Injectable()
export class BackendRestService {
  public app: any;

  constructor(
  ) {
    const restClient = rest(environment.backendUrl);

    this.app = feathers()
      .configure(restClient.fetch(window.fetch.bind(window)))
      .configure(authentication());

    this.app.reAuthenticate();
  }

  public getService(path: string) {
    return this.app.service(path);
  }

  public service(path: string): Service<any> {
    return this.getService(path);
  }

}
