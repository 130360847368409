import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from '@app/modules/core.module';
import { DocumentTemplateSelectModule } from '@app/components/document-template-select/document-template-select.module';
import { FileListModule } from '@app/components/file-list/file-list.module';
import { PipesModule } from '@app/modules/pipes.module';
import { EditorModule } from 'primeng/editor';
import { InplaceModule } from 'primeng/inplace';
import { SharedModule } from 'primeng/api';
import { DocumentItemComponent } from '@app/components/document-item/document-item.component';

@NgModule({
  declarations: [DocumentItemComponent],
  imports: [
    CoreModule,
    CommonModule,
    DocumentTemplateSelectModule,
    FileListModule,
    PipesModule,
    EditorModule,
    InplaceModule,
    SharedModule,
  ],
  exports: [DocumentItemComponent],
})
export class DocumentItemModule {}
