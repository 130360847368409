<div style="padding-left: 2rem; padding-right: 1rem;">
  <div *ngIf="policyCount < 1">
    <strong>Nebyly nalezeny žádné pojistné smlouvy k této nemovitosti</strong>
  </div>
  <div *ngIf="policyCount > 0">
    <table class="list">
      <tr>
        <td>Jméno</td>
        <td>Stav případu</td>
        <td style="text-align: right">Vytvořen</td>
      </tr>
      <tr *ngFor="let item of policyList; index as i">
        <ng-container *ngIf="expanded || (!expanded && i === 0)">
          <td>
            <a [routerLink]="['/cases', item._pipelineUrl, item._id]">{{ item.name }}</a>
          </td>
          <td>{{ item.stageName }}</td>
          <td style="text-align: right">{{ item.createdAt | date:'shortDate':undefined:'cs-CS' }}</td>
        </ng-container>
      </tr>
    </table>
    <div *ngIf="!expanded && policyCount > 1" class="flex flex-row justify-end items-center">
      <button mat-button color="primary" (click)="expanded=!expanded"><span>Zobrazit další...</span></button>
    </div>
  </div>
</div>
