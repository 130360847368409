import { Pipe, PipeTransform } from '@angular/core';
import { PipelinesService } from '@app/services/pipelines.service';

@Pipe({ name: 'caseCancelReasonLabel'})
export class CaseCancelReasonLabelPipe implements PipeTransform {
  constructor(protected pipelinesService: PipelinesService) {}
  transform(cancelReasons: string[], pipelineUrl: string): string {
    const r: string[] = [];
    const pipeline = this.pipelinesService.getPipeline(pipelineUrl);
    if (pipeline && cancelReasons.length > 0) {
      cancelReasons.forEach((item) => {
        const l = pipeline.cancelReasons.find((element) => element.id === item);
        if (l) {
          r.push(l.label);
        }
      });
    }
    return r.join(', ');
  }
}
