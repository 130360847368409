import { MatDialogRef } from '@angular/material/dialog';
import {AfterViewInit, Component} from '@angular/core';

@Component({
  selector: 'app-dialog',
  template: `
    <h2 mat-dialog-title>Odeslat ke kalkulaci</h2>
    <mat-dialog-content style="min-width: 500px">
      <div class="form-row">
        <mat-form-field>
          <mat-select placeholder="Vyber poskytovatele" [(ngModel)]="result" multiple>
            <ng-container *ngFor="let provider of providers">
              <mat-option [value]="provider._id">{{ provider.name }}</mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions>
      <button type="button" mat-button color="warn" (click)="dialogRef.close(null)">Zrušit</button>
      <button type="button" mat-raised-button color="primary" [disabled]="!result.length" (click)="dialogRef.close(result)">Potvrdit</button>
    </mat-dialog-actions>
  `,
})

export class UipathDialogComponent {

  public providers: any[];
  public result: string[] = [];

  constructor(
    public dialogRef: MatDialogRef<UipathDialogComponent>,
  ) {
  }
}
