import { Pipe, PipeTransform } from '@angular/core';
import {Property} from '@app/models/property.model';
import {AbstractControl, FormArray} from '@angular/forms';

@Pipe({ name: '_contactForm' })
export class ContactGetFormPipe implements PipeTransform {
  constructor() {}
  transform(contactId: string, contacts: FormArray) {
    return contacts.controls.find((p: AbstractControl) => p.get('_id')?.value === contactId) || null;
  }
}
