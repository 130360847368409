import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from '@app/modules/core.module';
import { DocumentTemplateSelectComponent } from '@app/components/document-template-select/document-template-select.component';
import { PipesModule } from '@app/modules/pipes.module';

@NgModule({
  declarations: [DocumentTemplateSelectComponent],
  imports: [CoreModule, CommonModule, PipesModule],
  exports: [DocumentTemplateSelectComponent],
})
export class DocumentTemplateSelectModule {}
