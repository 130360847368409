import { Injectable } from '@angular/core';
import {DataService} from '@app/services/data.service';
import {LifeInsurance} from '@app/models/life-insurance.model';
import {AbstractControl, FormArray, FormBuilder, Validators} from '@angular/forms';
import {MainService} from '@app/services/main.service';
import {LifeInsuranceProductsService} from '@app/services/life-insurance-products.service';
import {BehaviorSubject, Observable} from 'rxjs';
import {UsersService} from '@app/services/users.service';
import {PipelinesService} from '@app/services/pipelines.service';

@Injectable({providedIn: 'root'})
export class LifeInsurancesService extends DataService<LifeInsurance> {

  private case: LifeInsurance;
  public $case: BehaviorSubject<LifeInsurance> = new BehaviorSubject<LifeInsurance>(null);
  public case$: Observable<LifeInsurance> = this.$case.asObservable();
  public $formIsPristine: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public formIsPristine$: Observable<boolean> = this.$formIsPristine.asObservable();
  public pipelineUrl = 'life-service';
  public storedFilter = {};
  public isLoaded = false;
  constructor(
    private fb: FormBuilder,
    private mainService: MainService,
    private productsService: LifeInsuranceProductsService,
    private usersService: UsersService,
    private pipelinesService: PipelinesService
  ) {
    super('cases/life-insurance');
    this.pagination = 25;
    this.sort = {
      numberId: -1,
    };
    this.query = {
      stage: {$nin: [null, 'init']}
    };
    this.updated$.subscribe((updatedItem: LifeInsurance) => {
      if (this.case && this.case._id === updatedItem._id) {
        this.setCase(updatedItem);
      }
    });
    this.setFilter(JSON.parse(localStorage.getItem(this.pipelineUrl)) || {}, true);
  }

  public setCase(deal: LifeInsurance) {
    this.case = deal;
    this.$case.next(deal);
  }

  public setFilter(filters, withoutLoad = false) {
    const filter: any = {$or: []};
    this.storedFilter = filters;
    if (filters && Object.keys(filters).length > 0) {
      localStorage.setItem(this.pipelineUrl, JSON.stringify(filters));
      let isCanceled = false;
      let isPostponed = false;
      if (filters.stage.includes('canceled')) {
        isCanceled = true;
        filter.$or.push({isCanceled: true});
      }
      if (filters.stage.includes('postponed')) {
        isPostponed = true;
        filter.$or.push({isPostponed: true});
      }
      if (filters.ownerId.length) {
        filter.ownerId = {$in: this.usersService.isPrivileged('cases/all') ? filters.ownerId : [this.usersService.user._id]};
      }
      if (filters.stage.filter(s => !['canceled', 'postponed'].includes(s)).length) {
        const stage: any = {stage: {$in: filters.stage.filter(s => !['canceled', 'postponed'].includes(s))}};
        if (!isCanceled) {
          stage.isCanceled = {$ne: true};
        }
        if (!isPostponed) {
          stage.isPostponed = {$ne: true};
        }
        filter.$or.push(stage);
      }
      if (filters.search) {
        filter.search = {$regex: filters.search.normalize('NFD').replace(/[\u0300-\u036f]/g, ''), $options: 'i'};
      }
    } else {
      localStorage.removeItem(this.pipelineUrl);
    }
    if (!filter.$or.length) {
      delete filter.$or;
    }
    this.filter = filter;
    if (!withoutLoad) {
      this.load(0);
    }
  }

  public revalidateModelationsForms(modelationsForms?: FormArray) {
    for (const modelationForm of modelationsForms?.controls || []) {
      for (const variantForm of (modelationForm.get('variants') as FormArray)?.controls || []) {
        this.revalidateVariantForm(variantForm);
      }
    }
  }

  public revalidateVariantForm(variantForm: AbstractControl) {
    for (const productForm of (variantForm.get('products') as FormArray).controls || []) {
      this.validateProductForm(productForm, variantForm.get('isFinal').value);
    }
  }

  public createModelationForm(modelation?) {
    const form = this.fb.group({
      _id: [],
      type: ['risk'],
      requiredProviders: [[]],
      timeFrameType: ['time'],
      timeFrame: [40],
      variants: this.fb.array([])
    });
    if (modelation) {
      form.patchValue(modelation);
      for (const variant of modelation.variants || []) {
        (form.get('variants') as FormArray).push(this.createVariantForm(variant));
      }
    }
    return form;
  }

  public unmaskModelation(modelation) {
    if (!modelation._id) {
      delete modelation._id;
    }
    modelation.plannedMonthPremium = this.mainService.unmaskCurrency(modelation.plannedMonthPremium);
    for (const variant of modelation.variants) {
      if (!variant._id) {
        delete variant._id;
      }
      for (const product of variant.products) {
        if (!product._id) {
          delete product._id;
        }
        product.payment = this.mainService.unmaskCurrency(product.payment);
      }
    }
  }

  public createVariantForm(variant?) {
    const form = this.fb.group({
      _id: [null],
      isCandidate: [false],
      isConfirmed: [false],
      isFinal: [false],
      isHidden: [false],
      changeParentId: [null],
      changeCreatedAt: [null],
      products: this.fb.array([])
    });
    if (variant) {
      form.patchValue(variant);
      for (const product of variant.products || []) {
        const productForm = this.createProductForm(product);
        (form.get('products') as FormArray).push(productForm);
        this.validateProductForm(productForm, form.get('isFinal').value);
      }
    }
    form.get('isFinal').valueChanges.subscribe((value) => {
      for (const productForm of (form.get('products') as FormArray).controls) {
        this.validateProductForm(productForm, value);
      }
    });
    form.markAllAsTouched();
    return form;
  }

  public createProductForm(product?) {
    const form = this.fb.group({
      _id: [null],
      sourceId: [null],
      source: [null],
      payment: [null],
      paymentFrequency: [1],
      participation: [null],
      contractByPayment: [false],
      isPaid: [false],
      signedAt: [null],
      validFrom: [null],
      contractNumber: [null],
      meetingRecordSignedAt: [null]
    });
    form.get('sourceId').valueChanges.subscribe(async (sourceId) => {
      if (sourceId) {
        const source = await this.productsService.get(sourceId);
        form.get('source').patchValue(source);
      }
    });
    if (product) {
      form.patchValue(product);
      form.get('sourceId').patchValue(product.source?._id || null, {emitEvent: false});
    }
    form.markAllAsTouched();
    return form;
  }
  validateProductForm(form, isFinal = false) {
    if (this.pipelinesService.isStageBetween(this.case._pipelineUrl, this.case.stage, 'sent')) {
      if (isFinal) {
        form.get('validFrom').setValidators([Validators.required]);
        form.get('signedAt').setValidators([Validators.required]);
        form.get('paymentFrequency').setValidators([Validators.required]);
        form.get('payment').setValidators([Validators.required, Validators.min(1)]);
        form.get('sourceId').setValidators([Validators.required]);
        form.get('contractNumber').setValidators([Validators.required]);
      } else {
        form.get('validFrom').clearValidators();
        form.get('signedAt').clearValidators();
        form.get('paymentFrequency').clearValidators();
        form.get('payment').clearValidators();
        form.get('sourceId').clearValidators();
        form.get('contractNumber').clearValidators();
      }
      form.get('validFrom').updateValueAndValidity();
      form.get('signedAt').updateValueAndValidity();
      form.get('paymentFrequency').updateValueAndValidity();
      form.get('payment').updateValueAndValidity();
      form.get('sourceId').updateValueAndValidity();
      form.get('contractNumber').updateValueAndValidity();
    }
    if (this.pipelinesService.isStageBetween(this.case._pipelineUrl, this.case.stage, 'signed')) {
      if (isFinal) {
        form.get('meetingRecordSignedAt').setValidators([Validators.required]);
      } else {
        form.get('meetingRecordSignedAt').clearValidators();
      }
      form.get('meetingRecordSignedAt').updateValueAndValidity();
    }
  }

  invalidModelations(stage?: any) {
    let result = false;
    if (this.pipelinesService.isStageBetween(this.case._pipelineUrl, this.case.stage, 'sent')) {
      const finalModelation = this.case.modelations.find((m: any) => m.variants?.some((v: any) => v.isFinal));
      if (!finalModelation) {
        result = true;
      } else {
        const finalVariant = finalModelation.variants?.find((v: any) => v.isFinal);
        if (!finalVariant) {
          result = true;
        } else {
          for (const product of finalVariant.products) {
            if (
              !product.source ||
              !product.validFrom ||
              !product.signedAt ||
              !product.paymentFrequency ||
              !product.payment ||
              !product.contractNumber
            ) {
              result = true;
            }
          }
        }
      }
    }
    if (this.pipelinesService.isStageBetween(this.case._pipelineUrl, this.case.stage, 'signed')) {
      const finalModelation = this.case.modelations.find((m: any) => m.variants?.some((v: any) => v.isFinal));
      if (!finalModelation) {
        result = true;
      } else {
        const finalVariant = finalModelation.variants?.find((v: any) => v.isFinal);
        if (!finalVariant) {
          result = true;
        } else {
          for (const product of finalVariant.products) {
            if (
              !product.meetingRecordSignedAt
            ) {
              result = true;
            }
          }
        }
      }
    }
    return stage && !this.pipelinesService.isStageBetween(this.case._pipelineUrl, stage.id, 'sent') ? false : result;
  }


}
