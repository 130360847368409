import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { SnackbarService } from '@app/services/snackbar.service';
import { Contact } from '@app/models/contact.model';
import { ContactsService } from '@app/services/contacts.service';
import { BlacklistService } from '@app/services/blacklist.service';
import { UsersService } from '@app/services/users.service';

@Component({
  selector: 'app-dialog',
  templateUrl: './comm-preferences-dialog.component.html',
  styleUrls: ['./comm-preferences-dialog.component.scss'],
})
export class CommPreferencesDialogComponent implements OnInit {

  systemTooltip = 'Vypnutím zabráníte uživateli v zadávání poptávek a přihlášení do bankingu emailem! Vypíná veškerou systémovou komunikaci nezbytnou pro průchod od poptávky do uzavření smlouvy.';
  transactionTooltip = '';
  casesTooltip = 'Povoluje komunikaci na odložené nebo stornované případy, která má za cíl uživatele vyzvat k obnovení.';
  marketingTooltip = 'Povoluje odesílání marketingových emailů týkajících se akcí, nabídek, měsíčních infomailů a podobně.';

  public contactId: string;
  public loaded = false;
  public isAdmin = false;
  public countryCode: string;
  public phoneNumber: string;
  public validPhone = false;

  public comms: {
    systemMsg: boolean,
    transactionMsg: boolean,
    casesSms: boolean,
    casesEmail: boolean,
    marketingSms: boolean,
    marketingEmail: boolean,
    blacklisted: boolean
  };

  constructor(
    public usersService: UsersService,
    public contactsService: ContactsService,
    public snackbarService: SnackbarService,
    public blacklistService: BlacklistService,
    public dialogRef: MatDialogRef<CommPreferencesDialogComponent>,
  ) {
  }

  async ngOnInit() {
    if (['admin'].includes(this.usersService.user.role)) {
      this.isAdmin = true;
    }

    this.comms = {
      systemMsg: true,
      transactionMsg: true,
      casesSms: true,
      casesEmail: true,
      marketingSms: true,
      marketingEmail: true,
      blacklisted: false,
    };

    const contact: Contact = await this.contactsService.get(this.contactId);
    this.countryCode = contact.phone?.[0]?.countryCode;
    this.phoneNumber = contact.phone?.[0]?.number;
    this.validPhone = (!!contact.phone?.[0]?.countryCode) && (!!contact.phone?.[0]?.number);

    const bl = this.validPhone ? await this.blacklistService.getBlacklistStatus(`${this.countryCode}${this.phoneNumber}`) : false;

    if (contact.commPreferences) {
      this.comms = {
        blacklisted: bl,
        systemMsg: !contact.commPreferences.disabledSystemMsg,
        transactionMsg: !contact.commPreferences.disabledTransactionMsg,
        casesSms: !contact.commPreferences.disabledCasesSms,
        casesEmail: !contact.commPreferences.disabledCasesEmail,
        marketingSms: !contact.commPreferences.disabledMarketingSms,
        marketingEmail: !contact.commPreferences.disabledMarketingEmail,
      };
    }

    this.loaded = true;
  }

  async savePreferences(): Promise<any> {
    const changes = {
      commPreferences: {
        disabledSystemMsg: !this.comms.systemMsg,
        disabledTransactionMsg: !this.comms.transactionMsg,
        disabledCasesSms: !this.comms.casesSms,
        disabledCasesEmail: !this.comms.casesEmail,
        disabledMarketingSms: !this.comms.marketingSms,
        disabledMarketingEmail: !this.comms.marketingEmail,
      }
    };
    const result = await this.contactsService.patch(this.contactId, changes);

    if (this.validPhone && this.isAdmin) {
      await this.blacklistService.setBlacklistStatus(`${this.countryCode}${this.phoneNumber}`, this.comms.blacklisted);
    }

    this.snackbarService.showSuccess('Nastavení bylo uloženo');
    this.dialogRef.close(result);
    return true;
  }
}
