<h2 mat-dialog-title>{{ title }}</h2>
<mat-dialog-content style="min-width: 500px;">
  <div *ngIf="description">{{description}}</div>

  <div class="files-list" *ngIf="ready">
    <div class="files-item flex flex-row justify-start items-center" *ngFor="let file of files" [ngClass]="{warn: file.warn}">
      <div class="file-name grow flex flex-row justify-start items-center">
        <mat-icon>{{ file.icon }}</mat-icon>
        <div class="flex flex-col">
          <span>{{ file.name }}</span>
          <span *ngIf="file.warn"><strong>{{ file.remark }}</strong></span>
        </div>
      </div>
      <div class="file-date flex-none">{{ file.createdAt | date :'dd.MM.YYYY HH:mm' }}</div>
      <div class="file-size flex-none">{{ file.size | fileSize }}</div>
    </div>
  </div>

  <div class="form-row" *ngIf="operationType === 'join' && ready">
    <label class="flex-0-0-auto">Název výsledného souboru</label>
    <mat-form-field class="flex">
      <input matInput [(ngModel)]="result.fileName"/>
    </mat-form-field>
  </div>

  <ng-container *ngIf="operationType === 'reject' && ready">
    <div class="form-row">
      <label class="flex-0-0-auto">Důvod zamítnutí</label>
      <mat-form-field class="flex">
        <input matInput [(ngModel)]="result.rejectionReason"/>
      </mat-form-field>
    </div>
    <div>
      Uveďte, proč jste dokument zamítli. Příklad: nízká kvalita fotografie, chybí strana X, je nahrán nesprávný dokument.
      Pozor, text bude přímo zobrazen klientovi v bankovnictví.
    </div>
  </ng-container>

</mat-dialog-content>
<mat-dialog-actions>
  <ng-container *ngIf="ready && operationType === 'join'">
    <button type="button" mat-button color="warn" (click)="dialogRef.close(null)">Zrušit</button>
    <button type="button" mat-raised-button color="primary" [disabled]="false" (click)="passResult()">Potvrdit</button>
  </ng-container>
  <ng-container *ngIf="ready && operationType === 'reject'">
    <button type="button" mat-button color="warn" (click)="dialogRef.close(null)">Zrušit</button>
    <button type="button" mat-raised-button color="primary" [disabled]="!result.rejectionReason" (click)="passResult()">Potvrdit</button>
  </ng-container>
  <ng-container *ngIf="!ready">
    <button type="button" mat-raised-button color="primary" (click)="dialogRef.close(null)">Zrušit</button>
  </ng-container>
</mat-dialog-actions>
