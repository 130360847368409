import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { Document } from '../models/document.model';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '@env/environment';
import { MainService } from '@app/services/main.service';
import { BackendService } from '@app/services/backend.service';
import { DialogsService } from '@app/services/dialogs.service';
import { DocumentInterface } from '@app/interfaces/document.interface';
import { FormBuilder } from '@angular/forms';

@Injectable({providedIn: 'root'})
export class DocumentsService extends DataService<Document> {

  constructor(
    private mainService: MainService,
    private backendService: BackendService,
    private dialogsService: DialogsService,
    private http: HttpClient,
    private formBuilder: FormBuilder,
  ) {
    super('documents');
    this.pagination = 50;
    this.sort = {
    };
    this.query = {
      $or: [
        {
          startAt: {$gte: Date.now()},
        },
        {
          startAt: null,
        }
      ],
      deletedAt: {$exists: false},
    };
  }

  private getFilename(deal, type) {
    const filename = [deal.numberId, deal.name].join('_').toLowerCase().replace(':', '').replace(/[\u0300-\u036f]/g, '').replace(/\s/g, '_');
    return filename + type;
  }

  public async downloadArchive(deal, documents) {
    this.mainService.showLoading();
    let headers: HttpHeaders;
    let params = new HttpParams();
    documents = documents.filter((document: Document) => document.files.length);
    if (documents.length) {
      documents.forEach((document: Document) => {
        params = params.append('docsIds[]', document._id);
      });
      headers = new HttpHeaders({
        Authorization: this.backendService.accessToken
      });
      this.http.get(environment.backendUrl + '/documents/export', {
        headers,
        params,
        responseType: 'blob'
      }).subscribe((response: any) => {
        const url = window.URL.createObjectURL(response);
        const anchor = document.createElement('a');
        anchor.href = url;
        anchor.target = '_blank';
        anchor.download = this.getFilename(deal, '.zip');
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
        this.mainService.hideLoading();
      });
    } else {
      this.mainService.hideLoading();
      this.dialogsService.alert('Stáhnout archiv dokumentů', 'Vybrané dokumenty neobsahují žádné soubory.');
    }
  }

  public createDocumentForm(document: DocumentInterface) {
    const documentForm = this.formBuilder.group({
      _id: [null],
      templateId: [null],
      _template: [null],
      files: [[]],
      isHidden: [false],
      type: [null],
      notify: [],
      note: [null],
      status: ['waiting'],
      createdAt: [null],
      deliverUntil: [null],
      deliveredAt: [null],
      signatureUntil: [null],
      signedAt: [null],
      contactId: [null],
      caseId: [null],
      isInternal: [null],
      userId: [null],
      noteId: [null],
    });
    if (document) {
      documentForm.patchValue(document);
    }
    return documentForm;
  }
}
