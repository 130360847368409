import { Pipe, PipeTransform } from '@angular/core';
import {UserInterface} from '@app/interfaces/user.interface';
import {Contact} from '@app/models/contact.model';

@Pipe({ name: 'userName' })
export class UserNamePipe implements PipeTransform {
  constructor(
  ) {}
  transform(user?: UserInterface | Contact) {
    return ((user?.firstName || '') + ' ' + (user?.lastName || '')).trim();
  }
}
