import { Injectable } from '@angular/core';
import { Router, Resolve, RouterStateSnapshot,
  ActivatedRouteSnapshot } from '@angular/router';
import {CampaignsService} from '../services/campaigns.service';
import {Campaign} from '../models/campaign.model';

@Injectable()
export class CampaignResolver implements Resolve<Campaign> {
  constructor(
    private router: Router,
    private campaignsService: CampaignsService,
  ) {

  }

  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<Campaign> {
    const campaignId = route.paramMap.get('id');
    const type = route.paramMap.get('type');
    if (campaignId === 'add') {
      return Promise.resolve({
        name: 'Nová kampaň',
        filterBySenders: true,
        segmentation: [],
        sendersId: [],
        sendgridTemplateId: '',
        type
      });
    } else {
      return new Promise(async (resolve) => {
        try {
          const campaign: Campaign = await this.campaignsService.get(campaignId);
          if (campaign) {
            resolve(campaign);
          } else {
            await this.router.navigate(['/', 'campaigns']);
          }
        } catch (e) {
          await this.router.navigate(['/', 'campaigns']);
        }
      });
    }
  }
}
