<h2 mat-dialog-title><span>{{ options?.title ?? 'Zvolte uživatele / skupinu' }}</span></h2>
<mat-dialog-content>
  <form #taskForm="ngForm">
    <div class="form-row">
      <mat-form-field>
        <mat-label>Uživatel</mat-label>
        <input matInput [(ngModel)]="assigned" name="ownerId" required [matAutocomplete]="acUsers" (ngModelChange)="filterOwners($event)"
               #autoTrigger="matAutocompleteTrigger">
        <button [disableRipple]="true" *ngIf="assigned?.name" matSuffix mat-icon-button (click)="openPanel();">
          <mat-icon>arrow_drop_down</mat-icon>
        </button>
        <mat-autocomplete #acUsers="matAutocomplete" [displayWith]="userDisplayFn">
          <mat-option [value]="owner" *ngFor="let owner of filteredOwners">{{ owner.name }}</mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions>
  <button type="button" mat-button color="warn" (click)="dialogRef.close(null)">Zrušit</button>
  <button type="button" mat-button color="primary" *ngIf="this.oldOwnerId !== this.usersService.user._id && options.assignToMe" (click)="assignToMe()">Přiřadit mně</button>
  <button type="button" mat-raised-button color="accent" (click)="closeDialog()" [disabled]="taskForm.pristine || taskForm.invalid">Uložit</button>
</mat-dialog-actions>
