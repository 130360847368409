<div class="modelation-select" cdkDropList (cdkDropListDropped)="offersService.modelationsDrop($event)" cdkDropListLockAxis="y">
  <div class="modelation-button" *ngFor="let modelation of offersService.modelations.controls; let mIndex = index;trackBy: trackByFn;" cdkDrag>
    <span *cdkDragPreview></span>
    <button mat-flat-button [ngClass]="{'isHidden': offersService.modelationHidden(modelation),'hasError': offersService.modelationInvalid(mIndex), 'isMischmasch': offersService.modelationVariantsNotEqual(mIndex), 'selected': offersService.modelationIndex === mIndex}" [color]="offersService.modelationCreditWorthy(mIndex) ? 'accent' : modelation.get('variants').value.length > 0 ? 'primary' : null" (click)="offersService.selectModelation(mIndex)"><mat-icon class="mini mini-bottom-left" [ngClass]="{spin: isUipathProgress(mIndex)}" *ngIf="isUipath(mIndex)">smart_toy</mat-icon><mat-icon *ngIf="offersService.noFees(modelation.value)" class="mini mini-bottom">money_off</mat-icon><span>{{ mIndex + 1}}</span><mat-icon *ngIf="offersService.modelationHasCandidate(modelation) && !offersService.modelationHasConfirmed(modelation)" class="mini">star</mat-icon><mat-icon *ngIf="offersService.modelationHasConfirmed(modelation) && !offersService.modelationHasFinal(modelation)" class="mini">done</mat-icon><mat-icon *ngIf="offersService.modelationHasFinal(modelation)" class="mini">done_all</mat-icon></button>
    <button title="Odeslat ke kalkulaci" class="modelation-options" mat-flat-button [color]="offersService.modelationCreditWorthy(mIndex) ? 'accent' : modelation.get('variants').value.length > 0 ? 'primary' : null" *ngIf="!offer.isCanceled && offer.fillingScore >= 1000" (click)="createUipath(mIndex)"><mat-icon>smart_toy</mat-icon></button>
    <button title="Smazat poplatky" class="modelation-options" mat-flat-button [color]="offersService.modelationCreditWorthy(mIndex) ? 'accent' : modelation.get('variants').value.length > 0 ? 'primary' : null" (click)="offersService.removeFees(mIndex)"><mat-icon>money_off</mat-icon></button>
    <button title="Smazat varianty" class="modelation-options" mat-flat-button [color]="offersService.modelationCreditWorthy(mIndex) ? 'accent' : modelation.get('variants').value.length > 0 ? 'primary' : null" (click)="offersService.removeVariants(mIndex)"><mat-icon>delete_outline</mat-icon></button>
    <button title="Smazat modelaci" class="modelation-options" mat-flat-button [color]="offersService.modelationCreditWorthy(mIndex) ? 'accent' : modelation.get('variants').value.length > 0 ? 'primary' : null" *ngIf="offersService.modelations.controls.length > 1" (click)="offersService.removeModelation(mIndex)"><mat-icon>delete</mat-icon></button>
  </div>
  <div class="modelation-button">
    <button mat-flat-button color="default" [matMenuTriggerFor]="addModelationMenu"><mat-icon>add</mat-icon></button>
  </div>
  <div class="modelation-button">
    <button mat-flat-button color="default" (click)="offersService.removeFees()"><mat-icon>money_off</mat-icon></button>
  </div>
</div>
<div class="modelations">
  <ng-container [formGroup]="offersService.offerForm">
    <div class="modelation" formArrayName="modelations">
      <ng-container *ngFor="let modelation of offersService.modelations.controls; let mIndex = index;trackBy: trackByFn;">
        <div class="variants-wrap" *ngIf="mIndex === offersService.modelationIndex" [formGroupName]="mIndex">
          <div class="variants" formArrayName="variants">
            <div class="variants-sortable" cdkDropList (cdkDropListDropped)="offersService.variantsDrop($event)" cdkDropListOrientation="horizontal" cdkDropListLockAxis="x">
              <div class="variant-wrap" *ngFor="let variant of offersService.variants.controls; let vIndex = index;trackBy: trackByFn;" cdkDrag>
                <span *cdkDragPreview></span>
                <div class="variant" [formGroupName]="vIndex">
                  <div class="card-title" [ngClass]="{'candidate': variant.get('isCandidate').value, 'confirmed': variant.get('isConfirmed').value, 'final': variant.get('isFinal').value}">
                    <span cdkDragHandle class="variant-title">Varianta {{ variantIdentifier(mIndex, vIndex) }}</span>
                    <div>
                      <button
                        class="isHidden"
                        (click)="hideVariant(vIndex)"
                        mat-icon-button
                        title="Skrýt variantu"
                        [disabled]="offer.isCanceled">
                        <mat-icon *ngIf="variant.get('isHidden').value">visibility_off</mat-icon>
                        <mat-icon *ngIf="!variant.get('isHidden').value">visibility</mat-icon>
                      </button>
                      <button
                        *ngIf="offersService.isStageBetween(offer.stage, 'sent')"
                        class="isCandidate"
                        (click)="selectCandidate(vIndex)"
                        mat-icon-button
                        title="Předvybraná"
                        [disabled]="offer.isCanceled">
                        <mat-icon *ngIf="variant.get('isCandidate').value">star</mat-icon>
                        <mat-icon *ngIf="!variant.get('isCandidate').value">star_outline</mat-icon>
                      </button>
                      <button
                        *ngIf="offersService.isStageBetween(offer.stage, 'sent')"
                        [class]="variant.get('isFinal').value ? 'isFinal' : 'isConfirmed'"
                        (click)="selectVariant(vIndex)"
                        mat-icon-button
                        [title]="variant.get('isFinal').value ? 'Finální varianta' : 'Vybraná pro žádost'"
                        [disabled]="offer.isCanceled">
                        <mat-icon *ngIf="!variant.get('isFinal').value">done</mat-icon>
                        <mat-icon *ngIf="variant.get('isFinal').value">done_all</mat-icon>
                      </button>
                      <button *ngIf="offersService.availableVariantProducts(variant).length > 0" mat-icon-button [matMenuTriggerFor]="addVariantMenu" [matMenuTriggerData]="{variant: variant}" color="primary"><mat-icon>add</mat-icon></button>
                      <button mat-icon-button [matMenuTriggerFor]="variantMenu" [matMenuTriggerData]="{index: vIndex, variant: variant}"><mat-icon>more_vert</mat-icon></button>
                    </div>
                  </div>
                  <div class="card" [ngClass]="{'is-hidden': variant.get('isHidden').value}">
                    <div class="variant-summary">
                      <div class="form-row">
                        <mat-form-field class="readonly">
                          <input matInput placeholder="Splátka celkem" formControlName="totalRepayment" readonly [textMask]="{mask: mainService.currencyMask, guide: false}"/>
                        </mat-form-field>
                        <mat-form-field class="readonly">
                          <input matInput placeholder="DTI" formControlName="dti" readonly />
                        </mat-form-field>
                        <mat-form-field class="readonly">
                          <input matInput placeholder="DSTI %" formControlName="dsti" readonly />
                        </mat-form-field>
                      </div>
                      <div class="form-row">
                        <mat-form-field class="readonly">
                          <input matInput placeholder="Výše úvěru" formControlName="totalAmount" readonly [textMask]="{mask: mainService.currencyMask, guide: false}"/>
                          <mat-hint align="start" *ngIf="variant.get('totalAmount').value !== mainService.unmaskCurrency(modelation.get('amount').value)">{{ mainService.maskCurrency(mainService.unmaskCurrency(modelation.get('amount').value)) }}</mat-hint>
                        </mat-form-field>
                        <mat-form-field class="readonly">
                          <input matInput placeholder="Poplatky celkem" formControlName="totalFees" readonly [textMask]="{mask: mainService.currencyMask, guide: false}"/>
                        </mat-form-field>
                        <mat-form-field class="readonly">
                          <input matInput placeholder="Celkem zaplatí" formControlName="totalPayment" readonly [textMask]="{mask: mainService.currencyMask, guide: false}"/>
                        </mat-form-field>
                      </div>
                      <div class="form-row" *ngIf="variant.get('isConfirmed').value || variant.get('isFinal').value">
                        <mat-form-field>
                          <input matInput placeholder="Garance sazby od" [matDatepicker]="interestRateGuaranteeFromPicker" formControlName="interestRateGuaranteeFrom" />
                          <mat-datepicker-toggle matSuffix [for]="interestRateGuaranteeFromPicker"></mat-datepicker-toggle>
                          <mat-datepicker #interestRateGuaranteeFromPicker></mat-datepicker>
                        </mat-form-field>
                        <mat-form-field class="readonly">
                          <input matInput [placeholder]="(variant.get('products').value[0].source.provider.methodology?.interestRate?.interestRateGuaranteeStage === 'approved' ? 'SCHVÁLIT' : 'PODEPSAT') + ' do'" [matDatepicker]="interestRateGuaranteeToPicker" formControlName="interestRateGuaranteeTo" readonly />
                          <mat-datepicker #interestRateGuaranteeToPicker></mat-datepicker>
                        </mat-form-field>
                      </div>
                      <div class="form-row" *ngIf="variant.get('isConfirmed').value || variant.get('isFinal').value">
                        <mat-form-field>
                          <input matInput placeholder="Odhad objednán dne" [matDatepicker]="estimateOrderedAtPicker" formControlName="estimateOrderedAt" />
                          <mat-datepicker-toggle matSuffix [for]="estimateOrderedAtPicker"></mat-datepicker-toggle>
                          <mat-datepicker #estimateOrderedAtPicker></mat-datepicker>
                        </mat-form-field>
                      </div>
                    </div>
                    <mat-tab-group [ngClass]="{'is-hidden': variant.get('isHidden').value}" backgroundColor="primary" color="primary" formArrayName="products" animationDuration="0">
                      <ng-container *ngFor="let product of offersService.variantProducts(variant).controls; let pIndex = index;">
                        <mat-tab [formGroupName]="pIndex">
                          <ng-template mat-tab-label>
                            <div>
                              <span>{{ product.get('productTemplate').value.name }}</span>
                              <button mat-icon-button title="Odstranit" *ngIf="pIndex > 0" (click)="$event.stopPropagation(); offersService.removeProduct(variant, pIndex)"><mat-icon>cancel</mat-icon></button>
                            </div>
                          </ng-template>
                          <ng-container *ngIf="product.get('source').value.type === 'mortgage'">
                            <div class="variant-product" [ngClass]="{'dragover': dragcontainer['dragover']}" #dragcontainer (dragover)="$event.preventDefault();$event.stopPropagation()" (dragenter)="$event.preventDefault();$event.stopPropagation();dragcontainer['dragover'] = true;">
                              <div class="variant-product-detail">
                                <div class="form-row">
                                  <mat-form-field class="readonly" [ngClass]="{'app-nolimits': product.get('nolimits').value}">
                                    <input matInput placeholder="Produkt" [value]="product.get('source').value.name" readonly/>
                                    <!--<input matInput placeholder="Produkt" [value]="product.get('source').value.provider.stringCode + ' - ' + product.get('source').value.name" readonly/>-->
                                  </mat-form-field>
                                  <div class="form-button">
                                    <button *ngIf="!selectingSourceProduct || selectingSourceProduct.productIndex !== pIndex || selectingSourceProduct.variant.controls.id.value !== variant.value.id" mat-icon-button color="primary" title="Změnit produkt" (click)="showSourceProducts(variant, pIndex, product.get('productTemplate').value)"><mat-icon>layers</mat-icon></button>
                                    <button *ngIf="selectingSourceProduct && selectingSourceProduct.productIndex === pIndex && selectingSourceProduct.variant.controls.id.value === variant.value.id" mat-icon-button color="warn" title="Ukončit změnu produktu" (click)="showSourceProducts(null)"><mat-icon>layers</mat-icon></button>
                                  </div>
                                </div>
                                <div class="form-row">
                                  <mat-form-field>
                                    <input matInput placeholder="Úrok %" formControlName="interestRate" />
                                    <mat-icon matSuffix class="info-suffix" [matTooltip]="'Informativní výše úvěru: ' + product.get('interestRateAmount').value">attach_money</mat-icon>
                                    <mat-hint align="start" *ngIf="mainService.maskPercent(product.get('source').value.fixation.interestRate) !== product.get('interestRate').value">{{ mainService.maskPercent(product.get('source').value.fixation.interestRate) }}</mat-hint>
                                  </mat-form-field>
<!--                                  <mat-form-field class="readonly">-->
<!--                                    <input matInput placeholder="Úrok po slevě" formControlName="benefitInterestRate" readonly [matTooltip]="'Do slevy se počítá pouze kompenzace sazby.'" />-->
<!--                                  </mat-form-field>-->
                                  <mat-form-field class="readonly">
                                    <input matInput placeholder="RPSN %" formControlName="arp" readonly />
                                    <mat-hint align="start" *ngIf="mainService.maskPercent(product.get('source').value.fixation.arp) !== product.get('arp').value">{{ mainService.maskPercent(product.get('source').value.fixation.arp) }}</mat-hint>
                                  </mat-form-field>
                                  <mat-form-field class="readonly">
                                    <input matInput placeholder="Splátka" formControlName="repayment" [textMask]="{mask: mainService.currencyMask, guide: false}" readonly />
                                    <mat-hint align="start" *ngIf="product.get('source').value.fixation.repayment !== product.get('repayment').value">{{ mainService.maskCurrency(product.get('source').value.fixation.repayment) }}</mat-hint>
                                  </mat-form-field>
                                </div>
                                <div class="form-row">
                                  <mat-form-field class="readonly">
                                    <input matInput placeholder="Výše úvěru" formControlName="amount" [textMask]="{mask: mainService.currencyMask, guide: false}" readonly />
                                  </mat-form-field>
                                  <mat-form-field>
                                    <mat-select placeholder="Bonita spočtena" formControlName="isCreditworthy">
                                      <mat-option [value]="false">Ne</mat-option>
                                      <mat-option [value]="true">Ano</mat-option>
                                    </mat-select>
                                  </mat-form-field>
                                  <mat-form-field>
                                    <input matInput placeholder="Max. výše úvěru" formControlName="maximumLoanAmount" [textMask]="{mask: mainService.currencyMask, guide: false}"/>
                                    <mat-error *ngIf="product.get('maximumLoanAmount').hasError('maxValue')" align="start">{{ mainService.maskCurrency(product.get('source').value.limits.loanAmountMax) }}</mat-error>
                                  </mat-form-field>
<!--                                  <div class="form-button">-->
<!--                                    <button *ngIf="offersService.isStageBetween(offer.stage, null, 'create')" mat-icon-button [color]="product.get('attachment').value ? 'accent' : 'warn'" title="Příloha" [matMenuTriggerFor]="attachmentMenu" [matMenuTriggerData]="{product: product, sendFile: sendFile}"><mat-icon>attach_file</mat-icon></button>-->
<!--                                    <button *ngIf="offersService.isStageBetween(offer.stage, 'created')" mat-icon-button [color]="product.get('attachment').value ? 'accent' : 'warn'" title="Příloha" (click)="product.get('attachment').value ? downloadAttachment(product.get('attachment').value) : null;"><mat-icon>attach_file</mat-icon></button>-->
<!--                                  </div>-->
                                </div>
                                <div class="form-row">
                                  <mat-form-field class="readonly">
                                    <input matInput placeholder="Splatnost" [value]="product.get('maturity').value | maturity" readonly />
                                  </mat-form-field>
                                  <mat-form-field class="readonly">
                                    <input matInput placeholder="Fixace" formControlName="fixation" readonly />
                                  </mat-form-field>
                                  <mat-form-field class="readonly">
                                    <input matInput placeholder="Pojištění" [value]="product.get('insurance').value ? 'Ano' : 'Ne'" readonly />
                                  </mat-form-field>
                                </div>
                                <div class="form-row">
                                  <mat-form-field>
                                    <input matInput placeholder="Číslo smlouvy" formControlName="contractNumber"/>
                                  </mat-form-field>
                                  <mat-form-field>
                                    <input matInput placeholder="Datum podpisu ZZJ" [matDatepicker]="meetingRecordSignedAtPicker" formControlName="meetingRecordSignedAt" />
                                    <mat-datepicker-toggle matSuffix [for]="meetingRecordSignedAtPicker"></mat-datepicker-toggle>
                                    <mat-datepicker #meetingRecordSignedAtPicker></mat-datepicker>
                                  </mat-form-field>
                                </div>
                                <div class="form-row" *ngIf="product.get('source').value.isOffset">
                                  <mat-form-field class="readonly">
                                    <input matInput placeholder="Zůstatek na účtu" formControlName="averageAccountBalance" readonly />
                                  </mat-form-field>
                                  <mat-form-field class="readonly">
                                    <input matInput placeholder="Efektivní úrok %" formControlName="effectiveInterestRate" readonly />
                                  </mat-form-field>
                                </div>
<!--                                <input class="send-file" type="file" #sendFile (change)="onFileSelect($event, product)" />-->
                                <form class="sales" #salesForm="ngForm">
                                  <ng-container *ngFor="let sale of product.get('source').value | saleGroups; let sIndex = index">
                                    <ng-container *ngIf="sale?.length">
                                      <div class="form-row selectcheckbox">
                                        <mat-checkbox #checkbox class="flex-0-0-auto" [name]="'g.' + sale[0].groupOr" [ngModel]="product.get('source').value | isAppliedSale:sale" (ngModelChange)="offersService.recalculateSales(product, salesForm)"></mat-checkbox>
                                        <mat-select class="checkbox" [name]="'g.' + sale[0].groupOr + '.value'" [ngModel]="product.get('source').value | appliedGroupSale:sale" (ngModelChange)="offersService.recalculateSales(product, salesForm)">
                                          <mat-option *ngFor="let option of sale" [value]="option._id">
                                            {{ option.name }}
                                          </mat-option>
                                        </mat-select>
                                      </div>
                                    </ng-container>
                                    <ng-container *ngIf="sale && !sale?.length">
                                      <div class="form-row">
                                        <mat-checkbox [name]="sale?._id" [ngModel]="product.get('source').value | isAppliedSale:[sale]" (ngModelChange)="offersService.recalculateSales(product, salesForm)">{{ sale?.name }}</mat-checkbox>
                                      </div>
                                    </ng-container>
                                  </ng-container>
                                  <ng-container *ngFor="let sale of product.get('source').value | appliedSales">
                                    <div class="form-row">
                                      <mat-checkbox [checked]="true" disabled>{{ sale.name }}</mat-checkbox>
                                    </div>
                                  </ng-container>
                                </form>
                                <div class="fees">
                                  <div class="form-row">
                                    <mat-form-field class="readonly">
                                      <input matInput placeholder="Jednoráz. poplatky" formControlName="totalFeesOnetime" [textMask]="{mask: mainService.currencyMask, guide: false}" readonly />
                                    </mat-form-field>
                                    <mat-form-field class="readonly">
                                      <input matInput placeholder="Měsíční poplatky" formControlName="totalFeesMonthly" [textMask]="{mask: mainService.currencyMask, guide: false}" readonly />
                                    </mat-form-field>
                                    <mat-form-field class="readonly">
                                      <input matInput placeholder="Roční poplatky" formControlName="totalFeesAnnualy" [textMask]="{mask: mainService.currencyMask, guide: false}" readonly />
                                    </mat-form-field>
                                  </div>
                                  <ng-container formGroupName="fees">
                                    <div class="form-row">
                                      <mat-form-field>
                                        <input matInput placeholder="Zpracování" formControlName="provision" [textMask]="{mask: mainService.currencyMask, guide: false}"/>
                                        <mat-hint align="start" *ngIf="product.get('source').value.fixation.fees.provision !== mainService.unmaskCurrency(product.get('fees.provision').value)">{{ mainService.maskCurrency(product.get('source').value.fixation.fees.provision) }}</mat-hint>
                                      </mat-form-field>
                                      <mat-form-field>
                                        <input matInput placeholder="Katastr" formControlName="cadastre" readolny [textMask]="{mask: mainService.currencyMask, guide: false}"/>
                                        <mat-hint align="start" *ngIf="product.get('source').value.fixation.fees.cadastre !== mainService.unmaskCurrency(product.get('fees.cadastre').value)">{{ mainService.maskCurrency(product.get('source').value.fixation.fees.cadastre) }}</mat-hint>
                                      </mat-form-field>
                                      <mat-form-field>
                                        <input matInput placeholder="Ověření podpisu" formControlName="signatureVerification" [textMask]="{mask: mainService.currencyMask, guide: false}"/>
                                        <mat-hint align="start" *ngIf="product.get('source').value.fixation.fees.signatureVerification !== mainService.unmaskCurrency(product.get('fees.signatureVerification').value)">{{ mainService.maskCurrency(product.get('source').value.fixation.fees.signatureVerification) }}</mat-hint>
                                      </mat-form-field>
                                    </div>
                                    <div class="form-row">
                                      <mat-form-field>
                                        <input matInput placeholder="Vedení účtu" formControlName="accountManagement" [textMask]="{mask: mainService.currencyMask, guide: false}"/>
                                        <mat-hint align="start" *ngIf="product.get('source').value.fixation.fees.accountManagement !== mainService.unmaskCurrency(product.get('fees.accountManagement').value)">{{ mainService.maskCurrency(product.get('source').value.fixation.fees.accountManagement) }}</mat-hint>
                                      </mat-form-field>
                                      <mat-form-field>
                                        <input matInput placeholder="Výpis z účtu" formControlName="accountStatement" [textMask]="{mask: mainService.currencyMask, guide: false}"/>
                                        <mat-hint align="start" *ngIf="product.get('source').value.fixation.fees.accountStatement !== mainService.unmaskCurrency(product.get('fees.accountStatement').value)">{{ mainService.maskCurrency(product.get('source').value.fixation.fees.accountStatement) }}</mat-hint>
                                      </mat-form-field>
                                      <mat-form-field>
                                        <input matInput placeholder="Výpis k ukončení" formControlName="loanTerminationSatement" [textMask]="{mask: mainService.currencyMask, guide: false}"/>
                                        <mat-hint align="start" *ngIf="product.get('source').value.fixation.fees.loanTerminationSatement !== mainService.unmaskCurrency(product.get('fees.loanTerminationSatement').value)">{{ mainService.maskCurrency(product.get('source').value.fixation.fees.loanTerminationSatement) }}</mat-hint>
                                      </mat-form-field>
                                    </div>
                                    <div class="form-row">
                                      <mat-form-field>
                                        <input matInput placeholder="Odhad" formControlName="estimate" [textMask]="{mask: mainService.currencyMask, guide: false}"/>
                                        <mat-hint align="start" *ngIf="product.get('source').value.fixation.fees.estimate !== mainService.unmaskCurrency(product.get('fees.estimate').value)">{{ mainService.maskCurrency(product.get('source').value.fixation.fees.estimate) }}</mat-hint>
                                      </mat-form-field>
                                      <mat-form-field>
                                        <input matInput placeholder="Online odhad" formControlName="estimateOnline" [textMask]="{mask: mainService.currencyMask, guide: false}"/>
                                        <mat-hint align="start" *ngIf="product.get('source').value.fixation.fees.estimateOnline !== mainService.unmaskCurrency(product.get('fees.estimateOnline').value)">{{ mainService.maskCurrency(product.get('source').value.fixation.fees.estimateOnline) }}</mat-hint>
                                      </mat-form-field>
                                      <mat-form-field>
                                        <input matInput placeholder="Expresní odhad" formControlName="estimateExpress" [textMask]="{mask: mainService.currencyMask, guide: false}"/>
                                        <mat-hint align="start" *ngIf="product.get('source').value.fixation.fees.estimateExpress !== mainService.unmaskCurrency(product.get('fees.estimateExpress').value)">{{ mainService.maskCurrency(product.get('source').value.fixation.fees.estimateExpress) }}</mat-hint>
                                      </mat-form-field>
                                    </div>
                                    <div class="form-row">
                                      <mat-form-field>
                                        <input matInput placeholder="Pojištění nemovitosti" formControlName="realEstateInsurance" [textMask]="{mask: mainService.currencyMask, guide: false}"/>
                                        <mat-hint align="start" *ngIf="product.get('source').value.fixation.fees.realEstateInsurance !== mainService.unmaskCurrency(product.get('fees.realEstateInsurance').value)">{{ mainService.maskCurrency(product.get('source').value.fixation.fees.realEstateInsurance) }}</mat-hint>
                                      </mat-form-field>
                                      <mat-form-field>
                                        <input matInput placeholder="Pojištění" formControlName="insurance" [textMask]="{mask: mainService.currencyMask, guide: false}"/>
                                        <mat-hint align="start" *ngIf="product.get('source').value.fixation.fees.insurance !== mainService.unmaskCurrency(product.get('fees.insurance').value)">{{ mainService.maskCurrency(product.get('source').value.fixation.fees.insurance) }}</mat-hint>
                                      </mat-form-field>
                                    </div>
                                    <div class="form-row">
                                      <mat-form-field>
                                        <input matInput placeholder="Čerpání" formControlName="drawdown" [textMask]="{mask: mainService.currencyMask, guide: false}"/>
                                        <mat-hint align="start" *ngIf="product.get('source').value.fixation.fees.drawdown !== mainService.unmaskCurrency(product.get('fees.drawdown').value)">{{ mainService.maskCurrency(product.get('source').value.fixation.fees.drawdown) }}</mat-hint>
                                      </mat-form-field>
                                      <mat-form-field>
                                        <input matInput placeholder="Expresní čerpání" formControlName="drawdownExpress" [textMask]="{mask: mainService.currencyMask, guide: false}"/>
                                        <mat-hint align="start" *ngIf="product.get('source').value.fixation.fees.drawdownExpress !== mainService.unmaskCurrency(product.get('fees.drawdownExpress').value)">{{ mainService.maskCurrency(product.get('source').value.fixation.fees.drawdownExpress) }}</mat-hint>
                                      </mat-form-field>
                                    </div>
                                  </ng-container>
                                </div>
                              </div>
                            </div>
                          </ng-container>
                          <ng-container *ngIf="product.get('source').value.type === 'building_savings'">
                            <div class="variant-product" [ngClass]="{'dragover': dragcontainer['dragover']}" #dragcontainer (dragover)="$event.preventDefault();$event.stopPropagation()" (dragenter)="$event.preventDefault();$event.stopPropagation();dragcontainer['dragover'] = true;">
                              <div class="variant-product-detail">
                                <div class="form-row">
                                  <mat-form-field class="readonly">
                                    <input matInput placeholder="Produkt" [value]="product.get('source').value.name" readonly/>
                                    <!--<input matInput placeholder="Produkt" [value]="product.get('source').value.provider.stringCode + ' - ' + product.get('source').value.name" readonly/>-->
                                  </mat-form-field>
                                  <div class="form-button">
                                    <button *ngIf="!selectingSourceProduct || selectingSourceProduct.productIndex !== pIndex || selectingSourceProduct.variant.controls.id.value !== variant.value.id" mat-icon-button color="primary" title="Změnit produkt" (click)="showSourceProducts(variant, pIndex, product.get('productTemplate').value)"><mat-icon>layers</mat-icon></button>
                                    <button *ngIf="selectingSourceProduct && selectingSourceProduct.productIndex === pIndex && selectingSourceProduct.variant.controls.id.value === variant.value.id" mat-icon-button color="warn" title="Ukončit změnu produktu" (click)="showSourceProducts(null)"><mat-icon>layers</mat-icon></button>
                                  </div>
                                </div>
                                <div class="form-row">
                                  <mat-form-field *ngIf="product.get('source').value.isBridging">
                                    <input matInput placeholder="Dep. úrok %" formControlName="depositInterestRate"/>
                                    <mat-hint align="start" *ngIf="mainService.maskPercent(product.get('source').value.fixation.depositInterestRate) !== product.get('depositInterestRate').value">{{ mainService.maskPercent(product.get('source').value.fixation.depositInterestRate) }}</mat-hint>
                                  </mat-form-field>
                                  <mat-form-field>
                                    <input matInput placeholder="Úrok %" formControlName="interestRate"/>
                                    <!--<mat-icon matSuffix class="info-suffix" [matTooltip]="'Informativní výše úvěru: ' + product.get('interestRateAmount').value">attach_money</mat-icon>-->
                                    <mat-hint align="start" *ngIf="mainService.maskPercent(product.get('source').value.fixation.interestRate) !== product.get('interestRate').value">{{ mainService.maskPercent(product.get('source').value.fixation.interestRate) }}</mat-hint>
                                  </mat-form-field>
                                  <mat-form-field>
                                    <input matInput placeholder="RPSN %" formControlName="arp"/>
                                    <!--<mat-hint align="start" *ngIf="maskPercent(product.get('source').value.fixation.arp) !== product.get('arp').value">{{ maskPercent(product.get('source').value.fixation.arp) }}</mat-hint>-->
                                  </mat-form-field>
                                  <mat-form-field>
                                    <input matInput placeholder="Splátka" formControlName="repayment" [textMask]="{mask: mainService.currencyMask, guide: false}"/>
                                    <!--<mat-hint align="start" *ngIf="product.get('source').value.fixation.repayment !== product.get('repayment').value">{{ maskCurrency(product.get('source').value.fixation.repayment) }}</mat-hint>-->
                                  </mat-form-field>
                                </div>
                                <div class="form-row">
                                  <mat-form-field class="readonly">
                                    <input matInput placeholder="Výše úvěru" formControlName="amount" [textMask]="{mask: mainService.currencyMask, guide: false}" readonly />
                                  </mat-form-field>
                                  <mat-form-field>
                                    <mat-select placeholder="Bonita spočtena" formControlName="isCreditworthy">
                                      <mat-option [value]="false">Ne</mat-option>
                                      <mat-option [value]="true">Ano</mat-option>
                                    </mat-select>
                                  </mat-form-field>
                                  <mat-form-field>
                                    <input matInput placeholder="Max. výše úvěru" formControlName="maximumLoanAmount" [textMask]="{mask: mainService.currencyMask, guide: false}"/>
                                    <mat-error *ngIf="product.get('maximumLoanAmount').hasError('maxValue')" align="start">{{ mainService.maskCurrency(product.get('source').value.limits.loanAmountMax) }}</mat-error>
                                  </mat-form-field>
                                </div>
                                <div class="form-row">
                                  <mat-form-field class="readonly">
                                    <input matInput placeholder="Splatnost" [value]="product.get('maturity').value | maturity" readonly />
                                  </mat-form-field>
                                  <mat-form-field class="readonly">
                                    <input matInput placeholder="Fixace" formControlName="fixation" readonly />
                                  </mat-form-field>
                                  <mat-form-field class="readonly">
                                    <input matInput placeholder="Pojištění" [value]="product.get('insurance').value ? 'Ano' : 'Ne'" readonly />
                                  </mat-form-field>
                                  <mat-form-field>
                                    <input matInput placeholder="Dospořování" formControlName="upSavings"/>
                                  </mat-form-field>
                                </div>
                                <div class="form-row">
                                  <mat-form-field>
                                    <input matInput placeholder="Číslo smlouvy" formControlName="contractNumber"/>
                                  </mat-form-field>
                                  <mat-form-field>
                                    <input matInput placeholder="Datum podpisu ZZJ" [matDatepicker]="meetingRecordSignedAtPicker" formControlName="meetingRecordSignedAt" />
                                    <mat-datepicker-toggle matSuffix [for]="meetingRecordSignedAtPicker"></mat-datepicker-toggle>
                                    <mat-datepicker #meetingRecordSignedAtPicker></mat-datepicker>
                                  </mat-form-field>
                                </div>
                                <input class="send-file" type="file" #sendFile (change)="onFileSelect($event, product)" />
                                <div class="fees">
                                  <div class="form-row">
                                    <mat-form-field class="readonly">
                                      <input matInput placeholder="Jednoráz. poplatky" formControlName="totalFeesOnetime" [textMask]="{mask: mainService.currencyMask, guide: false}" readonly />
                                    </mat-form-field>
                                    <mat-form-field class="readonly">
                                      <input matInput placeholder="Měsíční poplatky" formControlName="totalFeesMonthly" [textMask]="{mask: mainService.currencyMask, guide: false}" readonly />
                                    </mat-form-field>
                                    <mat-form-field class="readonly">
                                      <input matInput placeholder="Roční poplatky" formControlName="totalFeesAnnualy" [textMask]="{mask: mainService.currencyMask, guide: false}" readonly />
                                    </mat-form-field>
                                  </div>
                                  <ng-container formGroupName="fees">
                                    <div class="form-row">
                                      <mat-form-field>
                                        <input matInput placeholder="Vstupní poplatek" formControlName="entryFee" [textMask]="{mask: mainService.currencyMask, guide: false}"/>
                                        <mat-hint align="start" *ngIf="product.get('source').value.fixation.fees.entryFee !== mainService.unmaskCurrency(product.get('fees.entryFee').value)">{{ mainService.maskCurrency(product.get('source').value.fixation.fees.entryFee) }}</mat-hint>
                                      </mat-form-field>
                                      <mat-form-field>
                                        <input matInput placeholder="Vedení vkl. účtu" formControlName="depositAccountManagement" [textMask]="{mask: mainService.currencyMask, guide: false}"/>
                                        <mat-hint align="start" *ngIf="product.get('source').value.fixation.fees.depositAccountManagement !== mainService.unmaskCurrency(product.get('fees.depositAccountManagement').value)">{{ mainService.maskCurrency(product.get('source').value.fixation.fees.depositAccountManagement) }}</mat-hint>
                                      </mat-form-field>
                                      <mat-form-field>
                                        <input matInput placeholder="Výpis z vkl. účtu" formControlName="depositAccountStatement" [textMask]="{mask: mainService.currencyMask, guide: false}"/>
                                        <mat-hint align="start" *ngIf="product.get('source').value.fixation.fees.depositAccountStatement !== mainService.unmaskCurrency(product.get('fees.depositAccountStatement').value)">{{ mainService.maskCurrency(product.get('source').value.fixation.fees.depositAccountStatement) }}</mat-hint>
                                      </mat-form-field>
                                    </div>
                                    <div class="form-row">
                                      <mat-form-field>
                                        <input matInput placeholder="Zpracování" formControlName="provision" [textMask]="{mask: mainService.currencyMask, guide: false}"/>
                                        <mat-hint align="start" *ngIf="product.get('source').value.fixation.fees.provision !== mainService.unmaskCurrency(product.get('fees.provision').value)">{{ mainService.maskCurrency(product.get('source').value.fixation.fees.provision) }}</mat-hint>
                                      </mat-form-field>
                                      <mat-form-field>
                                        <input matInput placeholder="Katastr" formControlName="cadastre" [textMask]="{mask: mainService.currencyMask, guide: false}"/>
                                        <mat-hint align="start" *ngIf="product.get('source').value.fixation.fees.cadastre !== mainService.unmaskCurrency(product.get('fees.cadastre').value)">{{ mainService.maskCurrency(product.get('source').value.fixation.fees.cadastre) }}</mat-hint>
                                      </mat-form-field>
                                      <mat-form-field>
                                        <input matInput placeholder="Ověření podpisu" formControlName="signatureVerification" [textMask]="{mask: mainService.currencyMask, guide: false}"/>
                                        <mat-hint align="start" *ngIf="product.get('source').value.fixation.fees.signatureVerification !== mainService.unmaskCurrency(product.get('fees.signatureVerification').value)">{{ mainService.maskCurrency(product.get('source').value.fixation.fees.signatureVerification) }}</mat-hint>
                                      </mat-form-field>
                                    </div>
                                    <div class="form-row">
                                      <mat-form-field>
                                        <input matInput placeholder="Vedení účtu" formControlName="accountManagement" [textMask]="{mask: mainService.currencyMask, guide: false}"/>
                                        <mat-hint align="start" *ngIf="product.get('source').value.fixation.fees.accountManagement !== mainService.unmaskCurrency(product.get('fees.accountManagement').value)">{{ mainService.maskCurrency(product.get('source').value.fixation.fees.accountManagement) }}</mat-hint>
                                      </mat-form-field>
                                      <mat-form-field>
                                        <input matInput placeholder="Výpis z účtu" formControlName="accountStatement" [textMask]="{mask: mainService.currencyMask, guide: false}"/>
                                        <mat-hint align="start" *ngIf="product.get('source').value.fixation.fees.accountStatement !== mainService.unmaskCurrency(product.get('fees.accountStatement').value)">{{ mainService.maskCurrency(product.get('source').value.fixation.fees.accountStatement) }}</mat-hint>
                                      </mat-form-field>
                                      <mat-form-field>
                                        <input matInput placeholder="Výpis k ukončení" formControlName="loanTerminationSatement" [textMask]="{mask: mainService.currencyMask, guide: false}"/>
                                        <mat-hint align="start" *ngIf="product.get('source').value.fixation.fees.loanTerminationSatement !== mainService.unmaskCurrency(product.get('fees.loanTerminationSatement').value)">{{ mainService.maskCurrency(product.get('source').value.fixation.fees.loanTerminationSatement) }}</mat-hint>
                                      </mat-form-field>
                                    </div>
                                    <div class="form-row">
                                      <mat-form-field>
                                        <input matInput placeholder="Odhad" formControlName="estimate" [textMask]="{mask: mainService.currencyMask, guide: false}"/>
                                        <mat-hint align="start" *ngIf="product.get('source').value.fixation.fees.estimate !== mainService.unmaskCurrency(product.get('fees.estimate').value)">{{ mainService.maskCurrency(product.get('source').value.fixation.fees.estimate) }}</mat-hint>
                                      </mat-form-field>
                                      <mat-form-field>
                                        <input matInput placeholder="Online odhad" formControlName="estimateOnline" [textMask]="{mask: mainService.currencyMask, guide: false}"/>
                                        <mat-hint align="start" *ngIf="product.get('source').value.fixation.fees.estimateOnline !== mainService.unmaskCurrency(product.get('fees.estimateOnline').value)">{{ mainService.maskCurrency(product.get('source').value.fixation.fees.estimateOnline) }}</mat-hint>
                                      </mat-form-field>
                                      <mat-form-field>
                                        <input matInput placeholder="Expresní odhad" formControlName="estimateExpress" [textMask]="{mask: mainService.currencyMask, guide: false}"/>
                                        <mat-hint align="start" *ngIf="product.get('source').value.fixation.fees.estimateExpress !== mainService.unmaskCurrency(product.get('fees.estimateExpress').value)">{{ mainService.maskCurrency(product.get('source').value.fixation.fees.estimateExpress) }}</mat-hint>
                                      </mat-form-field>
                                    </div>
                                    <div class="form-row">
                                      <mat-form-field>
                                        <input matInput placeholder="Pojištění nemovitosti" formControlName="realEstateInsurance" [textMask]="{mask: mainService.currencyMask, guide: false}"/>
                                        <mat-hint align="start" *ngIf="product.get('source').value.fixation.fees.realEstateInsurance !== mainService.unmaskCurrency(product.get('fees.realEstateInsurance').value)">{{ mainService.maskCurrency(product.get('source').value.fixation.fees.realEstateInsurance) }}</mat-hint>
                                      </mat-form-field>
                                      <mat-form-field>
                                        <input matInput placeholder="Pojištění" formControlName="insurance" [textMask]="{mask: mainService.currencyMask, guide: false}"/>
                                        <mat-hint align="start" *ngIf="product.get('source').value.fixation.fees.insurance !== mainService.unmaskCurrency(product.get('fees.insurance').value)">{{ mainService.maskCurrency(product.get('source').value.fixation.fees.insurance) }}</mat-hint>
                                      </mat-form-field>
                                    </div>
                                    <div class="form-row">
                                      <mat-form-field>
                                        <input matInput placeholder="Čerpání" formControlName="drawdown" [textMask]="{mask: mainService.currencyMask, guide: false}"/>
                                        <mat-hint align="start" *ngIf="product.get('source').value.fixation.fees.drawdown !== mainService.unmaskCurrency(product.get('fees.drawdown').value)">{{ mainService.maskCurrency(product.get('source').value.fixation.fees.drawdown) }}</mat-hint>
                                      </mat-form-field>
                                      <mat-form-field>
                                        <input matInput placeholder="Expresní čerpání" formControlName="drawdownExpress" [textMask]="{mask: mainService.currencyMask, guide: false}"/>
                                        <mat-hint align="start" *ngIf="product.get('source').value.fixation.fees.drawdownExpress !== mainService.unmaskCurrency(product.get('fees.drawdownExpress').value)">{{ mainService.maskCurrency(product.get('source').value.fixation.fees.drawdownExpress) }}</mat-hint>
                                      </mat-form-field>
                                    </div>
                                  </ng-container>
                                </div>
                              </div>
                            </div>
                          </ng-container>
                        </mat-tab>
                      </ng-container>
                    </mat-tab-group>
<!--                    <div class="variant-summary">-->
<!--                      <div class="form-row">-->
<!--                        <mat-form-field class="readonly">-->
<!--                          <input matInput placeholder="Zvýhodnění" formControlName="benefitsTotalAmount" [textMask]="{mask: mainService.currencyMask, guide: false}" readonly />-->
<!--                        </mat-form-field>-->
<!--                        <mat-form-field class="readonly">-->
<!--                          <input matInput placeholder="Zvýhodnění banking" formControlName="benefitsBankingTotalAmount" [textMask]="{mask: mainService.currencyMask, guide: false}" readonly />-->
<!--                        </mat-form-field>-->
<!--                        <div class="form-button">-->
<!--                          <button mat-icon-button color="primary" title="Změnit produkt" (click)="offersService.addBenefit(variant)"><mat-icon>add</mat-icon></button>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                      <ng-container formGroupName="benefits">-->
<!--                        <ng-container *ngFor="let benefit of variant.get('benefits').controls;let bIndex = index">-->
<!--                          <div class="form-row" [formArrayName]="bIndex">-->
<!--                            <mat-form-field>-->
<!--                              <mat-select placeholder="Typ zvýhodnění" formControlName="type" >-->
<!--                                <mat-option *ngFor="let option of (options('benefit') | uniqueOptions:benefit.get('type').value:offersService.benefitsType(variant))" [value]="option.value">{{ option.label }}</mat-option>-->
<!--                              </mat-select>-->
<!--                            </mat-form-field>-->
<!--                            <mat-form-field class="w30">-->
<!--                              <input matInput placeholder="Částka" formControlName="amount"  [textMask]="{mask: mainService.currencyMask, guide: false}"/>-->
<!--                            </mat-form-field>-->
<!--                            <button mat-icon-button color="warn" (click)="offersService.removeBenefit(variant, bIndex)"><mat-icon>remove_circle_outline</mat-icon></button>-->
<!--                          </div>-->
<!--                        </ng-container>-->
<!--                      </ng-container>-->
<!--                    </div>-->
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="variant add-variant">
            <div class="card-title">
              <span>Navrhované parametry modelace</span>
            </div>
            <div class="content" [matMenuTriggerFor]="addVariantMenu" [matMenuTriggerData]="{variant: null}">
              <div class="property">Výše úvěru: <b>{{ mainService.maskCurrency(modelation.get('amount').value) }}</b></div>
              <div class="property">LTV: <b>{{ modelation.get('ltv').value }}%</b></div>
              <div class="property">Fixace: <b>{{ modelation.get('fixation').value }}</b></div>
              <div class="property">Splatnost: <b>{{ modelation.get('maturity').value | maturity }}</b></div>
              <div class="property">Pojištění: <b>{{ modelation.get('insurance').value ? 'Ano' : 'Ne' }}</b></div>
              <div class="property">Offset: <b>{{ mainService.optionLabel(options('offsetType'), modelation.get('offsetType').value) }}</b></div>
              <span>[Kliknutím přidáte novou variantu]</span>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </ng-container>
  <div class="source-products" *ngIf="selectingSourceProduct">
    <button class="close" mat-icon-button color="warn" title="Zavřít" (click)="closeSourceProducts()"><mat-icon>close</mat-icon></button>
    <div class="source-products-filter" [formGroup]="sourceProductsForm">
      <div class="form-row">
        <mat-form-field>
          <input matInput placeholder="Výše úvěru" [textMask]="{mask: mainService.currencyMask, guide: false}" formControlName="amount" />
          <button mat-button matSuffix mat-icon-button (click)="sourceProductsForm.get('amount').reset()"><mat-icon>close</mat-icon></button>
        </mat-form-field>
        <mat-form-field class="maturity">
          <input matInput placeholder="Splatnost" formControlName="maturityYears" [readonly]="!offersService.canEdit()" /><span>r</span>
          <span class="space">, </span>
          <input matInput formControlName="maturityMonths" [readonly]="!offersService.canEdit()" (click)="$event.stopPropagation()" #maturityMonths /><span (click)="maturityMonths.focus();$event.stopPropagation()">m</span>
          <button mat-button matSuffix mat-icon-button (click)="sourceProductsForm.get('maturityMonths').reset();sourceProductsForm.get('maturityYears').reset()"><mat-icon>close</mat-icon></button>
        </mat-form-field>
        <mat-form-field>
          <input matInput type="number" placeholder="Fixace" formControlName="fixation" />
          <button mat-button matSuffix mat-icon-button (click)="sourceProductsForm.get('fixation').reset()"><mat-icon>close</mat-icon></button>
        </mat-form-field>
        <mat-form-field *ngIf="selectingSourceProduct.productTemplate.filter.type === 'mortgage'">
          <mat-select placeholder="Typ offsetu" formControlName="offsetType">
            <mat-option *ngFor="let option of options('offsetType')" [value]="option.value">{{ option.label }}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field *ngIf="selectingSourceProduct.productTemplate.filter.type === 'mortgage' && sourceProductsForm.get('offsetType').value !== 'none'">
          <input matInput placeholder="Zůstatek na účtu" [textMask]="{mask: mainService.currencyMask, guide: false}" formControlName="averageAccountBalance" />
          <button mat-button matSuffix mat-icon-button (click)="sourceProductsForm.get('averageAccountBalance').reset()"><mat-icon>close</mat-icon></button>
        </mat-form-field>
      </div>
      <div class="form-row">
        <mat-form-field class="w50">
          <mat-select placeholder="Doplňky" formControlName="others" multiple>
            <mat-option [value]="'esexpress'">Expresní odhad</mat-option>
            <mat-option [value]="'esonline'">Odhad online</mat-option>
            <mat-option [value]="'ddexpress'">Expresní čerpání</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="w30">
          <mat-select placeholder="Limitace" formControlName="nolimits">
            <mat-option [value]="true">Ne</mat-option>
            <mat-option [value]="false">Ano</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="w30">
          <mat-select placeholder="Dofinancování" formControlName="anotherFinancingSourceApply">
            <mat-option [value]="false">Ne</mat-option>
            <mat-option [value]="true">Ano</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field>
          <mat-chip-list #chipListProductsPurpose>
            <mat-chip *ngFor="let chip of sourceProductsForm.get('purpose').value" [selectable]="false" [removable]="true" (removed)="mainService.removeChip(chip, sourceProductsForm.get('purpose'))" [value]="chip" color="default">{{ mainService.optionLabel(options('purpose'), chip) }}<mat-icon matChipRemove>cancel</mat-icon></mat-chip>
          </mat-chip-list>
          <input
              #chipInputProductsPurpose
              placeholder="Účel"
              [matChipInputSeparatorKeyCodes]="mainService.separatorKeysCodes"
              [matChipInputFor]="chipListProductsPurpose"
              [matAutocomplete]="matAutoChipProductsPurpose"
              [matChipInputAddOnBlur]="false"
          />
          <mat-autocomplete #matAutoChipProductsPurpose="matAutocomplete" (optionSelected)="mainService.selectedChipItem($event, sourceProductsForm.get('purpose'));chipInputProductsPurpose.value = '';">
            <mat-option *ngFor="let item of mainService.filterChipItems(options('purpose'), sourceProductsForm.get('purpose'))" [value]="item.value">
              {{ mainService.optionLabel(options('purpose'), item.value) }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
    </div>
    <div class="card card-list">
      <table class="list">
        <thead>
        <tr class="header">
          <th class="dynamic sorting"><span (click)="productsService.sort('name')"><span>Název</span><mat-icon *ngIf="!productsService.isSorting('name')"></mat-icon><mat-icon *ngIf="productsService.isSorting('name', 1)">arrow_drop_down</mat-icon><mat-icon *ngIf="productsService.isSorting('name', -1)">arrow_drop_up</mat-icon></span></th>
          <th class="dynamic sorting"><span (click)="productsService.sort('provider.name')"><span>Poskytovatel</span><mat-icon *ngIf="!productsService.isSorting('provider.name')"></mat-icon><mat-icon *ngIf="productsService.isSorting('provider.name', 1)">arrow_drop_down</mat-icon><mat-icon *ngIf="productsService.isSorting('provider.name', -1)">arrow_drop_up</mat-icon></span></th>
          <th class="sorting"><span (click)="productsService.sort('fixation.arp')"><span>RPSN %</span><mat-icon *ngIf="!productsService.isSorting('fixation.arp')"></mat-icon><mat-icon *ngIf="productsService.isSorting('fixation.arp', 1)">arrow_drop_down</mat-icon><mat-icon *ngIf="productsService.isSorting('fixation.arp', -1)">arrow_drop_up</mat-icon></span></th>
          <th class="sorting"><span (click)="productsService.sort('fixation.effectiveInterestRate')"><span>Úrok %</span><mat-icon *ngIf="!productsService.isSorting('fixation.effectiveInterestRate')"></mat-icon><mat-icon *ngIf="productsService.isSorting('fixation.effectiveInterestRate', 1)">arrow_drop_down</mat-icon><mat-icon *ngIf="productsService.isSorting('fixation.effectiveInterestRate', -1)">arrow_drop_up</mat-icon></span></th>
          <th class="sorting"><span (click)="productsService.sort('fixation.totalFees')"><span>Poplatky</span><mat-icon *ngIf="!productsService.isSorting('fixation.totalFees')"></mat-icon><mat-icon *ngIf="productsService.isSorting('fixation.totalFees', 1)">arrow_drop_down</mat-icon><mat-icon *ngIf="productsService.isSorting('fixation.totalFees', -1)">arrow_drop_up</mat-icon></span></th>
          <th class="sorting"><span (click)="productsService.sort('fixation.dti')"><span>DTI</span><mat-icon *ngIf="!productsService.isSorting('fixation.dti')"></mat-icon><mat-icon *ngIf="productsService.isSorting('fixation.dti', 1)">arrow_drop_down</mat-icon><mat-icon *ngIf="productsService.isSorting('fixation.dti', -1)">arrow_drop_up</mat-icon></span></th>
          <th class="sorting"><span (click)="productsService.sort('fixation.dsti')"><span>DSTI %</span><mat-icon *ngIf="!productsService.isSorting('fixation.dsti')"></mat-icon><mat-icon *ngIf="productsService.isSorting('fixation.dsti', 1)">arrow_drop_down</mat-icon><mat-icon *ngIf="productsService.isSorting('fixation.dsti', -1)">arrow_drop_up</mat-icon></span></th>
          <th><span><span>Úprava objemu %</span></span></th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let product of (productsService.items$ | async);" [ngClass]="{'is-warning': product.fixation.dti > 9 || product.fixation.dsti > 0.45}">
          <td class="filter-padding"><span class="overflow-container"><span class="overflow-elipsis"><a (click)="chooseSourceProduct(product)">{{ product.name }}</a></span></span></td>
          <td class="filter-padding"><span class="overflow-container"><span class="overflow-elipsis">{{ product.provider.shortName }}</span></span></td>
          <td class="filter-padding"><span class="nowrap-container">{{ mainService.maskPercent(product.fixation.arp) }}</span></td>
          <td class="filter-padding"><span class="nowrap-container">{{ mainService.maskPercent(product.fixation.effectiveInterestRate) }}</span></td>
          <td class="filter-padding"><span class="nowrap-container">{{ mainService.maskCurrency(product.fixation.totalOnetimeFees) }}</span></td>
          <td class="filter-padding" [ngClass]="{'is-warning': product.fixation.dti > 9}"><span class="nowrap-container">{{ mainService.maskFloat(product.fixation.dti) }}</span></td>
          <td class="filter-padding" [ngClass]="{'is-warning': product.fixation.dsti > 0.45}"><span class="nowrap-container">{{ mainService.maskPercent(product.fixation.dsti) }}</span></td>
          <td class="filter-padding"><span class="nowrap-container">{{ mainService.maskPercent(product.volumeAdjustment) }}</span></td>
        </tr>
        <tr *ngIf="!(productsService.isLoading$ | async) && !(productsService.items$ | async).length"><td colspan="8">Pro zadané parametry mi to nic nenašlo.</td></tr>
        </tbody>
      </table>
      <div class="list-load-more" *ngIf="productsService.isLoading$ | async">
        <button mat-button color="primary" (click)="productsService.load()" [disabled]="productsService.isLoading$ | async">
          <mat-spinner [diameter]="30"></mat-spinner>
        </button>
      </div>
    </div>
  </div>
</div>
<mat-menu #addVariantMenu="matMenu">
  <ng-template matMenuContent let-variant="variant">
    <ng-container *ngFor="let productTemplate of offersService.availableVariantProducts(variant)">
      <button mat-menu-item (click)="addVariant(productTemplate, variant)"><mat-icon>layers</mat-icon>{{ productTemplate.name }}</button>
    </ng-container>
  </ng-template>
</mat-menu>
<mat-menu #variantMenu="matMenu">
  <ng-template matMenuContent let-index="index" let-variant="variant">
    <button mat-menu-item (click)="copyVariant(index)" *ngIf="variant.get('products').controls.length"><mat-icon>file_copy</mat-icon>Vytvořit kopii varianty</button>
    <button mat-menu-item (click)="removeVariant(index)"><mat-icon>delete</mat-icon>Smazat variantu</button>
  </ng-template>
</mat-menu>
<mat-menu #addModelationMenu="matMenu">
  <ng-template matMenuContent>
    <button mat-menu-item (click)="offersService.addModelation(null, true)"><mat-icon>add</mat-icon>S pojištěním</button>
    <button mat-menu-item (click)="offersService.addModelation(null, false)"><mat-icon>add</mat-icon>Bez pojištění</button>
  </ng-template>
</mat-menu>
<mat-menu #attachmentMenu="matMenu">
  <ng-template matMenuContent let-product="product" let-sendFile="sendFile">
    <button mat-menu-item (click)="downloadAttachment(product.get('attachment').value)" *ngIf="product.get('attachment').value"><mat-icon>remove_red_eye</mat-icon>Zobrazit přílohu</button>
    <button mat-menu-item (click)="sendFile.click()"><mat-icon>attach_file</mat-icon>Nahrát přílohu</button>
    <button mat-menu-item (click)="removeAttachment(product)" *ngIf="product.get('attachment').value"><mat-icon>delete</mat-icon>Odstranit přílohu</button>
  </ng-template>
</mat-menu>
