import { Injectable } from '@angular/core';
import {DataService} from '@app/services/data.service';
import {DaktelaCallService} from '@app/services/daktela-call.service';
import {Call, DialNumberParam} from '@app/models/calls.model';
import {UsersService} from '@app/services/users.service';
import {BehaviorSubject, Observable} from 'rxjs';
import {DocumentTemplateInterface} from '@app/interfaces/document-template.interface';

@Injectable({providedIn: 'root'})
export class CallsService extends DataService<Call> {

  public activeCall: Call = null;
  public incomingCall: Call = null;
  public $activeCall: BehaviorSubject<Call> = new BehaviorSubject(this.activeCall);
  public activeCall$: Observable<Call> = this.$activeCall.asObservable();
  public $incomingCall: BehaviorSubject<Call> = new BehaviorSubject(this.incomingCall);
  public incomingCall$: Observable<Call> = this.$incomingCall.asObservable();
  public $callInitiating: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public callInitiating$: Observable<boolean> = this.$callInitiating.asObservable();

  constructor(
    private communicationsService: DaktelaCallService,
    private usersService: UsersService,
  ) {
    super('calls');
    this.pagination = 20;
    this.sort = {
      callTime: -1
    };
    this.query = {
      userId: this.usersService.user._id
    };
    this.$items.subscribe(items => {
      this.activeCall = items.find(item => item.answered !== undefined && item.duration === undefined && !item.hangup);
      this.$activeCall.next(this.activeCall);
      this.incomingCall = items.find(item => item._id !== this.activeCall?._id && !item.hangup && !item.ignored && item.answered === undefined);
      this.$incomingCall.next(this.incomingCall);
      this.$callInitiating.next(false);
    });
  }

  public async initiateCall(param: DialNumberParam): Promise<any> {
    try {
      this.$callInitiating.next(true);
      await this.create({
        userId: this.usersService.user._id,
        contactId: param.contactId,
        phoneNumber: param.phoneNumber || (param.countryCode + param.number),
        callContext: {
          url: window.location.href,
          origin: param.origin,
          pipelineId: param.pipelineId,
          dealId: param.dealId,
          dealOwnerId: param.dealOwnerId,
          stage: param.stage
        }
      });
    } catch (e) {
      this.$callInitiating.next(false);
      console.error('CommunicationsService.dialNumber', e);
      return false;
    }
    return true;
  }


  /*
   * Vrátí true, pokud uživatel používá kontaktní centrum
   */
  public isContactCentre(): boolean {
    return true;
  }

  /*
   * Vrátí true, pokud uživatel používá kontaktní centrum a je připojen
   */
  public isConnected(): boolean {
    return true;
  }

  /*
   * Vrátí true, pokud právě probíhá hovor přes kontaktní centrum
   */
  public isOngoingCall(): boolean {
    return false;
  }

  /*
   * Pokud uživatel nepoužívá Daktelu, vrátí true -> dialNumber probíhá přes <a href="tel:123456789">
   * Pokud uživatel používá Daktelu, tak vrátí true pokud je připojen v komunikátoru a zároveň právě neprobíhá hovor
   */
  public canDialNumber(): boolean {
    if (this.isContactCentre()) {
      return (this.isConnected() && !this.isOngoingCall());
    } else {
      return true;
    }
  }

  /*
   * Odmítnutí příchozího hovoru
   */
  async hangUpCall(call: Call) {
    this.patch(call._id, {hangup: true});
    await this.communicationsService.get(call.daktelaActivityId, {query: {action: 'hang_up_call'}});
  }

  async ignoreCall(call: Call) {
    await this.patch(call._id, {ignored: true});
  }
}
