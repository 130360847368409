import { Pipe, PipeTransform } from '@angular/core';
import {Property} from '@app/models/property.model';
import {AbstractControl, FormArray} from '@angular/forms';
import {Contact} from '@app/models/contact.model';

@Pipe({ name: 'getContact' })
export class ContactGetPipe implements PipeTransform {
  constructor() {}
  transform(contactId: string, contacts: Contact[]) {
    return contacts.find((c: any) => c._id === contactId) || null;
  }
}
