import { Component } from '@angular/core';
import {TitleService} from '../../services/title.service';

@Component({
    selector: 'app-settings',
    templateUrl: 'settings.component.html',
    styleUrls: ['settings.component.scss']
})

export class SettingsComponent {

    constructor(
        private titleService: TitleService
    ) {
      this.titleService.setTitle('Nastavení');
    }
}
