import { MatDialogRef } from '@angular/material/dialog';
import {AfterViewInit, Component, OnInit} from '@angular/core';
import {UsersService} from '../../services/users.service';
import {UserInterface} from '../../interfaces/user.interface';
import {AffiliatesService} from '@app/services/affiliates.service';
import {BehaviorSubject, Observable} from 'rxjs';
import {Validators} from '@angular/forms';

@Component({
  selector: 'app-dialog',
  template: `
    <h2 mat-dialog-title><span>Vymazat poplatky</span></h2>
    <mat-dialog-content>
      <div class="form-row">
        <mat-form-field>
          <mat-select placeholder="Poplatky" multiple [(ngModel)]="fees" name="fees">
            <mat-option [value]="'entryFee'">Vstupní poplatek</mat-option>
            <mat-option [value]="'depositAccountManagement'">Vedení vkl. účtu</mat-option>
            <mat-option [value]="'depositAccountStatement'">Výpis z vkl. účtu</mat-option>
            <mat-option [value]="'provision'">Zpracování</mat-option>
            <mat-option [value]="'cadastre'">Katastr</mat-option>
            <mat-option [value]="'signatureVerification'">Ověření podpisu</mat-option>
            <mat-option [value]="'accountManagement'">Vedení účtu</mat-option>
            <mat-option [value]="'accountStatement'">Výpis z účtu</mat-option>
            <mat-option [value]="'loanTerminationSatement'">Výpis k ukončení</mat-option>
            <mat-option [value]="'estimate'">Odhad</mat-option>
            <mat-option [value]="'estimateOnline'">Online odhad</mat-option>
            <mat-option [value]="'estimateExpress'">Expresní odhad</mat-option>
            <mat-option [value]="'realEstateInsurance'">Pojištění nemovitosti</mat-option>
            <mat-option [value]="'insurance'">Pojištění</mat-option>
            <mat-option [value]="'drawdown'">Čerpání</mat-option>
            <mat-option [value]="'drawdownExpress'">Expresní čerpání</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions>
      <button type="button" mat-button color="warn" (click)="dialogRef.close(null)">Zrušit</button>
      <button type="button" mat-raised-button color="accent" (click)="dialogRef.close(fees)">Potvrdit</button>
    </mat-dialog-actions>
  `,
})
export class FeesDialogComponent implements OnInit {

  public fees: string[] = [];

  constructor(
    public dialogRef: MatDialogRef<FeesDialogComponent>,
  ) {
  }

  ngOnInit() {
    this.fees = [
      'entryFee',
      'depositAccountManagement',
      'depositAccountStatement',
      'provision',
      'accountManagement',
      'accountStatement',
      'loanTerminationSatement',
      'estimate',
      'estimateOnline',
      'estimateExpress',
      'cadastre',
      'cadastreWithBenefit',
      'realEstateInsurance',
      'drawdownExpress'
    ];
  }
}
