<div class="timeline-item" [ngClass]="item.type">
  <div class="timeline-left">
    <div class="timeline-date">
      <b>{{item.date | dateTimeline}}</b>
      <span>{{item.date | date:'shortDate':undefined:'cs-CS'}} {{item.date | date:'HH:mm:ss'}}</span>
    </div>
    <div *ngIf="item.isFutureActivity && (item.type === 'sms' || item.type === 'email')" class="timeline-extra">
      <div *ngIf="(timer$ | async) as timer" [ngClass]="{red: timer > 0 && timer < 60}">
        Odeslání za {{ item.data.sendAt | elapsedTime : true }}
      </div>
    </div>

    <span class="timeline-icon">
      <mat-icon *ngIf="task">task</mat-icon>
      <mat-icon *ngIf="email">mail</mat-icon>
      <mat-icon *ngIf="sms">message_outline</mat-icon>
      <mat-icon *ngIf="call">call</mat-icon>
      <ng-container *ngIf="activity">
        <mat-icon *ngIf="activity.type === 'stage-changed'">fast_forward</mat-icon>
        <mat-icon *ngIf="activity.type === 'platform-change'">draw</mat-icon>
        <mat-icon *ngIf="activity.type === 'files-uploaded'">upload_file</mat-icon>
        <mat-icon *ngIf="activity.type === 'confirmed-candidates'">check</mat-icon>
        <mat-icon *ngIf="activity.type === 'owner-changed'">forward</mat-icon>
        <mat-icon *ngIf="activity.type === 'source-deal'">file_copy</mat-icon>
        <mat-icon *ngIf="['video-call','video-sent'].includes(activity.type)">videocam</mat-icon>
      </ng-container>
    </span>
  </div>
  <div class="timeline-right">

    <ng-container *ngIf="task">
      <div class="timeline-title">
        {{task.title}}
      </div>
      <div class="timeline-link" *ngIf="deal">
        <a [routerLink]="deal._pipelineUrl === 'mortgage' ? ['/deals', deal._id,'offers'] : ['/cases', deal._pipelineUrl, deal._id]">{{ deal | caseNumber }}</a> {{ deal.name }}
      </div>
      <div class="timeline-info">
        <span *ngIf="assignedToName"><b>{{ assignedToType }}:</b>{{ assignedToName }}</span>
        <span *ngIf="!task.completedAt"><b>Splnit do:</b> {{task.dueAt | date:'shortDate':undefined:'cs-CS'}}</span>
        <span *ngIf="task.completedAt"><b>Splněno:</b> {{task.completedAt | date:'shortDate':undefined:'cs-CS'}}</span>
      </div>
      <div class="timeline-content" *ngIf="task.text">
        <p [innerHTML]="task.text"></p>
      </div>
    </ng-container>

    <ng-container *ngIf="call">
      <div class="timeline-title">{{call.direction === 'out' ? 'Odchozí' : 'Příchozí'}} hovor</div>
      <div class="timeline-link" *ngIf="deal">
        <a [routerLink]="deal._pipelineUrl === 'mortgage' ? ['/deals', deal._id,'offers'] : ['/cases', deal._pipelineUrl, deal._id]">{{ deal | caseNumber }}</a> {{ deal.name }}
      </div>
      <div class="timeline-info">
        <span *ngIf="call.userId && (call.userId | getUser | async) as user"><b>{{call.direction === 'out' ? 'Volající' : 'Volaný'}}:</b> {{user | userName}}</span>
        <span><b>{{call.direction === 'in' ? 'Volající' : 'Volaný'}}:</b> {{(call._contact | userName)}} ({{call.phoneNumber}})</span>
        <span><b>Délka vyzvánění:</b> {{call.ringingTime || call.waitingTime || 0}}s</span>
        <span *ngIf="call.answered"><b>Délka hovoru:</b> {{call.duration || 0}}s</span>
      </div>
    </ng-container>

    <ng-container *ngIf="activity">
      <ng-container *ngIf="activity.type === 'owner-changed'">
        <div class="timeline-title" *ngIf="activity.data.newOwner && (activity.data.newOwner | getUser | async) as user">Změna vlastníka</div>
        <div class="timeline-link" *ngIf="deal">
          <a [routerLink]="deal._pipelineUrl === 'mortgage' ? ['/deals', deal._id,'offers'] : ['/cases', deal._pipelineUrl, deal._id]">{{ deal | caseNumber }}</a> {{ deal.name }}
        </div>
        <div class="timeline-info">
          <span *ngIf="activity.data.newOwner && (activity.data.newOwner | getUser | async) as user"><b>Nový vlastník:</b> {{user | userName}}</span>
          <span *ngIf="activity.data.oldOwner && (activity.data.oldOwner | getUser | async) as user"><b>Původní vlastník:</b> {{user | userName}}</span>
          <span *ngIf="activity.userId && (activity.userId | getUser | async) as user"><b>Provedl:</b> {{user | userName}}</span>
        </div>
      </ng-container>

      <ng-container *ngIf="activity.type === 'platform-change'">
        <div class="timeline-title">Změna z platformy</div>
        <div class="timeline-link" *ngIf="deal">
          <a [routerLink]="deal._pipelineUrl === 'mortgage' ? ['/deals', deal._id,'offers'] : ['/cases', deal._pipelineUrl, deal._id]">{{ deal | caseNumber }}</a> {{ deal.name }}
        </div>
        <div class="timeline-info">
          <span *ngIf="activity.userId && (activity.userId | getUser | async) as user"><b>Provedl:</b> {{user | userName}}</span>
        </div>
      </ng-container>

      <ng-container *ngIf="activity.type === 'source-deal'">
        <div class="timeline-title">Byl vytvořen nový případ</div>
        <div class="timeline-link" *ngIf="deal">
          <a [routerLink]="deal._pipelineUrl === 'mortgage' ? ['/deals', deal._id,'offers'] : ['/cases', deal._pipelineUrl, deal._id]">{{ deal | caseNumber }}</a> {{ deal.name }}
        </div>
        <div class="timeline-info">
          <span><b>Typ:</b> {{(activity.data.pipelineId | pipeline:pipelinesService.pipelines).name}}</span>
          <span *ngIf="(activity.data | activityDeal:pipelinesService.pipelines) as deal"><b>Odkaz na případ:</b>&nbsp;<a [routerLink]="deal.url">{{deal.number}}</a></span>
        </div>
      </ng-container>

      <ng-container *ngIf="activity.type === 'files-uploaded'">
        <div class="timeline-title">Klient nahrál soubory</div>
        <div class="timeline-link" *ngIf="deal">
          <a [routerLink]="deal._pipelineUrl === 'mortgage' ? ['/deals', deal._id,'offers'] : ['/cases', deal._pipelineUrl, deal._id]">{{ deal | caseNumber }}</a> {{ deal.name }}
        </div>
      </ng-container>

      <ng-container *ngIf="activity.type === 'confirmed-candidates'">
        <div class="timeline-title">Klient vybral varianty</div>
        <div class="timeline-link" *ngIf="deal">
          <a [routerLink]="deal._pipelineUrl === 'mortgage' ? ['/deals', deal._id,'offers'] : ['/cases', deal._pipelineUrl, deal._id]">{{ deal | caseNumber }}</a> {{ deal.name }}
        </div>
      </ng-container>

      <ng-container *ngIf="activity.type === 'stage-changed'">
        <div class="timeline-title">Změna stavu</div>
        <div class="timeline-link" *ngIf="deal">
          <a [routerLink]="deal._pipelineUrl === 'mortgage' ? ['/deals', deal._id,'offers'] : ['/cases', deal._pipelineUrl, deal._id]">{{ deal | caseNumber }}</a> {{ deal.name }}
        </div>
        <div class="timeline-info">
          <span *ngIf="(activity.data.to | pipelineStage:activity.pipelineId:pipelinesService)?.label as stageName"><b>Nový stav:</b> {{stageName}}</span>
          <span *ngIf="(activity.data.from | pipelineStage:activity.pipelineId:pipelinesService)?.label as stageName"><b>Původní stav:</b> {{stageName}}</span>
          <span *ngIf="activity.userId && (activity.userId | getUser | async) as user"><b>Provedl:</b> {{user | userName}}</span>
        </div>
      </ng-container>

      <ng-container *ngIf="activity.type === 'video-call'">
        <div class="timeline-title">Video hovor</div>
        <div class="timeline-link" *ngIf="deal">
          <a [routerLink]="deal._pipelineUrl === 'mortgage' ? ['/deals', deal._id,'offers'] : ['/cases', deal._pipelineUrl, deal._id]">{{ deal | caseNumber }}</a> {{ deal.name }}
        </div>
        <div class="timeline-info">
          <span *ngIf="activity.userId && (activity.userId | getUser | async) as user"><b>Osoba:</b> {{user | userName}}</span>
        </div>
      </ng-container>

      <ng-container *ngIf="activity.type === 'video-sent'">
        <div class="timeline-title">Odesláno video</div>
        <div class="timeline-link" *ngIf="deal">
          <a [routerLink]="deal._pipelineUrl === 'mortgage' ? ['/deals', deal._id,'offers'] : ['/cases', deal._pipelineUrl, deal._id]">{{ deal | caseNumber }}</a> {{ deal.name }}
        </div>
        <div class="timeline-info">
          <span *ngIf="activity.userId && (activity.userId | getUser | async) as user"><b>Osoba:</b> {{user | userName}}</span>
        </div>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="sms">
      <div class="timeline-title flex flex-row justify-start items-center">
        <div class="timeline-title-text">
          {{sms.template?.name || (sms.text|charactersWords:40) || 'SMS'}}
        </div>
        <div *ngIf="item.isFutureActivity && (item.type === 'sms' || item.type === 'email')">
          <button mat-icon-button color="warn" (click)="archiveItem(item)">
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      </div>
      <div class="timeline-link" *ngIf="deal">
        <a [routerLink]="deal._pipelineUrl === 'mortgage' ? ['/deals', deal._id,'offers'] : ['/cases', deal._pipelineUrl, deal._id]">{{ deal | caseNumber }}</a> {{ deal.name }}
      </div>
      <div class="timeline-info">
        <span><b>Událost:</b> SMS {{sms?.direction === 'out' ? 'odchozí' : 'příchozí'}}</span>
        <span *ngIf="sms.template?.name"><b>Šablona:</b> {{sms.template?.name}}</span>
        <span><b>Příjemce:</b> {{sms.to}}</span>
      </div>
      <div class="timeline-content" *ngIf="sms.text">
        <p>{{sms.text}}</p>
      </div>
    </ng-container>

    <ng-container *ngIf="email">
      <div class="timeline-title flex flex-row justify-start items-center">
        <div class="timeline-title-text">
          {{email.subject || email.template?.name}}
        </div>
        <div *ngIf="item.isFutureActivity && (item.type === 'sms' || item.type === 'email')">
          <button mat-icon-button color="warn" (click)="archiveItem(item)">
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      </div>
      <div class="timeline-link" *ngIf="deal">
        <a [routerLink]="deal._pipelineUrl === 'mortgage' ? ['/deals', deal._id,'offers'] : ['/cases', deal._pipelineUrl, deal._id]">{{ deal | caseNumber }}</a> {{ deal.name }}
      </div>
      <div class="timeline-info">
        <span><b>Událost:</b> Email {{email?.direction === 'out' ? 'odchozí' : 'příchozí'}}</span>
        <span *ngIf="email.template?.name"><b>Šablona:</b> {{email.template?.name}}</span>
        <span><b>Příjemce:</b> {{email.to}}</span>
        <ng-container *ngIf="email?.direction === 'out'">
          <span *ngIf="email.msgState === 'sent'" class="font-semibold">
            Email odeslán {{ email.sentAt | date:'shortDate':undefined:'cs-CS' }} {{email.sentAt | date:'HH:mm:ss'}}
          </span>
          <span *ngIf="email.msgState === 'delivered' && email.status === 'delivered'" class="font-semibold text-green-600">
            Email odeslán {{ email.sentAt | date:'shortDate':undefined:'cs-CS' }} {{email.sentAt | date:'HH:mm:ss'}}
            v pořádku doručen<span *ngIf="email.opened > 0">, otevřen {{email.opened}} krát</span><span *ngIf="email.clicked > 0">, kliknuto {{email.clicked}} krát</span>
          </span>
          <span *ngIf="['bounce', 'dropped'].includes(email.status)" class="font-semibold text-red-600">
            Email se nepodařilo odeslat, chyba {{ email.status }}
          </span>
          <span *ngIf="email.msgState === 'queued'">
            Naplánováno odeslání
            <span *ngIf="email.sendAt">{{ email.sendAt | date:'shortDate':undefined:'cs-CS' }}</span>
          </span>
          <span *ngIf="email.msgState === 'unsubscribed'" class="font-semibold text-red-600">
            Email nebyl odeslán - odhlášen z komunikace
          </span>
          <span *ngIf="email.msgState === 'failed'" class="font-semibold text-red-600">
             Email se nepodařilo odeslat
          </span>
        </ng-container>
      </div>
    </ng-container>

    <ng-container *ngIf="false">
      <div class="timeline-title">{{task.title}}</div>
      <div class="timeline-info">
      </div>
      <div class="timeline-content">
      </div>
    </ng-container>
  </div>
</div>
