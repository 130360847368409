import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { Contact } from '../models/contact.model';

@Injectable({providedIn: 'root'})
export class WidgetContactsService extends DataService<Contact> {

  constructor(
  ) {
    super('contacts/search');
    this.pagination = 100;
    this.sort = {
      lastName: 1,
      firstName: 1
    };
  }
}
