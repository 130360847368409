import { MatDialogRef } from '@angular/material/dialog';
import {AfterViewInit, Component} from '@angular/core';

@Component({
  selector: 'app-dialog',
  template: `
    <h2 mat-dialog-title>Nastavit bankovnictví na krok</h2>
    <mat-dialog-content style="min-width: 500px">
      <div class="form-row">
        <mat-form-field>
          <mat-select placeholder="Vyber možnost" [(ngModel)]="result.newScore">
            <ng-container *ngFor="let option of options">
              <mat-option [value]="option.value">{{ option.label }}</mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions>
      <button type="button" mat-button color="warn" (click)="dialogRef.close(null)">Zrušit</button>
      <button type="button" mat-raised-button color="primary" [disabled]="!result.newScore" (click)="dialogRef.close(result)">Potvrdit</button>
    </mat-dialog-actions>
  `,
})

export class ScoreDialogComponent {

  public options: any[];
  public result: {oldScore: number, newScore: number} = {oldScore: 0, newScore: 0};

  constructor(
    public dialogRef: MatDialogRef<ScoreDialogComponent>,
  ) {
  }

  public setValues(options, oldScore) {
    this.options = options.filter((o: any) => o.value < oldScore);
    this.result.oldScore = oldScore;
  }
}
