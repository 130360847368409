<div class="flex flex-col px-8 pt-4 h-full overflow-y-auto">
  <div class="flex flex-row justify-between items-center">
    <span class="text-2xl">Úkoly ({{ tasksService.incompletedTotal$ | async }})</span>
    <div class="flex flex-row justify-between items-center gap-4">
      <mat-slide-toggle [(ngModel)]="tasksOptions.expandNotes"
                        (toggleChange)="updateTaskOptions()">
        Velké okno poznámky
      </mat-slide-toggle>
      <mat-slide-toggle [(ngModel)]="tasksOptions.compactView"
                        (toggleChange)="updateTaskOptions()">
        Kompaktní zobrazení
      </mat-slide-toggle>
      <button mat-button
              color="primary"
              (click)="tasksService.addTask(deal, offer)">
        <span>Nový úkol</span>
        <mat-icon>add</mat-icon>
      </button>
    </div>
  </div>

  <div class="pt-4">
    <app-tasks-list [tasks]="tasks" [options]="tasksOptions" (noteChange)="noteChanged($event)">
    </app-tasks-list>
  </div>

</div>
