import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { TimelineItem, TimeLineItemType } from '@app/components/timeline/timeline-item.component';
import { TimelineMessagesService } from '@app/services/timeline-messages.service';
import { Task } from '@app/models/task.model';
import { Activity } from '@app/models/activity.model';
import { Message } from '@app/models/message.model';
import { Call } from '@app/models/calls.model';

@Component({
  selector: 'app-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss']
})

export class TimelineComponent implements OnInit {

  public timelineFilter: string = 'all';
  public timelineItems$: Observable<TimelineItem[]>;
  public $timelineFilter: BehaviorSubject<string> = new BehaviorSubject<string>(this.timelineFilter);
  public timelineFilter$: Observable<string> = this.$timelineFilter.asObservable();

  public timeLineOptions: { label: string, value: string, klass?: string }[] = [
    { label: 'Všechny události', value: 'all' },
    { label: 'Úkoly', value: 'tasks', klass: 'opt-tasks' },
    { label: 'Stavy', value: 'stages' },
    { label: 'Komunikace', value: 'communication' },
    { label: 'Emaily', value: 'emails', klass: 'opt-email' },
    { label: 'SMSky', value: 'sms', klass: 'opt-sms' },
    { label: 'Hovory', value: 'calls', klass: 'opt-calls' },
  ];

  @Input() createdAt: Date;
  @Input() tasks: Observable<Task[]>;
  @Input() activities: Observable<Activity[]>;
  @Input() messages: Observable<Message[]>;
  @Input() calls: Observable<Call[]>;
  @Input() contactTimeline = false;
  @Input() deals: any[] = [];

  constructor(private timelineMessagesService: TimelineMessagesService) {
  }

  public ngOnInit() {
    this.timelineItems$ = combineLatest([
      this.timelineFilter$,
      this.tasks,
      this.activities,
      this.messages,
      this.calls
    ]).pipe(
      map(([filter, tasks, activities, messages, calls]) => {
        const items: TimelineItem[] = [];
        if (filter === 'all' || filter === 'tasks') {
          items.push(...tasks.map((task) => ({
            type: TimeLineItemType.task,
            date: new Date(task.createdAt),
            data: task,
            dealId: task.dealId,
          })));
        }
        if (filter === 'all' || filter === 'stages') {
          items.push(...activities.filter(a => ['stage-changed', 'platform-change', 'files-uploaded', 'confirmed-candidates', 'owner-changed', 'source-deal', 'video-sent', 'video-call'].includes(a.type)).map((activity) => ({
            type: TimeLineItemType.activity,
            date: new Date(activity.createdAt),
            data: activity,
            dealId: activity.dealId,
          })));
        }
        if (filter === 'all' || filter === 'communication' || filter === 'emails') {
          items.push(...messages.filter(m => m.type === 'email').map((activity) => ({
            type: TimeLineItemType.email,
            date: new Date(activity.sentAt || activity.sendAt || activity.createdAt),
            data: activity,
            dealId: activity.dealId,
            isFutureActivity: activity.sendAt && new Date(activity.sendAt) > new Date()
          })));
        }
        if (filter === 'all' || filter === 'communication' || filter === 'sms') {
          items.push(...messages.filter(m => m.type === 'sms').map((activity) => ({
            type: TimeLineItemType.sms,
            date: new Date(activity.sentAt || activity.sendAt || activity.createdAt),
            data: activity,
            dealId: activity.dealId,
            isFutureActivity: activity.sendAt && new Date(activity.sendAt) > new Date()
          })));
        }
        if (filter === 'all' || filter === 'communication' || filter === 'calls') {
          items.push(...calls.map((activity) => ({
            type: TimeLineItemType.call,
            date: new Date(activity.callTime),
            data: activity,
          })));
        }
        items.sort((a, b) => b.date.getTime() - a.date.getTime());
        return items;
      })
    );
  }

  filterTimeline() {
    this.$timelineFilter.next(this.timelineFilter);
  }

  public itemChanged($event: TimelineItem) {
    this.timelineMessagesService.load(0);
  }
}
