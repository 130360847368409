import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'fileIcon'})
export class FileIconPipe implements PipeTransform {
  public transform(value: any): string {
    const parts = value.split('.');
    if (parts.length > 1) {
      const extension = parts[parts.length - 1].toLowerCase();
      switch (extension) {
        case 'pdf':
          return 'picture_as_pdf';
        case 'jpg':
        case 'jpeg':
        case 'png':
          return 'image';
        default:
          return 'note';
      }
    } else {
      return 'note';
    }
  }
}
