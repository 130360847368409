import { Injectable } from '@angular/core';
import {DataService} from '@app/services/data.service';
import {Call} from '@app/models/calls.model';
import {UsersService} from '@app/services/users.service';
import {CallsService} from '@app/services/calls.service';

@Injectable({providedIn: 'root'})
export class MissedCallsService extends DataService<Call> {
  constructor(
    private usersService: UsersService,
    private callsService: CallsService
  ) {
    super('calls');
    this.pagination = 0;
    this.query = {
      userId: this.usersService.user._id,
      duration: {$exists: true},
      unseen: true,
      hangup: {$ne: true},
      answered: {$ne: true},
    };
    this.load(0);
    this.callsService.updated$.subscribe(() => {
      this.load(0);
    });
  }
}
