import { environment } from '@env/environment';
import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Injectable()
export class TitleService {
    constructor(
        private title: Title
    ) {

    }

    public setTitle(title: string) {
        this.title.setTitle(title + ' | ' + environment.title);
    }
}
