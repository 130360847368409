<ng-container *ngIf="loaded">
  <h2 mat-dialog-title>Povolené druhy automatické komunikace</h2>

  <div class="comm-prefs">
      <div class="comm-bl" [ngClass]="{ 'comm-blocked': comms.blacklisted }">
        <div class="flex flex-row justify-between items-center">
          <div *ngIf="!validPhone">
            U kontaktu není uvedeno telefonní číslo.
          </div>
          <div *ngIf="validPhone">
            Telefonní číslo <strong>{{countryCode}} {{phoneNumber}}</strong> {{ comms.blacklisted ? 'je' : 'není' }} na blacklistu
          </div>
          <div>
            <mat-slide-toggle [(ngModel)]="comms.blacklisted"
                              [disabled]="(!this.isAdmin) || (!this.validPhone)"
                              color="warn">
            </mat-slide-toggle>
          </div>
        </div>
        <div class="pt-2 flex flex-row justify-start items-center gap-4">
          <div>
            <mat-icon class="text-amber-500">warning</mat-icon>
          </div>
          <div>
            Pokud je telefonní číslo na blacklistu, neposíláme na číslo vůbec žádnou komunikaci.
            Umístění na blacklist je nadřazené všem nastavením komunikace níže.
          </div>
        </div>
        <div class="pt-2 flex flex-row justify-start items-center" *ngIf="!isAdmin">
          <div>
            <mat-icon color="primary" class="pr-2">info</mat-icon>
          </div>
          <div>
            Nastavení blacklistu může měnit pouze IT a správci.
          </div>
        </div>
      </div>

      <div class="option-group pt-8">
        Níže povolíte nebo zakážete komunikaci v rámci tohoto případu. Zákaz nebo povolení <strong>nemá vliv
        na jiné případy</strong>, i kdyby měli stejné telefonní číslo nebo mailovou adresu. Pokud chcete
        zakázat telefonní číslo všeobecně pro všechny případy, je nutné použít volbu blacklistu výše.
      </div>

      <div class="option-group flex flex-row justify-between items-center">
        <div class="text-red-500 flex flex-row justify-start items-center">
          Systémová komunikace <mat-icon color="primary" class="px-2" [matTooltip]="systemTooltip">info</mat-icon>
        </div>
        <div class="flex flex-col">
          <div class="flex flex-row justify-end items-center">
            <div class="option-label">SMS i email</div>
            <mat-slide-toggle [(ngModel)]="comms.systemMsg"
                              [disabled]="!this.isAdmin"
                              color="accent">
            </mat-slide-toggle>
          </div>
        </div>
      </div>

    <div class="option-group flex flex-row justify-between items-center">
        <div class="text-red-500 flex flex-row justify-start items-center">
          Procesní komunikace <mat-icon color="primary" class="px-2" [matTooltip]="transactionTooltip">info</mat-icon>
        </div>
        <div class="flex flex-col">
          <div class="flex flex-row justify-end items-center">
            <div class="option-label">SMS i email</div>
            <mat-slide-toggle [(ngModel)]="comms.transactionMsg"
                              [disabled]="!this.isAdmin"
                              color="accent">
            </mat-slide-toggle>
          </div>
        </div>
      </div>

      <div class="option-group flex flex-row justify-between items-center">
        <div class="flex flex-row justify-start items-center">
          Odloženo a storno <mat-icon color="primary" class="px-2" [matTooltip]="casesTooltip">info</mat-icon>
        </div>
        <div class="flex flex-col">
          <div class="flex flex-row justify-end items-center">
            <div class="option-label">SMS</div>
            <mat-slide-toggle [(ngModel)]="comms.casesSms" color="accent"></mat-slide-toggle>
          </div>
          <div class="flex flex-row justify-end items-center">
            <div class="option-label">Email</div>
            <mat-slide-toggle [(ngModel)]="comms.casesEmail" color="accent"></mat-slide-toggle>
          </div>
        </div>
      </div>

      <div class="option-group flex flex-row justify-between items-center">
        <div class="flex flex-row justify-start items-center">
          Marketingové informace <mat-icon color="primary" class="px-2" [matTooltip]="marketingTooltip">info</mat-icon>
        </div>
        <div class="flex flex-col">
          <div class="flex flex-row justify-end items-center">
            <div class="option-label">SMS</div>
            <mat-slide-toggle [(ngModel)]="comms.marketingSms" color="accent"></mat-slide-toggle>
          </div>
          <div class="flex flex-row justify-end items-center">
            <div class="option-label">Email</div>
            <mat-slide-toggle [(ngModel)]="comms.marketingEmail" color="accent"></mat-slide-toggle>
          </div>
        </div>
      </div>
  </div>

  <mat-dialog-actions>
    <button type="button" mat-button color="warn" (click)="dialogRef.close(null)">Zavřít</button>
    <button type="button" mat-raised-button color="accent" (click)="savePreferences()">Uložit</button>
  </mat-dialog-actions>
</ng-container>
