import { MatDialogRef } from '@angular/material/dialog';
import { Component } from '@angular/core';

@Component({
  selector: 'app-dialog',
  template: `
    <h2 mat-dialog-title>Obnovit</h2>
    <mat-dialog-content>
      <ng-container *ngIf="needReason">
        <div class="form-row" *ngFor="let reasonOption of reasonOptions; let reasonIndex = index">
          <mat-form-field>
            <mat-select placeholder="Důvod obnovení" [(ngModel)]="result.reasons[reasonIndex]" (selectionChange)="updateOptions(reasonIndex)">
              <ng-container *ngFor="let option of options[reasonOption]">
                <mat-option [value]="option.value">{{ option.label }}</mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>
        </div>
      </ng-container>
      <div class="form-row">
        <mat-form-field>
          <mat-select placeholder="Způsob obnovení" [(ngModel)]="result.createNew">
            <mat-option [value]="false">Obnovit původní</mat-option>
            <mat-option [value]="true">Zkopírovat do nové</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions>
      <button type="button" mat-button color="warn" (click)="dialogRef.close(null)">Zrušit</button>
      <button type="button" mat-raised-button color="primary" [disabled]="needReason && result.reasons.length !== reasonOptions.length" (click)="confirm('create')">Obnovit</button>
    </mat-dialog-actions>
  `,
})

export class RestoreDialogComponent {

  public options: any[];
  public needReason: boolean;
  public result: {reasons: any[], stage: string, createNew: boolean};
  public reasonOptions: string[];

  constructor(
    public dialogRef: MatDialogRef<RestoreDialogComponent>,
  ) {
    this.options = [];
    this.reasonOptions = ['restoreReason'];
    this.result = {
      reasons: [],
      stage: null,
      createNew: false
    };
  }

  public confirm(stage: string) {
    this.result.stage = stage;
    this.dialogRef.close(this.result);
  }

  public updateOptions(index) {
    this.result.reasons.splice(index + 1);
    this.reasonOptions.splice(index + 1);
    const nextOptions = this.result.reasons[index] + 'PostponeReason';
    if (this.options[nextOptions]) {
      this.reasonOptions.push(nextOptions);
    }
  }
}
