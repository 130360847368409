import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import {TimelineComponent} from '@app/components/timeline/timeline.component';
import {TimelineItemComponent} from '@app/components/timeline/timeline-item.component';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {FormsModule} from '@angular/forms';
import {DateTimelinePipe} from '@app/pipes/date-timeline.pipe';
import {CoreModule} from '@app/modules/core.module';
import { TimelineIsFuturePipe } from './timeline-is-future.pipe';

@NgModule({
  declarations: [
    TimelineComponent,
    TimelineItemComponent,
    DateTimelinePipe,
    TimelineIsFuturePipe,
  ],
    imports: [CommonModule, MatButtonModule, MatIconModule, MatInputModule, MatSelectModule, FormsModule, CoreModule],
  exports: [
    TimelineComponent,
    TimelineItemComponent,
    DateTimelinePipe
  ]
})
export class TimelineModule {}
