import { Component, OnInit } from '@angular/core';
import {Observable} from 'rxjs';
import {MainService} from '@app/services/main.service';
import {ConversationsService} from '@app/services/conversations.service';
import {Conversation} from '@app/models/conversation.model';
import {map} from 'rxjs/operators';
import {MessagesService} from '@app/services/messages.service';
import {PipelinesService} from '@app/services/pipelines.service';
import {DialNumberParam} from '@app/models/calls.model';
import {CallsService} from '@app/services/calls.service';
import {Message} from '@app/models/message.model';
import {UsersService} from '@app/services/users.service';
import {CallingWidgetService} from '@app/components/callingwidget/callingwidget.service';

@Component({
  selector: 'app-calling-widget-sms',
  templateUrl: './sms.component.html',
  styleUrls: ['./sms.component.scss']
})
export class SmsComponent implements OnInit {

  public sortedItems$: Observable<Conversation[]>;
  public messages$: Observable<Message[]>;
  public selectedConversation: Conversation;
  public message: string;
  public sendingMessage = false;
  constructor(
    public conversationsService: ConversationsService,
    public messagesService: MessagesService,
    public mainService: MainService,
    public pipelinesService: PipelinesService,
    private callsService: CallsService,
    public usersService: UsersService,
    public callingWidgetService: CallingWidgetService
  ) { }

  // TODO musi se dodelat ze kdyz stojim na otevrenejch zpravach a prijde nova zprava, tak se rovnou nastavi seen na dane konverzaci

  ngOnInit(): void {
    this.sortedItems$ = this.conversationsService.items$.pipe(
      map(items => items.slice().sort((a, b) => {
        if (a.lastMessage.createdAt > b.lastMessage.createdAt) {
          return -1;
        }
        if (a.lastMessage.createdAt < b.lastMessage.createdAt) {
          return 1;
        }
        return 0;
      }))
    );
    this.messages$ = this.messagesService.items$.pipe(
      map(items => items.slice().sort((a, b) => {
        if (a.createdAt > b.createdAt) {
          return 1;
        }
        if (a.createdAt < b.createdAt) {
          return -1;
        }
        return 0;
      }))
    );
    this.conversationsService.updated$.subscribe((item) => {
      if (this.selectedConversation?._id === item._id) {
        this.selectedConversation = Object.assign(this.selectedConversation, item);
        if (this.selectedConversation.seen.indexOf(this.usersService.user._id) === -1) {
          this.conversationsService.patch(this.selectedConversation._id, {$push: {seen: this.usersService.user._id}});
        }
      }
    });
    this.conversationsService.load(0);
    this.callingWidgetService.$openConversation.subscribe(async phoneNumber => {
      this.callingWidgetService.setLoading(true);
      let conversation = (await this.conversationsService.find({query: {phoneNumber}})) || null;
      if (conversation.total > 0) {
        this.selectConversation(conversation?.data?.[0]?._id);
      } else {
        conversation = await this.conversationsService.create({
          phoneNumber
        });
        this.selectConversation(conversation?._id);
      }
    });
    this.callingWidgetService.leaveConversation$.subscribe(() => {
      this.selectConversation(null);
    });
    this.messagesService.isLoading$.subscribe(loading => {
      if (!loading) {
        this.callingWidgetService.setLoading(false);
      }
    });
  }


  endReached() {
    if (!this.conversationsService.$isLoading.getValue()) {
      this.conversationsService.load();
    }
  }

  endMessagesReached() {
    if (!this.messagesService.$isLoading.getValue()) {
      this.messagesService.load();
    }
  }

  async selectConversation(conversationId: string | null) {
    this.callingWidgetService.setLoading(true);
    this.selectedConversation = conversationId ? await this.conversationsService.patch(conversationId, {$push: {seen: this.usersService.user._id}}, {query: {joins: ['deals']}}) : null;
    if (this.selectedConversation) {
      this.message = '';
      await this.loadMessages(this.selectedConversation.phoneNumber);
    } else {
      this.callingWidgetService.setLoading(false);
    }
  }
  private async loadMessages(phoneNumber: string) {
    this.messagesService.filter = {
      type: 'sms',
      to: phoneNumber,
      provider: 'daktela',
      $and: [
        {text: {$ne: ''}},
        {text: {$ne: null}}
      ]
    };
    await this.messagesService.load(0);
  }

  public async dialPhone(event: any) {
    const param: DialNumberParam = {
      phoneNumber: event.phoneNumber,
      contactId: event.contactId,
      origin: 'WidgetSmsComponent'
    };
    await this.callsService.initiateCall(param);
  }

  scrollToBottom(messagesWrapper: HTMLDivElement, isLast: boolean) {
    if (isLast) {
      messagesWrapper.scrollTo({top: messagesWrapper.scrollHeight});
    }
  }

  async sendMessage() {
    if (this.message) {
      this.sendingMessage = true;
      const message = this.message;
      this.message = '';
      await this.messagesService.create({
        type: 'sms',
        to: this.selectedConversation.phoneNumber,
        text: message,
        provider: 'daktela',
        direction: 'out',
        userId: this.usersService.user._id
      });
      this.sendingMessage = false;
    }
  }
}
