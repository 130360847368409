import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges} from '@angular/core';
import {AbstractControl} from '@angular/forms';
import {RealEstateInsurancesService} from '@app/services/real-estate-insurances.service';
import {PipelinesService} from '@app/services/pipelines.service';

@Component({
  selector: 'app-offer-policy-list',
  templateUrl: './offer-policy-list.component.html',
  styleUrls: ['./offer-policy-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OfferPolicyListComponent implements OnChanges {

  public policyList?: {_id: string, stageName: string, name: string, createdAt: any, _pipelineUrl: string }[];
  public policyCount: number = 0;
  public expanded: boolean = false;

  @Input() public property: AbstractControl;
  @Input() public offerProperty: AbstractControl;

  constructor(private realEstateInsurancesService: RealEstateInsurancesService,
              private pipelinesService: PipelinesService,
              private changeDetectorRef: ChangeDetectorRef) { }

  public ngOnChanges(): void {
    this.getPolicyList();
  }

  public getPolicyList(): void {
    const caseStages = this.pipelinesService.getPipeline('real-estate-insurance').stages;
    this.policyList = null;
    this.policyCount = 0;
    const propertyId = this.property.get('_id')?.value;
    this.realEstateInsurancesService.find({
      query: {
        properties : {
          $all : [ propertyId ]
        },
      }
    }).then(response => {
      if (response.data) {
        this.policyList = response.data.map(item => ({
          _id: item._id,
          stageName: (caseStages.find((itm) => itm.id === item.stage))?.label,
          name: item.name,
          createdAt: item.createdAt,
          _pipelineUrl: item._pipelineUrl
        }));
        this.policyCount = this.policyList?.length;
      }
      this.changeDetectorRef.markForCheck();
    });
  }
}
