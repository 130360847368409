import { Injectable } from '@angular/core';
import { CrudService } from '@app/services/crud.service';
import { BackendService } from '@app/services/backend.service';

@Injectable({providedIn: 'root'})
export class DaktelaCallService extends CrudService {
  constructor() {
    super('daktelaApi/call', BackendService);
  }
}
