import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { DialButtonComponent } from '@app/components/dialbutton/dial-button.component';

@NgModule({
	declarations: [DialButtonComponent],
	imports: [CommonModule, MatButtonModule, MatIconModule, MatMenuModule],
	exports: [
		DialButtonComponent
	]
})
export class DialButtonModule {}
