<div role="group" class="app-input-mask-container"
     [attr.aria-labelledby]="_formField?.getLabelId()"
     (focusin)="onFocusIn($event)"
     (focusout)="onFocusOut($event)">
  <input #input
         class="app-input-mask-input mat-input-element"
         [attr.id]="inputId"
         [attr.type]="type"
         [attr.name]="name"
         [ngStyle]="inputStyle"
         [class]="inputStyleClass"
         [attr.title]="title"
         [attr.size]="size"
         [attr.autocomplete]="autocomplete"
         [attr.maxlength]="maxlength"
         [attr.tabindex]="tabindex"
         [attr.aria-label]="ariaLabel"
         [attr.aria-required]="ariaRequired"
         [attr.inputmode]="inputmode"
         [disabled]="disabled"
         [readonly]="readonly"
         [attr.required]="required"
         [attr.autofocus]="autoFocus"
         (focus)="onInputFocus($event)"
         (blur)="onInputBlur($event)"
         (keydown)="onInputKeydown($event)"
         (keypress)="onKeyPress($event)"
         (input)="onInputChange($event)"
         (paste)="handleInputChange($event)">
</div>
