import { Component, OnInit} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormGroup, Validators } from '@angular/forms';

import { MainService } from '@app/services/main.service';
import { ProvidersService } from '@app/services/providers.service';
import { InterventionsService } from '@app/services/interventions.service';

@Component({
  selector: 'app-dialog',
  templateUrl: './intervention-dialog.component.html',
})
export class InterventionDialogComponent implements OnInit {
  public intervention: any;
  public isCounterProposal: boolean;
  public modelations: any;
  public title: string;

  public form: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<InterventionDialogComponent>,
    public mainService: MainService,
    public providersService: ProvidersService,
    public interventionsService: InterventionsService,
  ) {
  }

  public ngOnInit(): void {
    this.title = this.isCounterProposal ? 'Nová protinabídka' : 'Nová intervence';
    this.form = this.interventionsService.createForm(this.intervention);
    this.form.get('intervention.type').enable();
    this.form.get('counterProposal.type').enable();
    if (this.isCounterProposal) {
      this.form.get('counterProposal.type').addValidators(Validators.required);
      this.form.get('counterProposal.type').updateValueAndValidity();
    } else {
      this.form.get('intervention.type').addValidators(Validators.required);
      this.form.get('intervention.type').updateValueAndValidity();
    }
  }

  public closeDialog(): void {
    const data = this.form.getRawValue();
    delete data._id;
    data.isCounterProposal = this.isCounterProposal;
    data.case = this.intervention.case;
    data.caseType = this.intervention.caseType;
    this.dialogRef.close(this.interventionsService.unmask(data));
  }

}
