import { Pipe, PipeTransform } from '@angular/core';
import {AbstractControl} from '@angular/forms';

@Pipe({ name: 'typesCarInsurance', pure: false })
export class TypesCarInsurancePipe implements PipeTransform {
  constructor() {}
  transform(product: any) {
    const types = [];
    try {
      product = product.getRawValue();
    } catch (e) {
    }
    if (product.types?.includes('compulsory')) {
      types.push('POV');
    }
    if (product.types?.includes('accident')) {
      types.push('HAV');
    }
    return types.join(', ');
  }
}
