import {
  ActivatedRouteSnapshot,
  CanDeactivate,
  PRIMARY_OUTLET,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { Injectable } from '@angular/core';
import { DialogsService } from '@app/services/dialogs.service';

export interface GetFormPristine {
  isPristine: () => boolean;
}

@Injectable()
export class CanDeactivateFormGuard implements CanDeactivate<GetFormPristine> {
  constructor(
    private router: Router,
    private dialogsService: DialogsService) {}

  canDeactivate(component: GetFormPristine, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState?: RouterStateSnapshot): Promise<boolean> | boolean {
    if (component.isPristine()) {
      return true;
    } else {
      // If first part of the URL is 'cases' and last is 'modelations', we will allow deactivation because a
      // redirection is being carried out to 'modelations/_id' and 'canDeactivate' is called again.
      // cases have url /cases/life-insurance/6669aec432443249a2eeea7b/modelations'
      // deals have url /deals/6644861c1af1d50cbac6153b/offers/6644861c525085002a9a4dcb/modelations'
      const tree: UrlTree = this.router.parseUrl(nextState.url);
      const segments = tree.root.children[PRIMARY_OUTLET].segments;
      if (segments.length > 1) {
        if (segments[0].path === 'cases' && segments[segments.length - 1].path === 'modelations') {
          return true;
        }
      }
      return this.dialogsService.deactivate().toPromise();
    }
  }
}
