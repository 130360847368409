import { MatDialogRef } from '@angular/material/dialog';
import {AfterViewInit, Component, OnInit} from '@angular/core';
import {conformToMask} from 'angular2-text-mask';
import {MainService} from '../../services/main.service';
import { Contact } from '@app/models/contact.model';

@Component({
  selector: 'app-dialog',
  template: `
    <style>
      table {width: 100%;}
      thead { background-color: rgba(0, 0, 0, 0.03)}
      table tr th, table tr td {text-align:left; padding: 0.5rem;}
      .deals-button {
        padding: 1rem;
        display: flex;
        justify-content: center;
      }
    </style>
    <h2 mat-dialog-title>Změnit vlastníka</h2>
    <mat-dialog-content>
      <div class="form-row">
        <mat-form-field>
          <mat-select placeholder="Vyberte osobu" [(ngModel)]="selectedUser" (ngModelChange)="ownerChanged()">
            <ng-container *ngFor="let user of users">
              <mat-option *ngIf="!user.cases?.isDefaultOwner && !user.isBlocked" [value]="user._id">{{ user.firstName }} {{ user.lastName }} {{user | userPipelines}}</mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>
      </div>
      <ng-container *ngIf="showContactsSlider && selectedUser">
        <div class="form-row">
          <label>Počet kontaktů ke změně (celkem {{contacts.length}})</label>
        </div>
        <div class="form-row">
          <mat-slider [(ngModel)]="contactsCountToUpdate" name="contactsCountToUpdate" (ngModelChange)="contactsCountChanged()"
            [max]="contacts.length"
            [min]="1"
            [thumbLabel]="true"
            color="primary"
            [step]="1">
            <input matSliderThumb>
          </mat-slider>
        </div>
      </ng-container>
      <p *ngIf="filteredDeals.length > 0">Změna vlastníka se projeví u <b>{{ patchContactsId.length }} kontakt{{patchContactsId.length > 1 ? 'ů' : 'u'}} <ng-container *ngIf="patchContactsId.length > 1">({{duplicateContacts.length}} duplicitních)</ng-container></b> a <b>{{ filteredDeals.length }} případ{{filteredDeals.length > 1 ? 'ů' : 'u'}}</b>.</p>
      <ng-container *ngIf="(filteredDeals | casesFilterStage:['canceled']).length > 0">
        <h4>Nezamítnuté případy</h4>
        <table class="list">
          <thead>
          <tr><th>ID</th><th>Pipeline</th><th>Název</th><th>Částka</th><th>Stav</th><th>Vlastník</th></tr>
          </thead>
          <tbody>
          <ng-container *ngFor="let deal of (filteredDeals | casesFilterStage:['canceled']); let dIndex = index;">
            <tr *ngIf="(dIndex + 1) <= showLength">
              <td><span class="nowrap-container">P{{deal.numberId}}</span></td>
              <td><span class="nowrap-container">{{deal | casePipeline:true}}</span></td>
              <td><span class="nowrap-container"><span>{{deal.name}}</span> <mat-icon class="list-icon isAfter" *ngIf="deal._isDuplicity">theater_comedy</mat-icon></span></td>
              <td><span class="nowrap-container">{{maskCurrency(deal.amount)}}</span></td>
              <td><span class="nowrap-container">{{deal.stage | caseStage:stages[deal._pipelineUrl]}}</span></td>
              <td *ngIf="(deal.ownerId | getOwner | async) as user"><span class="nowrap-container">{{user.firstName}} {{user.lastName}}</span></td></tr>
          </ng-container>
          </tbody>
        </table>
        <div class="deals-button" *ngIf="this.showLength < filteredDeals.length">
          <button mat-button (click)="showMore()" color="primary">Zobrazit další</button>
        </div>
      </ng-container>
    </mat-dialog-content>
    <mat-dialog-actions>
      <button type="button" mat-button color="warn" (click)="dialogRef.close({ownerId: null, patchContactsId})">Zrušit</button>
      <button type="button" mat-raised-button color="primary" (click)="dialogRef.close({ownerId: selectedUser, patchContactsId})">Potvrdit</button>
    </mat-dialog-actions>
  `,
})

export class OwnerDialogComponent implements OnInit{

  public deals: any[];
  public filteredDeals: any[] = [];
  public users: any[] = [];
  public stages: any;
  public contacts: Contact[];
  public duplicateContacts: Contact[];
  public patchContactsId: string[] = [];
  public selectedUser: string = null;
  public showStep = 25;
  public showLength = 0;
  public contactsCountToUpdate: number = 0;
  public showContactsSlider = false;

  constructor(
    public dialogRef: MatDialogRef<OwnerDialogComponent>,
    public mainService: MainService
  ) {
  }
  ngOnInit() {
    this.patchContactsId = this.contacts.map(c => c._id);
    this.duplicateContacts = this.contacts.filter(c => c.isDuplicity);
    this.contactsCountToUpdate = this.contacts.length;
    if (this.contacts.filter(c => !c.isDuplicity).length > 1) {
      this.showContactsSlider = true;
    }
  }
  public maskCurrency(value: any) {
    if (!value) {
      value = 0;
    }
    return (conformToMask(
      Math.round(value).toString(),
      this.mainService.currencyMask,
      {guide: false}
    )).conformedValue;
  }

  public ownerChanged() {
    this.contactsCountToUpdate = this.contacts.length;
    this.contactsCountChanged();
  }

  public filterDeals() {
    if (this.selectedUser) {
      const user = this.users.find(u => u._id === this.selectedUser);
      this.filteredDeals = this.deals.filter((d: any) => Object.keys(user.cases).includes(d._pipelineUrl) && this.patchContactsId.includes(d.participants?.[0].contactId || d._offers?.reverse()[0].applicants?.[0]));
    } else {
      this.filteredDeals = [];
    }
    this.showLength = this.showStep;
  }

  public contactsCountChanged() {
    const contacts = this.contacts.slice(0, this.contactsCountToUpdate);
    this.patchContactsId = contacts.map(c => c._id);
    this.duplicateContacts = contacts.filter(c => c.isDuplicity);
    this.filterDeals();
  }

  public showMore() {
    this.showLength += this.showStep;
  }
}
