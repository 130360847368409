import { Injectable } from '@angular/core';
import { UsersService } from './users.service';
import { OffersService } from '@app/services/offers.service';
import { DealsService } from '@app/services/deals.service';
import { MagicLinksService } from '@app/services/magic-links-service';

@Injectable({providedIn: 'root'})
export class BankingService {

  constructor(
    private dealsService: DealsService,
    private usersService: UsersService,
    private offersService: OffersService,
    private magicLinksService: MagicLinksService,
  ) {}

  public async openBanking(deal) {
    let offerId = deal.offers?.[0];
    if (!offerId) {
      const d = await this.dealsService.get(deal._id);
      offerId = d.offers?.[0];
    }
    if (offerId) {
      const offer = await this.offersService.get(offerId);
      const offerUser = (await this.usersService.find({query: {contactId: (offer.leadApplicantId || offer.applicants[0])}}))[0];
      if (offerUser) {
        const now = new Date();
        const validUntil = (now).setDate(now.getDate() + 3);

        const item = {
          token: { userId: offerUser._id, email: offerUser.email, redirectUri: '' },
          link: '',
          validUntil,
        };
        const shortenedUrl = await this.magicLinksService.create(item);
        const url = shortenedUrl.short;

        console.log('BANKING REDIRECT URL:', url);
        window.open(url,'_blank');
      }
    }
  }
}
