import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { BackendService } from './backend.service';
import { SnackbarService } from './snackbar.service';
// import * as Sentry from '@sentry/angular-ivy';
import { DialogsService } from './dialogs.service';
import { UserInterface } from '../interfaces/user.interface';
import { AffiliatesService } from '@app/services/affiliates.service';
import { datadogRum } from '@datadog/browser-rum';
import { environment } from '@env/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { RecaptchaService } from '@app/services/recaptcha-service';

interface AmountStatsInterface {
  applicationsThisMonthAmount: number;
  signedThisMonthAmount: number;
  commissionsThisMonthAmount: number;
  personalBudget: number;
  teamBudget: number;
}

@Injectable()
export class UsersService {
  public isAuthenticated = false;
  public user: UserInterface = null;
  private socket: any;
  private socketList: any;
  private socketCode: any;
  public items: UserInterface[] = [];
  private amountStats: AmountStatsInterface = {
    applicationsThisMonthAmount: 0,
    signedThisMonthAmount: 0,
    commissionsThisMonthAmount: 0,
    personalBudget: undefined,
    teamBudget: undefined
  };
  private $user: BehaviorSubject<UserInterface> = new BehaviorSubject(null);
  public user$: Observable<UserInterface> = this.$user.asObservable();
  private $authenticated: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public authenticated$: Observable<boolean> = this.$authenticated.asObservable();
  private $amountStats: BehaviorSubject<AmountStatsInterface> = new BehaviorSubject(this.amountStats);
  public amountStats$: Observable<AmountStatsInterface> = this.$amountStats.asObservable();
  public $items: BehaviorSubject<UserInterface[]> = new BehaviorSubject([]);
  public items$: Observable<UserInterface[]> = this.$items.asObservable();
  private redirect = null;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private backendService: BackendService,
    private snackbarService: SnackbarService,
    private dialogsService: DialogsService,
    private affiliatesService: AffiliatesService,
    private httpClient: HttpClient,
    private recaptchaService: RecaptchaService
  ) {
    this.socket = this.backendService.getService('users');
    this.socketList = this.backendService.getService('users/list');
    this.socketCode = this.backendService.getService('users/code');
    this.socket.on('created', (createdItem: UserInterface) => this._created(createdItem));
    this.socket.on('updated', (updatedItem: UserInterface) => this._updated(updatedItem));
    this.socket.on('patched', (updatedItem: UserInterface) => this._updated(updatedItem));
    this.socket.on('removed', (removedItem: UserInterface) => this._removed(removedItem));
    this.authenticated$.subscribe(authenticated => {
      if (authenticated) {
        this.checkLogout();
      }
    });
    this.backendService.connected$.subscribe(connected => {
      if (!connected) {
        this._setAuthenticated(false);
      }
    });
    this.route.queryParams.subscribe((params) => {
      this.redirect = params.redirect;
    });
    window.addEventListener('storage', () => {
      const loggedIn = !!window.localStorage.getItem('feathers-jwt');
      if (!loggedIn) {
        this.logout(this.redirect ? this.redirect : this.router.url);
      }
    });
    setInterval(() => {
      this.checkLogout();
    }, 30000);
  }
  private checkLogout() {
    if (!this.backendService.checkRefreshedAt()) {
      this.logout(this.redirect ? this.redirect : this.router.url);
    }
  }

  public async getUser(id: string) {
    let user = this.items.find(u => u._id === id);
    if (user) {
      return user;
    } else {
      user = await this.get(id);
      if (user) {
        this.items.push(user);
        return user;
      }
    }
    return null;
  }

  public getUserInfo(id: string) {
    return this.items.find(u => u._id === id);
  }

  public getUserFullName(id: string): string {
    const user = this.items.find(u => u._id === id);
    if (user) {
      return ((user.firstName ?? '') + ' ' + (user.lastName ?? '')).trim();
    } else {
      return '';
    }
  }

  public setUser(user: UserInterface) {
    // DATADOG stuff
    const usr = {
      id: user._id,
      name: ((user.firstName ?? '') + ' ' + (user.lastName ?? '')).trim(),
      email: ((user.email ?? ''))
    };
    datadogRum.setUser(usr);

    // Sentry.configureScope((scope) => {
    //   scope.setUser({email: user.email});
    // });

    this.user = user;
    this.$user.next(user);
    let amountStats = false;
    if (user.cases?.applicationsThisMonthAmount !== undefined) {
      amountStats = true;
      this.amountStats.applicationsThisMonthAmount = user.cases?.applicationsThisMonthAmount;
    }
    if (user.cases?.signedThisMonthAmount !== undefined) {
      amountStats = true;
      this.amountStats.signedThisMonthAmount = user.cases?.signedThisMonthAmount;
    }
    if (user.cases?.commissionsThisMonthAmount !== undefined) {
      amountStats = true;
      this.amountStats.commissionsThisMonthAmount = user.cases?.commissionsThisMonthAmount;
    }
    if (user.cases?.personalBudget !== undefined) {
      amountStats = true;
      this.amountStats.personalBudget = user.cases?.personalBudget;
    }
    if (user.cases?.teamBudget !== undefined) {
      amountStats = true;
      this.amountStats.teamBudget = user.cases?.teamBudget;
    }
    if (amountStats) {
      this.$amountStats.next(this.amountStats);
    }
  }

  public loadAll() {
    return new Promise((resolve) => {
      if (!this.items.length) {
        this.findList({
          query: {
            $or: [
              {role: { $ne: 'user'}},
              {_id: {$in: this.affiliatesService.$items.getValue().map(a => a.members).flat(1).map(m => m.userId)}}
            ],
            $sort: {lastName: 1, firstName: 1}
          },
          collation: {
            locale: 'cs',
            strength: 1
          }
        }).then((items) => {
          this.items = items;
          this.$items.next(this.items);
          resolve(null);
        }).catch(() => {
          this.items = [];
          resolve(null);
        });
      } else {
        resolve(null);
      }
    });
  }

  public login(email: string, password: string): Promise<boolean> {
    return new Promise((resolve) => {
      this._authenticate(email, password).then((authenticated) => {
        if (authenticated) {
          if (this.redirect) {
            this.router.navigateByUrl(this.redirect).then(() => null);
          } else {
            this.router.navigate(['/deals']).then(() => null);
          }
        } else {
          this.snackbarService.showError('Špatné přihlašovací údaje.');
        }
        resolve(authenticated);
      }).catch((error: any) => {
        if (error.code === 401) {
          this.snackbarService.showError('Špatné přihlašovací údaje.');
        } else {
          this.snackbarService.showError('Nyní se nelze přihlásit. Zkuste to později.');
        }
        resolve(false);
      });
    });
  }


  /**
   * Before calling login(), we must check value 'refreshedAt' in local storage.
   * When it is in past, we must delete this item from localStorage, otherwise login hangs.
   */
  public patchRefreshedAt(): void {
    const refreshedAt = localStorage.getItem('refreshedAt');
    if (refreshedAt !==  null) {
      if (Number(refreshedAt) < Date.now()) {
        localStorage.removeItem('refreshedAt');
      }
    }
  }

  public async logout(redirect = null) {
    localStorage.removeItem('refreshedAt');
    this.backendService.accessToken = null;
    try {
      await this.backendService.client.authentication.logout();
      await this.backendService.client.logout();
    } catch (e) {
    }
    if (redirect) {
      await this.router.navigate(['/login'], {queryParams: {redirect}});
    } else {
      await this.router.navigate(['/login']);
    }
  }

  public password() {
    this.dialogsService.password().subscribe(async (password) => {
      if (password) {
        await this.patch(this.user._id, {password});
        this.snackbarService.showSuccess('Heslo bylo změněno.');
      }
    });
  }

  public checkLogin(): Promise<boolean> {
    return new Promise((resolve) => {
      this.backendService.client.reAuthenticate().then((response: any) => {
        this.backendService.accessToken = response.accessToken;
        this.setUser(response.user);
        if (!['user', 'affiliate'].includes(this.user.role)) {
          this._setAuthenticated(true);
        } else {
          this._setAuthenticated(false);
          this.logout();
        }
        resolve(this.isAuthenticated);
      }).catch((e) => {
        this._setAuthenticated(false);
        resolve(false);
      });
    });
  }

  public getUserName() {
    if (this.user) {
      return this.user.firstName + ' ' + this.user.lastName.substr(0, 1) + '.';
    }
    return null;
  }

  public find(params: any = {}) {
    return this.socket.find(params);
  }

  public findList(params: any = {}) {
    return this.socketList.find(params);
  }

  public get(id: string, params: any = {}) {
    return this.socket.get(id, params);
  }

  public create(item: any, params: any = {}) {
    return this.socket.create(item, params);
  }

  public patch(id: string, item: any, params: any = {}) {
    return this.socket.patch(id, item, params);
  }

  public remove(id: string, params: any = {}) {
    return this.socket.remove(id, params);
  }

  private _setAuthenticated(authenticated: boolean) {
    if (this.isAuthenticated !== authenticated) {
      this.isAuthenticated = authenticated;
      this.$authenticated.next(authenticated);
    }
  }

  private _authenticate(email: string, password: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.backendService.client.authenticate({
        strategy: 'local',
        email,
        password
      }).then((response: any) => {
        this.backendService.accessToken = response.accessToken;
        this.setUser(response.user);
        if (this.isPrivileged('platform')) {
          this._setAuthenticated(true);
        } else {
          this._setAuthenticated(false);
          this.logout();
        }
        resolve(this.isAuthenticated);
      }).catch((error: any) => {
        this._setAuthenticated(false);
        reject(error);
      });
    });
  }

  private _created(createdItem: UserInterface): void {
    if (!this.items.find(i => i._id === createdItem._id)) {
      this.items.push(createdItem);
      this.$items.next(this.items);
    }
  }

  private _updated(updatedItem: UserInterface): void {
    const index = this.items.findIndex(i => i._id === updatedItem._id);
    if (this.user?._id === updatedItem._id) {
      this.setUser(updatedItem);
    }
    this.items[index] = updatedItem;
    this.$items.next(this.items);
  }

  private _removed(removedItem: UserInterface): void {
    const index = this.items.findIndex(i => i._id === removedItem._id);
    this.items.splice(index, 1);
    this.$items.next(this.items);
  }

  public async generateCode(email: string, password: string): Promise<any> {
    const gcToken = await this.recaptchaService.check('users/code');
    const url = `${environment.backendUrl}/users/code/${email}`;
    const params = { password, gcToken };
    const query = new HttpParams({ fromObject: params });
    return this.httpClient.get<any>(url, { params: query }).toPromise();
  }

  public isPrivileged(rule) {
    const rules = rule.split(':');
    if (this.user && this.user.privileges && (this.user.privileges.includes(rule) || this.user.privileges.includes(rules[0]))) {
      return true;
    }
    return false;
  }

  public getAvatar(user: UserInterface | null) {
    if (!user) {
      return 'https://www.hyponamiru.cz/wp-content/uploads/2019/07/boy.png';
    }
    if (user.photo) {
      return user.photo;
    } else if (user.sex === 'f') {
      return 'https://www.hyponamiru.cz/wp-content/uploads/2019/07/girl.png';
    } else {
      return 'https://www.hyponamiru.cz/wp-content/uploads/2019/07/boy.png';
    }
  }

  public getUsersList(options: any): any[] {
    return this.items.filter(u => u.role !== 'user');
  }
}
