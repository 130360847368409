import { Injectable } from '@angular/core';
import {DataService} from './data.service';
import {Affiliate} from '@app/models/affiliate.model';

@Injectable({providedIn: 'root'})
export class AffiliatesService extends DataService<Affiliate> {

  constructor(
  ) {
    super('affiliates');
    this.sort = {
      name: 1,
    };
    this.load();
  }

}
