import { MatDialogRef } from '@angular/material/dialog';
import {AfterViewInit, Component, OnInit} from '@angular/core';
import {UsersService} from '../../services/users.service';
import {UserInterface} from '../../interfaces/user.interface';
import {AffiliatesService} from '@app/services/affiliates.service';
import {BehaviorSubject, Observable} from 'rxjs';
import {Contact} from '@app/models/contact.model';
import {Pipeline} from '@app/models/pipeline.model';

@Component({
  selector: 'app-dialog',
  template: `
    <h2 mat-dialog-title><span>Vybrat uživatele</span></h2>
    <mat-dialog-content>
      <mat-form-field>
        <input matInput placeholder="Hledání" [matAutocomplete]="autoUser" (input)="filterUsers($event)" />
        <mat-autocomplete #autoUser="matAutocomplete" panelWidth="auto" (optionSelected)="dialogRef.close($event)">
          <mat-option *ngFor="let user of filteredUsers | async" [value]="user._id">
            {{ user.firstName }} {{ user.lastName }} ({{ user.email }})
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </mat-dialog-content>
    <mat-dialog-actions>
      <button type="button" mat-button color="warn" (click)="dialogRef.close(null)">Zrušit</button>
    </mat-dialog-actions>
  `,
})
export class FindUserDialogComponent {

  public filteredUsers: BehaviorSubject<any> = new BehaviorSubject([]);

  constructor(
    public dialogRef: MatDialogRef<FindUserDialogComponent>,
    private usersService: UsersService
  ) {
  }

  async filterUsers(event: any) {
    if (event.target.value.length > 3) {
      const users = await this.usersService.find({
        query: {
          $limit: 20,
          $or: [
            {email: {$regex: event.target.value, $options: 'i'}},
            {lastName: {$regex: event.target.value, $options: 'i'}}
          ]
        }
      });
      this.filteredUsers.next(users);
    } else {
      this.filteredUsers.next([]);
    }
  }

}
