import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { Task } from '../models/task.model';
import { UsersService } from './users.service';
import { Router } from '@angular/router';

@Injectable({providedIn: 'root'})
export class IncompleteTasksService extends DataService<Task> {

  public totalCounts = '';
  public taskCount = [
    { type: 0, count: 0, name: 'Splnit dnes', day1: 0, day2: 0, klass: 'bg-black text-white' },
    { type: 1, count: 0, name: 'Zítra', day1: 1, day2: 1, klass: 'bg-solid-red text-white' },
    { type: 2, count: 0, name: 'Tento týden', day1: 2, day2: 7, klass: 'bg-solid-orange text-white' },
    { type: 3, count: 0, name: 'Během příštích 30 dnů', day1: 8, day2: 30, klass: 'bg-solid-green text-white' },
    { type: 4, count: 0, name: 'Nesplněných úkolů po termínu', day1: -1, day2: -1, klass: 'bg-solid-gray text-white' },
  ];

  constructor(
    private usersService: UsersService,
    private router: Router
  ) {
    super('tasks');
    this.pagination = 0;
    this.query = {
      $or: [ { startAt: { $lte: Date.now() }}, { startAt: null } ],
      completedAt: null,
      deletedAt: {$exists: false},
      $limit: 0,
    };
  }

  public async loadCounts(): Promise<boolean> {
    const tmp: string[] = [];
    if (this.router.url === '/tasks') {
      for (const item of this.taskCount) {
        const date1 = new Date();
        date1.setHours(0, 0, 0);
        date1.setDate(date1.getDate() + item.day1);

        const date2 = new Date();
        date2.setHours(23, 59, 59);
        date2.setDate(date2.getDate() + item.day2);

        if (item.day1 === -1 && item.day2 === -1) {
          this.filter = {
            dueAt: {$lt: date2.toUTCString()},
            assignedToId: {$in: [this.usersService.user._id]}
          };
        } else {
          this.filter = {
            dueAt: {$gte: date1.toUTCString(), $lt: date2.toUTCString()},
            assignedToId: {$in: [this.usersService.user._id]}
          };
        }
        if (this.usersService.user.groupId) {
          this.filter.assignedToId.$in.push(this.usersService.user.groupId);
        }
        const query = this.buildQuery();
        const res = await this.find({query});
        item.count = res.total;
        tmp.push(`${item.name}: ${item.count}`);
      }
      this.totalCounts = tmp.join(', \n');
    }

    this.filter = {
      assignedToId: {$in: [this.usersService.user._id]}
    };
    if (this.usersService.user.groupId) {
      this.filter.assignedToId.$in.push(this.usersService.user.groupId);
    }
    this.load(0);
    return true;
  }

  protected created(item: Task): void {
    this.loadCounts();
  }

  protected updated(item: Task): void {
    this.loadCounts();
  }

  protected removed(item: Task): void {
    this.loadCounts();
  }
}
