import { Injectable } from '@angular/core';
import {DataService} from './data.service';
import {Task} from '../models/task.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class TimelineTasksService extends DataService<Task> {

  public incompletedTotal$: Observable<number>;

  constructor(
  ) {
    super('tasks');
    this.pagination = 100;
    this.query = {
      deletedAt: {$exists: false},
    };

    this.incompletedTotal$ = this.$items.pipe(
      map((items: Task[]) => items.filter((i: Task) => !i.completedAt).length)
    );
  }

  public loadData(fromIndex?: number): Promise<void> {
    return this.load(fromIndex);
  }

}
