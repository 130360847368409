/* Based on PrimeNG InputMask component */

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DoCheck,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  Self,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NgControl, NgModel, } from '@angular/forms';
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { MAT_FORM_FIELD, MatFormField, MatFormFieldControl } from '@angular/material/form-field';
import { Subject } from 'rxjs';
import { DOCUMENT } from '@angular/common';

declare type Nullable<T = void> = T | null | undefined;

@Component({
  selector: 'app-input-mask',
  templateUrl: './input-mask.component.html',
  styleUrls: ['./input-mask.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{provide: MatFormFieldControl, useExisting: InputMaskComponent}],
  // tslint:disable-next-line:no-host-metadata-property
  host: {
    '[class.example-floating]': 'shouldLabelFloat',
    '[id]': 'id',
  },
})
export class InputMaskComponent implements OnInit, AfterViewInit, ControlValueAccessor, OnDestroy, DoCheck, MatFormFieldControl<string> {
  static nextId = 0;
  @ViewChild('input', {static: true}) inputViewChild: ElementRef;

  private _placeholder: string;
  private _required = false;
  private _disabled = false;
  private _mask: string;
  private _maskLength: number = 0;
  private _value: Nullable<string>;
  readonly autofilled: boolean;
  stateChanges = new Subject<void>();
  focused = false;
  touched = false;
  controlType = 'app-input-mask';
  id = `app-input-mask-${InputMaskComponent.nextId++}`;
  input: HTMLInputElement;
  filled: boolean;
  defs: any;
  tests: any[];
  partialPosition: any;
  firstNonMaskPos: number;
  lastRequiredNonMaskPos: any;
  len: number;
  oldVal: string;
  buffer: any;
  defaultBuffer: string;
  focusText: string;
  caretTimeoutId: any;
  androidChrome: boolean;

  // tslint:disable-next-line:no-input-rename
  @Input('aria-describedby') userAriaDescribedBy: string;

  @Input()
  get placeholder(): string {
    return this._placeholder;
  }

  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }

  @Input()
  get required(): boolean {
    return this._required;
  }

  set required(value: BooleanInput) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }

  set disabled(value: BooleanInput) {
    this._disabled = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  @Input()
  get value(): string | null {
    return this._value;
  }

  set value(value: string | null) {
    this._value = value;
    this.stateChanges.next();
  }

  @Input() get mask(): string {
    return this._mask;
  }

  set mask(val: string) {
    this._mask = val;

    this.initMask();
    this.writeValue('');
    this.onChange(this._value);
  }

  @Input() type: string = 'text';
  @Input() inputmode: string = 'text';
  @Input() slotChar: string = ' ';
  @Input() autoClear: boolean = true;
  @Input() showClear: boolean = false;
  @Input() style: any;
  @Input() inputId: string;
  @Input() styleClass: string;
  @Input() size: number;
  @Input() maxlength: number;
  @Input() tabindex: string;
  @Input() title: string;
  @Input() ariaLabel: string;
  @Input() ariaRequired: boolean;
  @Input() readonly: boolean;
  @Input() unmask: boolean = true;
  @Input() name: string;
  @Input() characterPattern: string = '[A-Za-z]';
  @Input() autoFocus: boolean;
  @Input() autocomplete: string;
  @Input() inputStyle: any;
  @Input() inputStyleClass: string | undefined;

  // tslint:disable:no-output-on-prefix
  @Output() onComplete: EventEmitter<any> = new EventEmitter();
  @Output() onFocus: EventEmitter<any> = new EventEmitter();
  @Output() onBlur: EventEmitter<any> = new EventEmitter();
  @Output() onInput: EventEmitter<any> = new EventEmitter();
  @Output() onKeydown: EventEmitter<any> = new EventEmitter();
  @Output() onClear: EventEmitter<any> = new EventEmitter();
  // tslint:enable:no-output-on-prefix

  @HostListener('input', ['$event'])
  onInputEvent() {
    this.updateFilledState();
  }

  onChange = (_: any) => {};
  onTouched = () => {};

  getUserAgent(): string {
    return navigator.userAgent;
  }

  get empty() {
    return !this._value;
  }

  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  get errorState(): boolean {
    if (this.ngControl.errors !== null && this.ngControl.touched) {
      return true;
    } else {
      const v = (this._value ?? '').length;
      return (v >= 1) && (v !== this._maskLength);
    }
  }

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private el: ElementRef,
    private cd: ChangeDetectorRef,
    @Optional() @Inject(MAT_FORM_FIELD) public _formField: MatFormField,
    @Optional() @Self() public ngControl: NgControl,
    @Optional() public ngModel: NgModel,
  ) {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnInit() {
    const ua = this.getUserAgent();
    this.androidChrome = /chrome/i.test(ua) && /android/i.test(ua);
    this.initMask();
  }

  ngAfterViewInit() {
    this.updateFilledState();
    this.cd.detectChanges();
  }

  ngDoCheck() {
    this.updateFilledState();
  }

  ngOnDestroy() {
    this.stateChanges.complete();
  }

  onFocusIn(_event: FocusEvent) {
    if (!this.focused) {
      this.focused = true;
      this.stateChanges.next();
    }
  }

  onFocusOut(event: FocusEvent) {
    if (!this.el.nativeElement.contains(event.relatedTarget as Element)) {
      this.touched = true;
      this.focused = false;
      this.onTouched();
      this.stateChanges.next();
    }
  }

  setDescribedByIds(ids: string[]) {
    const controlElement = this.el.nativeElement.querySelector(
      '.example-tel-input-container',
    );
    if (controlElement) {
      controlElement.setAttribute('aria-describedby', ids.join(' '));
    }
  }

  onContainerClick(event: MouseEvent) {
    // tslint:disable-next-line:triple-equals
    if ((event.target as Element).tagName.toLowerCase() != 'input') {
      this.el.nativeElement.querySelector('input').focus();
    }
  }

  writeValue(value: any): void {
    this._value = value;
    if (this.inputViewChild && this.inputViewChild.nativeElement) {
      if (this._value === undefined || this._value == null) {
        this.inputViewChild.nativeElement.value = '';
      } else {
        this.inputViewChild.nativeElement.value = this._value;
      }

      this.checkVal();
      this.focusText = this.inputViewChild.nativeElement.value;
      this.updateFilledState();
    }
    this.cd.markForCheck();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  initMask() {
    this._maskLength = 0;
    this.tests = [];
    this.partialPosition = this.mask.length;
    this.len = this.mask.length;
    this.firstNonMaskPos = null;
    this.defs = {
      9: '[0-9]',
      a: this.characterPattern,
      '*': `${this.characterPattern}|[0-9]`
    };

    const maskTokens = this.mask.split('');
    for (let i = 0; i < maskTokens.length; i++) {
      const c = maskTokens[i];
      if (c === '?') {
        this.len--;
        this.partialPosition = i;
      } else if (this.defs[c]) {
        this.tests.push(new RegExp(this.defs[c]));
        if (this.firstNonMaskPos === null) {
          this.firstNonMaskPos = this.tests.length - 1;
        }
        if (i < this.partialPosition) {
          this.lastRequiredNonMaskPos = this.tests.length - 1;
        }
      } else {
        this.tests.push(null);
      }
    }

    this.buffer = [];
    for (let i = 0; i < maskTokens.length; i++) {
      const c = maskTokens[i];
      if (c !== '?') {
        if (this.defs[c]) {
          this.buffer.push(this.getPlaceholder(i));
          this._maskLength++;
        } else {
          this.buffer.push(c);
        }
      }
    }
    this.defaultBuffer = this.buffer.join('');
  }

  setDisabledState(val: boolean): void {
    this.disabled = val;
    this.cd.markForCheck();
  }

  caret(first?: number, last?: number) {
    let range;
    let begin;
    let end;

    if (!this.inputViewChild.nativeElement.offsetParent || this.inputViewChild.nativeElement !== this.inputViewChild.nativeElement.ownerDocument.activeElement) {
      return;
    }

    if (typeof first === 'number') {
      begin = first;
      end = (typeof last === 'number') ? last : begin;
      if (this.inputViewChild.nativeElement.setSelectionRange) {
        this.inputViewChild.nativeElement.setSelectionRange(begin, end);
      } else if (this.inputViewChild.nativeElement.createTextRange) {
        range = this.inputViewChild.nativeElement.createTextRange();
        range.collapse(true);
        range.moveEnd('character', end);
        range.moveStart('character', begin);
        range.select();
      }
    } else {
      if (this.inputViewChild.nativeElement.setSelectionRange) {
        begin = this.inputViewChild.nativeElement.selectionStart;
        end = this.inputViewChild.nativeElement.selectionEnd;
      }
      // tslint:disable:no-string-literal
      else if (document['selection'] && document['selection'].createRange) {
          range = document['selection'].createRange();
          begin = 0 - range.duplicate().moveStart('character', -100000);
          end = begin + range.text.length;
      }
      // tslint:enable:no-string-literal
      return {begin, end};
    }
  }

  isCompleted(): boolean {
    for (let i = this.firstNonMaskPos; i <= this.lastRequiredNonMaskPos; i++) {
      if (this.tests[i] && this.buffer[i] === this.getPlaceholder(i)) {
        return false;
      }
    }

    return true;
  }

  getPlaceholder(i: number) {
    if (i < this.slotChar.length) {
      return this.slotChar.charAt(i);
    }
    return this.slotChar.charAt(0);
  }

  seekNext(pos) {
    while (++pos < this.len && !this.tests[pos]) {
    }
    return pos;
  }

  seekPrev(pos) {
    while (--pos >= 0 && !this.tests[pos]) {
    }
    return pos;
  }

  shiftL(begin: number, end: number) {
    let i;
    let j;

    if (begin < 0) {
      return;
    }

    for (i = begin, j = this.seekNext(end); i < this.len; i++) {
      if (this.tests[i]) {
        if (j < this.len && this.tests[i].test(this.buffer[j])) {
          this.buffer[i] = this.buffer[j];
          this.buffer[j] = this.getPlaceholder(j);
        } else {
          break;
        }

        j = this.seekNext(j);
      }
    }
    this.writeBuffer();
    this.caret(Math.max(this.firstNonMaskPos, begin));
  }

  shiftR(pos) {
    let i;
    let c;
    let j;
    let t;

    for (i = pos, c = this.getPlaceholder(pos); i < this.len; i++) {
      if (this.tests[i]) {
        j = this.seekNext(i);
        t = this.buffer[i];
        this.buffer[i] = c;
        if (j < this.len && this.tests[j].test(t)) {
          c = t;
        } else {
          break;
        }
      }
    }
  }

  handleAndroidInput(e) {
    const curVal = this.inputViewChild.nativeElement.value;
    const pos = this.caret();
    if (this.oldVal && this.oldVal.length && this.oldVal.length > curVal.length) {
      // a deletion or backspace happened
      this.checkVal(true);
      while (pos.begin > 0 && !this.tests[pos.begin - 1]) {
        pos.begin--;
      }
      if (pos.begin === 0) {
        while (pos.begin < this.firstNonMaskPos && !this.tests[pos.begin]) {
          pos.begin++;
        }
      }

      setTimeout(() => {
        this.caret(pos.begin, pos.begin);
        this.updateModel(e);
        if (this.isCompleted()) {
          this.onComplete.emit();
        }
      }, 0);
    } else {
      this.checkVal(true);
      while (pos.begin < this.len && !this.tests[pos.begin]) {
        pos.begin++;
      }

      setTimeout(() => {
        this.caret(pos.begin, pos.begin);
        this.updateModel(e);
        if (this.isCompleted()) {
          this.onComplete.emit();
        }
      }, 0);
    }
  }

  onInputBlur(e) {
    this.focused = false;
    this.onTouched();
    this.checkVal();
    this.updateFilledState();
    this.onBlur.emit(e);

    if (this.inputViewChild.nativeElement.value !== this.focusText || this.inputViewChild.nativeElement.value !== this._value) {
      this.updateModel(e);
      const event = document.createEvent('HTMLEvents');
      event.initEvent('change', true, false);
      this.inputViewChild.nativeElement.dispatchEvent(event);
    }
  }

  onInputKeydown(e) {
    if (this.readonly) {
      return;
    }

    const k = e.which || e.keyCode;
    let pos;
    let begin;
    let end;
    const iPhone = /iphone/i.test(this.getUserAgent());
    this.oldVal = this.inputViewChild.nativeElement.value;

    this.onKeydown.emit(e);

    // backspace, delete, and escape get special treatment
    if (k === 8 || k === 46 || (iPhone && k === 127)) {
      pos = this.caret();
      begin = pos.begin;
      end = pos.end;

      if (end - begin === 0) {
        begin = k !== 46 ? this.seekPrev(begin) : (end = this.seekNext(begin - 1));
        end = k === 46 ? this.seekNext(end) : end;
      }

      this.clearBuffer(begin, end);
      this.shiftL(begin, end - 1);
      this.updateModel(e);
      this.onInput.emit(e);

      e.preventDefault();
    } else if (k === 13) { // enter
      this.onInputBlur(e);
      this.updateModel(e);
    } else if (k === 27) { // escape
      this.inputViewChild.nativeElement.value = this.focusText;
      this.caret(0, this.checkVal());
      this.updateModel(e);

      e.preventDefault();
    }
  }

  onKeyPress(e) {
    if (this.readonly) {
      return;
    }

    const k = e.which || e.keyCode;
    const pos = this.caret();
    let p;
    let c;
    let next;
    let completed;

    if (e.ctrlKey || e.altKey || e.metaKey || k < 32 || (k > 34 && k < 41)) {// Ignore
      return;
    } else if (k && k !== 13) {
      if (pos.end - pos.begin !== 0) {
        this.clearBuffer(pos.begin, pos.end);
        this.shiftL(pos.begin, pos.end - 1);
      }

      p = this.seekNext(pos.begin - 1);
      if (p < this.len) {
        c = String.fromCharCode(k);
        if (this.tests[p].test(c)) {
          this.shiftR(p);

          this.buffer[p] = c;
          this.writeBuffer();
          next = this.seekNext(p);

          if (/android/i.test(this.getUserAgent())) {
            // Path for CSP Violation on FireFox OS 1.1
            const proxy = () => {
              this.caret(next);
            };

            setTimeout(proxy, 0);
          } else {
            this.caret(next);
          }

          if (pos.begin <= this.lastRequiredNonMaskPos) {
            completed = this.isCompleted();
          }

          this.onInput.emit(e);
        }
      }
      e.preventDefault();
    }

    this.updateModel(e);

    this.updateFilledState();

    if (completed) {
      this.onComplete.emit();
    }
  }

  clearBuffer(start, end) {
    let i;
    for (i = start; i < end && i < this.len; i++) {
      if (this.tests[i]) {
        this.buffer[i] = this.getPlaceholder(i);
      }
    }
  }

  writeBuffer() {
    this.inputViewChild.nativeElement.value = this.buffer.join('');
  }

  checkVal(allow?: boolean) {
    // try to place characters where they belong
    const test = this.inputViewChild.nativeElement.value;
    let lastMatch = -1;
    let i;
    let c;
    let pos;

    for (i = 0, pos = 0; i < this.len; i++) {
      if (this.tests[i]) {
        this.buffer[i] = this.getPlaceholder(i);
        while (pos++ < test.length) {
          c = test.charAt(pos - 1);
          if (this.tests[i].test(c)) {
            this.buffer[i] = c;
            lastMatch = i;
            break;
          }
        }
        if (pos > test.length) {
          this.clearBuffer(i + 1, this.len);
          break;
        }
      } else {
        if (this.buffer[i] === test.charAt(pos)) {
          pos++;
        }
        if (i < this.partialPosition) {
          lastMatch = i;
        }
      }
    }
    if (allow) {
      this.writeBuffer();
    } else if (lastMatch + 1 < this.partialPosition) {
      if (this.autoClear || this.buffer.join('') === this.defaultBuffer) {
        // Invalid value. Remove it and replace it with the
        // mask, which is the default behavior.
        if (this.inputViewChild.nativeElement.value) {
          this.inputViewChild.nativeElement.value = '';
        }
        this.clearBuffer(0, this.len);
      } else {
        // Invalid value, but we opt to show the value to the
        // user and allow them to correct their mistake.
        this.writeBuffer();
      }
    } else {
      this.writeBuffer();
      this.inputViewChild.nativeElement.value = this.inputViewChild.nativeElement.value.substring(0, lastMatch + 1);
    }
    return (this.partialPosition ? i : this.firstNonMaskPos);
  }

  onInputFocus(event) {
    if (this.readonly) {
      return;
    }

    this.focused = true;

    clearTimeout(this.caretTimeoutId);
    let pos;

    this.focusText = this.inputViewChild.nativeElement.value;

    pos = this.checkVal();

    this.caretTimeoutId = setTimeout(() => {
      if (this.inputViewChild.nativeElement !== this.inputViewChild.nativeElement.ownerDocument.activeElement) {
        return;
      }
      this.writeBuffer();
      if (pos === this.mask.replace('?', '').length) {
        this.caret(0, pos);
      } else {
        this.caret(pos);
      }
    }, 10);

    this.onFocus.emit(event);
  }

  onInputChange(event) {
    if (this.androidChrome) {
      this.handleAndroidInput(event);
    } else {
      this.handleInputChange(event);
    }

    this.onInput.emit(event);
  }

  handleInputChange(event) {
    if (this.readonly) {
      return;
    }

    setTimeout(() => {
      const pos = this.checkVal(true);
      this.caret(pos);
      this.updateModel(event);
      if (this.isCompleted()) {
        this.onComplete.emit();
      }
    }, 0);
  }

  getUnmaskedValue() {
    const unmaskedBuffer = [];
    for (let i = 0; i < this.buffer.length; i++) {
      const c = this.buffer[i];
      if (this.tests[i] && c !== this.getPlaceholder(i)) {
        unmaskedBuffer.push(c);
      }
    }

    return unmaskedBuffer.join('');
  }

  updateModel(e) {
    const updatedValue = this.unmask ? this.getUnmaskedValue() : e.target.value;
    if (updatedValue !== null || updatedValue !== undefined) {
      this._value = updatedValue;
      this.onChange(this._value);
    }
  }

  updateFilledState() {
    this.filled = this.inputViewChild.nativeElement && this.inputViewChild.nativeElement.value !== '';
  }

  focus() {
    this.inputViewChild.nativeElement.focus();
  }
}
