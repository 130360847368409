import { Pipe, PipeTransform } from '@angular/core';
import {AbstractControl} from '@angular/forms';
import {DocumentInterface} from '../interfaces/document.interface';
import {Offer} from '@app/models/offer.model';

@Pipe({ name: 'documentsOrder', pure: true })
export class DocumentsOrderPipe implements PipeTransform {
  constructor() {}
  transform(items: AbstractControl[], group: string, offer: Offer) {
    if (group === 'Doklady totožnosti') {
      items.sort((a, b) => {
        const aDoc: DocumentInterface = a.value;
        const bDoc: DocumentInterface = b.value;
        const aIndex = offer.applicants.findIndex((app: any) => app._id === aDoc.contactId);
        const bIndex = offer.applicants.findIndex((app: any) => app._id === bDoc.contactId);
        if (!aDoc.isInternal && !bDoc.isInternal) {
          return aIndex > bIndex ? 1 : aIndex < bIndex ? -1 : bDoc._template?.name.localeCompare(aDoc._template?.name);
        }
      });
    } else if (group === 'Dokumenty ke schválení') {
      items.sort((a, b) => {
        const aDoc: DocumentInterface = a.value;
        const bDoc: DocumentInterface = b.value;
        if (!aDoc.type) {
          aDoc.type = '';
        }
        if (!bDoc.type) {
          bDoc.type = '';
        }
        const aIndex = offer.applicants.findIndex((app: any) => app._id === aDoc.contactId);
        const bIndex = offer.applicants.findIndex((app: any) => app._id === bDoc.contactId);
        if (!aDoc.isInternal && !bDoc.isInternal) {
          return aDoc.type?.localeCompare(bDoc.type) !== 0 ? aDoc.type?.localeCompare(bDoc.type) : aIndex > bIndex ? 1 : aIndex < bIndex ? -1 : aDoc._template?.name.localeCompare(bDoc._template?.name);
        }
      });
    } else if (group === 'Dokumenty pro čerpání') {
      items.sort((a, b) => {
        const aDoc: DocumentInterface = a.value;
        const bDoc: DocumentInterface = b.value;
        if (!aDoc.isInternal && !bDoc.isInternal) {
          return aDoc.drawdownIndex > bDoc.drawdownIndex ? 1 : aDoc.drawdownIndex < bDoc.drawdownIndex ? -1 : bDoc._template?.name.localeCompare(aDoc._template?.name);
        }
      });
    } else if (group === 'internal') {
      items.sort((a, b) => {
        const aDoc: DocumentInterface = a.value;
        const bDoc: DocumentInterface = b.value;
        return (new Date(bDoc.createdAt))?.getTime() - (new Date(aDoc.createdAt))?.getTime();
      });
    } else {
      items.sort((a, b) => {
        const aDoc: DocumentInterface = a.value;
        const bDoc: DocumentInterface = b.value;
        if (!aDoc.type) {
          aDoc.type = '';
        }
        if (!bDoc.type) {
          bDoc.type = '';
        }
        if (!aDoc.isInternal && !bDoc.isInternal) {
          return aDoc.type?.localeCompare(bDoc.type) !== 0 ? aDoc.type?.localeCompare(bDoc.type) : aDoc._template?.name.localeCompare(bDoc._template?.name);
        }
      });
    }
    return items;
  }
}
