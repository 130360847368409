import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    DoCheck,
    ElementRef,
    EventEmitter,
    HostListener,
    Inject,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Optional,
    Output,
    Self,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NgControl, NgModel } from '@angular/forms';
import { MAT_FORM_FIELD, MatFormField, MatFormFieldControl } from '@angular/material/form-field';
import { DOCUMENT } from '@angular/common';
import { Subject } from 'rxjs';
import { InputNumberInputEvent } from '@app/components/input-number/input-number.interface';

declare type Nullable<T = void> = T | null | undefined;

// tslint:disable-next-line:no-conflicting-lifecycle
@Component({
    selector: 'app-input-number',
    templateUrl: './input-number.component.html',
    styleUrls: ['./input-number.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [{provide: MatFormFieldControl, useExisting: InputNumberComponent}],
    // tslint:disable-next-line:no-host-metadata-property
    host: {
        '[class.example-floating]': 'shouldLabelFloat',
        '[id]': 'id',
    },
})
export class InputNumberComponent implements OnInit, OnChanges, AfterViewInit, ControlValueAccessor, OnDestroy, DoCheck, MatFormFieldControl<number> {
    static nextId = 0;
    @ViewChild('input') input!: ElementRef;

    private _placeholder: string;
    private _required = false;
    private _disabled = false;
    private _value: Nullable<number>;
    stateChanges = new Subject<void>();
    filled: Nullable<boolean>;
    focused = false;
    touched = false;
    controlType = 'app-input-number';
    id = `app-input-number-${InputNumberComponent.nextId++}`;
    initialized: Nullable<boolean>;
    groupChar: string = '';
    prefixChar: string = '';
    suffixChar: string = '';
    isSpecialChar: Nullable<boolean>;
    timer: any;
    lastValue: Nullable<string>;
    _numeral: any;
    numberFormat: any;
    _decimal: any;
    _group: any;
    _minusSign: any;
    _currency: Nullable<RegExp | string>;
    _prefix: Nullable<RegExp>;
    _suffix: Nullable<RegExp>;
    _index: number | any;

    // tslint:disable-next-line:no-input-rename
    @Input('aria-describedby') userAriaDescribedBy: string;

    @Input()
    get placeholder(): string {
        return this._placeholder;
    }

    set placeholder(value: string) {
        this._placeholder = value;
        this.stateChanges.next();
    }

    @Input()
    get required(): boolean {
        return this._required;
    }

    set required(value: BooleanInput) {
        this._required = coerceBooleanProperty(value);
        this.stateChanges.next();
    }

    @Input()
    get disabled(): boolean {
        return this._disabled;
    }

    set disabled(value: BooleanInput) {
        this._disabled = coerceBooleanProperty(value);
        if (this.timer) {
            this.clearTimer();
        }
        this.stateChanges.next();
    }

    @Input()
    get value(): number | null {
        return this._value;
    }

    set value(value: number | null) {
        this._value = value;
        this.stateChanges.next();
    }

    @Input() format: boolean = true;
    @Input() size: number | undefined;
    @Input() maxlength: number | undefined;
    @Input() tabindex: number | undefined;
    @Input() name: string | undefined;
    @Input() autocomplete: string | undefined;
    @Input() min: number | undefined;
    @Input() max: number | undefined;
    @Input() readonly: boolean = false;
    @Input() step: number = 1;
    @Input() allowEmpty: boolean = true;
    @Input() locale: string | undefined;
    @Input() localeMatcher: string | undefined;
    @Input() mode: string = 'decimal';
    @Input() currency: string | undefined;
    @Input() currencyDisplay: string | undefined;
    @Input() useGrouping: boolean = true;
    @Input() minFractionDigits: number | undefined;
    @Input() maxFractionDigits: number | undefined;
    @Input() prefix: string | undefined;
    @Input() suffix: string | undefined;
    @Input() styleClass: string | undefined;
    @Input() style: { [klass: string]: any } | null | undefined;
    @Input() inputStyle: any;
    @Input() inputStyleClass: string | undefined;

    // tslint:disable:no-output-on-prefix
    @Output() onInput: EventEmitter<InputNumberInputEvent> = new EventEmitter<InputNumberInputEvent>();
    @Output() onFocus: EventEmitter<Event> = new EventEmitter<Event>();
    @Output() onBlur: EventEmitter<Event> = new EventEmitter<Event>();
    @Output() onKeyDown: EventEmitter<KeyboardEvent> = new EventEmitter<KeyboardEvent>();
    @Output() onClear: EventEmitter<void> = new EventEmitter<void>();

    // tslint:enable:no-output-on-prefix

    @HostListener('input', ['$event'])
    onInputEvent() {
        this.updateFilledState();
    }

    onChange = (_: any) => {};
    onTouched = () => {};

    constructor(
        @Inject(DOCUMENT) private document: Document,
        private el: ElementRef,
        private cd: ChangeDetectorRef,
        @Optional() @Inject(MAT_FORM_FIELD) public _formField: MatFormField,
        @Optional() @Self() public ngControl: NgControl,
        @Optional() public ngModel: NgModel,
    ) {
        if (this.ngControl != null) {
            this.ngControl.valueAccessor = this;
        }
    }

    ngOnChanges(simpleChange: SimpleChanges) {
        const props = ['locale', 'localeMatcher', 'mode', 'currency', 'currencyDisplay', 'useGrouping', 'minFractionDigits', 'maxFractionDigits', 'prefix', 'suffix'];
        if (props.some((p) => !!simpleChange[p])) {
            this.updateConstructParser();
        }
    }

    ngOnInit() {
        this.constructParser();
        this.initialized = true;
    }

    ngAfterViewInit() {
        this.updateFilledState();
        this.cd.detectChanges();
    }

    ngDoCheck() {
        this.updateFilledState();
    }

    ngOnDestroy() {
        this.stateChanges.complete();
    }

    get empty() {
        return !this._value;
    }

    get shouldLabelFloat() {
      return this.focused || (this._value !== null && this._value !== undefined);
    }

    setDescribedByIds(ids: string[]) {
        const controlElement = this.el.nativeElement.querySelector(
            '.app-input-number-container',
        );
        if (controlElement) {
            controlElement.setAttribute('aria-describedby', ids.join(' '));
        }
    }

    onContainerClick(event: MouseEvent) {
        // tslint:disable-next-line:triple-equals
        if ((event.target as Element).tagName.toLowerCase() != 'input') {
            this.el.nativeElement.querySelector('input').focus();
        }
    }

    get errorState(): boolean {
      if (this.ngControl?.errors !== null && this.ngControl?.touched) {
        return true;
      } else if (this.min && this._value && this._value < this.min) {
        return true;
      } else if (this.max && this._value && this._value > this.max) {
        return true;
      }
      return false;
    }

    onFocusIn(event: FocusEvent) {
        if (!this.focused) {
            this.focused = true;
            this.stateChanges.next();
        }
    }

    onFocusOut(event: FocusEvent) {
        if (!this.el.nativeElement.contains(event.relatedTarget as Element)) {
            this.touched = true;
            this.focused = false;
            this.onTouched();
            this.stateChanges.next();
        }
    }

    updateFilledState() {
        this.filled = (this.el.nativeElement.value && this.el.nativeElement.value.length) || (this.ngModel && this.ngModel.model);
    }

    getOptions() {
        return {
            localeMatcher: this.localeMatcher,
            style: this.mode,
            currency: this.currency,
            currencyDisplay: this.currencyDisplay,
            useGrouping: this.useGrouping,
            minimumFractionDigits: this.minFractionDigits,
            maximumFractionDigits: this.maxFractionDigits
        };
    }

    constructParser() {
        this.numberFormat = new Intl.NumberFormat(this.locale, this.getOptions());
        const numerals = [...new Intl.NumberFormat(this.locale, {useGrouping: false}).format(9876543210)].reverse();
        const index = new Map(numerals.map((d, i) => [d, i]));
        this._numeral = new RegExp(`[${numerals.join('')}]`, 'g');
        this._group = this.getGroupingExpression();
        this._minusSign = this.getMinusSignExpression();
        this._currency = this.getCurrencyExpression();
        this._decimal = this.getDecimalExpression();
        this._suffix = this.getSuffixExpression();
        this._prefix = this.getPrefixExpression();
        this._index = (d: any) => index.get(d);
    }

    updateConstructParser() {
        if (this.initialized) {
            this.constructParser();
        }
    }

    escapeRegExp(text: string): string {
        return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
    }

    getDecimalExpression(): RegExp {
        const formatter = new Intl.NumberFormat(this.locale, {...this.getOptions(), useGrouping: false});
        return new RegExp(
            `[${formatter
                .format(1.1)
                .replace(this._currency as RegExp | string, '')
                .trim()
                .replace(this._numeral, '')}]`,
            'g'
        );
    }

    getGroupingExpression(): RegExp {
        const formatter = new Intl.NumberFormat(this.locale, {useGrouping: true});
        this.groupChar = formatter.format(1000000).trim().replace(this._numeral, '').charAt(0);
        return new RegExp(`[${this.groupChar}]`, 'g');
    }

    getMinusSignExpression(): RegExp {
        const formatter = new Intl.NumberFormat(this.locale, {useGrouping: false});
        return new RegExp(`[${formatter.format(-1).trim().replace(this._numeral, '')}]`, 'g');
    }

    getCurrencyExpression(): RegExp {
        if (this.currency) {
            const formatter = new Intl.NumberFormat(this.locale, {
                style: 'currency',
                currency: this.currency,
                currencyDisplay: this.currencyDisplay,
                minimumFractionDigits: 0,
                maximumFractionDigits: 0
            });
            return new RegExp(`[${formatter.format(1).replace(/\s/g, '').replace(this._numeral, '').replace(this._group, '')}]`, 'g');
        }

        return new RegExp(`[]`, 'g');
    }

    getPrefixExpression(): RegExp {
        if (this.prefix) {
            this.prefixChar = this.prefix;
        } else {
            const formatter = new Intl.NumberFormat(this.locale, {
                style: this.mode,
                currency: this.currency,
                currencyDisplay: this.currencyDisplay
            });
            this.prefixChar = formatter.format(1).split('1')[0];
        }

        return new RegExp(`${this.escapeRegExp(this.prefixChar || '')}`, 'g');
    }

    getSuffixExpression(): RegExp {
        if (this.suffix) {
            this.suffixChar = this.suffix;
        } else {
            const formatter = new Intl.NumberFormat(this.locale, {
                style: this.mode,
                currency: this.currency,
                currencyDisplay: this.currencyDisplay,
                minimumFractionDigits: 0,
                maximumFractionDigits: 0
            });
            this.suffixChar = formatter.format(1).split('1')[1];
        }

        return new RegExp(`${this.escapeRegExp(this.suffixChar || '')}`, 'g');
    }

    formatValue(value: any) {
        if (value != null) {
            if (value === '-') {
                // Minus sign
                return value;
            }

            if (this.format) {
                const formatter = new Intl.NumberFormat(this.locale, this.getOptions());
                let formattedValue = formatter.format(value);
                if (this.prefix) {
                    formattedValue = this.prefix + formattedValue;
                }

                if (this.suffix) {
                    formattedValue = formattedValue + this.suffix;
                }

                return formattedValue;
            }

            return value.toString();
        }

        return '';
    }

    parseValue(text: any) {
        const filteredText = text
            .replace(this._suffix as RegExp, '')
            .replace(this._prefix as RegExp, '')
            .trim()
            .replace(/\s/g, '')
            .replace(this._currency as RegExp, '')
            .replace(this._group, '')
            .replace(this._minusSign, '-')
            .replace(this._decimal, '.')
            .replace(this._numeral, this._index);

        if (filteredText) {
            if (filteredText === '-') {
                return filteredText;
            }

            const parsedValue = +filteredText;
            return isNaN(parsedValue) ? null : parsedValue;
        }

        return null;
    }

    repeat(event: Event, interval: number | null, dir: number) {
        if (this.readonly) {
            return;
        }

        const i = interval || 500;

        this.clearTimer();
        this.timer = setTimeout(() => {
            this.repeat(event, 40, dir);
        }, i);

        this.spin(event, dir);
    }

    spin(event: Event, dir: number) {
        const step = this.step * dir;
        const currentValue = this.parseValue(this.input?.nativeElement.value) || 0;
        const newValue = this.validateValue((currentValue as number) + step);
        if (this.maxlength && this.maxlength < this.formatValue(newValue).length) {
            return;
        }
        this.updateInput(newValue, null, 'spin', null);
        this.updateModel(event, newValue);

        this.handleOnInput(event, currentValue, newValue);
    }

    onUserInput(event: Event) {
        if (this.readonly) {
            return;
        }

        if (this.isSpecialChar) {
            (event.target as HTMLInputElement).value = this.lastValue as string;
        }
        this.isSpecialChar = false;
    }

    onInputKeyDown(event: KeyboardEvent) {
        if (this.readonly) {
            return;
        }

        this.lastValue = (event.target as HTMLInputElement).value;
        if ((event as KeyboardEvent).shiftKey || (event as KeyboardEvent).altKey) {
            this.isSpecialChar = true;
            return;
        }

        const selectionStart = (event.target as HTMLInputElement).selectionStart as number;
        const selectionEnd = (event.target as HTMLInputElement).selectionEnd as number;
        const inputValue = (event.target as HTMLInputElement).value as string;
        let newValueStr = null;

        if (event.altKey) {
            event.preventDefault();
        }

        switch (event.code) {
            case 'ArrowUp':
                this.spin(event, 1);
                event.preventDefault();
                break;

            case 'ArrowDown':
                this.spin(event, -1);
                event.preventDefault();
                break;

            case 'ArrowLeft':
                if (!this.isNumeralChar(inputValue.charAt(selectionStart - 1))) {
                    event.preventDefault();
                }
                break;

            case 'ArrowRight':
                if (!this.isNumeralChar(inputValue.charAt(selectionStart))) {
                    event.preventDefault();
                }
                break;

            case 'Tab':
            case 'Enter':
                newValueStr = this.validateValue(this.parseValue(this.input.nativeElement.value));
                this.input.nativeElement.value = this.formatValue(newValueStr);
                this.input.nativeElement.setAttribute('aria-valuenow', newValueStr);
                this.updateModel(event, newValueStr);
                break;

            case 'Backspace': {
                event.preventDefault();

                if (selectionStart === selectionEnd) {
                    const deleteChar = inputValue.charAt(selectionStart - 1);
                    const {decimalCharIndex, decimalCharIndexWithoutPrefix} = this.getDecimalCharIndexes(inputValue);

                    if (this.isNumeralChar(deleteChar)) {
                        const decimalLength = this.getDecimalLength(inputValue);

                        if (this._group.test(deleteChar)) {
                            this._group.lastIndex = 0;
                            newValueStr = inputValue.slice(0, selectionStart - 2) + inputValue.slice(selectionStart - 1);
                        } else if (this._decimal.test(deleteChar)) {
                            this._decimal.lastIndex = 0;

                            if (decimalLength) {
                                this.input?.nativeElement.setSelectionRange(selectionStart - 1, selectionStart - 1);
                            } else {
                                newValueStr = inputValue.slice(0, selectionStart - 1) + inputValue.slice(selectionStart);
                            }
                        } else if (decimalCharIndex > 0 && selectionStart > decimalCharIndex) {
                            const insertedText = this.isDecimalMode() && (this.minFractionDigits || 0) < decimalLength ? '' : '0';
                            newValueStr = inputValue.slice(0, selectionStart - 1) + insertedText + inputValue.slice(selectionStart);
                        } else if (decimalCharIndexWithoutPrefix === 1) {
                            newValueStr = inputValue.slice(0, selectionStart - 1) + '0' + inputValue.slice(selectionStart);
                            newValueStr = (this.parseValue(newValueStr) as number) > 0 ? newValueStr : '';
                        } else {
                            newValueStr = inputValue.slice(0, selectionStart - 1) + inputValue.slice(selectionStart);
                        }
                        // tslint:disable-next-line:triple-equals
                    } else if (this.mode === 'currency' && deleteChar.search(this._currency) != -1) {
                        newValueStr = inputValue.slice(1);
                    }

                    this.updateValue(event, newValueStr, null, 'delete-single');
                } else {
                    newValueStr = this.deleteRange(inputValue, selectionStart, selectionEnd);
                    this.updateValue(event, newValueStr, null, 'delete-range');
                }

                break;
            }

            case 'Delete':
                event.preventDefault();

                if (selectionStart === selectionEnd) {
                    const deleteChar = inputValue.charAt(selectionStart);
                    const {decimalCharIndex, decimalCharIndexWithoutPrefix} = this.getDecimalCharIndexes(inputValue);

                    if (this.isNumeralChar(deleteChar)) {
                        const decimalLength = this.getDecimalLength(inputValue);

                        if (this._group.test(deleteChar)) {
                            this._group.lastIndex = 0;
                            newValueStr = inputValue.slice(0, selectionStart) + inputValue.slice(selectionStart + 2);
                        } else if (this._decimal.test(deleteChar)) {
                            this._decimal.lastIndex = 0;

                            if (decimalLength) {
                                this.input?.nativeElement.setSelectionRange(selectionStart + 1, selectionStart + 1);
                            } else {
                                newValueStr = inputValue.slice(0, selectionStart) + inputValue.slice(selectionStart + 1);
                            }
                        } else if (decimalCharIndex > 0 && selectionStart > decimalCharIndex) {
                            const insertedText = this.isDecimalMode() && (this.minFractionDigits || 0) < decimalLength ? '' : '0';
                            newValueStr = inputValue.slice(0, selectionStart) + insertedText + inputValue.slice(selectionStart + 1);
                        } else if (decimalCharIndexWithoutPrefix === 1) {
                            newValueStr = inputValue.slice(0, selectionStart) + '0' + inputValue.slice(selectionStart + 1);
                            newValueStr = (this.parseValue(newValueStr) as number) > 0 ? newValueStr : '';
                        } else {
                            newValueStr = inputValue.slice(0, selectionStart) + inputValue.slice(selectionStart + 1);
                        }
                    }

                    this.updateValue(event, newValueStr as string, null, 'delete-back-single');
                } else {
                    newValueStr = this.deleteRange(inputValue, selectionStart, selectionEnd);
                    this.updateValue(event, newValueStr, null, 'delete-range');
                }
                break;

            case 'Home':
                if (this.min) {
                    this.updateModel(event, this.min);
                    event.preventDefault();
                }
                break;

            case 'End':
                if (this.max) {
                    this.updateModel(event, this.max);
                    event.preventDefault();
                }
                break;

            default:
                break;
        }

        this.onKeyDown.emit(event);
    }

    onInputKeyPress(event: KeyboardEvent) {
        if (this.readonly) {
            return;
        }

        const code = event.which || event.keyCode;
        const char = String.fromCharCode(code);
        const isDecimalSign = this.isDecimalSign(char);
        const isMinusSign = this.isMinusSign(char);

        // tslint:disable-next-line:triple-equals
        if (code != 13) {
            event.preventDefault();
        }

        const newValue = this.parseValue(this.input.nativeElement.value + char);
        const newValueStr = newValue != null ? newValue.toString() : '';
        if (this.maxlength && newValueStr.length > this.maxlength) {
            return;
        }

        if ((48 <= code && code <= 57) || isMinusSign || isDecimalSign) {
            this.insert(event, char, {isDecimalSign, isMinusSign});
        }
    }

    onPaste(event: ClipboardEvent) {
        if (!this.disabled && !this.readonly) {
            event.preventDefault();
            // tslint:disable-next-line:no-string-literal
            let data = (event.clipboardData || (this.document as any).defaultView['clipboardData']).getData('Text');
            if (data) {
                if (this.maxlength) {
                    data = data.toString().substring(0, this.maxlength);
                }

                const filteredData = this.parseValue(data);
                if (filteredData != null) {
                    this.insert(event, filteredData.toString());
                }
            }
        }
    }

    allowMinusSign() {
        return this.min == null || this.min < 0;
    }

    isMinusSign(char: string) {
        if (this._minusSign.test(char) || char === '-') {
            this._minusSign.lastIndex = 0;
            return true;
        }

        return false;
    }

    isDecimalSign(char: string) {
        // accept . and , as decimal separator
        return char === '.' || char === ',';
    }

    isDecimalMode() {
        return this.mode === 'decimal';
    }

    getDecimalCharIndexes(val: string) {
        const decimalCharIndex = val.search(this._decimal);
        this._decimal.lastIndex = 0;

        const filteredVal = val
            .replace(this._prefix as RegExp, '')
            .trim()
            .replace(/\s/g, '')
            .replace(this._currency as RegExp, '');
        const decimalCharIndexWithoutPrefix = filteredVal.search(this._decimal);
        this._decimal.lastIndex = 0;

        return {decimalCharIndex, decimalCharIndexWithoutPrefix};
    }

    getCharIndexes(val: string) {
        const decimalCharIndex = val.search(this._decimal);
        this._decimal.lastIndex = 0;
        const minusCharIndex = val.search(this._minusSign);
        this._minusSign.lastIndex = 0;
        const suffixCharIndex = val.search(this._suffix as RegExp);
        (this._suffix as RegExp).lastIndex = 0;
        const currencyCharIndex = val.search(this._currency as RegExp);
        (this._currency as RegExp).lastIndex = 0;

        return {decimalCharIndex, minusCharIndex, suffixCharIndex, currencyCharIndex};
    }

    insert(event: Event, text: string, sign = {isDecimalSign: false, isMinusSign: false}) {
        const minusCharIndexOnText = text.search(this._minusSign);
        this._minusSign.lastIndex = 0;
        if (!this.allowMinusSign() && minusCharIndexOnText !== -1) {
            return;
        }

        const selectionStart = this.input?.nativeElement.selectionStart;
        const selectionEnd = this.input?.nativeElement.selectionEnd;
        const inputValue = this.input?.nativeElement.value.trim();
        const {decimalCharIndex, minusCharIndex, suffixCharIndex, currencyCharIndex} = this.getCharIndexes(inputValue);
        let newValueStr: string;

        if (sign.isMinusSign) {
            if (selectionStart === 0) {
                newValueStr = inputValue;
                if (minusCharIndex === -1 || selectionEnd !== 0) {
                    newValueStr = this.insertText(inputValue, text, 0, selectionEnd);
                }

                this.updateValue(event, newValueStr, text, 'insert');
            }
        } else if (sign.isDecimalSign) {
            if (decimalCharIndex > 0 && selectionStart === decimalCharIndex) {
                this.updateValue(event, inputValue, text, 'insert');
            } else if (decimalCharIndex > selectionStart && decimalCharIndex < selectionEnd) {
                newValueStr = this.insertText(inputValue, text, selectionStart, selectionEnd);
                this.updateValue(event, newValueStr, text, 'insert');
            } else if (decimalCharIndex === -1 && this.maxFractionDigits) {
                newValueStr = this.insertText(inputValue, text, selectionStart, selectionEnd);
                this.updateValue(event, newValueStr, text, 'insert');
            }
        } else {
            const maxFractionDigits = this.numberFormat.resolvedOptions().maximumFractionDigits;
            const operation = selectionStart !== selectionEnd ? 'range-insert' : 'insert';

            if (decimalCharIndex > 0 && selectionStart > decimalCharIndex) {
                if (selectionStart + text.length - (decimalCharIndex + 1) <= maxFractionDigits) {
                    const charIndex = currencyCharIndex >= selectionStart ? currencyCharIndex - 1 : suffixCharIndex >= selectionStart ? suffixCharIndex : inputValue.length;

                    newValueStr = inputValue.slice(0, selectionStart) + text + inputValue.slice(selectionStart + text.length, charIndex) + inputValue.slice(charIndex);
                    this.updateValue(event, newValueStr, text, operation);
                }
            } else {
                newValueStr = this.insertText(inputValue, text, selectionStart, selectionEnd);
                this.updateValue(event, newValueStr, text, operation);
            }
        }
    }

    insertText(value: string, text: string, start: number, end: number) {
        const textSplit = text === '.' ? text : text.split('.');

        if (textSplit.length === 2) {
            const decimalCharIndex = value.slice(start, end).search(this._decimal);
            this._decimal.lastIndex = 0;
            return decimalCharIndex > 0 ? value.slice(0, start) + this.formatValue(text) + value.slice(end) : value || this.formatValue(text);
        } else if (end - start === value.length) {
            return this.formatValue(text);
        } else if (start === 0) {
            return text + value.slice(end);
        } else if (end === value.length) {
            return value.slice(0, start) + text;
        } else {
            return value.slice(0, start) + text + value.slice(end);
        }
    }

    deleteRange(value: string, start: number, end: number) {
        let newValueStr: string;

        if (end - start === value.length) {
            newValueStr = '';
        } else if (start === 0) {
            newValueStr = value.slice(end);
        } else if (end === value.length) {
            newValueStr = value.slice(0, start);
        } else {
            newValueStr = value.slice(0, start) + value.slice(end);
        }

        return newValueStr;
    }

    initCursor() {
        let selectionStart = this.input?.nativeElement.selectionStart;
        let inputValue = this.input?.nativeElement.value;
        const valueLength = inputValue.length;
        let index = null;

        const prefixLength = (this.prefixChar || '').length;
        inputValue = inputValue.replace(this._prefix, '');
        selectionStart = selectionStart - prefixLength;

        let char = inputValue.charAt(selectionStart);
        if (this.isNumeralChar(char)) {
            return selectionStart + prefixLength;
        }

        let i = selectionStart - 1;
        while (i >= 0) {
            char = inputValue.charAt(i);
            if (this.isNumeralChar(char)) {
                index = i + prefixLength;
                break;
            } else {
                i--;
            }
        }

        if (index !== null) {
            this.input?.nativeElement.setSelectionRange(index + 1, index + 1);
        } else {
            i = selectionStart;
            while (i < valueLength) {
                char = inputValue.charAt(i);
                if (this.isNumeralChar(char)) {
                    index = i + prefixLength;
                    break;
                } else {
                    i++;
                }
            }

            if (index !== null) {
                this.input?.nativeElement.setSelectionRange(index, index);
            }
        }

        return index || 0;
    }

    onInputClick() {
        const currentValue = this.input?.nativeElement.value;

        if (!this.readonly && currentValue !== getSelection()) {
            this.initCursor();
        }
    }

    isNumeralChar(char: string) {
        if (char.length === 1 && (this._numeral.test(char) || this._decimal.test(char) || this._group.test(char) || this._minusSign.test(char))) {
            this.resetRegex();
            return true;
        }

        return false;
    }

    resetRegex() {
        this._numeral.lastIndex = 0;
        this._decimal.lastIndex = 0;
        this._group.lastIndex = 0;
        this._minusSign.lastIndex = 0;
    }

    updateValue(event: Event, valueStr: Nullable<string>, insertedValueStr: Nullable<string>, operation: Nullable<string>) {
        const currentValue = this.input?.nativeElement.value;
        let newValue = null;

        if (valueStr != null) {
            newValue = this.parseValue(valueStr);
            newValue = !newValue && !this.allowEmpty ? 0 : newValue;
            this.updateInput(newValue, insertedValueStr, operation, valueStr);

            this.handleOnInput(event, currentValue, newValue);
        }
    }

    handleOnInput(event: Event, currentValue: string, newValue: any) {
        if (this.isValueChanged(currentValue, newValue)) {
            (this.input as ElementRef).nativeElement.value = this.formatValue(newValue);
            this.input?.nativeElement.setAttribute('aria-valuenow', newValue);
            this.updateModel(event, newValue);
            this.onInput.emit({originalEvent: event, value: newValue, formattedValue: currentValue});
        }
    }

    isValueChanged(currentValue: string, newValue: string) {
        if (newValue === null && currentValue !== null) {
            return true;
        }

        if (newValue != null) {
            const parsedCurrentValue = typeof currentValue === 'string' ? this.parseValue(currentValue) : currentValue;
            return newValue !== parsedCurrentValue;
        }

        return false;
    }

    validateValue(value: number | string) {
        if (value === '-' || value == null) {
            return null;
        }

        if (this.min != null && (value as number) < this.min) {
            return this.min;
        }

        if (this.max != null && (value as number) > this.max) {
            return this.max;
        }

        return value;
    }

    updateInput(value: any, insertedValueStr: Nullable<string>, operation: Nullable<string>, valueStr: Nullable<string>) {
        insertedValueStr = insertedValueStr || '';

        const inputValue = this.input?.nativeElement.value;
        let newValue = this.formatValue(value);
        const currentLength = inputValue.length;

        if (newValue !== valueStr) {
            newValue = this.concatValues(newValue, valueStr as string);
        }

        if (currentLength === 0) {
            this.input.nativeElement.value = newValue;
            this.input.nativeElement.setSelectionRange(0, 0);
            const index = this.initCursor();
            const selectionEnd = index + insertedValueStr.length;
            this.input.nativeElement.setSelectionRange(selectionEnd, selectionEnd);
        } else {
            let selectionStart = this.input.nativeElement.selectionStart;
            let selectionEnd = this.input.nativeElement.selectionEnd;

            if (this.maxlength && newValue.length > this.maxlength) {
                newValue = newValue.slice(0, this.maxlength);
                selectionStart = Math.min(selectionStart, this.maxlength);
                selectionEnd = Math.min(selectionEnd, this.maxlength);
            }

            if (this.maxlength && this.maxlength < newValue.length) {
                return;
            }

            this.input.nativeElement.value = newValue;
            const newLength = newValue.length;

            if (operation === 'range-insert') {
                const startValue = this.parseValue((inputValue || '').slice(0, selectionStart));
                const startValueStr = startValue !== null ? startValue.toString() : '';
                const startExpr = startValueStr.split('').join(`(${this.groupChar})?`);
                const sRegex = new RegExp(startExpr, 'g');
                sRegex.test(newValue);

                const tExpr = insertedValueStr.split('').join(`(${this.groupChar})?`);
                const tRegex = new RegExp(tExpr, 'g');
                tRegex.test(newValue.slice(sRegex.lastIndex));

                selectionEnd = sRegex.lastIndex + tRegex.lastIndex;
                this.input.nativeElement.setSelectionRange(selectionEnd, selectionEnd);
            } else if (newLength === currentLength) {
                if (operation === 'insert' || operation === 'delete-back-single') {
                    this.input.nativeElement.setSelectionRange(selectionEnd + 1, selectionEnd + 1);
                } else if (operation === 'delete-single') {
                    this.input.nativeElement.setSelectionRange(selectionEnd - 1, selectionEnd - 1);
                } else if (operation === 'delete-range' || operation === 'spin') {
                    this.input.nativeElement.setSelectionRange(selectionEnd, selectionEnd);
                }
            } else if (operation === 'delete-back-single') {
                const prevChar = inputValue.charAt(selectionEnd - 1);
                const nextChar = inputValue.charAt(selectionEnd);
                const diff = currentLength - newLength;
                const isGroupChar = this._group.test(nextChar);

                if (isGroupChar && diff === 1) {
                    selectionEnd += 1;
                } else if (!isGroupChar && this.isNumeralChar(prevChar)) {
                    selectionEnd += -1 * diff + 1;
                }

                this._group.lastIndex = 0;
                this.input.nativeElement.setSelectionRange(selectionEnd, selectionEnd);
            } else if (inputValue === '-' && operation === 'insert') {
                this.input.nativeElement.setSelectionRange(0, 0);
                const index = this.initCursor();
                const selEnd = index + insertedValueStr.length + 1;
                this.input.nativeElement.setSelectionRange(selEnd, selEnd);
            } else {
                selectionEnd = selectionEnd + (newLength - currentLength);
                this.input.nativeElement.setSelectionRange(selectionEnd, selectionEnd);
            }
        }

        this.input.nativeElement.setAttribute('aria-valuenow', value);
    }

    concatValues(val1: string, val2: string) {
        if (val1 && val2) {
            const decimalCharIndex = val2.search(this._decimal);
            this._decimal.lastIndex = 0;

            if (this.suffixChar) {
                return val1.replace(this.suffixChar, '').split(this._decimal)[0] + val2.replace(this.suffixChar, '').slice(decimalCharIndex) + this.suffixChar;
            } else {
                return decimalCharIndex !== -1 ? val1.split(this._decimal)[0] + val2.slice(decimalCharIndex) : val1;
            }
        }
        return val1;
    }

    getDecimalLength(value: string) {
        if (value) {
            const valueSplit = value.split(this._decimal);

            if (valueSplit.length === 2) {
                return valueSplit[1]
                    .replace(this._suffix as RegExp, '')
                    .trim()
                    .replace(/\s/g, '')
                    .replace(this._currency as RegExp, '').length;
            }
        }

        return 0;
    }

    onInputFocus(event: Event) {
        this.focused = true;
        this.onFocus.emit(event);
    }

    onInputBlur(event: Event) {
        this.focused = false;

        const newValue = this.validateValue(this.parseValue(this.input.nativeElement.value));

        this.onBlur.emit(event);

        this.input.nativeElement.value = this.formatValue(newValue);
        this.input.nativeElement.setAttribute('aria-valuenow', newValue);
        this.updateModel(event, newValue);
    }

    formattedValue() {
        const val = !this._value && !this.allowEmpty ? 0 : this._value;
        return this.formatValue(val);
    }

    updateModel(event: Event, value: any) {
        if (this._value !== value) {
            this._value = value;
            this.onChange(value);
        }
        this.onTouched();
    }

    writeValue(value: any): void {
        this._value = value;
        this.cd.markForCheck();
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState(val: boolean): void {
        this.disabled = val;
        this.cd.markForCheck();
    }

    clearTimer() {
        if (this.timer) {
            clearInterval(this.timer);
        }
    }
}
