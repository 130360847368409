import { AbstractControl, FormArray, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { DataService } from './data.service';
import { Contact } from '../models/contact.model';
import { SettingsService } from '@app/services/settings.service';
import { DialogsService } from '@app/services/dialogs.service';
import { MainService } from '@app/services/main.service';
import { SnackbarService } from '@app/services/snackbar.service';
import { CarInsurancesService } from '@app/services/car-insurances.service';
import { LifeInsurancesService } from '@app/services/life-insurances.service';
import { RealEstateInsurancesService } from '@app/services/real-estate-insurances.service';
import { UsersService } from '@app/services/users.service';

@Injectable({providedIn: 'root'})
export class ContactsService extends DataService<Contact> {

  public contact: Contact;
  public $contact: BehaviorSubject<Contact> = new BehaviorSubject<Contact>(null);
  public contact$: Observable<Contact> = this.$contact.asObservable();
  public contactId?: string;
  public $contactId: BehaviorSubject<string> = new BehaviorSubject<string | null>(null);
  public contactId$: Observable<string | null> = this.$contactId.asObservable();

  public contactDeals: any[];

  constructor(
    private fb: FormBuilder,
    private settingsService: SettingsService,
    private dialogsService: DialogsService,
    private mainService: MainService,
    private snackbarService: SnackbarService,
    private carInsurancesService: CarInsurancesService,
    private lifeInsurancesService: LifeInsurancesService,
    private realEstateInsurancesService: RealEstateInsurancesService,
    private usersService: UsersService,
  ) {
    super('contacts');
    this.pagination = 50;
    this.sort = {
      numberId: -1,
    };
  }

  public setContact(contact: Contact) {
    this.contact = contact;
    this.$contact.next(contact);
    if (this.contactId !== contact?._id) {
      this.$contactId.next(contact?._id);
    }
  }

  public setContactId(id?: string) {
    if (this.contactId !== id) {
      this.contactId = id;
      this.$contactId.next(id);
    }
  }

  public createForm(contact?, pipelineUrl?, offerStage?, isLeadApplicant?, aIndex?, hasLegalRepresentative?): FormGroup {
    const contactForm = this.fb.group({
      _id: [],
      numberId: [],
      email: [null, !hasLegalRepresentative ? [Validators.required, Validators.email] : []],
      emailStatus: [0],
      emailVerified: [false],
      phone: this.fb.array([]),
      address: this.fb.group({
        permanent: this.fb.group({
          street: [null],
          orientationNumber: [null],
          houseNumber: [null],
          zipCode: [null],
          city: [null],
          district: [null],
          region: [null], // this.mainService.requireSelectionValidator('region')],
          country: ['CZE', [Validators.required, this.mainService.requireSelectionValidator('country')]],
          livingSince: [null, [Validators.min(1900), Validators.max(2100)]]
        }),
        mailing: this.fb.group({
          street: [null],
          orientationNumber: [null],
          houseNumber: [null],
          zipCode: [null],
          city: [null],
          district: [null],
          region: [null], // this.mainService.requireSelectionValidator('region')],
          country: ['CZE', [this.mainService.requireSelectionValidator('country')]],
          livingSince: [null, [Validators.min(1900), Validators.max(2100)]],
          sameAsPermanent: [false],
          sameAsTemporary: [false]
        }),
        temporary: this.fb.group({
          isActive: [false],
          street: [null],
          orientationNumber: [null],
          houseNumber: [null],
          zipCode: [null],
          city: [null],
          district: [null],
          region: [null], // this.mainService.requireSelectionValidator('region')],
          country: ['CZE', [this.mainService.requireSelectionValidator('country')]],
          livingSince: [null, [Validators.min(1900), Validators.max(2100)]]
        }),
        courier: this.fb.group({
          street: [null],
          orientationNumber: [null],
          houseNumber: [null],
          zipCode: [null],
          city: [null],
          district: [null],
          region: [null], // this.mainService.requireSelectionValidator('region')],
          country: ['CZE', [this.mainService.requireSelectionValidator('country')]],
          sameAsPermanent: [false],
          sameAsTemporary: [false],
          sameAsMailing: [false]
        }),
      }),
      subjectType: [null],
      companyNumber: [null],
      companyName: [null],
      titleBefore: [null],
      titleAfter: [null],
      firstName: [null, Validators.required],
      lastName: [null, Validators.required],
      familyName: [null],
      education: [null],
      sex: ['male', Validators.required],
      birthDate: [null, Validators.required],
      personalNumber: [null],
      nationality: ['CZE', [Validators.required, this.mainService.requireSelectionValidator('country')]],
      residence: [null],
      residenceFromDate: [null],
      residenceToDate: [null],
      economicallyActivePeriod: [null],
      familyStatus: [null],
      caresOfHousehold: [false],
      hasSicknessInsurance: [false],
      job: [null, [this.mainService.requireSelectionValidator('job')]],
      placeOfBirth: this.fb.group({
        city: [null],
        country: ['CZE', [Validators.required, this.mainService.requireSelectionValidator('country')]],
        zipCode: [null],
      }),
      bankAccounts: this.fb.array([]),
      identifications: this.fb.array([]),
      declarations: this.fb.group({
        isLinkedToBusinessCorporation: [false],
        isCloseToPersonRelatedToBank: [false],
        isRelatedToBank: [false],
        agreesHandlingPersonalData: [true, Validators.requiredTrue],
        isPoliticallyExposed: [false],
      }),
      verifiedStatus: this.fb.group({
        bankId: [null],
        courier: [null],
        internal: [null],
      }),
      incomes: this.fb.array([]),
      expenditures: this.fb.array([]),
      properties: this.fb.array([]),
      children: [[]],
      households: [[]],
      age: [0],
      height: [null],
      weight: [null],
      sport: this.fb.array([]),
      invalidity: this.fb.group({
        isInvalid: [false],
        level: [null]
      }),
      smoking: this.fb.group({
        isSmoker: [false],
        count: [null]
      }),
      dependents: this.fb.group({
        insure: [false],
        count: [null]
      }),
      bloodPresure: this.fb.group({
        isHigh: [false],
        isUnspecified: [false],
        systolic: [null],
        diastolic: [null]
      }),
      reserves: this.fb.group({
        incapacity: [null],
        death: [null]
      }),
      _properties: [[]],
      _households: [[]],
      _children: [[]],
    });
    if (aIndex > 0) {
      contactForm.get('email').removeValidators([Validators.required]);
      contactForm.get('email').updateValueAndValidity();
    }
    if (!isLeadApplicant) {
      contactForm.get('address.permanent.zipCode').clearValidators();
      contactForm.get('address.permanent.zipCode').updateValueAndValidity();
      contactForm.get('address.permanent.country').clearValidators();
      contactForm.get('address.permanent.country').updateValueAndValidity();
      contactForm.get('address.permanent.country').setValidators([this.mainService.requireSelectionValidator('country')]);
      contactForm.get('address.permanent.country').updateValueAndValidity();
    }
    if (pipelineUrl === 'mortgage') {
      contactForm.get('familyStatus').setValidators([Validators.required]);
      contactForm.get('familyStatus').updateValueAndValidity();
      contactForm.get('bankAccounts').setValidators([this.bankAccountsValidator()]);
      contactForm.get('bankAccounts').updateValueAndValidity();
      if (this.isStageBetween(offerStage, 'applicationDocuments')) {
        contactForm.get('email').setValidators([Validators.required, Validators.email]);
        contactForm.get('email').updateValueAndValidity();
        contactForm.get('phone').setValidators([this.mainService.atLeastOneElementValidator]);
        contactForm.get('phone').updateValueAndValidity();
        contactForm.get('address.permanent.street').setValidators([Validators.required]);
        contactForm.get('address.permanent.street').updateValueAndValidity();
        contactForm.get('address.permanent.houseNumber').setValidators([Validators.required]);
        contactForm.get('address.permanent.houseNumber').updateValueAndValidity();
        contactForm.get('address.permanent.zipCode').setValidators([Validators.required]);
        contactForm.get('address.permanent.zipCode').updateValueAndValidity();
        contactForm.get('address.permanent.livingSince').setValidators([Validators.required]);
        contactForm.get('address.permanent.livingSince').updateValueAndValidity();
        contactForm.get('address.permanent.city').setValidators([Validators.required]);
        contactForm.get('address.permanent.city').updateValueAndValidity();
        contactForm.get('address.permanent.country').setValidators([Validators.required, this.mainService.requireSelectionValidator('country')]);
        contactForm.get('address.permanent.country').updateValueAndValidity();
        contactForm.get('address.mailing.street').setValidators([Validators.required]);
        contactForm.get('address.mailing.street').updateValueAndValidity();
        contactForm.get('address.mailing.houseNumber').setValidators([Validators.required]);
        contactForm.get('address.mailing.houseNumber').updateValueAndValidity();
        contactForm.get('address.mailing.zipCode').setValidators([Validators.required]);
        contactForm.get('address.mailing.zipCode').updateValueAndValidity();
        contactForm.get('address.mailing.city').setValidators([Validators.required]);
        contactForm.get('address.mailing.city').updateValueAndValidity();
        contactForm.get('address.mailing.country').setValidators([Validators.required, this.mainService.requireSelectionValidator('country')]);
        contactForm.get('address.mailing.country').updateValueAndValidity();
        contactForm.get('education').setValidators([Validators.required]);
        contactForm.get('education').updateValueAndValidity();
        contactForm.get('job').setValidators([Validators.required]);
        contactForm.get('job').updateValueAndValidity();
        contactForm.get('familyName').setValidators([Validators.required]);
        contactForm.get('familyName').updateValueAndValidity();
      }
      if (this.isStageBetween(offerStage, 'applicationFilling')) {
        contactForm.get('placeOfBirth.city').setValidators([Validators.required]);
        contactForm.get('placeOfBirth.country').setValidators([Validators.required, this.mainService.requireSelectionValidator('country')]);
        contactForm.get('placeOfBirth.city').updateValueAndValidity();
        contactForm.get('placeOfBirth.country').updateValueAndValidity();
        contactForm.get('personalNumber').setValidators([Validators.required]);
        contactForm.get('personalNumber').updateValueAndValidity();
      }
    }
    contactForm.get('birthDate').valueChanges.subscribe((value: any) => {
      contactForm.get('age').patchValue(this.ageFromDate(value));
    });
    contactForm.get('emailVerified').valueChanges.subscribe((value: any) => {
      contactForm.get('email').updateValueAndValidity();
    });
    if (pipelineUrl === 'mortgage') {
      contactForm.get('nationality').valueChanges.subscribe((value: any) => {
        if (value !== 'CZE') {
          contactForm.get('residence').setValidators([Validators.required]);
          contactForm.get('economicallyActivePeriod').setValidators([Validators.required]);
        } else {
          contactForm.get('residence').clearValidators();
          contactForm.get('economicallyActivePeriod').clearValidators();
        }
        contactForm.get('residence').updateValueAndValidity();
        contactForm.get('economicallyActivePeriod').updateValueAndValidity();
      });
      contactForm.get('residence').valueChanges.subscribe((value: any) => {
        if (contactForm.get('nationality').value !== 'CZE' && ['temporary', 'long'].includes(value)) {
          contactForm.get('residenceFromDate').setValidators([Validators.required]);
          contactForm.get('residenceToDate').setValidators([Validators.required]);
        } else {
          contactForm.get('residenceFromDate').clearValidators();
          contactForm.get('residenceToDate').clearValidators();
        }
        contactForm.get('residenceFromDate').updateValueAndValidity();
        contactForm.get('residenceToDate').updateValueAndValidity();
      });
    }
    contactForm.get('address.permanent').valueChanges.subscribe((value: any) => {
      if (contactForm.get('address.mailing.sameAsPermanent').value) {
        this.copyAddress(contactForm.get('address.permanent'), contactForm.get('address.mailing'));
      }
      if (contactForm.get('address.courier.sameAsPermanent').value) {
        this.copyAddress(contactForm.get('address.permanent'), contactForm.get('address.courier'));
      }
    });
    contactForm.get('address.temporary').valueChanges.subscribe((value: any) => {
      if (this.isStageBetween(offerStage, 'applicationDocuments') && (value.street || value.orientationNumber || value.houseNumber || value.zipCode || value.city || value.district || value.region)) {
        contactForm.get('address.temporary.livingSince').setValidators([Validators.required]);
      } else {
        contactForm.get('address.temporary.livingSince').clearValidators();
      }
      contactForm.get('address.temporary.livingSince').updateValueAndValidity({emitEvent: false});
      if (contactForm.get('address.mailing.sameAsTemporary').value) {
        this.copyAddress(contactForm.get('address.temporary'), contactForm.get('address.mailing'));
      }
      if (contactForm.get('address.courier.sameAsTemporary').value) {
        this.copyAddress(contactForm.get('address.temporary'), contactForm.get('address.courier'));
      }
    });
    contactForm.get('address.mailing').valueChanges.subscribe((value: any) => {
      if (this.isStageBetween(offerStage, 'applicationDocuments') && (value.street || value.orientationNumber || value.houseNumber || value.zipCode || value.city || value.district || value.region)) {
        contactForm.get('address.mailing.livingSince').setValidators([Validators.required]);
      } else {
        contactForm.get('address.mailing.livingSince').clearValidators();
      }
      contactForm.get('address.mailing.livingSince').updateValueAndValidity({emitEvent: false});
      if (contactForm.get('address.courier.sameAsMailing').value) {
        this.copyAddress(contactForm.get('address.mailing'), contactForm.get('address.courier'));
      }
    });
    contactForm.get('address.mailing.sameAsPermanent').valueChanges.subscribe((value: any) => {
      if (value) {
        contactForm.get('address.mailing.sameAsTemporary').setValue(false);
        this.copyAddress(contactForm.get('address.permanent'), contactForm.get('address.mailing'));
      }
    });
    contactForm.get('address.mailing.sameAsTemporary').valueChanges.subscribe((value: any) => {
      if (value) {
        contactForm.get('address.mailing.sameAsPermanent').setValue(false);
        this.copyAddress(contactForm.get('address.temporary'), contactForm.get('address.mailing'));
      }
    });
    contactForm.get('address.courier.sameAsPermanent').valueChanges.subscribe((value: any) => {
      if (value) {
        contactForm.get('address.courier.sameAsTemporary').setValue(false);
        contactForm.get('address.courier.sameAsMailing').setValue(false);
        this.copyAddress(contactForm.get('address.permanent'), contactForm.get('address.courier'));
      }
    });
    contactForm.get('address.courier.sameAsTemporary').valueChanges.subscribe((value: any) => {
      if (value) {
        contactForm.get('address.courier.sameAsPermanent').setValue(false);
        contactForm.get('address.courier.sameAsMailing').setValue(false);
        this.copyAddress(contactForm.get('address.temporary'), contactForm.get('address.courier'));
      }
    });
    contactForm.get('address.courier.sameAsMailing').valueChanges.subscribe((value: any) => {
      if (value) {
        contactForm.get('address.courier.sameAsPermanent').setValue(false);
        contactForm.get('address.courier.sameAsTemporary').setValue(false);
        this.copyAddress(contactForm.get('address.mailing'), contactForm.get('address.courier'));
      }
    });
    contactForm.get('smoking.isSmoker').valueChanges.subscribe((value: any) => {
      if (!value) {
        contactForm.get('smoking.count').patchValue(null);
      }
    });
    contactForm.get('invalidity.isInvalid').valueChanges.subscribe((value: any) => {
      if (!value) {
        contactForm.get('invalidity.level').patchValue(null);
      }
    });
    if (pipelineUrl === 'life-insurance') {
      contactForm.get('firstName').clearValidators();
      contactForm.get('firstName').updateValueAndValidity();
      contactForm.get('lastName').clearValidators();
      contactForm.get('lastName').updateValueAndValidity();
      contactForm.get('email').setValidators([Validators.email]);
      contactForm.get('email').updateValueAndValidity();
      contactForm.get('birthDate').clearValidators();
      contactForm.get('birthDate').updateValueAndValidity();
    }
    if (contact) {
      contactForm.patchValue(contact);
      contactForm.get('nationality').patchValue(contact.nationality || 'CZE');
      for (const phone of (contact.phone || [])) {
        this.phones(contactForm).push(this.createPhoneForm(phone, offerStage, isLeadApplicant, aIndex));
      }
      for (const sport of (contact.sport || [])) {
        this.sport(contactForm).push(this.createSportForm(sport));
      }
      for (const bankAccount of (contact.bankAccounts?.length ? contact.bankAccounts : [null])) {
        this.bankAccounts(contactForm).push(this.createBankAccountForm(bankAccount, pipelineUrl, offerStage));
      }
      for (const identification of contact.identifications || []) {
        this.identifications(contactForm).push(this.createIdentificationForm(identification, pipelineUrl, offerStage));
      }
      if (contact.identifications?.length < 2 && !hasLegalRepresentative) {
        this.identifications(contactForm).push(this.createIdentificationForm(null, pipelineUrl));
        if (contact.identifications.length < 1) {
          this.identifications(contactForm).push(this.createIdentificationForm(null, pipelineUrl));
        }
      }
      for (const expenditure of contact.expenditures || []) {
        this.expenditures(contactForm).push(this.createExpenditureForm(expenditure, pipelineUrl, offerStage, null));
      }
      for (const property of contact.properties || []) {
        this.properties(contactForm).push(this.createPropertyForm(property));
      }
    }
    for (const income of (contact?.incomes?.length > 0 || hasLegalRepresentative ? contact.incomes : [null])) {
      this.incomes(contactForm).push(this.createIncomeForm(income, pipelineUrl, offerStage));
    }
    this.$updated.subscribe(updatedContact => {
      if (updatedContact._id === contact._id) {
        contactForm.get('emailStatus').patchValue(updatedContact.emailStatus);
        contactForm.get('emailVerified').patchValue(updatedContact.emailVerified);
        for (const _phone of updatedContact.phone) {
          const phone = this.phones(contactForm).at(this.phones(contactForm).controls.findIndex(p => p.get('_id').value === _phone._id));
          phone?.get('status').patchValue(_phone.status);
          phone?.get('isVerified').patchValue(_phone.isVerified);
        }
      }
    });
    contactForm.markAllAsTouched();
    return contactForm;
  }

  public emailVerified(verifiedControl): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      if (!verifiedControl.value) {
        return {emailVerified: false};
      }
      return null;
    };
  }

  // SPORTS
  public createSportForm(sport?) {
    const form = this.fb.group({
      _id: [],
      name: [null],
      level: [null]
    });
    if (sport) {
      form.patchValue(sport);
    }
    return form;
  }

  public addSport(control) {
    this.sport(control).push(this.createSportForm());
    control.markAllAsTouched();
    control.markAsDirty();
  }

  public removeSport(control, pIndex) {
    this.dialogsService.confirm('Smazat sport', 'Chcete opravdu smazat tento sport?').subscribe((confirm) => {
      if (confirm) {
        this.sport(control).removeAt(pIndex);
        control.markAsDirty();
      }
    });
  }

  public sport(control): FormArray {
    return control.get('sport') as FormArray;
  }
  // PHONES

  public createPhoneForm(phone?, offerStage?, isLeadApplicant?, aIndex?) {
    const phoneForm = this.fb.group({
      _id: [],
      countryCode: [null, Validators.required],
      number: [null, Validators.required],
      status: [1],
      isVerified: [],
      verifiedByClientAt: []
    });
    if (phone) {
      phoneForm.patchValue(phone);
    }
    return phoneForm;
  }

  public addPhone(control) {
    this.phones(control).push(this.createPhoneForm({status: 0}));
    control.markAllAsTouched();
    control.markAsDirty();
  }

  public removePhone(control, pIndex) {
    this.dialogsService.confirm('Smazat telefonní číslo', 'Chcete opravdu smazat toto telefonní číslo?').subscribe((confirm) => {
      if (confirm) {
        this.phones(control).removeAt(pIndex);
        control.markAsDirty();
      }
    });
  }

  public async verifyPhone(contact, phoneIndex) {
    const patchedContact = await this.patch(contact.get('_id').value, {['phone.' + phoneIndex + '.isVerified']: true, ['phone.' + phoneIndex + '.status']: 1});
    (contact.get('phone') as FormArray).at(phoneIndex).patchValue(patchedContact.phone[phoneIndex]);
  }

  public phones(control): FormArray {
    return control.get('phone') as FormArray;
  }

  // BANK ACCOUNTS

  public createBankAccountForm(bankAccount?, pipelineUrl?, offerStage?, isLeadApplicant?, aIndex?) {
    const bankAccountForm = this.fb.group({
      number: [null, [Validators.pattern('^([0-9]{1,6}-)?[0-9]{2,10}$')]],
      bankCode: [null, [this.mainService.requireSelectionValidator('bank')]],
      isHomeBank: [false]
    });
    if (pipelineUrl === 'mortgage') {
      bankAccountForm.get('bankCode').setValidators([Validators.required, this.mainService.requireSelectionValidator('bank')]);
      bankAccountForm.get('bankCode').updateValueAndValidity();
      if (this.isStageBetween(offerStage, 'applicationDocuments')) {
        bankAccountForm.get('number').setValidators([Validators.required, Validators.pattern('^([0-9]{1,6}-)?[0-9]{2,10}$')]);
        bankAccountForm.get('number').updateValueAndValidity();
        bankAccountForm.get('bankCode').setValidators([Validators.required, this.mainService.requireSelectionValidator('bank')]);
        bankAccountForm.get('bankCode').updateValueAndValidity();
      }
    }
    if (bankAccount) {
      bankAccountForm.patchValue(bankAccount);
    }
    return bankAccountForm;
  }

  public addBankAccount(control, pipelineUrl?) {
    this.bankAccounts(control).push(this.createBankAccountForm(null, pipelineUrl));
    for (const bankAccount of this.bankAccounts(control).controls) {
      bankAccount.updateValueAndValidity();
    }
    control.markAllAsTouched();
    control.markAsDirty();
  }

  public removeBankAccount(control, baIndex) {
    this.dialogsService.confirm('Smazat bankovní účet', 'Chcete opravdu smazat tento bankovní účet?').subscribe((confirm) => {
      if (confirm) {
        this.bankAccounts(control).removeAt(baIndex);
        control.markAsDirty();
      }
    });
  }

  public bankAccounts(control) {
    return control.get('bankAccounts') as FormArray;
  }

  public getHomeBank(control) {
    const bankAccounts = control.get('bankAccounts').getRawValue();
    const homeBank = bankAccounts ? bankAccounts.find(ba => ba.isHomeBank) : null;
    return homeBank ? homeBank.bankCode : null;
  }

  // IDENTIFICATIONS

  public createIdentificationForm(identification?, pipelineUrl?, offerStage?, isLeadApplicant?, aIndex?) {
    const identificationForm = this.fb.group({
      type: [null, this.mainService.requireSelectionValidator('identificationType')],
      number:  [null],
      dateOfIssue: [null],
      validTo: [null],
      country: ['CZE', this.mainService.requireSelectionValidator('country')],
      issuedBy: [null]
    });
    if (pipelineUrl === 'mortgage') {
      if (this.isStageBetween(offerStage, 'applicationFilling')) {
        identificationForm.get('type').setValidators([Validators.required, this.mainService.requireSelectionValidator('identificationType')]);
        identificationForm.get('number').setValidators([Validators.required]);
        identificationForm.get('dateOfIssue').setValidators([Validators.required]);
        identificationForm.get('validTo').setValidators([Validators.required]);
        identificationForm.get('issuedBy').setValidators([Validators.required]);
        identificationForm.get('country').setValidators([Validators.required, this.mainService.requireSelectionValidator('country')]);
        identificationForm.get('type').updateValueAndValidity();
        identificationForm.get('number').updateValueAndValidity();
        identificationForm.get('dateOfIssue').updateValueAndValidity();
        identificationForm.get('validTo').updateValueAndValidity();
        identificationForm.get('issuedBy').updateValueAndValidity();
        identificationForm.get('country').updateValueAndValidity();
      }
    }
    identificationForm.get('dateOfIssue').valueChanges.subscribe((value) => {
      if (identificationForm.get('type').value === 'identityCard') {
        const issuedAt = new Date(value);
        issuedAt.setFullYear(issuedAt.getFullYear() + 10);
        identificationForm.get('validTo').patchValue(issuedAt);
      }
    });
    if (identification) {
      identificationForm.patchValue(identification);
    }
    return identificationForm;
  }

  public addIdentification(control, pipelineUrl?) {
    this.identifications(control).push(this.createIdentificationForm(null, pipelineUrl));
    for (const identification of this.identifications(control).controls) {
      identification.updateValueAndValidity();
    }
    control.markAllAsTouched();
    control.markAsDirty();
  }

  public removeIdentification(control, idIndex) {
    this.dialogsService.confirm('Smazat identifikaci', 'Chcete opravdu smazat tuto identifikaci?').subscribe((confirm) => {
      if (confirm) {
        this.identifications(control).removeAt(idIndex);
        control.markAsDirty();
      }
    });
  }

  public identifications(control) {
    return control.get('identifications') as FormArray;
  }

  // INCOMES

  public createIncomeForm(income?, pipelineUrl?, offerStage?, isLeadApplicant?, aIndex?) {
    const incomeForm = this.fb.group({
      type: [null],
      amount: [null],
      currency: ['CZK', [this.mainService.requireSelectionValidator('currency')]],
      location: ['local'],
      locationCountry: [null, [this.mainService.requireSelectionValidator('country')]],
      locationAccount: [null],
      costAccounting: [null],
      professionType: [null],
      stoppedInLastTwoYears: [false],
      employedAtOwnCompany: [false],
      employmentExtendedCount: [0],
      shareInTheCompany: [null],
      flatRate: [null],
      fromDate: [null],
      untilDate: [null],
      contractTermPeriod: [null],
      isTrialPeriod: [false],
      isNoticePeriod: [false],
      isPaidToAccount: [false],
      isSubsequent: [false],
      taxReturn: this.fb.group({
        period: [null],
        row31: [null],
        row36: [null],
        row37: [null],
        row38: [null],
        row39: [null],
        row40: [null],
        row42: [null],
        row46: [null],
        row47: [null],
        row48: [null],
        row49: [null],
        row50: [null],
        row51: [null],
        row52: [null],
        row52a: [null],
        row53: [null],
        row64: [null],
        row65a: [null],
        row65b: [null],
        row66: [null],
        row67: [null],
        row68: [null],
        row69: [null],
        row69a: [null],
        row69b: [null],
        row72: [null],
        row74: [null],
        row101: [null],
        row102: [null],
        row104: [null],
        row106: [null],
        row107: [null],
        row108: [null],
        row109: [null],
        row110: [null],
        row113: [null],
        row201: [null],
        row202: [null],
        totalDepricationApplied: [null]
      }),
      source: this.fb.group({
        createdAt: [null],
        name: [null],
        idNumber: [null],
        vatNumber: [null],
        phone: [null],
        email: [null, [Validators.email]],
        naceCode: [null, this.mainService.requireSelectionValidator('nace')],
        sector: [null, this.mainService.requireSelectionValidator('sector')],
        personalNumber: [null],
        suspendedActivity: [false],
        address: this.fb.group({
          street: [null],
          orientationNumber: [null],
          houseNumber: [null],
          zipCode: [null],
          city: [null],
          district: [null],
          region: [null], // this.mainService.requireSelectionValidator('region')],
          country: ['CZE', [this.mainService.requireSelectionValidator('country')]],
        })
      })
    });
    if (['mortgage', 'life-insurance'].includes(pipelineUrl)) {
      incomeForm.get('currency').setValidators([Validators.required, this.mainService.requireSelectionValidator('currency')]);
      incomeForm.get('currency').updateValueAndValidity();
      if (pipelineUrl === 'mortgage') {
        incomeForm.get('type').setValidators([Validators.required]);
        incomeForm.get('type').updateValueAndValidity();
      }
      incomeForm.get('contractTermPeriod').valueChanges.subscribe((value: any) => {
        if (['rentalIncomeOutsideTaxReturn', 'futureRentalRevenue', 'rentalIncome'].includes(incomeForm.get('type').value)) {
          if (value === 'fixedTerm') {
            incomeForm.get('untilDate').setValidators([Validators.required]);
          } else {
            incomeForm.get('untilDate').clearValidators();
          }
          incomeForm.get('untilDate').updateValueAndValidity();
        }
      });
      if (pipelineUrl === 'mortgage') {
        incomeForm.get('location').setValidators([Validators.required]);
        incomeForm.get('location').updateValueAndValidity();
      }
      incomeForm.get('type').valueChanges.subscribe((value: any) => {
        if (pipelineUrl === 'mortgage') {
          if (['fixedTermEmployment', 'employmentAgreement', 'actionAgreement'].includes(value)) {
            incomeForm.get('fromDate').setValidators([Validators.required]);
            incomeForm.get('untilDate').setValidators([Validators.required]);
            incomeForm.get('employmentExtendedCount').setValidators([Validators.required]);
          } else if (['rentalIncome', 'futureRentalRevenue', 'rentalIncomeOutsideTaxReturn'].includes(value)) {
            incomeForm.get('fromDate').setValidators([Validators.required]);
            if (incomeForm.get('contractTermPeriod').value === 'fixedTerm') {
              incomeForm.get('untilDate').setValidators([Validators.required]);
            } else {
              incomeForm.get('untilDate').clearValidators();
            }
            incomeForm.get('employmentExtendedCount').clearValidators();
          } else if (['indefinitePeriodEmployment', 'indefiniteActionAgreement', 'indefiniteEmploymentAgreement', 'selfEmployment', 'parentalContribution', 'maternityContribution'].includes(value)) {
            incomeForm.get('fromDate').setValidators([Validators.required]);
            incomeForm.get('untilDate').clearValidators();
            incomeForm.get('employmentExtendedCount').clearValidators();
          } else if (value === 'noIncome') {
            incomeForm.patchValue({amount: 0});
            incomeForm.get('fromDate').clearValidators();
            incomeForm.get('untilDate').clearValidators();
            incomeForm.get('employmentExtendedCount').clearValidators();
          } else {
            incomeForm.get('fromDate').clearValidators();
            incomeForm.get('untilDate').clearValidators();
            incomeForm.get('employmentExtendedCount').clearValidators();
          }
          incomeForm.get('fromDate').updateValueAndValidity();
          incomeForm.get('untilDate').updateValueAndValidity();
          incomeForm.get('employmentExtendedCount').updateValueAndValidity();
          if (['fixedTermEmployment', 'indefinitePeriodEmployment', 'employmentAgreement', 'actionAgreement'].includes(value)) {
            incomeForm.get('employedAtOwnCompany').setValidators([Validators.required]);
          } else {
            incomeForm.get('employedAtOwnCompany').clearValidators();
          }
          incomeForm.get('employedAtOwnCompany').updateValueAndValidity();
          if (value === 'selfEmployment') {
            incomeForm.get('costAccounting').setValidators([Validators.required]);
          } else {
            incomeForm.get('costAccounting').clearValidators();
          }
          incomeForm.get('costAccounting').updateValueAndValidity();
          if (value === 'diet') {
            incomeForm.get('professionType').setValidators([Validators.required]);
          } else {
            incomeForm.get('professionType').clearValidators();
          }
          incomeForm.get('professionType').updateValueAndValidity();
          if (value === 'turnoverIncome' || this.isStageBetween(offerStage, 'applicationDocuments')) {
            incomeForm.get('source.name').setValidators([Validators.required]);
            incomeForm.get('source.idNumber').setValidators([Validators.required]);
            incomeForm.get('source.createdAt').setValidators([Validators.required]);
          } else {
            incomeForm.get('source.name').clearValidators();
            incomeForm.get('source.idNumber').clearValidators();
            incomeForm.get('source.createdAt').clearValidators();
          }
          incomeForm.get('source.name').updateValueAndValidity();
          incomeForm.get('source.idNumber').updateValueAndValidity();
          incomeForm.get('source.createdAt').updateValueAndValidity();
          if (value === 'turnoverIncome') {
            incomeForm.get('source.createdAt').setValidators([Validators.required]);
            incomeForm.get('shareInTheCompany').setValidators([Validators.required]);
          } else {
            incomeForm.get('source.createdAt').clearValidators();
            incomeForm.get('shareInTheCompany').clearValidators();
          }
          incomeForm.get('source.createdAt').updateValueAndValidity();
          incomeForm.get('shareInTheCompany').updateValueAndValidity();
          this.taxReturnsUpdate(incomeForm);
        }
        if (['rentalIncomeOutsideTaxReturn', 'futureRentalRevenue', 'rentalIncome'].includes(value)) {
          incomeForm.get('amount').setValidators([Validators.required]);
          incomeForm.get('contractTermPeriod').setValidators([Validators.required]);
        } else {
          incomeForm.get('amount').clearValidators();
          incomeForm.get('contractTermPeriod').clearValidators();
        }
        if (['maternityContribution', 'parentalContribution', 'rentalIncome', 'rentalIncomeOutsideTaxReturn', 'futureRentalRevenue'].includes(value)) {
          incomeForm.get('source.idNumber').clearValidators();
          incomeForm.get('source.idNumber').updateValueAndValidity();
        }
        incomeForm.get('amount').updateValueAndValidity();
        incomeForm.get('contractTermPeriod').updateValueAndValidity();

        if ((this.isStageBetween(offerStage, 'applicationDocuments')) && (value !== 'noIncome')) {
          incomeForm.get('source.address.street').setValidators([Validators.required]);
          incomeForm.get('source.address.houseNumber').setValidators([Validators.required]);
          incomeForm.get('source.address.zipCode').setValidators([Validators.required]);
          incomeForm.get('source.address.city').setValidators([Validators.required]);
          incomeForm.get('source.address.country').setValidators([Validators.required, this.mainService.requireSelectionValidator('country')]);
          incomeForm.get('source.name').setValidators([Validators.required]);
          incomeForm.get('source.idNumber').setValidators([Validators.required]);
        } else {
          incomeForm.get('source.address.street').clearValidators();
          incomeForm.get('source.address.houseNumber').clearValidators();
          incomeForm.get('source.address.zipCode').clearValidators();
          incomeForm.get('source.address.city').clearValidators();
          incomeForm.get('source.address.country').clearValidators();
          incomeForm.get('source.name').clearValidators();
          incomeForm.get('source.idNumber').clearValidators();
        }
        incomeForm.get('source.address.street').updateValueAndValidity();
        incomeForm.get('source.address.houseNumber').updateValueAndValidity();
        incomeForm.get('source.address.zipCode').updateValueAndValidity();
        incomeForm.get('source.address.city').updateValueAndValidity();
        incomeForm.get('source.address.country').updateValueAndValidity();
        incomeForm.get('source.name').updateValueAndValidity();
        incomeForm.get('source.idNumber').updateValueAndValidity();
      });
      if (pipelineUrl === 'mortgage') {
        incomeForm.get('flatRate').valueChanges.subscribe((value: any) => {
          this.taxReturnsValues(incomeForm);
        });
        incomeForm.get('taxReturn.row101').valueChanges.subscribe((value: any) => {
          this.taxReturnsValues(incomeForm);
        });
        incomeForm.get('taxReturn.row102').valueChanges.subscribe((value: any) => {
          this.taxReturnsValues(incomeForm);
        });
        incomeForm.get('location').valueChanges.subscribe((value: any) => {
          if (['expat', 'pendler'].includes(value)) {
            incomeForm.get('locationCountry').setValidators([Validators.required, this.mainService.requireSelectionValidator('country')]);
            incomeForm.get('locationAccount').setValidators([Validators.required]);
          } else {
            incomeForm.get('locationCountry').clearValidators();
            incomeForm.get('locationCountry').setValidators([this.mainService.requireSelectionValidator('country')]);
            incomeForm.get('locationAccount').clearValidators();
          }
          incomeForm.get('locationCountry').updateValueAndValidity();
          incomeForm.get('locationAccount').updateValueAndValidity();
        });
      }
    }
    if (income) {
      incomeForm.patchValue(income);
      incomeForm.get('shareInTheCompany').patchValue(this.mainService.maskPercent(incomeForm.get('shareInTheCompany').value));
      incomeForm.get('contractTermPeriod').patchValue(income.contractTermPeriod === true ? 'fixedTerm' : income.contractTermPeriod === false ? 'indefinite' : income.contractTermPeriod);
    }
    return incomeForm;
  }

  private taxReturnsUpdate(incomeForm) {
    incomeForm.get('taxReturn.row37').clearValidators();
    incomeForm.get('taxReturn.row74').clearValidators();
    incomeForm.get('taxReturn.row101').clearValidators();
    incomeForm.get('taxReturn.row102').clearValidators();
    incomeForm.get('taxReturn.row113').clearValidators();
    if (incomeForm.get('type').value === 'selfEmployment') {
      if (incomeForm.get('costAccounting').value === 'flatRate') {
        incomeForm.get('taxReturn.row37').setValidators([Validators.required]);
        incomeForm.get('taxReturn.row74').setValidators([Validators.required]);
        incomeForm.get('taxReturn.row101').setValidators([Validators.required]);
        incomeForm.get('taxReturn.row113').setValidators([Validators.required]);
      }
      if (incomeForm.get('costAccounting').value === 'taxRecords') {
        incomeForm.get('taxReturn.row37').setValidators([Validators.required]);
        incomeForm.get('taxReturn.row74').setValidators([Validators.required]);
        incomeForm.get('taxReturn.row101').setValidators([Validators.required]);
        incomeForm.get('taxReturn.row102').setValidators([Validators.required]);
        incomeForm.get('taxReturn.row113').setValidators([Validators.required]);
      }
      this.taxReturnsValues(incomeForm);
    }
    incomeForm.get('taxReturn.row37').updateValueAndValidity();
    incomeForm.get('taxReturn.row74').updateValueAndValidity();
    incomeForm.get('taxReturn.row101').updateValueAndValidity();
    incomeForm.get('taxReturn.row102').updateValueAndValidity();
    incomeForm.get('taxReturn.row113').updateValueAndValidity();
    incomeForm.markAllAsTouched();
  }

  private taxReturnsValues(incomeForm) {
    if (incomeForm.get('costAccounting').value === 'flatRate' && incomeForm.get('taxReturn.row101').value && incomeForm.get('flatRate').value) {
      incomeForm.get('taxReturn.row102').setValue(this.mainService.maskCurrency(Math.round(this.mainService.unmaskCurrency(incomeForm.get('taxReturn.row101').value) * incomeForm.get('flatRate').value)), {emitEvent: false});
    }
    if (incomeForm.get('taxReturn.row101').value && incomeForm.get('taxReturn.row102').value) {
      incomeForm.get('taxReturn.row104').setValue(this.mainService.maskCurrency(Math.round(this.mainService.unmaskCurrency(incomeForm.get('taxReturn.row101').value) - this.mainService.unmaskCurrency(incomeForm.get('taxReturn.row102').value))), {emitEvent: false});
    }
  }

  public addIncome(control, pipelineUrl?) {
    this.incomes(control).push(this.createIncomeForm(null, pipelineUrl));
    control.markAllAsTouched();
    control.markAsDirty();
  }

  public removeIncome(control, inIndex) {
    this.dialogsService.confirm('Smazat příjem', 'Chcete opravdu smazat tento příjem?').subscribe((confirm) => {
      if (confirm) {
        this.incomes(control).removeAt(inIndex);
        control.markAsDirty();
      }
    });
  }

  public incomes(control) {
    return control.get('incomes') as FormArray;
  }

  // EXPENDITURES

  public createExpenditureForm(expenditure?, pipelineUrl?, offerStage?, isLeadApplicant?) {
    const expenditureForm = this.fb.group({
      _id: [null],
      type: [null, [this.mainService.requireSelectionValidator('expenditureType')]],
      payment: [null],
      currency: ['CZK', [this.mainService.requireSelectionValidator('currency')]],
      repayment: [null, [this.mainService.requireSelectionValidator('expenditureRepayment')]],
      balance: [null],
      limit: [null],
      interestRate: [null],
      fixation: [null],
      fixationEndAt: [null],
      payoffDate: [null],
      payoffYears: [null],
      modelationsId: [[]],
      source: this.fb.group({
        type: [null],
        name: [null],
        idNumber: [null],
        vatNumber: [null],
        phone: [null],
        email: [null, [Validators.email]],
        naceCode: [null, [this.mainService.requireSelectionValidator('nace')]],
        sector: [null, [this.mainService.requireSelectionValidator('sector')]],
        personalNumber: [null],
        suspendedActivity: [false],
        address: this.fb.group({
          street: [null],
          orientationNumber: [null],
          houseNumber: [null],
          zipCode: [null],
          city: [null],
          district: [null],
          region: [null], // this.mainService.requireSelectionValidator('region')],
          country: ['CZE', [this.mainService.requireSelectionValidator('country')]],
        })
      })
    });
    if (['mortgage', 'life-insurance'].includes(pipelineUrl)) {
      expenditureForm.get('type').setValidators([Validators.required]);
      expenditureForm.get('type').updateValueAndValidity();
      expenditureForm.get('currency').setValidators([Validators.required, this.mainService.requireSelectionValidator('currency')]);
      expenditureForm.get('currency').updateValueAndValidity();
      if (pipelineUrl === 'mortgage') {
        expenditureForm.get('source.type').setValidators([Validators.required]);
        expenditureForm.get('source.type').updateValueAndValidity();
        expenditureForm.get('source.name').setValidators([Validators.required]);
        expenditureForm.get('source.name').updateValueAndValidity();
      }
      expenditureForm.get('type').valueChanges.subscribe((value: any) => {
        expenditureForm.get('payment').clearValidators();
        expenditureForm.get('balance').clearValidators();
        expenditureForm.get('limit').clearValidators();
        expenditureForm.get('repayment').clearValidators();
        expenditureForm.get('fixation').clearValidators();
        expenditureForm.get('interestRate').clearValidators();
        if (pipelineUrl === 'mortgage' && value !== 'alimony') {
          expenditureForm.get('repayment').setValidators([Validators.required]);
        }
        if (value === 'overdraft' || value === 'creditCard' || value === 'other') {
          expenditureForm.get('limit').setValidators([Validators.required, Validators.min(1)]);
        }
        if (value !== 'overdraft' && value !== 'creditCard' && value !== 'alimony') {
          expenditureForm.get('balance').setValidators([Validators.required, Validators.min(1)]);
        }
        if (value !== 'overdraft' && value !== 'creditCard') {
          expenditureForm.get('payment').setValidators([Validators.required, Validators.min(1)]);
        }
        if (pipelineUrl === 'mortgage') {
          if (['mortgage'].includes(value)) {
            expenditureForm.get('fixation').setValidators([Validators.required, Validators.min(1)]);
            // HP-2136 expenditureForm.get('fixationEndAt').setValidators([Validators.required, Validators.min(1)]);
            expenditureForm.get('interestRate').setValidators([Validators.required]);
          }
        }
        expenditureForm.get('payment').setValue(0);
        expenditureForm.get('balance').setValue(0);
        expenditureForm.get('limit').setValue(0);
        expenditureForm.get('payment').updateValueAndValidity();
        expenditureForm.get('balance').updateValueAndValidity();
        expenditureForm.get('limit').updateValueAndValidity();
        expenditureForm.get('repayment').updateValueAndValidity();
        expenditureForm.get('fixation').updateValueAndValidity();
        // HP-2136 expenditureForm.get('fixationEndAt').updateValueAndValidity();
        expenditureForm.get('interestRate').updateValueAndValidity();
      });
      if (pipelineUrl === 'mortgage') {
        expenditureForm.get('source.type').valueChanges.subscribe((value: any) => {
          expenditureForm.get('source.name').setValue(null);
          if (value === 'bank') {
            expenditureForm.get('source.name').setValidators([Validators.required, this.mainService.requireSelectionValidator('bank')]);
          } else {
            expenditureForm.get('source.name').setValidators([Validators.required]);
          }
          expenditureForm.get('source.name').updateValueAndValidity();
        });
      }
      expenditureForm.get('repayment').valueChanges.subscribe((value: any) => {
        if (value && value !== 'mortgage') {
          expenditureForm.get('modelationsId').patchValue([]);
        }
      });
      expenditureForm.get('payoffYears').valueChanges.subscribe((value: any) => {
        if (value) {
          const payoffDate = new Date();
          payoffDate.setFullYear(payoffDate.getFullYear() + parseInt(value, 10));
          expenditureForm.get('payoffDate').patchValue(payoffDate);
        }
      });
    }
    if (expenditure) {
      expenditureForm.patchValue(expenditure);
      expenditureForm.get('interestRate')?.patchValue(this.mainService.maskPercent(expenditure.interestRate || 0));
      const payoffYears = Math.round((new Date(expenditure.payoffDate).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24 * 365));
      expenditureForm.get('payoffYears')?.patchValue(payoffYears || 0, {emitEvent: false});
    }
    return expenditureForm;
  }

  public async addExpenditure(control, pipelineUrl?) {
    const contact = await this.patch(control.get('_id').value, {$push: {expenditures: {}}});
    for (const expenditure of contact.expenditures) {
      if (!this.expenditures(control).controls.find(ec => ec.get('_id').value === expenditure._id)) {
        this.expenditures(control).push(this.createExpenditureForm(expenditure, pipelineUrl));
      }
    }
    control.markAllAsTouched();
  }

  public removeExpenditure(control, exIndex) {
    this.dialogsService.confirm('Smazat výdaj', 'Chcete opravdu smazat tento výdaj?').subscribe((confirm) => {
      if (confirm) {
        this.expenditures(control).removeAt(exIndex);
        control.markAsDirty();
      }
    });
  }

  public expenditures(control) {
    return control.get('expenditures') as FormArray;
  }

  public createPropertyForm(property) {
    const propertyForm = this.fb.group({
      _id: [],
      isOwner: [false]
    });
    if (property) {
      propertyForm.patchValue(property);
    }
    return propertyForm;
  }

  public properties(control): FormArray {
    return control.get('properties') as FormArray;
  }

  public unmask(contact) {
    delete contact.numberId;
    delete contact.emailStatus;
    delete contact.emailVerified;
    delete contact._properties;
    delete contact._households;
    contact.reserves.incapacity = this.mainService.unmaskCurrency(contact.reserves.incapacity);
    contact.reserves.death = this.mainService.unmaskCurrency(contact.reserves.death);
    for (const income of contact.incomes) {
      income.amount = this.mainService.unmaskCurrency(income.amount);
      income.shareInTheCompany = this.mainService.unmaskPercent(income.shareInTheCompany);
      for (const taxReturnRow in income.taxReturn) {
        if (income.taxReturn[taxReturnRow] !== undefined) {
          income.taxReturn[taxReturnRow] = this.mainService.unmaskCurrency(income.taxReturn[taxReturnRow]);
        }
      }
    }
    for (const expenditure of contact.expenditures) {
      delete expenditure.payoffYears;
      expenditure.payment = this.mainService.unmaskCurrency(expenditure.payment);
      expenditure.balance = this.mainService.unmaskCurrency(expenditure.balance);
      expenditure.limit = this.mainService.unmaskCurrency(expenditure.limit);
      expenditure.interestRate = this.mainService.unmaskPercent(expenditure.interestRate);
    }
    for (const phone of contact.phone) {
      if (!phone._id) {
        delete phone._id;
      }
    }
    return contact;
  }

  public bankAccountsValidator(): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      let valid = true;
      if (!control.value.length) {
        valid = false;
      }
      if (!control.value.some(c => c.isHomeBank)) {
        valid = false;
        // @ts-ignore
        for (const bankAccount of control.controls) {
          bankAccount.get('isHomeBank').setErrors([{homeBank: true}]);
        }
      } else {
        // @ts-ignore
        for (const bankAccount of control.controls) {
          bankAccount.get('isHomeBank').setErrors(null);
        }
      }
      return valid ? null : {bankAccounts: {value: false}};
    };
  }

  public copyAddress(fromControl, toControl) {
    toControl.get('street').setValue(fromControl.get('street').value);
    toControl.get('orientationNumber').setValue(fromControl.get('orientationNumber').value);
    toControl.get('houseNumber').setValue(fromControl.get('houseNumber').value);
    toControl.get('zipCode').setValue(fromControl.get('zipCode').value);
    toControl.get('district').setValue(fromControl.get('district').value);
    toControl.get('city').setValue(fromControl.get('city').value);
    toControl.get('region').setValue(fromControl.get('region').value);
    toControl.get('country').setValue(fromControl.get('country').value);
    if (fromControl.get('livingSince') && toControl.get('livingSince')) {
      toControl.get('livingSince').setValue(fromControl.get('livingSince').value);
    }
  }

  private copyAddresses(contactForm) {
    if (contactForm.get('address.mailing.sameAsPermanent').value) {
      this.copyAddress(contactForm.get('address.permanent'), contactForm.get('address.mailing'));
    }
    if (contactForm.get('address.mailing.sameAsTemporary').value) {
      this.copyAddress(contactForm.get('address.temporary'), contactForm.get('address.mailing'));
    }
    if (contactForm.get('address.courier.sameAsPermanent').value) {
      this.copyAddress(contactForm.get('address.permanent'), contactForm.get('address.courier'));
    }
    if (contactForm.get('address.courier.sameAsTemporary').value) {
      this.copyAddress(contactForm.get('address.temporary'), contactForm.get('address.courier'));
    }
    if (contactForm.get('address.courier.sameAsMailing').value) {
      this.copyAddress(contactForm.get('address.mailing'), contactForm.get('address.courier'));
    }
  }

  public isStageBetween(stage, from, to = null) {
    const index = stage ? this.settingsService.settings.stageOrder.indexOf(stage) : 0;
    const fromIndex = from ? this.settingsService.settings.stageOrder.indexOf(from) : 0;
    const toIndex = to ? this.settingsService.settings.stageOrder.indexOf(to) : 1000;
    return index >= fromIndex && index <= toIndex;
  }

  public ageFromDate(dob) {
    if (!dob) {
      return 0;
    }
    if (isNaN((new Date(dob)).getTime())) {
      return 0;
    }
    try {
      const diffMs = Date.now() - new Date(dob).getTime();
      const ageDt = new Date(diffMs);
      return Math.abs(ageDt.getUTCFullYear() - 1970);
    } catch (e) {
      return 0;
    }
  }

  public contactOwner(contact) {
    return contact.owners?.find(o => o.type === 'contact');
  }

  public async sendMagicLink(applicant) {
    this.dialogsService.confirm('Odeslat přihlašovací odkaz?', 'Opravdu chcete odeslat přihlašovací odkaz na uloženou adresu? POZOR! Pokud chcete poslat odkaz na novou adresu, musíte ji nejdříve uložit.').subscribe((confirmed) => {
      if (confirmed) {
        this.patch(applicant.get('_id').value, {magicLink: true});
        this.snackbarService.showSuccess('Odkaz byl klientovi odeslán.');
      }
    });
  }

  public async changeOwner(contacts, stages, pipelineUrl?: string) {
    this.mainService.showLoading();
    let users = this.usersService.items.filter(u => u.role === 'specialist');
    if (pipelineUrl) {
      users = users.filter(u => u.cases?.[pipelineUrl]);
    }
    const query: any = {
      $or: [
        {duplicityToContactId: {$in: contacts.map(c => c._id)}}
      ],
      $limit: 100000
    };
    const duplicityContactsId = contacts.map(c => c.duplicityToContactId).filter(c => c);
    if (duplicityContactsId) {
      query.$or.push({
        _id: {$in: duplicityContactsId}
      });
    }
    const duplicateContacts = (await this.find({query}))?.data || [];
    contacts = [...contacts, ...duplicateContacts];
    const contactsId = contacts.map(o => o._id);
    const deals = (await Promise.all([
      this.carInsurancesService.find({query: {'participants.0.contactId': {$in: contactsId}}}),
      this.lifeInsurancesService.find({query: {'participants.0.contactId': {$in: contactsId}}}),
      this.realEstateInsurancesService.find({query: {'participants.0.contactId': {$in: contactsId}}}),
      // this.mortgagesService.find({query: {'participants.0.contactId': {$in: contactsId}}})
    ])).map(d => d.data).flat();
    this.dialogsService.owner(deals, users, stages, contacts).subscribe(async (result) => {
      if (result) {
        const {ownerId, patchContactsId} = result;
        if (ownerId) {
          await Promise.all(patchContactsId.map(contactId => this.patch(contactId, {changePipelineOwner: ownerId})));
          this.snackbarService.showSuccess('Změna vlastníka proběhla úspěšně.');
        }
      }
    });
    this.mainService.hideLoading();
  }

}
