import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from '@app/modules/core.module';
import { PriorityButtonComponent } from '@app/components/priority-button/priority-button.component';

@NgModule({
  declarations: [PriorityButtonComponent],
  imports: [
    CommonModule,
    CoreModule,
  ],
  exports: [PriorityButtonComponent],
})
export class PriorityButtonModule {}
