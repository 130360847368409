import { NgModule } from '@angular/core';
import { PinchZoomModule } from '@app/components/pinch-zoom/pinch-zoom.module';
import { CoreModule } from '@app/modules/core.module';
import { FileListComponent } from '@app/components/file-list/file-list.component';
import { FilePreviewModule } from '@app/components/file-preview/file-preview.module';
import { FileListIsPlatformPipe } from './file-list-is-platform.pipe';
import { FileUploadModule } from '@app/components/file-upload/file-upload.module';

@NgModule({
  declarations: [FileListComponent, FileListIsPlatformPipe],
  imports: [PinchZoomModule, CoreModule, FilePreviewModule, FileUploadModule],
  exports: [FileListComponent],
})
export class FileListModule {}
