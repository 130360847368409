<h2 mat-dialog-title>Zaslané video / videohovor</h2>
<mat-dialog-content>
  <div class="form-row">
    <mat-form-field>
      <mat-select [(ngModel)]="activityType">
        <mat-option value="video-sent">Zaslané video</mat-option>
        <mat-option value="video-call">Videohovor</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button type="button" mat-button color="warn" (click)="dialogRef.close(null)">Zrušit</button>
  <button type="button" mat-raised-button color="primary" (click)="dialogRef.close(activityType)" [disabled]="!activityType">OK</button>
</mat-dialog-actions>
