<div class="card-title">
  <span>Výpočty</span>
  <div>
    <button mat-raised-button color="accent" title="Nový výpočet"><span>Nový výpočet</span><mat-icon>add</mat-icon></button>
  </div>
</div>
<div class="card card-list">
  <table class="list">
    <thead>
    <tr class="header">
      <th class="dynamic sorting"><span><span>Název</span><mat-icon></mat-icon></span></th>
      <th class="dynamic sorting"><span><span>Poskytovatel</span><mat-icon></mat-icon></span></th>
      <th class="fitcontent sorting"><span><span>Typ</span><mat-icon></mat-icon></span></th>
      <th class="fitcontent"></th>
    </tr>
    </thead>
    <tbody>
    <tr>
      <td class="filter-padding"><span class="overflow-container"><span class="overflow-elipsis"><a routerLink="/offers/2">Poplatek za zřízení účtu</a></span></span></td>
      <td class="filter-padding"><span class="overflow-container"><span class="overflow-elipsis">Wüstenrot hypoteční banka a.s.</span></span></td>
      <td><span class="nowrap-container">poplatek</span></td>
      <td><button mat-icon-button><mat-icon>more_vert</mat-icon></button></td>
    </tr>
    </tbody>
  </table>
  <div class="list-load-more">
    <button mat-button color="primary">Načíst další</button>
  </div>
</div>
