import {Component, OnInit} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {TitleService} from '@app/services/title.service';
import {DealsService} from '@app/services/deals.service';
import {SettingsService} from '@app/services/settings.service';
import {MainService} from '@app/services/main.service';
import {DialogsService} from '@app/services/dialogs.service';
import {SnackbarService} from '@app/services/snackbar.service';
import {UsersService} from '@app/services/users.service';
import {OffersService} from '@app/services/offers.service';
import {debounceTime} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';
import {DialNumberParam } from '@app/models/calls.model';
import {CallsService} from '@app/services/calls.service';
import {AffiliatesService} from '@app/services/affiliates.service';
import {BankingService} from '@app/services/banking.service';

@Component({
  selector: 'app-deals',
  templateUrl: './deals.component.html',
  styleUrls: ['./deals.component.scss']
})

export class DealsComponent implements OnInit {

  public stageKeys: string[] = [];
  public deals: Observable<any[]>;
  public filters: any = {
    createdAtFrom: null,
    createdAtTo: null,
    search: '',
    stage: [],
    postponeReason: [],
    postponedUntilFrom: null,
    postponedUntilTo: null,
    signedAtFrom: null,
    signedAtTo: null,
    amountMin: '',
    amountMax: '',
    ownerId: [],
    affiliateId: []
  };
  public stages: any = [];
  public owners: any = [];
  public affiliates: any = [];
  private searchChanged$ = new Subject<string>();
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private titleService: TitleService,
    public dealsService: DealsService,
    public settingsService: SettingsService,
    public mainService: MainService,
    private dialogsService: DialogsService,
    private snackbarService: SnackbarService,
    public usersService: UsersService,
    public offersService: OffersService,
    public callsService: CallsService,
    public affiliatesService: AffiliatesService,
    public bankingService: BankingService
  ) {
    this.titleService.setTitle('Hypotéky');
    this.searchChanged$
      .pipe(debounceTime(500)) // Adjust the debounce time as needed
      .subscribe(value => {
        // Handle the debounced value change
        this.filter();
      });
  }

  onSearchChanged(value: string) {
    // Emit the value to the debouncing stream
    this.searchChanged$.next(value);
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      const searchParam = 'search';
      if (params[searchParam]) {
        const { [searchParam]: searchValue, ...restParams } = params;
        this.filters.search = searchValue;
        localStorage.setItem('dealsFilter', JSON.stringify(this.filters));
        this.router.navigate(['/deals', 'list']);
      }
    });
    if (localStorage.getItem('dealsFilter')) {
      this.filters = Object.assign(this.filters, JSON.parse(localStorage.getItem('dealsFilter')));
    }
    this.stageKeys = (Object.keys(this.settingsService.settings.stages)).filter(s => !['init', 'ready'].includes(s));
    this.stages = this.stageKeys.map(sKey => ({value: sKey, label: this.settingsService.settings.stages[sKey]}));
    this.usersService.items$.subscribe(users => {
      if (users.length) {
        this.owners = users.filter(u => u.role === 'specialist' || u.role === 'admin').map(o => ({
          value: o._id,
          label: o.firstName + ' ' + o.lastName
        }));
      }
    });
    this.affiliatesService.items$.subscribe(affiliates => {
      if (affiliates.length) {
        this.affiliates = affiliates.map(a => ({value: a._id, label: a.name}));
      }
    });
    this.filter();
  }

  public filterChipRemoved(value, filter) {
    const index = this.filters[filter].indexOf(value);
    if (index >= 0) {
      this.filters[filter].splice(index, 1);
      this.filter();
    }
  }

  public filterChipSelected(event, filter) {
    this.filters[filter] = [...this.filters[filter], event.option.value];
    event.option.focus();
    this.filter();
  }
  public filterChipItems(options, value, filter) {
    return options.filter(o => (!value || o.label.toLowerCase().includes(value.toLowerCase())) && !this.filters[filter].includes(o.value) );
  }

  public async postponeDeal(deal) {
    deal = await this.dealsService.get(deal._id);
    this.dialogsService.postpone(this.settingsService.options, deal.postponedUntil, deal.postponeReason || []).subscribe(async (result) => {
      if (result) {
        this.mainService.showLoading();
        deal = await this.dealsService.patch(deal._id, {
          stage: 'postponed',
          repostpone: !!deal.postponedUntil,
          postponeAlert: 0,
          postponeReason: result.reasons,
          postponedUntil: result.postponeDate,
          postponeTask: result.task,
          postponeTaskDate: result.taskDate,
          postponeTaskTitle: result.taskTitle,
          postponeTaskText: result.taskText,
          postponeTaskHours: result.taskHours,
          postponeTaskMinutes: result.taskMinutes,
          postpone: {
            autoCommunicate: result.autoCommunicate,
            communicationType: result.communicationType
          },
          lastContactedAt: Date(),
        });
        this.mainService.hideLoading();
        this.snackbarService.showSuccess('Případ byl odložen.');
      }
    });
  }

  public async changePriority(deal) {
    const options = this.settingsService.options['priority'];
    this.dialogsService.priority('Změna priority', '', options, deal.priority).subscribe((priority) => {
      if (Number.isInteger(priority)) {
        this.dealsService.patch(deal._id, {priority}).then(() => {
          deal.priority = priority;
          this.snackbarService.showSuccess('Změna priority proběhla úspěšně.');
        });
      }
    });
  }

  public async changeDrawer(deal) {
    const users = this.usersService.items.filter(u => u.role === 'drawer');
    this.dialogsService.drawer(deal.drawerId, users).subscribe((drawerId) => {
      if (drawerId) {
        this.dealsService.patch(deal._id, {drawerId}).then(() => {
          this.snackbarService.showSuccess('Změna čerpatele proběhla úspěšně.');
        });
      }
    });
  }

  public async cancelDeal(deal) {
    this.dialogsService.cancel(this.settingsService.options).subscribe(async (reasons) => {
      if (reasons) {
        this.mainService.showLoading();
        deal = await this.dealsService.patch(deal._id, {stage: 'canceled', cancelReason: reasons, lastContactedAt: Date()});
        this.mainService.hideLoading();
        this.snackbarService.showSuccess('Případ byl stornován.');
      }
    });
  }

  public async restoreDeal(deal) {
    deal = await this.dealsService.get(deal._id);
    this.dialogsService.confirm('Obnovení případu', 'Chcete skutečně obnovit případ?').subscribe( async (result) => {
      if (result) {
        this.mainService.showLoading();
        await this.offersService.restoreOffer(deal, deal._offers[deal.offers.length - 1], result);
        this.mainService.hideLoading();
        this.snackbarService.showSuccess('Případ byl obnoven.');
      }
    });
  }

  defaultFilters(): void {
    this.filters = {
      createdAtFrom: null,
      createdAtTo: null,
      search: '',
      stage: [],
      postponeReason: [],
      postponedUntilFrom: null,
      postponedUntilTo: null,
      signedAtFrom: null,
      signedAtTo: null,
      amountMin: '',
      amountMax: '',
      ownerId: [],
      affiliateId: []
    };
    localStorage.setItem('dealsFilter', JSON.stringify(this.filters));
    this.filter();
  }

  public filter() {
    localStorage.setItem('dealsFilter', JSON.stringify(this.filters));
    const filter: any = {
      $or: [],
      $and: [{stage: {$nin: [null, 'init', 'check', 'ready', 'assignFailed']}}]
    };
    if (!this.usersService.isPrivileged('deals/all')) {
      const filterViewers = [
        {drawerId: this.usersService.user._id},
        {ownerId: this.usersService.user._id}
      ];
      if (this.usersService.user.specialists) {
        // @ts-ignore
        filterViewers.push({ownerId: {$in: this.usersService.user.specialists}});
      }
      filter.$or = filterViewers;
    } else {
      delete filter.$or;
      filter.ownerId = {
        $ne : null,
        $exists: true
      };
    }
    if (this.filters.ownerId?.length) {
      filter.ownerId = this.filters.ownerId;
    }
    if (this.filters.affiliateId?.length) {
      filter.affiliateId = this.filters.affiliateId;
    }
    if (this.filters['stage'].length) {
      filter.stage = {$in: this.filters['stage']};
    }
    if (this.filters['postponeReason'].length) {
      filter.postponeReason = {$in: this.filters['postponeReason']};
    }
    if (this.filters['search']) {
      filter.search = { $regex: this.filters['search'].replace(/\+|\(|\)|\-|\\|\//gi, ''), $options: 'i' };
    }
    if (this.filters['amountMin'] || this.filters['amountMax']) {
      const amountFilter = {};
      if (this.filters['amountMin']) {
        amountFilter['$gte'] = parseInt(this.filters['amountMin']?.match(/\d+/g),  null);
      }
      if (this.filters['amountMax']) {
        amountFilter['$lte'] = parseInt(this.filters['amountMax']?.match(/\d+/g),  null);
      }
      filter.amount = amountFilter;
    }
    if (this.filters['createdAtFrom'] || this.filters['createdAtTo']) {
      const createdAtFilter = {};
      if (this.filters['createdAtFrom']) {
        createdAtFilter['$gte'] = new Date(this.filters['createdAtFrom']);
      }
      if (this.filters['createdAtTo']) {
        createdAtFilter['$lte'] = new Date(this.filters['createdAtTo']);
      }
      filter.createdAt = createdAtFilter;
    }
    if (this.filters['signedAtFrom'] || this.filters['signedAtTo']) {
      const signedAtFilter = {};
      if (this.filters['signedAtFrom']) {
        signedAtFilter['$gte'] = new Date(this.filters['signedAtFrom']);
      }
      if (this.filters['signedAtTo']) {
        signedAtFilter['$lte'] = new Date(this.filters['signedAtTo']);
      }
      filter.signedAt = signedAtFilter;
    }
    if (this.filters['postponedUntilFrom'] || this.filters['postponedUntilTo']) {
      const postponedUntilFilter = {};
      if (this.filters['postponedUntilFrom']) {
        postponedUntilFilter['$gte'] = new Date(this.filters['postponedUntilFrom']);
      }
      if (this.filters['postponedUntilTo']) {
        postponedUntilFilter['$lte'] = new Date(this.filters['postponedUntilTo']);
      }
      filter.postponedUntil = postponedUntilFilter;
    }
    this.dealsService.filter = filter;
    this.dealsService.load(0);
  }

  public async watchdog(deal) {
    const updated = await this.dealsService.watchdog(deal);
    if (updated) {
      deal.isWatchdog = updated.isWatchdog;
    }
  }

  public async changeOwners() {
    let deals = [];
    this.mainService.showLoading();
    const query = this.dealsService.buildQuery();
    query.$limit = 100000;
    deals = (await this.dealsService.find({query})).data;
    this.mainService.hideLoading();
    this.dealsService.changeOwner(deals);
  }

  public async dialPhone(event: any, deal: any) {
    const param: DialNumberParam = {
      countryCode: event.countryCode,
      number: event.phoneNumber,
      contactName: event.contactName,
      contactId: event.contactId,
      origin: 'DealsComponent',
      dealId: deal._id,
      pipelineId: deal._pipelineId,
      dealOwnerId: deal.ownerId,
      stage: deal.stage
    };
    await this.callsService.initiateCall(param);
  }
}
