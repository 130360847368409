import { Injectable } from '@angular/core';
import { BackendService } from './backend.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '@env/environment';
import { UsersService } from './users.service';
import { SettingsService } from './settings.service';
import { MainService } from './main.service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { AbstractControl, FormArray, FormBuilder, FormGroup, NgForm, ValidatorFn, Validators } from '@angular/forms';
import { DealProductsService } from './dealproducts.service';
import { SnackbarService } from './snackbar.service';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { DialogsService } from './dialogs.service';
import { ContactsService } from './contacts.service';
import { AresService } from './ares.service';
import { RuianService } from './ruian.service';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { DocumentsService } from './documents.service';
import { DocumentInterface } from '../interfaces/document.interface';
import { DocumentStatusInterface } from '../interfaces/document-template.interface';
import { OptionInterface } from '../interfaces/option.interface';
import { Offer } from '@app/models/offer.model';
import { Deal } from '@app/models/deal.model';
import { HouseholdsService } from '@app/services/households.service';
import { PropertiesService } from '@app/services/properties.service';
import { Property } from '@app/models/property.model';
import { Household } from '@app/models/household.model';
import { RelationLeadApplicantGroupPipe } from '@app/pipes/relation-lead-applicant-group.pipe';
import { Document } from '@app/models/document.model';

@Injectable()
export class OffersService {
  private socket: any;
  private offer: Offer = null;
  public _applicantsForm: FormArray = new FormArray([]);
  public _propertiesForm: FormArray = new FormArray([]);
  public _householdsForm: FormArray = new FormArray([]);
  private $offer: BehaviorSubject<Offer> = new BehaviorSubject(null);
  public offer$: Observable<Offer> = this.$offer.asObservable();
  public $save: Subject<string> = new Subject();
  public save$: Observable<string> = this.$save.asObservable();
  public view: string = 'offer';

  public offerForm: FormGroup;
  public documentsForm: FormGroup;
  public commissionsForm: FormGroup;
  public modelationIndex = 0;
  public applicantsProperties: (Property & { isOwner: boolean })[] = [];
  public applicantsHouseholds: Household[] = [];

  get _applicants(): AbstractControl[] {
    return this._applicantsForm.controls;
  }

  get _properties(): AbstractControl[] {
    return this._propertiesForm.controls;
  }

  get _households(): AbstractControl[] {
    return this._householdsForm.controls;
  }

  get applicants(): AbstractControl {
    return this.offerForm ? this.offerForm.get('applicants') : null;
  }

  get relationsToLeadApplicant(): FormArray {
    return this.offerForm ? (this.offerForm.get('relationsToLeadApplicant') as FormArray) : null;
  }

  get households(): AbstractControl {
    return this.offerForm ? this.offerForm.get('households') : null;
  }

  get properties(): FormArray {
    return this.offerForm ? (this.offerForm.get('properties') as FormArray) : null;
  }

  get drawdowns(): FormArray {
    return this.offerForm ? (this.offerForm.get('drawdowns') as FormArray) : null;
  }

  get modelations(): FormArray {
    return this.offerForm ? (this.offerForm.get('modelations') as FormArray) : null;
  }

  get variants(): FormArray {
    return this.modelations.at(this.modelationIndex).get('variants') as FormArray;
  }

  get documents(): FormArray {
    return this.documentsForm ? (this.documentsForm.get('documents') as FormArray) : null;
  }

  constructor(
    private backendService: BackendService,
    private usersService: UsersService,
    private mainService: MainService,
    private settingsService: SettingsService,
    private productsService: DealProductsService,
    private snackbarService: SnackbarService,
    private dialogsService: DialogsService,
    private contactsService: ContactsService,
    public propertiesService: PropertiesService,
    public householdsService: HouseholdsService,
    private documentsService: DocumentsService,
    private http: HttpClient,
    private fb: FormBuilder,
    private aresService: AresService,
    private ruianService: RuianService,
    private relationLeadApplicantGroup: RelationLeadApplicantGroupPipe
  ) {
    this.socket = this.backendService.getService('offers');
    this.socket.on('updated', (item: Offer) => this.updated(item));
    this.socket.on('patched', (item: Offer) => this.updated(item));
    this.createOfferForm();
    this.createDocumentsForm();
    // this.contactsService.updated$.subscribe((contact) => { // TODO V-P Podle me uz neni potreba ale uvidime. Myslim ze to bylo protoze se pak prepisovaly statusy atd ale tim ze je to ted vytazeny ven, tak nebude potreba.
    //   const contactIndex = this._applicants.findIndex(a => a._id === contact._id);
    //   if (contactIndex > -1) {
    //     this._applicants[contactIndex] = contact;
    //   }
    // });
  }

  async searchAres(income) {
    this.mainService.showLoading();
    const source = income.get('source');
    const result = await this.aresService.get(source.get('idNumber').value);
    if (result.idNumber) {
      source.get('vatNumber').patchValue(result.vatNumber);
      source.get('name').patchValue(result.name);
      source.get('address.street').patchValue(result.address.street);
      source.get('address.city').patchValue(result.address.city);
      source.get('address.zipCode').patchValue(result.address.zipCode);
      source.get('address.orientationNumber').patchValue(result.address.orientationNumber);
      source.get('address.houseNumber').patchValue(result.address.houseNumber);
      source.get('address.district').patchValue(result.address.district);
      source.get('address.region').patchValue(result.address.region);
      income.markAsDirty();
    }
    this.mainService.hideLoading();
  }

  async completeAddress(control, event: MatAutocompleteSelectedEvent) {
    control.get('street').patchValue('');
    this.mainService.showLoading();
    const address = await this.ruianService.get(event.option.value);
    if (address) {
      control.get('street').patchValue(address.street);
      control.get('city').patchValue(address.city);
      control.get('zipCode').patchValue(address.zipCode);
      control.get('houseNumber').patchValue(address.houseNumber);
      control.get('orientationNumber').patchValue(address.orientationNumber);
      control.get('district').patchValue(address.district);
      control.get('region').patchValue(address.region);
      control.markAsDirty();
    }
    this.mainService.hideLoading();
  }

  public hasDocuments(offer: Offer, group: string = null) {
    if (group) {
      return (
        offer._documents
          ?.filter((d: DocumentInterface) => d._template?.group === group)
          ?.some((d: DocumentInterface) => d.files?.length) ||
        offer._applicants?.some(a =>
          a._documents
            ?.filter((d: DocumentInterface) => d._template?.group === group)
            .some((d: DocumentInterface) => d.files?.length)
        )
      );
    } else {
      return (
        offer._documents?.some((d: DocumentInterface) => d.files?.length) ||
        offer._applicants?.some(a => a._documents?.some((d: DocumentInterface) => d.files?.length))
      );
    }
  }

  public changeAllDocumentsStatus(deal, offer: Offer, group: string = null, status: string) {
    for (const document of this.documents.controls) {
      if (document.get('_template').value.group === group) {
        document.get('status').patchValue(status);
        document.get('status').markAsDirty();
      }
    }
  }

  public async getDocuments(deal, offer: Offer, group: string = null) {
    this.mainService.showLoading();
    let headers: HttpHeaders;
    let params = new HttpParams();
    let documents = await this.documentsService.find({
      query: {
        $or: [
          {
            offerId: offer._id,
          },
          {
            contactId: { $in: offer.applicants },
            offerId: offer._id,
          },
          {
            contactId: { $in: offer.applicants },
            offerId: { $exists: false },
          },
        ],
        'files.0': { $exists: true },
      },
    });
    documents = group ? documents?.filter(d => d._template?.group === group) : documents;
    if (documents.length) {
      documents.forEach((document: Document) => {
        params = params.append('docsIds[]', document._id);
      });
      headers = new HttpHeaders({
        Authorization: this.backendService.accessToken,
      });
      this.http
        .get(environment.backendUrl + '/documents/export', {
          headers,
          params,
          responseType: 'blob',
        })
        .subscribe((response: any) => {
          const url = window.URL.createObjectURL(response);
          const anchor = document.createElement('a');
          anchor.href = url;
          anchor.target = '_blank';
          anchor.download = this.getFilename(deal, offer, '.zip', group);
          document.body.appendChild(anchor);
          anchor.click();
          document.body.removeChild(anchor);
          this.mainService.hideLoading();
        });
    } else {
      this.mainService.hideLoading();
      this.dialogsService.alert('Stáhnout dokumenty', 'Vybrané dokumenty neobsahují žádné soubory.');
    }
  }

  public getPdf(deal, offer, download = false) {
    this.mainService.showLoading();
    let headers: HttpHeaders;
    let params = new HttpParams();
    params = params.append('export', 'pdf_offer');
    headers = new HttpHeaders({
      Authorization: this.backendService.accessToken,
    });
    this.http
      .get(environment.backendUrl + '/offers/pdf/' + offer._id, { headers, params, responseType: 'blob' })
      .subscribe((response: any) => {
        const url = window.URL.createObjectURL(response);
        if (download) {
          const anchor = document.createElement('a');
          anchor.href = url;
          anchor.target = '_blank';
          anchor.download = this.getFilename(deal, offer, '.pdf');
          document.body.appendChild(anchor);
          anchor.click();
          document.body.removeChild(anchor);
        } else {
          window.open(url);
        }
        this.mainService.hideLoading();
      });
  }

  public getFilename(deal, offer, type, group = null) {
    const project = deal.project.normalize('NFD');
    const filename = [offer.numberId, project, group ?? 'nabidka']
      .join('_')
      .toLowerCase()
      .replace(':', '')
      .replace(/[\u0300-\u036f]/g, '')
      .replace(/\s/g, '_');
    return filename + type;
  }

  public offerStageText(offer) {
    return offer.stage ? this.settingsService.settings.stages[offer.stage] : 'Výchozí stav';
  }

  public isStageBetween(stage, from, to = null) {
    const index = stage ? this.settingsService.settings.stageOrder.indexOf(stage) : 0;
    const fromIndex = from ? this.settingsService.settings.stageOrder.indexOf(from) : 0;
    const toIndex = to ? this.settingsService.settings.stageOrder.indexOf(to) : 1000;
    return index >= fromIndex && index <= toIndex;
  }

  public setOffer(offer: Offer, fillForm = false) {
    if (offer) {
      if (offer?._id !== this.offer?._id) {
        this.modelationIndex = 0;
      }
      this.offer = offer;
      if (fillForm) {
        // TODO V-P Tady bylo neznamo proc || this.offer.isCanceled
        this.fillOfferForm();
        this.fillDocumentsForm();
      }
      this.$offer.next(this.offer);
    }
  }

  public get(id: string, params: any = {}) {
    return this.socket.get(id, params);
  }

  public patch(id: string, item: any, params: any = {}) {
    if (id && item.isPlatformChange === undefined) {
      item.isPlatformChange = true;
    }
    return this.socket.patch(id, item, params);
  }

  public create(item: any, params: any = {}) {
    return this.socket.create(item, params);
  }

  public remove(id: string, params: any = {}) {
    return this.socket.remove(id, params);
  }

  private updated(item: Offer): void {
    if (this.offer?._id === item?._id) {
      this.setOffer(item);
    }
  }

  public randomId(control, type) {
    const id = Math.floor(Math.random() * 100000);
    // if (type === 'variants') {
    //   if (control.controls.some(v => parseInt(v.get('id').value, null) === id)) {
    //     return this.randomId(control, type);
    //   }
    // }
    if (type === 'applicants') {
      if (control.controls.some(a => parseInt(a.get('_id').value, null) === id)) {
        return this.randomId(control, type);
      }
    }
    return id;
  }

  public hasFinal(offer) {
    return offer
      ? !offer.isCanceled &&
          offer.modelations &&
          offer.modelations.some(m => m.variants && m.variants.some(v => v.isFinal))
      : false;
  }

  public getFinalVariant(offer) {
    if (this.hasFinal(offer)) {
      const finalModelation = offer.modelations.find(m => m.variants.some(v => v.isFinal));
      const finalVariant = finalModelation.variants.find(v => v.isFinal);
      return finalVariant;
    } else {
      return null;
    }
  }

  public hasConfirmed(offer) {
    return offer.modelations && offer.modelations.some(m => m.variants && m.variants.some(v => v.isConfirmed));
  }

  public hasCandidate(offer) {
    return offer.modelations && offer.modelations.some(m => m.variants && m.variants.some(v => v.isCandidate));
  }

  public someHasInsurance(controls) {
    return controls.some(a => a.get('insurance').value);
  }

  public canEdit(): boolean {
    return true;
  }

  public canCancel(deal, offer) {
    return !offer.isCanceled && deal.offers.filter(o => !o.isCanceled).length >= 2;
  }

  public formValid() {
    if (this.mainService.getFormValidationErrors(this.offerForm, [], ['modelations']).length !== 0) {
      return false;
    }
    return this._applicantsForm.valid && this._propertiesForm.valid && this._householdsForm.valid;
  }

  public documentsValid() {
    const documents = this.documents.value;
    if (this.offer.stage === 'approvalDocuments') {
      if (
        !documents
          .filter(
            (d: DocumentInterface) =>
              d._template?.groupId === 25 &&
              d._template?.statuses.map((ds: DocumentStatusInterface) => ds.value).includes('transferedToBank')
          )
          .every((d: DocumentInterface) => ['transferedToBank', 'done', 'approved', 'sendOriginal'].includes(d.status))
      ) {
        return false;
      }
    }
    if (this.offer.stage === 'approval') {
      if (
        !documents
          .filter(
            (d: DocumentInterface) =>
              d._template?.groupId === 25 &&
              d._template?.statuses.map((ds: DocumentStatusInterface) => ds.value).includes('approved')
          )
          .every((d: DocumentInterface) => ['done', 'approved', 'sendOriginal'].includes(d.status))
      ) {
        return false;
      }
    }
    if (this.offer.stage === 'approved') {
      if (
        !documents
          .filter((d: DocumentInterface) => d._template?.groupId === 30)
          .every((d: DocumentInterface) => ['done', 'sendOriginal'].includes(d.status))
      ) {
        return false;
      }
    }
    if (this.offer.stage === 'drawing') {
      if (
        !documents
          .filter(
            (d: DocumentInterface) =>
              d._template?.groupId === 40 &&
              d._template?.statuses.map((ds: DocumentStatusInterface) => ds.value).includes('approved')
          )
          .every((d: DocumentInterface) => ['transferedToBank', 'done', 'approved', 'sendOriginal'].includes(d.status))
      ) {
        return false;
      }
    }
    if (this.mainService.getFormValidationErrors(this.documents).length !== 0) {
      return false;
    }
    return true;
  }

  public modelationsValid() {
    if (this.isStageBetween(this.offer.stage, 'sent')) {
      if (!this.modelations.value.some(m => m.variants.some(v => v.isConfirmed))) {
        return false;
      }
    }
    if (this.isStageBetween(this.offer.stage, 'approved')) {
      if (!this.modelations.value.some(m => m.variants.some(v => v.isFinal))) {
        return false;
      }
    }
    if (this.mainService.getFormValidationErrors(this.modelations).length !== 0) {
      return false;
    }
    return true;
  }

  public canGoNext(isCancel = false) {
    if (
      !this.offerForm.pristine ||
      !this._applicantsForm.controls.every((a: any) => a.pristine) ||
      !this._householdsForm.controls.every((h: any) => h.pristine) ||
      !this._propertiesForm.controls.every((p: any) => p.pristine)
    ) {
      return false;
    }
    if (!this.offer.tempComment) {
      return false;
    }
    return (
      isCancel ||
      (this.formValid() &&
        this._applicantsForm.valid &&
        this._householdsForm.valid &&
        this._propertiesForm.valid &&
        this.modelationsValid() &&
        this.documentsValid())
    );
  }

  public canSendForControl() {
    if (!this.offerForm.get('tempComment').value) {
      return false;
    }
    if (this.mainService.getFormValidationErrors(this.offerForm).length !== 0) {
      return false;
    }
    for (let modelationIndex = 0; modelationIndex < this.modelations.controls.length; modelationIndex++) {
      if (this.modelationVariantsNotEqual(modelationIndex)) {
        return false;
      }
    }
    return true;
  }

  public async changeScore() {
    await this.dialogsService
      .score(this.settingsService.options['fillingScore'], this.offer.fillingScore)
      .subscribe(async result => {
        if (result) {
          this.mainService.showLoading();
          const offer = await this.patch(this.offer._id, {
            scoreByStepUntil: this.offer.fillingScore,
            fillingScore: result.newScore,
          });
          this.offer.fillingScore = offer.fillingScore;
          this.mainService.hideLoading();
          this.snackbarService.showSuccess(`Bankovnictví bylo nastaveno na krok ${result.newScore}.`);
        }
      });
  }

  public modelationVariantsNotEqual(modelationIndex) {
    const variants = this.modelations.at(modelationIndex).get('variants') as FormArray;
    if (variants.controls.length > 0) {
      return !variants.controls.every(
        v =>
          variants.at(0).get('totalAmount').value === v.get('totalAmount').value &&
          parseInt(variants.at(0).get('products').value[0].maturity, null) ===
            parseInt(v.get('products').value[0].maturity, null) &&
          parseInt(variants.at(0).get('products').value[0].fixation, null) ===
            parseInt(v.get('products').value[0].fixation, null) &&
          variants.at(0).get('products').value[0].insurance === v.get('products').value[0].insurance &&
          variants
            .at(0)
            .get('products')
            .value.every((p, pIndex) => {
              return (
                v.get('products').value[pIndex] &&
                this.mainService.isEquivalent(
                  p.productTemplate.filter,
                  v.get('products').value[pIndex].productTemplate.filter
                )
              );
            })
      );
    }
    return false;
  }

  public modelationInvalid(modelationIndex) {
    return this.mainService.getFormValidationErrors(this.modelations.at(modelationIndex) as FormArray).length > 0;
  }

  public modelationCreditWorthy(modelationIndex) {
    return (
      (this.modelations.at(modelationIndex) as FormArray).get('variants').value.length &&
      (this.modelations.at(modelationIndex) as FormArray)
        .get('variants')
        .value.every(v => v.products.every(p => p.isCreditworthy))
    );
  }

  public modelationHasCandidate(modelation) {
    return modelation.get('variants').controls.some(v => v.get('isCandidate').value);
  }

  public modelationHasConfirmed(modelation) {
    return modelation.get('variants').controls.some(v => v.get('isConfirmed').value);
  }

  public modelationHidden(modelation) {
    return modelation.get('variants').controls.every(v => v.get('isHidden').value);
  }

  public modelationHasFinal(modelation) {
    return !this.offer.isCanceled && modelation.get('variants').controls.some(v => v.get('isFinal').value);
  }

  public selectModelation(mIndex) {
    return (this.modelationIndex = mIndex);
  }

  private createDocumentsForm() {
    this.documentsForm = this.fb.group({
      tempComment: [null],
      documents: this.fb.array([]),
    });
  }

  public createDocumentForm(document: DocumentInterface) {
    const documentForm = this.fb.group({
      _index: [Math.random()],
      _id: [null],
      templateId: [null],
      _template: [null],
      files: [[]],
      isHidden: [false],
      type: [null],
      notify: [],
      note: [null],
      status: ['waiting'],
      deliverUntil: [null],
      deliveredAt: [null],
      signatureUntil: [null],
      signedAt: [null],
      providerId: [null],
      contactId: [null],
      drawdownIndex: [null],
      isInternal: [null],
      userId: [null],
      noteId: [null],
      createdAt: [null],
    });
    if (document) {
      documentForm.patchValue(document);
    }
    return documentForm;
  }

  private fillDocumentsForm() {
    this.documentsForm.reset();
    this.documents.controls = [];
    this.documentsForm.get('tempComment').patchValue(this.offer.tempComment);
    let documents = [];
    for (const applicant of this.offer._applicants) {
      if (applicant._documents?.length) {
        documents = [...documents, ...applicant._documents];
      }
    }
    if (this.offer._documents?.length) {
      documents = [...documents, ...this.offer._documents];
    }

    documents = documents.sort((a: DocumentInterface, b: DocumentInterface) => {
      if (a.isInternal && b.isInternal) {
        return (new Date(b.createdAt))?.getTime() - (new Date(a.createdAt))?.getTime();
      }
    });

    for (const document of documents) {
      this.documents.push(this.createDocumentForm(document));
    }

    this.documentsForm.markAllAsTouched();
  }

  private createOfferForm() {
    this.offerForm = this.fb.group({
      note: this.fb.group({
        specialist: [null],
        operator: [null],
        support: [null],
        drawer: [null],
      }),
      amount: [0],
      value: [0],
      purpose: [[], Validators.required],
      fixation: [0],
      maturity: [0],
      insurance: [false],
      forOwnHousing: [false],
      ownResources: [0],
      otherResources: [0],
      refinancingDate: [null],
      fixationEndDate: [null],
      firstRepaymentDate: [null],
      outOfFixationDrawing: [false],
      hasSpecificProperty: [true],
      oldestApplicantAge: [0],
      drawingPeriod: [1],
      repaymentDay: [null],
      expressDrawdown: [false],
      drawdowns: this.fb.array([], [this.drawdownsValidator()]),
      leadApplicantId: [null],
      insuranceApplicantsId: [[]],
      relationsToLeadApplicant: this.fb.array([]),
      applicants: [[]],
      modelations: this.fb.array([]),
      households: [[], [Validators.required, this.requireAllApplicantsHousehold()]],
      properties: this.fb.array([]),
      ltv: [0],
      tempComment: [null],
      calculatorProduct: [null],
      refinancedProduct: this.fb.group({
        bankCode: [null],
        interestRate: [0],
        amount: [0],
        signatureDate: [null],
        fixation: [0],
        repayment: [0],
      }),
      // bankingFilters: [null], // TODO V-P podle me zbytecnej pozustatek
      benefits: [[]],
    });
    this.offerForm.get('amount').valueChanges.subscribe((amount: any) => {
      const value = this.mainService.unmaskCurrency(this.offerForm.get('value').value);
      amount = this.mainService.unmaskCurrency(amount);
      this.offerForm.get('ltv').setValue(amount && value ? Math.ceil((amount / value) * 100) : 0);
    });
    this.offerForm.get('value').valueChanges.subscribe((value: any) => {
      const amount = this.mainService.unmaskCurrency(this.offerForm.get('amount').value);
      value = this.mainService.unmaskCurrency(value);
      this.offerForm.get('ltv').setValue(amount && value ? Math.ceil((amount / value) * 100) : 0);
    });
    this.offerForm.get('oldestApplicantAge').valueChanges.subscribe((value: any) => {
      this.productsService.filters.oldestApplicantAge = parseInt(value, null);
    });
    this.offerForm.get('properties').valueChanges.subscribe((value: any) => {
      this.productsService.filters.propertyType = this.propertyType(this.offerForm.value);
      const totalValue = this._propertiesForm.value.reduce((acc, prop) => {
        acc +=
          this.mainService.unmaskCurrency(prop.value) &&
          this.properties.value.find(p => p._id === prop._id)?.status?.includes('pledged')
            ? this.mainService.unmaskCurrency(prop.value)
            : 0;
        return acc;
      }, 0);
      this.offerForm.get('value').patchValue(totalValue);
    });
    // HP-2136
    // this.offerForm.get('outOfFixationDrawing').valueChanges.subscribe((value: any) => {
    //   if (value) {
    //     this.offerForm.get('refinancingDate').setValidators([Validators.required]);
    //   } else {
    //     this.offerForm.get('refinancingDate').clearValidators();
    //   }
    //   this.offerForm.get('refinancingDate').updateValueAndValidity();
    // });
    this.offerForm.get('hasSpecificProperty').valueChanges.subscribe((value: any) => {
      if (value) {
        this.offerForm.get('drawingPeriod').clearValidators();
      } else {
        this.offerForm.get('drawingPeriod').setValidators([Validators.required]);
      }
      this.offerForm.get('drawingPeriod').updateValueAndValidity();
      for (const _property of this._properties) {
        this.propertiesService.propertyAddressUpdateValidator(_property, this.offer.stage, this.offerForm);
      }
    });
    // HP-2136 TODO -->
    // this.offerForm.get('fixationEndDate').valueChanges.subscribe((value: any) => {
    //   if (value) {
    //     const fixationEndDateValue = this.offerForm?.get('fixationEndDate')?.value?.toDate();
    //     console.log('fixationEndDateValue', fixationEndDateValue);
    //
    //     if (this._applicantsForm.controls) {
    //       this._applicantsForm.controls.forEach((applicant: FormGroup) => {
    //         console.log('applicant', applicant.value);
    //         if (applicant.get('expenditures')) {
    //           console.log('expenditures', (applicant.get('expenditures') as FormArray).value);
    //           (applicant.get('expenditures') as FormArray).controls.forEach((expenditure: FormGroup) => {
    //             console.log('expenditure', expenditure.value);
    //             if ((!expenditure.get('fixationEndAt')?.value) && (expenditure.get('type')?.value === 'mortgage')) {
    //               expenditure.get('fixationEndAt').setValue(fixationEndDateValue);
    //             }
    //           });
    //         }
    //       });
    //     }
    //   }
    // });
    // <-- TODO HP-2136
    this.offerForm.get('purpose').valueChanges.subscribe((value: any) => {
      // HP-2136 -->
      if (value && value.includes('refinancing')) {
        this.offerForm.get('refinancingDate').setValidators([Validators.required]);
      } else {
        this.offerForm.get('refinancingDate').clearValidators();
      }
      this.offerForm.get('refinancingDate').updateValueAndValidity();
      // if (value && value.includes('refinancing')) {
      //   this.offerForm.get('fixationEndDate').setValidators([Validators.required]);
      // } else {
      //   this.offerForm.get('fixationEndDate').clearValidators();
      // }
      // this.offerForm.get('fixationEndDate').updateValueAndValidity();
      // <-- HP-2136
      if (this.modelations.value.length) {
        // Budeme šaškovat s ucelama na modelacich
        for (const modelation of this.modelations.controls) {
          if (modelation.get('purposes')?.value?.length === 0 && value?.length === 2) {
            let pmIndex = 0;
            for (const purpose of value) {
              pmIndex++;
              (modelation.get('purposes') as FormArray).push(
                this.createModelationPurposeForm(
                  {
                    type: purpose,
                    amount: pmIndex === 1 ? this.mainService.unmaskCurrency(modelation.get('amount').value) : 0,
                  },
                  modelation
                )
              );
            }
          } else if (
            modelation.get('purposes')?.value?.length > 1 &&
            value?.length > modelation.get('purposes')?.value?.length
          ) {
            (modelation.get('purposes') as FormArray).push(
              this.createModelationPurposeForm({ type: value.slice(-1)[0], amount: 0 }, modelation)
            );
          } else if (
            modelation.get('purposes')?.value?.length > 2 &&
            value?.length < modelation.get('purposes')?.value?.length
          ) {
            (modelation.get('purposes') as FormArray).removeAt(
              modelation.get('purposes').value.findIndex(pm => !value.includes(pm.type))
            );
          } else if (
            modelation.get('purposes')?.value?.length > 1 &&
            value?.length < modelation.get('purposes')?.value?.length
          ) {
            (modelation.get('purposes') as FormArray).controls = [];
          }
          modelation.get('purposes').updateValueAndValidity();
        }
      }
    });
    this.offerForm.get('refinancedProduct.interestRate').valueChanges.subscribe(async (value: any) => {
      this.offerForm
        .get('refinancedProduct.interestRate')
        .setValue(value ? value.toString().replace('.', ',') : 0, { emitEvent: false });
    });
    this._applicantsForm.valueChanges.subscribe((value: any) => {
      let oldestApplicantAge = 0;
      for (const applicant of value) {
        if (applicant.age > oldestApplicantAge) {
          oldestApplicantAge = applicant.age;
        }
      }
      this.offerForm.get('oldestApplicantAge').setValue(oldestApplicantAge);
    });
    this._propertiesForm.valueChanges.subscribe((value: any) => {
      for (const _property of value) {
        const propertyIndex = this.applicantsProperties.findIndex(ap => ap?._id === _property._id);
        if (propertyIndex > -1) {
          this.applicantsProperties[propertyIndex] = _property;
        }
      }
      const totalValue = value.reduce((acc, prop) => {
        acc +=
          this.mainService.unmaskCurrency(prop.value) &&
          this.properties.value.find(p => p._id === prop._id)?.status?.includes('pledged')
            ? this.mainService.unmaskCurrency(prop.value)
            : 0;
        return acc;
      }, 0);
      this.offerForm.get('value').patchValue(totalValue);
    });
    this._householdsForm.valueChanges.subscribe((value: any) => {
      for (const _household of value) {
        const householdIndex = this.applicantsHouseholds.findIndex(ah => ah._id === _household._id);
        if (householdIndex > -1) {
          this.applicantsHouseholds[householdIndex] = _household;
        }
      }
    });
  }

  private fillOfferForm() {
    this.offerForm.reset();
    this.relationsToLeadApplicant.controls = [];
    this.modelations.controls = [];
    this.properties.controls = [];
    this.drawdowns.controls = [];
    this._applicantsForm.controls = [];
    this._householdsForm.controls = [];
    this._propertiesForm.controls = [];
    this._applicantsForm.reset();
    this._householdsForm.reset();
    this._propertiesForm.reset();
    this.applicantsProperties = [];
    this.applicantsHouseholds = [];
    this.offerForm.get('amount').setValue(this.offer.amount);
    this.offerForm.get('value').setValue(this.offer.value);
    this.offerForm.get('purpose').patchValue(this.offer.purpose);
    this.offerForm.get('maturity').setValue(this.offer.maturity);
    this.offerForm.get('fixation').setValue(this.offer.fixation);
    this.offerForm.get('insurance').setValue(this.offer.insurance);
    this.offerForm.get('note').patchValue(this.offer.note);
    this.offerForm.get('forOwnHousing').setValue(this.offer.forOwnHousing);
    this.offerForm.get('outOfFixationDrawing').setValue(this.offer.outOfFixationDrawing);
    this.offerForm.get('ownResources').setValue(this.offer.ownResources);
    this.offerForm.get('otherResources').setValue(this.offer.otherResources);
    this.offerForm.get('refinancingDate').setValue(this.offer.refinancingDate);
    this.offerForm.get('fixationEndDate').setValue(this.offer.fixationEndDate);
    this.offerForm.get('firstRepaymentDate').setValue(this.offer.firstRepaymentDate);
    this.offerForm.get('hasSpecificProperty').patchValue(this.offer.hasSpecificProperty);
    this.offerForm.get('repaymentDay').setValue(this.offer.repaymentDay ?? 20); // HP-2136
    this.offerForm.get('expressDrawdown').setValue(this.offer.expressDrawdown);
    this.offerForm.get('tempComment').setValue(this.offer.tempComment);
    this.offerForm.get('drawingPeriod').patchValue(this.offer.drawingPeriod);
    this.offerForm.get('insuranceApplicantsId').patchValue(this.offer.insuranceApplicantsId || []);
    this.offerForm.get('applicants').patchValue(this.offer.applicants || []);
    this.offerForm.get('households').patchValue(this.offer.households || []);
    this.offerForm.get('leadApplicantId').patchValue(this.offer.leadApplicantId);
    this.offerForm
      .get('calculatorProduct')
      .patchValue(this.offer.calculatorProduct ? this.offer.calculatorProduct : null);
    // this.offerForm.get('bankingFilters').patchValue(this.offer.bankingFilters ? this.offer.bankingFilters : null); // TODO V-P podle me zbytecnej pozustatek
    this.offerForm.get('benefits').patchValue(this.offer.benefits ? this.offer.benefits : []);
    if (this.offer.refinancedProduct) {
      this.offerForm.get('refinancedProduct').patchValue(this.offer.refinancedProduct);
      this.offerForm
        .get('refinancedProduct.interestRate')
        .patchValue(this.mainService.maskPercent(this.offer.refinancedProduct.interestRate));
      this.offerForm
        .get('refinancedProduct.amount')
        .patchValue(this.mainService.maskCurrency(this.offer.refinancedProduct.amount));
      this.offerForm
        .get('refinancedProduct.repayment')
        .patchValue(this.mainService.maskCurrency(this.offer.refinancedProduct.repayment));
    }
    if (this.isStageBetween(this.offer.stage, 'applicationDocuments')) {
      this.offerForm.get('repaymentDay').setValidators([Validators.required]);
      this.offerForm.get('repaymentDay').updateValueAndValidity();
    }
    for (const property of this.offer.properties || []) {
      this.properties.push(this.createPropertyForm(property));
    }
    for (const modelation of this.offer.modelations || []) {
      this.modelations.push(this.createModelationForm(modelation));
    }
    for (const drawdown of this.offer.drawdowns || []) {
      this.drawdowns.push(this.createDrawdownForm(drawdown));
    }
    for (const relation of this.offer.relationsToLeadApplicant || []) {
      this.relationsToLeadApplicant.push(
        this.createRelationToLeadApplicantForm(
          relation,
          this.offerForm.get('leadApplicantId').value === relation.contactId
        )
      );
    }
    for (const contactId of this.offer.applicants || []) {
      if (!this.offer.relationsToLeadApplicant?.some(relation => relation.contactId === contactId)) {
        this.relationsToLeadApplicant.push(
          this.createRelationToLeadApplicantForm(
            { type: '', contactId },
            this.offerForm.get('leadApplicantId').value === contactId
          )
        );
      }
    }
    for (const applicant of this.offer._applicants || []) {
      this._applicantsForm.push(
        this.contactsService.createForm(
          applicant,
          'mortgage',
          this.offer.stage,
          this.offerForm.get('leadApplicantId').value === applicant._id,
          this.offer._applicants.findIndex((a: any) => a._id === applicant._id),
          null
        )
      );
      this.applicantsProperties.push(...applicant._properties);
      this.applicantsHouseholds.push(...applicant._households);
    }
    this.applicantsProperties = this.applicantsProperties.filter(
      (value, index, self) => self.findIndex(s => s?._id === value?._id) === index
    );
    this.applicantsHouseholds = this.applicantsHouseholds.filter(
      (value, index, self) => self.findIndex(s => s?._id === value?._id) === index
    );
    for (const property of this.offer._properties || []) {
      this._propertiesForm.push(this.propertiesService.createForm(property, this.offer.stage, this.offerForm));
    }
    for (const household of this.offer.households?.length ? this.offer._households : []) {
      this._householdsForm.push(this.householdsService.createForm(household, this.offer.stage, this._applicantsForm));
    }
    this.offerForm.get('purpose').patchValue(this.offer.purpose); // Kvuli updatu modelací pokud mame vic jak dva ucely
    this.offerForm.markAllAsTouched();
    this._applicantsForm.markAllAsTouched();
    this._householdsForm.markAllAsTouched();
    this._propertiesForm.markAllAsTouched();
  }

  public minRateValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const minRate = this.mainService.unmaskFloat(
        control.get('minimumInterestRate') ? control.get('minimumInterestRate').value : 0
      );
      const interestRate = this.mainService.unmaskFloat(
        control.get('interestRate') ? control.get('interestRate').value : 0
      );
      if (minRate > interestRate) {
        control.get('minimumInterestRate').setErrors({ minRate: true });
      } else {
        control.get('minimumInterestRate').setErrors(null);
      }
      control.get('minimumInterestRate').markAsTouched();
      return null;
    };
  }

  // APPLICANTS

  public async addApplicant() {
    this.mainService.showLoading();
    if (
      !(
        this.offerForm.pristine &&
        this._applicantsForm.controls.every(c => c.pristine) &&
        this._householdsForm.controls.every(c => c.pristine) &&
        this._propertiesForm.controls.every(c => c.pristine)
      )
    ) {
      await this.saveOffer();
    }
    const newContact = await this.contactsService.create({ offer: this.offer._id });
    this._applicants.push(
      this.contactsService.createForm(
        newContact,
        'mortgage',
        this.offer.stage,
        this.offerForm.get('leadApplicantId').value === newContact._id,
        this.offer._applicants.findIndex((a: any) => a._id === newContact._id)
      )
    );
    this.applicants.patchValue([...this.applicants.value, newContact._id]);
    this._propertiesForm.updateValueAndValidity();
    this._householdsForm.updateValueAndValidity();
    this.properties.updateValueAndValidity();
    this.households.updateValueAndValidity();
    this._applicantsForm.markAllAsTouched();
    this.applicants.markAllAsTouched();
    this.relationsToLeadApplicant.push(
      this.createRelationToLeadApplicantForm(
        { type: '', contactId: newContact._id },
        this.offerForm.get('leadApplicantId').value === newContact._id
      )
    );
    this.relationsToLeadApplicant.markAllAsTouched();
    this.mainService.hideLoading();
  }

  public removeApplicant(applicantId) {
    this.dialogsService
      .confirm('Odebrat žadatele', 'Chcete opravdu odebrat tohoto žadatele?')
      .subscribe(async confirm => {
        if (confirm) {
          this.mainService.showLoading();
          if (
            !(
              this.offerForm.pristine &&
              this._applicantsForm.controls.every(c => c.pristine) &&
              this._householdsForm.controls.every(c => c.pristine) &&
              this._propertiesForm.controls.every(c => c.pristine)
            )
          ) {
            await this.saveOffer();
          }
          this._applicantsForm.removeAt(
            this._applicants.findIndex(_applicant => _applicant.get('_id').value === applicantId)
          );
          this.relationsToLeadApplicant.removeAt(
            this.relationsToLeadApplicant.getRawValue().findIndex(relation => relation.contactId === applicantId)
          );
          this.applicants.patchValue(this.applicants.value.filter(a => a !== applicantId));
          await this.patch(this.offer._id, {
            $pull: { applicants: applicantId, relationsToLeadApplicant: { contactId: applicantId } },
          });
          this._propertiesForm.updateValueAndValidity();
          this._householdsForm.updateValueAndValidity();
          this.properties.updateValueAndValidity();
          this.households.updateValueAndValidity();
          this.mainService.hideLoading();
        }
      });
  }

  public applicantHasInsurance(applicantId) {
    return this.offerForm.get('insuranceApplicantsId').value.includes(applicantId);
  }

  public applicantInsurance(applicantId, value) {
    const insuranceApplicantsId = this.offerForm.get('insuranceApplicantsId').value;
    if (value.checked) {
      if (!insuranceApplicantsId.includes(applicantId)) {
        insuranceApplicantsId.push(applicantId);
      }
    } else {
      if (insuranceApplicantsId.includes(applicantId)) {
        insuranceApplicantsId.splice(insuranceApplicantsId.indexOf(applicantId), 1);
      }
    }
    this.offerForm.get('insuranceApplicantsId').patchValue(insuranceApplicantsId);
    this.offerForm.get('insuranceApplicantsId').markAsDirty();
  }

  public selectLeadApplicant(applicantId: string) {
    if (applicantId) {
      this.dialogsService
        .confirm('Hlavní žadatel', 'Chcete tohoto žadatele nastavit jako hlavního?')
        .subscribe(async confirmed => {
          if (confirmed) {
            this.offerForm.get('leadApplicantId').patchValue(applicantId);
            this.saveOffer();
          }
        });
    }
  }

  public async setPrimaryContact(applicantId: string) {
    if (applicantId) {
      this.dialogsService
        .confirm('Kontaktní osoba', 'Chcete tohoto žadatele nastavit jako kontaktní osobu?')
        .subscribe(confirmed => {
          if (confirmed) {
            const applicants = this.offerForm.get('applicants').value;
            const primaryIndex = applicants.indexOf(applicantId);
            const primaryApplicantControl = this._applicantsForm.at(primaryIndex);
            this._applicantsForm.removeAt(primaryIndex);
            this._applicantsForm.insert(0, primaryApplicantControl);
            applicants.splice(primaryIndex, 1);
            applicants.unshift(applicantId);
            this.saveOffer();
          }
        });
    }
  }

  copyAddress(control: any, field: string) {
    const destinationControl = control.get(field);
    const sourceContactId = this.offerForm.get('applicants').value[0];
    const contactIndex = this._applicantsForm.value.findIndex(c => c._id === sourceContactId);
    destinationControl.patchValue(this._applicantsForm.at(contactIndex).get(field).value);
    destinationControl.markAsDirty();
  }

  public createRelationToLeadApplicantForm(relation, isLeadApplicant = false) {
    const relationForm = this.fb.group({
      type: [null],
      contactId: [null],
    });
    if (!isLeadApplicant && this.isStageBetween(this.offer.stage, 'applicationDocuments')) {
      relationForm.get('type').setValidators([Validators.required]);
      relationForm.get('type').updateValueAndValidity();
    }
    if (relation) {
      relationForm.patchValue(relation);
    }
    return relationForm;
  }

  // DRAWDOWNS

  public createDrawdownForm(drawdown) {
    const drawdownForm = this.fb.group({
      amount: [null],
      amountPercent: [null],
      dateFrom: [null],
      dateUntil: [null],
      drawnOn: [null],
      isPurposeful: [true],
    });
    drawdownForm.get('amount').valueChanges.subscribe((value: any) => {
      if (value) {
        const percent = this.getFinalVariant(this.offerForm.value)
          ? Math.round(
              (this.mainService.unmaskCurrency(value) /
                this.mainService.unmaskCurrency(this.getFinalVariant(this.offerForm.value).totalAmount)) *
                100
            )
          : 0;
        drawdownForm.get('amountPercent').setValue(percent);
      }
    });
    if (this.isStageBetween(this.offer.stage, 'signed') && this.hasFinal(this.offer)) {
      drawdownForm.get('dateFrom').setValidators([Validators.required]);
      drawdownForm.get('dateFrom').updateValueAndValidity();
      drawdownForm.get('dateUntil').setValidators([Validators.required]);
      drawdownForm.get('dateUntil').updateValueAndValidity();
    }
    if (drawdown) {
      drawdownForm.patchValue(drawdown);
    }
    return drawdownForm;
  }

  public addDrawdown() {
    const newDrawdown = {
      amount: this.mainService.unmaskCurrency(this.offerForm.get('amount').value),
      dateFrom: null,
      dateUntil: null,
    };
    if (this.drawdowns.controls.length) {
      const drawdownAmount = this.drawdowns.controls.reduce((value, drawdown) => {
        value += this.mainService.unmaskCurrency(drawdown.get('amount').value);
        return value;
      }, 0);
      newDrawdown.amount = drawdownAmount < newDrawdown.amount ? newDrawdown.amount - drawdownAmount : 0;
    }
    this.drawdowns.push(this.createDrawdownForm(newDrawdown));
    this.offerForm.markAllAsTouched();
    this.offerForm.markAsDirty();
  }

  public removeDrawdown(dIndex) {
    this.dialogsService.confirm('Smazat čerpání', 'Chcete opravdu smazat toto čerpání?').subscribe(confirm => {
      if (confirm) {
        this.drawdowns.removeAt(dIndex);
        this.offerForm.markAsDirty();
      }
    });
  }

  public drawdownPercent() {
    if (this.isStageBetween(this.offer?.stage, 'signed') && this.hasFinal(this.offer)) {
      const finalVariant = this.getFinalVariant(this.offer);
      return this.drawdowns && this.drawdowns.controls && this.drawdowns.controls.length
        ? Math.round(
            (this.drawdowns.controls.reduce((value, drawdown) => {
              value += drawdown ? this.mainService.unmaskCurrency(drawdown.get('amount').value) : 0;
              return value;
            }, 0) /
              finalVariant.totalAmount) *
              100
          )
        : 0;
    } else {
      return 0;
    }
  }

  public drawdownsValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      return this.drawdownPercent() > 100 ? { drawdownAmount: { value: true } } : null;
    };
  }

  // PROPERTY

  public createPropertyForm(property) {
    const propertyForm = this.fb.group(
      {
        _id: [],
        status: [[]],
      },
      { validators: [this.propertyUsageValidator()] }
    );
    if (property) {
      propertyForm.patchValue(property);
    }
    return propertyForm;
  }

  public propertyUsageValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const status = control.get('status').value;
      if (!status || status?.length === 0) {
        return { propertyUsage: true };
      }
      return null;
    };
  }

  public propertyStatusUpdate(control, type, value) {
    if (value.checked) {
      if (type === 'none') {
        control.get('status').patchValue([type]);
      } else {
        const newValue = [...control.get('status').value, type];
        const index = newValue.indexOf('none');
        if (index >= 0) {
          newValue.splice(index, 1);
        }
        control.get('status').patchValue(newValue);
      }
    } else {
      if (type !== 'none') {
        let newValue = control.get('status').value;
        if (newValue.length === 1) {
          newValue = [];
        } else {
          newValue.splice(
            control.get('status').value.findIndex(s => s === type),
            1
          );
        }
        control.get('status').patchValue(newValue);
      } else {
        control.get('status').patchValue([]);
      }
    }
    control.markAsDirty();
  }

  public async addNewProperty() {
    this.mainService.showLoading();
    if (
      !(
        this.offerForm.pristine &&
        this._applicantsForm.controls.every(c => c.pristine) &&
        this._householdsForm.controls.every(c => c.pristine) &&
        this._propertiesForm.controls.every(c => c.pristine)
      )
    ) {
      await this.saveOffer();
    }
    const newProperty = await this.propertiesService.create({ offer: this.offer._id, contacts: this.applicants.value });
    console.log('New property properties');
    this.properties.push(this.createPropertyForm({ _id: newProperty._id, status: [] }));
    console.log('New property _properties');
    this._propertiesForm.push(this.propertiesService.createForm(newProperty, this.offer.stage, this.offerForm));
    let applicantProperty: any;
    for (const _applicantForm of this._applicantsForm.controls) {
      applicantProperty = this.contactsService.createPropertyForm(newProperty);
      (_applicantForm.get('properties') as FormArray).push(applicantProperty);
    }
    if (applicantProperty) {
      this.applicantsProperties.push(applicantProperty.value);
    }
    this._propertiesForm.markAllAsTouched();
    this.properties.markAllAsTouched();
    this.mainService.hideLoading();
  }

  public addProperty(property) {
    this.properties.push(this.createPropertyForm({ _id: property._id, status: [] }));
    this._propertiesForm.push(this.propertiesService.createForm(property, this.offer.stage, this.offerForm));
    this.offerForm.get('properties').markAsDirty();
    this._propertiesForm.markAllAsTouched();
    this.properties.markAllAsTouched();
  }

  public removeProperty(property) {
    this.dialogsService.confirm('Odebrat nemovitost', 'Chcete opravdu odebrat tuto nemovitost?').subscribe(confirm => {
      if (confirm) {
        this.properties.removeAt(
          this.properties.controls.findIndex(p => p.get('_id').value === property.get('_id').value)
        );
        this._propertiesForm.removeAt(
          this._propertiesForm.controls.findIndex(p => p.get('_id').value === property.get('_id').value)
        );
        this.offerForm.markAsDirty();
      }
    });
  }

  public hasOneFunded(properties) {
    return properties.value.filter(p => p.status.includes('funded')).length <= 1;
  }

  public propertyType(offer) {
    return (
      this.offer._properties?.find(pp => pp?._id === this.offer.properties?.find(p => p.status.includes('funded'))?._id)
        ?.type || null
    );
  }

  public propertyOfferControl(propertyId) {
    return this.properties.at(this.properties.controls.findIndex(p => p.value._id === propertyId));
  }

  public propertyUsage(control, short = false) {
    const usages = [];
    const status = control.get('status').value;
    if (status.includes('pledged')) {
      usages.push(short ? 'Z' : 'Zastavovaná');
    }
    if (this.propertyHasOwner(control.get('_id').value)) {
      usages.push(short ? 'V' : 'Vlastněná');
    }
    if (status.includes('funded')) {
      usages.push(short ? 'F' : 'Financovaná');
    }
    if (status.includes('none')) {
      usages.push(short ? 'N' : 'Nevstupuje do úvěru');
    }
    return usages.join(', ');
  }

  public updatePropertyApplicant(propertyId, contactId, value) {
    (this._applicants.find(a => a.get('_id').value === contactId)?.get('properties') as FormArray).controls
      .find(ap => ap.get('_id').value === propertyId)
      ?.get('isOwner')
      .patchValue(value.checked);
    this.properties.controls.map(p => p.updateValueAndValidity());
    this._applicants.find(a => a.get('_id').value === contactId).markAsDirty();
  }

  public propertyHasOwner(propertyId) {
    return this._applicants.some(a => a.get('properties').value.find(p => p._id === propertyId)?.isOwner);
  }

  public isPropertyOwner(propertyId, applicantId) {
    return this._applicants
      .find(a => a.get('_id').value === applicantId)
      ?.get('properties')
      ?.value.find(p => p._id === propertyId)?.isOwner;
  }

  // HOUSEHOLD

  public householdHasApplicant(householdId, applicantId) {
    return this._applicants.find(a => a.value._id === applicantId).value.households.includes(householdId);
  }

  public otherHouseholdHasApplicant(applicantId, householdId?) {
    const _applicant = this._applicants.find(a => a.get('_id').value === applicantId).value;
    const applicantHouseholds = _applicant.households.filter(
      ah => this.households.value.includes(ah) && ah !== householdId
    );
    return !!applicantHouseholds.length;
  }

  public requireAllApplicantsHousehold(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      let all = true;
      for (const _applicant of this._applicants) {
        if (!_applicant.get('households').value.some(ah => control?.value?.includes(ah))) {
          all = false;
        }
      }
      return all ? null : { requireAllApplicantsHousehold: { value: false } };
    };
  }

  public updateHouseholdApplicant(householdId, contactId, value) {
    const households = this._applicants.find(a => a.get('_id').value === contactId)?.get('households').value;
    if (value.checked) {
      households.push(householdId);
    } else {
      const hIndex = households.indexOf(householdId);
      if (hIndex > -1) {
        households.splice(hIndex, 1);
      }
    }
    this._applicants
      .find(a => a.get('_id').value === contactId)
      ?.get('households')
      .patchValue(households);
    this.contactsService.patch(contactId, { households });
    this.households.updateValueAndValidity();
    this._applicants.find(a => a.get('_id').value === contactId).markAsDirty();
  }

  public async addNewHousehold() {
    this.mainService.showLoading();
    if (
      !(
        this.offerForm.pristine &&
        this._applicantsForm.controls.every(c => c.pristine) &&
        this._householdsForm.controls.every(c => c.pristine) &&
        this._propertiesForm.controls.every(c => c.pristine)
      )
    ) {
      await this.saveOffer();
    }
    const _applicants = this._applicants.filter(a => !a.get('households')?.value?.length);
    const newHousehold = await this.householdsService.create({
      offer: this.offer._id,
      contacts: _applicants.map(a => a.get('_id').value),
    });
    this._householdsForm.push(this.householdsService.createForm(newHousehold, this.offer.stage, this._applicantsForm));
    this.households.patchValue([...this.households.value, newHousehold._id]);
    for (const _applicantForm of _applicants) {
      _applicantForm.get('households').patchValue([..._applicantForm.get('households').value, newHousehold._id]);
    }
    if (_applicants.length) {
      this.applicantsHouseholds.push(newHousehold);
    }
    this._householdsForm.markAllAsTouched();
    this.households.markAllAsTouched();
    this.mainService.hideLoading();
  }

  public addHousehold(household) {
    this._householdsForm.push(this.householdsService.createForm(household, this.offer.stage, this._applicantsForm));
    this.households.patchValue([...this.households.value, household._id]);
    this.households.markAsDirty();
    this._householdsForm.markAllAsTouched();
    this.households.markAllAsTouched();
  }

  public removeHousehold(household) {
    this.dialogsService.confirm('Odebrat domácnost', 'Chcete opravdu odebrat tuto domácnost?').subscribe(confirm => {
      if (confirm) {
        const households = this.households.value;
        households.splice(this.households.value.indexOf(household.get('_id').value), 1);
        this.households.patchValue(households);
        this.households.updateValueAndValidity();
        this._householdsForm.removeAt(
          this._householdsForm.controls.findIndex(p => p.get('_id').value === household.get('_id').value)
        );
        this.offerForm.markAsDirty();
      }
    });
  }

  // MODELATIONS

  public createModelationForm(modelation) {
    const modelationForm = this.fb.group({
      _id: [undefined],
      amount: [0, Validators.required],
      fixation: [0, [Validators.required]],
      maturity: [0, [Validators.required, Validators.pattern('[0-9]*'), Validators.min(1), Validators.max(480)]],
      maturityYears: [0],
      maturityMonths: [0],
      insurance: [false],
      offsetType: ['none', this.mainService.requireSelectionValidator('offsetType')],
      repayExpendituresId: [[]],
      purposes: this.fb.array([]),
      variants: this.fb.array([]),
      ltv: [0],
    });
    modelationForm.get('amount').valueChanges.subscribe((amount: any) => {
      const value = this.mainService.unmaskCurrency(this.offerForm.get('value').value);
      amount = this.mainService.unmaskCurrency(amount);
      modelationForm.get('ltv').setValue(amount && value ? Math.ceil((amount / value) * 100) : 0);
    });
    this.offerForm.get('value').valueChanges.subscribe((value: any) => {
      const amount = this.mainService.unmaskCurrency(modelationForm.get('amount').value);
      value = this.mainService.unmaskCurrency(value);
      modelationForm.get('ltv').setValue(amount && value ? Math.ceil((amount / value) * 100) : 0);
    });
    modelationForm.get('maturityYears').valueChanges.subscribe((value: any) => {
      const mYears = parseInt(value, null) || 0;
      const mMonths = parseInt(modelationForm.get('maturityMonths').value, null) || 0;
      modelationForm.get('maturity').patchValue(mYears * 12 + mMonths);
    });
    modelationForm.get('maturityMonths').valueChanges.subscribe((value: any) => {
      const mMonths = parseInt(value, null) || 0;
      const mYears = parseInt(modelationForm.get('maturityYears').value, null) || 0;
      modelationForm.get('maturity').patchValue(mYears * 12 + mMonths);
    });
    if (modelation) {
      const mMonths = (modelation.maturity || 0) % 12;
      const mYears = Math.floor((modelation.maturity || 0) / 12);
      modelationForm.get('_id').patchValue(modelation._id);
      modelationForm.get('amount').patchValue(modelation.amount);
      modelationForm.get('fixation').patchValue(modelation.fixation);
      modelationForm.get('maturity').patchValue(modelation.maturity);
      modelationForm.get('maturityMonths').patchValue(mMonths);
      modelationForm.get('maturityYears').patchValue(mYears);
      modelationForm.get('insurance').patchValue(modelation.insurance);
      modelationForm.get('repayExpendituresId').patchValue(modelation.repayExpendituresId);
      modelationForm.get('offsetType').patchValue(modelation.offsetType ? modelation.offsetType : 'none');
      for (const purpose of modelation.purposes || []) {
        (modelationForm.get('purposes') as FormArray).push(this.createModelationPurposeForm(purpose, modelationForm));
      }
      for (const variant of modelation.variants || []) {
        (modelationForm.get('variants') as FormArray).push(this.createVariantForm(variant));
      }
    }
    return modelationForm;
  }

  public getModelationIndex(modelationId) {
    return (this.modelations.value.findIndex(m => m._id === modelationId) || 0) + 1;
  }

  public modelationsDrop(event: CdkDragDrop<{ modelation: any }[]>) {
    if (event.previousIndex !== event.currentIndex) {
      const dir = event.currentIndex > event.previousIndex ? 1 : -1;

      const from = event.previousIndex;
      const to = event.currentIndex;

      const temp = this.modelations.at(from);
      for (let i = from; i * dir < to * dir; i = i + dir) {
        const current = this.modelations.at(i + dir);
        this.modelations.setControl(i, current);
      }
      this.modelations.setControl(to, temp);
      if (this.modelationIndex === event.previousIndex) {
        this.modelationIndex = event.currentIndex;
      } else {
        if (
          !(
            (this.modelationIndex > event.previousIndex && this.modelationIndex > event.currentIndex) ||
            (this.modelationIndex < event.previousIndex && this.modelationIndex < event.currentIndex)
          )
        ) {
          this.modelationIndex =
            event.previousIndex > event.currentIndex ? this.modelationIndex + 1 : this.modelationIndex - 1;
        }
      }
      this.modelations.markAsDirty();
    }
  }

  public async addModelation(modelation = null, insurance = false) {
    if (
      !(
        this.offerForm.pristine &&
        this._applicantsForm.controls.every(c => c.pristine) &&
        this._householdsForm.controls.every(c => c.pristine) &&
        this._propertiesForm.controls.every(c => c.pristine)
      )
    ) {
      await this.saveOffer();
    }
    if (!modelation) {
      modelation =
        this.modelations.length && this.modelations.at(this.modelations.length - 1)
          ? (this.modelations.at(this.modelations.length - 1) as FormGroup).getRawValue()
          : {
              amount: this.offer.amount,
              fixation: this.offer.fixation,
              maturity: this.offer.maturity,
              insurance,
              offsetType: 'none',
              variants: [],
              ltv: 0,
            };
      delete modelation._id;
      modelation.variants = [];
    }
    modelation.amount = this.mainService.unmaskCurrency(modelation.amount);
    modelation.insurance = insurance;
    this.mainService.showLoading();
    const updatedOffer = await this.patch(this.offer._id, { $push: { modelations: modelation } });
    this.modelations.push(this.createModelationForm(updatedOffer.modelations.slice(-1)[0]));
    this.mainService.hideLoading();
  }

  public removeModelation(mIndex) {
    return new Promise(resolve => {
      this.dialogsService.confirm('Smazat modelaci', 'Chcete opravdu smazat tuto modelaci?').subscribe(confirm => {
        if (confirm) {
          this.modelations.removeAt(mIndex);
          this.offerForm.markAsDirty();
          if (this.modelationIndex === mIndex) {
            this.modelationIndex = this.modelationIndex > 0 ? this.modelationIndex - 1 : this.modelationIndex;
          } else {
            this.modelationIndex = this.modelationIndex > mIndex ? this.modelationIndex - 1 : this.modelationIndex;
          }
        }
        resolve(null);
      });
    });
  }

  public updateModelationAmount(modelation: FormGroup) {
    const mod = modelation.getRawValue();
    modelation
      .get('amount')
      .patchValue(mod.purposes.reduce((acc, purpose) => acc + this.mainService.unmaskCurrency(purpose.amount), 0));
  }

  // MODELATION PURPOSE

  public createModelationPurposeForm(purpose, modelation) {
    const purposeForm = this.fb.group({
      type: [null],
      amount: [0],
    });
    purposeForm.get('amount').valueChanges.subscribe(value => {
      this.updateModelationAmount(modelation);
    });
    if (purpose) {
      purposeForm.patchValue(purpose, { emitEvent: false });
    }
    return purposeForm;
  }

  // VARIANT

  public createVariantForm(variant) {
    const variantForm = this.fb.group({
      id: [null],
      isFinal: [false],
      isConfirmed: [false],
      isCandidate: [false],
      isHidden: [false],
      dti: [0, this.mainService.maxValueValidator(9, 'float')],
      dsti: [0, this.mainService.maxValueValidator(0.45, 'percent')],
      totalRepayment: [0],
      totalAmount: [0],
      totalFees: [0],
      totalFeesOnetime: [0],
      totalPayment: [0],
      products: this.fb.array([]),
      estimateOrderedAt: [],
      interestRateGuaranteeFrom: [],
      interestRateGuaranteeTo: [],
      benefitsTotalAmount: [0],
      benefitsBankingTotalAmount: [0],
      benefits: this.fb.array([]),
    });
    if (this.isStageBetween(this.offer.stage, 'applicationSent')) {
      variantForm.get('isConfirmed').valueChanges.subscribe(async (value: any) => {
        if (!value && !variantForm.get('isFinal').value) {
          variantForm.get('interestRateGuaranteeFrom').clearValidators();
        } else {
          variantForm.get('interestRateGuaranteeFrom').setValidators([Validators.required]);
        }
        variantForm.get('interestRateGuaranteeFrom').updateValueAndValidity();
      });
      variantForm.get('isFinal').valueChanges.subscribe(async (value: any) => {
        if (!value && !variantForm.get('isConfirmed').value) {
          variantForm.get('interestRateGuaranteeFrom').clearValidators();
        } else {
          variantForm.get('interestRateGuaranteeFrom').setValidators([Validators.required]);
        }
        variantForm.get('interestRateGuaranteeFrom').updateValueAndValidity();
      });
    }
    if (this.isStageBetween(this.offer.stage, 'approved')) {
      variantForm.get('isFinal').valueChanges.subscribe(async (value: any) => {
        for (const productForm of (variantForm.get('products') as FormArray).controls) {
          if (value) {
            productForm.get('contractNumber').setValidators([Validators.required]);
          } else {
            productForm.get('contractNumber').clearValidators();
          }
          productForm.get('contractNumber').updateValueAndValidity();
        }
      });
    }
    if (this.isStageBetween(this.offer.stage, 'signed')) {
      variantForm.get('isFinal').valueChanges.subscribe(async (value: any) => {
        for (const productForm of (variantForm.get('products') as FormArray).controls) {
          if (value) {
            productForm.get('meetingRecordSignedAt').setValidators([Validators.required]);
          } else {
            productForm.get('meetingRecordSignedAt').clearValidators();
          }
          productForm.get('meetingRecordSignedAt').updateValueAndValidity();
        }
      });
    }
    variantForm.get('interestRateGuaranteeFrom').valueChanges.subscribe(async (value: any) => {
      if (value) {
        const date = new Date(value);
        if (date) {
          date.setDate(
            date.getDate() +
              (variantForm.get('products').value[0].source.provider.methodology?.interestRate?.interestRateGuarantee ||
                0)
          );
          variantForm.get('interestRateGuaranteeTo').patchValue(date);
        }
      } else {
        variantForm.get('interestRateGuaranteeTo').patchValue(null);
      }
    });
    variantForm.get('isConfirmed').valueChanges.subscribe(async (value: any) => {
      if (!value && !variantForm.get('isFinal').value) {
        variantForm.get('interestRateGuaranteeFrom').patchValue(null, { emitEvent: false });
        variantForm.get('interestRateGuaranteeTo').patchValue(null, { emitEvent: false });
      }
    });
    variantForm.get('products').valueChanges.subscribe(async (value: any) => {
      const productForm = (variantForm.get('products') as FormArray).at(0);
      this.productBenefitInterestRate(
        productForm,
        (variantForm.get('benefits').value || []).find(i => i.type === 'interestRateCompensation')?.amount || 0
      );
    });
    variantForm.get('isFinal').valueChanges.subscribe(async (value: any) => {
      if (!value && !variantForm.get('isConfirmed').value) {
        variantForm.get('interestRateGuaranteeFrom').patchValue(null, { emitEvent: false });
        variantForm.get('interestRateGuaranteeTo').patchValue(null, { emitEvent: false });
      }
    });
    variantForm.get('benefits').valueChanges.subscribe(async (value: any) => {
      const productForm = (variantForm.get('products') as FormArray).at(0);
      variantForm.get('benefitsTotalAmount').patchValue(
        (value || []).reduce((total, item) => {
          total += this.mainService.unmaskCurrency(item.amount || 0);
          return total;
        }, 0)
      );
      variantForm.get('benefitsBankingTotalAmount').patchValue(
        (value || [])
          .filter(i => !this.settingsService.options['benefit'].find(o => o.value === i.type)?.excludedFromBanking)
          .reduce((total, item) => {
            total += this.mainService.unmaskCurrency(item.amount || 0);
            return total;
          }, 0)
      );
      this.productBenefitInterestRate(
        productForm,
        (value || []).find(i => i.type === 'interestRateCompensation')?.amount || 0
      );
    });
    variantForm.get('totalPayment').valueChanges.subscribe(async (value: any) => {
      if (variantForm.get('products').value.length > 0) {
        variantForm.get('totalRepayment').setValue(
          variantForm.get('products').value.reduce((acc, product) => {
            acc += this.mainService.unmaskCurrency(product.repayment)
              ? this.mainService.unmaskCurrency(product.repayment)
              : 0;
            return acc;
          }, 0)
        );
        variantForm.get('totalAmount').setValue(
          variantForm.get('products').value.reduce((acc, product) => {
            acc += this.mainService.unmaskCurrency(product.amount)
              ? this.mainService.unmaskCurrency(product.amount)
              : 0;
            return acc;
          }, 0)
        );
        variantForm.get('totalFees').setValue(
          Math.round(
            variantForm.get('products').value.reduce((acc, product) => {
              acc += product.totalFees ? product.totalFees : 0;
              return acc;
            }, 0)
          )
        );
        const totalFeesOnetime = Math.round(
          variantForm.get('products').value.reduce((acc, product) => {
            acc += product.totalFeesOnetime ? product.totalFeesOnetime : 0;
            return acc;
          }, 0)
        );
        const benefitsTotalAmount = variantForm
          .get('benefits')
          .value.filter(
            i => !this.settingsService.options['benefit'].find(o => o.value === i.type)?.excludedFromBanking
          )
          .reduce((total, item) => {
            total += this.mainService.unmaskCurrency(item.amount || 0);
            return total;
          }, 0);
        variantForm.get('totalFeesOnetime').setValue(totalFeesOnetime - benefitsTotalAmount);
        variantForm.get('totalPayment').setValue(
          Math.round(
            variantForm.get('products').value.reduce((acc, product) => {
              acc += product.totalPayment ? product.totalPayment : 0;
              return acc;
            }, 0)
          ),
          { emitEvent: false }
        );
        const source = (variantForm.get('products') as FormArray).at(0).get('source').value;
        if (source) {
          const dtiTotal = await this.productsService.getDti({
            type: source.type,
            providerId: source.provider._id,
            offerId: this.offer._id,
            repayment: variantForm.get('totalRepayment').value,
            amount: variantForm.get('totalAmount').value,
          });
          variantForm.get('dti').setValue(this.mainService.maskFloat(dtiTotal.dti));
          variantForm.get('dsti').setValue(this.mainService.maskPercent(dtiTotal.dsti));
          variantForm.get('dti').markAsTouched();
          variantForm.get('dsti').markAsTouched();
        }
      }
    });
    if (variant) {
      variantForm.get('isHidden').patchValue(variant.isHidden);
      variantForm.get('isCandidate').patchValue(variant.isCandidate);
      variantForm.get('isConfirmed').setValue(variant.isConfirmed);
      variantForm.get('isFinal').setValue(variant.isFinal);
      variantForm.get('dti').setValue(this.mainService.maskFloat(variant.dti), { emitEvent: false });
      variantForm.get('dsti').setValue(this.mainService.maskPercent(variant.dsti), { emitEvent: false });
      variantForm.get('totalRepayment').setValue(variant.totalRepayment, { emitEvent: false });
      variantForm.get('totalAmount').setValue(variant.totalAmount, { emitEvent: false });
      variantForm.get('totalFees').setValue(variant.totalFees, { emitEvent: false });
      variantForm.get('totalFeesOnetime').setValue(variant.totalFeesOnetime, { emitEvent: false });
      variantForm.get('totalPayment').setValue(variant.totalPayment, { emitEvent: false });
      variantForm.get('interestRateGuaranteeFrom').patchValue(variant.interestRateGuaranteeFrom, { emitEvent: false });
      variantForm.get('interestRateGuaranteeTo').patchValue(variant.interestRateGuaranteeTo, { emitEvent: false });
      variantForm.get('estimateOrderedAt').patchValue(variant.estimateOrderedAt, { emitEvent: false });
      for (const product of variant.products || []) {
        (variantForm.get('products') as FormArray).push(this.createProductForm(product, variantForm));
      }
      for (const benefit of variant.benefits || []) {
        (variantForm.get('benefits') as FormArray).push(this.createBenefitForm(benefit));
      }
      const productForm = (variantForm.get('products') as FormArray).at(0);
      this.productBenefitInterestRate(
        productForm,
        (variant.benefits || []).find(i => i.type === 'interestRateCompensation')?.amount || 0
      );
    }
    variantForm.get('id').setValue(this.randomId(null, 'variant')); // TODO tohle udelat nove ze se vytvori varianta v modelaci a budem mit ID, vytvori se skryta
    return variantForm;
  }

  public createBenefitForm(benefit: any) {
    const benefitForm = this.fb.group({
      type: [null],
      amount: [0],
    });
    if (benefit) {
      benefitForm.patchValue(benefit);
    }
    return benefitForm;
  }

  public addBenefit(variant: FormGroup) {
    (variant.get('benefits') as FormArray).push(this.createBenefitForm(null));
    variant.markAsDirty();
  }

  public removeBenefit(variant: FormGroup, bIndex) {
    (variant.get('benefits') as FormArray).removeAt(bIndex);
    variant.markAsDirty();
  }

  public benefitsType(variant: FormGroup) {
    return variant.get('benefits').value.map((b: any) => b.type);
  }

  public unmaskVariant(variant: any) {
    variant.dti = this.mainService.unmaskFloat(variant.dti);
    variant.dsti = this.mainService.unmaskPercent(variant.dsti);
    variant.totalRepayment = this.mainService.unmaskCurrency(variant.totalRepayment);
    variant.totalAmount = this.mainService.unmaskCurrency(variant.totalAmount);
    variant.totalFees = this.mainService.unmaskCurrency(variant.totalFees);
    variant.totalFeesOnetime = this.mainService.unmaskCurrency(variant.totalFeesOnetime);
    variant.totalPayment = this.mainService.unmaskCurrency(variant.totalPayment);
    for (const benefit of variant.benefits) {
      benefit.amount = this.mainService.unmaskCurrency(benefit.amount);
    }
    for (const product of variant.products) {
      product.arp = this.mainService.unmaskPercent(product.arp);
      product.repayment = this.mainService.unmaskCurrency(product.repayment);
      product.averageAccountBalance = this.mainService.unmaskCurrency(product.averageAccountBalance);
      product.amount = this.mainService.unmaskCurrency(product.amount);
      product.interestRateAmount = this.mainService.unmaskCurrency(product.amount);
      product.interestRate = this.mainService.unmaskPercent(product.interestRate);
      product.effectiveInterestRate = this.mainService.unmaskPercent(product.effectiveInterestRate);
      product.benefitInterestRate = this.mainService.unmaskPercent(product.benefitInterestRate);
      product.depositInterestRate = this.mainService.unmaskPercent(product.depositInterestRate);
      product.minimumInterestRate = this.mainService.unmaskPercent(product.minimumInterestRate);
      product.maximumLoanAmount = this.mainService.unmaskCurrency(product.maximumLoanAmount);
      product.totalFeesOnetime = this.mainService.unmaskCurrency(product.totalFeesOnetime);
      product.totalFeesMonthly = this.mainService.unmaskCurrency(product.totalFeesMonthly);
      product.totalFeesAnnualy = this.mainService.unmaskCurrency(product.totalFeesAnnualy);
      for (const fee in product.fees) {
        if (product.fees[fee]) {
          product.fees[fee] = this.mainService.unmaskCurrency(product.fees[fee]);
        }
      }
    }
    return variant;
  }

  public availableVariantProducts(variant) {
    const productTemplates = [];
    const productIndex = variant ? variant.get('products').value.length : 0;
    for (const productsTemplate of this.settingsService.settings.productTemplates) {
      if (
        productsTemplate[productIndex] &&
        !productTemplates.find(pt => this.mainService.isEquivalent(pt.filter, productsTemplate[productIndex].filter)) &&
        this.canAddProduct(productsTemplate[productIndex], variant)
      ) {
        productTemplates.push(productsTemplate[productIndex]);
      }
    }
    return productTemplates;
  }

  public async removeFees(mIndex = null) {
    this.dialogsService.fees().subscribe((fees: any) => {
      if (fees?.length) {
        if (Number.isInteger(mIndex)) {
          this.removeModelationFees(mIndex, fees);
        } else {
          if (confirm) {
            for (let mI = 0; mI < this.modelations.controls.length; mI++) {
              this.removeModelationFees(mI, fees);
            }
          }
        }
      }
    });
  }

  public removeModelationFees(mIndex, fees) {
    for (const vc of (this.modelations.at(mIndex).get('variants') as FormArray).controls) {
      for (const pc of (vc.get('products') as FormArray).controls) {
        for (const fk of Object.keys(pc.get('fees').value)) {
          if (fees.includes(fk)) {
            pc.get('fees.' + fk).patchValue(0);
          }
        }
      }
    }
    this.offerForm.markAsDirty();
  }

  public noFees(modelation) {
    const exclude = ['signatureVerification', 'insurance', 'drawdown'];
    return modelation.variants.every(v =>
      v.products.every(p =>
        Object.keys(p.fees)
          .filter(fk => !exclude.includes(fk))
          .every(fk => parseInt(p.fees[fk], null) === 0)
      )
    );
  }

  public async removeVariants(mIndex) {
    (this.modelations.at(mIndex).get('variants') as FormArray).controls = [];
    this.modelations.at(mIndex).get('variants').updateValueAndValidity();
    this.offerForm.markAsDirty();
  }

  public variantsDrop(event: CdkDragDrop<{ variant: any }[]>) {
    const dir = event.currentIndex > event.previousIndex ? 1 : -1;

    const from = event.previousIndex;
    const to = event.currentIndex;

    const temp = this.variants.at(from).value;
    for (let i = from; i * dir < to * dir; i = i + dir) {
      const current = this.variants.at(i + dir).value;
      this.variants.at(i).patchValue(current, { emitEvent: false });
    }
    this.variants.at(to).patchValue(temp, { emitEvent: false });
    this.offerForm.markAsDirty();
  }

  // PRODUCTS

  public createProductForm(product, variantForm?) {
    const productForm = this.fb.group(
      {
        contractNumber: [null],
        meetingRecordSignedAt: [null],
        offsetType: [null],
        productTemplate: [null],
        source: [null],
        sales: [[]],
        isCreditworthy: [true],
        interestRate: [0, Validators.required],
        effectiveInterestRate: [0],
        benefitInterestRate: [0],
        interestRateAmount: [0],
        depositInterestRate: [0, Validators.required],
        fixation: [0, Validators.required],
        maturity: [0, Validators.required],
        insurance: ['both'],
        minimumInterestRate: [0],
        maximumLoanAmount: [0, Validators.required],
        note: [null],
        attachment: [null],
        totalFeesOnetime: [0],
        totalFeesMonthly: [0],
        totalFeesAnnualy: [0],
        arp: [0],
        repayment: [0],
        repaymentTotal: [null],
        endFixationFees: [0],
        totalInterest: [0],
        totalFees: [0],
        totalPayment: [0],
        amount: [0],
        purpose: [[]],
        esexpress: [true],
        esonline: [true],
        ddexpress: [true],
        nolimits: [false],
        anotherFinancingSourceApply: [false],
        averageAccountBalance: [0],
        upSavings: [0],
        fees: this.fb.group({
          entryFee: [0, Validators.required],
          depositAccountManagement: [0, Validators.required],
          depositAccountStatement: [0, Validators.required],
          provision: [0, Validators.required],
          accountManagement: [0, Validators.required],
          accountStatement: [0, Validators.required],
          loanTerminationSatement: [0, Validators.required],
          estimate: [0, Validators.required],
          estimateOnline: [0, Validators.required],
          estimateExpress: [0, Validators.required],
          cadastre: [0, Validators.required],
          cadastreWithBenefit: [0],
          signatureVerification: [0, Validators.required],
          realEstateInsurance: [0, Validators.required],
          insurance: [0, Validators.required],
          drawdown: [0, Validators.required],
          drawdownExpress: [0, Validators.required],
        }),
      },
      { validators: [this.minRateValidator()] }
    );
    if (this.isStageBetween(this.offer.stage, 'approved') && variantForm?.get('isFinal').value) {
      productForm.get('contractNumber').setValidators([Validators.required]);
      productForm.get('contractNumber').updateValueAndValidity();
    }
    if (this.isStageBetween(this.offer.stage, 'signed') && variantForm?.get('isFinal').value) {
      productForm.get('meetingRecordSignedAt').setValidators([Validators.required]);
      productForm.get('meetingRecordSignedAt').updateValueAndValidity();
    }
    productForm.get('repayment').valueChanges.subscribe((value: any) => {
      productForm.get('interestRate').updateValueAndValidity({ onlySelf: false, emitEvent: true });
    });
    productForm.get('effectiveInterestRate').valueChanges.subscribe(async (value: any) => {
      await this.productBenefitInterestRate(productForm);
    });
    productForm.get('interestRate').valueChanges.subscribe(async (value: any) => {
      productForm.get('interestRate').setValue(value ? value.toString().replace('.', ',') : 0, { emitEvent: false });
      value = this.mainService.unmaskPercent(value);
      if (value) {
        // Načtení novejch poplatků na základě změny urokový sazby
        const originalProduct = productForm.getRawValue();
        let loadedProduct = await this.productsService.getProductWithRate(
          originalProduct.source._id,
          {
            offer: this.offer._id,
            productTemplate: originalProduct.productTemplate,
            amount: this.mainService.unmaskCurrency(originalProduct.amount),
            value: this.mainService.unmaskCurrency(this.offerForm.get('value').value),
            oldestApplicantAge: this.offerForm.get('oldestApplicantAge').value,
            averageAccountBalance: this.mainService.unmaskCurrency(originalProduct.averageAccountBalance || 0),
            purpose: originalProduct.purpose,
            insurance: originalProduct.insurance,
            maturity: originalProduct.maturity,
            fixation: originalProduct.fixation,
            esexpress: originalProduct.esexpress,
            esonline: originalProduct.esonline,
            ddexpress: originalProduct.ddexpress,
            type: originalProduct.source.type,
            nolimits: originalProduct.nolimits,
            sales: originalProduct.source.fixation?.appliedDiscounts?.map(ap => ap._id) || [],
            propertyType: this.propertyType(this.offerForm.value),
          },
          value.toFixed(4)
        );
        if (loadedProduct) {
          loadedProduct = this.processSelectedSourceProduct(loadedProduct);
          const updatedProduct = originalProduct.source;
          updatedProduct.fixation.fees = loadedProduct.fixation?.fees;
          productForm.get('source').setValue(updatedProduct);
        }
        // Konec načtení poplatků
        const fees = {};
        for (const fee in productForm.get('fees').value) {
          if (productForm.get('fees').value[fee]) {
            fees[fee] = this.mainService.unmaskCurrency(productForm.get('fees').value[fee]);
          }
        }
        const variantForm = productForm.parent?.parent;
        const benefits = variantForm.get('benefits').value || [];
        const sourceProduct = productForm.get('source').value;
        if (sourceProduct) {
          const totals = await this.productsService.getTotals({
            offerId: this.offer._id,
            type: sourceProduct.type,
            amount: productForm.get('amount').value,
            maturity: productForm.get('maturity').value,
            fixation: productForm.get('fixation').value,
            interestRate: value,
            providerId: sourceProduct.provider._id,
            isOffset: sourceProduct.isOffset,
            percentagePrincipalMax: sourceProduct.limits.percentagePrincipalMax,
            accountBalanceMax: sourceProduct.limits.accountBalanceMax,
            averageAccountBalance: this.mainService.unmaskCurrency(productForm.get('averageAccountBalance').value),
            fees,
            benefits,
            attachRepayment: true,
            repayment:
              sourceProduct.type === 'building_savings'
                ? this.mainService.unmaskCurrency(productForm.get('repayment').value)
                : 0, // TODO jen protože to neumíme na backendu zatim počítat
          });
          productForm.get('endFixationFees').setValue(totals['endFixationFees']);
          productForm.get('totalInterest').setValue(totals['totalInterest']);
          productForm.get('totalFees').setValue(totals['totalFees']);
          productForm.get('totalPayment').setValue(totals['totalPayment']);
          if (sourceProduct.type === 'mortgage') {
            productForm.get('repaymentTotal').setValue(totals['repaymentTotal']);
            productForm
              .get('effectiveInterestRate')
              .setValue(this.mainService.maskPercent(totals['repaymentTotal']['effectiveInterestRate']));
            productForm
              .get('repayment')
              .setValue(Math.round(totals['repaymentTotal']['repayment']), { emitEvent: false });
            productForm.get('arp').setValue(this.mainService.maskPercent(totals['arp']), { emitEvent: false });
            this.setProductDiscount(productForm);
          }
        }
        productForm.parent.parent.get('totalPayment').updateValueAndValidity({ onlySelf: false, emitEvent: true });
      }
    });
    productForm.get('arp').valueChanges.subscribe((value: any) => {
      productForm.get('arp').setValue(value ? value.toString().replace('.', ',') : 0, { emitEvent: false });
    });
    productForm.get('depositInterestRate').valueChanges.subscribe((value: any) => {
      productForm
        .get('depositInterestRate')
        .setValue(value ? value.toString().replace('.', ',') : 0, { emitEvent: false });
    });
    productForm.get('fees').valueChanges.subscribe(() => {
      if (productForm.get('source').value) {
        let totalFeesOnetime = 0;
        let totalFeesMonthly = 0;
        let totalFeesAnnualy = 0;
        for (const fee in productForm.get('fees').value) {
          if (productForm.get('fees').value[fee]) {
            if (this.settingsService.settings.monthlyFees.includes(fee)) {
              totalFeesMonthly += this.mainService.unmaskCurrency(productForm.get('fees').value[fee]);
            } else if (this.settingsService.settings.annualFees.includes(fee)) {
              totalFeesAnnualy += this.mainService.unmaskCurrency(productForm.get('fees').value[fee]);
            } else {
              totalFeesOnetime += this.mainService.unmaskCurrency(productForm.get('fees').value[fee]);
            }
          }
        }
        productForm.get('totalFeesOnetime').setValue(totalFeesOnetime);
        productForm.get('totalFeesMonthly').setValue(totalFeesMonthly);
        productForm.get('totalFeesAnnualy').setValue(totalFeesAnnualy);
        productForm.get('interestRate').updateValueAndValidity({ onlySelf: false, emitEvent: true });
      }
    });
    if (product) {
      productForm.get('contractNumber').setValue(product.contractNumber);
      productForm.get('meetingRecordSignedAt').setValue(product.meetingRecordSignedAt);
      productForm.get('offsetType').setValue(product.offsetType);
      productForm.get('productTemplate').setValue(product.productTemplate);
      productForm.get('source').setValue(product.source);
      productForm.get('isCreditworthy').setValue(product.isCreditworthy);
      productForm.get('esonline').setValue(product.esonline);
      productForm.get('esexpress').setValue(product.esexpress);
      productForm.get('ddexpress').setValue(product.ddexpress);
      productForm.get('nolimits').setValue(product.nolimits);
      productForm
        .get('anotherFinancingSourceApply')
        .setValue(product.anotherFinancingSourceApply === undefined || product.anotherFinancingSourceApply);
      productForm
        .get('averageAccountBalance')
        .setValue(this.mainService.maskCurrency(product.averageAccountBalance) || 0);
      productForm.get('purpose').setValue(product.purpose);
      productForm
        .get('effectiveInterestRate')
        .setValue(this.mainService.maskPercent(product.effectiveInterestRate), { emitEvent: false });
      productForm.get('benefitInterestRate').setValue(this.mainService.maskPercent(product.benefitInterestRate));
      productForm.get('depositInterestRate').setValue(this.mainService.maskPercent(product.depositInterestRate));
      productForm.get('insurance').setValue(product.insurance);
      productForm.get('fixation').setValue(product.fixation);
      productForm.get('maturity').setValue(product.maturity);
      productForm.get('arp').setValue(this.mainService.maskPercent(product.arp));
      productForm.get('repayment').setValue(product.repayment);
      productForm.get('amount').setValue(product.amount);
      productForm.get('note').setValue(product.note);
      productForm.get('attachment').setValue(product.attachment);
      productForm.get('fees').patchValue(product.fees);
      productForm.get('sales').patchValue(product.sales);
      productForm.get('minimumInterestRate').setValue(this.mainService.maskPercent(product.minimumInterestRate));
      productForm.get('maximumLoanAmount').setValue(product.maximumLoanAmount);
      productForm.get('upSavings').setValue(product.upSavings);
      if (product.source) {
        productForm
          .get('maximumLoanAmount')
          .setValidators([
            Validators.required,
            this.mainService.maxValueValidator(product.source.limits.loanAmountMax, 'currency'),
          ]);
        productForm.get('maximumLoanAmount').updateValueAndValidity();
      }
      productForm.get('interestRate').setValue(this.mainService.maskPercent(product.interestRate)); // Musí bejt poslední kvůli změnám
    }
    return productForm;
  }

  public async recalculateSource(productForm: FormGroup, sales?: any[]) {
    const originalProduct = productForm.getRawValue();
    let loadedProduct = await this.productsService.get(originalProduct.source._id, {
      query: this.productsService.createQuery({
        offer: this.offer._id,
        productTemplate: originalProduct.productTemplate,
        amount: this.mainService.unmaskCurrency(originalProduct.amount),
        value: this.mainService.unmaskCurrency(this.offerForm.get('value').value),
        oldestApplicantAge: this.offerForm.get('oldestApplicantAge').value,
        averageAccountBalance: this.mainService.unmaskCurrency(originalProduct.averageAccountBalance || 0),
        purpose: originalProduct.purpose,
        insurance: originalProduct.insurance,
        maturity: originalProduct.maturity,
        fixation: originalProduct.fixation,
        esexpress: originalProduct.esexpress,
        esonline: originalProduct.esonline,
        ddexpress: originalProduct.ddexpress,
        type: originalProduct.source.type,
        nolimits: originalProduct.nolimits,
        sales,
        propertyType: this.propertyType(this.offerForm.value),
      }),
    });
    loadedProduct.fixation = loadedProduct.fixations.find(
      f => f.years === parseInt(originalProduct.fixation, null) || f.years === 0
    );
    if (loadedProduct) {
      loadedProduct = this.processSelectedSourceProduct(loadedProduct);
      productForm.get('source').setValue(loadedProduct);
    }
    // Konec načtení poplatků
    const fees = {};
    for (const fee in productForm.get('fees').value) {
      if (productForm.get('fees').value[fee]) {
        fees[fee] = this.mainService.unmaskCurrency(productForm.get('fees').value[fee]);
      }
    }
  }

  public async recalculateSales(productForm: FormGroup, salesForm: NgForm) {
    const sales = [];
    for (const key of Object.keys(salesForm.form.value).filter(k => !k.includes('.value'))) {
      if (salesForm.form.value[key] === true) {
        if (key.includes('g.')) {
          sales.push(salesForm.form.value[key + '.value']);
        } else {
          sales.push(key);
        }
      }
    }
    const changeRate =
      productForm.get('source').value.fixation.interestRate.toFixed(4) ===
      this.mainService.unmaskPercent(productForm.get('interestRate').value).toFixed(4);
    await this.recalculateSource(productForm, sales);
    if (changeRate) {
      productForm
        .get('interestRate')
        .patchValue(this.mainService.maskPercent(productForm.get('source').value.fixation.interestRate));
    }
    this.offerForm.markAsDirty();
  }

  private setProductDiscount(productForm) {
    let discount = 0;
    const sourceProduct = productForm.get('source').value;
    if (sourceProduct) {
      for (const fee in sourceProduct.fixation.fees) {
        if (sourceProduct.fixation.fees[fee]) {
          if (
            !this.settingsService.settings.monthlyFees.includes(fee) &&
            !this.settingsService.settings.annualFees.includes(fee)
          ) {
            discount += sourceProduct.fixation.fees[fee];
          }
        }
      }
      discount -= this.mainService.unmaskCurrency(productForm.get('totalFeesOnetime').value);
      this.getDiscountInfo(
        sourceProduct.type,
        sourceProduct.fixation.interestRate,
        this.mainService.unmaskPercent(productForm.get('interestRate').value),
        productForm.get('maturity').value,
        productForm.get('amount').value,
        productForm.get('fixation').value,
        discount,
        sourceProduct.provider._id,
        sourceProduct.isOffset,
        sourceProduct.limits.percentagePrincipalMax,
        sourceProduct.limits.accountBalanceMax,
        this.mainService.unmaskCurrency(productForm.get('averageAccountBalance').value)
      ).then(info => {
        productForm.get('minimumInterestRate').setValue(this.mainService.maskPercent(info.minRate));
        productForm.get('interestRateAmount').setValue(this.mainService.maskCurrency(info.newAmount));
      });
    }
  }

  private async getDiscountInfo(
    type,
    interestRate,
    newInterestRate,
    maturity,
    amount,
    fixation,
    feesDiscount,
    providerId,
    isOffset,
    percentagePrincipalMax,
    accountBalanceMax,
    averageAccountBalance
  ) {
    let info = {
      minRate: 0,
      newAmount: 0,
    };
    if (amount && newInterestRate && interestRate && maturity && fixation) {
      info = await this.productsService.getDiscount({
        type,
        feesDiscount,
        interestRate,
        newInterestRate,
        providerId,
        maturity,
        fixation,
        amount,
        isOffset,
        percentagePrincipalMax,
        accountBalanceMax,
        averageAccountBalance,
      });
    }
    return info;
  }

  public processSelectedSourceProduct(selectedProduct) {
    delete selectedProduct.fixations;
    if (selectedProduct.fixation) {
      selectedProduct.fixation.repayment = Math.round(selectedProduct.fixation?.repayment);
      selectedProduct.fixation.fees.estimate = 0;
      const propertyType = this.propertyType(this.offerForm.value);
      if (propertyType === 'flatPrivate' || propertyType === 'flatCooperative') {
        selectedProduct.fixation.fees.estimate = selectedProduct.fixation.fees.estimateFlat;
      } else if (propertyType === 'familyHouse' || propertyType === 'newBuilding') {
        selectedProduct.fixation.fees.estimate = selectedProduct.fixation.fees.estimateHouse;
      } else if (propertyType === 'recreationalObject') {
        selectedProduct.fixation.fees.estimate = selectedProduct.fixation.fees.estimateRecreationalObject;
      } else if (propertyType === 'land') {
        selectedProduct.fixation.fees.estimate = selectedProduct.fixation.fees.estimateLand;
      } else if (propertyType === 'apartmentBuilding') {
        selectedProduct.fixation.fees.estimate = selectedProduct.fixation.fees.estimateApartmentBuilding;
      }
      delete selectedProduct.fixation.fees.estimateFlat;
      delete selectedProduct.fixation.fees.estimateHouse;
      delete selectedProduct.fixation.fees.estimateRecreationalObject;
      delete selectedProduct.fixation.fees.estimateLand;
      delete selectedProduct.fixation.fees.estimateApartmentBuilding;
    }
    return selectedProduct;
  }

  public canAddProduct(productTemplate, variant) {
    if (variant) {
      const productsTemplates = this.settingsService.settings.productTemplates;
      const searchTemplate = [];
      for (const product of variant.get('products').value) {
        searchTemplate.push(product.productTemplate.filter);
      }
      searchTemplate.push(productTemplate.filter);
      for (const productsTemplate of productsTemplates) {
        let foundTemplate = true;
        for (let searchIndex = 0; searchIndex < searchTemplate.length; searchIndex++) {
          if (
            !productsTemplate[searchIndex] ||
            !this.mainService.isEquivalent(searchTemplate[searchIndex], productsTemplate[searchIndex].filter)
          ) {
            foundTemplate = false;
          }
        }
        if (foundTemplate) {
          return true;
        }
      }
      return false;
    }
    return true;
  }

  public variantProducts(variant): FormArray {
    return variant.get('products') as FormArray;
  }

  public removeProduct(variant, productIndex) {
    this.dialogsService.confirm('Smazat produkt', 'Chcete opravdu smazat tento produkt?').subscribe(confirm => {
      if (confirm) {
        this.variantProducts(variant).removeAt(productIndex);
        variant.get('totalPayment').updateValueAndValidity({ onlySelf: false, emitEvent: true });
        this.variants.markAsDirty();
      }
    });
  }

  public addExpenditureToModelation(modelation, expenditureId, value) {
    const expendituresId = modelation.get('repayExpendituresId').value || [];
    if (value.checked) {
      expendituresId.push(expenditureId);
    } else {
      const expMIndex = expendituresId.findIndex(ei => ei === expenditureId);
      expendituresId.splice(expMIndex, 1);
    }
    modelation.get('repayExpendituresId').patchValue(expendituresId);
    modelation.markAsDirty();
  }

  public modelationExpenditures(modelation): any[] {
    const expenditures = [];
    let aIndex = 0;
    for (const _applicant of this._applicantsForm.getRawValue() || []) {
      aIndex++;
      for (const expenditure of _applicant.expenditures || []) {
        if (modelation.get('repayExpendituresId')?.value?.includes(expenditure._id)) {
          expenditures.push(
            `${_applicant.firstName} ${_applicant.lastName} (Žadatel ${aIndex}): ${this.mainService.optionLabel(this.settingsService.options['expenditureType'], expenditure.type)} (${expenditure.source.type === 'bank' ? this.mainService.optionLabel(this.settingsService.options['bank'], expenditure.source.name) : expenditure.source.name}) - ${this.mainService.maskCurrency(expenditure.balance)} Kč`
          );
        }
      }
    }
    return expenditures;
  }

  public unmaskOffer(offer) {
    offer.amount = this.mainService.unmaskCurrency(offer.amount);
    offer.value = this.mainService.unmaskCurrency(offer.value);
    offer.ownResources = this.mainService.unmaskCurrency(offer.ownResources);
    offer.otherResources = this.mainService.unmaskCurrency(offer.otherResources);
    offer.refinancedProduct.interestRate = this.mainService.unmaskPercent(offer.refinancedProduct.interestRate);
    offer.refinancedProduct.amount = this.mainService.unmaskCurrency(offer.refinancedProduct.amount);
    offer.refinancedProduct.repayment = this.mainService.unmaskCurrency(offer.refinancedProduct.repayment);
    for (const drawdown of offer.drawdowns) {
      drawdown.amount = this.mainService.unmaskCurrency(drawdown.amount);
    }
    for (const modelation of offer.modelations) {
      modelation.amount = this.mainService.unmaskCurrency(modelation.amount);
      for (const modelationPurpose of modelation.purposes) {
        modelationPurpose.amount = this.mainService.unmaskCurrency(modelationPurpose.amount);
      }
      for (const variantIndex in modelation.variants) {
        if (modelation.variants[variantIndex] !== undefined) {
          modelation.variants[variantIndex] = this.unmaskVariant(modelation.variants[variantIndex]);
        }
      }
    }
    return offer;
  }

  public async changeOfferStage(stage, referenceId: string = null) {
    const patch = {};
    if (stage === 'canceled') {
      patch['isCanceled'] = true;
      patch['canceledById'] = referenceId;
    } else {
      patch['stage'] = stage;
    }
    if (this.offerForm.get('tempComment').value) {
      patch['tempComment'] = this.offerForm.get('tempComment').value;
    }
    this.mainService.showLoading();
    this.offer = await this.patch(this.offer._id, patch);
    this.offerForm.get('tempComment').reset();
    this.mainService.hideLoading();
    this.snackbarService.showSuccess('Stav nabídky úspěšně změněn.');
  }

  public async cancelOffer(deal) {
    await this.dialogsService
      .confirm('Zamítnout nabídku', 'Chystáte se zamítnout tuto nabídku.')
      .subscribe(async confirm => {
        if (confirm) {
          const referenceOffers =
            deal.offers.filter((o: Offer) => !o.isCanceled && o._id !== this.offer._id) ??
            deal.offers.filter((o: Offer) => o._id !== this.offer._id);
          const referenceOffer = referenceOffers[referenceOffers.length - 1];
          await this.changeOfferStage('canceled', referenceOffer._id);
        }
      });
  }

  public async canSaveOffer(): Promise<boolean> {
    const duplicates = this.getEmailsDuplicates(this._applicantsForm);
    if (duplicates.length > 0) {
      return await this.dialogsService.emailDuplicity(this._applicantsForm, duplicates).toPromise();
    } else {
      return true;
    }
  }

  public async saveOffer() {
    this.mainService.showLoading();
    const invalidEmails: any[] = [];

    if (this._propertiesForm.controls.some(c => c.dirty)) {
      for (const propertyControl of this._propertiesForm.controls) {
        if (propertyControl.dirty) {
          const property = this.propertiesService.unmask(propertyControl.value);
          await this.propertiesService.patch(property._id, property);
        }
      }
    }
    if (this._householdsForm.controls.some(c => c.dirty)) {
      for (const householdControl of this._householdsForm.controls) {
        if (householdControl.dirty) {
          const household = householdControl.value;
          await this.householdsService.patch(household._id, household);
        }
      }
    }
    if (this._applicantsForm.controls.some(c => c.dirty)) {
      for (const contactControl of this._applicantsForm.controls) {
        if (contactControl.dirty) {
          const contact = this.contactsService.unmask(contactControl.value);
          if (contactControl.get('email').errors?.email) {
            this.dialogsService.alert(
              'Chybný email',
              `Email ${contact.email} není ve správném formátu a nebude uložen.`
            );
            invalidEmails.push({ email: contact.email, contactId: contactControl.get('_id').value });
            delete contact.email;
          }
          if (contactControl.get('email').value === '') {
            contact.email = null;
          }
          await this.contactsService.patch(contact._id, contact);
        }
      }
    }
    const offer = this.unmaskOffer(this.offerForm.getRawValue());
    for (const modelation of offer.modelations) {
      for (const variant of modelation.variants) {
        delete variant.id;
      }
    }
    this.offer = await this.patch(this.offer._id, offer);
    this.createOfferForm();
    this.fillOfferForm();
    invalidEmails.forEach(invalidEmail => {
      this._applicants
        .find(a => a.get('_id').value === invalidEmail.contactId)
        .get('email')
        .patchValue(invalidEmail.email);
    });
    this.mainService.hideLoading();
    this.snackbarService.showSuccess('Nabídka úspěšně uložena.');
    return true;
  }

  public async saveDocuments() {
    this.mainService.showLoading();
    const documentForms = this.documents.controls.filter((d: FormGroup) => !d.pristine);
    let originalDocuments = [];
    for (const applicant of this.offer._applicants) {
      if (applicant._documents?.length) {
        originalDocuments = [...originalDocuments, ...applicant._documents];
      }
    }
    if (this.offer._documents?.length) {
      originalDocuments = [...originalDocuments, ...this.offer._documents];
    }
    const archivedDocuments = originalDocuments
      .filter(od => !this.documents.value.find(d => d._id === od._id))
      .map(od => od._id);
    await Promise.all(
      documentForms.map((documentForm: FormGroup) => {
        const document = documentForm.value;
        if (document._id) {
          // Jen napatchujeme stávající dokument
          return this.documentsService.patch(document._id, document);
        } else {
          // Vytvoříme novej dokument
          if (document._template?.isRelatedTo === 'offer') {
            document['offerId'] = this.offer._id;
          }
          delete document._id;
          return this.documentsService.create(document);
        }
      })
    );
    await Promise.all(
      archivedDocuments.map((documentId: string) => {
        return this.documentsService.patch(documentId, { isArchived: true });
      })
    );
    this.offer = await this.patch(
      this.offer._id,
      { tempComment: this.documentsForm.value.tempComment },
      { query: { joins: ['documents'] } }
    );
    this.createDocumentsForm();
    this.fillDocumentsForm();
    this.mainService.hideLoading();
    this.snackbarService.showSuccess('Nabídka úspěšně uložena.');
    return true;
  }

  public isInvalid(control, type) {
    switch (type) {
      case 'applicant_basic':
        return (
          control.get('firstName').invalid ||
          control.get('lastName').invalid ||
          control.get('familyName').invalid ||
          control.get('sex').invalid ||
          control.get('job').invalid ||
          control.get('birthDate').invalid ||
          control.get('nationality').invalid ||
          control.get('residence').invalid ||
          control.get('residenceFromDate').invalid ||
          control.get('residenceToDate').invalid ||
          control.get('economicallyActivePeriod').invalid ||
          control.get('familyStatus').invalid ||
          control.get('personalNumber').invalid ||
          control.get('education').invalid ||
          control.get('bankAccounts').invalid ||
          control.get('placeOfBirth').invalid ||
          this.relationLeadApplicantGroup.transform(this.relationsToLeadApplicant, control.get('_id').value).invalid
        );
        break;
      case 'applicant_contacts':
        return control.get('email').invalid || control.get('phone').invalid || control.get('address').invalid;
        break;
      case 'modelation_basic':
        return (
          control.get('amount').invalid ||
          control.get('fixation').invalid ||
          control.get('maturity').invalid ||
          control.get('insurance').invalid ||
          control.get('offsetType').invalid
        );
        break;
    }
  }

  public filterAutocompleteItems(type, value): OptionInterface[] {
    const options: OptionInterface[] = this.settingsService.options[type];
    value = value ? value.toLowerCase() : null;
    return value
      ? options.filter(o => o.value.toLowerCase().includes(value) || o.label.toLowerCase().includes(value)).slice(0, 30)
      : options.slice(0, 30);
  }

  async productBenefitInterestRate(productForm: any, benefitAmount?: number) {
    const unmaskedValue = this.mainService.unmaskPercent(productForm.get('effectiveInterestRate').value);
    const sourceProduct = productForm.get('source').value;
    if (benefitAmount) {
      if (sourceProduct) {
        const benefitInterestRate = await this.productsService.getBenefitInterestRate({
          type: sourceProduct.type,
          amount: productForm.get('amount').value,
          maturity: productForm.get('maturity').value,
          fixation: productForm.get('fixation').value,
          interestRate: unmaskedValue,
          providerId: sourceProduct.provider._id,
          isOffset: sourceProduct.isOffset,
          percentagePrincipalMax: sourceProduct.limits.percentagePrincipalMax || 0,
          accountBalanceMax: sourceProduct.limits.accountBalanceMax || 0,
          averageAccountBalance: this.mainService.unmaskCurrency(productForm.get('averageAccountBalance').value),
          benefit: this.mainService.unmaskCurrency(benefitAmount),
          endFixationInterest: productForm.get('repaymentTotal').value?.endFixationInterest,
        });
        productForm
          .get('benefitInterestRate')
          .setValue(this.mainService.maskPercent(benefitInterestRate), { emitEvent: false });
      }
    } else {
      productForm
        .get('benefitInterestRate')
        .setValue(productForm.get('effectiveInterestRate').value, { emitEvent: false });
    }
  }

  async restoreOffer(deal: Deal, offer: Offer, result: any) {
    return await this.patch(offer._id, { restore: true });
  }

  public find(params: any = {}) {
    return this.socket.find(params);
  }

  public async sendMagicLink(applicant) {
    this.dialogsService
      .confirm(
        'Odeslat přihlašovací odkaz?',
        'Opravdu chcete odeslat přihlašovací odkaz na uloženou adresu? POZOR! Pokud chcete poslat odkaz na novou adresu, musíte ji nejdříve uložit.'
      )
      .subscribe(confirmed => {
        if (confirmed) {
          this.contactsService.patch(applicant.get('_id').value, { magicLink: true });
          this.snackbarService.showSuccess('Odkaz byl klientovi odeslán.');
        }
      });
  }

  public getEmailsDuplicates(applicantsForm: FormArray): number[] {
    const tmp = {};
    const duplicates: number[] = [];
    applicantsForm.controls.forEach((control: AbstractControl) => {
      const email = control.get('email').value;
      if (email !== null && email !== '') {
        if (tmp[email]) {
          tmp[email]++;
        } else {
          tmp[email] = 1;
        }
      }
    });
    applicantsForm.controls.forEach((control: AbstractControl, index: number) => {
      const email = control.get('email').value;
      if (tmp[email] && tmp[email] > 1) {
        duplicates.push(index);
      }
    });
    return duplicates;
  }
}

