import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { MainService } from '@app/services/main.service';
import { UsersService } from '@app/services/users.service';
import { DocumentsService } from '@app/services/documents.service';
import { FileListMoveEvent, FileListSelectEvent } from '@app/components/file-list/file-list.component';
import { Note } from '@app/models/note.model';
import { Inplace } from 'primeng/inplace';
import { NotesService } from '@app/services/notes.service';
import Quill from 'quill';
import { LifeInsurance } from '@app/models/life-insurance.model';
import { CarInsurance } from '@app/models/car-insurance.model';
import { RealEstateInsurance } from '@app/models/real-estate-insurance.model';

@Component({
  selector: 'app-document-item',
  templateUrl: './document-item.component.html',
  styleUrls: ['./document-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentItemComponent {
  @Input() public deal: LifeInsurance | CarInsurance | RealEstateInsurance;
  @Input() public document: AbstractControl;
  @Input() public documentIndex: number;
  @Input() public refresh: number;
  @Input() public isInternal = false;

  @Output() readonly removeDocument: EventEmitter<number> = new EventEmitter();
  @Output() readonly changeFiles: EventEmitter<FileListSelectEvent> = new EventEmitter();
  @Output() readonly moveFiles: EventEmitter<FileListMoveEvent> = new EventEmitter();
  @Output() readonly refreshFiles: EventEmitter<boolean> = new EventEmitter();

  @ViewChild('inpNote', { static: false }) inpNote: Inplace;

  public note: Note;
  public quill: Quill;

  constructor(
    public mainService: MainService,
    public notesService: NotesService,
    public usersService: UsersService,
    public documentsService: DocumentsService,
    public changeDetectorRef: ChangeDetectorRef,
  ) {
    this.note = new Note();
  }

  public noteEditing() {
    this.note.tmpText = this.note.text;
  }

  public initEditor($event: any): void {
    this.quill = $event.editor;
    setTimeout(() => {
      this.quill.focus();
      this.quill.setSelection(this.quill.getLength(), 0);
    }, 100);
  }

  public discardNote(inplace: Inplace): void {
    if (inplace) {
      inplace.deactivate();
    }
    this.note.tmpText = undefined;
  }

  public async saveNote(event: string, inplace?: Inplace) {
    if (inplace) {
      inplace.deactivate();
    }
    this.mainService.isLoading = true;
    try {
      let result: Note;
      if (this.note._id) {
        result = await this.notesService.patch(this.note._id, { text: event });
        this.document.patchValue({ note: result.plainText });
        this.document.markAsDirty();
      } else {
        result = await this.notesService.create({ text: event });
        this.document.patchValue({ noteId: result._id, note: result.plainText });
        this.document.markAsDirty();
      }
      this.note = { ...result };
      this.note.tmpText = '';
      this.changeDetectorRef.detectChanges();
    } finally {
      this.mainService.isLoading = false;
    }
  }

  public async panelOpenened() {
    const noteId = this.document.get('noteId').value;
    if (noteId) {
      this.note = await this.notesService.get(noteId);
      this.note.tmpText = undefined;
    }
  }
}
