import {Component, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {map} from 'rxjs/operators';
import {DealsService} from '@app/services/deals.service';
import {OffersService} from '@app/services/offers.service';
import {TasksService} from '@app/services/tasks.service';
import {UsersService} from '@app/services/users.service';
import {Deal} from '@app/models/deal.model';
import {Offer} from '@app/models/offer.model';
import {CallsService} from '@app/services/calls.service';
import {TasksListOptions} from '@app/components/tasks-list/tasks-list.interface';
import {Task} from '@app/models/task.model';
import {Note} from '@app/models/note.model';

@Component({
  selector: 'app-deal-tasks',
  templateUrl: './deal-tasks.component.html',
  styleUrls: ['./deal-tasks.component.scss']
})

export class DealTasksComponent implements OnInit, OnDestroy {

  public deal: Deal;
  public offer: Offer;
  private subs$: Subscription[] = [];
  public tasksOptions: TasksListOptions;
  public tasks: Observable<Task[]>;

  constructor(
    private dealsService: DealsService,
    private offersService: OffersService,
    public usersService: UsersService,
    public tasksService: TasksService,
    public callsService: CallsService,
  ) {
    this.tasksOptions = this.tasksService.loadOptionsFromStorage({ expandNotes: false, compactView: true, caseView: true, displayAvatar: true, displayNotes: 'all' });
    this.tasks = this.tasksService.items$.pipe(
      map((tasks) => this.tasksService.sortTasks(tasks))
    );
  }

  ngOnInit(): void {
    this.subs$.push(this.dealsService.deal$.subscribe((deal: Deal) => {
      this.deal = deal;
      this.tasksService.setFilterToDealId(deal._id);
      this.tasksService.loadData(0);
    }));
    this.subs$.push(this.offersService.offer$.subscribe((offer: Offer) => {
      this.offer = offer;
    }));
  }

  ngOnDestroy(): void {
    this.subs$.forEach((sub) => sub.unsubscribe());
    this.subs$ = [];
  }

  public isPristine(): boolean {
    return this.tasksService.isPristine(this.tasksService.items);
  }

  public updateTaskOptions() {
    this.tasksOptions = { ...this.tasksOptions };
  }

  public noteChanged(event: { task: Task; note: Note }) {
    this.tasksService.refreshTaskNote( this.tasksService.items, event.task, event.note );
  }
}
