<h2 mat-dialog-title>{{ title }}</h2>
<mat-dialog-content style="min-width: 500px;">
  <div *ngIf="description">{{description}}</div>

  <div class="dirs-list" [formGroup]="fg(documents)" *ngIf="ready === true && groups">
    <ng-container *ngFor="let group of groups">
      <ng-container *ngFor="let document of documents.controls;let dIndex = index">
        <div *ngIf="document.get('_template').value && document.get('_template').value.group === group"
             [formGroupName]="dIndex"
             class="dirs-item flex flex-row justify-between items-center gap-2">
          <div class="flex flex-col">
            <div>
              <strong>{{ document.get('_template').value.group }}</strong>
              <span *ngIf="document.get('isInternal')?.value === true" class="font-semibold text-blue">
                Interní
              </span>
            </div>
            <div>{{ document.get('_template').value.name }}</div>
          </div>
          <div>
            <button type="button" mat-raised-button color="primary"
                    [disabled]="document.get('_id').value === sourceGroup"
                    (click)="passResult(document.get('_id').value)">
              Přesunout
            </button>
          </div>
        </div>
      </ng-container>
    </ng-container>

    <ng-container *ngFor="let document of documents.controls;let dIndex = index">
      <div *ngIf="document.get('isInternal')?.value === true"
           [formGroupName]="dIndex"
           class="dirs-item flex flex-row justify-between items-center gap-2">
        <div class="flex flex-col">
          <div>
            <strong>{{ document.get('_template').value.name }}</strong>
            <span class="font-semibold text-blue">
              Interní
            </span>
          </div>
          <div>{{ document.get('note')?.value ?? '' }}</div>
        </div>
        <div>
          <button type="button" mat-raised-button color="primary"
                  [disabled]="document.get('_id').value === sourceGroup"
                  (click)="passResult(document.get('_id').value)">
            Přesunout
          </button>
        </div>
      </div>
    </ng-container>

  </div>

  <div class="dirs-list" [formGroup]="fg(documents)" *ngIf="ready === true && !groups">
      <ng-container *ngFor="let document of documents.controls;let dIndex = index">
        <div [formGroupName]="dIndex"
             class="dirs-item flex flex-row justify-between items-center gap-2">
          <div class="flex flex-col">
            <div *ngIf="document.get('isInternal')?.value === true">
              <div>
                <strong>{{ document.get('_template').value.name }}</strong>
                <span class="font-semibold text-blue">
                  Interní
                </span>
              </div>
              <div>{{ document.get('note')?.value ?? '' }}</div>
            </div>
            <div *ngIf="!document.get('isInternal')?.value === true">
              <div><strong>{{ document.get('_template').value.group }}</strong></div>
              <div>{{ document.get('_template').value.name }}</div>
            </div>
          </div>
          <div>
            <button type="button" mat-raised-button color="primary"
                    [disabled]="document.get('_id').value === sourceGroup"
                    (click)="passResult(document.get('_id').value)">
              Přesunout
            </button>
          </div>
        </div>
      </ng-container>
  </div>

</mat-dialog-content>

<mat-dialog-actions>
  <button *ngIf="!ready" type="button" mat-raised-button color="primary" (click)="dialogRef.close(null)">Zrušit</button>
  <button *ngIf="ready" type="button" mat-button color="warn" (click)="dialogRef.close(null)">Zrušit</button>
</mat-dialog-actions>
