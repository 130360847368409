import {Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {CdkVirtualScrollViewport} from '@angular/cdk/scrolling';
import {debounceTime} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {WidgetContactsService} from '@app/services/widget-contacts.service';
import {CallsService} from '@app/services/calls.service';
import {DialNumberParam} from '@app/models/calls.model';
import {MainService} from '@app/services/main.service';
import {UsersService} from '@app/services/users.service';

@Component({
  selector: 'app-calling-widget-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss']
})
export class ContactsComponent implements OnInit, OnChanges {

  @ViewChild('viewport') viewport: CdkVirtualScrollViewport;
  @Input('tabActivated') tabActivated: boolean = false;
  filters: any = {
    search: null,
    scope: null,
  };
  private searchChanged$ = new Subject<string>();
  private scopeChanged$ = new Subject<string>();
  public parsedPhoneNumber: any = null;

  constructor(
    public contactsService: WidgetContactsService,
    public callsService: CallsService,
    private mainService: MainService,
    public usersService: UsersService,
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.tabActivated) {
      if (this.tabActivated) {
        this.viewport.checkViewportSize();
      }
    }
  }

  ngOnInit() {
    if (localStorage.getItem('callingContactsFilter')) {
      this.filters = JSON.parse(localStorage.getItem('callingContactsFilter'));
    }
    this.filter();
    this.searchChanged$
      .pipe(debounceTime(500)) // Adjust the debounce time as needed
      .subscribe(value => {
        this.filter();
      });
    this.scopeChanged$
      .subscribe(value => {
        this.filter();
      });
    this.contactsService.items$.subscribe(items => {
      this.parsedPhoneNumber = items.length === 0 && this.filters.search ? this.parsePhoneNumber(this.filters.search) : null;
    });
  }

  onSearchChanged(value: string) {
    this.parsedPhoneNumber = null;
    this.searchChanged$.next(value);
  }

  onScopeChanged(value: string): void {
    if (value === this.filters?.scope) {
      value = null;
    }
    this.filters.scope = value;
    this.scopeChanged$.next(value);
  }

  endReached() {
    if (!this.contactsService.$isLoading.getValue()) {
      this.contactsService.load();
    }
  }

  filter() {
    localStorage.setItem('callingContactsFilter', JSON.stringify(this.filters));
    this.parsedPhoneNumber = null;
    const filter: any = {
      scope: this.filters.scope,
      joins: ['provider'],
      'phone.status': 1,
      $and: []
    };

    if (Object.keys(this.usersService.user.cases || {}).length) {
      const $or = [
        { 'owners.userId': this.usersService.user._id },
        { 'owners.0': { $exists: false } }
      ];
      filter.$and.push({ $or });
    }
    if (this.filters.search) {
      const $or = [];
      $or.push({search: { $regex: this.filters.search.normalize('NFD').replace(/\+|\(|\)|\-|\\|\//gi, '').replace(/[\u0300-\u036f]/g, ''), $options: 'i' }});
      $or.push({'phone.number' : { $regex: this.filters.search.replace(/\+|\(|\)|\-|\\|\//gi, ''), $options: 'i' }});
      filter.$and.push({ $or });
    }
    if (filter.$and.length === 0) {
      delete filter.$and;
    }
    this.contactsService.filter = filter;
    this.contactsService.load(0);
  }

  clearSearch() {
    this.filters.search = null;
    this.filter();
  }

  public async dialPhone(event: any) {
    const param: DialNumberParam = {
      countryCode: event.countryCode,
      number: event.phoneNumber,
      contactId: event.contactId,
      origin: 'WidgetContactsComponent'
    };
    await this.callsService.initiateCall(param);
  }

  public parsePhoneNumber(input: string) {
    const availableCountryCodes = this.mainService.options('phoneCountryCode').map(item => item.value);
    // Remove any non-digit characters
    const cleanedInput = input.replace(/[^0-9+]/g, '');
    // Check if the cleaned input has a country code (starts with a plus sign)
    if (cleanedInput.startsWith('+')) {
      const countryCode = cleanedInput.match(/\+(\d+)\d{9}/);
      const phoneNumber = cleanedInput.match(/\+\d+(\d{9})/);
      if (countryCode && phoneNumber) {
        return {
          phoneNumber: phoneNumber[1],
          countryCode: countryCode[1] && availableCountryCodes.includes(`+${countryCode[1]}`) ? `+${countryCode[1]}` : '+420',
          warning: input !== (countryCode[1] && availableCountryCodes.includes(`+${countryCode[1]}`) ? `+${countryCode[1]}` : '+420') + phoneNumber[1]
        };
      }
    } else if (cleanedInput.startsWith('00')) {
      const countryCode = cleanedInput.match(/00(\d+)\d{9}/);
      const phoneNumber = cleanedInput.match(/00\d+(\d{9})/);
      if (countryCode && phoneNumber) {
        return {
          phoneNumber: phoneNumber[1],
          countryCode: countryCode[1] && availableCountryCodes.includes(`+${countryCode[1]}`) ? `+${countryCode[1]}` : '+420',
          warning: input !== (countryCode[1] && availableCountryCodes.includes(`+${countryCode[1]}`) ? `+${countryCode[1]}` : '+420') + phoneNumber[1]
        };
      }
    } else {
      const phoneNumber = cleanedInput.match(/.*(\d{9})/);
      if (phoneNumber) {
        return {
          phoneNumber: phoneNumber[1],
          countryCode: '+420',
          warning: input !== '+420' + phoneNumber[1]
        };
      }
    }
    // If none of the conditions are met, return null or handle it accordingly
    return null;
  }
}
