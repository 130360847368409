<h2 mat-dialog-title><span>{{ title }}</span></h2>
<mat-dialog-content>
  <form [formGroup]="form">
    <div class="form-row">
      <mat-form-field>
        <mat-label>Pojišťovna</mat-label>
        <mat-select formControlName="providerId" >
          <mat-option *ngFor="let provider of (providersService.items$ | async) | fromFinalVariant:modelations" [value]="provider._id">{{ provider.name }}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field formGroupName="intervention" *ngIf="!isCounterProposal">
        <mat-label>Typ</mat-label>
        <mat-select formControlName="type">
          <mat-option *ngFor="let option of mainService.options('interventionType')" [value]="option.value">{{ option.label }}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field formGroupName="counterProposal" *ngIf="isCounterProposal">
        <mat-label>Typ</mat-label>
        <mat-select formControlName="type" >
          <mat-option *ngFor="let option of mainService.options('counterProposalType')" [value]="option.value">{{ option.label }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="form-row">
      <mat-form-field>
        <mat-label>Datum přijetí</mat-label>
        <input matInput [matDatepicker]="receivedAtpicker" formControlName="receivedAt" />
        <mat-datepicker-toggle matSuffix [for]="receivedAtpicker"></mat-datepicker-toggle>
        <mat-datepicker #receivedAtpicker></mat-datepicker>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Vyřešit do</mat-label>
        <input matInput [matDatepicker]="resolvedAtpicker" formControlName="resolveAt" />
        <mat-datepicker-toggle matSuffix [for]="resolvedAtpicker"></mat-datepicker-toggle>
        <mat-datepicker #resolvedAtpicker></mat-datepicker>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Datum vyřešení</mat-label>
        <input matInput [matDatepicker]="resolveAtpicker" formControlName="resolvedAt" />
        <mat-datepicker-toggle matSuffix [for]="resolveAtpicker"></mat-datepicker-toggle>
        <mat-datepicker #resolveAtpicker></mat-datepicker>
      </mat-form-field>

      <mat-form-field formGroupName="counterProposal" *ngIf="isCounterProposal">
        <mat-label>Navrhovaná výše pojistného</mat-label>
        <app-input-number formControlName="amount" type="number"></app-input-number>
        <span matSuffix>Kč</span>
      </mat-form-field>
    </div>

    <div class="form-row">
      <mat-form-field><textarea placeholder="Poznámky" formControlName="note" matInput cdkTextareaAutosize rows="1"></textarea></mat-form-field>
    </div>

    <div class="pt-4">
      Pozor, při <span class="font-semibold">každém</span> vytvoření intervence či protinávrhu, vznikne nový úkol osobám ze skupiny asistentů.
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions>
  <button type="button" mat-button color="warn" (click)="dialogRef.close(null)">Zrušit</button>
  <button type="button" mat-raised-button color="accent" [disabled]="!form.valid" (click)="closeDialog()">Uložit</button>
</mat-dialog-actions>
