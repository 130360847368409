<ng-container *ngIf="menuItem">
  <button mat-menu-item (click)="dialNumber()" *ngIf="callsService.isContactCentre()" [disabled]="!callsService.canDialNumber() || this.isNumberInvalid || (callingWidgetService.callsDisabled$ | async)">
    <mat-icon [color]="iconColor">phone</mat-icon>
    Volat {{contactName}}
  </button>
  <a mat-menu-item [href]="fallbackUrl" *ngIf="!callsService.isContactCentre()">
    <mat-icon [color]="iconColor">phone</mat-icon>
    Volat {{contactName}}
  </a>
  <button *ngIf="!hideSms" mat-menu-item (click)="openConversation()" [disabled]="this.isNumberInvalid || (callingWidgetService.smsDisabled$ | async)">
    <mat-icon [color]="iconColor">comment</mat-icon>
    Napsat {{contactName}}
  </button>
</ng-container>
<ng-container *ngIf="!menuItem && compact && widget">
  <button mat-mini-fab color="accent" (click)="dialNumber()" *ngIf="callsService.isContactCentre()" [disabled]="!callsService.canDialNumber() || this.isNumberInvalid || (callingWidgetService.callsDisabled$ | async)">
    <mat-icon>call</mat-icon>
  </button>
  <a mat-mini-fab class="ml-2" color="accent" [href]="fallbackUrl" *ngIf="!callsService.isContactCentre()">
    <mat-icon>call</mat-icon>
  </a>
  <button *ngIf="!hideSms" mat-mini-fab class="ml-2" color="primary" (click)="openConversation()" [disabled]="this.isNumberInvalid || (callingWidgetService.smsDisabled$ | async)">
    <mat-icon>message</mat-icon>
  </button>
</ng-container>
<ng-container *ngIf="!menuItem && compact && !widget">
  <button mat-mini-fab (click)="dialNumber()" *ngIf="callsService.isContactCentre()" [disabled]="!callsService.canDialNumber() || this.isNumberInvalid || (callingWidgetService.callsDisabled$ | async)">
    <mat-icon>phone</mat-icon>
  </button>
  <a mat-mini-fab [href]="fallbackUrl" class="ml-2" *ngIf="!callsService.isContactCentre()">
    <mat-icon>phone</mat-icon>
  </a>
  <button *ngIf="!hideSms" mat-mini-fab class="ml-2" color="primary" (click)="openConversation()" [disabled]="this.isNumberInvalid || (callingWidgetService.smsDisabled$ | async)">
    <mat-icon>message</mat-icon>
  </button>
</ng-container>
<ng-container *ngIf="!menuItem && !compact">
  <button mat-raised-button color="accent" (click)="dialNumber()" *ngIf="callsService.isContactCentre()" [disabled]="!callsService.canDialNumber() || this.isNumberInvalid || (callingWidgetService.callsDisabled$ | async)">
      Volat {{contactName}}
  </button>
  <a mat-raised-button [href]="fallbackUrl" color="accent" *ngIf="!callsService.isContactCentre()">
      Volat {{contactName}}
  </a>
  <button *ngIf="!hideSms" mat-raised-button color="primary" (click)="openConversation()" [disabled]="this.isNumberInvalid || (callingWidgetService.smsDisabled$ | async)">
    Napsat <mat-icon>message</mat-icon>
  </button>
</ng-container>
