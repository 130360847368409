import { MatDialogRef } from '@angular/material/dialog';
import { Component } from '@angular/core';

@Component({
    selector: 'app-dialog',
    template: `
        <h2 mat-dialog-title>Automatické odhlášení</h2>
        <mat-dialog-content>Došlo k automatickému odhlášení kvůli nečinnosti.</mat-dialog-content>
        <mat-dialog-actions>
          <a mat-raised-button color="primary" href="/login">Znovu přihlásit</a>
        </mat-dialog-actions>
    `,
})
export class AutoLogoutDialogComponent {


    constructor(
      public dialogRef: MatDialogRef<AutoLogoutDialogComponent>
    ) {

    }
}
