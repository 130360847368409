import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'caseStage'})
export class CaseStagePipe implements PipeTransform {
  constructor(
  ) {}
  transform(stageId: string, stages: any[]) {
    return stages.find(s => s.id === stageId)?.label || stageId;
  }
}
