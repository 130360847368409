import { Component } from '@angular/core';
import {TitleService} from '../../services/title.service';

@Component({
  selector: 'app-calculations',
  templateUrl: 'calculations.component.html',
  styleUrls: ['calculations.component.scss']
})

export class CalculationsComponent {


  constructor(
    private titleService: TitleService
  ) {
    this.titleService.setTitle('Výpočty');
  }
}
