import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { Blacklist } from '@app/models/blacklist.model';

@Injectable({providedIn: 'root'})
export class BlacklistService extends DataService<Blacklist> {
  constructor()
  {
    super('blacklist');
    this.query = {
      active: false,
    };
    this.sort = {};
  }

  async findBlacklistStatus(phoneNumber: string): Promise<Blacklist | null> {
    const f = await this.find({query: {value: phoneNumber}});
    if (!f || f.length === 0) {
      return null;
    } else {
      return f[0];
    }
  }

  async getBlacklistStatus(phoneNumber: string): Promise<boolean> {
    const b = await this.findBlacklistStatus(phoneNumber);
    return b !== null;
  }

  async setBlacklistStatus(phoneNumber: string, value: boolean): Promise<boolean> {
    if (value === true) {
      const b = await this.findBlacklistStatus(phoneNumber);
      if (b === null) {
        await this.service.create({value: phoneNumber});
      }
    } else {
      const f = await this.find({query: {value: phoneNumber}});
      if (f && f.length > 0) {
        for (const item of f) {
          await this.service.remove(item._id);
        }
      }
    }
    return value;
  }
}
