import { Injectable, isDevMode } from '@angular/core';
import { BackendService } from './backend.service';
import {BehaviorSubject} from 'rxjs';
import {SnackbarService} from './snackbar.service';
import {SettingsInterface} from '../interfaces/settings.interface';
import {OptionInterface} from '../interfaces/option.interface';

@Injectable()
export class SettingsService {
  private _socket: any;
  private _socketOptions: any;
  private optionsLoaded = false;
  private optionsTimestamp: number = null;
  public settings: SettingsInterface = null;
  public options: OptionInterface[][] = [];
  private _settingsChanged: BehaviorSubject<any> = new BehaviorSubject(null);

  constructor(
    private backendService: BackendService,
    private snackbarService: SnackbarService
  ) {
    this._socket = this.backendService.getService('settings');
    this._socketOptions = this.backendService.getService('options');
    this._socket.on('updated', (updatedSettings: SettingsInterface) => this._updated(updatedSettings));
    this._socket.on('patched', (updatedSettings: SettingsInterface) => this._updated(updatedSettings));
  }

  get settingsChanged$() {
    return this._settingsChanged.asObservable();
  }

  public async loadSettings(queryCode = {}) {
    const query = Object.assign({}, queryCode, {_name: isDevMode() ? 'default' : 'production'});
    this.settings = (await this.find({query}))[0];
    const d = (new Date().getTime()) - (1000 * 60 * 60 * 4); // Options are reload after four hours
    if ((this.optionsLoaded === false) || (this.optionsTimestamp < d)) {
      const options = await this._socketOptions.find();
      this.optionsLoaded = true;
      this.optionsTimestamp = new Date().getTime();
      for (const option of options) {
        this.options[option.type] = option.options;
      }
    }
  }

  public saveSettings(values: any) {
    this.patch(null, values, {query: {_name: isDevMode() ? 'default' : 'production'}}).then(() => {
      this.snackbarService.showSuccess('Nastavení bylo úspěšně uloženo');
    }).catch((err) => {
      console.log(err);
    });
  }

  public patch(id: string, values: any, params?: any) {
    return this._socket.patch(id, values, params);
  }

  public find(params: any) {
    return this._socket.find(params);
  }

  private _updated(updatedSettings: SettingsInterface): void {
    if ((isDevMode() && updatedSettings._name === 'default') || (!isDevMode() && updatedSettings._name === 'production')) {
      this.settings = updatedSettings;
      this._settingsChanged.next(updatedSettings);
    }
  }
}
