<mat-icon *ngIf="!enableMenu">
  <ng-container *ngTemplateOutlet="priorityTmpl; context: { priority: priority}"></ng-container>
</mat-icon>

<button mat-icon-button
        *ngIf="enableMenu"
        [matMenuTriggerFor]="priorityMenu">
  <ng-container *ngTemplateOutlet="priorityTmpl; context: { priority: priority}"></ng-container>
</button>

<ng-template #priorityTmpl let-priority="priority">
  <mat-icon *ngIf="priority === 2" color="warn" [class]="styleClass" svgIcon="priority-highest"></mat-icon>
  <mat-icon *ngIf="priority === 1" color="warn" [class]="styleClass" svgIcon="priority-high"></mat-icon>
  <mat-icon *ngIf="priority === 0" color="primary" [class]="styleClass" svgIcon="priority-medium"></mat-icon>
  <mat-icon *ngIf="priority === -1" color="accent" [class]="styleClass" svgIcon="priority-low"></mat-icon>
  <mat-icon *ngIf="priority === -2" color="accent" [class]="styleClass" svgIcon="priority-lowest"></mat-icon>
</ng-template>

<mat-menu #priorityMenu="matMenu">
  <button mat-menu-item *ngFor="let priority of this.settingsService.options['priority']" (click)="changePriority(priority.value)">
    <ng-container *ngTemplateOutlet="priorityTmpl; context: { priority: priority.value}"></ng-container>
    {{ priority.label }}
  </button>
</mat-menu>
