import {Inject} from '@angular/core';
import {BehaviorSubject, Observable, Subject, Subscription, from} from 'rxjs';
import {CrudService} from './crud.service';
import {BackendService} from './backend.service';
import sift from 'sift';

export interface DataServiceItem {
  _id?: string;
}

export class DataService<T extends DataServiceItem> extends CrudService {

  protected pagination?: number;
  protected hasAggregation: boolean = false;
  protected fromIndex: number = 0;
  public total: number = 0;
  public refreshStrategy = '';
  protected query: any = {};
  public filter: any = {};
  public sort: any = {};
  items: T[] = [];
  public $total: BehaviorSubject<number> = new BehaviorSubject<number>(this.total);
  public total$: Observable<number> = this.$total.asObservable();
  public $items: BehaviorSubject<T[]> = new BehaviorSubject<T[]>([]);
  public items$: Observable<T[]> = this.$items.asObservable();
  public $aggregation: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public aggregation$: Observable<any> = this.$aggregation.asObservable();
  protected $created: Subject<T> = new Subject();
  public created$: Observable<T> = this.$created.asObservable();
  protected $updated: Subject<T> = new Subject();
  public updated$: Observable<T> = this.$updated.asObservable();
  protected $removed: Subject<T> = new Subject();
  public removed$: Observable<T> = this.$removed.asObservable();
  public $isLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isLoading$: Observable<boolean> = this.$isLoading.asObservable();
  protected $canLoadMore: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public canLoadMore$: Observable<boolean> = this.$canLoadMore.asObservable();
  private result$: Subscription;


  constructor(
    @Inject('servicePath') protected servicePath: string
  ) {
    super(servicePath, BackendService);
    this.service.on('created', (item: T) => this.created(item));
    this.service.on('updated', (item: T) => this.updated(item));
    this.service.on('patched', (item: T) => this.updated(item));
    this.service.on('removed', (item: T) => this.removed(item));
  }

  public find(params: any = {}): any {
    return this.service.find(params);
  }

  public get(id: string, params: any = {}): any {
    return this.service.get(id, params);
  }

  public create(item: any, params: any = {}): any {
    return this.service.create(item, params);
  }

  public patch(id: string | null, item: any, params: any = {}): any {
    if (id && item.isPlatformChange === undefined) {
      item.isPlatformChange = true;
    }
    return this.service.patch(id, item, params);
  }

  public remove(id: string | null, params: any = {}): any {
    return this.service.remove(id, params);
  }

  protected created(item: any): void {
    if (item?._id) {
      try {
        const query = this.buildQuery({}, false);
        delete query.aggregation;
        delete query.$select;
        delete query.joins;
        delete query.nojoins;
        delete query.omitfields;
        Object.entries(query).forEach(([key, value]) => {
          if (value === undefined) {
            delete query[key];
          }
        });
        if (item.deletedAt) {
          if (typeof item.deletedAt === 'string') {
            item.deletedAt = new Date(item.deletedAt);
          }
        }
        if (item.startAt) {
          if (typeof item.startAt === 'string') {
            item.startAt = new Date(item.startAt);
          }
        }
        if (item.remindAt) {
          if (typeof item.remindAt === 'string') {
            item.remindAt = new Date(item.remindAt);
          }
        }
        if (item.dueAt) {
          if (typeof item.dueAt === 'string') {
            item.dueAt = new Date(item.dueAt);
          }
        }
        const isValidItem = [item].find(sift(query));
        if (isValidItem) {
          const index = this.items?.findIndex((i) => i._id === item._id);
          if (index < 0) {
            this.items.unshift(item);
            this.total++;
            this.setItems();
            this.updateTotal();
          }
          this.$created.next(item);
        }
      } catch (e) {
      }
    }
  }

  protected updated(item: any): void {
    if (item?._id) {
      try {
        const index = this.items.findIndex((i) => i._id === item._id);
        if (index >= 0) {
          const query = this.buildQuery({}, false);
          delete query.aggregation;
          delete query.$select;
          delete query.joins;
          delete query.nojoins;
          delete query.omitfields;
          Object.entries(query).forEach(([key, value]) => {
            if (value === undefined) {
              delete query[key];
            }
          });
          if (item.deletedAt) {
            if (typeof item.deletedAt === 'string') {
              item.deletedAt = new Date(item.deletedAt);
            }
          }
          if (item.startAt) {
            if (typeof item.startAt === 'string') {
              item.startAt = new Date(item.startAt);
            }
          }
          if (item.remindAt) {
            if (typeof item.remindAt === 'string') {
              item.remindAt = new Date(item.remindAt);
            }
          }
          if (item.dueAt) {
            if (typeof item.dueAt === 'string') {
              item.dueAt = new Date(item.dueAt);
            }
          }
          const isValidItem = [item].find(sift(query));
          if (isValidItem) {
            if (this.refreshStrategy === 'merge') {
              this.items[index] = { ...this.items[index], ...item } ;
            } else {
              this.items[index] = item;
            }
          } else {
            this.items.splice(index, 1);
            this.total--;
            this.fromIndex--;
            this.updateTotal();
          }
          this.setItems();
        } else {
          this.created(item);
        }
        this.$updated.next(item);
      } catch (e) {
        console.log(e);
      }
    }
  }

  protected removed(item: T): void {
    if (item?._id) {
      const index = this.items.findIndex((i) => i._id === item._id);
      if (index >= 0) {
        this.items.splice(index, 1);
        this.total--;
        this.fromIndex--;
        this.setItems();
        this.updateTotal();
      }
      this.$removed.next(item);
    }
  }

  protected updateTotal(): void {
    this.$total.next(this.total);
    this.canLoadMore();
  }

  protected setItems(items?: T[]): void {
    if (items) {
      this.items = items;
    }
    this.$items.next(this.items);
  }

  public async load(fromIndex?: number): Promise<void> {
    this.setLoading(true);
    if (fromIndex === 0) {
      this.setItems([]);
    }
    let data = [];
    const query = this.buildQuery();
    try {
      if (this.pagination !== undefined) {
        if (fromIndex !== undefined && Number.isInteger(fromIndex)) {
          this.fromIndex = fromIndex;
          this.items = [];
        }
        query.$limit = this.pagination;
        query.$skip = this.fromIndex;
        if (this.result$) {
          this.result$.unsubscribe();
        }
        this.result$ = from(this.find({query})).subscribe((result: any) => {
          this.total = result.total;
          this.fromIndex += this.pagination;
          data = result.data.filter((di: T) => this.items.findIndex(i => i._id === di._id) < 0);
          this.items.push(...data);
          this.setItems();
          this.updateTotal();
          this.loadAggregation(result);
          this.setLoading(false);
        });
      } else {
        data = await this.find({query});
        this.setItems(data);
        this.setLoading(false);
      }
    } catch (e) {
      this.setLoading(false);
      throw e;
    }
  }

  public buildQuery(base: any = {}, includeSort = true): any {
    const query = Object.assign(base, this.query, this.filter);
    if (includeSort) {
      query.$sort = this.sort;
    }
    return query;
  }

  public async loadAggregation(result?: any): Promise<void> {
    if (this.hasAggregation) {
      const query = this.buildQuery({aggregationOnly: true});
      if (!result) {
        result = await this.find({query});
      }
      if (result.aggregation !== undefined) {
        this.$aggregation.next(result.aggregation || null);
      }
    }
  }

  private canLoadMore() {
    return this.$canLoadMore.next(this.items?.length && this.total && this.items.length < this.total || false);
  }

  public setLoading(isLoading: boolean) {
    this.$isLoading.next(isLoading);
  }

  public trackByFn(index: number, item: T) {
    return item._id;
  }
}
