import { Injectable } from '@angular/core';
import {CrudService} from '@app/services/crud.service';
import {BackendRestService} from '@app/services/backend-rest.service';

@Injectable({providedIn: 'root'})
export class DemandsService extends CrudService {

  constructor(
  ) {
    super('demands', BackendRestService);
  }
}
