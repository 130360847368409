import { Pipe, PipeTransform } from '@angular/core';
import { formatNumber } from '@angular/common';

@Pipe({ name: 'fileSize'})
export class FileSizePipe implements PipeTransform {
  constructor(
  ) {}
  public transform(value: any): string {
    if (!value) {
      return '';
    } else {
      if (value < 100000) {
        return '0,1 MB';
      }
      return formatNumber(value / (1024 * 1024), 'cs-CS', '1.1-1') + ' MB';
    }
  }
}



