import { MatDialogRef } from '@angular/material/dialog';
import { Component } from '@angular/core';

@Component({
    selector: 'app-dialog',
    template: `
      <h2 mat-dialog-title>Nebyly uloženy změny</h2>
      <mat-dialog-content>Chcete přesto opustit tuto stránku?</mat-dialog-content>
      <mat-dialog-actions>
        <button type="button" mat-button color="warn" (click)="dialogRef.close(true)">Ano</button>
        <button type="button" mat-raised-button color="accent" (click)="dialogRef.close(false)">Ne</button>
      </mat-dialog-actions>
    `,
})
export class DeactivateDialogComponent {

    constructor(
      public dialogRef: MatDialogRef<DeactivateDialogComponent>
    ) {

    }
}
