import { MatDialogRef } from '@angular/material/dialog';
import { Component } from '@angular/core';
import { FormArray } from '@angular/forms';
import { OffersService } from '@app/services/offers.service';

@Component({
  selector: 'app-dialog',
  templateUrl: './email-duplicity-dialog.component.html'
})

export class EmailDuplicityDialogComponent {

  public applicants: FormArray;
  public duplicates: number[];

  constructor(
    public dialogRef: MatDialogRef<EmailDuplicityDialogComponent>,
    private offersService: OffersService,
  ) {
  }

  public save(): void {
    this.dialogRef.close(true);
  }

  public isDuplicates() {
    const d = this.offersService.getEmailsDuplicates(this.applicants);
    this.duplicates = [ ...new Set([...this.duplicates, ...d]) ];
    return d.length > 0;
  }
}
