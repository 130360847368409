import {Component, HostListener, OnInit} from '@angular/core';
import {OffersService} from '../../services/offers.service';
import {DealsService} from '../../services/deals.service';
import {MainService} from '../../services/main.service';
import {TitleService} from '../../services/title.service';
import {Deal} from '@app/models/deal.model';
import {Offer} from '@app/models/offer.model';


@Component({
  selector: 'app-offer',
  templateUrl: 'offer.component.html',
  styleUrls: ['offer.component.scss']
})

export class OfferComponent implements OnInit {
  public deal: Deal = null;
  public offer: Offer = null;

  @HostListener('window:beforeunload', ['$event'])
  onBeforeUnload(event) {
    return this.offersService.offerForm.pristine;
  }

  @HostListener('document:keypress', ['$event'])
  onKeyPress(event) {
    if (event.ctrlKey && event.code === 'KeyI') {
      console.log('Errors:');
      const offer = this.mainService.getFormValidationErrors(this.offersService.offerForm);
      const applicants = this.mainService.getFormValidationErrors(this.offersService._applicantsForm);
      const households = this.mainService.getFormValidationErrors(this.offersService._householdsForm);
      const properties = this.mainService.getFormValidationErrors(this.offersService._propertiesForm);
      if (offer.length) {
        console.log(offer, this.offersService.offerForm.value);
      }
      if (applicants.length) {
        console.log(applicants, this.offersService._applicantsForm.value);
      }
      if (households.length) {
        console.log(households, this.offersService._householdsForm.value);
      }
      if (properties.length) {
        console.log(properties, this.offersService._propertiesForm.value);
      }
    }
  }

  constructor(
    public offersService: OffersService,
    private dealsService: DealsService,
    private mainService: MainService,
    private titleService: TitleService
  ) {

  }

  ngOnInit(): void {
    this.dealsService.deal$.subscribe((deal: Deal) => {
      this.deal = deal;
      this.titleService.setTitle(`P${deal.numberId} ${deal.name}`);
    });
    this.offersService.offer$.subscribe((offer: Offer) => {
      this.offer = offer;
    });
  }
}
