import { Injectable, isDevMode } from '@angular/core';
import {BackendService} from './backend.service';
import {UsersService} from './users.service';
import {BehaviorSubject} from 'rxjs';
import {ProductInterface} from '../interfaces/product.interface';

@Injectable()
export class ProductsService {
  public items: ProductInterface[] = [];
  private _socket: any;

  private _items: BehaviorSubject<ProductInterface[]> = new BehaviorSubject([]);
  private _initLoad = true;
  public isLoading = false;
  public sorting: any = {
    name: 1
  };
  public filters: any = {
    name: null,
    providerId: null,
    active: null
  };

  constructor(
    private backendService: BackendService,
    private usersService: UsersService
  ) {
    this._socket = this.backendService.getService('products');
    this._socket.on('updated', (item: ProductInterface) => this._updated(item));
    this._socket.on('updated', (item: ProductInterface) => this._updated(item));
    this._socket.on('created', (item: ProductInterface) => this._created(item));
    this._socket.on('removed', (item: ProductInterface) => this._removed(item));
    this.usersService.authenticated$.subscribe(authenticated => {
      if (authenticated) {
        if (this._initLoad) {
          this.firstLoad();
        }
      } else {
        this._initLoad = true;
      }
    });
  }

  get items$() {
    return this._items.asObservable();
  }

  public firstLoad() {
    this.items = [];
    this._initLoad = false;
    this.load();
  }

  public load() {
    this.isLoading = true;
    const query = {
      inactive: true
    };
    this.find({
      query,
      collation: {
        locale: 'cs',
        strength: 1
      }
    }).then((data) => {
      this.items = data;
      this.processItems();
      this.isLoading = false;
    }).catch((e) => {
      this.items = [];
      this.processItems();
      this.isLoading = false;
    });
  }

  public processItems() {
    let items = Array.from(this.items);
    items = items.filter((i) => {
      let active = true;
      let providerId = true;
      let name = true;
      if (this.filters.active) {
        active = this.filters.active === 'active' ? i.active : !i.active;
      }
      if (this.filters.providerId) {
        providerId = this.filters.providerId === i.providerId;
      }
      if (this.filters.name) {
        const regexp = RegExp('.*' + this.filters.name + '.*', 'i');
        name = regexp.test(i.name);
      }
      return active && providerId && name;
    });
    items.sort((a, b) => {
      const sortBy = Object.keys(this.sorting)[0];
      const ascending = this.sorting[sortBy] === 1;
      return ascending ? getObjectProperty(a, sortBy).toLowerCase().localeCompare(getObjectProperty(b, sortBy).toLowerCase()) : getObjectProperty(b, sortBy).toLowerCase().localeCompare(getObjectProperty(a, sortBy).toLowerCase());
    });
    this._items.next(items);
  }

  public filter() {
    this.processItems();
  }

  public clearFilter(column) {
    this.filters[column] = null;
    this.processItems();
  }

  public sort(column) {
    if (this.sorting[column] === undefined) {
      this.sorting = {};
      this.sorting[column] = 1;
    } else {
      this.sorting[column] *= -1;
    }
    this.processItems();
  }

  public isSorting(column, direction = null) {
    if (!direction) {
      return this.sorting[column] !== undefined;
    } else {
      return this.sorting[column] && this.sorting[column] === direction;
    }
  }

  public find(params: any = {}) {
    return this._socket.find(params);
  }

  public get(id: string, params: any = {}) {
    return this._socket.get(id, params);
  }

  public create(item: any, params: any = {}) {
    return this._socket.create(item, params);
  }

  public patch(id: string, item: any, params: any = {}) {
    return this._socket.patch(id, item, params);
  }

  public remove(id: string, params: any = {}) {
    return this._socket.remove(id, params);
  }

  private _updated(item: ProductInterface): void {
    const index = this.items?.findIndex(i => i._id === item._id);
    if (index > -1) {
      this.items[index] = item;
      this.processItems();
    }
  }

  private _created(item: ProductInterface): void {
    this.items.push(item);
    this.processItems();
  }

  private _removed(item: ProductInterface): void {
    const index = this.items?.findIndex(i => i._id === item._id);
    this.items.splice(index, 1);
    this.processItems();
  }

}

function getObjectProperty(o, s) {
  s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
  s = s.replace(/^\./, '');           // strip a leading dot
  const a = s.split('.');
  for (let i = 0, n = a.length; i < n; ++i) {
    const k = a[i];
    if (k in o) {
      o = o[k];
    } else {
      return;
    }
  }
  return o;
}
