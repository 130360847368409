import { Injectable } from '@angular/core';
import {CrudService} from './crud.service';
import {BackendService} from './backend.service';

@Injectable({providedIn: 'root'})
export class CampaignTemplatesService extends CrudService {

  constructor(
  ) {
    super('campaigns/templates', BackendService);
  }
}
