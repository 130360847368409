import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'appliedSales' })
export class ProductAppliedSalesPipe implements PipeTransform {
  constructor() {}
  transform(product: any) {
    const sales = product.sales?.filter(s => s.rateChange && product.fixation?.appliedDiscounts?.find(ad => ad._id === s._id && s.type === 'params')) || [];
    return sales;
  }
}
