import { Injectable } from '@angular/core';
import {BackendService} from './backend.service';

@Injectable()
export class AresService {
  private socket: any;
  constructor(
    private backendService: BackendService
  ) {
    this.socket = this.backendService.getService('ares');
  }

  public get(id: string, params: any = {}) {
    return this.socket.get(id, params);
  }
}
