import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'saleGroups' })
export class ProductSaleGroupsPipe implements PipeTransform {
  constructor() {}
  transform(product: any) {
    const groupedSales = product.sales?.filter(s => s.rateChange) || []; // Zajimaji nas pouze slevy na urokove sazbe
    const returnSales = groupedSales.filter(s => !s.groupOr && s.type !== 'params'); // Nejdriv priradime vsechny slevy ktere nejsou params a nejsou v zadne skupine
    // Vybereme skupiny jejich zadny params clen neni v appliedDiscounts
    const saleGroups = [...new Set(groupedSales.map(s => s.groupOr))].filter(g => g && groupedSales.filter(gs => gs.groupOr === g).every(e => !(product.fixation?.appliedDiscounts?.find(ad => ad._id === e._id && e.type === 'params'))));
    for (const saleGroup of saleGroups) {
      const groupSales = groupedSales.filter(s => s.groupOr === saleGroup && s.type !== 'params'); // Do skupiny chceme jen cleny ktere nejsou params typ
      const group = groupSales.length > 1 ? groupSales : groupSales[0]; // Pokud nam po vyfiltrovani zbude jeden clen skupiny, tak ho zobrazime jako samostatnou slevu
      returnSales.push(group);
    }
    return returnSales;
  }
}
