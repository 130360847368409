import {Component, OnDestroy, OnInit} from '@angular/core';
import {DealsService} from '@app/services/deals.service';
import {Deal} from '@app/models/deal.model';
import {TimelineTasksService} from '@app/services/timeline-tasks.service';
import {TimelineActivityService} from '@app/services/timeline-activity.service';
import {TimelineMessagesService} from '@app/services/timeline-messages.service';
import {TimelineCallsService} from '@app/services/timeline-calls.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-deal-timeline',
  templateUrl: './deal-timeline.component.html',
  styleUrls: ['./deal-timeline.component.scss']
})

export class DealTimelineComponent implements OnInit, OnDestroy {

  public deal: Deal;
  private subs$: Subscription[] = [];

  constructor(
    private dealsService: DealsService,
    public timelineTasksService: TimelineTasksService,
    public timelineActivityService: TimelineActivityService,
    public timelineMessagesService: TimelineMessagesService,
    public timelineCallsService: TimelineCallsService,
  ) {
  }

  ngOnInit(): void {
    this.subs$.push(this.dealsService.deal$.subscribe((deal: Deal) => {
      this.deal = deal;
      this.timelineTasksService.filter = {
        dealId: this.deal._id
      };
      this.timelineTasksService.loadData(0);
      this.timelineActivityService.filter = {
        dealId: this.deal._id,
      };
      this.timelineActivityService.load(0);
      this.timelineMessagesService.filter = {
        dealId: this.deal._id,
        to: {$exists: true, $ne: ''},
        isArchived: {$ne: true}
      };
      this.timelineMessagesService.load(0);
      this.timelineCallsService.filter = {
        'callContext.dealId': this.deal._id
      };
      this.timelineCallsService.load(0);
    }));
  }

  ngOnDestroy(): void {
    this.subs$.forEach((sub) => sub.unsubscribe());
    this.subs$ = [];
  }
}
