import { Injectable } from '@angular/core';
import {DataService} from './data.service';
import {Product} from '@app/models/product.model';

@Injectable({providedIn: 'root'})
export class CarInsuranceProductsService extends DataService<Product> {

  constructor(
  ) {
    super('products/car-insurance');
    this.pagination = 25;
    this.sort = {
      name: 1
    };
    this.load(0);
  }
}
