import { Pipe, PipeTransform } from '@angular/core';
import {AbstractControl, FormArray, FormControl} from '@angular/forms';
import {Contact} from '@app/models/contact.model';

@Pipe({ name: 'participanstLifeInsuranceSort', pure: true })
export class ParticipantLifeInsuranceSortPipe implements PipeTransform {
  constructor() {}
  transform(participants: AbstractControl[], contacts: Contact[]) {
    const sorted = [];
    contacts.sort((a, b) => {
      return participants.findIndex(p => p.get('contactId').value === a._id) - participants.findIndex(p => p.get('contactId').value === b._id);
    });
    const parents = contacts.filter(c => !contacts.some(parent => parent.children?.includes(c._id)));
    for (const parent of parents) {
      const participant = participants.find(p => p.get('contactId').value === parent._id);
      if (participant) {
        sorted.push(participant);
        if (parent.children?.length) {
          for (const childId of parent.children) {
            const partChild = participants.find(p => p.get('contactId').value === childId);
            if (partChild) {
              sorted.push(partChild);
            }
          }
        }
      }
    }
    return sorted;
  }
}
