import { MatDialogRef } from '@angular/material/dialog';
import {AfterViewInit, Component} from '@angular/core';

@Component({
  selector: 'app-dialog',
  template: `
    <h2 mat-dialog-title>{{ title }}</h2>
    <mat-dialog-content>{{ message }}</mat-dialog-content>
    <div class="form-row">
      <mat-form-field>
        <mat-select placeholder="Priorita" [(ngModel)]="priority">
          <mat-select-trigger style="display: flex; align-items: center;">
            <ng-container [ngTemplateOutlet]="priorityTmpl" [ngTemplateOutletContext]="{priority: priority}"></ng-container>
            <span>{{ priorityText(priority) }}</span>
          </mat-select-trigger>
          <ng-container *ngFor="let option of options">
            <mat-option [value]="option.value"><ng-container [ngTemplateOutlet]="priorityTmpl" [ngTemplateOutletContext]="{priority: option.value}"></ng-container>{{ option.label }}</mat-option>
          </ng-container>
        </mat-select>
      </mat-form-field>
    </div>
    <mat-dialog-actions>
      <button type="button" mat-button color="warn" (click)="dialogRef.close(null)">Zrušit</button>
      <button type="button" mat-raised-button color="primary" (click)="dialogRef.close(priority)" [disabled]="!prioritySelected()">OK</button>
    </mat-dialog-actions>
    <ng-template #priorityTmpl let-priority="priority">
      <mat-icon *ngIf="priority === 2" color="warn" class="priority" svgIcon="priority-highest"></mat-icon>
      <mat-icon *ngIf="priority === 1" color="warn" class="priority" svgIcon="priority-high"></mat-icon>
      <mat-icon *ngIf="priority === 0" color="primary" class="priority" svgIcon="priority-medium"></mat-icon>
      <mat-icon *ngIf="priority === -1" color="accent" class="priority" svgIcon="priority-low"></mat-icon>
      <mat-icon *ngIf="priority === -2" color="accent" class="priority" svgIcon="priority-lowest"></mat-icon>
    </ng-template>
  `,
})
export class PriorityDialogComponent implements AfterViewInit {

  public options: any[];
  public title: string;
  public message: string;
  public priority: number;

  constructor(
    public dialogRef: MatDialogRef<PriorityDialogComponent>
  ) {
  }

  ngAfterViewInit() {
    this.options.sort((a, b) => {
      return b.value - a.value;
    });
  }

  priorityText(priority) {
    return this.options.find(o => o.value === priority)?.label;
  }

  prioritySelected() {
    return Number.isInteger(this.priority);
  }
}
