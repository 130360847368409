import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { PinchZoomComponent } from './pinch-zoom.component';

@NgModule({
    declarations: [PinchZoomComponent],
    imports: [CommonModule],
    exports: [PinchZoomComponent],
})
export class PinchZoomModule {}
