import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-calling-widget-incoming',
  templateUrl: './incoming.component.html',
  styleUrls: ['./incoming.component.scss']
})
export class IncomingComponent {

  constructor(
    public dialogRef: MatDialogRef<IncomingComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ignoreCall() {
    this.dialogRef.close('ignore');
  }

  hangUpCall() {
    this.dialogRef.close('hangup');
  }
}
