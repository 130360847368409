import { Injectable } from '@angular/core';
import {BackendService} from './backend.service';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {UipathInterface} from '../interfaces/uipath.interface';

@Injectable()
export class UipathService {
  private offerId: string;
  public items: UipathInterface[] = [];
  public $items: BehaviorSubject<UipathInterface[]> = new BehaviorSubject<UipathInterface[]>(this.items);
  public items$: Observable<UipathInterface[]> = this.$items.asObservable();
  private socket: any;

  constructor(
    private backendService: BackendService
  ) {
    this.socket = this.backendService.getService('uipath');
    this.socket.on('created', (item: any) => this.created(item));
    this.socket.on('updated', (item: any) => this.updated(item));
    this.socket.on('patched', (item: any) => this.updated(item));
  }

  public find(params: any = {}) {
    return this.socket.find(params);
  }

  public get(id: string, params: any = {}) {
    return this.socket.get(id, params);
  }

  public create(item: any, params: any = {}) {
    return this.socket.create(item, params);
  }

  public async loadItems(offerId: string) {
    this.offerId = offerId;
    this.items = await this.find({query: {offerId: this.offerId}});
    this.$items.next(this.items);
  }

  private created(item: UipathInterface): void {
    if (item.offerId === this.offerId) {
      this.items.push(item);
      this.$items.next(this.items);
    }
  }

  private updated(item: UipathInterface): void {
    const uIndex = this.items.findIndex((ui: UipathInterface) => ui._id === item._id);
    if (uIndex > -1) {
      this.items[uIndex] = item;
      this.$items.next(this.items);
    }
  }
}
