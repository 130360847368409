<h2 mat-dialog-title>Duplicitní maily u žadatelů</h2>

<mat-dialog-content>
  <div class="pb-4">
    Nelze použít stejnou emailovou adresu jako už použil jiný spolužadatel!
  </div>
  <form [formGroup]="applicants | formGroup">
    <ng-container *ngFor="let applicant of applicants.controls; let i = index">
      <ng-container *ngIf="duplicates.indexOf(i) >= 0" [formGroup]="applicant | formGroup">
        <div class="form-row flex-row items-center justify-between">
          <div>
            {{ applicant.get('firstName').value }} {{ applicant.get('lastName').value }}
          </div>
          <div>
            <mat-form-field class="w-full">
              <input matInput formControlName="email"/>
            </mat-form-field>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </form>
</mat-dialog-content>

<mat-dialog-actions>
  <button type="button" mat-button color="warn" (click)="dialogRef.close(false)">Zrušit</button>
  <button type="button" mat-raised-button color="primary" [disabled]="isDuplicates()" (click)="save()">Uložit</button>
</mat-dialog-actions>
