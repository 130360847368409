import {Component, OnDestroy, OnInit} from '@angular/core';
import {DialogsService} from '@app/services/dialogs.service';
import {OffersService} from '@app/services/offers.service';
import {Offer} from '@app/models/offer.model';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-deal',
  templateUrl: 'deal.component.html',
  styleUrls: ['deal.component.scss']
})

export class DealComponent implements OnInit, OnDestroy {

  public offer: Offer;
  private subs$: Subscription[] = [];

  constructor(
    private offersService: OffersService,
    private dialogsService: DialogsService
  ) {

  }

  public ngOnInit(): void {
    this.subs$.push(this.offersService.offer$.subscribe((offer) => {
      this.offer = offer;
      this.checkinterestRateGuaranteed();
    }));
  }

  public ngOnDestroy(): void {
    this.subs$.map(s => s.unsubscribe());
  }

  private checkinterestRateGuaranteed() {
    const alertVariants = [];
    for (const mIndex in this.offer.modelations) {
      if (this.offer.modelations[mIndex]) {
        const modelation = this.offer.modelations[mIndex];
        for (const vIndex in modelation.variants) {
          if (modelation.variants[vIndex]) {
            const variant = modelation.variants[vIndex];
            if (variant.interestRateGuaranteeTo && (variant.isConfirmed || variant.isFinal)) {
              const product = variant.products[0];
              const guaranteeStage = product.source.provider.methodology.interestRate.interestRateGuaranteeStage || 'signed';
              if (new Date(variant.interestRateGuaranteeTo) < new Date() && !this.offersService.isStageBetween(this.offer.stage, guaranteeStage)) {
                alertVariants.push(this.variantIdentifier(parseInt(mIndex, null), parseInt(vIndex, null)));
              }
            }
          }
        }
      }
    }
    if (alertVariants.length) {
      this.dialogsService.alert('Rezervace sazby', 'Rezervace sazby propadla u variant ' + alertVariants.join(', ') + '.');
    }
  }

  variantIdentifier(mIndex: number, vIndex: number) {
    return `${mIndex + 1}${String.fromCharCode(65 + vIndex)}`;
  }

}
