import { MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit } from '@angular/core';
import { DocumentFile } from '@app/interfaces/document-file.interface';
import { AbstractControl, FormArray, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-dialog',
  templateUrl: './file-choose-group-dialog.component.html',
  styleUrls: ['./file-choose-group-dialog.component.scss'],
})

export class FileChooseGroupDialogComponent implements OnInit {
  public files: DocumentFile[];
  public title = '';
  public description = '';
  public sourceGroup: string;
  public groups: any;
  public documents: FormArray;
  public ready: boolean;

  public result: { sourceGroup: string, destGroup: string, files: DocumentFile[] };

  constructor(
    public dialogRef: MatDialogRef<FileChooseGroupDialogComponent>,
  ) {
    this.result = { sourceGroup: '', destGroup: '', files: [] };
  }

  ngOnInit(): void {
    if (this.documents.controls.length < 2) {
      this.ready = false;
      this.description = 'Aby bylo možné přesunout soubor, musí existovat alespoň dvě složky dokumentů.';
    } else {
      this.ready = true;
    }
  }

  public fg(control: AbstractControl): FormGroup {
    return control as FormGroup;
  }

  public passResult(destId: string) {
    this.result.files = this.files;
    this.result.sourceGroup = this.sourceGroup;
    this.result.destGroup = destId;
    this.dialogRef.close(this.result);
  }
}
