import { Injectable } from '@angular/core';

import { DialogService } from 'primeng/dynamicdialog';
import { ErrorDialogComponent } from '@app/error-handler/error-dialog.component';
import { InfoDialogComponent } from '@app/error-handler/info-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class ErrorDialogService {
  constructor(
    public dialogService: DialogService
  ) {}

  public errorVisible = false;
  public infoVisible = false;

  public errorDialog(params: any): void {
    if (!this.errorVisible) {
      const ref = this.dialogService.open(ErrorDialogComponent, {
        showHeader: false,
        closable: false,
        data: params,
        styleClass: 'bg-red-100',
        width: window.innerWidth < 740 ? '100%' : '740px',
        contentStyle: {overflow: 'auto'},
      });
      ref.onClose.subscribe((result) => {
        this.errorVisible = false;
      });
      this.errorVisible = true;
    }
  }

  public infoDialog(params: any): void {
    if (!this.infoVisible) {
      const ref =
        this.dialogService.open(InfoDialogComponent, {
          showHeader: false,
          closable: false,
          data: params,
          width: window.innerWidth < 640 ? '100%' : '640px',
          contentStyle: {overflow: 'auto'},
        });
      ref.onClose.subscribe((result) => {
        this.infoVisible = false;
      });
      this.infoVisible = true;
    }
  }
}
