import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'appliedGroupSale' })
export class ProductAppliedGroupSalePipe implements PipeTransform {
  constructor() {}
  transform(product: any, groupSales: any[]) {
    const appliedSale = product.fixation.appliedDiscounts.find(ad => groupSales.map(gs => gs._id).includes(ad._id));
    if (appliedSale) {
      return appliedSale._id;
    }
    const maxDiscount = Math.min(...groupSales.map(gs => gs.rateChange));
    groupSales = groupSales.filter(gs => gs.rateChange === maxDiscount);
    return groupSales[0]._id;
  }
}
