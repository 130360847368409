<mat-list>
  <ng-container *ngFor="let group of groups$ | async">
    <div mat-subheader *ngIf="group | dateDesc">{{group | dateDesc:true }}</div>
    <mat-list-item *ngFor="let call of ((groupedCalls$ | async)?.[group])" [ngClass]="{missed: call.direction === 'in' && !call.answered, hasdeal: call._deals?.[0], hasprovider: call._contact?._provider, disabled: (callingWidgetService.callsDisabled$ | async)}">
      <mat-icon class="call-icon made" *ngIf="call.direction === 'out' && call.answered" mat-list-icon>call_made</mat-icon>
      <mat-icon class="call-icon received" *ngIf="call.direction === 'in' && call.answered" mat-list-icon>call_received</mat-icon>
      <mat-icon class="call-icon missed-outgoing" *ngIf="call.direction === 'out' && call.answered !== undefined && !call.answered" mat-list-icon>call_missed_outgoing</mat-icon>
      <mat-icon class="call-icon missed" *ngIf="call.direction === 'in' && call.answered !== undefined && !call.answered" mat-list-icon>call_missed</mat-icon>
      <div mat-line>
        <a *ngIf="call._contact" (click)="$event.stopPropagation();" [routerLink]="['/contacts', call._contact._id]">{{ call | callsContactName }}</a>
        <span *ngIf="!call._contact">{{call.phoneNumber}}</span>
      </div>
      <div mat-line *ngIf="call._contact?._provider as _provider" class="flex flex-col">
        <div class="flex flex-row">
          <mat-icon class="provider-icon">account_balance</mat-icon>{{ _provider.name }}
        </div>
      </div>
      <div mat-line *ngIf="call._deals[0] as _deal" class="flex flex-col">
        <div class="flex flex-row">
          <a (click)="$event.stopPropagation();" [routerLink]="_deal._pipelineUrl === 'mortgage' ? ['/deals', _deal._id,'offers'] : ['/cases', _deal._pipelineUrl, _deal._id]">{{ _deal | caseNumber }}</a>
          <div *ngIf="_deal.amount">&nbsp;-&nbsp;{{mainService.maskCurrency(_deal.amount)}} Kč</div>
        </div>
        <div class="flex flex-row">
          <b>
            {{_deal.stage | caseStage:pipelinesService.getPipeline(_deal._pipelineUrl)?.stages}}
            <span *ngIf="_deal.cancelReason">{{_deal.cancelReason | caseCancelReasonLabel: _deal._pipelineUrl }}</span>
            <span *ngIf="_deal.postponeReason">{{_deal.postponeReason | casePostponeReasonLabel: _deal._pipelineUrl}}</span>
          </b>
        </div>
      </div>
      <div mat-line>
        <div class="call-info-time">
          <span *ngIf="call.direction === 'in' && call.answered !== undefined && !call.answered">zmeškaný hovor</span>
          <span *ngIf="call.direction === 'out' && call.answered !== undefined && !call.answered">volaný nezvedl</span>
          <span *ngIf="call.direction === 'in' && call.answered">příchozí hovor</span>
          <span *ngIf="call.direction === 'out' && call.answered">odchozí hovor</span>
          <span>&nbsp;-&nbsp;<b>{{ call.callTime | date:'HH:mm:ss'}}</b></span>
        </div>
        <div class="call-info-duration">
          <span *ngIf="!call.answered">zvonilo <b>{{ call.direction === 'out' ? call.ringingTime : call.waitingTime }} sekund</b></span>
          <span *ngIf="call.answered">délka hovoru <b>{{ call.duration | secondsToMinutes }}</b></span>
        </div>
      </div>
      <div class="call-buttons">
        <app-dial-button [widget]="true"
                         [compact]="true"
                         [phoneNumber]="call.phoneNumber"
                         [contactId]="call.contactId"
                         (clickDial)="dialPhone($event)">
        </app-dial-button>
      </div>
    </mat-list-item>
  </ng-container>
  <app-infinite-scroll *ngIf="callsService.canLoadMore$ | async" (endReached)="endReached()"></app-infinite-scroll>
</mat-list>
