import { Pipe, PipeTransform } from '@angular/core';
import {AbstractControl, FormArray, FormGroup} from '@angular/forms';

@Pipe({ name: 'relationLeadApplicantGroup' })
export class RelationLeadApplicantGroupPipe implements PipeTransform {
  constructor() {}
  transform(formArray: FormArray, contactId: string): AbstractControl {
    return formArray.controls.find((group: FormGroup) => group.get('contactId').value === contactId);
  }
}
