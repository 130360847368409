import { Component, OnInit } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { appInfo } from '@app/modules/core.module';

@Component({
  selector: 'app-error-dialog',
  templateUrl: './error-dialog.component.html',
})
export class ErrorDialogComponent implements OnInit {
  public param: any;
  public appInfo = appInfo;

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public clipboard: Clipboard,
  ) {}

  public ngOnInit(): void {
    this.param = this.config.data;
  }

  public close(): void {
    this.ref.close(false);
  }

  public copyMsg() {
    const msg: string[] = [];
    msg.push('Něco se pokazilo...');
    if (this.param.title) {
      msg.push('Chyba: ' + this.param.title);
    }
    if (this.param.message) {
      msg.push('Popis chyby: ' + this.param.message);
    }
    msg.push('Verze: ' + this.appInfo.version + ':' + this.appInfo.env);
    msg.push('------------------------------------------------------------');
    if (this.param.stack) {
      msg.push('Stack: ');
      msg.push(this.param.stack);
    }
    const e = msg.join('\n');
    this.clipboard.copy(e);
  }
}
