import { MatDialogRef } from '@angular/material/dialog';
import { Component } from '@angular/core';

@Component({
    selector: 'app-dialog',
    template: `
      <h2 mat-dialog-title>Vybrat šablonu</h2>
      <mat-dialog-content>
        <div class="templates-preview grid grid-cols-4">
          <div (click)="dialogRef.close(template)" class="template-preview flex flex-col items-center" *ngFor="let template of templates">
            <img [src]="'https://' + template.thumbnail_url">
            <span>{{ template.name }}</span>
          </div>
        </div>
      </mat-dialog-content>
      <mat-dialog-actions>
        <button type="button" mat-button color="warn" (click)="dialogRef.close(false)">Zrušit</button>
      </mat-dialog-actions>
    `,
})
export class TemplateDialogComponent {
    templates: any[];

    constructor(
      public dialogRef: MatDialogRef<TemplateDialogComponent>
    ) {
    }
}
