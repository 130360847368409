import { Pipe, PipeTransform } from '@angular/core';
import { PipelinesService } from '@app/services/pipelines.service';

@Pipe({ name: 'casePostponeReasonLabel'})
export class CasePostponeReasonLabelPipe implements PipeTransform {
  constructor(protected pipelinesService: PipelinesService) {}
  transform(postponeReasons: string[], pipelineUrl: string) {
    const r: string[] = [];
    const pipeline = this.pipelinesService.getPipeline(pipelineUrl);
    if (pipeline && postponeReasons.length > 0) {
      postponeReasons.forEach((item) => {
        const l = pipeline.postponeReasons.find((element) => element.id === item);
        if (l) {
          r.push(l.label);
        }
      });
    }
    return r.join(', ');
  }
}
