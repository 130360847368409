import { Pipe, PipeTransform } from '@angular/core';
import {DocumentTemplateInterface} from '../interfaces/document-template.interface';
import {Provider} from '@app/models/provider.model';

@Pipe({ name: 'fromFinalVariant' })
export class ProviderFromFinalVariantPipe implements PipeTransform {
  constructor() {}
  transform(providers: Provider[], modelations: any[]) {
    const finalVariantProviders = modelations.map(m => m.variants).flat()?.filter(v => v.isFinal)?.map(v => v.products).flat().map(p => p.source?.providerId) || [];
    return providers.filter(p => finalVariantProviders.includes(p._id));
  }
}
