import { Injectable } from '@angular/core';
import {DataService} from '@app/services/data.service';
import {UsersService} from '@app/services/users.service';
import {Conversation} from '@app/models/conversation.model';

@Injectable({providedIn: 'root'})
export class ConversationsService extends DataService<Conversation> {

  constructor(
    private usersService: UsersService,
  ) {
    super('conversations');
    this.pagination = 20;
    this.sort = {
      'lastMessage.createdAt': -1
    };
    this.query = {
      users: this.usersService.user._id
    };
  }
}
