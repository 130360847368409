export class Note {
  constructor(
  public _id?: string,
  public dealId?: string,
  public documentId?: string,
  public userId?: string, // could be users._id or user-groups._id
  public text?: string,
  public plainText?: string,
  public updatedAt?: Date,
  public createdAt?: Date,
  // used only on
  public tmpText?: string) {
  }
}
