import { MatDialogRef } from '@angular/material/dialog';
import { Component } from '@angular/core';

@Component({
    selector: 'app-dialog',
    template: `
      <h2 mat-dialog-title>{{ title }}</h2>
      <mat-dialog-content>{{ message }}</mat-dialog-content>
      <mat-dialog-actions>
        <button type="button" mat-raised-button color="primary" (click)="dialogRef.close(true)">OK</button>
      </mat-dialog-actions>
    `,
})
export class AlertDialogComponent {

    public title: string;
    public message: string;

    constructor(
      public dialogRef: MatDialogRef<AlertDialogComponent>
    ) {

    }
}
