import { Component} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog',
  templateUrl: './video-call-dialog.component.html'
})
export class VideoCallDialogComponent {

  public activityType: string;

  constructor(
    public dialogRef: MatDialogRef<VideoCallDialogComponent>
  ) {}

}
