import { Injectable } from '@angular/core';
import {DocumentTemplateInterface} from '../interfaces/document-template.interface';
import {DataService} from '@app/services/data.service';

@Injectable({providedIn: 'root'})
export class DocumentTemplatesService extends DataService<DocumentTemplateInterface> {
  constructor(
  ) {
    super('document-templates');
  }
}
