import { Pipe, PipeTransform } from '@angular/core';
import {AbstractControl} from '@angular/forms';

@Pipe({ name: 'typesRealEstateInsurance', pure: false })
export class TypesRealEstateInsurancePipe implements PipeTransform {
  constructor() {}
  transform(product: any) {
    const types = [];
    try {
      product = product.getRawValue();
    } catch (e) {
    }
    if (product.types?.includes('building')) {
      types.push('STAV');
    }
    if (product.types?.includes('household')) {
      types.push('DOM');
    }
    if (product.types?.includes('responsibility')) {
      types.push('ODP');
    }
    return types.join(', ');
  }
}
