import {Injectable} from '@angular/core';
import {DataService} from './data.service';
import {SettingsService} from '@app/services/settings.service';
import {DialogsService} from '@app/services/dialogs.service';
import {MainService} from '@app/services/main.service';
import {Deal} from '@app/models/deal.model';
import {BehaviorSubject, Observable} from 'rxjs';
import {OffersService} from '@app/services/offers.service';
import {SnackbarService} from '@app/services/snackbar.service';
import {DemandsService} from '@app/services/demands.service';
import {ContactsService} from '@app/services/contacts.service';
import {AssignService} from '@app/services/assign.service';
import {UsersService} from '@app/services/users.service';
import {TimelineActivityService} from '@app/services/timeline-activity.service';

@Injectable({providedIn: 'root'})
export class DealsService extends DataService<Deal> {

  private deal: Deal = null;
  private $deal: BehaviorSubject<Deal> = new BehaviorSubject(null);
  public deal$: Observable<Deal> = this.$deal.asObservable();

  constructor(
    private settingsService: SettingsService,
    private dialogsService: DialogsService,
    private mainService: MainService,
    private offersService: OffersService,
    private snackbarService: SnackbarService,
    private demandsService: DemandsService,
    private contactsService: ContactsService,
    private usersService: UsersService,
    private assignService: AssignService,
    private activityService: TimelineActivityService,
  ) {
    super('deals');
    this.pagination = 25;
    this.query = {
      $select: [ '_id', 'numberId', 'isWatchdog', 'amount', 'stage', 'postponeAlert', 'affiliateId', 'ownerId', 'createdAt', 'priority', 'hubspot', 'phones', 'name', 'offers', '_pipelineId', '_pipelineUrl' ],
      nojoins: [ 'owner', 'affiliate', 'applicants', 'documents', 'households', 'properties' ],
      omitfields: [ 'offers', '_offers' ], // SPEEDUP
    };
    this.sort = {
      numberId: -1,
    };
    this.$updated.subscribe((deal) => {
      if (this.deal && this.deal._id === deal._id) {
        this.setDeal(deal);
      }
    });
  }

  public setDeal(deal) {
    this.deal = deal;
    this.$deal.next(this.deal);
  }

  public async changeStage() {
    await this.dialogsService.stage(this.settingsService.settings.stages, this.deal.stage).subscribe(async (result) => {
      if (result) {
        this.mainService.showLoading();
        const deal = await this.patch(this.deal._id, {stage: result.newStage});
        this.deal.stage = deal.stage;
        this.mainService.hideLoading();
        this.snackbarService.showSuccess('Stav případu byl změněn.');
      }
    });
  }

  public cancelReason() {
    const reasons = [];
    let options = 'cancelReason';
    for (const reason of this.deal.cancelReason) {
      reasons.push(this.mainService.optionLabel(this.settingsService.options[options], reason));
      options = reason + 'CancelReason';
    }
    return reasons.join(' > ');
  }

  public postponeReason() {
    const reasons = [];
    let options = 'postponeReason';
    for (const reason of this.deal.postponeReason) {
      reasons.push(this.mainService.optionLabel(this.settingsService.options[options], reason));
      options = reason + 'PostponeReason';
    }
    return reasons.join(' > ');
  }

  public async newDeal(deal: any = null, offerId = null) {
    this.dialogsService.deal(deal, offerId, this.usersService.user, this.usersService.items.filter(u => u.role === 'specialist' && !u.cases?.isDefaultOwner && !u.isBlocked && u.cases?.mortgage), this.usersService.items.filter(u => u.role === 'affiliate'), this.settingsService.options).subscribe(async (newDeal) => {
      if (newDeal) {
        this.mainService.showLoading();
        newDeal.refinancedProduct.interestRate = ((newDeal.refinancedProduct.interestRate || 0) / 100) || 0;
        newDeal.maturityMonths = newDeal.maturity;
        delete newDeal.maturity;
        const demand = await this.demandsService.create(newDeal);
        this.mainService.hideLoading();
        const newDealCreated = await this.assignService.get(demand.dealId, {query: {force: true}});
        if (newDeal.authorId && newDeal.authorId !== newDealCreated.ownerId) {
          this.dialogsService.alert('Klient jiného specialisty', 'Tento klient již byl dříve přiřazen k: ' + newDealCreated._owner?.firstName + ' ' + newDealCreated._owner?.lastName);
        } else {
          this.snackbarService.showSuccess('Případ byl úspěšně přidán.');
        }
      }
    });
  }

  public async changeOwner(deals: any[]) {
    this.mainService.showLoading();
    const _ids = deals.map((deal) => deal._id);
    const _deals = await this.find( { query: { _id: { $in: _ids }, $limit: 1000 } });

    let contacts = _deals.data.map(deal => deal._offers.reverse()[0]._applicants[0]);
    const query: any = {
      $or: [
        {duplicityToContactId: {$in: contacts?.map(c => c?._id)}}
      ],
      $limit: 1000
    };
    const duplicityContactsId = contacts.map(c => c.duplicityToContactId).filter(c => c);
    if (duplicityContactsId) {
      query.$or.push({
        _id: {$in: duplicityContactsId}
      });
    }
    const duplicateContacts = (await this.contactsService.find({query}))?.data || [];
    contacts = [...contacts, ...duplicateContacts];
    const contactsId = contacts.map(o => o._id);
    const offers = (await this.offersService.find({query: {
        'applicants.0': {$in: contactsId},
        $limit: 1000
      }})).data.map(o => o._id);
    const allDeals = offers.length ? (await this.find({query: {
        offers: {$in: offers},
        ownerId: {$ne: null, $exists: true},
        $limit: 1000
      }})).data : [];
    const users = this.usersService.items.filter(u => u.role === 'specialist' && u.cases?.mortgage);
    const stages = Object.keys(this.settingsService.settings.stages).map(key => ({id: key, label: this.settingsService.settings.stages[key]}));
    this.dialogsService.owner(allDeals, users, {mortgage: stages}, contacts).subscribe(async (result) => {
      if (result) {
        const {ownerId, patchContactsId} = result;
        if (ownerId) {
          for (const contactId of patchContactsId) {
            await this.contactsService.patch(contactId, {changePipelineOwner: ownerId});
          }
          this.snackbarService.showSuccess('Změna vlastníka proběhla úspěšně.');
        }
      }
      this.mainService.hideLoading();
    });
  }


  public async watchdog(deal): Promise<any> {
    return new Promise((resolve) => {
      if (deal.stage === 'postponed') {
        this.dialogsService.watchdog(deal).subscribe(async (updatedDeal) => {
          if (updatedDeal) {
            const patchedDeal = await this.patch(deal._id, updatedDeal);
            this.setDeal(patchedDeal);
            this.snackbarService.showSuccess('Změna byla uložena.');
            resolve(patchedDeal);
          } else {
            resolve(null);
          }
        });
      } else {
        this.snackbarService.showError('Změna možná pouze v odloženu.');
        resolve(null);
      }
    });
  }

  public async videoCall(deal: Deal) {
    this.dialogsService.videoCall().subscribe(async (actType) => {
      if (actType) {
        const activity = {
          'pipelineId': deal._pipelineId,
          'dealId': deal._id,
          'userId': this.usersService.user._id,
          'type': actType,
        };
        await this.activityService.create(activity);
        this.snackbarService.showSuccess('Úspěšně zaznamenáno.');
      }
    });
  }
}
