import {Inject} from '@angular/core';
import {CoreModule} from '../modules/core.module';

export class CrudService {
  protected service: any;
  protected apiService: any;

  constructor(
    @Inject('servicePath') protected servicePath: string,
    @Inject('serviceType') protected serviceType: any
  ) {
    this.apiService = CoreModule.injector.get(serviceType);
    this.service = this.apiService.service(servicePath);
  }

  public find(params: any = {}): any {
    return this.service.find(params);
  }

  public get(id: string, params: any = {}): any {
    return this.service.get(id, params);
  }

  public create(item: any, params: any = {}): any {
    return this.service.create(item, params);
  }

  public patch(id: string | null, item: any, params: any = {}): any {
    if (id && item.isPlatformChange === undefined) {
      item['isPlatformChange'] = true;
    }
    return this.service.patch(id, item, params);
  }

  public remove(id: string, params: any = {}): any {
    return this.service.remove(id, params);
  }
}
