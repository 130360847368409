import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'activityDeal'})
export class ActivityDealPipe implements PipeTransform {
  constructor(
  ) {}
  transform(data: any, pipelines: any[]) {
    const pipeline = pipelines.find(p => p._id === data.pipelineId);
    const deal = {
      number: (pipeline.url === 'life-insurance' ? 'Z' : pipeline.url === 'car-insurance' ? 'A' : pipeline.url === 'real-estate-insurance' ? 'M' : 'H') + data.numberId,
      url: pipeline.url === 'mortgage' ? `/deals/${data.dealId}` : `/cases/${pipeline.url}/${data.dealId}`
    };
    return deal;
  }
}
