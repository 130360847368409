import { Pipe, PipeTransform } from '@angular/core';
import {DocumentTemplateInterface} from '../interfaces/document-template.interface';

@Pipe({ name: 'providerLowestRate' })
export class ProviderLowestratePipe implements PipeTransform {
  constructor() {}
  transform(lowestInterestRateProducts: any[]) {
    const products = [];
    for (const product of lowestInterestRateProducts || []) {
      products.push(`${product.name}\nfixace: ${product.fixations.join(', ')}\nslevy: ${product.sales.join(', ')}`);
    }
    return products.join('\n\n');
  }
}
