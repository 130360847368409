import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SettingsService } from '@app/services/settings.service';

@Component({
  selector: 'app-priority-button',
  templateUrl: './priority-button.component.html',
  styleUrls: ['./priority-button.component.scss']
})
export class PriorityButtonComponent {

  @Input() priority: number;
  @Input() enableMenu = false;
  @Input() styleClass = '';

  @Output() readonly priorityChange= new EventEmitter<number>();

  constructor(public settingsService: SettingsService) {}

  public changePriority(value: number) {
    this.priorityChange.emit(value);
  }
}
