import { MatDialogRef } from '@angular/material/dialog';
import { Component } from '@angular/core';

@Component({
    selector: 'app-dialog',
    template: `
        <h2 mat-dialog-title>Načtení změn</h2>
        <mat-dialog-content>Případ byl změněn někým jiným. Pokračujte kliknutím na tlačítko, které je načte. Vaše neuložené změny nebudou uloženy!</mat-dialog-content>
        <mat-dialog-actions>
          <button type="button" mat-raised-button color="primary" (click)="dialogRef.close(true)">Načíst změny</button>
        </mat-dialog-actions>
    `,
})
export class RefreshDialogComponent {

    public view: string;

    constructor(
      public dialogRef: MatDialogRef<RefreshDialogComponent>
    ) {

    }
}
