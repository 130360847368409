import {AfterViewInit, Component, Input, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {IncomingComponent} from '@app/components/callingwidget/incoming/incoming.component';
import {CallsService} from '@app/services/calls.service';
import {ContactNamePipe} from '@app/pipes/contact-name.pipe';
import {Call} from '@app/models/calls.model';
import {UsersService} from '@app/services/users.service';
import {PipelinesService} from '@app/services/pipelines.service';
import {MainService} from '@app/services/main.service';
import {DealsService} from '@app/services/deals.service';
import {CasesService} from '@app/services/cases.service';
import {MissedCallsService} from '@app/services/missed-calls.service';
import {MissedConversationsService} from '@app/services/missed-conversations.service';
import {MatTabGroup} from '@angular/material/tabs';
import {ConversationsService} from '@app/services/conversations.service';
import {CallingWidgetService} from '@app/components/callingwidget/callingwidget.service';

@Component({
    selector: 'app-calling-widget',
    templateUrl: 'callingwidget.component.html',
    styleUrls: ['callingwidget.component.scss']
})

export class CallingWidgetComponent implements AfterViewInit{

  @Input() public status: string = 'idle';
  @Input() public opened: boolean = false;
  selectedTab = 0;
  @ViewChild('tabs') tabs: MatTabGroup;
  constructor(
    private dialog: MatDialog,
    public callsService: CallsService,
    private contactNamePipe: ContactNamePipe,
    public pipelinesService: PipelinesService,
    public usersService: UsersService,
    public mainService: MainService,
    public dealsService: DealsService,
    public casesService: CasesService,
    public missedCallsService: MissedCallsService,
    public missedConversationsService: MissedConversationsService,
    public conversationsService: ConversationsService,
    public callingWidgetService: CallingWidgetService
  ) {
    this.callsService.incomingCall$.subscribe((call) => {
      if (call && this.callsService.activeCall) {
        this.incomingDialog(call);
      }
    });

  }
  ngAfterViewInit() {
    this.callingWidgetService.openConversation$.subscribe(() => {
      this.tabs.selectedIndex = 1;
      this.opened = true;
    });
    this.tabs.selectedTabChange.subscribe((event) => {
      if (this.selectedTab === 0) { // Pokud odcházíme z prvního panelu
        this.callsService.patch(null, {unseen: false}, {query: {unseen: true, userId: this.usersService.user._id}});
      }
      if (this.selectedTab === 1) { // Pokud odcházíme z druhého panelu
        this.callingWidgetService.leaveConversation();
      }
      const tabIndex = event.index;
      this.selectedTab = tabIndex;
    });
  }

  toggle() {
      this.opened = !this.opened;
  }

  async incomingDialog(call: Call): Promise<void> {
    const dialogRef = this.dialog.open(IncomingComponent, {
      disableClose: true,
      data: {
        name: this.contactNamePipe.transform(call._contact),
      }
    });
    const result = await dialogRef.afterClosed().toPromise();
    switch (result) {
      case 'ignore':
        await this.callsService.ignoreCall(call);
        break;
      case 'hangup':
        await this.callsService.hangUpCall(call);
        break;
      default:
    }
  }

  async newDeal(deal) {
    if (deal?._pipelineUrl !== 'mortgage') {
      if (!deal._contacts) {
        deal = await this.casesService.getCase(deal._id, deal._pipelineUrl);
      }
      await this.dealsService.newDeal(deal);
    } else {
      if (!deal._offers) { // Nemáme del s offerama tak ho načteme
        deal = await this.dealsService.get(deal._id);
      }
      const offerId = deal.offers?.[deal.offers.length - 1];
      await this.dealsService.newDeal(deal, offerId);
    }
  }

  async newCase(deal) {
    if (deal?._pipelineUrl !== 'mortgage') {
      if (!deal._contacts) {
        deal = await this.casesService.getCase(deal._id, deal._pipelineUrl);
      }
    } else {
      if (!deal._offers) { // Nemáme del s offerama tak ho načteme
        deal = await this.dealsService.get(deal._id);
      }
    }
    await this.casesService.addCase(deal, deal._pipelineUrl);
  }
}
