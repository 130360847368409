import { Injectable } from '@angular/core';
import { ReCaptchaV3Service } from 'ng-recaptcha';

@Injectable({
  providedIn: 'root'
})
export class RecaptchaService {

  constructor(
    private reCaptchaV3Service: ReCaptchaV3Service
  ) {}

  public async check(action: string): Promise<string> {
    let gcToken: string = '';
    try {
      gcToken = await this.reCaptchaV3Service.execute(action).toPromise() ?? '';
    } catch (e) {
      console.error('hyponamiru: Error executing reCAPTCHA service.');
    }
    return gcToken;
  }
}
