import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output
} from '@angular/core';
import { AbstractControl, FormArray, FormGroup } from '@angular/forms';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { DocumentFile } from '@app/interfaces/document-file.interface';
import { DialogsService } from '@app/services/dialogs.service';
import { UsersService } from '@app/services/users.service';
import { FilesService } from '@app/services/files.service';
import { SnackbarService } from '@app/services/snackbar.service';
import { DocumentInterface } from '@app/interfaces/document.interface';
import { Platform } from '@angular/cdk/platform';

export interface FileListSelectEvent {
  documentId?: string;
  files?: any[];
}

export interface FileListMoveEvent {
  sourceGroup?: string;
  files?: DocumentFile[];
}

export interface FileListUploadEvent {
  response: any,
  isDeliveredByUs: boolean;
}

@Component({
  selector: 'app-file-list',
  templateUrl: './file-list.component.html',
  styleUrls: ['./file-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileListComponent implements OnChanges {

  public previewVisible = false;
  public previewIndex = 0;
  public previewFiles: DocumentFile[];

  public documentFormGroup: FormGroup;
  public filesFormArray: FormArray;
  public documentId: string;

  public canDisplayFile = false;
  public canDisplayPdf = false;
  public filesInfo: DocumentFile[];
  public isCheckedAll = false;
  public isCheckedSome = false;
  public hasClientFiles = false;
  public hasPlatformFiles = false;

  public uploadVisible = false;
  public uploadForSign = false;
  public uploadTitle = '';

  @Input() public isInternal = false;
  @Input() public documentControl: AbstractControl;
  @Input() public documentItem: DocumentInterface | null;
  @Input() public refresh: number;
  @Input() public readonly = false;
  @Output() readonly selectFiles: EventEmitter<FileListSelectEvent> = new EventEmitter<FileListSelectEvent>();
  @Output() readonly moveFiles: EventEmitter<FileListMoveEvent> = new EventEmitter<FileListMoveEvent>();
  @Output() readonly refreshFiles: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private changeDetectorRef: ChangeDetectorRef,
              public usersService: UsersService,
              private filesService: FilesService,
              private snackbarService: SnackbarService,
              private dialogsService: DialogsService,
              private platform: Platform) {
  }

  public ngOnChanges(): void {
    this.canDisplayFile = this.usersService.isPrivileged('documents:get');
    this.canDisplayPdf = !this.platform.SAFARI;
    if (!this.documentItem) {
      this.documentFormGroup = this.documentControl as FormGroup;
      this.documentId = this.documentFormGroup.get('_id').value;
      this.filesFormArray = this.documentFormGroup.get('files') as FormArray;
      this.filesInfo = [];
      if (this.filesFormArray.value) {
        this.filesFormArray.value.forEach((file: any) => {
          const {type, icon} = this.filesService.getFileInfo(file.name, file.joinedFiles);
          if (file.joinedFiles) {
            file.joinedFiles.forEach((joinedFile: any, index) => {
              const jf = this.filesService.getFileInfo(joinedFile.name);
              file.joinedFiles[index].icon = jf.icon;
              file.joinedFiles[index].type = jf.type;
            });
          }
          this.filesInfo.push({...file, isSelected: false, isExpanded: false, type, icon, hasUserFiles: this.checkUserFiles(file)});
        });
      }
    } else {
      this.documentFormGroup = null;
      this.documentId = this.documentItem._id;
      this.filesInfo = [];
      if (this.documentItem.files) {
        this.documentItem.files.forEach((file: any) => {
          const {type, icon} = this.filesService.getFileInfo(file.name, file.joinedFiles);
          if (file.joinedFiles) {
            file.joinedFiles.forEach((joinedFile: any, index) => {
              const jf = this.filesService.getFileInfo(joinedFile.name);
              file.joinedFiles[index].icon = jf.icon;
              file.joinedFiles[index].type = jf.type;
            });
          }
          this.filesInfo.push({...file, isSelected: false, isExpanded: false, type, icon, hasUserFiles: this.checkUserFiles(file)});
        });
      }
    }

    this.checkFiles();
  }

  public fileHidden(isHidden: boolean, index: number, index2: number | null): void {
    const docFiles = this.filesFormArray.value;
    if (index2 === null) {
      this.filesInfo[index].isHidden = isHidden;
      docFiles[index].isHidden = isHidden;
    } else {
      this.filesInfo[index].joinedFiles[index2].isHidden = isHidden;
      docFiles[index].joinedFiles[index2].isHidden = isHidden;
    }
    this.filesFormArray.patchValue(docFiles);
    this.filesFormArray.markAsDirty();
  }

  public drop(event: CdkDragDrop<any>): void {
    this.moveItem(event.previousIndex, event.currentIndex);
  }

  public setAll(checked: boolean): void {
    if (this.filesInfo) {
      this.filesInfo.forEach(el => el.isSelected = checked);
    }
    this.checkFiles();
  }

  public setSelected(findex: number): void {
    this.filesInfo[findex].isSelected = !this.filesInfo[findex].isSelected;
    this.checkFiles();
  }

  public checkFiles(): void {
    if (this.filesInfo) {
      this.isCheckedAll = this.filesInfo.every(el2 => el2.isSelected);
      if (this.isCheckedAll) {
        this.isCheckedSome = false;
      } else {
        this.isCheckedSome = this.filesInfo.some(el1 => el1.isSelected);
      }
      this.hasClientFiles = this.filesInfo.some(file => file.isPlatformChange !== true);
      this.hasPlatformFiles = this.filesInfo.some(file => file.isPlatformChange === true);
    } else {
      this.isCheckedSome = false;
      this.isCheckedAll = false;
      this.hasClientFiles = false;
      this.hasPlatformFiles = false;
    }

    const paths = [];
    this.filesInfo.forEach((file) => {
      if (!this.isCheckedSome || file.isSelected) {
        paths.push(file.path);
      }
    });
    this.selectFiles.emit({documentId: this.documentId, files: paths});
  }

  public setExpanded(findex: number, value: boolean): void {
    this.filesInfo[findex].isExpanded = value;
  }

  public moveItem(previousIndex: number, newIndex: number): void {
    const item = this.filesInfo.splice(previousIndex, 1);
    this.filesInfo.splice(newIndex, 0, item[0]);

    const docFiles = this.filesFormArray.value;
    const itemv = docFiles.splice(previousIndex, 1);
    docFiles.splice(newIndex, 0, itemv[0]);
    this.filesFormArray.patchValue(docFiles);
    this.filesFormArray.markAsDirty();
  }

  public showPreview(file: any, rootFiles: boolean): void {
    if (this.canDisplayFile) {
      if ((this.canDisplayPdf && file.type === 'pdf') || ( file.type === 'image')) {
        this.previewFiles = []; // we must force changes - ChangeDetection.OnPush
        if (rootFiles) {
          this.previewFiles = this.filesInfo.filter((fi: any) => (fi.type === 'image') || (fi.type === 'pdf' && this.canDisplayPdf));
          this.previewIndex = this.previewFiles.findIndex((el) => el.path === file.path);
        } else {
          this.previewFiles = [file];
          this.previewIndex = 0;
        }
        this.previewVisible = true;
      } else {
        this.filesService.get(file.path, file.name);
      }
    }
  }

  public removeFile(index: number): void {
    const msg = `Chcete opravdu smazat soubor '${this.filesInfo[index].name}' ?`;
    this.dialogsService.confirm('Smazat soubor', msg).subscribe((confirm) => {
      if (confirm) {
        this.filesInfo.splice(index, 1);
        const docFiles = this.filesFormArray.value;
        docFiles.splice(index, 1);
        this.filesFormArray.patchValue(docFiles);
        this.filesFormArray.markAsDirty();
        this.changeDetectorRef.detectChanges();
        this.checkFiles();
      }
    });
  }

  public downloadFiles(): void {
    const paths = [];
    if (this.filesInfo.length > 0) {
      if (this.isCheckedAll === false && this.isCheckedSome === true) {
        this.filesInfo.forEach((file) => {
          if (file.isSelected) {
            paths.push(file.path);
          }
        });
      }
    }
    this.filesService.exportFiles(this.documentId, paths);
  }

  public splitFile(index: number): void {
    const msg = `Chcete opravdu rozpojit soubor '${this.filesInfo[index].name}' ?`;
    this.dialogsService.confirm('Rozpojit soubory', msg).subscribe((confirm) => {
      if (confirm) {
        const joined = this.filesInfo[index].joinedFiles;
        joined.forEach((fj) => { delete fj.parent; });
        const docFiles = this.filesFormArray.value;
        this.filesInfo.push(...joined);
        docFiles.push(...joined);

        const fi = this.filesInfo[index];
        fi.icon = 'picture_as_pdf';
        fi.type = 'pdf';
        fi.isJoined = false;
        fi.isExpanded = false;
        fi.hasUserFiles = false;
        delete fi.joinedFiles;

        this.filesInfo[index] = fi;
        docFiles[index] = fi;

        this.filesFormArray.patchValue(docFiles);
        this.filesFormArray.markAsDirty();
        this.changeDetectorRef.detectChanges();
        this.checkFiles();
      }
    });
  }

  public joinFile(): void {
    const tmp: DocumentFile[] = [];
    let warn = false;
    let ready = true;
    let count = 0;
    let description = 'Spojením souborů vznikne nový pdf soubor, který bude obsahovat Vámi zvolené soubory.';

    this.filesInfo.forEach((file, index) => {
      if (!this.isCheckedSome || file.isSelected) {
        if ((file.type !== 'image' && file.type !== 'pdf')) {
          file.warn = true;
          file.remark = 'Spojit lze pouze obrázky nebo pdf soubory.';
          warn = true;
        } else if (!!file.isJoined) {
          file.warn = true;
          file.remark = 'Již spojený soubor nelze znova spojit.';
          warn = true;
        } else {
          file.warn = false;
          count++;
        }
        tmp.push({ ...file, index });
      }
    });

    if (warn) {
      description = description + ' Červeně označené soubory nebudou spojeny.';
    }

    if (count < 2) {
      description = 'Musíte vybrat alespoň 2 soubory pro spojení.';
      ready = false;
    }

    this.dialogsService.fileJoinDialog(description, 'SpojenySoubor.pdf', ready, tmp).subscribe((result) => {
      if (result) {
        this.performJoin(result.fileName, result.files);
      }
    });
  }

  public async performJoin(fileName: string, files: DocumentFile[]): Promise<void> {
    if (fileName === '') {
      fileName = 'SpojenySoubor.pdf';
    } else {
      if (!fileName.endsWith('.pdf')) {
        const tmp = fileName.split('.');
        tmp[tmp.length - 1] = '.pdf';
        fileName = tmp.join('.');
      }
    }
    if (fileName === '.pdf') {
      fileName = 'SpojenýSoubor.pdf';
    }

    const response = await this.filesService.mergeFiles(this.documentId, fileName, files.map((item) => item.path));
    if (response === false) {
      this.snackbarService.showError('Při spojení souborů došlo k chybě!');
      return;
    }

    const {type, icon} = this.filesService.getFileInfo(response.name, true);
    const file: DocumentFile = {
      path: response.path,
      name: response.name,
      size: response.size,
      createdAt: new Date(),
      type,
      isJoined: true,
      isHidden: false,
      isPlatformChange: true,
    };
    const file2: DocumentFile = {...file, icon, isSelected: false, isExpanded: false};
    file.joinedFiles = [];
    file2.joinedFiles = [];

    const docFiles = this.filesFormArray.value;

    for (const item of files) {
      const fi: DocumentFile = {
        path: item.path,
        name: item.name,
        size: item.size,
        createdAt: item.createdAt,
        type: item.type,
        isJoined: false,
        isHidden: item.isHidden,
        isDeliveredByUs: item.isDeliveredByUs,
        isPlatformChange: item.isPlatformChange,
      };
      const fi2: DocumentFile = {...fi, icon: item.icon, isSelected: false, isExpanded: false};
      file.joinedFiles.push(fi);
      file2.joinedFiles.push(fi2);
    }

    for (let index = files.length - 1; index >= 0; index--) {
      const elementIndex = files[index].index;
      this.filesInfo.splice(elementIndex, 1);
      docFiles.splice(elementIndex, 1);
    }

    file2.hasUserFiles = this.checkUserFiles(file2);
    this.filesInfo.push(file2);
    docFiles.push(file);

    this.filesFormArray.patchValue(docFiles);
    this.filesFormArray.markAsDirty();
    this.changeDetectorRef.detectChanges();
    this.checkFiles();
    this.snackbarService.showSuccess('Soubory byly spojeny.');
  }

  public moveFile(findex: any) {
    this.moveFiles.emit({sourceGroup: this.documentId, files: [this.filesInfo[findex]]});
  }

  public checkUserFiles(file: any): boolean {
    return file.joinedFiles && file.joinedFiles.some((file: any) => !file.isPlatformChange);
  }

  public uploadFile(forSign: boolean) {
    this.uploadTitle = forSign ? 'Nahrát soubory k podpisu' : 'Nahrát soubory';
    this.uploadForSign = forSign;
    this.uploadVisible = true;
  }

  public fileUploaded(event: any) {
    const response = event.response;
    const name = response.originalname;
    const { type, icon } = this.filesService.getFileInfo(name);
    const file = { path: response.id, name, size: response.size, createdAt: new Date(), type, isDeliveredByUs: event.isDeliveredByUs, isPlatformChange: true };
    const file2 = { ...file, isJoined: false, isHidden: false, icon, isSelected: false, isExpanded: false };

    const docFiles = this.filesFormArray.value;
    this.filesInfo.push(file2);
    docFiles.push(file);

    this.filesFormArray.patchValue(docFiles);
    this.filesFormArray.markAsDirty();
    this.checkFiles();
    this.changeDetectorRef.detectChanges();
    this.refreshFiles.emit(true);
  }

  public async shrinkFile(index: number): Promise<void> {
    const fi = this.filesInfo[index];
    if (fi) {
      const res = await this.filesService.shrinkFile(fi.name, fi.path);
      if (res) {
        res.isHidden = fi.isHidden;
        res.isDeliveredByUs = fi.isDeliveredByUs;
        res.originalName = fi.name;
        const saving = Math.round((res.size * 100) / fi.size);
        this.fileUploaded({response: res});
        this.snackbarService.showSuccess(`Soubor byl zmenšen na ${saving}% původní velikosti.`);
      }
    }
  }

  public rejectFiles() {
    const tmp: DocumentFile[] = [];
    const ready = true;
    const description = '';

    this.filesInfo.forEach((file, index) => {
      if (!this.isCheckedSome || file.isSelected) {
        {
          file.warn = false;
          tmp.push({...file, index});
        }
      }
    });

    this.dialogsService.fileRejectDialog(description, ready, tmp).subscribe((result) => {
      if (result) {
        this.performReject(result.rejectionReason, result.files);
      }
    });
  }

  public performReject(rejectionReason: string, files: DocumentFile[]): void {
    const docFiles = this.filesFormArray.value;
    for (let index = files.length - 1; index >= 0; index--) {
      const elementIndex = files[index].index;
      this.filesInfo[elementIndex].status = 'rejected';
      this.filesInfo[elementIndex].rejectionReason = rejectionReason;
      docFiles[elementIndex].status = 'rejected';
      docFiles[elementIndex].rejectionReason = rejectionReason;
    }
    this.snackbarService.showSuccess('Soubory byly zamítnuty.');
    this.filesFormArray.patchValue(docFiles);
    this.filesFormArray.markAsDirty();
    this.checkFiles();
  }

  public cancelRejectFile(index: number) {
    const docFiles = this.filesFormArray.value;
    this.filesInfo[index].status = '';
    this.filesInfo[index].rejectionReason = '';
    docFiles[index].status = '';
    docFiles[index].rejectionReason = '';
    this.filesFormArray.patchValue(docFiles);
    this.filesFormArray.markAsDirty();
    this.checkFiles();
  }
}
