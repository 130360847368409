import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {UsersService} from '../services/users.service';
import {MainService} from '../services/main.service';
import {SettingsService} from '../services/settings.service';

@Injectable()
export class LoginGuard implements CanActivate {
  constructor(
    private router: Router,
    private usersService: UsersService,
    private mainService: MainService,
    private settingsService: SettingsService
  ) {

  }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const url: string = state.url;
    return this.checkLogin(url);
  }

  public checkLogin(url: string): Promise<boolean> {
    return new Promise(async (resolve) => {
      const authenticated = await this.usersService.checkLogin();
      if (authenticated) {
        if (url === '/' || url.indexOf('/login') === 0) {
          this.router.navigate(['/deals']).then(() => null);
          resolve(false);
        } else {
          await this.settingsService.loadSettings();
          await this.usersService.loadAll();
          resolve(true);
        }
      } else {
        if (url.indexOf('/login') === 0) {
          resolve(true);
        } else {
          this.router.navigate(['/login'], {queryParams: {redirect: url}}).then(() => null);
          resolve(false);
        }
      }
    });
  }
}
// http://localhost:4200/deals/5ed4c52d4dc90d00186fd65e/offers/5f98082e54d764001917fee0/modelations
