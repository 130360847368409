import { MatDialogRef } from '@angular/material/dialog';
import { Component } from '@angular/core';

@Component({
  selector: 'app-dialog',
  template: `
    <h2 mat-dialog-title>Odložit</h2>
    <mat-dialog-content style="min-width: 500px;">
      <div class="form-row" *ngFor="let reasonOption of reasonOptions; let reasonIndex = index">
        <mat-form-field>
          <mat-select placeholder="Důvod" [(ngModel)]="result.reasons[reasonIndex]" (selectionChange)="updateOptions(reasonIndex)">
            <ng-container *ngFor="let option of (options[reasonOption] | optionsFilter: {params: {isArchived: false}}:'label')">
              <mat-option [value]="option.value">{{ option.label }}</mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>
        <mat-form-field>
          <input matInput placeholder="Odložit do" [matDatepicker]="postponeDate" [(ngModel)]="result.postponeDate" />
          <mat-datepicker-toggle matSuffix [for]="postponeDate"></mat-datepicker-toggle>
          <mat-datepicker #postponeDate></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="form-row" style="padding-bottom: 1rem;">
          <mat-slide-toggle [(ngModel)]="result.task" (ngModelChange)="taskChanged()">Vytvořit úkol</mat-slide-toggle>
      </div>
      <div class="form-row" *ngIf="result.task">
        <mat-form-field>
          <input matInput placeholder="Nadpis úkolu - Kontrola odložena" [(ngModel)]="result.taskTitle" />
        </mat-form-field>
      </div>
      <div class="form-row" *ngIf="result.task">
        <mat-form-field>
          <input matInput placeholder="Popis úkolu" [(ngModel)]="result.taskText" />
        </mat-form-field>
      </div>
      <div class="form-row" *ngIf="result.task">
        <mat-form-field>
          <input matInput placeholder="Datum úkolu" [matDatepicker]="taskDate" [(ngModel)]="result.taskDate" />
          <mat-datepicker-toggle matSuffix [for]="taskDate"></mat-datepicker-toggle>
          <mat-datepicker #taskDate></mat-datepicker>
        </mat-form-field>
        <mat-form-field>
          <mat-select placeholder="Hodin" [(ngModel)]="result.taskHours">
            <ng-container *ngFor="let value of hourOptions">
              <mat-option [value]="value">{{ pad(value, 2) }}</mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>
        <mat-form-field>
          <mat-select placeholder="Minut" [(ngModel)]="result.taskMinutes">
            <ng-container *ngFor="let value of minuteOptions">
              <mat-option [value]="value">{{ pad(value, 2) }}</mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="form-row" style="padding-bottom: 1rem;">
        <mat-slide-toggle [(ngModel)]="result.autoCommunicate" (ngModelChange)="autoCommunicateChanged()">Automaticky komunikovat</mat-slide-toggle>
      </div>
      <ng-container *ngIf="result.autoCommunicate">
        <label class="form-label">Komunikace</label>
        <div class="form-row">
          <mat-radio-group [(ngModel)]="result.communicationType">
            <mat-radio-button [value]="0">Krátkodobá</mat-radio-button>
            <mat-radio-button [value]="1">Střednědobá</mat-radio-button>
            <mat-radio-button [value]="2">Dlouhodobá</mat-radio-button>
          </mat-radio-group>
        </div>
      </ng-container>
    </mat-dialog-content>
    <mat-dialog-actions>
      <button type="button" mat-button color="warn" (click)="dialogRef.close(null)">Zrušit</button>
      <button type="button" mat-raised-button color="primary" [disabled]="!result.reasons || result.reasons.length !== reasonOptions.length || !result.postponeDate || (result.task && (!result.taskText || !result.taskDate))" (click)="dialogRef.close(result)">Potvrdit</button>
    </mat-dialog-actions>
  `,
})

export class PostponeDialogComponent {

  public options: any;
  public result: {reasons: any[], postponeDate: Date, task: boolean, taskTitle: string, taskText: string, taskDate: Date, taskHours: number, taskMinutes: number, autoCommunicate: boolean, communicationType: number};
  public reasonOptions: string[];
  public hourOptions: number[] = Array.from(Array(23).keys());
  public minuteOptions: number[] = [0, 15, 30, 45];

  constructor(
    public dialogRef: MatDialogRef<PostponeDialogComponent>,
  ) {
    this.options = [];
    this.reasonOptions = ['postponeReason'];
    this.result = {
      reasons: [],
      task: false,
      taskTitle: '',
      taskText: '',
      postponeDate: null,
      taskDate: null,
      taskHours: 12,
      taskMinutes: 0,
      autoCommunicate: true,
      communicationType: 0
    };
  }

  public updateOptions(index) {
    const option = this.options.postponeReason.find((o: any) => o.value === this.result.reasons[index]);
    if (option) {
      this.result.communicationType = option.params.sendRate;
    }
    this.result.taskTitle = this.options.postponeReason.find((o: any) => o.value === this.result.reasons[index]).label;
    this.result.reasons.splice(index + 1);
    this.reasonOptions.splice(index + 1);
    const nextOptions = this.result.reasons[index] + 'PostponeReason';
    if (this.options[nextOptions]) {
      this.reasonOptions.push(nextOptions);
    }
  }

  public pad(num: number, size: number): string {
    let s = num + '';
    while (s.length < size) {
      s = '0' + s;
    }
    return s;
  }

  autoCommunicateChanged() {
    if (!this.result.autoCommunicate && !this.result.task) {
      this.result.task = true;
    }
  }

  taskChanged() {
    if (!this.result.task && !this.result.autoCommunicate) {
      this.result.autoCommunicate = true;
    }
  }
}
