<div class="offer-form" [formGroup]="offersService.offerForm">
  <div class="edit-wrap">
    <div class="edit">
      <section>
        <div class="card-title">
          <span>Základní parametry úvěru</span>
        </div>
        <div class="card">
          <div class="form-row">
            <mat-form-field class="w50">
              <mat-chip-list #chipList formControlName="purpose">
                <mat-chip *ngFor="let chip of offersService.offerForm.get('purpose').value" [selectable]="false" (removed)="mainService.removeChip(chip, offersService.offerForm.get('purpose'))" [value]="chip" color="default">{{ mainService.optionLabel(options('purpose'), chip)}}<mat-icon *ngIf="offersService.canEdit()" matChipRemove>cancel</mat-icon></mat-chip>
              </mat-chip-list>
              <input
                #chipInput
                placeholder="Účel hypotéky"
                [matChipInputSeparatorKeyCodes]="mainService.separatorKeysCodes"
                [matChipInputFor]="chipList"
                [matAutocomplete]="matAutoChip"
                [matChipInputAddOnBlur]="false"
                [disabled]="!offersService.canEdit()"
              />
              <mat-autocomplete #matAutoChip="matAutocomplete" (optionSelected)="mainService.selectedChipItem($event, offersService.offerForm.get('purpose'));chipInput.value = '';">
                <mat-option *ngFor="let item of mainService.filterChipItems(options('purpose'), offersService.offerForm.get('purpose'))" [value]="item.value">
                  {{ mainService.optionLabel(options('purpose'), item.value) }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
            <ng-container *ngIf="offersService.offerForm.get('purpose').value?.includes('refinancing')">
              <mat-form-field class="w30">
                <input matInput placeholder="Kdy refinancovat" [matDatepicker]="refinancingDate" formControlName="refinancingDate" />
                <mat-datepicker-toggle matSuffix [for]="refinancingDate"></mat-datepicker-toggle>
                <mat-datepicker #refinancingDate></mat-datepicker>
              </mat-form-field>
              <mat-checkbox class="w30" formControlName="outOfFixationDrawing">Refinancovat mimo fixaci</mat-checkbox>
            </ng-container>
          </div>
          <div class="form-row">
            <mat-form-field>
              <input matInput placeholder="Konec fixace" [matDatepicker]="fixationEndDate" formControlName="fixationEndDate" (change)="fixationEndDateChanged($event, offersService.offerForm)"/>
              <mat-datepicker-toggle matSuffix [for]="fixationEndDate"></mat-datepicker-toggle>
              <mat-datepicker #fixationEndDate></mat-datepicker>
            </mat-form-field>
            <mat-form-field>
              <!--input matInput placeholder="Den splátky" formControlName="repaymentDay"/-->
              <app-input-number placeholder="Den splátky" formControlName="repaymentDay" min="1" max="31"></app-input-number>
            </mat-form-field>
            <mat-form-field *ngIf="!offersService.offerForm.get('hasSpecificProperty').value">
              <mat-select placeholder="Doba na čerpání" formControlName="drawingPeriod" >
                <mat-option [value]="1">1 rok</mat-option>
                <mat-option [value]="2">2 roky</mat-option>
                <mat-option [value]="3">3 roky</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-checkbox class="w30" formControlName="hasSpecificProperty">Má konkrétní nemovitost</mat-checkbox>
          </div>
        </div>
      </section>
      <section>
        <div class="card-title">
          <span>Modelace ({{ offersService.modelations.controls.length }})</span>
          <div *ngIf="offersService.canEdit()">
            <button mat-button color="primary" (click)="$event.stopPropagation(); offersService.addModelation()"><span>Nová modelace</span><mat-icon>add</mat-icon></button>
          </div>
        </div>
        <mat-accordion formArrayName="modelations">
          <ng-container *ngFor="let modelation of offersService.modelations.controls; let mIndex = index;trackBy: trackByFn;">
            <mat-expansion-panel [@.disabled]="true" [formGroupName]="mIndex" [id]="'modelation' + mIndex" (afterExpand)="mainService.scrollToId('modelation' + mIndex)">
              <mat-expansion-panel-header [ngClass]="{'invalid': offersService.isInvalid(modelation, 'modelation_basic')}">
                <mat-panel-title class="middle-description">
                  <div>{{ mainService.maskCurrency(mainService.unmaskCurrency(modelation.get('amount').value)) }} Kč</div>
                  <div>LTV: {{ modelation.get('ltv').value }}%</div>
                  <div>Fixace: {{ modelation.get('fixation').value }}</div>
                  <div>Splatnost: {{ modelation.get('maturity').value | maturity }}</div>
                  <div>Pojištění: {{ modelation.get('insurance').value ? 'Ano' : 'Ne' }}</div>
                  <div>Offset: {{ modelation.get('offsetType').value ? mainService.optionLabel(options('offsetType'), modelation.get('offsetType').value) : 'Bez offsetu' }}</div>
                  <button mat-icon-button color="warn" class="hide-not-expanded" [disabled]="mIndex === 0" *ngIf="offersService.canEdit()" (click)="$event.stopPropagation(); offersService.removeModelation(mIndex)"><mat-icon>remove_circle_outline</mat-icon></button>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <ng-template matExpansionPanelContent>
                <div class="form-row">
                  <mat-form-field [ngClass]="{readonly: offersService.offerForm.get('purpose')?.value?.length > 1}">
                    <input matInput placeholder="Výše úvěru" formControlName="amount" [textMask]="{mask: mainService.currencyMask, guide: false}" [readonly]="offersService.offerForm.get('purpose')?.value?.length > 1" />
                  </mat-form-field>
                  <mat-form-field class="readonly w30">
                    <input matInput placeholder="LTV %" formControlName="ltv" readonly/>
                  </mat-form-field>
                  <mat-form-field>
                    <mat-select placeholder="Fixace" *ngIf="offersService.canEdit()" formControlName="fixation" >
                      <mat-option *ngFor="let option of options('fixation')" [value]="option.value">{{ option.label }}</mat-option>
                    </mat-select>
                    <input *ngIf="!offersService.canEdit()" type="number" matInput placeholder="Fixace" [value]="mainService.optionLabel(options('fixation'), modelation.get('fixation').value)" readonly />
                  </mat-form-field>
                  <mat-form-field class="maturity">
                    <input matInput placeholder="Splatnost" formControlName="maturityYears" /><span>r</span>
                    <span class="space">, </span>
                    <input matInput formControlName="maturityMonths" (click)="$event.stopPropagation()" #maturityMonths /><span (click)="maturityMonths.focus();$event.stopPropagation()">m</span>
                  </mat-form-field>
                  <mat-form-field>
                    <mat-select placeholder="Pojištění" formControlName="insurance" *ngIf="offersService.canEdit()">
                      <mat-option [value]="true">Ano</mat-option>
                      <mat-option [value]="false">Ne</mat-option>
                    </mat-select>
                    <input *ngIf="!offersService.canEdit()" matInput placeholder="Pojištění" [value]="modelation.get('insurance').value ? 'Ano' : 'Ne'" readonly />
                  </mat-form-field>
                  <mat-form-field>
                    <mat-select placeholder="Typ offsetu" *ngIf="offersService.canEdit()" formControlName="offsetType">
                      <mat-option *ngFor="let option of options('offsetType')" [value]="option.value">{{ option.label }}</mat-option>
                    </mat-select>
                    <input *ngIf="!offersService.canEdit()" matInput placeholder="Typ offsetu" [value]="mainService.optionLabel(options('offsetType'), modelation.get('offsetType').value)" readonly />
                  </mat-form-field>
                </div>
                <ng-container *ngIf="offersService.offerForm.get('purpose')?.value?.length > 1" formArrayName="purposes">
                  <div class="form-row" *ngFor="let purpose of (modelation.get('purposes').controls || []); let mpIndex = index" [formGroupName]="mpIndex">
                    <mat-form-field class="readonly">
                      <input matInput placeholder="Účel" [value]="mainService.optionLabel(options('purpose'), purpose.get('type').value)" readonly />
                    </mat-form-field>
                    <mat-form-field>
                      <input matInput placeholder="Výše" formControlName="amount" [textMask]="{mask: mainService.currencyMask, guide: false}" />
                    </mat-form-field>
                  </div>
                </ng-container>
                <ng-container *ngIf="offersService.modelationExpenditures(modelation).length">
                  <h3>Výdaje:</h3>
                  <div class="form-row" *ngFor="let expenditure of offersService.modelationExpenditures(modelation)">
                    {{expenditure}}
                  </div>
                </ng-container>
              </ng-template>
            </mat-expansion-panel>
          </ng-container>
        </mat-accordion>
      </section>
      <section>
        <div class="card-title" [ngClass]="{'invalid': offersService.offerForm.get('properties').invalid || offersService._propertiesForm.invalid}">
          <span>Nemovitosti ({{ offersService.properties.controls.length }})</span>
          <div>
            <button mat-button color="primary" *ngIf="offersService.canEdit()" [matMenuTriggerFor]="addPropertyMenu"><span>Přidat nemovitost</span><mat-icon>add</mat-icon></button>
          </div>
        </div>
        <mat-accordion>
          <ng-container *ngFor="let property of offersService._properties; let pIndex = index;trackBy: trackControlByFn;">
            <mat-expansion-panel [@.disabled]="true" *ngIf="offersService.propertyOfferControl(property.get('_id').value) as offerProperty" [id]="'property' + pIndex" [ngClass]="{'blue': offerProperty.get('status').value.includes('funded')}" (afterExpand)="mainService.scrollToId('property' + pIndex)">
              <mat-expansion-panel-header [ngClass]="{'invalid': property.invalid || offerProperty.invalid}">
                <mat-panel-title class="middle-description">
                  <div class="elipsis"><span>{{ mainService.optionLabel(options('propertyType'), property.get('type').value) }}</span></div>
                  <div>{{ mainService.maskCurrency(mainService.unmaskCurrency(property.get('value').value)) }} Kč</div>
                  <div>{{ offersService.propertyUsage(offerProperty) }}</div>
                  <button mat-icon-button color="warn" class="hide-not-expanded" *ngIf="offersService.canEdit()" [disabled]="offerProperty.get('status').value.includes('funded') && offersService.hasOneFunded(offersService.properties)" (click)="$event.stopPropagation(); offersService.removeProperty(property)"><mat-icon>remove_circle_outline</mat-icon></button>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <ng-template matExpansionPanelContent [formGroup]="property">
              <div class="form-section">
                <div class="form-section-title" [ngClass]="{'invalid': offerProperty.hasError('propertyUsage')}">Vlastnosti *</div>
                <div class="form-section-content">
                  <div class="form-row">
                    <mat-checkbox [checked]="offerProperty.get('status').value.includes('pledged')" (change)="offersService.propertyStatusUpdate(offerProperty, 'pledged', $event)">Zastavovaná</mat-checkbox>
                    <mat-checkbox [checked]="offerProperty.get('status').value.includes('funded')" (change)="offersService.propertyStatusUpdate(offerProperty, 'funded', $event)">Financovaná</mat-checkbox>
                    <mat-checkbox [checked]="offerProperty.get('status').value.includes('none')" (change)="offersService.propertyStatusUpdate(offerProperty, 'none', $event)">Nevstupuje do úvěru</mat-checkbox>
                  </div>
                </div>
              </div>
              <div class="form-section">
                <div class="form-section-title">Obecné</div>
                <div class="form-section-content">
                  <div class="form-row">
                    <mat-form-field >
                      <mat-select placeholder="Typ nemovitosti" formControlName="type" >
                        <mat-option *ngFor="let option of options('propertyType')" [value]="option.value">{{ option.label }}</mat-option>
                      </mat-select>
                    </mat-form-field>
                    <mat-form-field class="w50">
                      <input matInput placeholder="Hodnota" formControlName="value" [textMask]="{mask: mainService.currencyMask, guide: false}"/>
                    </mat-form-field>
                    <mat-form-field class="w50">
                      <input matInput placeholder="Užitná plocha m2" formControlName="usableArea"/>
                    </mat-form-field>
                    <mat-form-field>
                      <mat-select placeholder="Daň z NV uhrazena" formControlName="realEstateTax">
                        <mat-option [value]="true">Ano</mat-option>
                        <mat-option [value]="false">Ne</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="form-row" *ngIf="property.get('type').value === 'apartmentBuilding'">
                    <mat-form-field>
                      <input matInput placeholder="Počet jednotek" formControlName="housingUnitsCount"/>
                    </mat-form-field>
                    <mat-form-field>
                      <mat-select placeholder="Jednotky zapsány v katastru" formControlName="cadastreRegisterSeparately">
                        <mat-option [value]="true">zvlášť</mat-option>
                        <mat-option [value]="false">dohromady</mat-option>
                      </mat-select>
                    </mat-form-field>
                    <mat-form-field>
                      <input matInput placeholder="Podíl komerční plochy v %" formControlName="commercialAreaPercentage" [textMask]="{mask: mainService.percentMask, guide: false}" max="100"/>
                    </mat-form-field>
                  </div>
                  <div class="form-row">
                    <mat-checkbox formControlName="isDeveloperProject">Developerský projekt</mat-checkbox>
                    <mat-form-field *ngIf="property.get('isDeveloperProject').value">
                      <input matInput placeholder="Název projektu" formControlName="developerProject"/>
                    </mat-form-field>
                  </div>
                  <div class="form-row" style="padding-top: 1rem">
                    <mat-form-field style="width: 25%; flex: none">
                      <mat-select placeholder="Pojištění" formControlName="insured">
                        <mat-option value="byUs">Pojištěná námi</mat-option>
                        <mat-option value="elsewhere">Pojištěná jinde</mat-option>
                        <mat-option value="uninsured">Nepojištěná</mat-option>
                      </mat-select>
                    </mat-form-field>
                    <div style="width: 75%; flex: none">
                      <app-offer-policy-list [property]="property" [offerProperty]="offerProperty"></app-offer-policy-list>
                    </div>
                  </div>
                  <div class="form-row">
                    <mat-form-field>
                      <input matInput placeholder="Poznámka" formControlName="note"/>
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <div class="form-section">
                <div class="form-section-title">Vlastníci</div>
                <div class="form-section-content">
                  <ul class="form-list">
                    <ng-container *ngFor="let applicant of offersService._applicants; let aIndex = index;trackBy: trackControlByFn;">
                      <li><mat-checkbox [checked]="offersService.isPropertyOwner(property.get('_id').value, applicant.get('_id').value)" (change)="offersService.updatePropertyApplicant(property.get('_id').value, applicant.get('_id').value, $event)">{{ applicant.get('titleBefore').value }} {{ applicant.get('firstName').value }} {{ applicant.get('lastName').value }} {{ applicant.get('titleAfter').value }} (Žadatel {{ aIndex + 1 }})</mat-checkbox></li>
                    </ng-container>
                  </ul>
                </div>
              </div>
              <div class="form-section" formGroupName="address">
                <div class="form-section-title">Adresa</div>
                <div class="form-section-content">
                  <div class="form-row">
                    <mat-form-field>
                      <mat-label>Ulice <mat-icon>search</mat-icon></mat-label>
                      <input matInput formControlName="street" [matAutocomplete]="autoAddress" (ngModelChange)="ruianService.searchAddress($event)" />
                      <mat-autocomplete #autoAddress="matAutocomplete" panelWidth="auto" (optionSelected)="offersService.completeAddress(property.get('address'), $event)">
                        <mat-option *ngFor="let address of ruianService.results$ | async" [value]="address.objectId">
                          {{ address.text }}
                        </mat-option>
                      </mat-autocomplete>
                    </mat-form-field>
                    <mat-form-field class="w30">
                      <input matInput placeholder="Č. popisné" formControlName="houseNumber"/>
                    </mat-form-field>
                    <mat-form-field class="w30">
                      <input matInput placeholder="Č. orientační" formControlName="orientationNumber"/>
                    </mat-form-field>
                    <mat-form-field class="w30">
                      <app-input-mask placeholder="PSČ" formControlName="zipCode" mask="999 99" inputmode="numeric"></app-input-mask>
                    </mat-form-field>
                  </div>
                  <div class="form-row">
                    <mat-form-field>
                      <input matInput placeholder="Město" formControlName="city" [matAutocomplete]="autoCity" (ngModelChange)="ruianService.cities($event)"/>
                      <mat-autocomplete #autoCity="matAutocomplete" panelWidth="auto">
                        <mat-option *ngFor="let city of ruianService.cities$ | async" [value]="city">
                          {{ city }}
                        </mat-option>
                      </mat-autocomplete>
                    </mat-form-field>
                    <mat-form-field>
                      <input matInput placeholder="Okres" formControlName="district" [matAutocomplete]="autoDistrict" (ngModelChange)="ruianService.districts($event)"/>
                      <mat-autocomplete #autoDistrict="matAutocomplete" panelWidth="auto">
                        <mat-option *ngFor="let district of ruianService.districts$ | async" [value]="district">
                          {{ district }}
                        </mat-option>
                      </mat-autocomplete>
                    </mat-form-field>
                    <mat-form-field>
                      <input matInput placeholder="Kraj" formControlName="region"/>
                    </mat-form-field>
                    <mat-form-field>
                      <input matInput placeholder="Země" formControlName="country" [matAutocomplete]="autoCountry"/>
                      <mat-autocomplete #autoCountry="matAutocomplete" panelWidth="auto" [displayWith]="mainService.optionLabel.bind(property.get('address.country').value, options('country'))">
                        <mat-option *ngFor="let option of offersService.filterAutocompleteItems('country', property.get('address.country').value)" [value]="option.value">
                          {{ option.label }}
                        </mat-option>
                      </mat-autocomplete>
                    </mat-form-field>
                  </div>
                  <div class="form-row">
                    <mat-form-field>
                      <input matInput placeholder="Katastrální území" formControlName="cadastralTerritory"/>
                    </mat-form-field>
                    <mat-form-field>
                      <input matInput placeholder="Číslo bytové jednotky" formControlName="unitNumber"/>
                    </mat-form-field>
                    <mat-form-field>
                      <input matInput placeholder="Parcelní číslo" formControlName="parcelNumber"/>
                    </mat-form-field>
                    <mat-form-field>
                      <input matInput placeholder="List vlastninctví" formControlName="ownershipCertificate"/>
                    </mat-form-field>
                  </div>
                </div>
              </div>
              </ng-template>
            </mat-expansion-panel>
          </ng-container>
        </mat-accordion>
      </section>
      <section>
        <div class="card-title" [ngClass]="{'invalid': offersService.applicants.invalid || offersService._applicantsForm.invalid}">
          <span>Žadatelé ({{ offersService._applicants.length }})</span>
          <div *ngIf="offersService.canEdit()">
            <button mat-button color="primary" (click)="offersService.addApplicant()"><span>Nový žadatel</span><mat-icon>add</mat-icon></button>
          </div>
        </div>
        <mat-accordion>
          <ng-container *ngFor="let applicant of offersService._applicants || []; let aIndex = index;trackBy: trackControlByFn;">
            <mat-expansion-panel [@.disabled]="true" [formGroup]="applicant" class="blue" [id]="'applicant' + aIndex" (afterExpand)="mainService.scrollToId('applicant' + aIndex)">
              <mat-expansion-panel-header [ngClass]="{'invalid': applicant.invalid || offersService.isInvalid(applicant, 'applicant_basic') }">
                <mat-panel-title class="middle-description">
                  <div class="elipsis"><span>{{ applicant.get('titleBefore').value }} {{ applicant.get('firstName').value }} {{ applicant.get('lastName').value }} {{ applicant.get('titleAfter').value }} (Žadatel {{ aIndex + 1 }})</span></div>
                  <div>{{ applicant.get('age').value }} let</div>
                  <div *ngIf="offersService.offerForm.get('leadApplicantId').value === applicant.get('_id').value">Hlavní žadatel</div>
                  <div *ngIf="offersService.offerForm.get('leadApplicantId').value !== applicant.get('_id').value">Spolužadatel</div>
                  <span class="flex flex-row">
                    <button *ngIf="aIndex === 0" mat-icon-button color="primary" (click)="$event.stopPropagation();"><mat-icon>contact_emergency</mat-icon></button>
                    <button *ngIf="aIndex > 0" mat-icon-button color="warn" (click)="$event.stopPropagation(); offersService.removeApplicant(applicant.get('_id').value);"><mat-icon>remove_circle_outline</mat-icon></button>
                  </span>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <ng-template matExpansionPanelContent>
              <mat-accordion>
                <mat-expansion-panel [id]="'applicant' + aIndex + 'basicinfo'" (afterExpand)="mainService.scrollToId('applicant' + aIndex + 'basicinfo')">
                  <mat-expansion-panel-header [ngClass]="{'invalid': offersService.isInvalid(applicant, 'applicant_basic')}">
                    <mat-panel-title>
                      <div>Základní údaje</div>
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <ng-template matExpansionPanelContent>
                  <div class="form-section">
                    <div class="form-section-title">Obecné</div>
                    <div class="form-section-content">
                      <div class="form-row">
                        <mat-form-field class="w50">
                          <input matInput placeholder="Titul před" formControlName="titleBefore"/>
                        </mat-form-field>
                        <mat-form-field>
                          <input matInput placeholder="Jméno" formControlName="firstName"/>
                        </mat-form-field>
                        <mat-form-field>
                          <input matInput placeholder="Příjmení" formControlName="lastName"/>
                        </mat-form-field>
                        <mat-form-field>
                          <input matInput placeholder="Rodné příjmení" formControlName="familyName"/>
                        </mat-form-field>
                        <mat-form-field class="w50">
                          <input matInput placeholder="Titul za" formControlName="titleAfter"/>
                        </mat-form-field>
                      </div>
                      <div class="form-row">
                        <mat-form-field class="w50">
                          <mat-select placeholder="Pohlaví" formControlName="sex" >
                            <mat-option *ngFor="let option of options('sex')" [value]="option.value">{{ option.label }}</mat-option>
                          </mat-select>
                        </mat-form-field>
                        <mat-form-field>
                          <mat-select placeholder="Rodinný stav" formControlName="familyStatus" >
                            <mat-option *ngFor="let option of options('familyStatus')" [value]="option.value">{{ option.label }}</mat-option>
                          </mat-select>
                        </mat-form-field>
                        <mat-form-field>
                          <input matInput placeholder="Datum narození" [matDatepicker]="birthAtPicker" formControlName="birthDate" />
                          <mat-datepicker-toggle matSuffix [for]="birthAtPicker"></mat-datepicker-toggle>
                          <mat-datepicker #birthAtPicker></mat-datepicker>
                        </mat-form-field>
                        <mat-form-field class="readonly w30">
                          <input matInput placeholder="Věk" formControlName="age" readonly/>
                        </mat-form-field>
                      </div>
                      <div class="form-row">
                        <mat-form-field class="w50">
                          <input matInput placeholder="Rodné číslo" formControlName="personalNumber"/>
                        </mat-form-field>
                        <mat-form-field>
                          <input matInput placeholder="Státní příslušnost" formControlName="nationality" [matAutocomplete]="autoNationality"/>
                          <mat-autocomplete #autoNationality="matAutocomplete" [displayWith]="mainService.optionLabel.bind(applicant.get('nationality').value, options('country'))">
                            <mat-option *ngFor="let option of offersService.filterAutocompleteItems('country', applicant.get('nationality').value)" [value]="option.value">
                              {{ option.label }}
                            </mat-option>
                          </mat-autocomplete>
                        </mat-form-field>
                      </div>
                      <div class="form-row" *ngIf="applicant.get('nationality').value !== 'CZE'">
                        <mat-form-field>
                          <mat-select placeholder="Typ pobytu" formControlName="residence" >
                            <mat-option *ngFor="let option of options('residence')" [value]="option.value">{{ option.label }}</mat-option>
                          </mat-select>
                        </mat-form-field>
                        <mat-form-field>
                          <mat-select placeholder="Ekonomicky aktivní v ČR" formControlName="economicallyActivePeriod" >
                            <mat-option [value]="0">0 let</mat-option>
                            <mat-option [value]="1">1 rok</mat-option>
                            <mat-option [value]="2">2 roky</mat-option>
                            <mat-option [value]="3">3 roky</mat-option>
                            <mat-option [value]="4">4 roky</mat-option>
                            <mat-option [value]="5">5 a více let</mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                      <div class="form-row" *ngIf="['temporary', 'long'].includes(applicant.get('residence').value)">
                        <mat-form-field class="w50">
                          <input matInput placeholder="Pobyt od data" [matDatepicker]="fromDatePicker" formControlName="residenceFromDate" />
                          <mat-datepicker-toggle *ngIf="offersService.canEdit()" matSuffix [for]="fromDatePicker"></mat-datepicker-toggle>
                          <mat-datepicker #fromDatePicker></mat-datepicker>
                        </mat-form-field>
                        <mat-form-field class="w50">
                          <input matInput placeholder="Pobyt do data" [matDatepicker]="toDatePicker" formControlName="residenceToDate" />
                          <mat-datepicker-toggle *ngIf="offersService.canEdit()" matSuffix [for]="toDatePicker"></mat-datepicker-toggle>
                          <mat-datepicker #toDatePicker></mat-datepicker>
                        </mat-form-field>
                      </div>
                      <div class="form-row" *ngIf="offersService.offerForm.get('leadApplicantId').value !== applicant.get('_id').value">
                        <mat-form-field [formGroup]="offersService.relationsToLeadApplicant | relationLeadApplicantGroup:applicant.get('_id').value">
                          <mat-select placeholder="Vztah k 1. žadateli" formControlName="type">
                            <mat-option *ngFor="let option of options('relationToFirstApplicant')" [value]="option.value">{{ option.label }}</mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                      <div class="form-row flex flex-row justify-between items-center">
                        <button [disabled]="offersService.offerForm.get('leadApplicantId').value === applicant.get('_id').value" mat-flat-button color="primary" (click)="$event.stopPropagation(); offersService.selectLeadApplicant(applicant.get('_id').value)"><span>Hlavní žadatel</span></button>
                        <button [disabled]="aIndex === 0" mat-flat-button color=primary (click)="$event.stopPropagation(); offersService.setPrimaryContact(applicant.get('_id').value)"><span>Kontaktní osoba</span></button>
                      </div>
                    </div>
                  </div>
                  <div class="form-section">
                    <div class="form-section-title">Kvalifikace</div>
                    <div class="form-section-content">
                      <div class="form-row">
                        <mat-form-field>
                          <mat-select placeholder="Vzdělání" formControlName="education">
                            <mat-option *ngFor="let option of options('education')" [value]="option.value">{{ option.label }}</mat-option>
                          </mat-select>
                        </mat-form-field>
                        <mat-form-field>
                          <input matInput placeholder="Povolání" formControlName="job" [matAutocomplete]="autoJob"/>
                          <mat-autocomplete #autoJob="matAutocomplete" panelWidth="auto" [displayWith]="mainService.optionLabel.bind(applicant.get('job').value, options('job'))">
                            <mat-option *ngFor="let option of offersService.filterAutocompleteItems('job', applicant.get('job').value)" [value]="option.value">
                              {{ option.label }}
                            </mat-option>
                          </mat-autocomplete>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
                  <div class="form-section">
                    <div class="form-section-title">Místo narození</div>
                    <div class="form-section-content">
                      <div class="form-row" formGroupName="placeOfBirth">
                        <mat-form-field>
                          <input matInput placeholder="Město" formControlName="city" [matAutocomplete]="autoBirthCity" (ngModelChange)="ruianService.cities($event)"/>
                          <mat-autocomplete #autoBirthCity="matAutocomplete" panelWidth="auto">
                            <mat-option *ngFor="let city of ruianService.cities$ | async" [value]="city">
                              {{ city }}
                            </mat-option>
                          </mat-autocomplete>
                        </mat-form-field>
                        <mat-form-field class="w30">
                          <app-input-mask placeholder="PSČ" formControlName="zipCode" mask="999 99" inputmode="numeric"></app-input-mask>
                        </mat-form-field>
                        <mat-form-field>
                          <input matInput placeholder="Země" formControlName="country" [matAutocomplete]="autoBirthCountry"/>
                          <mat-autocomplete #autoBirthCountry="matAutocomplete" panelWidth="auto" [displayWith]="mainService.optionLabel.bind(applicant.get('placeOfBirth.country').value, options('country'))">
                            <mat-option *ngFor="let option of offersService.filterAutocompleteItems('country', applicant.get('placeOfBirth.country').value)" [value]="option.value">
                              {{ option.label }}
                            </mat-option>
                          </mat-autocomplete>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
                  <div class="form-section">
                    <div class="form-section-title" [ngClass]="{'invalid': applicant.get('bankAccounts').invalid}">Bankovní účty</div>
                    <div class="form-section-content">
                      <div class="form-section-buttons">
                        <button mat-button color="primary" (click)="contactsService.addBankAccount(applicant, 'mortgage')"><span>Nový bankovní účet</span><mat-icon>add</mat-icon></button>
                      </div>
                      <mat-accordion formArrayName="bankAccounts">
                        <ng-container *ngFor="let bankAccount of applicant.get('bankAccounts').controls; let baIndex = index;trackBy: trackControlByFn;">
                          <mat-expansion-panel [formGroupName]="baIndex">
                            <mat-expansion-panel-header [ngClass]="{'invalid': bankAccount.invalid}">
                              <mat-panel-title class="middle-description">
                                <div class="elipsis"><mat-icon *ngIf="bankAccount.get('isHomeBank').value">home</mat-icon><span>{{ mainService.optionLabel(options('bank'), bankAccount.get('bankCode').value) }}</span></div>
                                <div>{{ bankAccount.get('number').value }}/{{ bankAccount.get('bankCode').value }}</div>
                                <button mat-icon-button color="warn" class="hide-not-expanded" [disabled]="baIndex === 0" (click)="$event.stopPropagation(); contactsService.removeBankAccount(applicant, baIndex);"><mat-icon>remove_circle_outline</mat-icon></button>
                              </mat-panel-title>
                            </mat-expansion-panel-header>
                            <ng-template matExpansionPanelContent>
                            <div class="form-row">
                              <mat-form-field>
                                <input matInput placeholder="Číslo účtu" formControlName="number"/>
                              </mat-form-field>
                              <mat-form-field class="w30">
                                <input matInput placeholder="Kód banky" formControlName="bankCode" [matAutocomplete]="autoBank" />
                                <mat-autocomplete #autoBank="matAutocomplete" panelWidth="auto">
                                  <mat-option *ngFor="let option of offersService.filterAutocompleteItems('bank', bankAccount.get('bankCode').value)" [value]="option.value">
                                    {{ option.value }} - {{ option.label }}
                                  </mat-option>
                                </mat-autocomplete>
                              </mat-form-field>
                              <mat-form-field class="w50" >
                                <mat-select placeholder="Domovská banka" formControlName="isHomeBank">
                                  <mat-option [value]="true">Ano</mat-option>
                                  <mat-option [value]="false">Ne</mat-option>
                                </mat-select>
                              </mat-form-field>
                            </div>
                            </ng-template>
                          </mat-expansion-panel>
                        </ng-container>
                      </mat-accordion>
                    </div>
                  </div>
                  </ng-template>
                </mat-expansion-panel>
                <ng-container>
                  <mat-expansion-panel [id]="'applicant' + aIndex + 'contacts'" (afterExpand)="mainService.scrollToId('applicant' + aIndex + 'contacts')">
                    <mat-expansion-panel-header [ngClass]="{'invalid': offersService.isInvalid(applicant, 'applicant_contacts')}">
                      <mat-panel-title>
                        <div>Kontakty</div>
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <ng-template matExpansionPanelContent>
                    <div class="form-section" >
                      <div class="form-section-title">Email</div>
                      <div class="form-section-content">
                        <div class="form-row">
                          <button mat-icon-button class="verification" [ngClass]="{status0: applicant.get('emailStatus').value === 0, status1: applicant.get('emailStatus').value === 1, status2: applicant.get('emailStatus').value > 1, verified: applicant.get('emailVerified').value}" (click)="offersService.sendMagicLink(applicant)"><mat-icon>verified_user</mat-icon></button>
                          <mat-form-field>
                            <input matInput placeholder="E-mailová adresa" formControlName="email"/>
                          </mat-form-field>
                          <button
                                  mat-button
                                  color="primary"
                                  class="flex-0-0-auto"
                                  (click)="setupCommPreferences(applicant)">
                              Odhlášení z komunikace...
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="form-section" >
                      <div class="form-section-title" [ngClass]="{'invalid': applicant.get('phone').invalid}">Telefon</div>
                      <div class="form-section-content">
                        <ng-container formGroupName="phone">
                          <ng-container *ngFor="let phone of applicant.get('phone').controls; let phoneIndex = index;trackBy: trackByFn;">
                            <div class="form-row" [formGroupName]="phoneIndex">
                              <button mat-icon-button
                                      class="verification"
                                      [disabled]="!phone.get('_id').value"
                                      [ngClass]="{reverified: demandInfo.reverified && phone.get('number').value === demandInfo.phoneNumber && phone.get('countryCode').value === demandInfo.phoneCountryCode, status0: phone.get('status').value === 0, status1: phone.get('status').value === 1, status2: phone.get('status').value > 1, verified: phone.get('isVerified').value}"
                                      [matTooltip]="demandInfo.tooltip"
                                      [matTooltipDisabled]="!(demandInfo.reverified && phone.get('number').value === demandInfo.phoneNumber && phone.get('countryCode').value === demandInfo.phoneCountryCode)"
                                      (click)="contactsService.verifyPhone(applicant, phoneIndex)">
                                  <mat-icon>verified_user</mat-icon>
                              </button>
                              <mat-form-field class="w30">
                                <mat-select placeholder="Tel. předvolba" formControlName="countryCode">
                                  <mat-option *ngFor="let option of options('phoneCountryCode')" [value]="option.value">{{ option.label }}</mat-option>
                                </mat-select>
                              </mat-form-field>
                              <mat-form-field class="w50">
                                <input matInput placeholder="Telefonní číslo" formControlName="number"/>
                              </mat-form-field>
                              <app-dial-button class="dial-button-icon" [menuItem]="false" [compact]="true" [phoneNumber]="phone.get('number').value" [countryCode]="phone.get('countryCode').value" [contactId]="applicant.get('_id').value" [contactName]="deal.name" (clickDial)="dialPhone($event, phone)"></app-dial-button>
                              <button mat-icon-button color="warn" *ngIf="offersService.canEdit()" (click)="$event.stopPropagation(); contactsService.removePhone(applicant, phoneIndex)"><mat-icon>remove_circle_outline</mat-icon></button>
                            </div>
                          </ng-container>
                        </ng-container>
                        <div class="form-row">
                          <button mat-button color="primary" *ngIf="offersService.canEdit()" (click)="contactsService.addPhone(applicant)"><span>Přidat telefon</span><mat-icon>add</mat-icon></button>
                          <span class="flex-1-1-100per"></span>
                        </div>
                      </div>
                    </div>
                    <ng-container formGroupName="address">
                      <div class="form-section" formGroupName="permanent">
                        <div class="form-section-title">Trvalé bydliště</div>
                        <div class="form-section-content">
                          <div class="form-row" *ngIf="aIndex > 0">
                            <span class="flex-1-1-auto"></span>
                            <button mat-button color="primary" (click)="offersService.copyAddress(applicant, 'address.permanent')"><span>Zkopírovat od prvního žadatele</span><mat-icon>content_copy</mat-icon></button>
                          </div>
                          <div class="form-row">
                            <mat-form-field>
                              <mat-label>Ulice <mat-icon>search</mat-icon></mat-label>
                              <input matInput formControlName="street" [matAutocomplete]="autoAddressPermanent" (ngModelChange)="ruianService.searchAddress($event)" />
                              <mat-autocomplete #autoAddressPermanent="matAutocomplete" panelWidth="auto" (optionSelected)="offersService.completeAddress(applicant.get('address.permanent'), $event)">
                                <mat-option *ngFor="let address of ruianService.results$ | async" [value]="address.objectId">
                                  {{ address.text }}
                                </mat-option>
                              </mat-autocomplete>
                            </mat-form-field>
                            <mat-form-field class="w40">
                              <input matInput placeholder="Č. popisné" formControlName="houseNumber"/>
                            </mat-form-field>
                            <mat-form-field class="w40">
                              <input matInput placeholder="Č. orientační" formControlName="orientationNumber"/>
                            </mat-form-field>
                            <mat-form-field class="w30">
                              <app-input-mask placeholder="PSČ" formControlName="zipCode" mask="999 99" inputmode="numeric"></app-input-mask>
                            </mat-form-field>
                            <mat-form-field class="w30">
                              <input matInput type="number" placeholder="Od roku" formControlName="livingSince" [textMask]="{mask: mainService.yearMask, guide: false}"/>
                            </mat-form-field>
                          </div>
                          <div class="form-row">
                            <mat-form-field>
                              <input matInput placeholder="Město" formControlName="city" [matAutocomplete]="autoAddressPermanentCity" (ngModelChange)="ruianService.cities($event)"/>
                              <mat-autocomplete #autoAddressPermanentCity="matAutocomplete" panelWidth="auto">
                                <mat-option *ngFor="let city of ruianService.cities$ | async" [value]="city">
                                  {{ city }}
                                </mat-option>
                              </mat-autocomplete>
                            </mat-form-field>
                            <mat-form-field>
                              <input matInput placeholder="Okres" formControlName="district" [matAutocomplete]="autoAddressPermanentDistrict" (ngModelChange)="ruianService.districts($event)"/>
                              <mat-autocomplete #autoAddressPermanentDistrict="matAutocomplete" panelWidth="auto">
                                <mat-option *ngFor="let district of ruianService.districts$ | async" [value]="district">
                                  {{ district }}
                                </mat-option>
                              </mat-autocomplete>
                            </mat-form-field>
                            <mat-form-field>
                              <input matInput placeholder="Kraj" formControlName="region"/>
                            </mat-form-field>
                            <mat-form-field>
                              <input matInput placeholder="Země" formControlName="country" [matAutocomplete]="autoAddressPermanentCountry"/>
                              <mat-autocomplete #autoAddressPermanentCountry="matAutocomplete" panelWidth="auto" [displayWith]="mainService.optionLabel.bind(applicant.get('address.permanent.country').value, options('country'))">
                                <mat-option *ngFor="let option of offersService.filterAutocompleteItems('country', applicant.get('address.permanent.country').value)" [value]="option.value">
                                  {{ option.label }}
                                </mat-option>
                              </mat-autocomplete>
                            </mat-form-field>
                          </div>
                        </div>
                      </div>
                      <div class="form-section" formGroupName="temporary">
                        <div class="form-section-title">Přechodné bydliště</div>
                        <div class="form-section-content">
                          <div class="form-row" *ngIf="aIndex > 0">
                            <span class="flex-1-1-auto"></span>
                            <button mat-button color="primary" (click)="offersService.copyAddress(applicant, 'address.temporary')"><span>Zkopírovat od prvního žadatele</span><mat-icon>content_copy</mat-icon></button>
                          </div>
                          <div class="form-row">
                            <mat-form-field>
                              <mat-label>Ulice <mat-icon>search</mat-icon></mat-label>
                              <input matInput formControlName="street" [matAutocomplete]="autoAddressTemporary" (ngModelChange)="ruianService.searchAddress($event)" />
                              <mat-autocomplete #autoAddressTemporary="matAutocomplete" panelWidth="auto" (optionSelected)="offersService.completeAddress(applicant.get('address.temporary'), $event)">
                                <mat-option *ngFor="let address of ruianService.results$ | async" [value]="address.objectId">
                                  {{ address.text }}
                                </mat-option>
                              </mat-autocomplete>
                            </mat-form-field>
                            <mat-form-field class="w40">
                              <input matInput placeholder="Č. popisné" formControlName="houseNumber"/>
                            </mat-form-field>
                            <mat-form-field class="w40">
                              <input matInput placeholder="Č. orientační" formControlName="orientationNumber"/>
                            </mat-form-field>
                            <mat-form-field class="w30">
                              <app-input-mask placeholder="PSČ" formControlName="zipCode" mask="999 99" inputmode="numeric"></app-input-mask>
                            </mat-form-field>
                            <mat-form-field class="w30">
                              <input matInput type="number" placeholder="Od roku" formControlName="livingSince" [textMask]="{mask: mainService.yearMask, guide: false}"/>
                            </mat-form-field>
                          </div>
                          <div class="form-row">
                            <mat-form-field>
                              <input matInput placeholder="Město" formControlName="city" [matAutocomplete]="autoAddressTemporaryCity" (ngModelChange)="ruianService.cities($event)"/>
                              <mat-autocomplete #autoAddressTemporaryCity="matAutocomplete" panelWidth="auto">
                                <mat-option *ngFor="let city of ruianService.cities$ | async" [value]="city">
                                  {{ city }}
                                </mat-option>
                              </mat-autocomplete>
                            </mat-form-field>
                            <mat-form-field>
                              <input matInput placeholder="Okres" formControlName="district" [matAutocomplete]="autoAddressTemporaryDistrict" (ngModelChange)="ruianService.districts($event)"/>
                              <mat-autocomplete #autoAddressTemporaryDistrict="matAutocomplete" panelWidth="auto">
                                <mat-option *ngFor="let district of ruianService.districts$ | async" [value]="district">
                                  {{ district }}
                                </mat-option>
                              </mat-autocomplete>
                            </mat-form-field>
                            <mat-form-field>
                              <input matInput placeholder="Kraj" formControlName="region"/>
                            </mat-form-field>
                            <mat-form-field>
                              <input matInput placeholder="Země" formControlName="country" [matAutocomplete]="autoAddressTemporaryCountry"/>
                              <mat-autocomplete #autoAddressTemporaryCountry="matAutocomplete" panelWidth="auto" [displayWith]="mainService.optionLabel.bind(applicant.get('address.temporary.country').value, options('country'))">
                                <mat-option *ngFor="let option of offersService.filterAutocompleteItems('country', applicant.get('address.temporary.country').value)" [value]="option.value">
                                  {{ option.label }}
                                </mat-option>
                              </mat-autocomplete>
                            </mat-form-field>
                          </div>
                        </div>
                      </div>
                      <div class="form-section" formGroupName="mailing">
                        <div class="form-section-title">Korespondenční adr.</div>
                        <div class="form-section-content">
                          <div class="form-row" *ngIf="aIndex > 0">
                            <span class="flex-1-1-auto"></span>
                            <button mat-button color="primary" (click)="offersService.copyAddress(applicant, 'address.mailing')"><span>Zkopírovat od prvního žadatele</span><mat-icon>content_copy</mat-icon></button>
                          </div>
                          <ul class="form-list">
                            <li><mat-checkbox formControlName="sameAsPermanent">Totožná s trvalým bydlištěm</mat-checkbox></li>
                            <li><mat-checkbox formControlName="sameAsTemporary">Totožná s přechodným bydlištěm</mat-checkbox></li>
                          </ul>
                          <div class="form-row" *ngIf="!applicant.get('address.mailing.sameAsPermanent').value && !applicant.get('address.mailing.sameAsTemporary').value">
                            <mat-form-field>
                              <mat-label>Ulice <mat-icon>search</mat-icon></mat-label>
                              <input matInput formControlName="street" [matAutocomplete]="autoAddressMailing" (ngModelChange)="ruianService.searchAddress($event)" />
                              <mat-autocomplete #autoAddressMailing="matAutocomplete" panelWidth="auto" (optionSelected)="offersService.completeAddress(applicant.get('address.mailing'), $event)">
                                <mat-option *ngFor="let address of ruianService.results$ | async" [value]="address.objectId">
                                  {{ address.text }}
                                </mat-option>
                              </mat-autocomplete>
                            </mat-form-field>
                            <mat-form-field class="w40">
                              <input matInput placeholder="Č. popisné" formControlName="houseNumber"/>
                            </mat-form-field>
                            <mat-form-field class="w40">
                              <input matInput placeholder="Č. orientační" formControlName="orientationNumber"/>
                            </mat-form-field>
                            <mat-form-field class="w30">
                              <app-input-mask placeholder="PSČ" formControlName="zipCode" mask="999 99" inputmode="numeric"></app-input-mask>
                            </mat-form-field>
                            <mat-form-field class="w30">
                              <input matInput type="number" placeholder="Od roku" formControlName="livingSince" [textMask]="{mask: mainService.yearMask, guide: false}"/>
                            </mat-form-field>
                          </div>
                          <div class="form-row" *ngIf="!applicant.get('address.mailing.sameAsPermanent').value && !applicant.get('address.mailing.sameAsTemporary').value">
                            <mat-form-field>
                              <input matInput placeholder="Město" formControlName="city" [matAutocomplete]="autoAddressMailingCity" (ngModelChange)="ruianService.cities($event)"/>
                              <mat-autocomplete #autoAddressMailingCity="matAutocomplete" panelWidth="auto">
                                <mat-option *ngFor="let city of ruianService.cities$ | async" [value]="city">
                                  {{ city }}
                                </mat-option>
                              </mat-autocomplete>
                            </mat-form-field>
                            <mat-form-field>
                              <input matInput placeholder="Okres" formControlName="district" [matAutocomplete]="autoAddressMailingDistrict" (ngModelChange)="ruianService.districts($event)"/>
                              <mat-autocomplete #autoAddressMailingDistrict="matAutocomplete" panelWidth="auto">
                                <mat-option *ngFor="let district of ruianService.districts$ | async" [value]="district">
                                  {{ district }}
                                </mat-option>
                              </mat-autocomplete>
                            </mat-form-field>
                            <mat-form-field>
                              <input matInput placeholder="Kraj" formControlName="region"/>
                            </mat-form-field>
                            <mat-form-field>
                              <input matInput placeholder="Země" formControlName="country" [matAutocomplete]="autoAddressMailingCountry"/>
                              <mat-autocomplete #autoAddressMailingCountry="matAutocomplete" panelWidth="auto" [displayWith]="mainService.optionLabel.bind(applicant.get('address.mailing.country').value, options('country'))">
                                <mat-option *ngFor="let option of offersService.filterAutocompleteItems('country', applicant.get('address.mailing.country').value)" [value]="option.value">
                                  {{ option.label }}
                                </mat-option>
                              </mat-autocomplete>
                            </mat-form-field>
                          </div>
                        </div>
                      </div>
                      <div class="form-section" formGroupName="courier">
                        <div class="form-section-title">Adresa pro kurýra</div>
                        <div class="form-section-content">
                          <div class="form-row" *ngIf="aIndex > 0">
                            <span class="flex-1-1-auto"></span>
                            <button mat-button color="primary" (click)="offersService.copyAddress(applicant, 'address.courier')"><span>Zkopírovat od prvního žadatele</span><mat-icon>content_copy</mat-icon></button>
                          </div>
                          <ul class="form-list">
                            <li><mat-checkbox formControlName="sameAsPermanent">Totožná s trvalým bydlištěm</mat-checkbox></li>
                            <li><mat-checkbox formControlName="sameAsTemporary">Totožná s přechodným bydlištěm</mat-checkbox></li>
                            <li><mat-checkbox formControlName="sameAsMailing">Totožná s korespondenční adresou</mat-checkbox></li>
                          </ul>
                          <div class="form-row" *ngIf="!applicant.get('address.courier.sameAsPermanent').value && !applicant.get('address.courier.sameAsTemporary').value && !applicant.get('address.courier.sameAsMailing').value">
                            <mat-form-field>
                              <mat-label>Ulice <mat-icon>search</mat-icon></mat-label>
                              <input matInput formControlName="street" [matAutocomplete]="autoAddressCourier" (ngModelChange)="ruianService.searchAddress($event)" />
                              <mat-autocomplete #autoAddressCourier="matAutocomplete" panelWidth="auto" (optionSelected)="offersService.completeAddress(applicant.get('address.courier'), $event)">
                                <mat-option *ngFor="let address of ruianService.results$ | async" [value]="address.objectId">
                                  {{ address.text }}
                                </mat-option>
                              </mat-autocomplete>
                            </mat-form-field>
                            <mat-form-field class="w40">
                              <input matInput placeholder="Č. popisné" formControlName="houseNumber"/>
                            </mat-form-field>
                            <mat-form-field class="w40">
                              <input matInput placeholder="Č. orientační" formControlName="orientationNumber"/>
                            </mat-form-field>
                            <mat-form-field class="w30">
                              <app-input-mask placeholder="PSČ" formControlName="zipCode" mask="999 99" inputmode="numeric"></app-input-mask>
                            </mat-form-field>
                          </div>
                          <div class="form-row" *ngIf="!applicant.get('address.courier.sameAsPermanent').value && !applicant.get('address.courier.sameAsTemporary').value && !applicant.get('address.courier.sameAsMailing').value">
                            <mat-form-field>
                              <input matInput placeholder="Město" formControlName="city" [matAutocomplete]="autoAddressCourierCity" (ngModelChange)="ruianService.cities($event)"/>
                              <mat-autocomplete #autoAddressCourierCity="matAutocomplete" panelWidth="auto">
                                <mat-option *ngFor="let city of ruianService.cities$ | async" [value]="city">
                                  {{ city }}
                                </mat-option>
                              </mat-autocomplete>
                            </mat-form-field>
                            <mat-form-field>
                              <input matInput placeholder="Okres" formControlName="district" [matAutocomplete]="autoAddressCourierDistrict" (ngModelChange)="ruianService.districts($event)"/>
                              <mat-autocomplete #autoAddressCourierDistrict="matAutocomplete" panelWidth="auto">
                                <mat-option *ngFor="let district of ruianService.districts$ | async" [value]="district">
                                  {{ district }}
                                </mat-option>
                              </mat-autocomplete>
                            </mat-form-field>
                            <mat-form-field>
                              <input matInput placeholder="Kraj" formControlName="region"/>
                            </mat-form-field>
                            <mat-form-field>
                              <input matInput placeholder="Země" formControlName="country" [matAutocomplete]="autoAddressCourierCountry"/>
                              <mat-autocomplete #autoAddressCourierCountry="matAutocomplete" panelWidth="auto" [displayWith]="mainService.optionLabel.bind(applicant.get('address.courier.country').value, options('country'))">
                                <mat-option *ngFor="let option of offersService.filterAutocompleteItems('country', applicant.get('address.courier.country').value)" [value]="option.value">
                                  {{ option.label }}
                                </mat-option>
                              </mat-autocomplete>
                            </mat-form-field>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                    </ng-template>
                  </mat-expansion-panel>
                </ng-container>
                <mat-expansion-panel [id]="'applicant' + aIndex + 'incomes'" (afterExpand)="mainService.scrollToId('applicant' + aIndex + 'incomes')">
                  <mat-expansion-panel-header [ngClass]="{'invalid': applicant.get('incomes').invalid}">
                    <mat-panel-title>
                      <div>Příjmy</div>
                      <button mat-button color="primary" class="hide-not-expanded" *ngIf="offersService.canEdit()" (click)="$event.stopPropagation(); contactsService.addIncome(applicant, 'mortgage')"><span>Nový příjem</span><mat-icon>add</mat-icon></button>
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <ng-template matExpansionPanelContent>
                  <mat-accordion formArrayName="incomes">
                    <ng-container *ngFor="let income of applicant.get('incomes').controls; let inIndex = index;trackBy: trackControlByFn;">
                      <mat-expansion-panel [formGroupName]="inIndex" [id]="'applicant' + aIndex + 'income' + inIndex" (afterExpand)="mainService.scrollToId('applicant' + aIndex + 'income' + inIndex)">
                        <mat-expansion-panel-header [ngClass]="{'invalid': income.invalid}">
                          <mat-panel-title class="middle-description">
                            <div class="elipsis"><span>{{ mainService.optionLabel(options('incomeType'), income.get('type').value) }}</span></div>
                            <div>{{ mainService.maskCurrency(mainService.unmaskCurrency(income.get('amount').value)) }} {{ mainService.optionLabel(options('currency'), income.get('currency').value) }}</div>
                            <button mat-icon-button color="warn" class="hide-not-expanded" *ngIf="offersService.canEdit()" [disabled]="inIndex === 0" (click)="$event.stopPropagation(); contactsService.removeIncome(applicant, inIndex)"><mat-icon>remove_circle_outline</mat-icon></button>
                          </mat-panel-title>
                        </mat-expansion-panel-header>
                        <ng-template matExpansionPanelContent>
                        <div class="form-section">
                          <div class="form-section-title">Obecné</div>
                          <div class="form-section-content">
                            <div class="form-row">
                              <mat-form-field>
                                <mat-select placeholder="Typ příjmu" *ngIf="offersService.canEdit()" formControlName="type" >
                                  <mat-option *ngFor="let option of options('incomeType')" [value]="option.value">{{ option.label }}</mat-option>
                                </mat-select>
                                <input *ngIf="!offersService.canEdit()" matInput placeholder="Typ příjmu" [value]="mainService.optionLabel(options('incomeType'), income.get('type').value)" readonly />
                              </mat-form-field>
                              <mat-form-field class="w50">
                                <input matInput placeholder="Od data" [matDatepicker]="fromDatePicker" formControlName="fromDate" />
                                <mat-datepicker-toggle *ngIf="offersService.canEdit()" matSuffix [for]="fromDatePicker"></mat-datepicker-toggle>
                                <mat-datepicker #fromDatePicker></mat-datepicker>
                              </mat-form-field>
                              <mat-form-field class="w50">
                                <input matInput placeholder="Do data" [matDatepicker]="untilDatePicker" formControlName="untilDate" />
                                <mat-datepicker-toggle *ngIf="offersService.canEdit()" matSuffix [for]="untilDatePicker"></mat-datepicker-toggle>
                                <mat-datepicker #untilDatePicker></mat-datepicker>
                              </mat-form-field>
                              <mat-form-field *ngIf="['selfEmployment'].includes(income.get('type').value)" class="w50">
                                <mat-select placeholder="V posl. 2 letech" formControlName="stoppedInLastTwoYears" >
                                  <mat-option [value]="true">přerušil</mat-option>
                                  <mat-option [value]="false">nepřerušil</mat-option>
                                </mat-select>
                              </mat-form-field>
                            </div>
                            <div class="form-row">
                              <mat-form-field *ngIf="['fixedTermEmployment', 'employmentAgreement', 'actionAgreement'].includes(income.get('type').value)">
                                <mat-select placeholder="Prodloužení" formControlName="employmentExtendedCount" >
                                  <mat-option [value]="0">nikdy</mat-option>
                                  <mat-option [value]="1">1</mat-option>
                                  <mat-option [value]="2">2</mat-option>
                                  <mat-option [value]="3">3 a více</mat-option>
                                </mat-select>
                              </mat-form-field>
                              <mat-form-field *ngIf="['rentalIncomeOutsideTaxReturn', 'futureRentalRevenue', 'rentalIncome'].includes(income.get('type').value)">
                                <mat-select placeholder="Smlouva na" formControlName="contractTermPeriod" >
                                  <mat-option [value]="null"></mat-option>
                                  <mat-option [value]="'fixedTerm'">dobu určitou</mat-option>
                                  <mat-option [value]="'indefinite'">dobu neurčitou</mat-option>
                                </mat-select>
                              </mat-form-field>
                              <mat-form-field *ngIf="['diet'].includes(income.get('type').value)">
                                  <mat-select placeholder="Profese" formControlName="professionType" >
                                    <mat-option *ngFor="let option of options('profession')" [value]="option.value">{{ option.label }}</mat-option>
                                  </mat-select>
                              </mat-form-field>
                              <mat-form-field *ngIf="['turnoverIncome'].includes(income.get('type').value)">
                                <input matInput placeholder="Podíl v %" formControlName="shareInTheCompany" [textMask]="{mask: mainService.percentMask, guide: false}" />
                              </mat-form-field>
                              <mat-form-field [ngClass]="{readonly: income.get('type').value === 'noIncome'}">
                                <input matInput placeholder="Výše příjmu" formControlName="amount" [textMask]="{mask: mainService.currencyMask, guide: false}" [readonly]="income.get('type').value === 'noIncome'"/>
                              </mat-form-field>
                              <mat-form-field class="w30">
                                <input matInput placeholder="Měna" formControlName="currency" [matAutocomplete]="autoCurrency" />
                                <mat-autocomplete #autoCurrency="matAutocomplete" panelWidth="auto" [displayWith]="mainService.optionLabel.bind(income.get('currency').value, options('currency'))">
                                  <mat-option *ngFor="let option of offersService.filterAutocompleteItems('currency', income.get('currency').value)" [value]="option.value">
                                    {{ option.label }}
                                  </mat-option>
                                </mat-autocomplete>
                              </mat-form-field>
                              <mat-form-field>
                                <mat-select placeholder="Lokace příjmu" *ngIf="offersService.canEdit()" formControlName="location" >
                                  <mat-option *ngFor="let option of options('incomeLocation')" [value]="option.value">{{ option.label }}</mat-option>
                                </mat-select>
                                <input *ngIf="!offersService.canEdit()" matInput placeholder="Lokace příjmu" [value]="mainService.optionLabel(options('incomeLocation'), income.get('location').value)" readonly />
                              </mat-form-field>
                            </div>
                            <div class="form-row" *ngIf="['expat', 'pendler'].includes(income.get('location').value)">
                              <mat-form-field>
                                <input matInput placeholder="Země lokace příjmu" formControlName="locationCountry" [matAutocomplete]="autoCountryLocation"/>
                                <mat-autocomplete #autoCountryLocation="matAutocomplete" panelWidth="auto" [displayWith]="mainService.optionLabel.bind(income.get('locationCountry').value, options('country'))">
                                  <mat-option *ngFor="let option of offersService.filterAutocompleteItems('country', income.get('locationCountry').value)" [value]="option.value">
                                    {{ option.label }}
                                  </mat-option>
                                </mat-autocomplete>
                              </mat-form-field>
                              <mat-form-field>
                                <mat-select placeholder="Příjem na účet" formControlName="locationAccount">
                                  <mat-option [value]="'CZE'">český</mat-option>
                                  <mat-option [value]="'other'">zahraniční</mat-option>
                                </mat-select>
                              </mat-form-field>
                            </div>
                            <div class="form-row" *ngIf="['fixedTermEmployment', 'indefinitePeriodEmployment', 'employmentAgreement', 'actionAgreement'].includes(income.get('type').value)">
                              <mat-slide-toggle formControlName="employedAtOwnCompany">Ve vlastní firmě</mat-slide-toggle>
                            </div>
                            <div class="form-row">
                              <mat-slide-toggle formControlName="isTrialPeriod">Ve zkušební lhůtě</mat-slide-toggle>
                              <mat-slide-toggle formControlName="isPaidToAccount">Vyplácení na účet</mat-slide-toggle>
                            </div>
                            <div class="form-row">
                              <mat-slide-toggle formControlName="isNoticePeriod">Ve výpovědní lhůtě</mat-slide-toggle>
                              <mat-slide-toggle formControlName="isSubsequent">Navazující</mat-slide-toggle>
                            </div>
                          </div>
                        </div>
                        <div class="form-section" formGroupName="source">
                          <div class="form-section-title">Zdroj příjmu</div>
                          <div class="form-section-content">
                            <div class="form-row">
                              <mat-form-field>
                                <input matInput placeholder="Název" formControlName="name"/>
                              </mat-form-field>
                              <mat-form-field class="w50">
                                <input matInput placeholder="IČO" formControlName="idNumber"/>
                                <mat-icon matSuffix class="search-ares" (click)="offersService.searchAres(income)">search</mat-icon>
                              </mat-form-field>
                              <mat-form-field class="w50">
                                <input matInput placeholder="DIČ" formControlName="vatNumber"/>
                              </mat-form-field>
                              <mat-form-field class="w50">
                                <input matInput placeholder="Rodné číslo" formControlName="personalNumber"/>
                              </mat-form-field>
                            </div>
                            <div class="form-row">
                              <mat-form-field class="w50">
                                <input matInput placeholder="Vznik" [matDatepicker]="createdDatePicker" formControlName="createdAt" />
                                <mat-datepicker-toggle *ngIf="offersService.canEdit()" matSuffix [for]="createdDatePicker"></mat-datepicker-toggle>
                                <mat-datepicker #createdDatePicker></mat-datepicker>
                              </mat-form-field>
                              <mat-form-field>
                                <input matInput placeholder="E-mailová adresa" formControlName="email"/>
                              </mat-form-field>
                              <mat-form-field class="w50">
                                <input matInput placeholder="Telefonní číslo" formControlName="phone"/>
                              </mat-form-field>
                            </div>
                            <div class="form-row">
                              <mat-form-field>
                                <input matInput placeholder="Odvětví NACE" formControlName="naceCode" [matAutocomplete]="autoNace" />
                                <mat-autocomplete #autoNace="matAutocomplete" panelWidth="auto" [displayWith]="mainService.optionLabel.bind(income.get('source.naceCode').value, options('nace'))">
                                  <mat-option *ngFor="let option of offersService.filterAutocompleteItems('nace', income.get('source.naceCode').value)" [value]="option.value">
                                    {{ option.value }} - {{ option.label }}
                                  </mat-option>
                                </mat-autocomplete>
                              </mat-form-field>
                              <mat-form-field>
                                <input matInput placeholder="Sektor" formControlName="sector" [matAutocomplete]="autoSector"/>
                                <mat-autocomplete #autoSector="matAutocomplete" panelWidth="auto" [displayWith]="mainService.optionLabel.bind(income.get('source.sector').value, options('sector'))">
                                  <mat-option *ngFor="let option of offersService.filterAutocompleteItems('sector', income.get('source.sector').value)" [value]="option.value">
                                    {{ option.label }}
                                  </mat-option>
                                </mat-autocomplete>
                              </mat-form-field>
                              <mat-form-field class="w50">
                                <mat-select placeholder="Pozast. činnost" formControlName="suspendedActivity" *ngIf="offersService.canEdit()">
                                  <mat-option [value]="true">Ano</mat-option>
                                  <mat-option [value]="false">Ne</mat-option>
                                </mat-select>
                                <input *ngIf="!offersService.canEdit()" matInput placeholder="Pozast. činnost" [value]="income.get('source.suspendedActivity').value ? 'Ano' : 'Ne'" readonly />
                              </mat-form-field>
                            </div>
                            <ng-container formGroupName="address">
                              <div class="form-row">
                                <mat-form-field>
                                  <mat-label>Ulice <mat-icon>search</mat-icon></mat-label>
                                  <input matInput formControlName="street" [matAutocomplete]="autoAddress" (ngModelChange)="ruianService.searchAddress($event)" />
                                  <mat-autocomplete #autoAddress="matAutocomplete" panelWidth="auto" (optionSelected)="offersService.completeAddress(income.get('source.address'), $event)">
                                    <mat-option *ngFor="let address of ruianService.results$ | async" [value]="address.objectId">
                                      {{ address.text }}
                                    </mat-option>
                                  </mat-autocomplete>
                                </mat-form-field>
                                <mat-form-field class="w40">
                                  <input matInput placeholder="Č. popisné" formControlName="houseNumber"/>
                                </mat-form-field>
                                  <!-- sirka -->
                                <mat-form-field class="w40">
                                  <input matInput placeholder="Č. orientační" formControlName="orientationNumber"/>
                                </mat-form-field>
                                <mat-form-field class="w30">
                                  <app-input-mask placeholder="PSČ" formControlName="zipCode" mask="999 99" inputmode="numeric"></app-input-mask>
                                </mat-form-field>
                              </div>
                              <div class="form-row">
                                <mat-form-field>
                                  <input matInput placeholder="Město" formControlName="city" [matAutocomplete]="autoCity" (ngModelChange)="ruianService.cities($event)"/>
                                  <mat-autocomplete #autoCity="matAutocomplete" panelWidth="auto">
                                    <mat-option *ngFor="let city of ruianService.cities$ | async" [value]="city">
                                      {{ city }}
                                    </mat-option>
                                  </mat-autocomplete>
                                </mat-form-field>
                                <mat-form-field>
                                  <input matInput placeholder="Okres" formControlName="district" [matAutocomplete]="autoDistrict" (ngModelChange)="ruianService.districts($event)"/>
                                  <mat-autocomplete #autoDistrict="matAutocomplete" panelWidth="auto">
                                    <mat-option *ngFor="let district of ruianService.districts$ | async" [value]="district">
                                      {{ district }}
                                    </mat-option>
                                  </mat-autocomplete>
                                </mat-form-field>
                                <mat-form-field>
                                  <input matInput placeholder="Kraj" formControlName="region"/>
                                </mat-form-field>
                                <mat-form-field>
                                  <input matInput placeholder="Země" formControlName="country" [matAutocomplete]="autoCountry"/>
                                  <mat-autocomplete #autoCountry="matAutocomplete" panelWidth="auto" [displayWith]="mainService.optionLabel.bind(income.get('source.address.country').value, options('country'))">
                                    <mat-option *ngFor="let option of offersService.filterAutocompleteItems('country', income.get('source.address.country').value)" [value]="option.value">
                                      {{ option.label }}
                                    </mat-option>
                                  </mat-autocomplete>
                                </mat-form-field>
                              </div>
                            </ng-container>
                          </div>
                        </div>
                        <div class="form-section" *ngIf="income.get('type').value === 'selfEmployment'">
                          <div class="form-section-title">OSVČ</div>
                          <div class="form-section-content">
                            <div class="form-row">
                              <mat-form-field>
                                <mat-select placeholder="Nákladová evidence" *ngIf="offersService.canEdit()" formControlName="costAccounting" >
                                  <mat-option *ngFor="let option of options('costAccounting')" [value]="option.value">{{ option.label }}</mat-option>
                                </mat-select>
                                <input *ngIf="!offersService.canEdit()" matInput placeholder="Nákladová evidence" [value]="mainService.optionLabel(options('costAccounting'), income.get('costAccounting').value)" readonly />
                              </mat-form-field>
                              <mat-form-field class="w50" *ngIf="income.get('costAccounting').value === 'flatRate'">
                                <mat-select placeholder="Výše paušálu" *ngIf="offersService.canEdit()" formControlName="flatRate" >
                                  <mat-option *ngFor="let option of options('flatRate')" [value]="option.value">{{ option.label }}</mat-option>
                                </mat-select>
                                <input *ngIf="!offersService.canEdit()" matInput placeholder="Výše paušálu" [value]="mainService.optionLabel(options('flatRate'), income.get('flatRate').value)" readonly />
                              </mat-form-field>
                            </div>
                          </div>
                        </div>
                        <mat-expansion-panel class="pink" *ngIf="income.get('type').value === 'selfEmployment'" formGroupName="taxReturn">
                          <mat-expansion-panel-header [ngClass]="{'invalid': income.get('taxReturn').invalid}">
                            <mat-panel-title>
                              <div>Daňové přiznání</div>
                            </mat-panel-title>
                          </mat-expansion-panel-header>
                          <ng-template matExpansionPanelContent>
                          <div class="form-section">
                            <div class="form-section-title">Obecné</div>
                            <div class="form-section-content">
                              <div class="form-row">
                                <mat-form-field class="w30">
                                  <input matInput placeholder="Za období" formControlName="period"/>
                                </mat-form-field>
                                <div></div>
                              </div>
                            </div>
                          </div>
                          <div class="form-section">
                            <div class="form-section-title">2. oddíl</div>
                            <div class="form-section-content">
                              <div class="form-row">
                                <mat-form-field>
                                  <input matInput placeholder="(31) úhrn příjmů od všech zaměstnavatelů" formControlName="row31" [textMask]="{mask: mainService.currencyMask, guide: false}"/>
                                </mat-form-field>
                                <mat-form-field>
                                  <input matInput placeholder="(36) dílčí základ daně ze závislé činnosti" formControlName="row36" [textMask]="{mask: mainService.currencyMask, guide: false}"/>
                                </mat-form-field>
                              </div>
                              <div class="form-row">
                                <mat-form-field>
                                  <input matInput placeholder="(37) dílčí základ daně ze samostatné činnosti" formControlName="row37" [textMask]="{mask: mainService.currencyMask, guide: false}" />
                                </mat-form-field>
                                <mat-form-field>
                                  <input matInput placeholder="(38) dílčí základ daně z kapitálového majetku" formControlName="row38" [textMask]="{mask: mainService.currencyMask, guide: false}"/>
                                </mat-form-field>
                              </div>
                              <div class="form-row">
                                <mat-form-field>
                                  <input matInput placeholder="(39) dílčí základ daně z nájmu" formControlName="row39" [textMask]="{mask: mainService.currencyMask, guide: false}"/>
                                </mat-form-field>
                                <mat-form-field>
                                  <input matInput placeholder="(40) dílčí základ daně z ostatních příjmů" formControlName="row40" [textMask]="{mask: mainService.currencyMask, guide: false}"/>
                                </mat-form-field>
                              </div>
                              <div class="form-row">
                                <mat-form-field>
                                  <input matInput placeholder="(42) základ daně" formControlName="row42" [textMask]="{mask: mainService.currencyMask, guide: false}"/>
                                </mat-form-field>
                                <div></div>
                              </div>
                            </div>
                          </div>
                          <div class="form-section">
                            <div class="form-section-title">3. oddíl</div>
                            <div class="form-section-content">
                              <div class="form-row">
                                <mat-form-field>
                                  <input matInput placeholder="(46) hodnota darů" formControlName="row46" [textMask]="{mask: mainService.currencyMask, guide: false}"/>
                                </mat-form-field>
                                <mat-form-field>
                                  <input matInput placeholder="(47) odečet úroků" formControlName="row47" [textMask]="{mask: mainService.currencyMask, guide: false}"/>
                                </mat-form-field>
                              </div>
                              <div class="form-row">
                                <mat-form-field>
                                  <input matInput placeholder="(48) odečet penzijního spoření" formControlName="row48" [textMask]="{mask: mainService.currencyMask, guide: false}"/>
                                </mat-form-field>
                                <mat-form-field>
                                  <input matInput placeholder="(49) odečet soukromého ŽP" formControlName="row49" [textMask]="{mask: mainService.currencyMask, guide: false}"/>
                                </mat-form-field>
                              </div>
                              <div class="form-row">
                                <mat-form-field>
                                  <input matInput placeholder="(50) odborové příspěvky" formControlName="row50" [textMask]="{mask: mainService.currencyMask, guide: false}"/>
                                </mat-form-field>
                                <mat-form-field>
                                  <input matInput placeholder="(51) úhrada za další vzdělávání" formControlName="row51" [textMask]="{mask: mainService.currencyMask, guide: false}"/>
                                </mat-form-field>
                              </div>
                              <div class="form-row">
                                <mat-form-field>
                                  <input matInput placeholder="(52) výzkum a vývoj" formControlName="row52" [textMask]="{mask: mainService.currencyMask, guide: false}"/>
                                </mat-form-field>
                                <mat-form-field>
                                  <input matInput placeholder="(52a) odpočet na podporu odb. vzdělávání" formControlName="row52a" [textMask]="{mask: mainService.currencyMask, guide: false}"/>
                                </mat-form-field>
                              </div>
                              <div class="form-row">
                                <mat-form-field>
                                  <input matInput placeholder="(53) další částky" formControlName="row53" [textMask]="{mask: mainService.currencyMask, guide: false}"/>
                                </mat-form-field>
                                <div></div>
                              </div>
                            </div>
                          </div>
                          <div class="form-section">
                            <div class="form-section-title">5. oddíl</div>
                            <div class="form-section-content">
                              <div class="form-row">
                                <mat-form-field>
                                  <input matInput placeholder="(64) základní sleva na poplatníka - manžel/ka" formControlName="row64" [textMask]="{mask: mainService.currencyMask, guide: false}"/>
                                </mat-form-field>
                                <mat-form-field>
                                  <input matInput placeholder="(65a) sleva na manžela/ku - manžel/ka" formControlName="row65a" [textMask]="{mask: mainService.currencyMask, guide: false}"/>
                                </mat-form-field>
                              </div>
                              <div class="form-row">
                                <mat-form-field>
                                  <input matInput placeholder="(65b) sleva na ZTP - manžel/ka" formControlName="row65b" [textMask]="{mask: mainService.currencyMask, guide: false}"/>
                                </mat-form-field>
                                <mat-form-field>
                                  <input matInput placeholder="(66) sleva na invaliditu - manžel/ka" formControlName="row66" [textMask]="{mask: mainService.currencyMask, guide: false}"/>
                                </mat-form-field>
                              </div>
                              <div class="form-row">
                                <mat-form-field>
                                  <input matInput placeholder="(67) rozšířená sleva na ZTP - manžel/ka" formControlName="row67" [textMask]="{mask: mainService.currencyMask, guide: false}"/>
                                </mat-form-field>
                                <mat-form-field>
                                  <input matInput placeholder="(68) sleva na držitele ZTP/P" formControlName="row68" [textMask]="{mask: mainService.currencyMask, guide: false}"/>
                                </mat-form-field>
                              </div>
                              <div class="form-row">
                                <mat-form-field>
                                  <input matInput placeholder="(69) sleva na studenta - manžel/ka" formControlName="row69" [textMask]="{mask: mainService.currencyMask, guide: false}"/>
                                </mat-form-field>
                                <mat-form-field>
                                  <input matInput placeholder="(69a) sleva za umístění dítěte - manžel/ka" formControlName="row69a" [textMask]="{mask: mainService.currencyMask, guide: false}"/>
                                </mat-form-field>
                              </div>
                              <div class="form-row">
                                <mat-form-field>
                                  <input matInput placeholder="(69b) sleva na evidenci tržeb - manžel/ka" formControlName="row69b" [textMask]="{mask: mainService.currencyMask, guide: false}"/>
                                </mat-form-field>
                                <mat-form-field>
                                  <input matInput placeholder="(72) daňové zvýhodnění na vyživované dítě" formControlName="row72" [textMask]="{mask: mainService.currencyMask, guide: false}"/>
                                </mat-form-field>
                              </div>
                              <div class="form-row">
                                <mat-form-field>
                                  <input matInput placeholder="(74) daň po uplatnění slev" formControlName="row74" [textMask]="{mask: mainService.currencyMask, guide: false}" />
                                </mat-form-field>
                                <div></div>
                              </div>
                            </div>
                          </div>
                          <div class="form-section">
                            <div class="form-section-title">Příloha 1</div>
                            <div class="form-section-content">
                              <div class="form-row">
                                <mat-form-field>
                                  <input matInput placeholder="(101) příjmy ze samostatné činnosti" formControlName="row101" [textMask]="{mask: mainService.currencyMask, guide: false}" />
                                </mat-form-field>
                                <mat-form-field [ngClass]="{'readonly': offer.stage || income.get('costAccounting').value === 'flatRate'}">
                                  <input matInput placeholder="(102) výdaje ze samostatné činnosti" formControlName="row102" [readonly]="offer.stage || income.get('costAccounting').value === 'flatRate'" [textMask]="{mask: mainService.currencyMask, guide: false}" />
                                </mat-form-field>
                              </div>
                              <div class="form-row">
                                <mat-form-field class="readonly">
                                  <input matInput placeholder="(104) rozdíl mezi příjmy a výdaji SČ" formControlName="row104" readonly [textMask]="{mask: mainService.currencyMask, guide: false}"/>
                                </mat-form-field>
                                <mat-form-field>
                                  <input matInput placeholder="(106)" formControlName="row106" [textMask]="{mask: mainService.currencyMask, guide: false}"/>
                                </mat-form-field>
                              </div>
                              <div class="form-row">
                                <mat-form-field>
                                  <input matInput placeholder="(107)" formControlName="row107" [textMask]="{mask: mainService.currencyMask, guide: false}"/>
                                </mat-form-field>
                                <mat-form-field>
                                  <input matInput placeholder="(108)" formControlName="row108" [textMask]="{mask: mainService.currencyMask, guide: false}"/>
                                </mat-form-field>
                              </div>
                              <div class="form-row">
                                <mat-form-field>
                                  <input matInput placeholder="(109)" formControlName="row109" [textMask]="{mask: mainService.currencyMask, guide: false}"/>
                                </mat-form-field>
                                <mat-form-field>
                                  <input matInput placeholder="(110)" formControlName="row110" [textMask]="{mask: mainService.currencyMask, guide: false}"/>
                                </mat-form-field>
                              </div>
                              <div class="form-row">
                                <mat-form-field>
                                  <input matInput placeholder="(113) dílčí základ daně z příjmu" formControlName="row113" [textMask]="{mask: mainService.currencyMask, guide: false}" />
                                </mat-form-field>
                                <mat-form-field>
                                  <input matInput placeholder="(2A) uplatněné odpisy celkem" formControlName="totalDepricationApplied" [textMask]="{mask: mainService.currencyMask, guide: false}"/>
                                </mat-form-field>
                              </div>
                            </div>
                          </div>
                          <div class="form-section">
                            <div class="form-section-title">Příloha 2</div>
                            <div class="form-section-content">
                              <div class="form-row">
                                <mat-form-field>
                                  <input matInput placeholder="(201) příjmy z nájmu" formControlName="row201" [textMask]="{mask: mainService.currencyMask, guide: false}"/>
                                </mat-form-field>
                                <mat-form-field>
                                  <input matInput placeholder="(202) výdaje z nájmu" formControlName="row202" [textMask]="{mask: mainService.currencyMask, guide: false}"/>
                                </mat-form-field>
                              </div>
                            </div>
                          </div>
                          </ng-template>
                        </mat-expansion-panel>
                        </ng-template>
                      </mat-expansion-panel>
                    </ng-container>
                  </mat-accordion>
                  </ng-template>
                </mat-expansion-panel>
                <mat-expansion-panel [id]="'applicant' + aIndex + 'expenditures'" (afterExpand)="mainService.scrollToId('applicant' + aIndex + 'expenditures')">
                  <mat-expansion-panel-header [ngClass]="{'invalid': applicant.get('expenditures').invalid}">
                    <mat-panel-title>
                      <div>Výdaje</div>
                      <button mat-button color="primary" class="hide-not-expanded" *ngIf="offersService.canEdit()" (click)="$event.stopPropagation(); contactsService.addExpenditure(applicant, 'mortgage')"><span>Nový výdaj</span><mat-icon>add</mat-icon></button>
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <ng-template matExpansionPanelContent>
                  <mat-accordion formArrayName="expenditures">
                    <ng-container *ngFor="let expenditure of applicant.get('expenditures').controls; let exIndex = index;trackBy: trackControlByFn;">
                      <mat-expansion-panel [formGroupName]="exIndex" [id]="'applicant' + aIndex + 'expenditure' + exIndex" (afterExpand)="mainService.scrollToId('applicant' + aIndex + 'expenditure' + exIndex)">
                        <mat-expansion-panel-header [ngClass]="{'invalid': expenditure.invalid}">
                          <mat-panel-title class="middle-description">
                            <div class="elipsis"><span>{{ mainService.optionLabel(options('expenditureType'), expenditure.get('type').value) }}</span></div>
                            <div *ngIf="expenditure.get('type').value !== 'overdraft' && expenditure.get('type').value !== 'creditCard'">{{ mainService.maskCurrency(mainService.unmaskCurrency(expenditure.get('payment').value)) }} {{ mainService.optionLabel(options('currency'), expenditure.get('currency').value) }}</div>
                            <div *ngIf="expenditure.get('type').value === 'overdraft' || expenditure.get('type').value === 'creditCard'">{{ mainService.maskCurrency(mainService.unmaskCurrency(expenditure.get('limit').value)) }} {{ mainService.optionLabel(options('currency'), expenditure.get('currency').value) }}</div>
                            <button mat-icon-button color="warn" class="hide-not-expanded" *ngIf="offersService.canEdit()" (click)="$event.stopPropagation(); contactsService.removeExpenditure(applicant, exIndex)"><mat-icon>remove_circle_outline</mat-icon></button>
                          </mat-panel-title>
                        </mat-expansion-panel-header>
                        <ng-template matExpansionPanelContent>
                        <div class="form-section">
                          <div class="form-section-title">Obecné</div>
                          <div class="form-section-content">
                            <div class="form-row">
                              <mat-form-field>
                                <mat-select placeholder="Typ výdaje" *ngIf="offersService.canEdit()" formControlName="type" >
                                  <mat-option *ngFor="let option of options('expenditureType')" [value]="option.value">{{ option.label }}</mat-option>
                                </mat-select>
                                <input *ngIf="!offersService.canEdit()" matInput placeholder="Typ výdaje" [value]="mainService.optionLabel(options('expenditureType'), expenditure.get('type').value)" readonly />
                              </mat-form-field>
                              <mat-form-field *ngIf="expenditure.get('type').value !== 'overdraft' && expenditure.get('type').value !== 'creditCard'" class="w50">
                                <input matInput placeholder="Splátka" formControlName="payment" [textMask]="{mask: mainService.currencyMask, guide: false}"/>
                              </mat-form-field>
                              <mat-form-field *ngIf="expenditure.get('type').value !== 'overdraft' && expenditure.get('type').value !== 'creditCard' && expenditure.get('type').value !== 'alimony'" class="w50">
                                <input matInput placeholder="Zůstatek" formControlName="balance" [textMask]="{mask: mainService.currencyMask, guide: false}">
                              </mat-form-field>
                              <mat-form-field *ngIf="expenditure.get('type').value === 'overdraft' || expenditure.get('type').value === 'creditCard' || expenditure.get('type').value === 'other'" class="w50">
                                <input matInput placeholder="Rámec" formControlName="limit" [textMask]="{mask: mainService.currencyMask, guide: false}"/>
                              </mat-form-field>
                              <mat-form-field *ngIf="expenditure.get('type').value !== 'alimony'" class="w50">
                                <input matInput placeholder="Úrok" formControlName="interestRate" [textMask]="{mask: mainService.percentMask, guide: false}"/>
                              </mat-form-field>
                              <mat-form-field class="w30">
                                <input matInput placeholder="Měna" formControlName="currency" [matAutocomplete]="autoCurrency" />
                                <mat-autocomplete #autoCurrency="matAutocomplete" panelWidth="auto" [displayWith]="mainService.optionLabel.bind(expenditure.get('currency').value, options('currency'))">
                                  <mat-option *ngFor="let option of offersService.filterAutocompleteItems('currency', expenditure.get('currency').value)" [value]="option.value">
                                    {{ option.label }}
                                  </mat-option>
                                </mat-autocomplete>
                              </mat-form-field>
                              <mat-form-field *ngIf="expenditure.get('type').value === 'mortgage' || expenditure.get('type').value === 'other' || expenditure.get('type').value === 'buildingSavingsLoan'" class="w50">
                                <input matInput type="number" placeholder="Fixace" formControlName="fixation"/>
                              </mat-form-field>
                            </div>
                            <div class="form-row" *ngIf="expenditure.get('type').value !== 'alimony'">
                              <mat-form-field>
                                <mat-select placeholder="Splacení závazku" *ngIf="offersService.canEdit()" formControlName="repayment" >
                                  <mat-option *ngFor="let option of options('expenditureRepayment')" [value]="option.value">{{ option.label }}</mat-option>
                                </mat-select>
                                <input *ngIf="!offersService.canEdit()" matInput placeholder="Splacení závazku" [value]="mainService.optionLabel(options('expenditureRepayment'), expenditure.get('repayment').value)" readonly />
                              </mat-form-field>
                              <mat-form-field *ngIf="expenditure.get('type')?.value === 'mortgage'" class="w30">
                                <input matInput placeholder="Fixace do" formControlName="fixationEndAt" [matDatepicker]="fixationEndAtPicker" (change)="fixationEndAtChanged($event, expenditure)"/>
                                <mat-datepicker-toggle matSuffix [for]="fixationEndAtPicker"></mat-datepicker-toggle>
                                <mat-datepicker #fixationEndAtPicker></mat-datepicker>
                              </mat-form-field>
                            </div>
                            <ng-container *ngIf="expenditure.get('repayment')?.value === 'mortgage'" >
                              <div class="form- row" *ngFor="let modelation of (offersService.modelations.controls || []); let mIndex = index">
                                  <mat-checkbox (change)="offersService.addExpenditureToModelation(modelation, expenditure.get('_id')?.value, $event)" [checked]="modelation.get('repayExpendituresId')?.value?.includes(expenditure.get('_id')?.value)">Modelace {{mIndex + 1}}: {{ mainService.maskCurrency(mainService.unmaskCurrency(modelation.get('amount').value)) }} Kč, LTV: {{ modelation.get('ltv').value }}%, Fix.: {{ modelation.get('fixation').value }}, Splat.: {{ modelation.get('maturity').value | maturity }}, Poj.: {{ modelation.get('insurance').value ? 'Ano' : 'Ne' }}</mat-checkbox>
                              </div>
                            </ng-container>
                          </div>
                        </div>
                        <div class="form-section" formGroupName="source">
                          <div class="form-section-title">Věřitel</div>
                          <div class="form-section-content">
                            <div class="form-row">
                              <mat-form-field>
                                <mat-select placeholder="Typ věřitele" *ngIf="offersService.canEdit()" formControlName="type" >
                                  <mat-option *ngFor="let option of options('creditorType')" [value]="option.value">{{ option.label }}</mat-option>
                                </mat-select>
                                <input *ngIf="!offersService.canEdit()" matInput placeholder="Typ věřitele" [value]="mainService.optionLabel(options('creditorType'), expenditure.get('source.type').value)" readonly />
                              </mat-form-field>
                            </div>
                            <div class="form-row">
                              <mat-form-field *ngIf="expenditure.get('source.type').value !== 'bank'">
                                <input matInput placeholder="Název" formControlName="name" />
                              </mat-form-field>
                              <mat-form-field *ngIf="expenditure.get('source.type').value === 'bank'">
                                <input matInput placeholder="Název" formControlName="name" [matAutocomplete]="autoCurrency" />
                                <mat-autocomplete #autoCurrency="matAutocomplete" panelWidth="auto" [displayWith]="mainService.optionLabel.bind(expenditure.get('source.name').value, options('bank'))">
                                  <mat-option *ngFor="let option of offersService.filterAutocompleteItems('bank', expenditure.get('source.name').value)" [value]="option.value">
                                    {{ option.label }}
                                  </mat-option>
                                </mat-autocomplete>
                              </mat-form-field>
                              <mat-form-field class="w50">
                                <input matInput placeholder="IČO" formControlName="idNumber"/>
                                <mat-icon matSuffix class="search-ares" (click)="offersService.searchAres(expenditure)">search</mat-icon>
                              </mat-form-field>
                              <mat-form-field class="w50">
                                <input matInput placeholder="DIČ" formControlName="vatNumber"/>
                              </mat-form-field>
                              <mat-form-field class="w50">
                                <input matInput placeholder="Rodné číslo" formControlName="personalNumber"/>
                              </mat-form-field>
                            </div>
                            <div class="form-row">
                              <mat-form-field>
                                <input matInput placeholder="E-mailová adresa" formControlName="email"/>
                              </mat-form-field>
                              <mat-form-field class="w30">
                                <input matInput placeholder="Telefonní číslo" formControlName="phone"/>
                              </mat-form-field>
                            </div>
                            <div class="form-row">
                              <mat-form-field>
                                <input matInput placeholder="Odvětví NACE" formControlName="naceCode" [matAutocomplete]="autoNace"/>
                                <mat-autocomplete #autoNace="matAutocomplete" panelWidth="auto" [displayWith]="mainService.optionLabel.bind(expenditure.get('source.naceCode').value, options('nace'))">
                                  <mat-option *ngFor="let option of offersService.filterAutocompleteItems('nace', expenditure.get('source.naceCode').value)" [value]="option.value">
                                    {{ option.value }} - {{ option.label }}
                                  </mat-option>
                                </mat-autocomplete>
                              </mat-form-field>
                              <mat-form-field>
                                <input matInput placeholder="Sektor" formControlName="sector" [matAutocomplete]="autoSector"/>
                                <mat-autocomplete #autoSector="matAutocomplete" panelWidth="auto" [displayWith]="mainService.optionLabel.bind(expenditure.get('source.sector').value, options('sector'))">
                                  <mat-option *ngFor="let option of offersService.filterAutocompleteItems('sector', expenditure.get('source.sector').value)" [value]="option.value">
                                    {{ option.label }}
                                  </mat-option>
                                </mat-autocomplete>
                              </mat-form-field>
                              <mat-form-field class="w50">
                                <mat-select placeholder="Pozast. činnost" formControlName="suspendedActivity" *ngIf="offersService.canEdit()">
                                  <mat-option [value]="true">Ano</mat-option>
                                  <mat-option [value]="false">Ne</mat-option>
                                </mat-select>
                                <input *ngIf="!offersService.canEdit()" matInput placeholder="Pozast. činnost" [value]="expenditure.get('source.suspendedActivity').value" readonly />
                              </mat-form-field>
                            </div>
                            <ng-container formGroupName="address">
                              <div class="form-row">
                                <mat-form-field>
                                  <mat-label>Ulice <mat-icon>search</mat-icon></mat-label>
                                  <input matInput formControlName="street" [matAutocomplete]="autoAddress" (ngModelChange)="ruianService.searchAddress($event)" />
                                  <mat-autocomplete #autoAddress="matAutocomplete" panelWidth="auto" (optionSelected)="offersService.completeAddress(expenditure.get('source.address'), $event)">
                                    <mat-option *ngFor="let address of ruianService.results$ | async" [value]="address.objectId">
                                      {{ address.text }}
                                    </mat-option>
                                  </mat-autocomplete>
                                </mat-form-field>
                                <mat-form-field class="w30">
                                  <input matInput placeholder="Č. popisné" formControlName="houseNumber"/>
                                </mat-form-field>
                                <mat-form-field class="w30">
                                  <input matInput placeholder="Č. orientační" formControlName="orientationNumber"/>
                                </mat-form-field>
                                <mat-form-field class="w30">
                                  <app-input-mask placeholder="PSČ" formControlName="zipCode" mask="999 99" inputmode="numeric"></app-input-mask>
                                </mat-form-field>
                              </div>
                              <div class="form-row">
                                <mat-form-field>
                                  <input matInput placeholder="Město" formControlName="city" [matAutocomplete]="autoCity" (ngModelChange)="ruianService.cities($event)"/>
                                  <mat-autocomplete #autoCity="matAutocomplete" panelWidth="auto">
                                    <mat-option *ngFor="let city of ruianService.cities$ | async" [value]="city">
                                      {{ city }}
                                    </mat-option>
                                  </mat-autocomplete>
                                </mat-form-field>
                                <mat-form-field>
                                  <input matInput placeholder="Okres" formControlName="district" [matAutocomplete]="autoDistrict" (ngModelChange)="ruianService.districts($event)"/>
                                  <mat-autocomplete #autoDistrict="matAutocomplete" panelWidth="auto">
                                    <mat-option *ngFor="let district of ruianService.districts$ | async" [value]="district">
                                      {{ district }}
                                    </mat-option>
                                  </mat-autocomplete>
                                </mat-form-field>
                                <mat-form-field>
                                  <input matInput placeholder="Kraj" formControlName="region"/>
                                </mat-form-field>
                                <mat-form-field>
                                  <input matInput placeholder="Země" formControlName="country" [matAutocomplete]="autoCountry"/>
                                  <mat-autocomplete #autoCountry="matAutocomplete" panelWidth="auto" [displayWith]="mainService.optionLabel.bind(expenditure.get('source.address.country').value, options('country'))">
                                    <mat-option *ngFor="let option of offersService.filterAutocompleteItems('country', expenditure.get('source.address.country').value)" [value]="option.value">
                                      {{ option.label }}
                                    </mat-option>
                                  </mat-autocomplete>
                                </mat-form-field>
                              </div>
                            </ng-container>
                          </div>
                        </div>
                        </ng-template>
                      </mat-expansion-panel>
                    </ng-container>
                  </mat-accordion>
                  </ng-template>
                </mat-expansion-panel>
                <ng-container>
                  <mat-expansion-panel [id]="'applicant' + aIndex + 'identifications'" (afterExpand)="mainService.scrollToId('applicant' + aIndex + 'identifications')">
                    <mat-expansion-panel-header [ngClass]="{'invalid': applicant.get('identifications').invalid}">
                      <mat-panel-title>
                        <div>Identifikace</div>
                        <button mat-button color="primary" class="hide-not-expanded" (click)="$event.stopPropagation(); contactsService.addIdentification(applicant, 'mortgage')"><span>Nová identifikace</span><mat-icon>add</mat-icon></button>
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <ng-template matExpansionPanelContent>
                    <mat-accordion formArrayName="identifications">
                      <ng-container *ngFor="let identification of applicant.get('identifications').controls; let idIndex = index;trackBy: trackControlByFn;">
                        <mat-expansion-panel [formGroupName]="idIndex" [id]="'applicant' + aIndex + 'identification' + idIndex" (afterExpand)="mainService.scrollToId('applicant' + aIndex + 'identification' + idIndex)">
                          <mat-expansion-panel-header [ngClass]="{'invalid': identification.invalid}">
                            <mat-panel-title class="middle-description">
                              <div class="elipsis"><span>{{ mainService.optionLabel(options('identificationType'), identification.get('type').value) }}</span></div>
                              <div>{{ identification.get('number').value }}</div>
                              <button mat-icon-button color="warn" class="hide-not-expanded" [disabled]="applicant.get('identifications').controls.length < 2" (click)="$event.stopPropagation(); contactsService.removeIdentification(applicant, idIndex)"><mat-icon>remove_circle_outline</mat-icon></button>
                            </mat-panel-title>
                          </mat-expansion-panel-header>
                          <ng-template matExpansionPanelContent>
                          <div class="form-row">
                            <mat-form-field>
                              <mat-select placeholder="Typ dokladu" formControlName="type">
                                <mat-option *ngFor="let option of options('identificationType')" [value]="option.value">{{ option.label }}</mat-option>
                              </mat-select>
                            </mat-form-field>
                            <mat-form-field>
                              <input matInput placeholder="Číslo dokladu" formControlName="number"/>
                            </mat-form-field>
                          </div>
                          <div class="form-row">
                            <mat-form-field>
                              <input matInput placeholder="Datum vydání" formControlName="dateOfIssue" [matDatepicker]="issuedAtPicker"/>
                              <mat-datepicker-toggle matSuffix [for]="issuedAtPicker"></mat-datepicker-toggle>
                              <mat-datepicker #issuedAtPicker></mat-datepicker>
                            </mat-form-field>
                            <mat-form-field>
                              <input matInput placeholder="Platnost do" formControlName="validTo" [matDatepicker]="validUntilPicker"/>
                              <mat-datepicker-toggle matSuffix [for]="validUntilPicker"></mat-datepicker-toggle>
                              <mat-datepicker #validUntilPicker></mat-datepicker>
                            </mat-form-field>
                            <mat-form-field>
                              <input matInput placeholder="Země vydání" formControlName="country" [matAutocomplete]="autoCountry"/>
                              <mat-autocomplete #autoCountry="matAutocomplete" panelWidth="auto" [displayWith]="mainService.optionLabel.bind(identification.get('country').value, options('country'))">
                                <mat-option *ngFor="let option of offersService.filterAutocompleteItems('country', identification.get('country').value)" [value]="option.value">
                                  {{ option.label }}
                                </mat-option>
                              </mat-autocomplete>
                            </mat-form-field>
                            <mat-form-field>
                              <input matInput placeholder="Vydal" formControlName="issuedBy"/>
                            </mat-form-field>
                          </div>
                          </ng-template>
                        </mat-expansion-panel>
                      </ng-container>
                    </mat-accordion>
                    </ng-template>
                  </mat-expansion-panel>
                  <mat-expansion-panel [id]="'applicant' + aIndex + 'declarations'" (afterExpand)="mainService.scrollToId('applicant' + aIndex + 'declarations')">
                    <mat-expansion-panel-header [ngClass]="{'invalid': applicant.get('declarations').invalid}">
                      <mat-panel-title>
                        <div>Prohlášení</div>
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <ng-template matExpansionPanelContent>
                    <div class="form-list" formGroupName="declarations">
                      <li><mat-slide-toggle formControlName="isLinkedToBusinessCorporation">Je osobou propojenou s obchodní korporací?</mat-slide-toggle></li>
                      <li><mat-slide-toggle formControlName="isCloseToPersonRelatedToBank">Je osobou blízkou osobě se zvláštním vztahem k bance?</mat-slide-toggle></li>
                      <li><mat-slide-toggle formControlName="isRelatedToBank">Je osobou se zvláštním vztahem k bance?</mat-slide-toggle></li>
                      <li><mat-slide-toggle formControlName="isPoliticallyExposed">Je osobou politicky exponovanou osobou?</mat-slide-toggle></li>
                      <li><mat-slide-toggle formControlName="agreesHandlingPersonalData">Souhlasí se zpracováním osobních údajů?</mat-slide-toggle></li>
                    </div>
                    </ng-template>
                  </mat-expansion-panel>
                  <mat-expansion-panel [id]="'applicant' + aIndex + 'verification'" (afterExpand)="mainService.scrollToId('applicant' + aIndex + 'verification')">
                    <mat-expansion-panel-header>
                      <mat-panel-title>
                        <div>Ověření</div>
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <ng-template matExpansionPanelContent>
                      <div class="form-row" formGroupName="verifiedStatus">
                        <mat-form-field>
                          <mat-select placeholder="Bankovní identita" formControlName="bankId">
                            <mat-option [value]="null"></mat-option>
                            <mat-option [value]="'waiting'">Čeká na ověření</mat-option>
                            <mat-option [value]="'verified'">Ověřeno</mat-option>
                          </mat-select>
                        </mat-form-field>
                        <mat-form-field>
                          <mat-select placeholder="Kurýr" formControlName="courier">
                            <mat-option [value]="null"></mat-option>
                            <mat-option [value]="'waiting'">Čeká na ověření</mat-option>
                            <mat-option [value]="'verified'">Ověřeno</mat-option>
                          </mat-select>
                        </mat-form-field>
                        <mat-form-field>
                          <mat-select placeholder="Interní ověření" formControlName="internal">
                            <mat-option [value]="null"></mat-option>
                            <mat-option [value]="'waiting'">Čeká na ověření</mat-option>
                            <mat-option [value]="'verified'">Ověřeno</mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </ng-template>
                  </mat-expansion-panel>
                </ng-container>
              </mat-accordion>
              </ng-template>
            </mat-expansion-panel>
          </ng-container>
        </mat-accordion>
      </section>
      <section>
        <div class="card-title" [ngClass]="{'invalid': offersService.households.invalid || offersService._householdsForm.invalid}">
          <span>Domácnosti ({{ offersService._households.length }})</span>
          <div>
            <button mat-button color="primary" *ngIf="offersService.canEdit() && offersService._households.length < 2" [matMenuTriggerFor]="addHouseholdMenu"><span>Přidat domácnost</span><mat-icon>add</mat-icon></button>
          </div>
        </div>
        <mat-accordion>
          <ng-container *ngFor="let household of offersService._households; let hIndex = index;trackBy: trackControlByFn;">
            <mat-expansion-panel [@.disabled]="true" [id]="'household' + hIndex" (afterExpand)="mainService.scrollToId('household' + hIndex)">
              <mat-expansion-panel-header [ngClass]="{'invalid': household.invalid}">
                <mat-panel-title class="middle-description">
                  <div class="elipsis"><span>{{ mainService.optionLabel(options('householdType'), household.get('type').value) }} (Domácnost {{ hIndex + 1 }})</span></div>
                  <div class="flex-0-0-auto">Členů: {{ householdsService.householdMembersCount(household, offersService._applicants) }}</div>
                  <button mat-icon-button color="warn" class="hide-not-expanded" *ngIf="offersService.canEdit()" [disabled]="hIndex === 0" (click)="$event.stopPropagation(); offersService.removeHousehold(household)"><mat-icon>remove_circle_outline</mat-icon></button>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <ng-template matExpansionPanelContent [formGroup]="household">
              <div class="form-section">
                <div class="form-section-title">Obecné</div>
                <div class="form-section-content">
                  <div class="form-row">
                    <mat-form-field class="w30">
                      <mat-select placeholder="Typ domácnosti" *ngIf="offersService.canEdit()" formControlName="type" >
                        <mat-option *ngFor="let option of options('householdType')" [value]="option.value">{{ option.label }}</mat-option>
                      </mat-select>
                      <input *ngIf="!offersService.canEdit()" matInput placeholder="Typ domácnosti" [value]="mainService.optionLabel(options('householdType'), household.get('type').value)" readonly />
                    </mat-form-field>
                    <mat-form-field *ngIf="household.get('type').value === 'other'">
                      <input matInput placeholder="Jiný typ domácnosti" formControlName="other"/>
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <div class="form-section">
                <div class="form-section-title">Žadatelé</div>
                <div class="form-section-content">
                  <ul class="form-list">
                    <ng-container *ngFor="let applicant of offersService._applicants; let aIndex = index;trackBy: trackControlByFn;">
                      <li *ngIf="!offersService.otherHouseholdHasApplicant(applicant.get('_id').value, household.get('_id').value)"><mat-checkbox #checkApplicant [disabled]="!offersService.canEdit() && !checkApplicant.checked" [ngClass]="{'ng-invalid': !offersService.otherHouseholdHasApplicant(applicant.get('_id').value, household.get('_id').value) && !checkApplicant.checked}" [checked]="offersService.householdHasApplicant(household.get('_id').value, applicant.get('_id').value)" (change)="offersService.updateHouseholdApplicant(household.get('_id').value, applicant.get('_id').value, $event)">{{ applicant.get('titleBefore').value }} {{ applicant.get('firstName').value }} {{ applicant.get('lastName').value }} {{ applicant.get('titleAfter').value }} (Žadatel {{ aIndex + 1 }})</mat-checkbox></li>
                    </ng-container>
                  </ul>
                </div>
              </div>
              <div class="form-section">
                <div class="form-section-title" [ngClass]="{'invalid': household.get('members').invalid}">Ostatní členové</div>
                <div class="form-section-content">
                  <div class="form-section-buttons">
                    <button mat-button color="primary" *ngIf="offersService.canEdit()" (click)="$event.stopPropagation(); householdsService.addHouseholdMember(household)"><span>Nový člen</span><mat-icon>add</mat-icon></button>
                  </div>
                  <mat-accordion formGroupName="members">
                    <ng-container *ngFor="let member of householdsService.householdMembers(household).controls; let mmIndex = index;trackBy: trackControlByFn;">
                      <mat-expansion-panel [formGroupName]="mmIndex">
                        <mat-expansion-panel-header [ngClass]="{'invalid': member.invalid}">
                          <mat-panel-title class="middle-description">
                            <div class="elipsis"><span>{{ mainService.optionLabel(options('memberType'), member.get('type').value) }}</span></div>
                            <div>{{ member.get('age').value }} let</div>
                            <button mat-icon-button color="warn" class="hide-not-expanded" *ngIf="offersService.canEdit()" (click)="$event.stopPropagation(); householdsService.removeHouseholdMember(household, mmIndex)"><mat-icon>remove_circle_outline</mat-icon></button>
                          </mat-panel-title>
                        </mat-expansion-panel-header>
                        <ng-template matExpansionPanelContent>
                        <div class="form-row">
                          <mat-form-field>
                            <mat-select placeholder="Typ" *ngIf="offersService.canEdit()" formControlName="type" >
                              <mat-option *ngFor="let option of options('memberType')" [value]="option.value">{{ option.label }}</mat-option>
                            </mat-select>
                            <input *ngIf="!offersService.canEdit()" matInput placeholder="Typ" [value]="mainService.optionLabel(options('memberType'), member.get('type').value)" readonly />
                          </mat-form-field>
                          <mat-form-field class="w50">
                            <input matInput placeholder="Věk" formControlName="age" />
                          </mat-form-field>
                        </div>
                        </ng-template>
                      </mat-expansion-panel>
                    </ng-container>
                  </mat-accordion>
                </div>
              </div>
              <div class="form-section" formGroupName="address">
                <div class="form-section-title">Adresa</div>
                <div class="form-section-content">
                  <div class="form-row">
                    <mat-form-field>
                      <mat-label>Ulice <mat-icon>search</mat-icon></mat-label>
                      <input matInput formControlName="street" [matAutocomplete]="autoAddress" (ngModelChange)="ruianService.searchAddress($event)" />
                      <mat-autocomplete #autoAddress="matAutocomplete" panelWidth="auto" (optionSelected)="offersService.completeAddress(household.get('address'), $event)">
                        <mat-option *ngFor="let address of ruianService.results$ | async" [value]="address.objectId">
                          {{ address.text }}
                        </mat-option>
                      </mat-autocomplete>
                    </mat-form-field>
                    <mat-form-field class="w30">
                      <input matInput placeholder="Č. popisné" formControlName="houseNumber"/>
                    </mat-form-field>
                    <mat-form-field class="w30">
                      <input matInput placeholder="Č. orientační" formControlName="orientationNumber"/>
                    </mat-form-field>
                    <mat-form-field class="w30">
                      <app-input-mask placeholder="PSČ" formControlName="zipCode" mask="999 99" inputmode="numeric"></app-input-mask>
                    </mat-form-field>
                  </div>
                  <div class="form-row">
                    <mat-form-field>
                      <input matInput placeholder="Město" formControlName="city" [matAutocomplete]="autoCity" (ngModelChange)="ruianService.cities($event)"/>
                      <mat-autocomplete #autoCity="matAutocomplete" panelWidth="auto">
                        <mat-option *ngFor="let city of ruianService.cities$ | async" [value]="city">
                          {{ city }}
                        </mat-option>
                      </mat-autocomplete>
                    </mat-form-field>
                    <mat-form-field>
                      <input matInput placeholder="Okres" formControlName="district" [matAutocomplete]="autoDistrict" (ngModelChange)="ruianService.districts($event)"/>
                      <mat-autocomplete #autoDistrict="matAutocomplete" panelWidth="auto">
                        <mat-option *ngFor="let district of ruianService.districts$ | async" [value]="district">
                          {{ district }}
                        </mat-option>
                      </mat-autocomplete>
                    </mat-form-field>
                    <mat-form-field>
                      <input matInput placeholder="Kraj" formControlName="region"/>
                    </mat-form-field>
                    <mat-form-field>
                      <input matInput placeholder="Země" formControlName="country" [matAutocomplete]="autoCountry"/>
                      <mat-autocomplete #autoCountry="matAutocomplete" panelWidth="auto" [displayWith]="mainService.optionLabel.bind(household.get('address.country').value, options('country'))">
                        <mat-option *ngFor="let option of offersService.filterAutocompleteItems('country', household.get('address.country').value)" [value]="option.value">
                          {{ option.label }}
                        </mat-option>
                      </mat-autocomplete>
                    </mat-form-field>
                  </div>
                </div>
              </div>
              </ng-template>
            </mat-expansion-panel>
          </ng-container>
        </mat-accordion>
      </section>
      <section>
        <div class="card-title">
          <span>Pojištění</span>
        </div>
        <div class="card">
          <ul class="form-list">
            <ng-container *ngFor="let applicant of offersService._applicants; let aIndex = index;trackBy: trackControlByFn;">
              <li><mat-checkbox (change)="offersService.applicantInsurance(applicant.get('_id').value, $event)" [disabled]="!!offer.stage" [checked]="offersService.applicantHasInsurance(applicant.get('_id').value)">{{ applicant.get('titleBefore').value }} {{ applicant.get('firstName').value }} {{ applicant.get('lastName').value }} {{ applicant.get('titleAfter').value }} (Žadatel {{ aIndex + 1 }})</mat-checkbox></li>
            </ng-container>
          </ul>
        </div>
      </section>
      <section *ngIf="offersService.isStageBetween(offer.stage, 'approved')">
        <div class="card-title" [ngClass]="{'invalid': offersService.offerForm.get('drawdowns').hasError('drawdownAmount')}">
          <span>Čerpání ({{ offersService.drawdownPercent() }}%)</span>
          <div>
            <button mat-button color="primary" *ngIf="offersService.canEdit()" (click)="$event.stopPropagation(); offersService.addDrawdown()"><span>Nové čerpání</span><mat-icon>add</mat-icon></button>
          </div>
        </div>
        <mat-accordion formArrayName="drawdowns">
          <ng-container *ngFor="let drawdown of offersService.drawdowns.controls; let dIndex = index;trackBy: trackControlByFn;">
            <mat-expansion-panel [@.disabled]="true" [formGroupName]="dIndex" [id]="'drawdown' + dIndex" (afterExpand)="mainService.scrollToId('drawdown' + dIndex)">
              <mat-expansion-panel-header [ngClass]="{'invalid': drawdown.invalid}">
                <mat-panel-title class="middle-description">
                  <div>{{ mainService.maskCurrency(mainService.unmaskCurrency(drawdown.get('amount').value)) }} Kč ({{ drawdown.get('amountPercent').value }}%)</div>
                  <div><span *ngIf="drawdown.get('isPurposeful').value">Účelové</span><span *ngIf="!drawdown.get('isPurposeful').value">Neúčelové</span></div>
                  <div *ngIf="!drawdown.get('drawnOn').value">Datum čerpání: {{ drawdown.get('dateFrom').value | date:'shortDate':undefined:'cs-CS' }} - {{ drawdown.get('dateUntil').value | date:'shortDate':undefined:'cs-CS' }}</div>
                  <div *ngIf="drawdown.get('drawnOn').value">Čerpáno dne: {{ drawdown.get('drawnOn').value | date:'shortDate':undefined:'cs-CS' }}</div>
                  <button mat-icon-button color="warn" class="hide-not-expanded" [disabled]="dIndex === 0" *ngIf="offersService.canEdit()" (click)="$event.stopPropagation(); offersService.removeDrawdown(dIndex)"><mat-icon>remove_circle_outline</mat-icon></button>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <ng-template matExpansionPanelContent>
              <div class="form-row">
                <mat-form-field>
                  <input matInput placeholder="Výše čerpání" formControlName="amount" [textMask]="{mask: mainService.currencyMask, guide: false}" />
                </mat-form-field>
                <mat-form-field>
                  <mat-select placeholder="Účelové čerpání" formControlName="isPurposeful">
                    <mat-option [value]="true">Ano</mat-option>
                    <mat-option [value]="false">Ne</mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field>
                  <input matInput placeholder="Datum čerpání od" [matDatepicker]="drawdownDateFrom" formControlName="dateFrom" />
                  <mat-datepicker-toggle matSuffix [for]="drawdownDateFrom"></mat-datepicker-toggle>
                  <mat-datepicker #drawdownDateFrom></mat-datepicker>
                </mat-form-field>
                <mat-form-field>
                  <input matInput placeholder="Datum čerpání do" [matDatepicker]="drawdownDateUntil" formControlName="dateUntil" />
                  <mat-datepicker-toggle matSuffix [for]="drawdownDateUntil"></mat-datepicker-toggle>
                  <mat-datepicker #drawdownDateUntil></mat-datepicker>
                </mat-form-field>
                <mat-form-field>
                  <input matInput placeholder="Čerpáno dne" [matDatepicker]="drawdownDate" formControlName="drawnOn" />
                  <mat-datepicker-toggle matSuffix [for]="drawdownDate"></mat-datepicker-toggle>
                  <mat-datepicker #drawdownDate></mat-datepicker>
                </mat-form-field>
              </div>
              </ng-template>
            </mat-expansion-panel>
          </ng-container>
        </mat-accordion>
      </section>
    </div>
  </div>
</div>
<div class="comment" [formGroup]="offersService.offerForm">
  <mat-tab-group formGroupName="note" dynamicHeight>
    <mat-tab label="Specialista">
      <ng-template matTabContent>
        <textarea placeholder="Poznámka" matInput cdkTextareaAutosize formControlName="specialist"></textarea>
      </ng-template>
    </mat-tab>
    <mat-tab label="Nabídky">
      <ng-template matTabContent>
        <textarea placeholder="Poznámka" matInput cdkTextareaAutosize formControlName="operator"></textarea>
      </ng-template>
    </mat-tab>
    <mat-tab label="Podpora">
      <ng-template matTabContent>
        <textarea placeholder="Poznámka" matInput cdkTextareaAutosize formControlName="support"></textarea>
      </ng-template>
    </mat-tab>
    <mat-tab label="Čerpání">
      <ng-template matTabContent>
        <textarea placeholder="Poznámka" matInput cdkTextareaAutosize formControlName="drawer"></textarea>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>
<mat-menu #addPropertyMenu="matMenu">
  <button mat-menu-item (click)="offersService.addNewProperty()">Nová nemovitost</button>
  <button mat-menu-item (click)="offersService.addProperty(property)" *ngFor="let property of offersService.applicantsProperties | contactProperties:offersService.offerForm.get('properties').value"><span>{{ mainService.optionLabel(options('propertyType'), property?.type) }} - {{ mainService.maskCurrency(property?.value) }} Kč</span> <span *ngIf="property?.address?.city || property?.address?.street || property?.address?.zipCode">&nbsp; ({{ property?.address.zipCode }}, {{ property?.address.city }}, {{ property?.address.street }})</span></button>
</mat-menu>
<mat-menu #addHouseholdMenu="matMenu">
  <button mat-menu-item (click)="offersService.addNewHousehold()">Nová domácnost</button>
  <button mat-menu-item (click)="offersService.addHousehold(household)" *ngFor="let household of offersService.applicantsHouseholds | contactHouseholds:offersService.offerForm.get('households').value"><span>{{ mainService.optionLabel(options('householdType'), household.type) }}</span> <span *ngIf="household.address?.city || household.address?.street || household.address?.zipCode">&nbsp; ({{ household.address.zipCode }}, {{ household.address.city }}, {{ household.address.street }})</span></button>
</mat-menu>
