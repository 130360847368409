import { Injectable } from '@angular/core';
import {DataService} from './data.service';
import {Deal} from '@app/models/deal.model';

@Injectable({providedIn: 'root'})
export class AssignService extends DataService<Deal> {

  constructor(
  ) {
    super('deals/assign');
    this.query = {
    };
  }
}
