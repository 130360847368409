import { MatDialogRef } from '@angular/material/dialog';
import { Component } from '@angular/core';

@Component({
  selector: 'app-dialog',
  template: `
    <h2 mat-dialog-title>Zamítnout případ</h2>
    <mat-dialog-content>
      <div class="form-row" *ngFor="let reasonOption of reasonOptions; let reasonIndex = index">
        <mat-form-field>
          <mat-select placeholder="Vyber možnost" [(ngModel)]="reasons[reasonIndex]" (selectionChange)="updateOptions(reasonIndex)">
            <ng-container *ngIf="reasonIndex === 0">
              <ng-container *ngFor="let option of (options[reasonOption] | optionsFilter: {params: {isArchived: false}}:'label')">
                <mat-option [value]="option.value">{{ option.label }}</mat-option>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="reasonIndex !== 0">
              <ng-container *ngFor="let option of options[reasonOption]">
                <mat-option [value]="option.value">{{ option.label }}</mat-option>
              </ng-container>
            </ng-container>
          </mat-select>
        </mat-form-field>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions>
      <button type="button" mat-button color="warn" (click)="dialogRef.close(null)">Zrušit</button>
      <button type="button" mat-raised-button color="primary" [disabled]="reasons.length !== reasonOptions.length" (click)="dialogRef.close(reasons)">Potvrdit</button>
    </mat-dialog-actions>
  `,
})

export class CancelDialogComponent {

  public options: any[];
  public reasons: string[];
  public reasonOptions: string[];

  constructor(
    public dialogRef: MatDialogRef<CancelDialogComponent>,
  ) {
    this.options = [];
    this.reasons = [];
    this.reasonOptions = ['cancelReason'];
  }

  public updateOptions(index) {
    this.reasons.splice(index + 1);
    this.reasonOptions.splice(index + 1);
    const nextOptions = this.reasons[index] + 'CancelReason';
    if (this.options[nextOptions]) {
      this.reasonOptions.push(nextOptions);
    }
  }
}
