import { Injectable } from '@angular/core';
import { Router, Resolve, RouterStateSnapshot,
  ActivatedRouteSnapshot } from '@angular/router';
import {DealsService} from '../services/deals.service';
import {UsersService} from '../services/users.service';
import {Deal} from '@app/models/deal.model';

@Injectable()
export class DealResolver implements Resolve<Deal> {
  constructor(
    private router: Router,
    private dealsService: DealsService,
    private usersService: UsersService
  ) {

  }

  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<Deal> {
    const dealId = route.paramMap.get('dealId');
    return new Promise(async (resolve) => {
      try {
        const deal: Deal = await this.dealsService.get(dealId);
        if (deal && (this.usersService.isPrivileged('deals/all') || deal.ownerId === this.usersService.user._id || deal.drawerId === this.usersService.user._id  || this.usersService.user.specialists?.includes(deal.ownerId))) {
          this.dealsService.setDeal(deal);
          resolve(deal);
        } else {
          await this.router.navigate(['/', 'deals']);
        }
      } catch (e) {
        await this.router.navigate(['/', 'deals']);
      }
    });
  }
}
