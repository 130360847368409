import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {FormArray, FormGroup} from '@angular/forms';
import {Injectable} from '@angular/core';
import {BackendService} from './backend.service';
import {environment} from '@env/environment';
import {UsersService} from './users.service';
import {Observable} from 'rxjs';
import {MainService} from '@app/services/main.service';
import {DocumentFile} from '@app/interfaces/document-file.interface';

@Injectable()
export class FilesService {
  private _socket: any;
  public imageViewer = false;
  public fullImage: string = null;

  constructor(
      private http: HttpClient,
      private backendService: BackendService,
      private usersService: UsersService,
      private mainService: MainService
  ) {
    this._socket = this.backendService.getService('files');
  }

  public create(file: File): Observable<any> {
    const path = environment.backendUrl + '/files/upload';
    const formData: FormData = new FormData();
    const headers = new HttpHeaders({
      Authorization: this.backendService.accessToken,
    });
    formData.append('uri', file, encodeURIComponent(file.name));
    return this.http.post(path, formData, {headers, withCredentials: true});
  }

  public getFileGetUrl(id: string): string {
    return `${environment.backendUrl}/file/get/${id}?token=${this.backendService.accessToken}`;
  }

  public getFileDownloadUrl(id: string, filename: string | null): string {
    const suffix = filename ? `&filename=${encodeURIComponent(filename)}` : '';
    return `${environment.backendUrl}/file/download/${id}?token=${this.backendService.accessToken}${suffix}`;
  }

  public get(id: string, fileName = null) {
    let headers = new HttpHeaders();
    const params = new HttpParams();
    headers = new HttpHeaders({
      Authorization: this.backendService.accessToken,
    });
    this.http.get(environment.backendUrl + '/files/' + id, {headers, params, withCredentials: true})
        .subscribe((response: any) => {
          let byteString;
          if (response.uri.split(',')[0].indexOf('base64') >= 0) {
            byteString = atob(response.uri.split(',')[1]);
          } else {
            byteString = unescape(response.uri.split(',')[1]);
          }
          // separate out the mime component
          const mimeString = response.uri.split(',')[0].split(':')[1].split(';')[0];

          // write the bytes of the string to a typed array
          const ia = new Uint8Array(byteString.length);
          for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
          }
          const blob = new Blob([ia], {type: mimeString});
          const url = window.URL.createObjectURL(blob);
          if (!['pdf', 'jpg', 'png'].includes(fileName.split('.').reverse()[0].toLowerCase())) {
            const anchor = document.createElement('a');
            anchor.href = url;
            anchor.target = '_blank';
            if (fileName) {
              anchor.download = fileName;
            }
            document.body.appendChild(anchor);
            anchor.click();
            document.body.removeChild(anchor);
          } else {
            window.open(url);
          }
        });
  }

  public getFileInfo(name: any, joined: boolean = false): { type: string, icon: string } {
    if (joined) {
      return {type: 'pdf', icon: 'join_inner'};
    }
    const parts = name.split('.');
    if (parts.length > 1) {
      const extension = parts[parts.length - 1].toLowerCase();
      switch (extension) {
        case 'pdf':
          return {type: 'pdf', icon: 'picture_as_pdf'};
        case 'jpg':
        case 'jpeg':
        case 'png':
          return {type: 'image', icon: 'image'};
        default:
          return {type: 'other', icon: 'note'};
      }
    } else {
      return {type: 'file', icon: 'note'};
    }
  }

  public moveFileInDocuments(files: DocumentFile[], group: FormArray, sourceGroup: string, destGroup: string): boolean {
    const srcIndex = group.controls.findIndex((formGroup: FormGroup) =>
        formGroup.controls._id.value === sourceGroup
    );
    const dstIndex = group.controls.findIndex((formGroup: FormGroup) =>
        formGroup.controls._id.value === destGroup
    );

    if (srcIndex >= 0 && dstIndex >= 0) {
      const srcFiles = group.controls[srcIndex].get('files').value;
      const dstFiles = group.controls[dstIndex].get('files').value;

      files.forEach(file => {
        const index = srcFiles.findIndex((item) => item.path === file.path);
        dstFiles.push(srcFiles[index]);
        srcFiles.splice(index, 1);
      });

      group.controls[dstIndex].patchValue(dstFiles);
      group.controls[srcIndex].patchValue(srcFiles);
      group.controls[dstIndex].markAsDirty();
      group.controls[srcIndex].markAsDirty();
      return true;
    }
  }

  public async mergeFiles(documentId: string, filename: string, filesId: string[]): Promise<any> {
    this.mainService.showLoading();
    try {
      const mergeService = this.backendService.getService('file/merge');
      const params = {documentId, files: filesId, outputName: filename};
      const res = await mergeService.create(params);
      this.mainService.hideLoading();
      return res;
    } catch (e) {
      this.mainService.hideLoading();
      console.error(e);
      return false;
    }
  }

  public async shrinkFile(fileName: string, filePath: string): Promise<any> {
    this.mainService.showLoading();
    try {
      const shrinkService = this.backendService.getService('file/shrink');
      const params = {name: fileName, path: filePath};
      const res = await shrinkService.create(params);
      res.originalname = 'zmenšený_' + fileName;
      this.mainService.hideLoading();
      return res;
    } catch (e) {
      this.mainService.hideLoading();
      console.log(e);
      return false;
    }
  }

  public async exportFiles(documentId: string, filePaths: string[]) {
    this.mainService.showLoading();

    const files = encodeURIComponent(JSON.stringify(filePaths));
    const url = `${environment.backendUrl}/file/export/${documentId}?token=${this.backendService.accessToken}&files=${files}`;
    const anchor = document.createElement('a');
    anchor.href = url;
    anchor.target = '_blank';
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
    this.mainService.hideLoading();
  }
}
