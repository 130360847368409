import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { InputMaskComponent } from '@app/components/input-mask/input-mask.component';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule],
  exports: [InputMaskComponent],
  declarations: [InputMaskComponent]
})
export class InputMaskModule {}
