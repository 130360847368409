import { Pipe, PipeTransform } from '@angular/core';
import { Pipeline } from '@app/models/pipeline.model';

@Pipe({ name: 'pipeline'})
export class PipelinePipe implements PipeTransform {
  constructor(
  ) {}
  transform(pipelineId: string, pipelines?: Pipeline[]) {
    return pipelines.find(p => p._id === pipelineId);
  }
}
