import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { DealsService } from '@app/services/deals.service';
import {CallsService} from '@app/services/calls.service';
import {CallingWidgetService} from '@app/components/callingwidget/callingwidget.service';

@Component({
  selector: 'app-dial-button',
  templateUrl: 'dial-button.component.html',
  styleUrls: ['dial-button.component.scss']
})

export class DialButtonComponent implements OnChanges {
  @Input() collectionName: 'deals' | 'cases' | 'contacts' | 'tasks' | undefined;
  @Input() collectionId: string | undefined;
  @Input() menuItem: boolean = false;
  @Input() compact: boolean = false;
  @Input() hideSms: boolean = false;
  @Input() widget: boolean = false;
  @Input() countryCode: string | undefined;
  @Input() phoneNumber: string | undefined;
  @Input() contactName: string | undefined;
  @Input() contactId: string | undefined;
  @Input() dealName: string | undefined;
  @Input() iconColor: ThemePalette | undefined;

  @Output() clickDial = new EventEmitter<any>();

  fallbackUrl: string;
  isNumberInvalid: boolean;

  constructor(
    public callsService: CallsService,
    private dealsService: DealsService,
    public callingWidgetService: CallingWidgetService
  ) {
  }

  public async ngOnChanges(changes: SimpleChanges): Promise<void> {
    this.isNumberInvalid = false;
    if (this.collectionName !== undefined && this.collectionId !== undefined) {
      await this.dataLoad();
    }

    if (this.dealName !== undefined) {
      const tmp = this.dealName.split(' ');
      tmp.shift();
      this.contactName = tmp.join(' ');
    }

    if (this.countryCode === '+420') {
      this.fallbackUrl = `tel:${this.phoneNumber}`;
    } else {
      this.fallbackUrl = `tel:${this.countryCode} ${this.phoneNumber}`;
    }
    if (this.phoneNumber === undefined || this.phoneNumber === null || this.phoneNumber === '') {
      this.isNumberInvalid = true;
    }
  }

  public async dataLoad(): Promise<void> {
    switch (this.collectionName) {
      case 'deals':
        const deal = await this.dealsService.get(this.collectionId);
        this.phoneNumber = deal.phones?.[0]?.number;
        this.countryCode = deal.phones?.[0]?.countryCode;
        this.contactName = deal.name;
        this.contactId = deal._offers[deal.offers.length - 1].applicants[0];
        break;
      case 'cases':
        break;
      case 'contacts':
        break;
      case 'tasks':
        break;
    }
  }

  public async dialNumber(): Promise<void> {
    this.clickDial.emit({
      countryCode: this.countryCode,
      phoneNumber: this.phoneNumber,
      contactName: this.contactName,
      contactId: this.contactId
    });
  }
  public async openConversation(): Promise<void> {
    let phone = this.phoneNumber;
    if (this.countryCode) {
      phone = this.countryCode + this.phoneNumber;
    }
    this.callingWidgetService.addConversation(phone);
  }
}
