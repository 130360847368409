<nav mat-tab-nav-bar class="tabs tabs-invert tabs-noborder" mat-align-tabs="center">
  <a mat-tab-link [active]="timelineLink.isActive" [routerLink]="['/deals', deal._id, 'offers', offer._id, 'timeline']" routerLinkActive #timelineLink="routerLinkActive">Přehled</a>
  <a mat-tab-link [active]="tasksLink.isActive" [routerLink]="['/deals', deal._id, 'offers', offer._id, 'tasks']" routerLinkActive #tasksLink="routerLinkActive">Úkoly</a>
  <a mat-tab-link [active]="formLink.isActive" [routerLink]="['/deals', deal._id, 'offers', offer._id, 'edit']" routerLinkActive #formLink="routerLinkActive" [ngClass]="{invalid: !offersService.formValid()}">Formulář</a>
  <a mat-tab-link [active]="modelationsLink.isActive" [routerLink]="['/deals', deal._id, 'offers', offer._id, 'modelations']" routerLinkActive #modelationsLink="routerLinkActive" [ngClass]="{invalid: !offersService.modelationsValid()}">Modelace</a>
  <a mat-tab-link [active]="documentsLink.isActive" [routerLink]="['/deals', deal._id, 'offers', offer._id, 'documents']" routerLinkActive #documentsLink="routerLinkActive" [ngClass]="{invalid: !offersService.documentsValid()}">Dokumenty</a>
  <a mat-tab-link [active]="commissionsLink.isActive" [routerLink]="['/deals', deal._id, 'offers', offer._id, 'commissions']" routerLinkActive #commissionsLink="routerLinkActive"><span>Provize</span></a>
</nav>
<div class="offer">
  <router-outlet></router-outlet>
</div>
