<div class="bg-red-100 p-8">
  <div class="flex flex-row justify-between items-center">
    <div class="text-2xl font-semibold pb-2">Něco se pokazilo...</div>
    <div class="flex flex-row justify-end items-center gap-2">
      <button mat-flat-button (click)="copyMsg()">Zkopírovat</button>
      <button mat-flat-button color="primary" (click)="close()">Zavřít</button>
    </div>
  </div>
  <div class="text-lg font-semibold py-2 border-b border-gray-600">{{ param.title }}</div>
  <div class="font-semibold py-2 break-words" *ngIf="param.message" [innerHTML]="param.message"></div>
  <div class="text-sm py-2 break-words" *ngIf="param.stack" [innerHTML]="param.stack"></div>
  <div class="text-sm py-2" *ngIf="param.url">{{ param.url }}</div>
  <div class="text-sm font-semibold pt-2">Verze: {{ appInfo.version }}:{{ appInfo.env }}</div>
</div>
