<div class="file-list flex flex-col">

  <div class="form-section-title">Soubory</div>

  <div class="files-header flex flex-row justify-between items-center w-full">
    <div class="flex flex-row">
      <mat-checkbox [checked]="isCheckedAll"
                    [indeterminate]="isCheckedSome"
                    (change)="setAll($event.checked)"
                    *ngIf="filesInfo.length > 0">
        {{ isCheckedAll ? 'Odznačit všechny' : 'Označit všechny' }}
      </mat-checkbox>
    </div>
    <div>
      <button mat-icon-button color="primary"
              *ngIf="!readonly"
              [disabled]="filesInfo.length === 0"
              (click)="joinFile()"
              [matTooltip]="isCheckedSome ? 'Spojit vybrané' : 'Spojit všechny'">
        <mat-icon>join_inner</mat-icon>
      </button>

      <button mat-icon-button color="primary"
              *ngIf="!readonly && !isInternal"
              [disabled]="filesInfo.length === 0"
              (click)="rejectFiles()"
              [matTooltip]="isCheckedSome ? 'Zamítnout vybrané' : 'Zamítnout všechny'">
        <mat-icon>do_not_disturb</mat-icon>
      </button>

      <button mat-icon-button color="primary"
              *ngIf="!readonly"
              [disabled]="filesInfo.length === 0"
              (click)="downloadFiles()"
              [matTooltip]="isCheckedSome ? 'Stáhnout vybrané' : 'Stáhnout všechny'">
        <mat-icon>cloud_download</mat-icon>
      </button>

      <button mat-icon-button color="primary"
              *ngIf="!readonly && !isInternal"
              [disabled]="!usersService.isPrivileged('documents:create')"
              (click)="uploadFile(true)"
              matTooltip="Nahrát soubory k podpisu">
        <mat-icon>draw</mat-icon>
      </button>

      <button mat-icon-button color="primary"
              *ngIf="!readonly"
              [disabled]="!usersService.isPrivileged('documents:create')"
              (click)="uploadFile(false)"
              matTooltip="Nahrát soubory">
        <mat-icon>add</mat-icon>
      </button>
    </div>
  </div>

  <ng-container *ngFor="let isPlatform of [true, false]">
    <ng-container *ngIf="(isPlatform && hasPlatformFiles) || (!isPlatform && hasClientFiles)">

      <div [ngClass]="{'files-list': true, 'files-list-top': isPlatform || (!isPlatform && !hasPlatformFiles) }">
        <div class="files-item">
          <div class="font-semibold text-gray-400">
            {{ isPlatform ? 'Soubory nahrané námi' : 'Soubory nahrané klientem' }}
          </div>
        </div>
      </div>

      <div cdkDropList
           [ngClass]="{'files-list': true, 'files-list-bottom': !isPlatform || (isPlatform && !hasClientFiles) }"
           (cdkDropListDropped)="drop($event)">
        <ng-container *ngFor="let file of filesInfo; index as findex">
          <div class="files-item" *ngIf="file | fileListIsPlatform : isPlatform" cdkDrag [cdkDragDisabled]="readonly">
            <div class="files-drag-placeholder" *cdkDragPlaceholder></div>
            <div class="flex flex-row gap-2">

              <div class="grow flex flex-row justify-start items-center gap-1">
                <div class="file-check flex-none">
                  <mat-checkbox [checked]="file.isSelected" (change)="setSelected(findex)"></mat-checkbox>
                </div>
                <div class="file-name grow shrink flex flex-row justify-start items-center gap-1" cdkDragHandle
                     (click)="showPreview(file, true)">
                  <mat-icon [ngClass]="{'cursor-move': !readonly}">{{ file.icon }}</mat-icon>
                  <div [ngClass]="{'cursor-pointer': (file.type === 'image' || file.type === 'pdf') && this.canDisplayFile, 'text-green': file.hasUserFiles }">
                    <div class="flex flex-col">
                      <div>
                        {{ file.name }}
                      </div>
                      <div *ngIf="file.status === 'rejected'" class="text-red">
                        {{ file.rejectionReason }}
                      </div>
                    </div>
                  </div>
                  <mat-icon *ngIf="file.hasUserFiles"
                            style="color: var(--color-green)"
                            matTooltip="Tento spojený soubor jsme vytvořili my, avšak obsahuje i soubory klienta.">
                    info
                  </mat-icon>
                  <mat-icon *ngIf="file.isDeliveredByUs">
                    draw
                  </mat-icon>
                </div>
                <div class="file-date flex-none">{{ file.createdAt | date :'dd.MM.YYYY HH:mm' }}</div>
                <div class="file-size flex-none">{{ file.size | fileSize }}</div>
              </div>

              <div class="flex shrink-0 flex-row justify-end items-center w-40">
                <ng-container *ngIf="!!file.isJoined">
                  <button mat-icon-button color="primary"
                          *ngIf="!file.isExpanded"
                          (click)="setExpanded(findex, true)">
                    <mat-icon>unfold_more</mat-icon>
                  </button>

                  <button mat-icon-button color="primary"
                          *ngIf="!!file.isExpanded"
                          (click)="setExpanded(findex, false)">
                    <mat-icon>unfold_less</mat-icon>
                  </button>
                </ng-container>

                <a mat-icon-button color="primary" matTooltip="Stáhnout soubor" [href]="file | fileDownloadUrl">
                  <mat-icon>cloud_download</mat-icon>
                </a>

                <button mat-icon-button
                        matTooltip="Soubor není zobrazen v bankingu"
                        color="warn"
                        *ngIf="!readonly && !isInternal && !!file.isHidden"
                        (click)="fileHidden(false, findex, null)">
                  <mat-icon>visibility_off</mat-icon>
                </button>

                <button mat-icon-button
                        matTooltip="Soubor je zobrazen v bankingu"
                        color="primary"
                        *ngIf="!readonly && !isInternal && !file.isHidden"
                        (click)="fileHidden(true, findex, null)">
                  <mat-icon>visibility</mat-icon>
                </button>

                <button mat-icon-button color="primary"
                        [disabled]="readonly"
                        [matMenuTriggerFor]="fileMenu"
                        [matMenuTriggerData]="{file: file, findex: findex}">
                  <mat-icon>more_vert</mat-icon>
                </button>
              </div>
            </div>

            <ng-container *ngIf="!!file.isExpanded">
              <div class="flex flex-row gap-2" *ngFor="let file2 of file.joinedFiles; index as findex2">

                <div class="grow flex flex-row justify-start items-center gap-1">
                  <div class="file-check flex-none">
                  </div>
                  <div class="file-name grow shrink flex flex-row justify-start items-center gap-1"
                       (click)="showPreview(file2, false)">
                    <mat-icon>{{ file2.icon }}</mat-icon>
                    <span [ngClass]="{'cursor-pointer': (file2.type === 'image' || file2.type === 'pdf') && this.canDisplayFile,
                                      'text-green': !file2.isPlatformChange}">
                      {{ file2.name }}
                    </span>
                    <mat-icon *ngIf="file2.isDeliveredByUs">draw</mat-icon>
                  </div>
                  <div class="file-date flex-none">{{ file2.createdAt | date :'dd.MM.YYYY HH:mm' }}</div>
                  <div class="file-size flex-none">{{ file2.size | fileSize }}</div>
                </div>

                <div class="flex shrink-0 flex-row justify-end items-center w-40">
                  <a mat-icon-button color="primary" matTooltip="Stáhnout soubor" [href]="file2 | fileDownloadUrl">
                    <mat-icon>cloud_download</mat-icon>
                  </a>

                  <button mat-icon-button color="warn" matTooltip="Soubor není zobrazen v bankingu"
                          *ngIf="!!file2.isHidden && !isInternal"
                          (click)="fileHidden(false, findex, findex2)">
                    <mat-icon>visibility_off</mat-icon>
                  </button>

                  <button mat-icon-button color="primary" matTooltip="Soubor je zobrazen v bankingu"
                          *ngIf="!file2.isHidden && !isInternal"
                          (click)="fileHidden(true, findex, findex2)">
                    <mat-icon>visibility</mat-icon>
                  </button>

                  <button mat-icon-button color="primary" [disabled]="true">
                    <mat-icon>more_vert</mat-icon>
                  </button>

                </div>
              </div>
            </ng-container>
          </div>
        </ng-container>
      </div>
    </ng-container>
  </ng-container>
</div>

<mat-menu #fileMenu="matMenu">
  <ng-template matMenuContent let-file="file" let-findex="findex">
    <button mat-menu-item *ngIf="findex > 0" (click)="moveItem(findex, findex - 1)">
      <mat-icon>keyboard_double_arrow_up</mat-icon>
      Posunout nahoru
    </button>

    <button mat-menu-item *ngIf="findex < filesInfo.length - 1" (click)="moveItem(findex, findex + 1)">
      <mat-icon>keyboard_double_arrow_down</mat-icon>
      Posunout dolu
    </button>

    <button mat-menu-item (click)="moveFile(findex)">
      <mat-icon>tab_move</mat-icon>
      Přesunout
    </button>

    <button mat-menu-item *ngIf="file.type === 'image'" (click)="shrinkFile(findex)">
      <mat-icon>density_medium</mat-icon>
      Zmenšit velikost
    </button>

    <button mat-menu-item *ngIf="!!file.isJoined" (click)="splitFile(findex)">
      <mat-icon>join_left</mat-icon>
      Rozpojit soubory
    </button>

    <button mat-menu-item *ngIf="file.status === 'rejected'" (click)="cancelRejectFile(findex)">
      <mat-icon>do_not_disturb_off</mat-icon>
      Zrušit zamítnutí souboru
    </button>

    <button mat-menu-item *ngIf="!file.isJoined" (click)="removeFile(findex)">
      <mat-icon color="warn">clear</mat-icon>
      Smazat
    </button>
  </ng-template>
</mat-menu>

<app-file-preview [files]="previewFiles"
                  [index]="previewIndex"
                  [(display)]="previewVisible">
</app-file-preview>

<app-file-upload [title]="uploadTitle"
                 [isDeliveredByUs]="uploadForSign"
                 [(visible)]="uploadVisible"
                 (uploadFile)="fileUploaded($event)">
</app-file-upload>
