import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Observable } from 'rxjs';
import { Task } from '@app/models/task.model';
import { TasksService } from '@app/services/tasks.service';
import { TasksListOptions } from '@app/components/tasks-list/tasks-list.interface';
import { Note } from '@app/models/note.model';

@Component({
  selector: 'app-tasks-list',
  templateUrl: './tasks-list.component.html',
  styleUrls: ['./tasks-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TasksListComponent implements OnChanges {
  @Input() tasks: Observable<Task[]>;
  @Input() options: TasksListOptions;

  @Output() readonly noteChange: EventEmitter<{ task: Task; note: Note }> = new EventEmitter<{ task: Task; note: Note }>();
  @Output() readonly taskComplete: EventEmitter<Task> = new EventEmitter();

  constructor(public tasksService: TasksService) {
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.options) {
      this.tasksService.saveOptionsToStorage(this.options);
    }
  }

  public taskMoved(event: Task): void {
    const elementId = 'task-' + event._id;
    setTimeout(() => {
      const element = document.getElementById(elementId);
      if (element) {
        element.scrollIntoView({ block: 'end', inline: 'nearest' });
      }
    }, 250);
  }

  public noteChanged(event: { task: Task; note: Note }): void {
    this.taskMoved(event.task);
    this.noteChange.emit(event);
  }

  public taskCompleted(event: Task): void {
    this.taskComplete.emit(event);
  }
}
