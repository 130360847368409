import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'participantLifeInsurance', pure: false })
export class ParticipantLifeInsurancePipe implements PipeTransform {
  constructor() {}
  transform(participant: any) {
    const roles = [];
    participant = participant.getRawValue();
    if (participant.isInsurer) {
      roles.push('Pojistník');
    }
    if (participant.isInsured) {
      roles.push('Pojištěná osoba');
    }
    if (!participant.isInsurer && participant.isInsured && participant.hasLegalRepresentative) {
      roles.push('Má zákonného zástupce');
    }
    return roles.join(', ');
  }
}
