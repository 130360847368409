import { Injectable } from '@angular/core';
import {DataService} from './data.service';
import {Pipeline} from '@app/models/pipeline.model';
import {BehaviorSubject, Observable} from 'rxjs';
import {DialogsService} from '@app/services/dialogs.service';
import {SettingsService} from '@app/services/settings.service';
import {MainService} from '@app/services/main.service';
import {UsersService} from '@app/services/users.service';
import {DemandsService} from '@app/services/demands.service';

@Injectable({providedIn: 'root'})
export class PipelinesService extends DataService<Pipeline> {

  public pipelines: Pipeline[] = [];
  public stages: any[] = [];
  public isLoaded = false;
  constructor(
    private dialogsService: DialogsService,
    private settingsService: SettingsService,
    private usersService: UsersService,
    private demandsService: DemandsService,
    private mainService: MainService,
  ) {
    super('pipelines');
  }
  public getPipeline(pipelineUrl): Pipeline {
    if (pipelineUrl === 'mortgage') { // Docasne simulovani klasicke pipeline
      const postponeReasons = this.mainService.options('postponeReason');
      const cancelReasons = this.mainService.options('cancelReason');
      return {
        stages: Object.keys(this.settingsService.settings.stages).map(id => ({id, label: this.settingsService.settings.stages[id]})),
        postponeReasons: postponeReasons.map(reason => {
          reason.id = reason.value;
          return reason;
        }),
        cancelReasons: cancelReasons.map(reason => {
          reason.id = reason.value;
          return reason;
        })
      };
    } else {
      return this.pipelines.find(p => p.url === pipelineUrl);
    }
  }

  public getStage(pipelineUrl, stageId) {
    let stage;
    const stages = this.getPipeline(pipelineUrl)?.stages;
    const stageIndex = stages?.findIndex(s => s.id === (stageId || 'init'));
    if (stageIndex > -1) {
      stage = stages[stageIndex];
      if (!stage.next) {
        stage.next = [];
      }
      if (stages[stageIndex + 1]) {
        stage.next.push(stages[stageIndex + 1].id);
      }
    }
    return stage;
  }

  public isStageBetween(pipelineUrl, stage, from, to = null) {
    const pipeline = this.getPipeline(pipelineUrl);
    const index = stage ? pipeline.stages.findIndex(s => s.id === stage) : 0;
    const fromIndex = from ? pipeline.stages.findIndex(s => s.id === from) : 0;
    const toIndex = to ? pipeline.stages.findIndex(s => s.id === to) : 1000;
    return index >= fromIndex && index <= toIndex;
  }
}
