import { Pipe, PipeTransform } from '@angular/core';
import { FilesService } from '@app/services/files.service';
import { DocumentFile } from '@app/interfaces/document-file.interface';

@Pipe({ name: 'fileDownloadUrl'})
export class FileDownloadUrlPipe implements PipeTransform {
  constructor(private filesService: FilesService) {
  }

  public transform(value: DocumentFile): string {
    if (!value) {
      return '';
    } else {
      return this.filesService.getFileDownloadUrl(value.path, value.name);
    }
  }
}



