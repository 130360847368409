// import * as Sentry from '@sentry/angular-ivy';
import {ErrorHandler, Injectable, Injector, LOCALE_ID, NgModule} from '@angular/core';
import {CommonModule, CurrencyPipe, DatePipe, registerLocaleData} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {HttpClientModule} from '@angular/common/http';
import localeCs from '@angular/common/locales/cs';
import localeCsExtra from '@angular/common/locales/extra/cs';
import {DragDropModule} from '@angular/cdk/drag-drop';

import {
  MAT_DATE_FORMATS,
  MatDateFormats,
  MatNativeDateModule,
  MatRippleModule,
} from '@angular/material/core';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatMenuModule} from '@angular/material/menu';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatIconModule, MatIconRegistry} from '@angular/material/icon';
import {MatBadgeModule} from '@angular/material/badge';
import {MatSelectModule} from '@angular/material/select';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatChipsModule} from '@angular/material/chips';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatTabsModule} from '@angular/material/tabs';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatListModule} from '@angular/material/list';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatDialogModule} from '@angular/material/dialog';
import {MatMomentDateModule} from '@angular/material-moment-adapter';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatSliderModule} from '@angular/material/slider';

import {TextMaskModule} from 'angular2-text-mask';

import {environment} from '@env/environment';
import {TaskTypePipe} from '@app/pipes/task-type.pipe';
import {SafePipe} from '@app/pipes/safe.pipe';
import {MaturityPipe} from '@app/pipes/maturity.pipe';
import {DocumentStatusPipe} from '@app/pipes/document-status.pipe';
import {ProviderLowestratePipe} from '@app/pipes/provider-lowestrate.pipe';
import {FilterDealsValidatorDirective} from '@app/directives/filter-deals-validator.directive';
import {MatButtonLoadingDirective} from '@app/directives/button-loading/button-loading.directive';
import {ProductSaleGroupsPipe} from '@app/pipes/product-sale-groups.pipe';
import {ProductAppliedSalesPipe} from '@app/pipes/product-applied-sales.pipe';
import {ProductIsAppliedSalePipe} from '@app/pipes/product-is-applied-sale.pipe';
import {ProductAppliedGroupSalePipe} from '@app/pipes/product-applied-group-sale.pipe';
import {ContactHouseholdsPipe} from '@app/pipes/contact-households.pipe';
import {DataFindItemPipe} from '@app/pipes/data-find-item.pipe';
import {PropertyGetFormPipe} from '@app/pipes/property-get-form.pipe';
import {FormArrayPipe} from '@app/pipes/form-array.pipe';
import {FormGroupPipe} from '@app/pipes/form-group.pipe';
import {ContactGetFormPipe} from '@app/pipes/contact-get-form.pipe';
import {ContactPropertiesPipe} from '@app/pipes/contact-properties.pipe';
import {ParticipantCarInsurancePipe} from '@app/pipes/participant-car-insurance.pipe';
import {ParticipantLifeInsurancePipe} from '@app/pipes/participant-life-insurance.pipe';
import {OwnerUserPipe} from '@app/pipes/owner-user.pipe';
import {ModelationVariantsStatusPipe} from '@app/pipes/modelation-variants-has.pipe';
import {ParticipantLifeInsuranceSortPipe} from '@app/pipes/participant-life-insurance-sort.pipe';
import {ChildrenLifeInsurancePipe} from '@app/pipes/children-life-insurance.pipe';
import {VariantIdentifierPipe} from '@app/pipes/variant-identifier.pipe';
import {TypesRealEstateInsurancePipe} from '@app/pipes/types-real-estate-insurance.pipe';
import {TypesCarInsurancePipe} from '@app/pipes/types-car-insurance.pipe';
import {KeysFromControlsPipe} from '@app/pipes/keys-from-controls.pipe';
import {LifeInsuranceShowPipe} from '@app/pipes/life-insurance-show.pipe';
import {CaseStagePipe} from '@app/pipes/case-stage.pipe';
import {CasesFilterStagePipe} from '@app/pipes/cases-filter-stage.pipe';
import {UserPipelinesPipe} from '@app/pipes/user-pipelines.pipe';
import {CasePipelinePipe} from '@app/pipes/case-pipeline.pipe';
import {InterventionGetFormPipe} from '@app/pipes/intervention-get-form.pipe';
import {InterventionsIncompleteCountPipe} from '@app/pipes/interventions-incomplete-count.pipe';
import {ProviderFromFinalVariantPipe} from '@app/pipes/provider-final-variant.pipe';
import {GetUserPipe} from '@app/pipes/get-user.pipe';
import {CaseNumberPipe} from '@app/pipes/case-number.pipe';
import {UserNamePipe} from '@app/pipes/user-name.pipe';
import {CasePhonesPipe} from '@app/pipes/case-phones.pipe';
import { PipelineUrlPipe } from '@app/pipes/pipeline-url.pipe';
import {RelationLeadApplicantGroupPipe} from '@app/pipes/relation-lead-applicant-group.pipe';
import {ContactNamePipe} from '@app/pipes/contact-name.pipe';
import {ContactGetPipe} from '@app/pipes/contact-get.pipe';
import {MatRadioModule} from '@angular/material/radio';
import {FormArrayContainsPipe} from '@app/pipes/form-array-contains.pipe';
import {ProviderGetPipe} from '@app/pipes/provider-get.pipe';
import {ParticipantsInsuredPipe} from '@app/pipes/participants-insured.pipe';
import {OptionsFilterPipe} from '@app/pipes/options-filter.pipe';
import {FileSizePipe} from '@app/pipes/file-size.pipe';
import {FileIconPipe} from '@app/pipes/file-icon.pipe';
import {FileDownloadUrlPipe} from '@app/pipes/file-download-url.pipe';
import {SecondsToMinutesPipe} from '@app/pipes/seconds-to-minutes.pipe';
import {DateDescPipe} from '@app/pipes/date-desc.pipe';
import {PhoneNumberPipe} from '@app/pipes/phone-number.pipe';
import {ElapsedTimePipe} from '@app/pipes/elapsed-time.pipe';
import {PipelineStagePipe} from '@app/pipes/pipeline-stage.pipe';
import {CharactersWordsPipe} from '@app/pipes/characters-words.pipe';
import {PipelinePipe} from '@app/pipes/pipeline.pipe';
import {ActivityDealPipe} from '@app/pipes/activity-deal.pipe';
import {OptionsLabelPipe} from '@app/pipes/options-label.pipe';
import {OptionLabelPipe} from '@app/pipes/option-label.pipe';
import {AffiliateLabelPipe} from '@app/pipes/affiliate-label.pipe';
import {FullAddressPipe} from '@app/pipes/full-adress.pipe';
import {CustomErrorHandler} from '@app/error-handler/custom-error-handler';
import {DialogService} from 'primeng/dynamicdialog';

// DATADOG stuff
import { datadogRum } from '@datadog/browser-rum';
// eslint-disable-next-line @typescript-eslint/no-var-requires
const { version } = require('../../../version.json');
console.log(`%cVersion: ${version}, Environment: ${environment.env}`, 'font-size:24px;color:#02c57d');
export const appInfo: { version: string, env: string } = { version: version, env: environment.env };

if (environment.env === 'prod' || environment.env === 'dev') {
  datadogRum.init({
    applicationId: '11c8636d-ebce-4446-8a72-c9f794789cf1',
    clientToken: 'pub5c63d967b263e2525d627d2247755d9e',
    site: 'datadoghq.eu',
    service: 'hypoportal-frontend',
    env: environment.env,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'allow',
    version: version
  });
}

// Sentry.init({
//   dsn: 'https://63eb94e8d78b4774b278e1329331c907@sentry.io/1379455',
//   replaysSessionSampleRate: 0,
//   replaysOnErrorSampleRate: environment.production ? 1.0 : 0,
//   integrations: [new Sentry.Replay()],
// });

// @Injectable()
// export class SentryErrorHandler implements CustomErrorHandler {
//   constructor() {}
//   handleError(error) {
//     console.error(error);
//     if (environment.production) {
//       const eventId = Sentry.captureException(error.originalError || error);
//       Sentry.showReportDialog({eventId});
//     }
//   }
// }

registerLocaleData(localeCs, 'cs-CS', localeCsExtra);
const MOMENT_DATE_FORMATS: MatDateFormats = {
  parse: {
    dateInput: 'DD.MM.YYYY'
  },
  display: {
    dateInput: 'DD.MM.YYYY',
    monthYearLabel: 'MMMM Y',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM Y'
  }
};

const imports: any[] = [
];

const declarations: any[] = [
  TaskTypePipe,
  SafePipe,
  MaturityPipe,
  DocumentStatusPipe,
  ProviderLowestratePipe,
  ProductSaleGroupsPipe,
  ProductAppliedSalesPipe,
  ProductIsAppliedSalePipe,
  ProductAppliedGroupSalePipe,
  ContactPropertiesPipe,
  ContactHouseholdsPipe,
  FilterDealsValidatorDirective,
  MatButtonLoadingDirective,
  DataFindItemPipe,
  PropertyGetFormPipe,
  ContactGetFormPipe,
  FormArrayPipe,
  FormGroupPipe,
  ParticipantCarInsurancePipe,
  ParticipantLifeInsurancePipe,
  OwnerUserPipe,
  ModelationVariantsStatusPipe,
  ParticipantLifeInsuranceSortPipe,
  ChildrenLifeInsurancePipe,
  VariantIdentifierPipe,
  TypesRealEstateInsurancePipe,
  TypesCarInsurancePipe,
  KeysFromControlsPipe,
  LifeInsuranceShowPipe,
  CaseStagePipe,
  CasesFilterStagePipe,
  UserPipelinesPipe,
  CasePipelinePipe,
  InterventionGetFormPipe,
  InterventionsIncompleteCountPipe,
  ProviderFromFinalVariantPipe,
  GetUserPipe,
  CaseNumberPipe,
  UserNamePipe,
  CasePhonesPipe,
  PipelineUrlPipe,
  RelationLeadApplicantGroupPipe,
  ContactNamePipe,
  ContactGetPipe,
  FormArrayContainsPipe,
  ProviderGetPipe,
  ParticipantsInsuredPipe,
  OptionsFilterPipe,
  SecondsToMinutesPipe,
  DateDescPipe,
  PhoneNumberPipe,
  ElapsedTimePipe,
  FileSizePipe,
  FileIconPipe,
  FileDownloadUrlPipe,
  ElapsedTimePipe,
  PipelineStagePipe,
  CharactersWordsPipe,
  PipelinePipe,
  ActivityDealPipe,
  OptionsLabelPipe,
  OptionLabelPipe,
  AffiliateLabelPipe,
  FullAddressPipe,
];

const modules: any[] = [
  CommonModule,
  FormsModule,
  RouterModule,
  HttpClientModule,
  ReactiveFormsModule,
  MatFormFieldModule,
  MatMenuModule,
  MatInputModule,
  MatButtonModule,
  MatSnackBarModule,
  MatProgressSpinnerModule,
  MatIconModule,
  MatBadgeModule,
  MatSelectModule,
  MatRippleModule,
  MatButtonToggleModule,
  MatNativeDateModule,
  MatDatepickerModule,
  MatExpansionModule,
  MatChipsModule,
  MatAutocompleteModule,
  MatSlideToggleModule,
  TextMaskModule,
  MatTabsModule,
  MatToolbarModule,
  MatListModule,
  MatTooltipModule,
  MatDialogModule,
  MatMomentDateModule,
  MatCheckboxModule,
  DragDropModule,
  MatSliderModule,
  MatRadioModule
];

const providers: any[] = [
  {
    provide: ErrorHandler,
    useClass: CustomErrorHandler,
  },
  DatePipe,
  CurrencyPipe,
  MatIconRegistry,
  RelationLeadApplicantGroupPipe,
  DialogService,
  {
    provide: LOCALE_ID, useValue: 'cs-CS'
  },
  {provide: MAT_DATE_FORMATS, useValue: MOMENT_DATE_FORMATS},
];

@NgModule({
  declarations: [...declarations],
  imports: [...imports, ...modules],
  providers: [...providers],
  exports: [...imports, ...modules, ...declarations],
})
export class CoreModule {
  /**
   * Allows for retrieving singletons using `AppModule.injector.get(MyService)`
   * This is good to prevent injecting the service as constructor parameter.
   */
  static injector: Injector;
  constructor(injector: Injector, iconRegistry: MatIconRegistry) {
    CoreModule.injector = injector;
  }
}
