import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from '@app/modules/core.module';
import { EditorModule } from 'primeng/editor';
import { RichEditorComponent } from '@app/components/rich-editor/rich-editor.component';
import { DialogModule } from 'primeng/dialog';

@NgModule({
  declarations: [RichEditorComponent],
  imports: [CommonModule, CoreModule, EditorModule, DialogModule],
  exports: [RichEditorComponent],
})
export class RichEditorModule {}
