import {
  AfterViewInit,
  ComponentFactory,
  ComponentFactoryResolver,
  ComponentRef,
  Directive, ElementRef, EventEmitter,
  Input,
  OnChanges, Output,
  Renderer2,
  SimpleChanges,
  ViewContainerRef
} from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { MatButton } from '@angular/material/button';
import { MatProgressSpinner } from '@angular/material/progress-spinner';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: `[chat-item]`,
})
export class ChatItemDirective implements AfterViewInit {


  @Output()
  loaded: EventEmitter<ElementRef> = new EventEmitter<ElementRef>();

  constructor(
    private el: ElementRef
  ) {
  }

  public ngAfterViewInit() {
    this.loaded.emit(this.el);
  }
}
