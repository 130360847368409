import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UsersService } from '@app/services/users.service';
import { TitleService } from '@app/services/title.service';
import { SnackbarService } from '@app/services/snackbar.service';
import { ActivatedRoute } from '@angular/router';
import { DialogsService } from '@app/services/dialogs.service';
import { appInfo } from '@app/modules/core.module';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent {
  public loginForm: FormGroup;
  public isCode = false;
  public isLoading = false;
  public appInfo = appInfo;

  constructor(
    private usersService: UsersService,
    private titleService: TitleService,
    private snackbarService: SnackbarService,
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private dialogsService: DialogsService
  ) {
    this.titleService.setTitle('Přihlášení');
    this.usersService.patchRefreshedAt();
    this._createLoginForm();
    activatedRoute.queryParams.subscribe((params) => {
      localStorage.removeItem('deviceRegistration');
      if (params.deviceId && params.model && params.platform) {
        localStorage.setItem('deviceRegistration', JSON.stringify(params));
      }
    });
  }

  public async login() {
    if (this.loginForm.valid) {
      this.isLoading = true;
      try {
        if (!this.isCode) {
          await this.usersService.generateCode(this.loginForm.get('email').value, this.loginForm.get('password').value);
          this.isCode = true;
          this.loginForm.get('code').setValidators([Validators.required]);
          this.isLoading = false;
        } else {
          const loggedin = await this.usersService.login(this.loginForm.get('email').value, this.loginForm.get('password').value + '|' + this.loginForm.get('code').value);
          if (!loggedin) {
            this.isCode = false;
            this.loginForm.get('code').clearValidators();
            this.loginForm.get('code').patchValue('');
            this.isLoading = false;
          } else {
            if (localStorage.getItem('deviceRegistration')) {
              const deviceRegistration = JSON.parse(localStorage.getItem('deviceRegistration'));
              this.usersService.patch(null, {$push: {registeredDevices: {deviceId: deviceRegistration.deviceId, model: deviceRegistration.model, platform: deviceRegistration.platform, registeredAt: new Date()}}}, {query: {_id: this.usersService.user._id, 'registeredDevices.deviceId': {$ne: deviceRegistration.deviceId}}});
              localStorage.removeItem('deviceRegistration');
              this.dialogsService.modalInfo('Zařízení bylo úspěšně registrováno.', 'Zařízení bylo úspěšně registrováno. Nyní můžete začít používat aplikaci.');
            }
          }
        }
      } catch (e) {
        this.isCode = false;
        this.snackbarService.showError('Špatné přihlašovací údaje.');
        this.loginForm.get('code').clearValidators();
        this.loginForm.get('code').patchValue('');
        this.isLoading = false;
      }
    }
  }

  private _createLoginForm() {
    this.loginForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
      code: ['']
    });
  }
}
