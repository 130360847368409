import { MatDialogRef } from '@angular/material/dialog';
import { Component } from '@angular/core';
import { DocumentFile } from '@app/interfaces/document-file.interface';

export type FileOperationsType = 'join' | 'reject';

@Component({
  selector: 'app-dialog',
  templateUrl: './file-operations-dialog.component.html',
  styleUrls: ['./file-operations-dialog.component.scss'],
})
export class FileOperationsDialogComponent {
  public operationType: FileOperationsType;
  public files: DocumentFile[];
  public title = '';
  public description = '';
  public ready: boolean;

  public result: { fileName?: string, rejectionReason?: string, files: DocumentFile[] };

  constructor(
    public dialogRef: MatDialogRef<FileOperationsDialogComponent>,
  ) {
    this.result = { fileName: '', rejectionReason: '', files: [] };
  }

  public passResult() {
    this.result.files = this.files.filter(file => file.warn !== true);
    this.dialogRef.close(this.result);
  }
}
