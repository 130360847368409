import {Component, OnInit} from '@angular/core';
import {environment} from '@env/environment';
import { Inject } from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {

  public env = environment;

  constructor(
    @Inject(DOCUMENT) private document,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {
    this.matIconRegistry.addSvgIcon(
      'priority-highest',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/priority-highest.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'priority-high',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/priority-high.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'priority-medium',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/priority-medium.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'priority-low',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/priority-low.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'priority-lowest',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/priority-lowest.svg')
    );
  }

  ngOnInit(): void {
  }
}
