import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from '@app/modules/core.module';
import { TasksListComponent } from '@app/components/tasks-list/tasks-list.component';
import { TasksListItemModule } from '@app/components/tasks-list-item/tasks-list-item.module';

@NgModule({
  declarations: [TasksListComponent],
  imports: [
    CommonModule,
    CoreModule,
    TasksListItemModule,
  ],
  exports: [TasksListComponent],
})
export class TasksListModule {}
