import { environment } from '@env/environment';
import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import * as io from 'socket.io-client';
import feathers, {Service} from '@feathersjs/feathers';
import socketIoClient from '@feathersjs/socketio-client';
import authentication from '@feathersjs/authentication-client';
import jwt_decode from 'jwt-decode';

@Injectable()
export class BackendService {
  public autologout = 60;
  public client: any;
  readonly io: any;
  public accessToken = null;

  // Subjects
  private $connected: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);

  // Observables
  public connected$: Observable<boolean> = this.$connected.asObservable();

  constructor(
  ) {
    this.io = io(environment.backendUrl);
    this.io.on('connect', () => {
      this.$connected.next(true);
    });
    this.io.on('disconnect', (reason) => {
      this.$connected.next(false);
    });
    this.client = feathers()
      .configure(socketIoClient(this.io, {timeout: 60000}))
      .configure(authentication());

    this.client.hooks({
      before: {
        all: [
          this.refreshActivity
        ]
      },
      error: {
        all: []
      }
    });

    setInterval(() => {
      this.updateAutologout();
    }, 1000);
  }

  get refreshedAt(): number {
    return Number(localStorage.getItem('refreshedAt'));
  }

  public getService(item: string) {
    return this.client.service(item);
  }

  public service(path: string): Service<any> {
    return this.getService(path);
  }

  public checkRefreshedAt() {
    if (this.accessToken && this.refreshedAt) {
      const token: any = jwt_decode(this.accessToken);
      const now = Date.now();
      const exp = token.exp * 1000;
      const ref = this.refreshedAt;
      if (now > exp || ref < now - (60 * 60 * 1000)) {
        return false;
      }
    }
    return true;
  }

  private refreshActivity = (): void => {
    if (this.accessToken && this.checkRefreshedAt()) {
      localStorage.setItem('refreshedAt', Date.now().toString());
    }
    this.updateAutologout();
  }

  private updateAutologout() {
    this.autologout = Math.ceil(((60 * 60 * 1000) - (Date.now() - this.refreshedAt)) / 60000);
  }
}
