import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from '@app/modules/core.module';
import { InfoDialogComponent } from '@app/error-handler/info-dialog.component';

@NgModule({
  declarations: [InfoDialogComponent],
  imports: [CommonModule, CoreModule],
  exports: [InfoDialogComponent],
})
export class InfoDialogModule {}
