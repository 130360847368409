import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { UserGroup } from '@app/models/user-group.model';

@Injectable({providedIn: 'root'})
export class UserGroupsService extends DataService<UserGroup> {

  constructor(
  ) {
    super('user-groups');
    this.sort = {
      name: 1,
    };
    this.load(0);
  }

  public getGroupInfo(id: string) {
    return this.items.find(u => u._id === id);
  }

}
