import { Pipe, PipeTransform } from '@angular/core';
import {DocumentTemplateInterface} from '../interfaces/document-template.interface';

@Pipe({ name: 'documentStatus' })
export class DocumentStatusPipe implements PipeTransform {
  constructor() {}
  transform(status: string, template: DocumentTemplateInterface) {
    return template?.statuses.find((s: any) => s.value === status)?.label || null;
  }
}
