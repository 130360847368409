<h2 mat-dialog-title *ngIf="task._offer"><span>{{ task.dealName }} - {{ task._offer?.numberId}}</span></h2>
<h2 mat-dialog-title *ngIf="!task._offer"><span>{{ task.dealName }}</span></h2>
<mat-dialog-content>
  <form #taskForm="ngForm">
    <div class="form-row">
      <mat-form-field>
        <input matInput placeholder="Úkol" [(ngModel)]="task.title" name="title" required/>
      </mat-form-field>
    </div>
    <div class="form-row">
      <mat-form-field>
        <mat-select placeholder="Priorita" [(ngModel)]="task.priority" name="priority">
          <mat-select-trigger style="display: flex; align-items: center;">
            <ng-container [ngTemplateOutlet]="priorityTmpl" [ngTemplateOutletContext]="task"></ng-container>
            <span>{{ priorityText(task.priority) }}</span>
          </mat-select-trigger>
          <ng-container *ngFor="let option of options['priority']">
            <mat-option [value]="option.value"><ng-container [ngTemplateOutlet]="priorityTmpl" [ngTemplateOutletContext]="{priority: option.value}"></ng-container>{{ option.label }}</mat-option>
          </ng-container>
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Přiřazeno</mat-label>
        <input matInput [(ngModel)]="assigned" name="assignedToId" required [matAutocomplete]="acUsers" (ngModelChange)="filterOwners($event)"
               #autoTrigger="matAutocompleteTrigger">
        <button [disableRipple]="true" *ngIf="assigned?.name" matSuffix mat-icon-button (click)="openPanel();">
          <mat-icon>arrow_drop_down</mat-icon>
        </button>
        <mat-autocomplete #acUsers="matAutocomplete" [displayWith]="userDisplayFn">
          <mat-option [value]="owner" *ngFor="let owner of filteredOwners">{{ owner.name }}</mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>
    <div class="form-row">
      <mat-form-field>
        <input matInput placeholder="Splnit do" [matDatepicker]="dueAtDate" [(ngModel)]="task.dueAt" name="dueAt" required (ngModelChange)="dueDateAtChanged($event, dueAtHours, dueAtMinutes)" />
        <mat-datepicker-toggle matSuffix [for]="dueAtDate"></mat-datepicker-toggle>
        <mat-datepicker #dueAtDate></mat-datepicker>
      </mat-form-field>
      <mat-form-field *ngIf="task.dueAt">
        <mat-select placeholder="Hodin" [(ngModel)]="dueAtHours" (ngModelChange)="hoursChanged($event, task.dueAt, dueAtHours)" name="hours" >
          <ng-container *ngFor="let value of hourOptions">
            <mat-option [value]="value">{{ pad(value, 2) }}</mat-option>
          </ng-container>
        </mat-select>
      </mat-form-field>
      <mat-form-field *ngIf="task.dueAt">
        <mat-select placeholder="Minut" [(ngModel)]="dueAtMinutes" (ngModelChange)="minutesChanged($event, task.dueAt, dueAtMinutes)" name="minutes" >
          <ng-container *ngFor="let value of minuteOptions">
            <mat-option [value]="value">{{ pad(value, 2) }}</mat-option>
          </ng-container>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="form-row">
      <mat-form-field>
        <mat-select placeholder="Připomenout" [(ngModel)]="reminder" (ngModelChange)="reminderChanged($event)" name="reminder" >
          <mat-option [value]="''"></mat-option>
          <mat-option [value]="0">Dnes</mat-option>
          <mat-option [value]="1">Zítra</mat-option>
          <mat-option [value]="2">Za 2 dny</mat-option>
          <mat-option [value]="3">Za 3 dny</mat-option>
          <mat-option [value]="7">Za týden</mat-option>
          <mat-option [value]="14">Za 2 týdny</mat-option>
          <mat-option [value]="30">Za měsíc</mat-option>
          <mat-option [value]="90">Za 2 měsíce</mat-option>
          <mat-option [value]="180">Za 6 měsíců</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="form-row">
      <mat-form-field>
        <input matInput placeholder="Připomenout dne" [matDatepicker]="remindAtDate" [(ngModel)]="task.remindAt" (ngModelChange)="remindDateAtChanged($event, remindAtHours, remindAtMinutes)" name="remindAt" />
        <mat-datepicker-toggle matSuffix [for]="remindAtDate"></mat-datepicker-toggle>
        <mat-icon matSuffix (click)="task.remindAt = null" style="cursor: pointer; line-height: 1">close</mat-icon>
        <mat-datepicker #remindAtDate></mat-datepicker>
      </mat-form-field>
      <mat-form-field *ngIf="task.remindAt">
        <mat-select placeholder="Hodin" [(ngModel)]="remindAtHours" (ngModelChange)="hoursChanged($event, task.remindAt, remindAtHours)" name="hours" >
          <ng-container *ngFor="let value of hourOptions">
            <mat-option [value]="value">{{ pad(value, 2) }}</mat-option>
          </ng-container>
        </mat-select>
      </mat-form-field>
      <mat-form-field *ngIf="task.remindAt">
        <mat-select placeholder="Minut" [(ngModel)]="remindAtMinutes" (ngModelChange)="minutesChanged($event, task.remindAt, remindAtMinutes)" name="minutes" >
          <ng-container *ngFor="let value of minuteOptions">
            <mat-option [value]="value">{{ pad(value, 2) }}</mat-option>
          </ng-container>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="form-row">
      <mat-form-field>
        <textarea placeholder="Popis" matInput cdkTextareaAutosize name="text" [(ngModel)]="task.text"></textarea>
      </mat-form-field>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions>
  <button type="button" mat-button color="warn" (click)="dialogRef.close(null)">Zrušit</button>
  <app-dial-button [compact]="false" [phoneNumber]="task.phones?.[0]?.number" [countryCode]="task.phones?.[0]?.countryCode" [dealName]="task.dealName" (clickDial)="dialPhone($event, task)"></app-dial-button>
  <button type="button" mat-raised-button color="warn" (click)="dialogRef.close(false)" *ngIf="task._id">Smazat</button>
  <button type="button" mat-raised-button color="accent" (click)="dialogRef.close(task)" [disabled]="taskForm.pristine || taskForm.invalid">Uložit</button>
</mat-dialog-actions>
<ng-template #priorityTmpl let-priority="priority">
  <mat-icon *ngIf="priority === 2" color="warn" class="priority" svgIcon="priority-highest"></mat-icon>
  <mat-icon *ngIf="priority === 1" color="warn" class="priority" svgIcon="priority-high"></mat-icon>
  <mat-icon *ngIf="priority === 0" color="primary" class="priority" svgIcon="priority-medium"></mat-icon>
  <mat-icon *ngIf="priority === -1" color="accent" class="priority" svgIcon="priority-low"></mat-icon>
  <mat-icon *ngIf="priority === -2" color="accent" class="priority" svgIcon="priority-lowest"></mat-icon>
</ng-template>
