import { Pipe, PipeTransform } from '@angular/core';
import {Contact} from '@app/models/contact.model';

@Pipe({ name: 'contactName' })
export class ContactNamePipe implements PipeTransform {
  constructor(
  ) {}
  transform(contact?: Contact) {
    return ((contact?.firstName || '') + ' ' + (contact?.lastName || '')).trim();
  }
}
