<mat-list *ngIf="!selectedConversation && !(callingWidgetService.loading$ | async)">
  <mat-list-item *ngFor="let conversation of (sortedItems$ | async);trackBy: conversationsService.trackByFn;" matRipple (click)="selectConversation(conversation._id)">
    <div class="unseen" [ngClass]="{hide: conversation.seen?.includes(usersService.user._id)}"></div>
    <div mat-line class="noelipsis">
      <a class="name" (click)="$event.stopPropagation();" [routerLink]="['/contacts', conversation.contactId]" *ngIf="conversation.name && conversation.contactId">{{conversation.name}}</a>
      <span class="name" *ngIf="conversation.name && !conversation.contactId">{{conversation.name}}</span>
      <span class="number">{{conversation.phoneNumber | phoneNumber}}</span>
      <span class="date">{{(conversation.lastMessage.createdAt | dateDesc) ? (conversation.lastMessage.createdAt | dateDesc) : (conversation.lastMessage.createdAt | date:'HH:mm:ss') }}</span>
    </div>
    <div mat-line>{{conversation.lastMessage.text}}</div>
    <mat-icon>chevron_right</mat-icon>
  </mat-list-item>
  <app-infinite-scroll *ngIf="conversationsService.canLoadMore$ | async" (endReached)="endReached()"></app-infinite-scroll>
</mat-list>

<div class="empty-items" *ngIf="(this.conversationsService.total$ | async) < 1">
  <span>Zatím nebyla vytvořena žádná konverzace.</span>
</div>

<div *ngIf="selectedConversation && !(callingWidgetService.loading$ | async)" class="flex flex-col h-full">
  <div class="header sticky top-0 bg-gray-100 z-10 p-2">
    <button mat-icon-button (click)="selectConversation(null)"><mat-icon>chevron_left</mat-icon></button>
    <div class="info">
      <div class="contact"><a class="name" [routerLink]="['/contacts', selectedConversation.contactId]" *ngIf="selectedConversation.name && selectedConversation.contactId">{{selectedConversation.name}}</a><span class="number">{{selectedConversation.phoneNumber | phoneNumber}}</span></div>
      <div class="deals" *ngIf="selectedConversation._deals?.[0] as _deal">
        <span>
          <a (click)="$event.stopPropagation();" [routerLink]="_deal._pipelineUrl === 'mortgage' ? ['/deals', _deal._id,'offers'] : ['/cases', _deal._pipelineUrl, _deal._id]">{{ _deal | caseNumber }}</a>
          &nbsp;-&nbsp;<b>{{_deal.stage | caseStage:pipelinesService.getPipeline(_deal._pipelineUrl)?.stages}}</b>
          <span *ngIf="_deal.amount">&nbsp;-&nbsp;{{mainService.maskCurrency(_deal.amount)}} Kč</span>
        </span>
      </div>
      <div *ngIf="selectedConversation._provider as _provider" class="flex flex-col">
        <div class="flex flex-row deals">
          <mat-icon class="provider-icon">account_balance</mat-icon>{{ _provider.name }}
        </div>
      </div>
    </div>
    <app-dial-button [widget]="true" [compact]="true" [hideSms]="true" (clickDial)="dialPhone($event)" [phoneNumber]="selectedConversation.phoneNumber"></app-dial-button>
  </div>
  <div class="messages grow" #messagesWrapper>
    <div class="messages-content">
      <app-infinite-scroll [scrollDirectionTop]="true" *ngIf="messagesService.canLoadMore$ | async" (endReached)="endMessagesReached()"></app-infinite-scroll>
      <div class="message" chat-item (loaded)="scrollToBottom(messagesWrapper, isLast)" *ngFor="let message of messages$ | async;let isLast = last;trackBy: messagesService.trackByFn" [ngClass]="{client: message.direction === 'in'}">
        <div class="date"><span *ngIf="message.createdAt | dateDesc">{{message.createdAt | dateDesc}}, </span>{{message.createdAt | date:'HH:mm:ss'}}</div>
        <div class="text">{{message.text}}</div>
      </div>
      <div class="empty-items" *ngIf="!(this.messagesService.isLoading$ | async) && (this.messagesService.total$ | async) < 1">
        <span>Tato konverzace zatím neobsahuje žádné zprávy.</span>
      </div>
    </div>
  </div>
  <div class="send sticky bottom-0 bg-white z-10">
    <div class="text">
      <textarea cdkTextareaAutosize [(ngModel)]="message"></textarea>
    </div>
    <button mat-icon-button color="primary" (click)="sendMessage()" [disabled]="!message || (callingWidgetService.smsDisabled$ | async) || sendingMessage">
      <mat-icon *ngIf="!sendingMessage">send</mat-icon>
      <mat-spinner *ngIf="sendingMessage" [diameter]="16"></mat-spinner>
    </button>
  </div>
</div>
