import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'elapsedTime',
  pure: false
})
export class ElapsedTimePipe implements PipeTransform {
  transform(value: Date, reverse: boolean = false): string {
    if (!value) {
      return '';
    }

    const now = new Date();
    const timeDifference =  reverse === true ? (new Date(value)).getTime() - now.getTime() : now.getTime() - (new Date(value)).getTime();
    const seconds = Math.floor(timeDifference / 1000);

    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    const formatNumber = (num: number): string => (num < 10 ? `0${num}` : `${num}`);

    return `${formatNumber(hours)}:${formatNumber(minutes)}:${formatNumber(remainingSeconds)}`;
  }
}
