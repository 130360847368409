import { Pipe, PipeTransform } from '@angular/core';
import { OptionInterface } from '../interfaces/option.interface';

@Pipe({ name: 'optionLabel' })
export class OptionLabelPipe implements PipeTransform {
  transform(value: string, options: OptionInterface[]) {
    let label = '';
    label = options?.find((s: any) => s.value === value)?.label;
    return label;
  }
}
