import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'participantCarInsurance', pure: false })
export class ParticipantCarInsurancePipe implements PipeTransform {
  constructor() {}
  transform(participant: any) {
    const roles = [];
    participant = participant.getRawValue();
    if (participant.isInsurer) {
      roles.push('Pojistník');
    }
    if (participant.isInsured) {
      roles.push('Provozovatel');
    }
    if (participant.isOperator) {
      roles.push('Vlastník');
    }
    return roles.join(', ');
  }
}
