import { MatDialogRef } from '@angular/material/dialog';
import { Component } from '@angular/core';

@Component({
  selector: 'app-dialog',
  template: `
    <h2 mat-dialog-title>Odložit</h2>
    <mat-dialog-content style="min-width: 500px;">
      <div class="form-row">
        <mat-form-field>
          <mat-select placeholder="Důvod" [(ngModel)]="result.reasons[0]">
            <ng-container *ngFor="let option of (options | optionsFilter: {} : 'label')">
              <mat-option [value]="option.id">{{ option.label }}</mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>
        <mat-form-field *ngIf="result.reasons[0] !== 'watchdog'">
          <input matInput placeholder="Odložit do" [matDatepicker]="postponeDate" [(ngModel)]="result.postponeDate" />
          <mat-datepicker-toggle matSuffix [for]="postponeDate"></mat-datepicker-toggle>
          <mat-datepicker #postponeDate></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="form-row" style="padding-bottom: 1rem;">
          <mat-slide-toggle [(ngModel)]="result.task">Vytvořit úkol</mat-slide-toggle>
      </div>
      <div class="form-row" *ngIf="result.task">
        <mat-form-field>
          <input matInput placeholder="Nadpis úkolu - Kontrola odložena" [(ngModel)]="result.taskTitle" />
        </mat-form-field>
      </div>
      <div class="form-row" *ngIf="result.task">
        <mat-form-field>
          <input matInput placeholder="Popis úkolu" [(ngModel)]="result.taskText" />
        </mat-form-field>
      </div>
      <div class="form-row" *ngIf="result.task">
        <mat-form-field>
          <input matInput placeholder="Datum úkolu" [matDatepicker]="taskDate" [(ngModel)]="result.taskDate" />
          <mat-datepicker-toggle matSuffix [for]="taskDate"></mat-datepicker-toggle>
          <mat-datepicker #taskDate></mat-datepicker>
        </mat-form-field>
        <mat-form-field>
          <mat-select placeholder="Hodin" [(ngModel)]="result.taskHours">
            <ng-container *ngFor="let value of hourOptions">
              <mat-option [value]="value">{{ pad(value, 2) }}</mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>
        <mat-form-field>
          <mat-select placeholder="Minut" [(ngModel)]="result.taskMinutes">
            <ng-container *ngFor="let value of minuteOptions">
              <mat-option [value]="value">{{ pad(value, 2) }}</mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions>
      <button type="button" mat-button color="warn" (click)="dialogRef.close(null)">Zrušit</button>
      <button type="button" mat-raised-button color="primary" [disabled]="!result.reasons?.length || (result.reasons[0] !=='watchdog' && !result.postponeDate) || (result.task && (!result.taskText || !result.taskDate))" (click)="dialogRef.close(result)">Potvrdit</button>
    </mat-dialog-actions>
  `,
})

export class PostponeCaseDialogComponent {

  public options: any[];
  public result: {reasons: any[], postponeDate: Date, task: boolean, taskTitle: string, taskText: string, taskDate: Date, taskHours: number, taskMinutes: number};
  public hourOptions: number[] = Array.from(Array(23).keys());
  public minuteOptions: number[] = [0, 15, 30, 45];

  constructor(
    public dialogRef: MatDialogRef<PostponeCaseDialogComponent>,
  ) {
    this.options = [];
    this.result = {
      reasons: [],
      task: false,
      taskTitle: '',
      taskText: '',
      postponeDate: null,
      taskDate: null,
      taskHours: 12,
      taskMinutes: 0
    };
  }
  public pad(num: number, size: number): string {
    let s = num + '';
    while (s.length < size) {
      s = '0' + s;
    }
    return s;
  }
}
