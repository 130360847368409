import { Pipe, PipeTransform } from '@angular/core';
import {OptionInterface} from '../interfaces/option.interface';

@Pipe({ name: 'optionsLabel' })
export class OptionsLabelPipe implements PipeTransform {
  constructor() {}
  transform(values: string[], options: OptionInterface[]) {
    const newValues = [];
    for (const value of values) {
      newValues.push(options?.find((s: any) => s.value === value)?.label);
    }
    return newValues;
  }
}
