<div class="nav-header">
  <span>H:Platforma</span>
  <button mat-icon-button (click)="toggleSidenav()">
    <mat-icon *ngIf="mainService.sidenavFull">chevron_left</mat-icon>
    <mat-icon *ngIf="!mainService.sidenavFull">chevron_right</mat-icon>
  </button>
</div>
<nav>
  <!--<button mat-button [title]="!mainService.sidenavFull ? 'Nástěnka' : ''" routerLink="/dashboard" routerLinkActive="active"><span>Nástěnka</span><mat-icon>dashboard</mat-icon></button>-->
  <button mat-button [title]="!mainService.sidenavFull ? 'Hypotéky' : ''" routerLink="/deals" routerLinkActive="active">
    <span>{{ mainService.sidenavFull ? 'Hypotéky' : '' }}</span>
    <mat-icon>attach_money</mat-icon>
  </button>
  <button mat-button [title]="!mainService.sidenavFull ? 'Pojištění' : ''" routerLink="cases" routerLinkActive="active">
    <span>{{ mainService.sidenavFull ? 'Pojištění' : '' }}</span>
    <mat-icon>cases</mat-icon>
  </button>
  <button mat-button [title]="!mainService.sidenavFull ? 'Klienti' : ''" routerLink="/contacts" routerLinkActive="active">
    <span>{{ mainService.sidenavFull ? 'Klienti' : '' }}</span>
    <mat-icon>contacts</mat-icon>
  </button>
  <!--button mat-button [title]="!mainService.sidenavFull ? 'Sazby' : ''" routerLink="/products" routerLinkActive="active"><span>Sazby</span><mat-icon>percent</mat-icon></button-->
  <button mat-button [title]="!mainService.sidenavFull ? 'Poskytovatelé' : ''" routerLink="/providers" routerLinkActive="active">
    <span>{{ mainService.sidenavFull ? 'Poskytovatelé' : '' }}</span>
    <mat-icon>account_balance</mat-icon>
  </button>
  <button mat-button [title]="!mainService.sidenavFull ? 'Úkoly' : ''" routerLink="/tasks" routerLinkActive="active">
    <span>{{ mainService.sidenavFull ? 'Úkoly' : '' }}</span>
    <mat-icon
            [matBadgeHidden]="!tasksService.total"
            [matBadge]="tasksService.total < 1000 ? tasksService.total : '>999'"
            matBadgeColor="warn">
      task
    </mat-icon>
    <!-- [matTooltip]="tasksService.totalCounts"> -->
  </button>
  <button mat-button *ngIf="usersService.isPrivileged('campaigns/email:get')" [title]="!mainService.sidenavFull ? 'Emailové kampaně' : ''" routerLink="/campaigns/email" routerLinkActive="active">
    <span>{{ mainService.sidenavFull ? 'Emailové kampaně' : '' }}</span>
    <mat-icon>mail</mat-icon>
  </button>
  <button mat-button *ngIf="usersService.isPrivileged('campaigns/sms:get')" [title]="!mainService.sidenavFull ? 'SMS kampaně' : ''" routerLink="/campaigns/sms" routerLinkActive="active">
    <span>{{ mainService.sidenavFull ? 'SMS kampaně' : '' }}</span>
    <mat-icon>sms</mat-icon></button>
  <button mat-button [title]="!mainService.sidenavFull ? 'Uživatelé' : ''" routerLink="/users" routerLinkActive="active">
    <span>{{ mainService.sidenavFull ? 'Uživatelé' : '' }}</span>
    <mat-icon>group</mat-icon>
  </button>
  <div>
    <span *ngIf="mainService.sidenavFull" class="version">
      Verze: {{ appInfo.version }}:{{ appInfo.env }}
    </span>
    <button mat-button *ngIf="!mainService.sidenavFull" [matTooltip]="'Verze: ' + appInfo.version + ':' + appInfo.env">
      <span></span>
      <mat-icon>
        info
      </mat-icon>
    </button>

    <button mat-button *ngIf="usersService.user" [title]="!mainService.sidenavFull ? usersService.getUserName() : ''" [matMenuTriggerFor]="userMenu">
      <span>{{ usersService.getUserName() }}</span>
      <mat-icon>person</mat-icon>
    </button>
  </div>
</nav>
<mat-menu #userMenu="matMenu">
  <ng-template matMenuContent>
    <button mat-menu-item (click)="usersService.password()" title="Změna hesla"><mat-icon>vpn_key</mat-icon>Změna hesla</button>
    <button mat-menu-item (click)="usersService.logout()" title="Odhlásit se"><mat-icon>exit_to_app</mat-icon>Odhlásit se</button>
  </ng-template>
</mat-menu>
