import {Component, OnDestroy, OnInit} from '@angular/core';
import {DealsService} from '@app/services/deals.service';
import {OffersService} from '@app/services/offers.service';
import {MainService} from '@app/services/main.service';
import {SettingsService} from '@app/services/settings.service';
import {DialogsService} from '@app/services/dialogs.service';
import {RuianService} from '@app/services/ruian.service';
import {OptionInterface} from '@app/interfaces/option.interface';
import {DemandsService} from '@app/services/demands.service';
import {Deal} from '@app/models/deal.model';
import {Offer} from '@app/models/offer.model';
import {ContactsService} from '@app/services/contacts.service';
import {HouseholdsService} from '@app/services/households.service';
import {DialNumberParam} from '@app/models/calls.model';
import { AbstractControl, FormArray, FormGroup } from '@angular/forms';
import {DatePipe} from '@angular/common';
import {CallsService} from '@app/services/calls.service';
import {RealEstateInsurancesService} from '@app/services/real-estate-insurances.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-offer-form',
  templateUrl: './offer-form.component.html',
  styleUrls: ['./offer-form.component.scss']
})

export class OfferFormComponent implements OnInit, OnDestroy {

  public deal: Deal = null;
  public offer: Offer = null;
  private subs$: Subscription[] = [];

  public demandInfo: {
    phoneNumber?: string,
    phoneCountryCode?: string,
    tooltip?: string,
    reverified: boolean
  } = { reverified: false };

  constructor(
    public dealsService: DealsService,
    public offersService: OffersService,
    public demandsService: DemandsService,
    public realEstateInsurancesService: RealEstateInsurancesService,
    public contactsService: ContactsService,
    public householdsService: HouseholdsService,
    public mainService: MainService,
    public settingsService: SettingsService,
    private dialogsService: DialogsService,
    public ruianService: RuianService,
    public callsService: CallsService,
    private datePipe: DatePipe
  ) {
    this.offersService.view = 'offer';
  }

  public canDeactivate() {
    return new Promise( (resolve) => {
      if (this.offersService.offerForm.pristine) {
        resolve(true);
      } else {
        this.dialogsService.deactivate().subscribe((confirm) => {
          if (!confirm) {
            this.mainService.hideLoading();
          }
          resolve(confirm);
        });
      }
    });
  }

  ngOnInit(): void {
    this.subs$.push(this.dealsService.deal$.subscribe((deal: Deal) => {
      this.deal = deal;

      this.demandInfo = { reverified: false };
      this.demandsService.find({
        query: {
          dealId: this.deal._id,
          $select: ['_id', 'createdAt', 'reverificationSentAt', 'verifiedPhoneAt', 'contacts']
        }
      }).then(response => {
        if (response.total > 0) {
          const createdAt = response.data[0].createdAt;
          const verifiedPhoneAt = response.data[0].verifiedPhoneAt;
          const reverificationSentAt = response.data[0].reverificationSentAt;
          if (!!reverificationSentAt && !!verifiedPhoneAt) {
            const ca = this.datePipe.transform(createdAt, 'dd.MM.yyyy HH:mm');
            const va = this.datePipe.transform(verifiedPhoneAt, 'dd.MM.yyyy HH:mm');
            this.demandInfo = {
              phoneNumber: response.data[0].contacts?.phoneNumber,
              phoneCountryCode: response.data[0].contacts?.phoneCountryCode,
              tooltip: `Vytvoření poptávky: ${ca}\nOvěření telefonu: ${va}`,
              reverified: true,
            };
          } else {
            this.demandInfo = { reverified: false };
          }
        }
      });
    }));

    this.subs$.push(this.offersService.offer$.subscribe((offer: Offer) => {
      this.offer = offer;
    }));
  }

  ngOnDestroy(): void {
    this.subs$.forEach((sub) => sub.unsubscribe());
    this.subs$ = [];
  }

  public options(type: string): OptionInterface[] {
    return this.settingsService.options[type];
  }

  public trackByFn(index: any, item: any) {
    return index;
  }

  public trackControlByFn(index: any, item: any) {
    if (item.get) {
      return item.get('_id')?.value;
    }
    return item._id || index;
  }

  public async dialPhone(event: any, phone: AbstractControl) {
    const param: DialNumberParam = {
      countryCode: event.countryCode,
      number: event.phoneNumber,
      contactName: event.contactName,
      contactId: event.contactId,
      origin: 'OfferFormComponent',
      dealId: this.deal._id,
      pipelineId: this.deal._pipelineId,
      dealOwnerId: this.deal.ownerId,
      stage: this.deal.stage
    };
    await this.callsService.initiateCall(param);
  }

  public setupCommPreferences(participant: AbstractControl) {
    this.dialogsService.commPreferences((participant as FormGroup).get('_id').value);
  }

  // HP-2136 -->
  public fixationEndDateChanged(e: any, fg: FormGroup) {
    // console.log('fixationEndDateChanged', e, fg);
    let d: Date = null;
    try {
      d = fg.get('fixationEndDate')?.value.toDate();
      if (this.offersService._applicantsForm?.controls) {
        this.offersService._applicantsForm.controls.forEach((applicant: FormGroup) => {
          if (applicant.get('expenditures')) {
            (applicant.get('expenditures') as FormArray).controls.forEach((expenditure: FormGroup) => {
              if ((!expenditure.get('fixationEndAt')?.value) && (expenditure.get('type')?.value === 'mortgage')) {
                expenditure.get('fixationEndAt').setValue(d);
              }
            });
          }
        });
      }
    } catch (e) {}
  }
  // <-- HP-2136

  // HP-2136 -->
  public fixationEndAtChanged(e: any, fg: FormGroup) {
    // console.log('fixationEndAtChanged', e, fg);
    let d: Date = null;
    try {
      d = fg.get('fixationEndAt')?.value.toDate();
      const fixationEndDate = this.offersService.offerForm.get('fixationEndDate').value;
      if (d && !fixationEndDate) {
        this.offersService.offerForm.get('fixationEndDate').setValue(d);
      }
    } catch (e) {}
  }
  // <-- HP-2136
}


