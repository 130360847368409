import { Injectable } from '@angular/core';
import {BackendService} from './backend.service';
import {BehaviorSubject, Observable} from 'rxjs';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {MainService} from '@app/services/main.service';

@Injectable()
export class RuianService {
  private isSearching = false;
  private search = '';
  private city = '';
  private district = '';
  private socket: any;
  private $results: BehaviorSubject<any[]> = new BehaviorSubject([]);
  public results$: Observable<any[]> = this.$results.asObservable();
  private $cities: BehaviorSubject<any[]> = new BehaviorSubject([]);
  public cities$: Observable<any[]> = this.$cities.asObservable();
  private $districts: BehaviorSubject<any[]> = new BehaviorSubject([]);
  public districts$: Observable<any[]> = this.$districts.asObservable();

  constructor(
    private backendService: BackendService,
    private mainService: MainService
  ) {
    this.socket = this.backendService.getService('ruian');
  }

  public get(id: string, params: any = {}) {
    return this.socket.get(id, params);
  }

  public find(params: any = {}) {
    return this.socket.find(params);
  }

  public async searchAddress(search: string) {
    search = search?.toString().trim();
    this.search = search;
    if (this.search?.length > 2) {
      const results = await this.find({query: {search}});
      if (this.search === search) {
        this.$results.next(results);
      }
    } else {
      this.$results.next([]);
    }
  }

  public async cities(search: string) {
    search = search?.trim();
    this.city = search;
    if (this.city?.length > 1) {
      const results = await this.find({query: {city: search}});
      if (this.city === search) {
        this.$cities.next(results);
      }
    } else {
      this.$cities.next([]);
    }
  }

  public async districts(search: string) {
    search = search?.trim();
    this.district = search;
    if (this.district?.length > 1) {
      const results = await this.find({query: {district: search}});
      if (this.district === search) {
        this.$districts.next(results);
      }
    } else {
      this.$districts.next([]);
    }
  }

  async completeAddress(control, event: MatAutocompleteSelectedEvent) {
    control.get('street').patchValue('');
    this.mainService.showLoading();
    const address = await this.get(event.option.value);
    if (address) {
      control.get('street').patchValue(address.street);
      control.get('city').patchValue(address.city);
      control.get('zipCode').patchValue(address.zipCode);
      control.get('houseNumber').patchValue(address.houseNumber);
      control.get('orientationNumber').patchValue(address.orientationNumber);
      control.get('district').patchValue(address.district);
      control.get('region').patchValue(address.region);
      control.markAsDirty();
    }
    this.mainService.hideLoading();
  }
}
