import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from '@app/modules/core.module';
import { ErrorDialogComponent } from '@app/error-handler/error-dialog.component';

@NgModule({
  declarations: [ErrorDialogComponent],
  imports: [CommonModule, CoreModule],
  exports: [ErrorDialogComponent],
})
export class ErrorDialogModule {}
