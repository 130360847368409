import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {Call, DialNumberParam} from '@app/models/calls.model';
import {map} from 'rxjs/operators';
import {CallsService} from '@app/services/calls.service';
import {PipelinesService} from '@app/services/pipelines.service';
import {MainService} from '@app/services/main.service';
import {CallingWidgetService} from '@app/components/callingwidget/callingwidget.service';

@Component({
  selector: 'app-calling-widget-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss']
})
export class HistoryComponent implements OnInit {
  $groupedCalls: BehaviorSubject<{ [key: string]: Call[] }> = new BehaviorSubject<{ [key: string]: Call[] }>({});
  groupedCalls$: Observable<{ [key: string]: Call[] }> = this.$groupedCalls.asObservable();
  groups$: Observable<string[]>;
  callsDisabled = false;

  constructor(
    public callsService: CallsService,
    public pipelinesService: PipelinesService,
    public mainService: MainService,
    public callingWidgetService: CallingWidgetService,
  ) {
    this.callingWidgetService.callsDisabled$.subscribe(disabled => this.callsDisabled = disabled);
  }

  ngOnInit(): void {
    this.callsService.items$.subscribe(calls => {
      const groups = new Set(calls.filter((c => c.duration !== undefined)).map(call => new Date(call.callTime).toDateString()));
      const groupedCalls = {};
      groups.forEach(dateString => {
        groupedCalls[dateString] = calls.filter(call =>
          call.duration !== undefined && new Date(call.callTime).toDateString() === dateString
        ).sort((a, b) => new Date(b.callTime).getTime() - new Date(a.callTime).getTime());
      });
      this.$groupedCalls.next(groupedCalls);
    });
    this.groups$ = this.groupedCalls$.pipe(
      map(groups => Object.keys(groups).sort((a: string, b: string) => new Date(b).getTime() - new Date(a).getTime()))
    );
    this.callsService.load(0);
  }

  public async dialPhone(call: Call) {
    const param: DialNumberParam = {
      phoneNumber: call.phoneNumber,
      contactId: call.contactId,
      origin: 'WidgetHistoryComponent'
    };
    if (!this.callsDisabled) {
      await this.callsService.initiateCall(param);
    }
  }

  endReached() {
    if (!this.callsService.$isLoading.getValue()) {
      this.callsService.load();
    }
  }
}
