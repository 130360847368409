import { MatDialogRef } from '@angular/material/dialog';
import {AfterViewInit, Component} from '@angular/core';

@Component({
  selector: 'app-dialog',
  template: `
    <h2 mat-dialog-title>Změna hesla</h2>
    <mat-dialog-content>
      <p>Heslo musí být bez mezer, musí obsahovat velké i malé písmeno, musí obsahovat číslo a musí mít délku alespoň 10 znaků.</p>
      <p>Kdo to nedá na maximánlně tři pokusy, bude exemplárně vystaven ve firemním panoptiku.</p>
      <div class="form-row">
        <mat-form-field>
          <input matInput [type]="show ? 'text' : 'password'" autocomplete="new-password" [(ngModel)]="password" />
          <mat-icon style="cursor: pointer" matSuffix (click)="show = !show">visibility</mat-icon>
          <mat-hint align="end">{{ password.length }}</mat-hint>
        </mat-form-field>
      </div>
      <p *ngIf="tries === 1">1. pokus se nepovedl. Nevadí! To dáme.</p>
      <p *ngIf="tries === 2">2. nepovedený pokus už trochu bolí...</p>
      <p *ngIf="tries > 2">{{ tries }}krát špatně?! Najdi nějákého Lukáše a požádej ho o pomoc.</p>
      <p *ngIf="tries > 10">10 pokusů... Hmm, za vytrvalost máš v našem panoptiku vyhrazeno VIP místo!</p>
      <p *ngIf="tries > 99">100! Zkusit si to musel. Dál už fakt nic není!</p>
      <p *ngIf="tries > 999">1000... Lhal jsem!</p>
    </mat-dialog-content>
    <mat-dialog-actions>
      <button type="button" mat-button color="warn" (click)="dialogRef.close(null)">Zrušit</button>
      <button type="button" mat-raised-button color="primary" (click)="confirmPassword()">Potvrdit</button>
    </mat-dialog-actions>
  `,
})

export class PasswordDialogComponent {

  public password = '';
  public tries = 0;
  public show = false;

  constructor(
    public dialogRef: MatDialogRef<PasswordDialogComponent>,
  ) {
  }

  confirmPassword() {
    if (this.password && this.password.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z])(?!.*\s).{10,}$/gm)) {
      this.dialogRef.close(this.password);
    } else {
      this.tries++;
    }
  }

}
