import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {NavigationEnd, NavigationStart, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {BackendService} from '../services/backend.service';
import {UsersService} from '../services/users.service';
import {MainService} from '../services/main.service';
import {SettingsService} from '../services/settings.service';
import {SwUpdate} from '@angular/service-worker';
import {DialogsService} from '../services/dialogs.service';

@Component({
  selector: 'app-main',
  templateUrl: 'main.component.html',
  styleUrls: ['main.component.scss']
})

export class MainComponent implements AfterViewInit, OnInit, OnDestroy {
  private _lastScrollY = 0;
  private $router: Subscription;
  public day: number = (new Date()).getDate();
  private isWaitingForVersionUpdate = false;

  @ViewChild('sidenav', { static: true }) sidenav: ElementRef;

  constructor(
    private router: Router,
    public backendService: BackendService,
    public usersService: UsersService,
    public mainService: MainService,
    public settingsService: SettingsService,
    private swUpdate: SwUpdate,
    private dialogsService: DialogsService,
  ) {
    this.$router = this.router.events.subscribe(event => {
      // if (event instanceof NavigationStart) {
      //   this.mainService.showLoading();
      // }
      // if (event instanceof NavigationEnd) {
      //   this.mainService.hideLoading();
      // }
    });
    console.log('SW update is ' + (swUpdate.isEnabled ? 'enabled' : 'disabled'));
    if (swUpdate.isEnabled) {
      swUpdate.unrecoverable.subscribe(async event => {
        await this.dialogsService.alert('Chyba synchronizace', 'Nastala chyba synchronizace verzí a aplikace bude nyní znovu načtena.');
        window.location.reload();
      });
      swUpdate.versionUpdates.subscribe(async event => {
        if (!this.isWaitingForVersionUpdate) {
          this.isWaitingForVersionUpdate = true;
          //const isUpdated = await this.swUpdate.activateUpdate();
          this.dialogsService.confirm('Nová verze', 'Byla vydána nová verze aplikace. Chcete spustit aktualizaci nyní? Můžete tak přijít o neuložená data.').subscribe((confirmed) => {
            if (confirmed) {
              window.location.reload();
            }
          });
        }
      });
    }
  }

  public ngOnInit() {
    this.mainService.init();
  }

  public ngAfterViewInit() {
    this.mainService.afterViewInit();
  }

  public ngOnDestroy(): void {
    this.$router.unsubscribe();
  }

  public onScroll() {
    const delta = window.scrollY - this._lastScrollY;
    this._lastScrollY = window.scrollY;
    this.sidenav.nativeElement.scrollBy(0, delta);
  }
}
