<div class="search-bar">
  <input placeholder="Hledání..." autocomplete="off" [(ngModel)]="filters.search" (ngModelChange)="onSearchChanged($event)">
  <button mat-icon-button
          matTooltip="Hledat v ověřených kontaktech na klienty"
          [color]="filters.scope === 'contacts' ? 'primary' : null"
          (click)="onScopeChanged('contacts')">
    <mat-icon>contacts</mat-icon>
  </button>
  <button mat-icon-button
          matTooltip="Hledat kontaktech na banky, pojišťovny"
          [color]="filters.scope === 'providers' ? 'primary' : null"
          (click)="onScopeChanged('providers')">
    <mat-icon>account_balance</mat-icon>
  </button>
  <button mat-icon-button
          matTooltip="Hledat v Hyponamíru"
          [color]="filters.scope === 'employees' ? 'primary' : null"
          (click)="onScopeChanged('employees')">
    <mat-icon>group</mat-icon>
  </button>
  <button mat-icon-button matTooltip="Smazat podmínky hledání" (click)="clearSearch()">
    <mat-icon>close</mat-icon>
  </button>
  <ng-container *ngIf="parsedPhoneNumber && !(contactsService.$isLoading | async)">
    <span class="foundPhone" [ngClass]="{warning: parsedPhoneNumber.warning}">{{(parsedPhoneNumber.countryCode + parsedPhoneNumber.phoneNumber) | phoneNumber}}</span>
    <app-dial-button [widget]="true" [compact]="true" (clickDial)="dialPhone($event)" [countryCode]="parsedPhoneNumber.countryCode" [phoneNumber]="parsedPhoneNumber.phoneNumber"></app-dial-button>
  </ng-container>
</div>
<mat-list cdkVirtualScrollingElement>
  <cdk-virtual-scroll-viewport #viewport itemSize="49" class="virtual-scroll-container" minBufferPx="300" maxBufferPx="400">
    <mat-list-item *cdkVirtualFor="let contact of contactsService.items$">
      <div mat-line>{{ contact.firstName }} {{ contact.lastName }}</div>
      <div mat-line>
        {{(contact.phone[0].countryCode + contact.phone[0].number) | phoneNumber}}
      </div>
      <div mat-line *ngIf="contact._provider">
        <mat-icon class="provider-icon">account_balance</mat-icon>{{ contact._provider.name }}
      </div>
      <app-dial-button [widget]="true" [compact]="true" [phoneNumber]="contact.phone[0].number" [countryCode]="contact.phone[0].countryCode" [contactId]="contact._id" (clickDial)="dialPhone($event)"></app-dial-button>
    </mat-list-item>
    <mat-list-item *ngIf="!(contactsService.items$ | async).length && !(contactsService.isLoading$ | async)">
      Pro zadaný výraz nebylo nic nalezeno.
    </mat-list-item>
    <div class="contacts-loader" *ngIf="!(contactsService.items$ | async).length && (contactsService.isLoading$ | async)">
      <mat-spinner></mat-spinner>
    </div>
    <app-infinite-scroll *ngIf="(contactsService.items$ | async).length" (endReached)="endReached()"></app-infinite-scroll>
  </cdk-virtual-scroll-viewport>
</mat-list>
