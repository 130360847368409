import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Pipe({ name: 'affiliateLabel' })
export class AffiliateLabelPipe implements PipeTransform {
  constructor() {}
  transform(value: Observable<any[]>, id: string) {
    return value.pipe(map(items => items.find(item => item._id === id)?.name));
  }
}
