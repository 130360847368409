import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from '@app/modules/core.module';
import { TasksListItemComponent } from './tasks-list-item.component';
import { DialButtonModule } from '@app/components/dialbutton/dial-button.module';
import { RichEditorModule } from '@app/components/rich-editor/rich-editor.module';
import { EditorModule } from 'primeng/editor';
import { PriorityButtonModule } from '@app/components/priority-button/priority-button.module';
import { UserGroupPipe } from '@app/pipes/user-group.pipe';
import { DialogModule } from 'primeng/dialog';
import { InplaceModule } from 'primeng/inplace';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { PipesModule } from '@app/modules/pipes.module';

@NgModule({
  declarations: [TasksListItemComponent, UserGroupPipe],
  imports: [
    CommonModule,
    CoreModule,
    DialButtonModule,
    RichEditorModule,
    EditorModule,
    PriorityButtonModule,
    DialogModule,
    InplaceModule,
    InputTextareaModule,
    PipesModule,
  ],
  exports: [TasksListItemComponent, UserGroupPipe],
})
export class TasksListItemModule {}
