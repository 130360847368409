import { Pipe, PipeTransform } from '@angular/core';
import {AbstractControl, FormArray} from '@angular/forms';

@Pipe({ name: 'formArrayContains', pure: false })
export class FormArrayContainsPipe implements PipeTransform {
  constructor() {}
  transform(form: AbstractControl, param: string, value: string) {
    return (form as FormArray).controls.filter((control: AbstractControl) => control.get(param)?.value === value).length > 0;
  }
}
