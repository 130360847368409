import { MatDialogRef } from '@angular/material/dialog';
import {AfterViewInit, Component, OnInit} from '@angular/core';
import {UsersService} from '../../services/users.service';
import {UserInterface} from '../../interfaces/user.interface';
import {AffiliatesService} from '@app/services/affiliates.service';
import {BehaviorSubject, Observable} from 'rxjs';
import {Contact} from '@app/models/contact.model';
import {Pipeline} from '@app/models/pipeline.model';
import {ContactsService} from '@app/services/contacts.service';
import {map} from 'rxjs/operators';
import {DatePipe} from '@angular/common';
import {SettingsService} from '@app/services/settings.service';

@Component({
  selector: 'app-dialog',
  template: `
    <h2 mat-dialog-title><span>Nový případ</span></h2>
    <mat-dialog-content>
      <form #newDealForm="ngForm">
        <ng-container *ngIf="!copyContactsId[0]">
          <div class="form-row">
            <mat-form-field>
              <mat-select placeholder="Předvolba" [(ngModel)]="demand.contacts.phoneCountryCode" name="contactPhoneCountryCode" (ngModelChange)="filterContacts(newDealForm.control)">
                <mat-option *ngFor="let option of options['phoneCountryCode']" [value]="option.value">{{ option.label }}</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field>
              <input matInput type="number" placeholder="Telefon" [(ngModel)]="demand.contacts.phoneNumber" name="contactPhoneNumber" required (ngModelChange)="filterContacts(newDealForm.control)"/>
            </mat-form-field>
          </div>
          <div class="form-row">
            <mat-form-field>
              <input matInput placeholder="Email" [(ngModel)]="demand.contacts.email" name="contactEmail" email (ngModelChange)="filterContacts(newDealForm.control)"/>
            </mat-form-field>
          </div>
          <div class="form-row" *ngIf="(filteredContacts | async).length && !contactSelectionDone">
            <mat-form-field>
              <mat-select placeholder="Existující kontakty" [(ngModel)]="selectedContact" name="existingContacts">
                <ng-container *ngFor="let contact of filteredContacts | async">
                  <mat-option [value]="contact._id">
                    <div class="flex flex-col">
                      <span style="line-height: 1.5em;">{{contact.label}}&nbsp;</span>
                      <small style="line-height: 1.5em;">{{contact.sublabel}}</small>
                    </div>
                  </mat-option>
                </ng-container>
              </mat-select>
            </mat-form-field>
          </div>
        </ng-container>
        <ng-container *ngIf="copyContactsId[0] || contactSelectionDone">
          <div class="form-row" *ngIf="!copyContactsId[0]">
            <mat-form-field>
              <input matInput placeholder="Jméno" [(ngModel)]="demand.contacts.name" name="contactName" required/>
            </mat-form-field>
            <mat-form-field>
              <app-input-mask placeholder="PSČ" [(ngModel)]="demand.contacts.zipCode" name="contactZipCode" mask="999 99" inputmode="numeric"></app-input-mask>
            </mat-form-field>
          </div>
          <div class="form-row">
            <mat-form-field>
              <mat-select placeholder="Druh případu" [(ngModel)]="demand.pipelineId" name="pipelineId" required>
                <mat-option [value]="pipeline._id" *ngFor="let pipeline of pipelines | pipelineProduct : 'insurance'">{{ pipeline.name }}</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field>
              <mat-select placeholder="Priorita" [(ngModel)]="demand.priority" name="priority">
                <mat-select-trigger style="display: flex; align-items: center;">
                  <ng-container [ngTemplateOutlet]="priorityTmpl" [ngTemplateOutletContext]="{priority: demand.priority}"></ng-container>
                  <span>{{ priorityText(demand.priority) }}</span>
                </mat-select-trigger>
                <ng-container *ngFor="let option of priorities">
                  <mat-option [value]="option.value"><ng-container [ngTemplateOutlet]="priorityTmpl" [ngTemplateOutletContext]="{priority: option.value}"></ng-container>{{ option.label }}</mat-option>
                </ng-container>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="form-row">
            <mat-form-field>
              <mat-select placeholder="Vlastník případu" [(ngModel)]="demand.ownerId" name="ownerId">
                <mat-option [value]="''">Přiřadit automaticky</mat-option>
                <mat-option [value]="owner._id" *ngFor="let owner of pipelineOwners">{{ owner.firstName }} {{ owner.lastName }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <ng-container *ngIf="!deal?._id">
            <div class="form-row">
              <mat-form-field>
                <mat-select placeholder="Zdroj obchodu" [(ngModel)]="demand.sourceType" name="sourceType" (selectionChange)="sourceTypeChanged($event)">
                  <mat-option [value]="''">Firemní obchod</mat-option>
                  <mat-option [value]="'affiliate'">Affiliate partner</mat-option>
                  <mat-option [value]="'personal'">Soukromá osoba</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="form-row" *ngIf="demand.sourceType === 'affiliate'">
              <mat-form-field>
                <mat-select placeholder="Partner" [(ngModel)]="demand.affiliateId" name="affiliateId">
                  <mat-option [value]="''">Není vybrán</mat-option>
                  <mat-option [value]="affiliate._id" *ngFor="let affiliate of affiliatesService.items$ | async">{{ affiliate.name }}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="form-row" *ngIf="demand.sourceType === 'personal'">
              <mat-form-field>
                <input matInput placeholder="Soukromá osoba (email)" [(ngModel)]="demand.authorEmail" name="authorEmail" [matAutocomplete]="autoUser" (ngModelChange)="filterUsers($event)" />
                <mat-autocomplete #autoUser="matAutocomplete" panelWidth="auto">
                  <mat-option *ngFor="let user of filteredUsers | async" [value]="user.email" (onSelectionChange)="demand.authorId = user._id">
                    {{ user.email }}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
              <mat-checkbox [(ngModel)]="demand.isOwnDeal" name="isOwnDeal" class="flex-0-0-auto">Vlastní případ</mat-checkbox>
            </div>
          </ng-container>
          <div class="form-row">
            <mat-form-field>
              <textarea matInput placeholder="Poznámka" cdkTextareaAutosize cdkAutosizeMinRows="1" [(ngModel)]="demand.note" name="note"></textarea>
            </mat-form-field>
          </div>
        </ng-container>
      </form>
    </mat-dialog-content>
    <mat-dialog-actions>
      <button type="button" mat-button color="warn" (click)="dialogRef.close(null)">Zrušit</button>
      <ng-container *ngIf="!copyContactsId[0] && !contactSelectionDone">
        <button type="button" mat-raised-button color="accent" (click)="selectContact(true)" [disabled]="!selectedContact">Použít kontakt</button>
        <button type="button" mat-raised-button color="accent" (click)="selectContact(false)" [disabled]="newDealForm.control.get('contactPhoneNumber')?.invalid">Nový kontakt</button>
      </ng-container>
      <ng-container *ngIf="copyContactsId[0] || contactSelectionDone">
        <button type="button" mat-raised-button color="primary" (click)="backToContactSelection()">Zpět</button>
        <button type="button" mat-raised-button color="accent" (click)="confirmDialog()" [disabled]="newDealForm.invalid">Vytvořit</button>
      </ng-container>
    </mat-dialog-actions>
    <ng-template #priorityTmpl let-priority="priority">
      <mat-icon *ngIf="priority === 2" color="warn" class="priority" svgIcon="priority-highest"></mat-icon>
      <mat-icon *ngIf="priority === 1" color="warn" class="priority" svgIcon="priority-high"></mat-icon>
      <mat-icon *ngIf="priority === 0" color="primary" class="priority" svgIcon="priority-medium"></mat-icon>
      <mat-icon *ngIf="priority === -1" color="accent" class="priority" svgIcon="priority-low"></mat-icon>
      <mat-icon *ngIf="priority === -2" color="accent" class="priority" svgIcon="priority-lowest"></mat-icon>
    </ng-template>
  `,
})
export class NewDemandDialogComponent implements OnInit {

  public user: UserInterface;
  public priorities: any[];
  public deal: any;
  public copyContactsId: string[] = [];
  public ownerId: string;
  public pipelineId: string;
  public owners: any[];
  public pipelineOwners: any[];
  public pipelines: Pipeline[];
  public affiliates: any[];
  public options: any;
  public demand: any;
  public filteredUsers: BehaviorSubject<any> = new BehaviorSubject([]);
  public filteredContacts: Observable<any> = new Observable<any>();
  public contactSelectionDone = false;
  public selectedContact = null;

  constructor(
    public dialogRef: MatDialogRef<NewDemandDialogComponent>,
    public affiliatesService: AffiliatesService,
    private usersService: UsersService,
    public contactsService: ContactsService,
    public settingService: SettingsService,
    private datePipe: DatePipe
  ) {
    this.options = this.settingService.options;
    this.filteredContacts = this.contactsService.items$.pipe(
      map((contacts: any[]) => contacts.map((contact: any) => {
        contact.phoneVerified = contact.phone.some((p: any) => p.isVerified);
        return contact;
      }).sort((a: any, b: any) => {
        if (a.emailVerified && a.phoneVerified && !b.emailVerified && !b.phoneVerified) {
          return -1;
        }
        if (!a.emailVerified && !a.phoneVerified && b.emailVerified && b.phoneVerified) {
          return 1;
        }

        // Secondary sorting: At least one of them is true
        if ((a.emailVerified || a.phoneVerified) && !(b.emailVerified || b.phoneVerified)) {
          return -1;
        }
        if (!(a.emailVerified || a.phoneVerified) && (b.emailVerified || b.phoneVerified)) {
          return 1;
        }
      }).map((contact: any) => {
        let fields = [];
        let subfields = [];
        fields.push(((contact.firstName || '') + ' ' + (contact.lastName || '')).trim());
        fields.push(contact.birthDate ? (new Date (contact.birthDate)).getFullYear() : '');
        fields.push(contact.email || '');
        subfields.push(contact.address?.permanent?.city || '');
        subfields.push(`(${this.datePipe.transform(contact.createdAt, 'shortDate')})`);
        fields = fields.filter((field: string) => field);
        subfields = subfields.filter((field: string) => field);
        return {
          _id: contact._id,
          label: fields.join(', ') || `K${contact.numberId}`,
          sublabel: subfields.join(', '),
          emailVerified: contact.emailVerified,
          phoneVerified: contact.phoneVerified
        };
      }).filter((contact: any) => contact.label || contact.sublabel))
    );
    this.filteredContacts.subscribe((contacts: any[]) => {
      if (contacts.length) {
        this.selectedContact = contacts[0]?._id;
      } else {
        this.selectedContact = null;
      }
    });
  }

  ngOnInit() {
    this.selectedContact = null;
    this.contactSelectionDone = false;
    this.contactsService.$items.next([]);
    this.filterPipelineOwners(this.pipelines?.[0]?.url);
    if (this.deal) {
      this.ownerId = this.deal.ownerId;
      this.copyContactsId = this.pipelineId !== 'mortgage' ? this.deal.participants?.map(p => p?.contactId) : this.deal._offers[0].applicants;
    } else {
      if (this.owners.find(o => o._id === this.user._id)) {
        this.ownerId = this.user._id;
      }
    }
    if (this.ownerId && !this.pipelineOwners.find(o => o._id === this.ownerId)) {
      this.ownerId = '';
    }
    this.demand = {
      source: 'portal',
      pipelineId: null,
      affiliateId: '',
      authorId: null,
      priority: 0,
      ownerId: this.ownerId || '',
      solveOnline: true,
      note: '',
      contacts: {
        name: null,
        zipCode: null,
        phoneCountryCode: '+420',
        phoneNumber: null,
        email: null,
      },
      sourceType: ''
    };
    if (this.deal) {
      this.demand.sourceDeal = {
        pipelineId: this.deal?._pipelineId,
        dealId: this.deal?._id,
        contactsId: this.copyContactsId,
        stage: this.deal?.stage
      };
    }
  }
  async filterUsers(search: string) {
    if (search.length > 3) {
      const users = await this.usersService.find({
        query: {
          $limit: 20,
          email: {$regex: search, $options: 'i'}
        }
      });
      this.filteredUsers.next(users);
    } else {
      this.filteredUsers.next([]);
    }
  }

  sourceTypeChanged(event: any) {
    if (event.value !== 'affiliate') {
      this.demand.affiliateId = '';
    }
    if (event.value !== 'personal') {
      this.demand.isOwnDeal = false;
      this.demand.authorEmail = '';
      this.demand.authorId = '';
    }
  }

  priorityText(priority) {
    return this.priorities.find(o => o.value === priority)?.label;
  }

  filterPipelineOwners(pipelineUrl) {
    this.pipelineOwners = this.owners.filter(o => o.cases?.[pipelineUrl]);
  }

  public filterContacts(control: any) {
    if (!this.contactSelectionDone) {
      const countryCode = control.get('contactPhoneCountryCode')?.value;
      const phoneNumber = control.get('contactPhoneNumber')?.value;
      const email = control.get('contactEmail')?.value;
      if (phoneNumber) {
        this.contactsService.filter = {
          phone: {
            $elemMatch: {
              countryCode,
              number: phoneNumber
            }
          },
        };
        if (email) {
          this.contactsService.filter.email = email;
        }
        this.contactsService.load(0);
      }
    }
  }

  public selectContact(fill = false) {
    if (fill) {
      this.copyContactsId.push(this.selectedContact);
      this.demand.sourceDeal = {
        contactsId: this.selectedContact ? [this.selectedContact] : [],
      };
    }
    this.contactSelectionDone = true;
  }

  backToContactSelection() {
    this.copyContactsId = [];
    this.demand.sourceDeal = {
      contactsId: [],
    };
    this.contactSelectionDone = false;
  }

  confirmDialog() {
    if (!this.demand.authorId) {
      this.demand.authorId = this.user._id;
    }
    if (!this.demand.affiliateId) {
      this.demand.affiliateId = null;
    }
    if (!this.demand.ownerId) {
      this.demand.ownerId = null;
    }
    this.dialogRef.close(this.demand);
  }
}
