import { MatDialogRef } from '@angular/material/dialog';
import { Component } from '@angular/core';

@Component({
    selector: 'app-dialog',
    template: `
      <h2 mat-dialog-title>Změnit čerpatele</h2>
      <mat-dialog-content>
        <mat-form-field>
          <mat-select placeholder="Vyberte osobu" [(ngModel)]="selectedUser">
            <ng-container *ngFor="let user of users">
              <mat-option *ngIf="drawerId !== user._id" [value]="user._id">{{ user.firstName }} {{ user.lastName }}</mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>
      </mat-dialog-content>
      <mat-dialog-actions>
        <button type="button" mat-button color="warn" (click)="dialogRef.close(null)">Zrušit</button>
        <button type="button" mat-raised-button color="primary" (click)="dialogRef.close(selectedUser)">Potvrdit</button>
      </mat-dialog-actions>
    `,
})

export class DrawerDialogComponent {

    public drawerId: string;
    public users: any[];
    public selectedUser: string = null;

    constructor(
      public dialogRef: MatDialogRef<DrawerDialogComponent>,
    ) {
        this.users = [];
    }
}
