import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'secondsToMinutes'
})
export class SecondsToMinutesPipe implements PipeTransform {
  transform(seconds: number): string {
    const minutes = Math.floor(seconds / 60);

    if (minutes < 2) {
      return `${seconds} ${this.getSecondString(seconds)}`;
    } else {
      return `${minutes} ${this.getMinuteString(minutes)}`;
    }
  }

  private getSecondString(seconds: number): string {
    if (seconds === 1) {
      return 'sekunda';
    } else if (seconds >= 2 && seconds <= 4) {
      return 'sekundy';
    } else {
      return 'sekund';
    }
  }

  private getMinuteString(minutes: number): string {
    if (minutes === 1) {
      return 'minuta';
    } else if (minutes >= 2 && minutes <= 4) {
      return 'minuty';
    } else {
      return 'minut';
    }
  }
}
