import { Pipe, PipeTransform } from '@angular/core';
import {DocumentTemplateInterface} from '../interfaces/document-template.interface';

@Pipe({ name: 'maturity' })
export class MaturityPipe implements PipeTransform {
  constructor() {}
  transform(maturity: number): string {
    const mYears = Math.floor((maturity || 0) / 12);
    const mMonths = (maturity || 0) % 12;
    return mMonths ? `${mYears}r, ${mMonths}m` : `${mYears}r`;
  }
}
