<p-sidebar [(visible)]="visible"
           styleClass="sp-sidebar-md"
           [showCloseIcon]="false"
           [closeOnEscape]="true"
           [dismissible]="false"
           [modal]="true"
           position="right"
           [baseZIndex]="10000">

  <div class="flex flex-row justify-between items-center pb-4">
    <div class="page-title font-semibold">{{ title }}</div>
    <button mat-icon-button color="warn" [disabled]="uploading" (click)="closePanel()">
      <mat-icon title="Zavřít">close</mat-icon>
    </button>
  </div>

  <div *ngIf="uploading" class="flex flex-row gap-2 items-center justify-between pb-2">
    <div class="h-full w-full">
      <p-progressBar [value]="progress" styleClass="rounded h-6"></p-progressBar>
    </div>
    <div class="w-12 text-right font-semibold">{{ progress }} %</div>
  </div>

  <div class="flex h-full">
    <p-fileUpload #fileUpload
                  name="file[]"
                  url="./upload.php"
                  multiple="multiple"
                  maxFileSize="25000000"
                  chooseLabel="Vybrat soubory..."
                  chooseIcon=""
                  uploadLabel="Nahrát"
                  uploadIcon=""
                  invalidFileSizeMessageSummary="Soubor '{0}' je příliš velký"
                  invalidFileSizeMessageDetail="Maximální velikost je {0}."
                  [showCancelButton]="false"
                  [customUpload]="true"
                  (uploadHandler)="upload($event)">
      <ng-template let-file let-i="index" pTemplate="file">
        <div class="flex flex-row justify-between items-center">
          <div class="w-12">
            <mat-icon>{{ file.name | fileIcon }}</mat-icon>
          </div>
          <div class="grow">
            {{file.name}}
          </div>
          <div class="w-24 text-right">
            {{file.size | fileSize}}</div>
          <div>
            <button mat-icon-button color="warn" (click)="fileUpload.remove(null, i)">
              <mat-icon>remove_circle_outline</mat-icon>
            </button>
          </div>
        </div>
      </ng-template>
    </p-fileUpload>
  </div>

</p-sidebar>
