import {MatDialogRef, MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {Injectable} from '@angular/core';
import {FormArray, FormGroup} from '@angular/forms';
import {Observable} from 'rxjs';
import {AutoLogoutDialogComponent} from '@app/components/dialogs/autologout-dialog.component';
import {ModalInfoDialogComponent} from '@app/components/dialogs/modalinfo-dialog.component';
import {RefreshDialogComponent} from '@app/components/dialogs/refresh-dialog.component';
import {AlertDialogComponent} from '@app/components/dialogs/alert-dialog.component';
import {ConfirmDialogComponent} from '@app/components/dialogs/confirm-dialog.component';
import {TemplateDialogComponent} from '@app/components/dialogs/template-dialog.component';
import {PriorityDialogComponent} from '@app/components/dialogs/priority-dialog.component';
import {OwnerDialogComponent} from '@app/components/dialogs/owner-dialog.component';
import {DrawerDialogComponent} from '@app/components/dialogs/drawer-dialog.component';
import {CancelDialogComponent} from '@app/components/dialogs/cancel-dialog.component';
import {PostponeDialogComponent} from '@app/components/dialogs/postpone-dialog.component';
import {ScoreDialogComponent} from '@app/components/dialogs/score-dialog.component';
import {UipathDialogComponent} from '@app/components/dialogs/uipath-dialog.component';
import {StageDialogComponent} from '@app/components/dialogs/stage-dialog.component';
import {RestoreDialogComponent} from '@app/components/dialogs/restore-dialog.component';
import {PasswordDialogComponent} from '@app/components/dialogs/password-dialog.component';
import {DeactivateDialogComponent} from '@app/components/dialogs/deactivate-dialog.component';
import {DealDialogComponent} from '@app/components/dialogs/deal-dialog.component';
import {UserInterface} from '@app/interfaces/user.interface';
import {Task} from '@app/models/task.model';
import {TaskDialogComponent} from '@app/components/dialogs/task-dialog.component';
import {FeesDialogComponent} from '@app/components/dialogs/fees-dialog.component';
import {WatchdogDialogComponent} from '@app/components/dialogs/watchdog-dialog.component';
import {Deal} from '@app/models/deal.model';
import {RestoreCaseDialogComponent} from '@app/components/dialogs/restore-case-dialog.component';
import {CancelCaseDialogComponent} from '@app/components/dialogs/cancel-case-dialog.component';
import {PostponeCaseDialogComponent} from '@app/components/dialogs/postpone-case-dialog.component';
import {NewDemandDialogComponent} from '@app/components/dialogs/new-demand-dialog.component';
import {Pipeline} from '@app/models/pipeline.model';
import {FindUserDialogComponent} from '@app/components/dialogs/find-user-dialog.component';
import {DocumentFile} from '@app/interfaces/document-file.interface';
import {FileOperationsDialogComponent } from '@app/components/dialogs/file-operations-dialog.component';
import {FileChooseGroupDialogComponent} from '@app/components/dialogs/file-choose-group-dialog.component';
import {CommPreferencesDialogComponent} from '@app/components/dialogs/comm-preferences-dialog.component';
import {EmailDuplicityDialogComponent} from '@app/components/dialogs/email-duplicity-dialog.component';
import {VideoCallDialogComponent} from '@app/components/dialogs/video-call-dialog.component';
import {InterventionDialogComponent} from '@app/components/dialogs/intervention-dialog.component';
import {Intervention} from '@app/models/intervention.model';
import { ChooseOwnerDialogComponent } from '@app/components/dialogs/choose-owner-dialog.component';

@Injectable()
export class DialogsService {
  public dialogConfig: MatDialogConfig;
  constructor(private dialog: MatDialog) { }

  public autoLogout(): any {
    let dialogRef: MatDialogRef<AutoLogoutDialogComponent>;
    this.dialogConfig = new MatDialogConfig();
    this.dialogConfig.disableClose = true;
    dialogRef = this.dialog.open(AutoLogoutDialogComponent, this.dialogConfig);
    return dialogRef;
  }

  public modalInfo(title: string, message: string): any {
    let dialogRef: MatDialogRef<ModalInfoDialogComponent>;
    this.dialogConfig = new MatDialogConfig();
    this.dialogConfig.disableClose = true;
    dialogRef = this.dialog.open(ModalInfoDialogComponent, this.dialogConfig);
    dialogRef.componentInstance.title = title;
    dialogRef.componentInstance.message = message;
    return dialogRef;
  }

  public refresh(view: string): any {
    let dialogRef: MatDialogRef<RefreshDialogComponent>;
    this.dialogConfig = new MatDialogConfig();
    this.dialogConfig.disableClose = true;
    dialogRef = this.dialog.open(RefreshDialogComponent, this.dialogConfig);
    dialogRef.componentInstance.view = view;
    return dialogRef;
  }

  public alert(title: string, message: string): Observable<boolean> {
    let dialogRef: MatDialogRef<AlertDialogComponent>;
    dialogRef = this.dialog.open(AlertDialogComponent);
    dialogRef.componentInstance.title = title;
    dialogRef.componentInstance.message = message;
    return dialogRef.afterClosed();
  }

  public watchdog(deal: Deal): Observable<any> {
    let dialogRef: MatDialogRef<WatchdogDialogComponent>;
    dialogRef = this.dialog.open(WatchdogDialogComponent);
    dialogRef.componentInstance.deal = deal;
    return dialogRef.afterClosed();
  }

  public confirm(title: string, message: string): Observable<boolean> {
    let dialogRef: MatDialogRef<ConfirmDialogComponent>;
    dialogRef = this.dialog.open(ConfirmDialogComponent);
    dialogRef.componentInstance.title = title;
    dialogRef.componentInstance.message = message;
    return dialogRef.afterClosed();
  }

  public fees(): Observable<any> {
    let dialogRef: MatDialogRef<FeesDialogComponent>;
    dialogRef = this.dialog.open(FeesDialogComponent);
    return dialogRef.afterClosed();
  }

  public template(templates: any[]): Observable<any> {
    let dialogRef: MatDialogRef<TemplateDialogComponent>;
    dialogRef = this.dialog.open(TemplateDialogComponent);
    dialogRef.componentInstance.templates = templates;
    return dialogRef.afterClosed();
  }

  public priority(title: string, message: string, options: any[], priority: number = null): Observable<number> {
    let dialogRef: MatDialogRef<PriorityDialogComponent>;
    dialogRef = this.dialog.open(PriorityDialogComponent);
    dialogRef.componentInstance.title = title;
    dialogRef.componentInstance.message = message;
    dialogRef.componentInstance.options = options;
    dialogRef.componentInstance.priority = priority;
    return dialogRef.afterClosed();
  }

  public deal(deal: any, offerId: string, user: UserInterface, owners: any[], affiliates: any[], options: any): Observable<any> {
    let dialogRef: MatDialogRef<DealDialogComponent>;
    dialogRef = this.dialog.open(DealDialogComponent);
    dialogRef.componentInstance.affiliates = affiliates;
    dialogRef.componentInstance.owners = owners;
    dialogRef.componentInstance.options = options;
    dialogRef.componentInstance.deal = deal;
    dialogRef.componentInstance.offerId = offerId;
    dialogRef.componentInstance.user = user;
    return dialogRef.afterClosed();
  }

  public newDemand(params: {deal?: any, pipelineId?: string, user?: UserInterface, owners?: any[], priorities?: any[], affiliates?: any[], pipelines?: Pipeline[]}): Observable<any> {
    let dialogRef: MatDialogRef<NewDemandDialogComponent>;
    dialogRef = this.dialog.open(NewDemandDialogComponent);
    dialogRef.componentInstance.affiliates = params.affiliates;
    dialogRef.componentInstance.owners = params.owners;
    dialogRef.componentInstance.deal = params.deal;
    dialogRef.componentInstance.user = params.user;
    dialogRef.componentInstance.pipelines = params.pipelines;
    dialogRef.componentInstance.pipelineId = params.pipelineId;
    dialogRef.componentInstance.priorities = params.priorities;
    return dialogRef.afterClosed();
  }

  public task(task: Task, user: UserInterface, owners: any[], groups: any[], options: any): Observable<Task> {
    let dialogRef: MatDialogRef<TaskDialogComponent>;
    dialogRef = this.dialog.open(TaskDialogComponent);
    dialogRef.componentInstance.owners = owners;
    dialogRef.componentInstance.groups = groups;
    dialogRef.componentInstance.task = Object.assign({}, task);
    dialogRef.componentInstance.user = user;
    dialogRef.componentInstance.options = options;
    return dialogRef.afterClosed();
  }

  public owner(deals: any[], users: any[], stages: any, contacts: any[]): Observable<any> {
    let dialogRef: MatDialogRef<OwnerDialogComponent>;
    dialogRef = this.dialog.open(OwnerDialogComponent);
    dialogRef.componentInstance.deals = deals;
    dialogRef.componentInstance.users = users;
    dialogRef.componentInstance.stages = stages;
    dialogRef.componentInstance.contacts = contacts;
    return dialogRef.afterClosed();
  }

  public drawer(ownerId: string, users: any[]): Observable<boolean> {
    let dialogRef: MatDialogRef<DrawerDialogComponent>;
    dialogRef = this.dialog.open(DrawerDialogComponent);
    dialogRef.componentInstance.drawerId = ownerId;
    dialogRef.componentInstance.users = users;
    return dialogRef.afterClosed();
  }

  public findUser(): Observable<boolean> {
    let dialogRef: MatDialogRef<FindUserDialogComponent>;
    dialogRef = this.dialog.open(FindUserDialogComponent);
    return dialogRef.afterClosed();
  }

  public cancel(options: any[]): Observable<boolean> {
    let dialogRef: MatDialogRef<CancelDialogComponent>;
    dialogRef = this.dialog.open(CancelDialogComponent);
    dialogRef.componentInstance.options = options;
    return dialogRef.afterClosed();
  }

  public cancelCase(options: any[]): Observable<boolean> {
    let dialogRef: MatDialogRef<CancelCaseDialogComponent>;
    dialogRef = this.dialog.open(CancelCaseDialogComponent);
    dialogRef.componentInstance.options = options;
    return dialogRef.afterClosed();
  }

  public postpone(options: any[], postponeDate, reasons): Observable<any> {
    let dialogRef: MatDialogRef<PostponeDialogComponent>;
    dialogRef = this.dialog.open(PostponeDialogComponent);
    dialogRef.componentInstance.options = options;
    dialogRef.componentInstance.result.reasons = reasons;
    dialogRef.componentInstance.result.postponeDate = postponeDate ? new Date(postponeDate) : null;
    return dialogRef.afterClosed();
  }
  public postponeCase(options: any[], postponeDate, reasons): Observable<any> {
    let dialogRef: MatDialogRef<PostponeCaseDialogComponent>;
    dialogRef = this.dialog.open(PostponeCaseDialogComponent);
    dialogRef.componentInstance.options = options;
    dialogRef.componentInstance.result.reasons = reasons;
    dialogRef.componentInstance.result.postponeDate = postponeDate ? new Date(postponeDate) : null;
    return dialogRef.afterClosed();
  }

  public score(options: any[], score): Observable<any> {
    let dialogRef: MatDialogRef<ScoreDialogComponent>;
    dialogRef = this.dialog.open(ScoreDialogComponent);
    dialogRef.componentInstance.setValues(options, score);
    return dialogRef.afterClosed();
  }

  public uipath(providers: any[]): Observable<any> {
    let dialogRef: MatDialogRef<UipathDialogComponent>;
    dialogRef = this.dialog.open(UipathDialogComponent);
    dialogRef.componentInstance.providers = providers;
    return dialogRef.afterClosed();
  }

  public stage(stages: any, stage): Observable<any> {
    let dialogRef: MatDialogRef<StageDialogComponent>;
    dialogRef = this.dialog.open(StageDialogComponent);
    dialogRef.componentInstance.setValues(stages, stage);
    return dialogRef.afterClosed();
  }

  public restoreCase(stages: any[], stage): Observable<any> {
    let dialogRef: MatDialogRef<RestoreCaseDialogComponent>;
    dialogRef = this.dialog.open(RestoreCaseDialogComponent);
    dialogRef.componentInstance.setValues(stages, stage);
    return dialogRef.afterClosed();
  }

  public restore(options: any[], needReason: boolean): Observable<any> {
    let dialogRef: MatDialogRef<RestoreDialogComponent>;
    dialogRef = this.dialog.open(RestoreDialogComponent);
    dialogRef.componentInstance.options = options;
    dialogRef.componentInstance.needReason = needReason;
    return dialogRef.afterClosed();
  }

  public password(): Observable<boolean> {
    let dialogRef: MatDialogRef<PasswordDialogComponent>;
    dialogRef = this.dialog.open(PasswordDialogComponent);
    return dialogRef.afterClosed();
  }

  public deactivate(): Observable<boolean> {
    let dialogRef: MatDialogRef<DeactivateDialogComponent>;
    dialogRef = this.dialog.open(DeactivateDialogComponent);
    return dialogRef.afterClosed();
  }

  public fileJoinDialog(description: string, filename: string, ready: boolean, files: DocumentFile[]): Observable<any> {
    const dialogRef: MatDialogRef<FileOperationsDialogComponent> = this.dialog.open(FileOperationsDialogComponent);
    dialogRef.componentInstance.operationType = 'join';
    dialogRef.componentInstance.files = files;
    dialogRef.componentInstance.title = 'Spojit soubory';
    dialogRef.componentInstance.description = description;
    dialogRef.componentInstance.ready = ready;
    dialogRef.componentInstance.result.fileName = filename;
    return dialogRef.afterClosed();
  }

  public fileRejectDialog(description: string, ready: boolean, files: DocumentFile[]): Observable<any> {
    const dialogRef: MatDialogRef<FileOperationsDialogComponent> = this.dialog.open(FileOperationsDialogComponent);
    dialogRef.componentInstance.operationType = 'reject';
    dialogRef.componentInstance.files = files;
    dialogRef.componentInstance.title = 'Zamítnout soubory';
    dialogRef.componentInstance.description = description;
    dialogRef.componentInstance.ready = ready;
    return dialogRef.afterClosed();
  }

  public fileChooseGroupDialog(description: string, files: DocumentFile[], groups: any, documents: any, sourceGroup: string): Observable<any> {
    const dialogRef: MatDialogRef<FileChooseGroupDialogComponent> = this.dialog.open(FileChooseGroupDialogComponent);
    dialogRef.componentInstance.files = files;
    dialogRef.componentInstance.groups = groups;
    dialogRef.componentInstance.documents = documents;
    dialogRef.componentInstance.sourceGroup = sourceGroup;
    dialogRef.componentInstance.title = 'Přesunout soubor';
    dialogRef.componentInstance.description = description;
    return dialogRef.afterClosed();
  }

  public commPreferences(contactId: string): Observable<any> {
    let dialogRef: MatDialogRef<CommPreferencesDialogComponent>;
    dialogRef = this.dialog.open(CommPreferencesDialogComponent);
    dialogRef.componentInstance.contactId = contactId;
    return dialogRef.afterClosed();
  }

  public emailDuplicity(applicants: FormArray, duplicates: number[]): Observable<boolean> {
    const dialogRef: MatDialogRef<EmailDuplicityDialogComponent> = this.dialog.open(EmailDuplicityDialogComponent);
    dialogRef.componentInstance.applicants = applicants;
    dialogRef.componentInstance.duplicates = duplicates;
    return dialogRef.afterClosed();
  }

  public videoCall(): Observable<string> {
    const dialogRef: MatDialogRef<VideoCallDialogComponent> = this.dialog.open(VideoCallDialogComponent);
    return dialogRef.afterClosed();
  }

  public newIntervention(intervention: any, isCounterProposal: boolean, modelations: any): Observable<FormGroup> {
    const dialogRef: MatDialogRef<InterventionDialogComponent> = this.dialog.open(InterventionDialogComponent);
    dialogRef.componentInstance.intervention = intervention;
    dialogRef.componentInstance.isCounterProposal = isCounterProposal;
    dialogRef.componentInstance.modelations = modelations;
    return dialogRef.afterClosed();
  }

  public chooseOwner(ownerId: string, owners: any[], groups: any[], options: any): Observable<string | null> {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.width = '30rem';
    const dialogRef: MatDialogRef<ChooseOwnerDialogComponent> = this.dialog.open(ChooseOwnerDialogComponent, dialogConfig);
    dialogRef.componentInstance.owners = owners;
    dialogRef.componentInstance.groups = groups;
    dialogRef.componentInstance.options = options;
    dialogRef.componentInstance.ownerId = ownerId;
    return dialogRef.afterClosed();
  }

}
