import { Pipe, PipeTransform } from '@angular/core';
import {OptionInterface} from '../interfaces/option.interface';

@Pipe({ name: 'optionsFilter' })
export class OptionsFilterPipe implements PipeTransform {
  constructor() {}
  transform(options: OptionInterface[], filter: any, sort?: string) {
    if (!options || !filter) {
      return options;
    }
    const filteredOptions = options.filter((option: OptionInterface) => matchFilter(option, filter));
    if (sort) {
      filteredOptions.sort((a: OptionInterface, b: OptionInterface) => {
        return a[sort].localeCompare(b[sort]);
      });
    }
    return filteredOptions;

  }
}

const matchFilter = (option: OptionInterface, filter: any): boolean => {
  return Object.keys(filter).every((key: string) => {
    if (typeof filter[key] === 'object' && filter[key] !== null) {
      // Recursively check nested properties
      return matchFilter(option[key], filter[key]);
    } else {
      // Check non-nested properties
      return filter[key] === option[key];
    }
  });
};
