import {Injectable} from '@angular/core';
import {BackendService} from './backend.service';
import {DialogsService} from './dialogs.service';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import {AbstractControl, FormArray, ValidationErrors, ValidatorFn} from '@angular/forms';
import {conformToMask} from 'angular2-text-mask';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {UsersService} from './users.service';
import {OptionInterface} from '../interfaces/option.interface';
import {SettingsService} from '@app/services/settings.service';
import {SnackbarService} from '@app/services/snackbar.service';

@Injectable()
export class MainService {
  public sidenavFull = false;
  public yearMask: any = null;
  public currencyMask: any = null;
  public percentMask: any = null;
  private _offlineDialog: any = null;
  private refreshDialog: any = null;
  public isLoading = false;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  public hideCasePanel: boolean = false;

  constructor(
    private backendService: BackendService,
    private dialogsService: DialogsService,
    private settingsService: SettingsService,
    private usersService: UsersService,
    private snackbarService: SnackbarService
  ) {
    this.backendService.connected$.subscribe((connected) => {
      if (connected) {
        if (this._offlineDialog) {
          this._offlineDialog.close();
          this._offlineDialog = null;
        }
      } else {
        if (!this._offlineDialog && connected !== null) {
          this._offlineDialog = this.dialogsService.modalInfo('Spojení přerušeno', 'Bylo přerušeno spojení se serverem. Jakmile bude navázáno, aplikace bude pokračovat.');
        }
      }
    });
    this.usersService.authenticated$.subscribe(async (auth) => {
      if (auth) {
        const user = await this.usersService.get(this.usersService.user._id, {query: {amountstats: true}});
        this.usersService.setUser(user);
      }
    });
    this.currencyMask = createNumberMask({
      prefix: '',
      suffix: '',
      thousandsSeparatorSymbol: ' ',
      allowNegative: true
    });
    this.percentMask = createNumberMask({
      prefix: '',
      includeThousandsSeparator: false,
      allowDecimal: true,
      decimalSymbol: ',',
      integerLimit: 3
    });
    this.yearMask = createNumberMask({
      prefix: '',
      includeThousandsSeparator: false,
      allowDecimal: false,
      integerLimit: 4
    });
    this.hideCasePanel = JSON.parse(localStorage.getItem('hideCasePanel')) === true;
  }

  public showLoading() {
    this.isLoading = true;
  }

  public hideLoading() {
    this.isLoading = false;
  }

  public showRefreshDialog() {
    if (!this.refreshDialog) {
      this.refreshDialog = this.dialogsService.modalInfo('Spojení přerušeno', 'Bylo přerušeno spojení se serverem. Jakmile bude navázáno, aplikace bude pokračovat.');
    }
  }

  public hideRefreshDialog() {
    if (this.refreshDialog) {
      this.refreshDialog.close();
      this.refreshDialog = null;
    }
  }

  public async init() {
  }

  public afterViewInit() {
  }

  public maskCurrency(value: any) {
    value = this.unmaskCurrency(value) || 0;
    return (conformToMask(
      Math.round(value).toString(),
      this.currencyMask,
      {guide: false}
    )).conformedValue;
  }

  public unmaskCurrency(value: any) {
    value = value || 0;
    return parseInt(value.toString().replace(/\s/g, ''), null);
  }

  public maskFloat(value: any) {
    value = value || 0;
    return value.toFixed(2).replace('.', ',');
  }

  public unmaskFloat(value: any) {
    value = value || 0;
    return parseFloat(value.toString().replace(',', '.'));
  }

  public maskPercent(value: any) {
    value = value || 0;
    return (value * 100).toFixed(2).replace('.', ',');
  }

  public unmaskPercent(value: any) {
    value = value || 0;
    return parseFloat(value.toString().replace(',', '.')) / 100;
  }

  public maxValueValidator(maxValue: any, type: string): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      let value = control.value;
      if (type === 'currency') {
        value = this.unmaskCurrency(value);
      } else if (type === 'percent') {
        value = this.unmaskPercent(value);
      } else if (type === 'float') {
        value = this.unmaskFloat(value);
      } else {
        value = parseInt(value, null);
      }
      return value > maxValue ? {maxValue: {value: control.value}} : null;
    };
  }

  public requireSelectionValidator(type: string): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      if (control.value && this.settingsService.options[type].findIndex(o => o.value === control.value) < 0) {
        return {requireSelection: {value: control.value}};
      } else {
        return null;
      }
    };
  }

  public getFormValidationErrors(formGroup: any, invalid = [], exclude = []) {
    (Object as any).keys(formGroup.controls).forEach(key => {
      const controlErrors: ValidationErrors = formGroup.get(key) ? formGroup.get(key).errors : null;
      if (!exclude.includes(key) && controlErrors != null && key !== 'dti' && key !== 'dsti' && key !== 'minimumInterestRate') {
        invalid.push({formGroup, key});
      }
      if (!exclude.includes(key) && formGroup.controls[key].controls) {
        return this.getFormValidationErrors(formGroup.controls[key], invalid, exclude);
      }
    });
    return invalid;
  }

  public isEquivalent(a, b) {
    // Create arrays of property names
    const aProps = Object.getOwnPropertyNames(a);
    const bProps = Object.getOwnPropertyNames(b);

    // If number of properties is different,
    // objects are not equivalent
    if (aProps.length !== bProps.length) {
      return false;
    }

    for (let i = 0; i < aProps.length; i++) {
      const propName = aProps[i];

      // If values of same property are not equal,
      // objects are not equivalent
      if (a[propName] !== b[propName]) {
        return false;
      }
    }

    // If we made it this far, objects
    // are considered equivalent
    return true;
  }

  public options(type: string) {
    return this.settingsService.options[type] || [];
  }

  public optionLabel(type: OptionInterface[] | string, value, isInternal = false) {
    if (value !== undefined) {
      let options: OptionInterface[];
      if (typeof type === 'string') {
        options = this.settingsService.options[type];
      } else {
        options = type;
      }
      const option = options?.find(o => o.value === value);
      if (option) {
        return isInternal ? option.internalLabel : option.label;
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  public scrollToId(id) {
    const el = document.getElementById(id);
    el.scrollIntoView();
  }

  public filterChipItems(type: OptionInterface[] | string, control): OptionInterface[] {
    let options: OptionInterface[];
    if (typeof type === 'string') {
      options = this.settingsService.options[type];
    } else {
      options = type;
    }
    return options?.filter(o => !control.value || !control.value.includes(o.value)) || [];
  }

  public removeChip(chip: string, control) {
    const items = control.value;
    const index = items.indexOf(chip);
    if (index >= 0) {
      items.splice(index, 1);
      control.setValue(items);
      control.root.markAsDirty();
    }
  }

  public selectedChipItem(event: MatAutocompleteSelectedEvent, control) {
    const values = control.value;
    values.push(event.option.value);
    control.setValue(values);
    control.root.markAsDirty();
  }

  public isStageBetween(stage, from, to = null) {
    const index = stage ? this.settingsService.settings.stageOrder.indexOf(stage) : 0;
    const fromIndex = from ? this.settingsService.settings.stageOrder.indexOf(from) : 0;
    const toIndex = to ? this.settingsService.settings.stageOrder.indexOf(to) : 1000;
    return index >= fromIndex && index <= toIndex;
  }

  public toggleCasePanel() {
    this.hideCasePanel = !this.hideCasePanel;
    localStorage.setItem('hideCasePanel', JSON.stringify(this.hideCasePanel));
  }

  public filterAutocompleteItems(type, value): OptionInterface[] {
    const options: OptionInterface[] = this.settingsService.options[type];
    value = value ? value.toLowerCase() : null;
    return value ? options.filter(o => o.value.toLowerCase().includes(value) || o.label.toLowerCase().includes(value)).slice(0, 30) : options.slice(0, 30);
  }

  public trackItemByIndexFn(index: any, item: any) {
    return index;
  }

  public trackControlByIdFn(index: any, item: any) {
    if (item.get) {
      return item.get('_id')?.value;
    }
    return item._id || index;
  }

  atLeastOneElementValidator(control: FormArray) {
    if (control && control.length < 1) {
      return { atLeastOneElement: true };
    }
    return null;
  }

  async copyToClipboard(value) {
    await navigator.clipboard.writeText(value);
    this.snackbarService.showSuccess('Data byla zkopírována do schránky.');
  }

  public removeDiacritics(input) {
    return input
      .normalize('NFD') // Normalize to decomposed form
      .replace(/[\u0300-\u036f]/g, ''); // Remove diacritical marks
  }

}
