<div class="case-body" [formGroup]="form">
  <div class="timeline-wrap" formArrayName="commissionsEntitlements">
    <div class="card-title">
      <span>Nároky</span>
      <div>
        <button mat-button color="primary" (click)="addEntitlement()"><span>Nový nárok</span><mat-icon>add</mat-icon></button>
      </div>
    </div>
    <ng-container *ngFor="let entitlement of (form.get('commissionsEntitlements') | formArray).controls; let eIndex = index;trackBy: mainService.trackControlByIdFn;">
      <div class="task card" [formGroupName]="eIndex">
        <div class="form">
          <div class="form-row">
            <mat-form-field *ngIf="(entitlement.get('userId').value | getUser | async) as user">
              <input placeholder="Uživatel" matInput [value]="(user | userName) + ' (' + user.email + ')'" readonly (click)="findUser(entitlement)"/>
            </mat-form-field>
            <mat-form-field class="flex-1-1-50px">
              <input placeholder="Provize" matInput formControlName="commissionShare" type="number" step="0.1" />
              <span matSuffix>%</span>
            </mat-form-field>
            <button class="flex-0-0-auto" mat-icon-button (click)="removeEntitlement(eIndex)" color="warn"><mat-icon>remove_circle_outline</mat-icon></button>
          </div>
          <div class="form-row">
            <mat-form-field><textarea placeholder="Poznámka" formControlName="note" matInput cdkTextareaAutosize rows="1"></textarea></mat-form-field>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="timeline-wrap">
    <div class="card-title">
      <span>Provize</span>
      <div>
        <button *ngIf="usersService.isPrivileged('cases/commissions:patch')" mat-button color="primary" (click)="addCommission()"><span>Nová provize / storno</span><mat-icon>add</mat-icon></button>
      </div>
    </div>
    <mat-accordion formArrayName="commissions" *ngIf="usersService.isPrivileged('cases/commissions:patch')">
      <ng-container *ngFor="let commission of (form.get('commissions') | formArray).controls; let cIndex = index;trackBy: mainService.trackControlByIdFn;">
        <mat-expansion-panel [formGroupName]="cIndex">
          <mat-expansion-panel-header>
            <mat-panel-title class="middle-description">
              <mat-icon color="accent" *ngIf="!commission.get('isCancellation').value">add</mat-icon>
              <mat-icon color="warn" *ngIf="commission.get('isCancellation').value">remove</mat-icon>
              <span class="flex-1-1-auto">{{ mainService.maskCurrency(commission.get('amount').value) + ' Kč'}}</span>
              <span class="flex-1-1-auto" >{{ commission.get('contractNumber').value }}</span>
              <span class="flex-1-1-auto" *ngIf="!commission.get('isCancellation').value">{{ mainService.optionLabel('commissionType', commission.get('type').value) }}</span>
              <button mat-icon-button color="warn" (click)="$event.stopPropagation(); removeCommission(cIndex)"><mat-icon>remove_circle_outline</mat-icon></button>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="form-row">
            <mat-slide-toggle class="flex-0-0-auto" color="warn" formControlName="isCancellation">Storno</mat-slide-toggle>
            <mat-form-field>
              <input placeholder="Částka" matInput formControlName="amount" [textMask]="{mask: mainService.currencyMask, guide: false}"/>
              <span matSuffix>Kč</span>
            </mat-form-field>
            <mat-form-field>
              <mat-select placeholder="Číslo smlouvy" formControlName="contractNumber">
                <mat-option *ngFor="let option of contractNumbers" [value]="option">{{ option }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="form-row">
            <mat-form-field *ngIf="commission.get('isCancellation').value">
              <input matInput placeholder="Datum výpovědi" [matDatepicker]="canceledAtPicker" formControlName="canceledAt" />
              <mat-datepicker-toggle matSuffix [for]="canceledAtPicker"></mat-datepicker-toggle>
              <mat-datepicker #canceledAtPicker></mat-datepicker>
            </mat-form-field>
            <mat-form-field *ngIf="!commission.get('isCancellation').value">
              <input matInput placeholder="Očekávané zaúčtování" [matDatepicker]="settleAtPicker" formControlName="settleAt" />
              <mat-datepicker-toggle matSuffix [for]="settleAtPicker"></mat-datepicker-toggle>
              <mat-datepicker #settleAtPicker></mat-datepicker>
            </mat-form-field>
            <mat-form-field>
              <input matInput placeholder="Datum zaúčtování" [matDatepicker]="settledAtPicker" formControlName="settledAt" />
              <mat-datepicker-toggle matSuffix [for]="settledAtPicker"></mat-datepicker-toggle>
              <mat-datepicker #settledAtPicker></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="form-row">
            <mat-form-field><textarea placeholder="Poznámka" formControlName="note" matInput cdkTextareaAutosize rows="1"></textarea></mat-form-field>
          </div>
          <div class="form-section" *ngIf="!commission.get('isCancellation').value">
            <div class="form-section-title">Příjemci</div>
            <div class="form-section-content">
              <div class="form-section-buttons">
                <button mat-button color="primary" (click)="addRecipient(commission)"><span>Nový příjemce</span><mat-icon>add</mat-icon></button>
              </div>
            </div>
          </div>
          <mat-accordion formArrayName="recipients">
            <ng-container *ngFor="let recipient of (commission.get('recipients') | formArray).controls; let rIndex = index;trackBy: mainService.trackControlByIdFn;">
              <mat-expansion-panel [formGroupName]="rIndex">
                <mat-expansion-panel-header>
                  <mat-panel-title class="middle-description">
                    <mat-icon *ngIf="recipient.get('isPaid').value" color="accent">done</mat-icon>
                    <mat-icon *ngIf="!recipient.get('isPaid').value" color="warn">close</mat-icon>
                    <div class="flex-1-1-auto" *ngIf="recipient.get('affiliateId').value">{{ affiliate?.name }}</div>
                    <div class="flex-1-1-auto" *ngIf="!recipient.get('affiliateId').value"><span *ngIf="(recipient.get('userId').value | getUser | async) as user">{{ user | userName }}</span></div>
                    <span class="flex-1-1-auto">{{ mainService.maskCurrency(recipient.get('amount').value) }} Kč</span>
                    <button mat-icon-button color="warn" (click)="$event.stopPropagation(); removeRecipient(commission, rIndex)"><mat-icon>remove_circle_outline</mat-icon></button>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="form-row">
                  <mat-form-field *ngIf="affiliate">
                    <mat-select placeholder="Affiliate" formControlName="affiliateId">
                      <mat-option [value]="null"></mat-option>
                      <mat-option [value]="affiliate?._id">{{ affiliate?.name }} ({{ affiliate?.maxProvisionRate}}%)</mat-option>
                    </mat-select>
                  </mat-form-field>
                  <mat-form-field>
                    <mat-select placeholder="Uživatel" formControlName="userId">
                      <mat-option [value]="null"></mat-option>
                      <ng-container *ngFor="let entitlement of form.get('commissionsEntitlements').value">
                        <mat-option *ngIf="(entitlement.userId | getUser | async) as user" [value]="entitlement.userId">{{ (user | userName) + ' (' + entitlement.commissionShare + '%)' }}</mat-option>
                      </ng-container>
                    </mat-select>
                  </mat-form-field>
                  <mat-checkbox formControlName="isPaid">Vyplaceno</mat-checkbox>
                </div>
                <div class="form-row">
                  <mat-form-field>
                    <input matInput placeholder="Datum platby" [matDatepicker]="paymentAtPicker" formControlName="paymentAt" />
                    <mat-datepicker-toggle matSuffix [for]="paymentAtPicker"></mat-datepicker-toggle>
                    <mat-datepicker #paymentAtPicker></mat-datepicker>
                  </mat-form-field>
                  <mat-form-field>
                    <input placeholder="Provize" matInput formControlName="commissionShare" />
                    <span matSuffix>%</span>
                  </mat-form-field>
                  <mat-form-field>
                    <input placeholder="Částka" matInput formControlName="amount" [textMask]="{mask: mainService.currencyMask, guide: false}" readonly/>
                    <span matSuffix>Kč</span>
                  </mat-form-field>
                </div>
                <div class="form-row">
                  <mat-form-field><textarea placeholder="Poznámka" formControlName="note" matInput cdkTextareaAutosize rows="1"></textarea></mat-form-field>
                </div>
              </mat-expansion-panel>
            </ng-container>
          </mat-accordion>
        </mat-expansion-panel>
      </ng-container>
    </mat-accordion>
    <ng-container *ngFor="let commission of form.get('commissions').value;trackBy: mainService.trackControlByIdFn;">
      <ng-container *ngFor="let recipient of commission.recipients;trackBy: mainService.trackControlByIdFn;">
        <div class="task card" *ngIf="!commission.isCancellation && recipient.userId === usersService.user._id">
          <div class="form">
            <div class="form-row">
              <mat-form-field>
                <input placeholder="Částka" matInput [value]="this.mainService.maskCurrency(recipient.amount)" readonly/>
                <span matSuffix>Kč</span>
              </mat-form-field>
              <mat-form-field>
                <input placeholder="Datum výplaty" matInput [value]="recipient.paymentAt | date:'shortDate':undefined:'cs-CS'" readonly/>
              </mat-form-field>
              <mat-icon *ngIf="recipient.isPaid" color="accent" class="flex-0-0-auto">done</mat-icon>
              <mat-icon *ngIf="!recipient.isPaid" color="warn" class="flex-0-0-auto">close</mat-icon>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>
<mat-menu #entitlementMenu="matMenu">
  <ng-template matMenuContent let-eIndex="eIndex">
    <button mat-menu-item (click)="removeEntitlement(eIndex)"><mat-icon>delete</mat-icon>Odstranit</button>
  </ng-template>
</mat-menu>
