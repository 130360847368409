import { Injectable, isDevMode } from '@angular/core';
import {BackendService} from './backend.service';
import {BehaviorSubject, Observable} from 'rxjs';
import {ProductInterface} from '../interfaces/product.interface';

@Injectable()
export class DealProductsService {
  public items: ProductInterface[] = [];
  public total: number = 0;
  private _socket: any;
  private _socketTotals: any;
  private _socketDiscount: any;
  private _socketDti: any;
  private socketBenefitInterestRate: any;
  private _items: BehaviorSubject<ProductInterface[]> = new BehaviorSubject([]);
  public $total: BehaviorSubject<number> = new BehaviorSubject<number>(this.total);
  public total$: Observable<number> = this.$total.asObservable();
  public isLoading = false;
  public sorting: any = {
    'fixation.effectiveInterestRate': 1
  };
  public filters: any = {
    offerId: null,
    propertyType: null,
    offsetType: 'none',
    oldestApplicantAge: null,
    amount: null,
    value: null,
    maturity: null,
    fixation: null,
    insurance: false,
    purpose: [],
    productFilter: null,
    esexpress: true,
    esonline: true,
    ddexpress: true,
    anotherFinancingSourceApply: false,
    anotherFinancingSource: 0,
    averageAccountBalance: 0,
    otherSourceOf: null,
    nolimits: false
  };

  protected $isLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isLoading$: Observable<boolean> = this.$isLoading.asObservable();
  constructor(
    private backendService: BackendService
  ) {
    this._socket = this.backendService.getService('products');
    this._socketTotals = this.backendService.getService('products/totals');
    this._socketDiscount = this.backendService.getService('products/discount');
    this.socketBenefitInterestRate = this.backendService.getService('products/benefit-interest-rate');
    this._socketDti = this.backendService.getService('products/dti');
    this._socket.on('updated', (item: ProductInterface) => this._updated(item));
    this._socket.on('updated', (item: ProductInterface) => this._updated(item));
    this._socket.on('created', (item: ProductInterface) => this._created(item));
    this._socket.on('removed', (item: ProductInterface) => this._removed(item));
  }

  get items$() {
    return this._items.asObservable();
  }

  public resetFilters() {
    this.filters = {
      offerId: null,
      propertyType: null,
      offsetType: 'none',
      oldestApplicantAge: null,
      amount: null,
      value: null,
      maturity: null,
      fixation: null,
      insurance: false,
      purpose: [],
      productFilter: null,
      esexpress: true,
      esonline: true,
      ddexpress: true,
      anotherFinancingSource: 0,
      anotherFinancingSourceApply: false,
      averageAccountBalance: 0,
      otherSourceOf: null,
      nolimits: false
    };
  }

  public createQuery(filter) {
    filter = Object.assign({}, filter, filter.productFilter);
    const query = Object.assign({}, filter.productFilter);
    query['amount'] = filter.amount ? parseInt(filter.amount, null) : 0;
    query['value'] = filter.value ? parseInt(filter.value, null) : 0;
    query['maturity'] = filter.maturity ? parseInt(filter.maturity, null) : 0;
    query['fixations.years'] = filter.fixation ? parseInt(filter.fixation, null) : 0;
    query['insurance'] = filter.insurance;
    query['property'] = filter.propertyType;
    query['purposes'] = filter.purpose;
    query['age'] = filter.oldestApplicantAge ? parseInt(filter.oldestApplicantAge, null) : 0;
    if (filter.sales) {
      query['sales'] = filter.sales;
    }
    if (filter.esexpress) {
      query['esexpress'] = filter.esexpress;
    }
    if (filter.esonline) {
      query['esonline'] = filter.esonline;
    }
    if (filter.ddexpress) {
      query['ddexpress'] = filter.ddexpress;
    }
    if (filter.nolimits) {
      query['nolimits'] = filter.nolimits;
    }
    if (filter.deal && filter.code) {
      query['deal'] = filter.deal;
      query['code'] = filter.code;
    }
    query['offerId'] = filter.offerId;
    if (filter.type === 'mortgage') {
      if (filter.anotherFinancingSourceApply) {
        query['anotherSource'] = filter.anotherFinancingSource.toFixed(2);
      }
      query['offsetType'] = filter.offsetType;
      query['averageAccountBalance'] = filter.averageAccountBalance;
    }
    if (filter.type === 'building_savings') {
      query['otherSourceOf'] = filter.otherSourceOf;
    }
    return query;
  }

  public load() {
    this.items = [];
    this.processItems();
    this.setLoading(true);
    this.isLoading = true;
    const valid = this.filters.amount && this.filters.maturity && this.filters.fixation;
    if (valid) {
      const query = this.createQuery(this.filters);
      this.find({
        query,
        collation: {
          locale: 'cs',
          strength: 1
        }
      }).then((data) => {
        this.items = data.map((i) => {i.fixation = i.fixations[0]; return i; });
        this.processItems();
      }).catch((e) => {
        this.items = [];
        this.processItems();
      });
    } else {
      this.items = [];
      this.processItems();
    }
  }

  public processItems() {
    const items = Array.from(this.items);
    items.sort((a, b) => {
      const sortBy = Object.keys(this.sorting)[0];
      const ascending = this.sorting[sortBy] === 1;
      if (typeof getObjectProperty(a, sortBy) === 'string') {
        return ascending ? getObjectProperty(a, sortBy).toLowerCase().localeCompare(getObjectProperty(b, sortBy).toLowerCase()) : getObjectProperty(b, sortBy).toLowerCase().localeCompare(getObjectProperty(a, sortBy).toLowerCase());
      } else {
        return ascending ? getObjectProperty(a, sortBy) - getObjectProperty(b, sortBy) : getObjectProperty(b, sortBy) - getObjectProperty(a, sortBy);
      }
    });
    this._items.next(items);
    this.setLoading(false);
  }

  public clearItems() {
    this.items = [];
    this._items.next([]);
  }

  public sort(column) {
    if (this.sorting[column] === undefined) {
      this.sorting = {};
      this.sorting[column] = 1;
    } else {
      this.sorting[column] *= -1;
    }
    this.processItems();
  }

  public isSorting(column, direction = null) {
    if (!direction) {
      return this.sorting[column] !== undefined;
    } else {
      return this.sorting[column] && this.sorting[column] === direction;
    }
  }

  public getDti(query: any = {}) {
    return this._socketDti.find({query});
  }

  public getDiscount(query: any = {}) {
    return this._socketDiscount.find({query});
  }

  public getTotals(query: any = {}) {
    return this._socketTotals.find({query});
  }

  public setLoading(isLoading: boolean) {
    this.isLoading = isLoading;
    this.$isLoading.next(isLoading);
  }

  public getBenefitInterestRate(query: any = {}) {
    return this.socketBenefitInterestRate.find({query});
  }

  public find(params: any = {}) {
    return this._socket.find(params);
  }

  public async getProductWithRate(productId, filter, interestRate) {
    const query = Object.assign({}, this.createQuery(filter), {interestRate: parseFloat(interestRate)});
    try {
      const product = await this.get(productId, {query});
      product.fixation = product.fixations.find(f => f.years === parseInt(filter.fixation, null) || f.years === 0);
      return product;
    } catch (e) {
      return null;
    }
  }

  public get(id: string, params: any = {}) {
    return this._socket.get(id, params);
  }

  private _updated(item: ProductInterface): void {
    const index = this.items?.findIndex(i => i._id === item._id);
    if (index > -1) {
      this.items[index] = item;
      this.processItems();
    }
  }

  private _created(item: ProductInterface): void {
    this.items.push(item);
    this.processItems();
  }

  private _removed(item: ProductInterface): void {
    const index = this.items?.findIndex(i => i._id === item._id);
    this.items.splice(index, 1);
    this.processItems();
  }

}

function getObjectProperty(o, s) {
  s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
  s = s.replace(/^\./, '');           // strip a leading dot
  const a = s.split('.');
  for (let i = 0, n = a.length; i < n; ++i) {
    const k = a[i];
    if (k in o) {
      o = o[k];
    } else {
      return;
    }
  }
  return o;
}
