import { Component } from '@angular/core';
import {TitleService} from '../../services/title.service';

@Component({
  selector: 'app-sales',
  templateUrl: 'sales.component.html',
  styleUrls: ['sales.component.scss']
})

export class SalesComponent {


  constructor(
    private titleService: TitleService
  ) {
    this.titleService.setTitle('Slevové akce');
  }
}
