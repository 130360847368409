import { NgModule } from '@angular/core';
import { CoreModule } from '@app/modules/core.module';
import { FileUploadComponent } from '@app/components/file-upload/file-upload.component';
import { SidebarModule } from 'primeng/sidebar';
import { FileUploadModule as PrimeUploadModule } from 'primeng/fileupload';

@NgModule({
  declarations: [FileUploadComponent],
  imports: [CoreModule, SidebarModule, PrimeUploadModule],
  exports: [FileUploadComponent],
})
export class FileUploadModule {}
