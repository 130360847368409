import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { InputNumberComponent } from '@app/components/input-number/input-number.component';

@NgModule({
    imports: [CommonModule, ReactiveFormsModule],
    exports: [InputNumberComponent],
    declarations: [InputNumberComponent]
})
export class InputNumberModule {}
