import { Pipe, PipeTransform } from '@angular/core';
import {OptionInterface} from '../interfaces/option.interface';
import {UsersService} from '@app/services/users.service';
import {UserInterface} from '@app/interfaces/user.interface';

@Pipe({ name: 'userPipelines' })
export class UserPipelinesPipe implements PipeTransform {
  constructor(
  ) {}
  transform(user: UserInterface) {
    const pipelines = [];
    if (user.cases?.['mortgage']) {
      pipelines.push('H');
    }
    if (user.cases?.['car-insurance']) {
      pipelines.push('PV');
    }
    if (user.cases?.['life-insurance']) {
      pipelines.push('PŽ');
    }
    if (user.cases?.['real-estate-insurance']) {
      pipelines.push('PN');
    }
    return pipelines.length ? `(${pipelines.join(', ')})` : '';
  }
}
