import { Pipe, PipeTransform } from '@angular/core';
import {AbstractControl, FormGroup} from '@angular/forms';

@Pipe({ name: 'formGroup' })
export class FormGroupPipe implements PipeTransform {
  constructor() {}
  transform(form: AbstractControl) {
    return (form as FormGroup);
  }
}
