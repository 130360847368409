import { NgModule } from '@angular/core';
import { PinchZoomModule } from '@app/components/pinch-zoom/pinch-zoom.module';
import { CoreModule } from '@app/modules/core.module';
import { FilePreviewComponent } from '@app/components/file-preview/file-preview.component';

@NgModule({
  declarations: [FilePreviewComponent],
  imports: [PinchZoomModule, CoreModule],
  exports: [FilePreviewComponent],
})
export class FilePreviewModule {}
