import { Injectable } from '@angular/core';
import {DataService} from './data.service';
import {Activity} from '@app/models/activity.model';

@Injectable({providedIn: 'root'})
export class TimelineActivityService extends DataService<Activity> {

  constructor(
  ) {
    super('activity');

  }

}
