import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { Note } from '@app/models/note.model';

@Injectable({providedIn: 'root'})
export class NotesService extends DataService<Note> {

  constructor(
  ) {
    super('notes');
    this.sort = { createdAt: -1 };
  }
}
