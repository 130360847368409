<form [formGroup]="this.loginForm" class="login" novalidate (ngSubmit)="this.login()">
    <h1>Multiplatforma</h1>
    <div class="version">{{ appInfo.version }}:{{ appInfo.env }}</div>
    <mat-form-field>
        <input matInput placeholder="E-mail" name="email" formControlName="email" autocapitalize="off" />
    </mat-form-field>
    <mat-form-field *ngIf="!isCode">
        <input matInput type="password" name="password" placeholder="Heslo" formControlName="password" />
    </mat-form-field>
    <mat-form-field *ngIf="isCode">
        <input matInput type="text" name="code" placeholder="Kód" formControlName="code" />
    </mat-form-field>
    <button mat-flat-button color="primary" type="submit">
        <mat-icon>lock_open</mat-icon>
        <span *ngIf="!isCode">Přihlásit</span>
        <span *ngIf="isCode">Potvrdit kód</span>
    </button>
</form>
<img class="logo" src="https://www.hyponamiru.cz/wp-content/uploads/2019/07/header_logo.png">
<div class="loading" *ngIf="isLoading"><mat-spinner class="white"></mat-spinner></div>
