<p-dialog [(visible)]="visible"
          [modal]="true"
          [dismissableMask]="true"
          [showHeader]="false"
          [transitionOptions]="'0ms'"
          [baseZIndex]="100"
          (onHide)="closeDialog(false)"
          position="center">
  <p-editor *ngIf="visible"
            [(ngModel)]="tmp"
            [style]="{ height: '700px', width: '1024px' }"
            (onInit)="initEditor($event)">
    <ng-template pTemplate="header">
      <span class="ql-formats">
        <select class="ql-size"></select>
      </span>
      <span class="ql-formats">
        <button type="button" class="ql-bold"></button>
        <button type="button" class="ql-italic"></button>
        <button type="button" class="ql-underline"></button>
        <!--select class="ql-color"></select-->
      </span>
      <span class="ql-formats">
        <button type="button" class="ql-list" value="ordered"></button>
        <button type="button" class="ql-list" value="bullet"></button>
        <button type="button" class="ql-indent" value="-1"></button>
        <button type="button" class="ql-indent" value="+1"></button>
      </span>
      <span class="ql-formats">
        <button type="button" class="ql-image"></button>
      </span>
    </ng-template>
  </p-editor>

  <button mat-raised-button color="accent" class="btn-save" (click)="closeDialog(true)">
    Uložit
  </button>

<!--  <button mat-raised-button color="primary" class="btn-close" (click)="closeDialog(false)">-->
<!--    Zavřít-->
<!--  </button>-->
</p-dialog>
